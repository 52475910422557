import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import cs from "classnames";

// import illustration from "../../../assets/images/maor-projects-on-devices-screen.png";
import illustration from "../../../assets/images/ThankYou.png";
import { APPLICATION_ROUTES } from "../../../constants";
import { StoreContext } from "../../../contexts";
import classes from "./DonationSuccess.module.scss";

const PartnersSuccess = observer(() => {
    const { products } = useContext(StoreContext);
    const { t } = useTranslation();

    useEffect(() => {
        products.getCampaign();
    }, []);

    return (
        <div className="bgc-gold">
            <div className="wrapper-xl wrapper-vertical-m-a minh-100vh">
                <div className={classes.grid}>
                    <div className={classes.message}>
                        <h2 className="heading-l-a m-0 tt-uppercase c-orange">
                            Thank you for your support!
                        </h2>
                        <div>

                            <p className={cs("m-0 mt-05", classes.messageOne)}> Your donation will enable us to continue creating an engaging experience, expanding our reach to the additional audiences and enhancing the quality of our production to connecting adults and children to the Rebbe.</p>

                            <p className={cs("m-0 mt-05", classes.messageOne)}>Moshiach now!</p>

                        </div>


                        <div className={cs("mt-05 fw-700 c-orange", classes.messageOne)}>
                            <Link to={APPLICATION_ROUTES.MY_MAOR_MAIN}>{t("pages.partners.donation.thank_you.1")}</Link>
                        </div>
                    </div>
                    <div className={classes.illustration}>
                        <img src={illustration} alt="devices with maor website" draggable={false} />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default PartnersSuccess;
