import { observer } from "mobx-react-lite";
import React from "react";

import cs from "classnames";
import classes from "./Field.module.scss";
import child from "../../../../../../assets/images/logotype-maor-child.png";
import rebbe from "../../../../../../assets/images/logotype-project-daily-rebbe-video.png";
import illuminate from "../../../../../../assets/images/illuminate3.png";
import Button from "../../../../../atoms/Button";
import Icon from "../../../../../atoms/Icon";
import { APPLICATION_ROUTES } from "../../../../../../constants";
import { scrollToTop } from "../../../../../../utilities/helpers";
import { useHistory } from "react-router-dom";
import play from "../../../../../../assets/images/playlistOrange.png";

const Field = observer(() => {
  const history = useHistory();

  const navigateToMaorLibrary = () => {
    history.push(APPLICATION_ROUTES.MY_MAOR_LIBRARY);
    scrollToTop();
  };

  const navigateToDailyLibrary = () => {
    history.push(APPLICATION_ROUTES.DAILY_REBBE_VIDEO_LIBRARY);
    scrollToTop();
  };

  const navigateToIlluminateLibrary = () => {
    history.push(APPLICATION_ROUTES.ILLUMINATE_LIBRARY);
    scrollToTop();
  };

  const navigateToDailyProject = () => {
    history.push(APPLICATION_ROUTES.DAILY_REBBE_VIDEO_MAIN);
    scrollToTop();
  };

  const navigateToIlluminateProject = () => {
    history.push(APPLICATION_ROUTES.ILLUMINATE);
    scrollToTop();
  };

  const navigateToMaorProject = () => {
    history.push(APPLICATION_ROUTES.MY_MAOR_MAIN);
    scrollToTop();
  };

  return (
    <div className={cs(classes.fieldsContainer)}>
      <div className={cs(classes.container, classes.marginZero)}>
        <div
          className={cs(classes.imageContainer)}
          onClick={navigateToMaorProject}
        >
          <img src={child} />
        </div>

        <div className={cs(classes.mobileTopContainer, classes.mobileDisplay)}>
          <div
            className={cs(classes.logoContainer)}
            onClick={navigateToMaorProject}
          >
            <img src={child} />
            <h3>FOR EVERY CHILD</h3>
          </div>
          <div className={cs(classes.libraryContainer)}>
            <Button
              onClick={navigateToMaorLibrary}
              className={cs(classes.sidebarButton)}
            >
              {" "}
              <img src={play} alt="play" /> Library{" "}
            </Button>
          </div>
        </div>

        <div className={cs(classes.infoContainer)}>
          <div className={cs(classes.infoTop)}>
            <h4>For every child</h4>

            <Button
              onClick={navigateToMaorLibrary}
              className={cs(classes.sidebarButton)}
            >
              {" "}
              <Icon name="playlist" size="xs" /> Library{" "}
            </Button>
          </div>
          <div className={cs(classes.infoCenter)}>
            <p>
              {" "}
              <span className={cs(classes.mbText)}>MyMaor | </span> The animated
              sichos, illustrated stories, exciting niggunim, and enlivened{" "}
              <br className={cs(classes.br)} />
              Moshiach lessons are specifically created with your children in
              mind.{" "}
            </p>
          </div>
          <div className={cs(classes.infoBottom, "c-orange", "ta-left")}>
            <p>
              &quot;My kids feel and see that the Rebbe is also for them, not
              just adults.&quot; <br className={cs(classes.br)} />
              <span className={cs(classes.italic)}>
                {" "}
                <span className={cs(classes.italicBold)}>
                  {" "}
                  Rochele Lustig,
                </span>{" "}
                New Heaven CT{" "}
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className={cs(classes.container, classes.mt)}>
        <div
          className={cs(classes.imageContainer)}
          onClick={navigateToDailyProject}
        >
          <img src={rebbe}></img>
        </div>

        <div className={cs(classes.mobileTopContainer, classes.mobileDisplay)}>
          <div
            className={cs(classes.logoContainer)}
            onClick={navigateToDailyProject}
          >
            <img src={rebbe} />
            <h3>FOR EVERY CHOSID</h3>
          </div>
          <div className={cs(classes.libraryContainer)}>
            <Button
              onClick={navigateToDailyLibrary}
              className={cs(classes.sidebarButton)}
            >
              {" "}
              <img src={play} alt="play" /> Library{" "}
            </Button>
          </div>
        </div>
        <div className={cs(classes.infoContainer)}>
          <div className={cs(classes.infoTop)}>
            <h4>For every Chosid</h4>
            <Button
              onClick={navigateToDailyLibrary}
              className={cs(classes.sidebarButton)}
            >
              {" "}
              <Icon name="playlist" size="xs" /> Library{" "}
            </Button>
          </div>
          <div className={cs(classes.infoCenter)}>
            <p>
              {" "}
              <span className={cs(classes.mbText)}>
                Daily Rebbe Video |{" "}
              </span>{" "}
              The daily clip of the Rebbe, equipped with subtitles, that allows
              adults to
              <br className={cs(classes.br)} />
              hear inspiring messages directly from the Rebbe.{" "}
            </p>
          </div>
          <div className={cs(classes.infoBottom, "c-orange", "ta-left")}>
            <p>
              &quot;I took the message personally, and B&apos;&apos;H arranged a
              monthly block shiur <br className={cs(classes.br)} /> for 30
              buildings in Crown Heights.&quot;
              <span className={cs(classes.italic)}>
                {" "}
                <span className={cs(classes.italicBold)}>
                  {" "}
                  Rivky Levy,
                </span>{" "}
                Crown Heights NY{" "}
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className={cs(classes.container, classes.mt)}>
        <div
          className={cs(classes.imageContainer)}
          onClick={navigateToIlluminateProject}
        >
          <img src={illuminate}></img>
        </div>
        <div className={cs(classes.mobileTopContainer, classes.mobileDisplay)}>
          <div
            className={cs(classes.logoContainer, classes.illuminateLogo)}
            onClick={navigateToIlluminateProject}
          >
            <img src={illuminate} />
            <h3>FOR EVERY JEW</h3>
          </div>
          <div className={cs(classes.libraryContainer)}>
            <Button
              onClick={navigateToIlluminateLibrary}
              className={cs(classes.sidebarButton)}
            >
              {" "}
              <img src={play} alt="play" /> Library{" "}
            </Button>
          </div>
        </div>
        <div className={cs(classes.infoContainer)}>
          <div className={cs(classes.infoTop)}>
            <h4>For every jew</h4>
            <Button
              className={cs(classes.sidebarButton)}
              onClick={navigateToIlluminateLibrary}
            >
              {" "}
              <Icon name="playlist" size="xs" /> Library{" "}
            </Button>
          </div>
          <div className={cs(classes.infoCenter)}>
            <p>
              {" "}
              <span className={cs(classes.mbText)}>Illuminate | </span> This
              illustrated series for adults has subtitles and explanatory
              narration, <br className={cs(classes.br)} />
              helping every type of learner internalize the Rebbe&apos;s
              teachings.{" "}
            </p>
          </div>
          <div className={cs(classes.infoBottom, "c-orange", "ta-left")}>
            <p>
              &quot;It keeps me so engaged while learning from the Rebbe. I love
              the active <br className={cs(classes.br)} /> illustrations in the
              Illuminated videos.&quot;
              <span className={cs(classes.italic)}>
                {" "}
                <span className={cs(classes.italicBold)}>
                  {" "}
                  Malka Solomon,
                </span>{" "}
                Hillside NJ{" "}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default React.memo(Field);
