import React, { useContext } from "react";
import cs from "classnames";

import Icon from "../../../../atoms/Icon";
import GlobalNavigation, {
  DIRECTIONS,
} from "../../../../molecules/GlobalNavigation";
import ProfileMenu from "../../../../molecules/ProfileMenu";
import CampaignBanner from "../../../../organisms/CampaignBanner";
import Modal from "../../../../organisms/Modal";
import { useModal } from "../../../../../hooks";
import classes from "./ModalMenu.module.scss";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../../../../../contexts";
import { BrowserView, isMobile } from "react-device-detect";

const ModalMenu = observer(() => {
  const { isOpened, onClose, onOpen } = useModal();

  const { authorization } = useContext(StoreContext);

  const campingBanner = !authorization.isUserMember ? (
    <div className={cs(classes.banner, "wrapper-xl")}>
      <CampaignBanner />
    </div>
  ) : null;

  return (
    <div className="d-flex ai-center">
      <button onClick={onOpen} className={classes.hamburger}>
        <Icon name="hamburger" size="stretched" />
      </button>
      <Modal
        opened={isOpened}
        style={{ background: "transparent" }}
        onClose={onClose}
      >
        <div className={classes.body}>
          {campingBanner}
          <nav className={classes.navigation}>
            <div className="wrapper-xl">
              <div className={classes.header}>
                <button
                  className={cs(classes.hamburger, classes.small)}
                  onClick={onClose}
                >
                  <Icon name="x" size="s" />
                </button>
              </div>
              {!isMobile ? (
                <BrowserView>
                  <div className={classes.main}>
                    <ProfileMenu id="modal-menu-profile-menu" />
                  </div>
                </BrowserView>
              ) : null}

              <div className={classes.footer}>
                <GlobalNavigation
                  direction={DIRECTIONS.VERTICAL}
                  onClick={onClose}
                />
              </div>
            </div>
          </nav>
        </div>
      </Modal>
    </div>
  );
});

export default ModalMenu;
