import React, { useState } from "react";
import PropTypes from "prop-types";
import Icon from "../../atoms/Icon";
import cs from "classnames";

import classes from "./Collapse.module.scss";

const Collapse = ({ header, body, showIcon, opened }) => {
  const [visible, setVisible] = useState(opened);
  return (
    <div className={cs(classes.collapseWrapper)}>
      <div className={cs(classes.collapseHeader)} onClick={() => setVisible(!visible)}>
        {showIcon && <Icon name={visible ? "arrow-up" : "arrow-down"} />}
        {header}
      </div>
      <div
        className={cs(
          classes.collapseBody,
          { [classes.collapseShown]: visible },
          { [classes.collapseHidden]: !visible }
        )}
      >
        {body}
      </div>
    </div>
  );
};

Collapse.propTypes = {
  header: PropTypes.element.isRequired,
  body: PropTypes.element.isRequired,
  showIcon: PropTypes.bool.isRequired,
  opened: PropTypes.bool.isRequired,
};

Collapse.defaultProps = {
  showIcon: true,
  opened: false,
};

export default Collapse;
