import React from "react";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";

const PageMetaTags = ({ videoDescription, videoTitle }) => {
  return (
    <Helmet>
      <meta charset="utf-8" />
      <link
        rel="icon"
        href="https://maor-git-test-json-maor.vercel.app/static/media/logotype-maor.f9038bfc.png"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta name="description" content={videoDescription} />

      <meta property="og:title" content={videoTitle} />
      <meta property="og:type" content="article" />
      <meta
        property="og:image"
        content="https://maor-git-test-json-maor.vercel.app/static/media/logotype-maor.f9038bfc.png"
      />
      <meta property="og:url" content="https://www.maor.org/" />
      <meta property="og:description" content={videoDescription} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={videoTitle} />
      <meta name="twitter:description" content={videoDescription} />
      <meta
        property="og:image"
        content="https://maor-git-test-json-maor.vercel.app/static/media/logotype-maor.f9038bfc.png"
      />

      <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
      <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
      <title>{videoTitle}</title>
    </Helmet>
  );
};

PageMetaTags.propTypes = {
  videoTitle: PropTypes.string,
  videoDescription: PropTypes.string,
};

PageMetaTags.defaultProps = {
  videoTitle: "",
  videoDescription: "",
};

export default PageMetaTags;
