import React, { useContext } from "react";
import { TYPES } from "../../atoms/Logotype/utilities";
import classes from "./SelectProject.module.scss";
import cs from "classnames";
import Logotype from "../../atoms/Logotype";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../../../contexts";
import useLibraryFilter from "../../pages/Library/useLibraryFilter";
import { useHistory } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../../constants";
// import scrollToTop from "../../../utilities/helpers/scrollToTop";

const SelectProject = observer(() => {
  const { common } = useContext(StoreContext);
  const history = useHistory();
  const { onNavigationChange } = useLibraryFilter();

  const handleProjectChange = (project) => {
    common.set("project", project);

    // window.scrollTo({
    //   top: 0,
    //   left: 0,
    // });

    switch (project) {
      case TYPES.MY_MAOR:
        history.push(APPLICATION_ROUTES.MY_MAOR_LIBRARY);
        break;
      case TYPES.ILLUMINATE:
        history.push(APPLICATION_ROUTES.ILLUMINATE_LIBRARY);
        break;
      case TYPES.DAILY_REBBE_VIDEO:
        history.push(APPLICATION_ROUTES.DAILY_REBBE_VIDEO_LIBRARY);
        break;
    }

    const event = {
      target: {
        href: location.href,
      },
    };

    onNavigationChange(event);

    common.set("selectProjectScreen", false);
  };

  return (
    <main className={cs("c-grey-dark text-m", classes.container)}>
      <div className={classes.sectionContainer}>
        <div className={classes.heading}>Select Video Library</div>

        <div className={classes.projects}>
          <div
            onClick={() => {
              handleProjectChange(TYPES.MY_MAOR);
            }}
            className={classes.item}
          >
            <div className={classes.image}>
              <Logotype url="#" size="chooseProjectSize" type={TYPES.MY_MAOR} />
            </div>
            <div className={classes.title}>MyMaor</div>
          </div>
          <div
            onClick={() => {
              handleProjectChange(TYPES.DAILY_REBBE_VIDEO);
            }}
            className={classes.item}
          >
            <div className={classes.image}>
              <Logotype
                url="#"
                size="chooseProjectSize"
                type={TYPES.DAILY_REBBE_VIDEO}
              />
            </div>
            <div className={classes.title}>Daily Rebbe Video</div>
          </div>
          <div
            onClick={() => {
              handleProjectChange(TYPES.ILLUMINATE);
            }}
            className={classes.item}
          >
            <div className={classes.image}>
              <Logotype
                url="#"
                size="chooseProjectSize"
                type={TYPES.ILLUMINATE}
              />
            </div>
            <div className={classes.title}>Illuminate</div>
          </div>
        </div>
      </div>
    </main>
  );
});

export default SelectProject;
