import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import Icon, { NAMES as ICON_NAMES, SIZES as ICON_SIZES } from "../Icon";
import { COLORS, SIZES } from "./utilities";
import classes from "./IconButton.module.scss";

const IconButton = ({
  color,
  iconName,
  iconSize,
  shadow,
  size,
  noBorder,
  ...properties
}) => (
  <button
    className={cs(
      classes.container,
      classes[color],
      classes[size],
      { [classes.shadow]: shadow },
      noBorder ? classes.noBorder : ""
    )}
    {...properties}
  >
    <Icon name={iconName} size={iconSize} />
  </button>
);

IconButton.propTypes = {
  color: PropTypes.oneOf(Object.values(COLORS)),
  iconName: PropTypes.oneOf(Object.values(ICON_NAMES)).isRequired,
  iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
  shadow: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZES)),
  noBorder: PropTypes.integer
};

IconButton.defaultProps = {
  color: COLORS.PRIMARY,
  iconSize: ICON_SIZES.MEDIUM,
  shadow: true,
  size: SIZES.MEDIUM,
};

export default IconButton;
