import React from 'react'
import cs from "classnames";
import classes from "./ColoringPageButton.module.scss";
import Icon, { NAMES } from '../Icon';
import PropTypes from "prop-types";

const ColoringPageButton = ({ pdfHandler, size }) => {
    return (
        <div className={cs(classes.previewButton, classes[size])} onClick={pdfHandler}>
            <Icon size="s" name={NAMES.PdfBlack} />
            Coloring Page
        </div>
    )
}

ColoringPageButton.propTypes = {
    pdfHandler: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired
};

export default ColoringPageButton;