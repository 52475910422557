import React from "react";

import cs from "classnames";
import classes from "./Member.module.scss";
import { observer } from "mobx-react-lite";
import AsyncAutocompleteSearch from "../../../../atoms/AsyncAutocompleteSearch";
import Icon from "../../../../atoms/Icon";
import useLibraryFilter from "../../../Library/useLibraryFilter";
import Modal from "../../../../organisms/Modal";
import Popup from "../../../../atoms/Popup";
import Player from "../../../../organisms/Player";
import { APPLICATION_ROUTES, STORAGE } from "../../../../../constants";
import { useModal } from "../../../../../hooks";
import MailingModal from "../../../../organisms/MailingModal/MailingModal";
import VideoContainer from "./components/VideoConatiner";
import useMyMaor from "../../../MyMaorMain/useMyMaor";
import { COLORS as PILL_COLORS } from "../../../../atoms/Pills/utilities";
import Pills from "../../../../atoms/Pills";

const Member = observer(() => {
  const { onNavigateToSearch } = useMyMaor();
  const video = useModal();
  const { search, searching, holiday, filterData } = useLibraryFilter();
  const videoNotificationPopup = useModal();

  let holidayOptions = [];
  if (
    filterData &&
    !filterData.holidaysToday.isLoading &&
    !filterData.holidaysToday.isError
  ) {
    holidayOptions =
      filterData.holidaysToday.data.length > 0
        ? filterData.holidaysToday.data
        : [];
    holidayOptions = holidayOptions.map((holidayOption) => {
      return {
        id: `holiday-${holidayOption.id}`,
        label: holidayOption.name_english,
        value: holidayOption.id,
      };
    });
  }
  holidayOptions = holidayOptions.slice(0, 2);

  return (
    <div className={cs(classes.container)}>
      <div className={cs(classes.topContainer)}>
        <div className={cs(classes.search)}>
          <AsyncAutocompleteSearch
            id="async-autocomplete-search"
            placeholder="Search Library"
            value={search.value}
            page="illuminate"
            onSearch={searching.onSearch}
            searchingOptions={searching.options}
            isSearching={searching.isSearching}
            onChange={(value) => {
              search.onChange(value);
              onNavigateToSearch(false, value, APPLICATION_ROUTES.ILLUMINATE_LIBRARY);
            }}
          >
            <Icon
              cursorPointer={true}
              onClick={() => {
                let searchValue = document.querySelector(".rbt-input-main")
                  .value;
                if (searchValue != "") {
                  search.onChange(searchValue);
                  onNavigateToSearch(false, searchValue, APPLICATION_ROUTES.ILLUMINATE_LIBRARY);
                }
              }}
              name="pink-search"
            />
          </AsyncAutocompleteSearch>
        </div>
        <section className={cs(classes.holidaySection, "mb-0")}>
          {holidayOptions.length != 0 && (
            <Pills
              color={PILL_COLORS.PURPLE}
              items={holidayOptions}
              value={holiday.value}
              onChange={holiday.onChange}
            />
          )}
        </section>
      </div>
      <div className={cs(classes.content, classes.wrapper)}>
        <VideoContainer />
      </div>

      <MailingModal popup={videoNotificationPopup} />

      <Modal opened={video.isOpened}>
        <Popup popup={video}>
          <Player video={STORAGE.FEATURE_VIDEO} />
        </Popup>
      </Modal>
    </div>
  );
});

export default React.memo(Member);
