import React from "react";
import PropTypes from "prop-types";

import Button from "../../../../atoms/Button";
import cs from "classnames";
import classes from "./FirstStep.module.scss";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../../../../constants";
import GoogleAuth from "../../../../organisms/GoogleAuth";
import { observer } from "mobx-react-lite";
import { useQuery } from "../../../../../hooks";

const FirstStep = observer(({ onSubmit }) => {
  const { t } = useTranslation();

  const query = useQuery();
  const redirectPageURL = query.get("redirect");

  const TEXT = (
    <Trans
      components={{
        a: (
          <Link
            className="c-orange"
            to={
              !redirectPageURL
                ? APPLICATION_ROUTES.SIGN_IN
                : APPLICATION_ROUTES.SIGN_IN + "?redirect=" + redirectPageURL
            }
          />
        ),
      }}
      i18nKey="pages.authorization.sign_up.text.1"
      t={t}
    />
  );

  const TITLE = t("pages.authorization.sign_up.title");

  // const TEXT_2 = <Trans components={{ a: <br />, b: <b /> }} i18nKey="pages.authorization.sign_up.text.2" t={t} />;

  return (
    <form className="d-flex fd-column ai-center" onSubmit={onSubmit}>
      <div className="mb-10">
        <h2 className="heading-m-a ta-center c-blue-dark">{TITLE}</h2>
      </div>

      <div className={cs(classes.signUpContainer, "mt-10")}>
        <div className={cs(classes.optionsContainer)}>
          <Button type="submit" borderRadius="8" size="m">
            Sign-up with Email
          </Button>
          <div className={cs(classes.orSeparator, "mt-05", "mb-05")}>
            <label>OR</label>
          </div>
          <div>
            <GoogleAuth />
          </div>
        </div>
      </div>
      <div className="mt-05 mb-10 w-100p fw-700 ta-center c-blue-dark">
        {TEXT}
      </div>
      <div className={cs("mt-10 ta-center", classes.lineHeight30)}>
        Use one account per household <br className={cs(classes.mobileBreak)} />{" "}
        to keep your info organized and useful.
        <a />
        <br />
        <br />
        <b>TIP</b>: Family members can be added{" "}
        <br className={cs(classes.mobileBreak)} /> at any time to your profile!
      </div>
    </form>
  );
});

const inputPropTypes = PropTypes.shape({
  error: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  touched: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}).isRequired;

FirstStep.propTypes = {
  email: inputPropTypes,
  isNotValid: PropTypes.bool.isRequired,
  lastName: inputPropTypes,
  firstName: inputPropTypes,
  referralCode: inputPropTypes,
  onSubmit: PropTypes.func.isRequired,
};

export default FirstStep;
