import { makeAutoObservable } from "mobx"
import { ACTIONS } from "./utilities"
import { DonationAPI } from "../../api"

const donationAPI = new DonationAPI()

const Donation = class {
    constructor({ store }) {
        const actions = Object.values(ACTIONS);

        for (let i = 0; i < actions.length; i++) {
            const ACTION = actions[i]

            this[`isLoading${ACTION}`] = false;
            this[`error${ACTION}`] = "";
        }

        this.store = store;
        this.details = null;
        this.pledgePartners = [];
        this.data = null;
        makeAutoObservable(this)
    }

    setIsLoading(name, value) {
        this[`isLoading${name}`] = value;
    }

    setError(name, value) {
        this[`error${name}`] = value;
    }

    get hasData() {
        return !!this.data; 
    }

    setValue(name, value) {
        this[name] = value;
    }

    get hasDetails() {
        return !!this.details;
    }

    get isActive() {
        if (this.hasDetails) {
            return "active" === this.details.status;
        }
        return false;
    }

    getDonation() {
        this.setIsLoading(ACTIONS.GET_DONATION, true);

        donationAPI
            .get()
            .then((response) => {
                if (response.status === 200) return response.json();
                throw new Error(response.status);
            })
            .then((data) => {
                this.setIsLoading(ACTIONS.GET_DONATION, false);
                this.setValue("details", {
                    id: data.id,
                    status: data.status,
                    transaction_id: data.transaction_id,
                    plan_type: data.plan_type,
                    plan: data.plan_name,
                    price: data.plan_price,
                    plan_id: data.plan_id,
                    interval: data.recurring_interval,
                    updated_at: data.updated_at,
                    next_payment_date: data.next_payment_date,
                    expiration_date: data.expiration_date,
                    outer_id: data.outer_id,
                    pledge_expiration_date: data.pledge_expiration_date,
                })
            })
            .catch(({ message }) => {
                this.setValue("details", null);
                this.setError(ACTIONS.GET_DONATION, message);
                this.setIsLoading(ACTIONS.GET_DONATION, false);
            });
    }

    getAllSubscription() {
        this.setIsLoading(ACTIONS.GET_ALL_SUBSCRIPTION, true);
        donationAPI.
          getAllSubscription()
          .then((response) => {
            if (response.status === 200) return response.json();
            throw new Error(response.status);
          })
          .then((data) => {
            this.setIsLoading(ACTIONS.GET_ALL_SUBSCRIPTION, false);
            console.log(data, "heelo")
            this.setValue("data", data)
          })
          .catch(({ message }) => {
            this.setValue("data", null);
            this.setError(ACTIONS.GET_ALL_SUBSCRIPTION, message);
            this.setIsLoading(ACTIONS.GET_ALL_SUBSCRIPTION, false);
          })
        }

    cancelDonation({donationId}) {
        this.setIsLoading(ACTIONS.CANCEL_DONATION, true);
        donationAPI
            .cancel({
                id: donationId,
            })
            .then((response) => {
                if (response.status === 200) return response.json();
                throw new Error(response.status);
            })
            .then(({ data }) => {
                this.setIsLoading(ACTIONS.CANCEL_DONATION, false);
                this.setValue("data", data);
                this.getAllSubscription();
            })
            .catch(({ message }) => {
                this.setError(ACTIONS.CANCEL_DONATION, message);
                this.setIsLoading(ACTIONS.CANCEL_DONATION, false);
            });
    }

    getDonationPartners({pledgeDonation}) {
        this.setIsLoading(ACTIONS.GET_DONATION_PARTNERS, true);
        donationAPI.getDonationPartners({pledgeDonation}).then((response) => {
            if (response.status === 200) return response.json();
            throw new Error(response.status)
        })
            .then((data) => {
                this.setValue("pledgePartners", data.data);
                this.setIsLoading(ACTIONS.GET_DONATION_PARTNERS, false);
            })
            .catch(({ message }) => {
                this.setError(ACTIONS.GET_DONATION_PARTNERS, message);
                this.setIsLoading(ACTIONS.GET_DONATION_PARTNERS, false)
            })
    }

    getIsLoading(value) {
        return this[`isLoading${value}`];
    }
}

export default Donation
