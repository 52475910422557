import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import classes from "./TagSelector.module.scss";

const TagSelector = ({ items, values, onChange }) => (
  <ul className={cs(classes.container)}>
    {items.map((item) => {
      const checked = values.includes(item.value);
      return (
        <li key={item.id} className={classes.item}>
          <input
            checked={checked}
            className={classes.input}
            id={item.id}
            type="checkbox"
            value={item.value}
            onChange={onChange}
          />
          <label htmlFor={item.id} className={classes.label}>
            {item.label}
          </label>
        </li>
      );
    })}
  </ul>
);

TagSelector.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TagSelector;
