import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import Icon, {
  NAMES as ICON_NAMES,
  SIZES as ICON_SIZES,
} from "../../../../atoms/Icon";
import classes from "./Arrow.module.scss";

const Arrow = ({
  className,
  rotated,
  style,
  onClick,
  arrowname = ICON_NAMES.ARROW_IN_CIRCLE,
}) => (
  <button
    className={cs(classes.container, className)}
    style={style}
    onClick={onClick}
  >
    <Icon name={arrowname} rotated={rotated} size={ICON_SIZES.M} />
  </button>
);

Arrow.propTypes = {
  className: PropTypes.string,
  rotated: PropTypes.bool,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
  arrowname: PropTypes.string,
};

Arrow.defaultProps = {
  className: "",
  rotated: false,
  // eslint-disable-next-line prettier/prettier
  onClick: () => {},
};

export default Arrow;
