const COLORS = {
  PRIMARY: "primary",
  GHOST: "ghost",
};

const SIZES = {
  XS: "xs",
  S: "s",
  M: "m",
  ASM: "asm",
};

export { COLORS, SIZES };
