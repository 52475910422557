import React, { useEffect } from "react";
import Library from "./Library";
import Protect from "react-app-protect";
import "react-app-protect/dist/index.css";

const LibraryProtected = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        let p = document.createElement("p");
        p.innerHTML = "The password was changed. Please contact Rabbi Chaskind to get the new password";
        p.classList.add("c-red");
        p.classList.add("mt-02");
        p.classList.add("ta-center");
        document.querySelector("input[type='password']").after(p);
      } catch (e) {
        console.error(e);
      }
    }, 700);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Protect
      boxTitle="Welcome to VIP access to our new library!"
      blur={true}
      styles={{
        wrapper: { border: "1px solid #e0e0e0" },
        boxButton: { width: "100%", textAlign: "center" },
        input: { width: "100%", padding: "10px 20px", border: "1px solid #e0e0e0" },
        header: { backgroundColor: "#ff8a34", color: "#fff", padding: "10px", textAlign: "center", fontSize: "1rem" },
        button: {
          backgroundColor: "#ff8a34",
          color: "#fff",
          borderStyle: "solid",
          borderRadius: "22px",
          borderColor: "transparent",
          fontSize: "1rem",
          padding: "5px 10px",
        },
      }}
      sha512="898d1d0c1ff53319a23da99546b2e942ddd18e8c84974fdcafca51906042aeede2b28615f75b85c318748e21834461aab42f93eada4372faa0b6cd07914cc2ee"
    >
      <Library />
    </Protect>
  );
};

export default LibraryProtected;
