/* eslint-disable no-undef */

const getMode = () => {
  const isProduction = window.location.host == "maor.org" ||
    window.location.host == "www.maor.org" ||
    window.location.host == "mobile.maor.org";
  return {
    isDevelopment: !isProduction,
    isProduction: isProduction
  };
};

export default getMode;
