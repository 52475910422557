import { observer } from "mobx-react-lite";
import React from "react";

import cs from "classnames";
import classes from "./OurImpact.module.scss";
import Button from "../../../../atoms/Button";
import Feature from "./components/Features";
import Icon from "../../../../atoms/Icon";
import { useHistory } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../../../../constants";
import { scrollToTop } from "../../../../../utilities/helpers";
import { useModal } from "../../../../../hooks";
import Modal from "../../../../organisms/Modal";
import Popup from "../../../../atoms/Popup";
import Player from "../../../../organisms/Player";

const OurImpact = observer(() => {
  const history = useHistory();
  const video = useModal();

  const navigateToDonatePage = () => {
    history.push(APPLICATION_ROUTES.PARTNERS_DONATION);
    scrollToTop();
  };

  const handleVideoPlay = () => {
    video.onOpen();
  };

  return (
    <div className={cs(classes.container, "wrapper-m")}>
      <div className={cs(classes.headingContainer)}>
        <h3 className={cs(classes.browserText)}>Our Impact</h3>
        <h3 className={cs(classes.mobileText)}>Our Impact</h3>
        {/* <h3 className={cs(classes.mobileText)}>Maors Impact</h3> */}
        <p>
          Maor Is Driven by a Mission and Made Available to Your Family at No
          Cost.
        </p>
      </div>

      <div className={cs(classes.centerContainer)}>
        <div className={cs(classes.featuresContainer)}>
          <Feature />
        </div>

        <div className={cs(classes.videoContainer, "bgc-white")}>
          <Icon
            onClick={handleVideoPlay}
            name="play-icon"
            size="xxl"
            className={cs(classes.pointer)}
          />
        </div>
      </div>

      <div className={cs(classes.btnWrap)}>
        <Button onClick={navigateToDonatePage}>Support Maor</Button>
      </div>

      <Modal opened={video.isOpened}>
        <Popup popup={video}>
          <Player video="575115468" />
        </Popup>
      </Modal>
    </div>
  );
});

export default React.memo(OurImpact);
