import { useCallback, useState } from "react";
import * as Hebcal from "hebcal";

import { getDays, getHebrewDays } from "./utilities";
import useCheckbox from "../useCheckbox";
// import { addDates } from "jewish-dates-core";

const useDatepicker = ({
  hebrew = false,
  label = "",
  promptSunsetCheck = false,
  bornAfterSunset = false,
  value: initialValue = new Date(),
  useHeTranslation = false,
} = {}) => {
  const [isHebrewMode, setIsHebrewMode] = useState(hebrew);
  const [value, setValue] = useState(initialValue);

  const days = isHebrewMode ? getHebrewDays(value) : getDays(value);

  const toggleMode = useCallback(() => {
    setIsHebrewMode((state) => !state);
  }, []);

  const setDay = useCallback((value) => {
    setValue(() => {
      const date = new Date(value);
      return date;
    });
  }, []);

  const decrementMonth = useCallback(() => {
    setValue((value) => {
      const date = new Date(value);
      date.setMonth(date.getMonth() - 1, 1);

      return date;
    });
  }, []);

  const decrementMonthHebrew = useCallback(() => {
    setValue((value) => {
      const hDate = new Hebcal.HDate(value);

      if (hDate.getMonth() === 1 && hDate.getYearObject().prev().isLeapYear()) {
        /* Note: Library does not return last month of previous year if it is leap year */

        hDate.setDate(1);
        hDate.setFullYear(hDate.getFullYear() - 1);
        hDate.setMonth(13);
      } else {
        hDate.setDate(1);
        hDate.setMonth(hDate.getMonth() - 1);
      }

      const date = hDate.greg();

      return date;
    });
  }, []);

  const incrementMonth = useCallback(() => {
    setValue((value) => {
      const date = new Date(value);
      date.setMonth(date.getMonth() + 1, 1);

      return date;
    });
  }, []);

  const incrementMonthHebrew = useCallback(() => {
    setValue((value) => {
      const hDate = new Hebcal.HDate(value);
      if (hDate.getMonth() === 12 && !hDate.isLeapYear()) {
        /* Note: Library does not return next year after last month of non leap year */

        hDate.setDate(1);
        hDate.setMonth(1);
        hDate.setFullYear(hDate.getFullYear() + 1);
      } else {
        hDate.setDate(1);
        hDate.setMonth(hDate.getMonth() + 1);
      }

      const date = hDate.greg();

      return date;
    });
  }, []);

  const decrementYear = useCallback(() => {
    setValue((value) => {
      const date = new Date(value);
      date.setFullYear(date.getFullYear() - 1, date.getMonth(), 1);

      return date;
    });
  }, []);

  const decrementYearHebrew = useCallback(() => {
    setValue((value) => {
      const hDate = new Hebcal.HDate(value);
      hDate.setFullYear(hDate.getFullYear() - 1);
      const date = hDate.greg();

      return date;
    });
  }, []);

  const incrementYear = useCallback(() => {
    setValue((value) => {
      const date = new Date(value);
      date.setFullYear(date.getFullYear() + 1, date.getMonth(), 1);

      return date;
    });
  }, []);

  const incrementYearHebrew = useCallback(() => {
    setValue((value) => {
      const hDate = new Hebcal.HDate(value);
      hDate.setFullYear(hDate.getFullYear() + 1);
      const date = hDate.greg();

      return date;
    });
  }, []);

  const bornAfterSunsetCheckbox = useCheckbox({
    checked: bornAfterSunset,
  });

  const checkLeapYear = (year) => {
    return (0 == year % 4 && 0 != year % 100) || 0 == year % 400;
  };

  return {
    label: label,
    days: days,
    hebrew: isHebrewMode,
    month: isHebrewMode ? new Hebcal.HDate(value).getMonth() : value.getMonth(),
    value: value,
    isLeapYear: isHebrewMode ? new Hebcal.HDate(value).isLeapYear() : checkLeapYear(value.getFullYear()),
    year: isHebrewMode ? new Hebcal.HDate(value).getFullYear() : value.getFullYear(),
    toggleMode: toggleMode,
    setDay: setDay,
    decrementMonth: isHebrewMode ? decrementMonthHebrew : decrementMonth,
    incrementMonth: isHebrewMode ? incrementMonthHebrew : incrementMonth,
    decrementYear: isHebrewMode ? decrementYearHebrew : decrementYear,
    incrementYear: isHebrewMode ? incrementYearHebrew : incrementYear,
    promptSunsetCheck: promptSunsetCheck,
    bornAfterSunset: bornAfterSunsetCheckbox.checked,
    bornAfterSunsetCheckbox: bornAfterSunsetCheckbox,
    useHeTranslation: useHeTranslation,
  };
};

export default useDatepicker;
