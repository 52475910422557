import React from 'react';
import Icon, { NAMES } from '../Icon';
import cs from "classnames";
import classes from "./ShareButton.module.scss"
import PropTypes from "prop-types";

const ShareButton = ({ sharePage, size = "s" }) => {
    return (
        <div className={cs(classes.previewButton, classes[size])} onClick={sharePage}>
            <Icon size={size} name={NAMES.ShareArrow} />
            Share
        </div>
    )
}

ShareButton.propTypes = {
    sharePage: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired
};

export default ShareButton;