const ACTIONS = {
  CREATE_TRANSACTION: "CreateTransaction",
  UPDATE_TRANSACTION: "UpdateTransaction",
  GET_TOKEN: "GetToken",
  GET_TRANSACTION: "GetTransaction",
  INITIALIZE_PAYMENT_METHOD: "InitializePaymentMethod",
  REQUEST_PAYLOAD_METHOD: "RequestPayloadMethod",
  SALE: "Sale",
};

export { ACTIONS };
