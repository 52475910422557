import React from "react";
import PropTypes from "prop-types";

import classes from "./OptionalInput.module.scss";

const OptionalInput = ({ id, label, ...properties }) => (
  <div className={classes.container}>
    {label && (
      <label htmlFor={id} className={classes.label}>
        {label}
      </label>
    )}
    <input id={id} className={classes.input} {...properties} />
  </div>
);

OptionalInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

OptionalInput.defaultProps = {
  label: "",
};

export default OptionalInput;
