import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import ShareButton from "../../../../atoms/ShareButton";
import ColoringPageButton from "../../../../atoms/ColoringPageButton";
import SubmitAnswerButton from "../../../../atoms/SubmitAnswerButton";
import { StoreContext } from "../../../../../contexts";
import {
  getDetails,
  getURL,
} from "../../../Library/components/Project/components/Preview/utilities";
import { APPLICATION_ROUTES } from "../../../../../constants";
import cs from "classnames";
import classes from "./VideoButtons.module.scss";

const VideoButtons = observer(
  ({
    locales,
    categories,
    questions,
    tags,
    file,
    holiday,
    internal_id,
    onSelect,
    videoAction,
  }) => {
    const { playlist, authorization } = useContext(StoreContext);

    const submitAnswer = (e) => {
      playlist.setCurrentPlayingVideoId(details.id);
      onSelect({
        end: details.end_time,
        start: details.start_time,
        url: getURL(details.link, { start: details.start_time }),
        name: details.name,
        description: details.description,
        number: details.number,
        categories: categories,
        questions: questions,
        tags: tags,
        holiday: holiday,
        date: details.created_at,
        file: file,
      });
      videoAction.showQuestionScreen(e);
    };

    const sharePage = async () => {
      try {
        await navigator.share({
          url: window.location.origin + "/" + details.video_url,
          title: details.title,
        });
      } catch (e) {
        console.error(e);
      }
    };

    const details = getDetails("en", locales);

    const downloadFile = (url) => {
      window.open(url);
    };

    const pdfHandler = () => {
      if (authorization.isAuthenticated) {
        downloadFile(file);
      } else {
        if (!location.pathname.includes(APPLICATION_ROUTES.LIBRARY_PROECTED)) {
          playlist.setCurrentPlayingVideoId(details.id);
          onSelect({
            end: details.end_time,
            start: details.start_time,
            url: getURL(details.link, {
              start: details.start_time,
            }),
            name: details.name,
            description: details.description,
            number: details.number,
            categories: categories,
            questions: questions,
            tags: tags,
            holiday: holiday,
            date: details.created_at,
            file: details.file,
            internal_id: internal_id,
          });
        }
      }
    };

    return (
      <div className={cs(classes.previewButtons)}>
        <ShareButton sharePage={sharePage} />
        {file && <ColoringPageButton pdfHandler={pdfHandler} />}
        <SubmitAnswerButton submitAnswer={submitAnswer} />
      </div>
    );
  }
);

export default VideoButtons;
