import { nanoid } from "nanoid";

const ATTRIBUTES = {
  SELECT: "data-cr-select",
};

const COLORS = {
  PRIMARY: "primary",
};

const SIZES = {
  MEDIUM: "medium",
};

const createID = () => {
  return nanoid();
};

export { ATTRIBUTES, COLORS, SIZES, createID };
