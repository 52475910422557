const getInitials = ({ id, items }) => {
  const defaultValues = {
    delivery: {
      city: "",
      state: "",
      street: "",
      country: "",
      apartment: "",
      zip_code: "",
      is_default: false
    },
  };

  if (!id) return defaultValues;

  const item = items.find((item) => item.item_id == id);

  if (!item) return defaultValues;

  let data = {
    delivery: { ...item.data.delivery },
  };

  return data;
};

export { getInitials };
