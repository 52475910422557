import React from "react";
import Icon, { NAMES, SIZES } from "../../atoms/Icon";
import classes from "./Partners.module.scss";
import { isMobile } from "react-device-detect";

const cards = [
  {
    logoName: NAMES.PartnersDiscount,
    title: "Partner Discounts",
    description:
      "The Maor Partner exclusive card unlocks incredible discounts at your favorite stores, ensuring you enjoy unparalleled savings and benefits!<br> A small donation to Maor's important work can save you over $2000 a year!",
    color: "darkYellow",
    backgroundTitle: (
      <>
        <div className={classes.cardBackInfo}>
          <Icon name={NAMES.PartnersDiscount} />
          <p>Partner</p>
        </div>
        <p>Discounts</p>
      </>
    ),
  },
  {
    logoName: NAMES.VideoLibrary,
    title: "Video Library",
    description:
      "With over 1000 MyMaor videos, there's always something new and exciting for kids of all ages.<br/><br/>Bring the excitement of Yiddishkeit and Chassidishkeit into your home with MyMaor videos.",
    color: "yellow",
    backgroundTitle: (
      <>
        <div className={classes.cardBackInfo}>
          <Icon  size={!isMobile ? SIZES.L : SIZES.M} name={NAMES.VideoLibrary} />
          <p>Video Library</p>
        </div>
      </>
    ),
  },
  {
    logoName: NAMES.HeadphoneIcon,
    title: "Audio Stories",
    description:
      "Need a bedtime boost or travel entertainment?<br/> Join Yanki and Mendy on their epic Shlichus Adventures!<br/> Together, you'll meet the Alter Rebbe, learn about electricity has to do with Moshiach, and much more!",
    color: "blue",
    backgroundTitle: (
      <>
        <div className={classes.cardBackInfo}>
          <Icon size={!isMobile ? SIZES.L : SIZES.M} name={NAMES.HeadphoneIcon} />
          <p>Audio Stories</p>
        </div>
      </>
    ),
  },
  {
    logoName: NAMES.ColoringIcon,
    title: "Coloring Pages",
    description:
      "Over 180 printable coloring pages.  <br /> <br /> Enhance your child's connection to the stories by coloring characters and scenes from the videos, providing hours of entertainment.",
    color: "green",
    backgroundTitle: (
      <>
        <div className={classes.cardBackInfo}>
          <Icon  size={!isMobile ? SIZES.L : SIZES.M} name={NAMES.ColoringIcon} />
          <p className={classes.noWrap}>Coloring Pages</p>
        </div>
      </>
    ),
  },
  {
    logoName: NAMES.BirthdayCelebration,
    title: "Birthday Celebrations",
    description:
      "Imagine your child's excitement when they hear their name in a MyMaor video, making their birthday unforgettable.<br/><br/>Choose one of our birthday packages to celebrate their special day.",
    color: "pink",
    backgroundTitle: (
      <>
        <div className={classes.cardBackInfo}>
          <Icon name={NAMES.BirthdayCelebration} />
          <p>Birthday</p>
        </div>
        <p>Celebrations</p>
      </>
    ),
  },
];

const businessCards = [
  {
    logoName: NAMES.PartnersDiscount,
    title: "Holesome Bagels",
    description: "The Maor Partner exclusive card unlocks incredible",
    offer: "10% off",
    explaination: "Bar Mitzva Tefillin",
  },
  {
    logoName: NAMES.VideoLibrary,
    title: "Video Library",
    description:
      "With over 1000 MyMaor videos, there’s always something new and OK.",
    offer: "10% off",
    explaination: "Your partner",
  },
  {
    logoName: NAMES.HeadphoneIcon,
    title: "Audio Stories",
    description: "Need a bedtime boost or travel entertainment? <br />",
    offer: "10% off",
    explaination: "Your partner",
  },
  {
    logoName: NAMES.ColoringIcon,
    title: "Coloring Pages",
    description:
      "Over 180 printable coloring pages.  <br /> <br /> Enhance your child's",
    offer: "10% off",
    explaination: "Your partner",
  },
  {
    logoName: NAMES.BirthdayCelebration,
    title: "Birthday Celebrations",
    description: "Imagine your child's excitement when they hear their name.",
    offer: "10% off",
    explaination: "Your partner",
  },
  {
    logoName: NAMES.PartnersDiscount,
    title: "Partner Discounts",
    description: "The Maor Partner exclusive card unlocks incredible",
    offer: "10% off",
    explaination: "Your partner",
  },
  {
    logoName: NAMES.PartnersDiscount,
    title: "Partner Discounts",
    description: "The Maor Partner exclusive card unlocks incredible",
    offer: "10% off",
    explaination: "Your partner",
  },
  {
    logoName: NAMES.PartnersDiscount,
    title: "Partner Discounts",
    description: "The Maor Partner exclusive card unlocks incredible",
    offer: "10% off",
    explaination: "Your partner",
  },
  {
    logoName: NAMES.PartnersDiscount,
    title: "Partner Discounts",
    description: "The Maor Partner exclusive card unlocks incredible",
    offer: "10% off",
    explaination: "Your partner",
  },
  {
    logoName: NAMES.PartnersDiscount,
    title: "Partner Discounts",
    description: "The Maor Partner exclusive card unlocks incredible",
    offer: "10% off",
    explaination: "Your partner",
  },
  {
    logoName: NAMES.PartnersDiscount,
    title: "Partner Discounts",
    description: "The Maor Partner exclusive card unlocks incredible",
    offer: "10% off",
    explaination: "Your partner",
  },
];

export { cards, businessCards };
