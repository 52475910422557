import React from "react";
import { useTranslation } from "react-i18next";
import cs from "classnames";
import PropTypes from "prop-types";

import classes from "./NotificationButtons.module.scss";
import Icon from "../../atoms/Icon";
import { useModal } from "../../../hooks";
import { STORAGE } from "../../../constants";
import MailingModal from "../MailingModal/MailingModal";
import { isMobile } from "react-device-detect";

const NotificationButtons = ({ page }) => {
  const { t } = useTranslation();
  const videoNotificationPopup = useModal();
  let mailinglistID;

  const addClasses = () => {
    if (page == "illuminate") {
      mailinglistID = 3;
      return classes.illuminate;
    } else if (page == "daily-rebbe-video") {
      mailinglistID = 2;
      return classes.dailyRebbeVideo;
    } else {
      mailinglistID = 1;
    }

    return classes[page];
  };

  const openWhatsapp = () => {
    if (page == "illuminate") {
      window.open(STORAGE.WHATSAPP_GROUP_LINK_ILLUMINATE, "_blank");
    }
    if (page == "daily-rebbe-video") {
      window.open(STORAGE.WHATSAPP_GROUP_LINK_DRV, "_blank");
    }
    window.open(STORAGE.WHATSAPP_GROUP_LINK, "_blank");
  };

  return (
    <>
      <div className={cs(addClasses())}>
        <div
          className={cs(
            "mb-02 ta-center",
            isMobile && "mt-05",
            classes.heading
          )}
        >
          Get a notification when a new video is published:
        </div>
      </div>
      <div className={classes.subscribeMailingAction}>
        <button
          className={classes.popupButton}
          onClick={() => {
            openWhatsapp();
          }}
        >
          {" "}
          <Icon size="xs" name="whatsapp" />
          {t("pages.mymaor.labels.2")}
        </button>
        <button
          className={classes.popupButton}
          onClick={() => {
            videoNotificationPopup.onOpen();
          }}
        >
          {" "}
          <Icon size="xs" name="letter" />
          {t("pages.mymaor.labels.1")}
        </button>
      </div>

      <MailingModal
        popup={videoNotificationPopup}
        mailinglistID={mailinglistID}
      />
    </>
  );
};

NotificationButtons.propTypes = {
  page: PropTypes.string,
  mailinglistID: PropTypes.number,
};

NotificationButtons.defaultProps = {
  page: "",
};

export default NotificationButtons;
