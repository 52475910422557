import validate from "./validate";

const createState = ({ value, validators }) => {
  const errors = validate({ value, validators });

  return {
    errors: errors,
    touched: false,
    value: value,
  };
};

export default createState;
