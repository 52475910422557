import { useLocation } from "react-router-dom";

const useQuery = () => {
  const location = useLocation();
  const parameters = new URLSearchParams(location.search);

  return parameters;
};

export default useQuery;  
