import React, { useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Button from "../../../../atoms/Button";
import PasswordInput from "../../../../atoms/PasswordInput";
import PhoneInput from "../../../../atoms/PhoneInput";
// import PlainInput from "../../../../atoms/PlainInput";
// import RadioTabs from "../../../../atoms/RadioTabs";
import { APPLICATION_ROUTES } from "../../../../../constants";
import { StoreContext } from "../../../../../contexts";
import { ACTIONS } from "../../../../../store";
import PlainInput from "../../../../atoms/PlainInput";
// import Icon from "../../../../atoms/Icon";

const SecondStep = ({
  email,
  firstName,
  lastName,
  error,
  isNotValid,
  password,
  phone,
  setStepIndex,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { authorization } = useContext(StoreContext);

  const LABEL_SUBMIT = t("pages.authorization.sign_up.labels.submit_2");
  const BACK = t("forms.labels.back");

  const TEXT = (
    <Trans
      components={{
        a: <Link className="c-orange" to={APPLICATION_ROUTES.PRIVACY_POLICY} />,
        b: (
          <Link
            className="c-orange"
            to={APPLICATION_ROUTES.DONATION_REFUND_POLICY}
          />
        ),
      }}
      i18nKey="pages.authorization.sign_up.text.3"
      t={t}
    />
  );

  const TITLE = t("pages.authorization.sign_up.title");

  return (
    <form className="d-flex fd-column ai-center w-100p" onSubmit={onSubmit}>
      <div className="">
        <h2 className="heading-m-a ta-center c-blue-dark">{TITLE}</h2>
      </div>

      <div className="w-100p mt-10" style={{ maxWidth: "320px" }}>
        <PlainInput
          error={firstName.error}
          id="sign-up-form-first-name"
          label={firstName.label}
          value={firstName.value}
          onChange={firstName.onChange}
        />
      </div>
      <div className="mt-06 w-100p" style={{ maxWidth: "320px" }}>
        <PlainInput
          error={lastName.error}
          id="sign-up-form-last-name"
          label={lastName.label}
          value={lastName.value}
          onChange={lastName.onChange}
        />
      </div>
      <div className="mt-06 w-100p" style={{ maxWidth: "320px" }}>
        <PlainInput
          error={email.error}
          id="sign-up-form-email"
          label={email.label}
          value={email.value}
          onChange={email.onChange}
          type="email"
        />
      </div>

      <div className="mt-06 w-100p" style={{ maxWidth: "320px" }}>
        <PhoneInput
          error={phone.error}
          value={phone.value}
          onChange={phone.onChange}
        />
      </div>
      {/* <div className="mt-06 w-100p" style={{ maxWidth: "320px" }}>
        <PlainInput
          error={referralCode.error}
          id="sign-up-form-referral-code"
          label={referralCode.label}
          value={referralCode.value}
          onChange={referralCode.onChange}
        />
      </div> */}
      <div className="w-100p mt-06" style={{ maxWidth: "320px" }}>
        <PasswordInput
          error={password.errorOfOriginal}
          id="sign-up-form-password"
          label={password.labelOfOriginal}
          value={password.valueOfOriginal}
          onChange={password.onChangeOriginal}
        />
      </div>
      <div className="mt-06 w-100p" style={{ maxWidth: "320px" }}>
        <PasswordInput
          error={password.errorOfConfirm}
          id="sign-up-form-password-repeat"
          label={password.labelOfConfirm}
          value={password.valueOfConfirm}
          onChange={password.onChangeConfirm}
        />
      </div>
      {/* {!!firstName.value && (
        <div className="mt-06 w-100p" style={{ maxWidth: "320px" }}>
          <PlainInput
            error={firstName.error}
            id="sign-up-form-first-name"
            label={firstName.label}
            value={firstName.value}
            onChange={firstName.onChange}
          />
        </div>
      )}
      {!!firstName.value && (
        <div className="mt-06 w-100p" style={{ maxWidth: "320px" }}>
          <RadioTabs items={ageStatus.options} value={ageStatus.value} onChange={ageStatus.onChange} />
        </div>
      )} */}

      <div className="d-flex mt-07 gap-5">
        <Button
          color="ghost"
          onClick={() => {
            authorization.clearError(ACTIONS.AUTHORIZATION.SIGN_UP);
            setStepIndex(0);
          }}
        >
          <div>{BACK}</div>
        </Button>

        <Button disabled={isNotValid} type="submit">
          {LABEL_SUBMIT}
        </Button>
      </div>
      {error && (
        <div
          className="w-100p c-red fw-700 mt-06 mb-04"
          style={{ maxWidth: "320px", textAlign: "center" }}
        >
          {error}
          {authorization.errorSignUp == "duplicate_email" ? (
            <NavLink
              className="c-orange td-none ml-01"
              to={APPLICATION_ROUTES.SIGN_IN}
            >
              Sign In
            </NavLink>
          ) : null}
        </div>
      )}
      <div className="mt-04 ta-center">{TEXT}</div>
    </form>
  );
};

const doubleInputPropTypes = PropTypes.shape({
  errorOfOriginal: PropTypes.string,
  errorOfConfirm: PropTypes.string,
  errorsOfOriginal: PropTypes.arrayOf(PropTypes.string).isRequired,
  errorsOfConfirm: PropTypes.arrayOf(PropTypes.string).isRequired,
  labelOfOriginal: PropTypes.string.isRequired,
  labelOfConfirm: PropTypes.string.isRequired,
  touchedOriginal: PropTypes.bool.isRequired,
  touchedConfirm: PropTypes.bool.isRequired,
  valueOfOriginal: PropTypes.string.isRequired,
  valueOfConfirm: PropTypes.string.isRequired,
  onChangeOriginal: PropTypes.func.isRequired,
  onChangeConfirm: PropTypes.func.isRequired,
}).isRequired;

const inputPropTypes = PropTypes.shape({
  error: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  touched: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}).isRequired;

const phoneInputProps = PropTypes.shape({
  error: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  touched: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}).isRequired;

const radioPropTypes = PropTypes.shape({
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}).isRequired;

SecondStep.propTypes = {
  ageStatus: radioPropTypes,
  error: PropTypes.string,
  firstName: inputPropTypes,
  isNotValid: PropTypes.bool.isRequired,
  password: doubleInputPropTypes,
  phone: phoneInputProps,
  onSubmit: PropTypes.func.isRequired,
  setStepIndex: PropTypes.func.isRequired,
  email: inputPropTypes,
  lastName: inputPropTypes,
  referralCode: inputPropTypes,
};

export default SecondStep;
