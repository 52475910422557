import { observer } from "mobx-react-lite";
import React from "react";
import cs from "classnames";
import classes from "./PartnersPageMain.module.scss";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { APPLICATION_ROUTES, PAGE_TITLES } from "../../../constants";
import { useModal } from "../../../hooks";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import PageTitle from "../../atoms/PageTitle";
import Modal from "../../organisms/Modal";
import Layout from "../../templates/Layout";
import Banner from "./components/Banner/Banner";
import ExploreLibrary from "./components/ExploreLibrary/ExploreLibrary";
import Testimonial from "./components/Testimonial/Testimonial";
import OurImpact from "./components/OurImpact/OurImpact";
import OurMethod from "./components/OurMethod";
import OurStory from "./components/OurStory";
import GetInvolved from "./components/GetInvolved";
import Featured from "./components/Featured";
import { scrollToTop } from "../../../utilities/helpers";
import OurTeam from "./components/OurTeam/OurTeam";

const PartnersPageMain = observer(() => {
  const { t } = useTranslation();
  const modal = useModal();

  const history = useHistory();

  const onClickOverlay = (event) => {
    if (event.target.matches(`[data-name="video-overlay"]`)) modal.onClose();
  };

  const navigateToLibrary = () => {
    history.push(APPLICATION_ROUTES.LIBRARY);
    scrollToTop();
  };

  return (
    <>
      <PageTitle value={PAGE_TITLES.PARTNERS_MAIN_HOME} />
      <Layout logotype="childSrc">
        <div className="text-m-a">
          <section className={cs(classes.bannerContainer)}>
            <Banner />
          </section>

          <section className={cs(classes.exploreLibraryContainer, "bgc-gold")}>
            <ExploreLibrary />
          </section>

          <section className={cs(classes.testimonialsContainer, "bgc-white")}>
            <Testimonial />
          </section>

          <section className={cs(classes.ourImpactContainer, "bgc-gold")}>
            <OurImpact />
          </section>

          <section className={cs(classes.ourMethodContainer, "bgc-white")}>
            <OurMethod />
          </section>

          <section className={cs(classes.ourStoryContainer, "bgc-gold")}>
            <OurStory />
          </section>

          <section className={cs(classes.getInvolvedContainer, "bgc-white")}>
            <GetInvolved />
          </section>

          <section className={cs(classes.ourTeamContainer, "bgc-gold")}>
            <OurTeam />
          </section>

          <section className={cs(classes.featuredContainer, "bgc-white")}>
            <Featured />
          </section>


        </div>

        <Modal opened={modal.isOpened} style={modal.style}>
          <div
            data-name="video-overlay"
            className={classes.overlay}
            role="button"
            onClick={onClickOverlay}
          >
            <div className={classes.card}>
              <div
                onClick={() => {
                  modal.onClose();
                }}
                className={classes.closeContainer}
              >
                <Icon size="xs" name="x" cursorPointer={true} />
              </div>
              <h4 className={classes.title}>{t("pages.library.titles.1")}</h4>
              <div className={classes.navigation}>
                <Button onClick={navigateToLibrary}>
                  {t("pages.partners.main.sections.packages.titles.2")}!
                </Button>
              </div>
              <p className={classes.text}>{t("pages.library.texts.3")}</p>
            </div>
          </div>
        </Modal>
      </Layout>
    </>
  );
});

PartnersPageMain.displayName = 'PartnersPageMain';
export default React.memo(PartnersPageMain);


