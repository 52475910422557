import React, { useContext } from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import Logotype, { NAMES, SIZES } from "../../atoms/Logotype";
import CampaignBanner from "../../organisms/CampaignBanner";
import LinearMenu from "./components/LinearMenu";
import ModalMenu from "./components/ModalMenu";
import classes from "./Header.module.scss";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../../constants";
// import Button from "../../atoms/Button";
import { StoreContext } from "../../../contexts";
import { observer } from "mobx-react-lite";
// import ReferralBanner from "../ReferralBanner";
// import PromotionalBanner from "../PromotionalBanner/PromotionalBanner";
// import Cookies from "../../../utilities/cookies";

const Header = observer(({ logotype, size }) => {
  const { t } = useTranslation();
  const { authorization } = useContext(StoreContext);

  // const onSignOut = () => {
  //   Cookies.delete({ name: COOKIES.AUTO_SIGNIN });
  //   authorization.signOut();
  // };

  return (
    <div className={classes.container}>
      {!authorization.isUserMember ? (
        <div className={cs(classes.banner, "wrapper-xl")}>
          <CampaignBanner />
        </div>
      ) : null}
      <div className={cs(classes.banner)}>
        {/* <PromotionalBanner /> */}
        {/* <ReferralBanner /> */}
      </div>
      <div className={cs(classes.boxShadow, "bgc-white")}>
        <div className={cs(classes.bar)}>
          <div className={classes.logotype}>
            <Logotype type={logotype} size={size} />
          </div>
          <div className={classes.desktop}>
            <LinearMenu />
          </div>
          <div className={classes.mobile}>
            <>
              <div className={classes.links}>
                <NavLink
                  activeClassName={classes.active}
                  exact={false}
                  className={classes.link}
                  to={APPLICATION_ROUTES.LIBRARY}
                >
                  {t("components.molecules.global_navigation.items.5")}
                </NavLink>
              </div>
            </>
            <div>
              <ModalMenu />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

Header.propTypes = {
  logotype: PropTypes.oneOf(Object.values(NAMES)),
  size: PropTypes.oneOf([...Object.values(SIZES), ""]),
};

export default Header;
