/* eslint-disable react/prop-types */

import React from "react";
import { Range, getTrackBackground } from "react-range";

import classes from "./Ranger.module.scss";

const Ranger = ({ labels, min, max, step, rtl, values, onChange }) => {
  return (
    <div className={classes.container}>
      <Range
        values={values}
        step={step}
        min={min}
        max={max}
        rtl={rtl}
        onChange={onChange}
        renderTrack={({ props, children }) => (
          <div className={classes.ranger} onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart}>
            <div
              ref={props.ref}
              style={{
                background: getTrackBackground({
                  values,
                  colors: ["#FF8A34", "#F6F6F6"],
                  min: min,
                  max: max,
                  rtl,
                }),
              }}
              className={classes.track}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => <div {...props} className={classes.thumb} />}
      />
      {!!labels.length && (
        <ul className={classes.labels}>
          {labels.map((item) => (
            <li className={classes.item} key={item}>
              <div className={classes.title}>{item}</div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

Ranger.defaultProps = {
  labels: [],
  min: 1,
  max: 10,
  rtl: false,
};

export default Ranger;
