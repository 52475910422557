import ErrorAPI from "../../api/error";

const errorAPI = new ErrorAPI();

class Error {
  notifyError({ error }) {
    errorAPI.notifyError({ error });
  }
}

export default Error;
