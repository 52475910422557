import { nanoid } from "nanoid";

import { ANGLES, ATTRIBUTES, COLORS, POSITIONS, SIZES } from "./constants";

const createID = () => {
  return nanoid();
};

const getAngle = (value) => {
  switch (value) {
    case POSITIONS.RIGHT_TOP_OUT:
      return ANGLES.BOTTOM_RIGHT;
    default:
      return ANGLES.WITHOUT;
  }
};

export { ANGLES, ATTRIBUTES, COLORS, POSITIONS, SIZES, createID, getAngle };
