import childSrc from "../../../assets/images/logotype-maor-child.png";
import dailyRebbeVideoSrc from "../../../assets/images/logotype-project-daily-rebbe-video.png";
import defaultSrc from "../../../assets/images/logotype-maor.png";
import myMaorSrc from "../../../assets/images/logotype-maor-child.png";
import partnersSrc from "../../../assets/images/logotype-maor-partners.png";
import maorPledge from "../../../assets/images/maor-pledge-logo.png"

import illuminateSrc from "../../../assets/images/newilluminate.png";

const SOURCES = {
  CHILD: childSrc,
  DAILY_REBBE_VIDEO: dailyRebbeVideoSrc,
  DEFAULT: defaultSrc,
  ILLUMINATE: illuminateSrc,
  MY_MAOR: myMaorSrc,
  PARTNERS: partnersSrc,
  MAOR_PLEDGE : maorPledge,
};

const SIZES = {
  SMALL: "s",
  MEDIUM: "m",
  LARGE: "l",
  CUSTOM: "c",
};

const TYPES = {
  CHILD: "child",
  DAILY_REBBE_VIDEO: "daily-rebbe-video",
  DEFAULT: "default",
  ILLUMINATE: "illuminate",
  MY_MAOR: "my-maor",
  PARTNERS: "partners",
  MAOR_PLEDGE : "maor-pledge",
};

const NAMES = TYPES;

const getImage = (value) => {
  switch (value) {
    case TYPES.MAOR_PLEDGE:
      return SOURCES.MAOR_PLEDGE
    case TYPES.CHILD:
      return SOURCES.CHILD;
    case TYPES.DAILY_REBBE_VIDEO:
      return SOURCES.DAILY_REBBE_VIDEO;
    case TYPES.DEFAULT:
      return SOURCES.DEFAULT;
    case TYPES.ILLUMINATE:
      return SOURCES.ILLUMINATE;
    case TYPES.MY_MAOR:
      return SOURCES.MY_MAOR;
    case TYPES.PARTNERS:
      return SOURCES.PARTNERS;
    default:
      return SOURCES.DEFAULT;
  }
};

export { NAMES, SIZES, TYPES, getImage };
