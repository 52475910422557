import React from "react";
import PropTypes from "prop-types";
import { STORAGE } from "../../../constants";
import Popup from "../../atoms/Popup";
import Modal from "../Modal";
import Player from "../Player";

const FeatureModal = ({ modal }) => {
  return (
    <Modal opened={modal.isOpened}>
      <Popup popup={modal}>
        <h3 className="mb-04">Our Features</h3>
        <Player video={STORAGE.FEATURE_VIDEO} />
      </Popup>
    </Modal>
  );
};

FeatureModal.propTypes = {
  modal: PropTypes.object,
};

export default FeatureModal;
