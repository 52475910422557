const PAGE_TITLES = {
  COMING_SOON: "coming_soon",
  DAILY_REBBE_VIDEO: "daily-rebbe-video",
  MAOR_PLEDGE: "maor-pledge",
  DONATION_REFUND_POLICY: "donation-refund-policy",
  HOME: "home",
  ILLUMINATE: "illuminate",
  LIBRARY: "library",
  MY_MAOR: "my-maor",
  NOT_FOUND: "404",
  SIGN_IN: "sign-in",
  SIGN_UP: "sign-up",
  PARTNERS: "partners",
  PARTNERS_MAIN_HOME: "partners-main-home",
  OUR_TEAM: "our-team",
  SPONSORS: "our_sponsors",
  PASSWORD_RECOVERY: "password-recovery",
  PRIVACY_POLICY: "privacy-policy",
  QUESTIONNAIRE: "questionnaire",
  LEGACY_GIFT: "legacy-gift",
  REFERRAL : "referral",
  ADDRESS: "address",
};

export default PAGE_TITLES;
