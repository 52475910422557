import React from 'react';
import classes from "./ToggleBox.module.scss"
import cs from "classnames";
import PropTypes from "prop-types";

const ToggleBox = ({ label1, label2 , handleInputChange, selectedValue }) => {
    return (
        <div className={cs(classes.switchesContainer)}>
            <input
                type="radio"
                id={`switch${label1}`}
                name="switchPlan"
                value={label1}
                checked={selectedValue === label1}
                onChange={handleInputChange}
            />
            <label htmlFor="switchCivil">{label1}</label>

            <input
                type="radio"
                id={`switch${label2}`}
                name="switchPlan"
                value={label2}
                checked={selectedValue === label2}
                onChange={handleInputChange}
            />
            <label htmlFor="switchJewish">{label2}</label>

            <div className={cs(classes.switchWrapper)}>
                <div className={cs(classes.switch)}>
                    <div>{label1}</div>
                    <div>{label2}</div>
                </div>
            </div>
        </div>
    )
}

ToggleBox.propTypes = {
    handleInputChange: PropTypes.func.isRequired,
    selectedValue: PropTypes.string.isRequired,
    label1: PropTypes.string.isRequired,
    label2: PropTypes.string.isRequired
};

export default ToggleBox