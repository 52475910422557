import { API_ROUTES } from "../constants";
import API from "./_api";

const FreeDonorAccessAPI = class extends API {
    get({ fdaURL }) {
        return this.request(API_ROUTES.FREE_DONOR_ACCESS, {
            body: {
                url: fdaURL
            },
            method: "POST"
        })
    }
}

export default FreeDonorAccessAPI;