import React, { useCallback } from "react";
import cs from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Logotype from "../../atoms/Logotype";
import { APPLICATION_ROUTES } from "../../../constants";
import { items, getLanguages } from "./utilities";
import classes from "./Footer.module.scss";

const Footer = () => {
  const { t, i18n } = useTranslation();

  const currentYear = new Date().getFullYear();

  const languages = getLanguages({
    items: Object.keys(i18n.store.data),
    language: i18n.language,
  });
  const onChangeLanguage = useCallback(
    ({
      target: {
        dataset: { value },
      },
    }) => {
      i18n.changeLanguage(value);
    },
    [i18n.changeLanguage]
  );

  items, languages, onChangeLanguage;

  return (
    <div className={cs(classes.container, "bgc-white")}>
      <div className={cs(classes.wrapper, classes.grid, "wrapper-xl")}>
        <nav className={classes.navigation}>
          <div className={classes.logotype}>
            <Logotype />

          </div>
            <div className={classes.donation}>
              <Link
                className="heading-m fw-700 c-orange"
                to={APPLICATION_ROUTES.PARTNERS_DONATION}
              >
                {t("components.organisms.footer.labels.1")}
              </Link>
            </div>
          <div className={classes.sponsors}>
            <Link
              className="heading-m fw-700 c-orange"
              to={APPLICATION_ROUTES.SPONSORS}
            >
              {t("components.organisms.footer.labels.4")}
            </Link>
          </div>
          {/* <ul className={classes.list}>
            {items.map((item) => (
              <li className={classes.item} key={item.title.key}>
                <Link className={classes.link} to={item.url}>
                  {t(item.title.key)}
                </Link>
              </li>
            ))}
          </ul> */}
        </nav>
        <div className={classes.subnavigation}>
          {/* <p className={cs(classes.fact, "m-0")}>{t("components.organisms.footer.texts.2")}</p> */}
          <ul className={classes.list}>
            <li className={classes.item}>
              <Link
                className={classes.link}
                to={APPLICATION_ROUTES.DONATION_REFUND_POLICY}
              >
                {t("components.organisms.footer.labels.2")}
              </Link>
            </li>
            <li className={classes.item}>
              <Link
                className={classes.link}
                to={APPLICATION_ROUTES.PRIVACY_POLICY}
              >
                {t("components.organisms.footer.labels.3")}
              </Link>
            </li>
          </ul>
        </div>
        <div className={classes.tools}>
          {/* <ul className={classes.languages}>
            {languages.map((item) => (
              <li className={classes.item} key={item.value}>
                <button
                  className={cs(classes.button, "fw-700 c-blue-dark", {
                    [classes.active]: item.active,
                  })}
                  data-value={item.value}
                  onClick={onChangeLanguage}
                >
                  {item.title}
                </button>
              </li>
            ))}
          </ul> */}
          <p className={cs(classes.copyrights, "m-0 fw-700 c-blue-dark")}>
            {t("components.organisms.footer.texts.1", { value: currentYear })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Footer);
