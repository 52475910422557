import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";

import Loader from "../../atoms/Loader";
import Logotype from "../../atoms/Logotype";
import PageTitle from "../../atoms/PageTitle";
import ProgressBar from "../../atoms/ProgressBar";
import { PAGE_TITLES } from "../../../constants";
import { StoreContext } from "../../../contexts";
import { ACTIONS } from "../../../store";
import useSteps from "./useSteps";
import { getTranslationKeyOfError } from "./utilities";
import { useTranslation } from "react-i18next";

const SignUpPage = observer(() => {
  const { allStepsLength, currentStepNumber, setStepIndex, fields, isNotValid, Step, onSubmit } = useSteps();
  const { authorization } = useContext(StoreContext);
  const { t } = useTranslation();

  const ERROR_MESSAGE = t(getTranslationKeyOfError(authorization.errorSignUp));

  useEffect(() => {
    return () => {
      authorization.clearError(ACTIONS.AUTHORIZATION.SIGN_UP);
    };
  }, []);

  return (
    <>
      <PageTitle value={PAGE_TITLES.SIGN_UP} />
      <main className="bg-gold-gradient--horizontal c-grey-dark text-m">
        <div className="wrapper-s wrapper-vertical-s-a d-flex fd-column ai-center minh-100vh">
          <div>
            <Logotype />
          </div>
          <div className="mt-08 w-100p" style={{ maxWidth: "300px" }}>
            <ProgressBar current={currentStepNumber} length={allStepsLength + 1} />
          </div>
          <div className="mt-10 w-100p mb-05">
            <Step
              {...fields}
              error={authorization.errorSignUp && ERROR_MESSAGE}
              isNotValid={isNotValid || authorization.isLoadingSignUp}
              setStepIndex={setStepIndex}
              onSubmit={onSubmit}
            />
          </div>
          
          {authorization.isLoadingSignUp && (
            <div className="mt-04">
              <Loader />
            </div>
          )}
        </div>
      </main>
    </>
  );
});

export default SignUpPage;
