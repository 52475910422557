import { observer } from "mobx-react-lite";
import React from "react";
import cs from "classnames";
import classes from "./PreviewFooter.module.scss";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { APPLICATION_ROUTES } from "../../../constants";
import ShareButton from "../ShareButton";
import SubmitAnswerButton from "../SubmitAnswerButton";
import ColoringPageButton from "../ColoringPageButton";
import { isMobile } from "react-device-detect";

const PreviewFooter = observer(
  ({ videoInfo, sharePage, submitAnswer, pdfHandler, file }) => {
    return (
      <div className={cs(classes.previewFooter)}>
        <div className={cs(classes.previewInfo)}>
          <div className={classes.videoTags}>
            {videoInfo.tags &&
              videoInfo.tags.map((tag, index) => (
                <NavLink
                  target="_blank"
                  key={tag.id + index}
                  to={`${APPLICATION_ROUTES.LIBRARY}?keyword=${tag.name_english}`}
                >
                  #{tag.name_english}
                </NavLink>
              ))}
          </div>
        </div>
        <div className={cs(classes.previewButtons)}>
          <ShareButton size={isMobile ? "xs" : "m"} sharePage={sharePage} />
          {file && (
            <ColoringPageButton
              size={isMobile ? "xs" : "m"}
              pdfHandler={pdfHandler}
            />
          )}
          <SubmitAnswerButton
            size={isMobile ? "xs" : "m"}
            submitAnswer={submitAnswer}
            // color={isMobile ? "white" : ""}
          />
        </div>
      </div>
    );
  }
);

export default PreviewFooter;
