import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import classes from "./Arrow.module.scss";
import Icon, {
  NAMES as ICON_NAMES,
  SIZES as ICON_SIZES,
} from "../../../../../../../../../../atoms/Icon";

const Arrow = ({ className, rotated, style, onClick }) => (
  <button
    className={cs(classes.container, className)}
    style={style}
    onClick={onClick}
  >
    <Icon
      name={ICON_NAMES.ARROW_IN_CIRCLE}
      rotated={rotated}
      size={ICON_SIZES.M}
    />
  </button>
);

Arrow.propTypes = {
  className: PropTypes.string,
  rotated: PropTypes.bool,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
};

Arrow.defaultProps = {
  className: "",
  rotated: false,
  // eslint-disable-next-line prettier/prettier
  onClick: () => {},
};

export default Arrow;
