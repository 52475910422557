/* eslint-disable prettier/prettier */
import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Element, scroller } from "react-scroll";
import cs from "classnames";

import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import Logotype from "../../atoms/Logotype";
import Tooltip from "../../molecules/Tooltip";
import { Decoration, features, packages } from "./utilities";
import classes from "./MyMaorPackages.module.scss";
import { APPLICATION_ROUTES, COOKIES } from "../../../constants";
import BirthdayPackages from "../../organisms/BirthdayPackages";
import { StoreContext } from "../../../contexts";
import { observer } from "mobx-react-lite";
import Cookies from "../../../utilities/cookies";

const MyMaorPackages = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { authorization } = useContext(StoreContext);

  useEffect(() => {
    history.push(APPLICATION_ROUTES.MY_MAOR_MAIN);
    try {
      setTimeout(() => {
        document.getElementById("birthday-package").scrollIntoView();
      }, 500);
    } catch (e) {
      console.warn(e);
    }
    return;

    // cart.cartType = "birthday";
    // cart.get();
    // authorization.resignIn({});
  }, []);

  const onSignOut = useCallback(() => {
    Cookies.delete({ name: COOKIES.AUTO_SIGNIN });
    authorization.signOut();
  });

  const onNavigateToCheckout = useCallback(
    ({
      target: {
        dataset: { name },
      },
    }) => {
      history.push(`${APPLICATION_ROUTES.MY_MAOR_ORDER}?type=${name}`);
    },
    [history]
  );

  const onScrollToPackageSection = useCallback(() => {
    scroller.scrollTo("packages", { duration: 500, smooth: true, offset: -24 });
  }, [scroller]);

  return (
    <div className={cs(classes.container, "")}>
      <div
        className={cs(classes.wrapper, "d-flex fd-column jc-center ai-center")}
      >
        <Logotype type="my-maor" />
      </div>
      <Element name="packages" />
      <section className={cs(classes.packages, "d-flex fd-column ai-center")}>
        <div
          className={cs(
            classes.wrapper,
            "d-flex fd-column jc-center ai-center"
          )}
        >
          <h3 className={classes.sectionTitle}>
            {t("projects.maor-kids.mymaor_birthday_experience")}
            <div
              className={cs(
                classes.decoration,
                classes.confetti,
                classes.right
              )}
            >
              <Decoration.ConfettiGreen />
            </div>
            <div
              className={cs(classes.decoration, classes.confetti, classes.left)}
            >
              <Decoration.ConfettiOrange />
            </div>
          </h3>
          {authorization.isAuthenticated && (
            <header className={cs("text-l-a", classes.header)}>
              <p>
                <span className={classes.loggedInText}>
                  {t("layout.logged_in_as")}
                  <span className={classes.familyText}>
                    <strong>{authorization.lastName} </strong>
                    {t("layout.family").toLowerCase()}
                  </span>
                </span>
                <a className={classes.logoutLink} onClick={onSignOut} href="#">
                  {t("layout.log_out")}
                </a>
              </p>
            </header>
          )}

          <div className={classes.birthdayPackageContainer}>
            <BirthdayPackages />
            <ul className={cs("w-100 d-none")}>
              {packages.map(
                ({
                  cost,
                  features,
                  isMarked,
                  Illustration,
                  name,
                  title_translation_key,
                }) => (
                  <li
                    key={title_translation_key}
                    className={cs(classes.item, { [classes.marked]: isMarked })}
                  >
                    {isMarked && (
                      <div className={classes.background}>
                        <div className={classes.tape}>
                          {t("projects.maor-kids.most_popular")}
                        </div>
                      </div>
                    )}
                    <div className={classes.illustration}>
                      <Illustration />
                    </div>
                    <h4 className={classes.title}>
                      {t(title_translation_key)}
                    </h4>
                    <ul className={classes.sublist}>
                      {features.map((subitem) => (
                        <li
                          key={subitem.text_translation_key}
                          className={classes.subitem}
                        >
                          <div className={classes.icon}>
                            <Icon
                              name={
                                isMarked
                                  ? "checked-circle-white"
                                  : "checked-circle-orange"
                              }
                              size="axss"
                            />
                          </div>
                          <div className={classes.subtitle}>
                            {t(subitem.text_translation_key)}
                          </div>
                          {subitem.message_translation_key && (
                            <div
                              className={cs(
                                classes.icon,
                                classes.tooltip,
                                classes.right
                              )}
                            >
                              <Tooltip
                                message={t(subitem.message_translation_key)}
                              >
                                <Icon
                                  name="question-mark-in-circle"
                                  size="axss"
                                />
                              </Tooltip>
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                    <div className={classes.footer}>
                      <div className={classes.cost}>${cost}</div>
                      <div className={classes.button}>
                        <Button
                          data-name={name}
                          color="ghost"
                          size="asm"
                          onClick={onNavigateToCheckout}
                        >
                          {t("projects.maor-kids.yes_i_want_this")}
                        </Button>
                      </div>
                    </div>
                  </li>
                )
              )}
            </ul>
            {/* <div className={cs(classes.decoration, classes.pattern, classes.left)}>
              <Decoration.DotPatternVertical />
            </div>
            <div className={cs(classes.decoration, classes.pattern, classes.right)}>
              <Decoration.DotPatternHorizontal />
            </div> */}
          </div>
        </div>
      </section>

      <section className={classes.features}>
        <div
          className={cs(
            classes.wrapper,
            "d-flex fd-column jc-center ai-center"
          )}
        >
          <h3 className={classes.sectionTitle}>
            {t("projects.maor-kids.so_what_is_in_the_package")}
            <div
              className={cs(classes.decoration, classes.ballon, classes.right)}
            >
              <Decoration.BallonRed />
            </div>
            <div
              className={cs(classes.decoration, classes.ballon, classes.left)}
            >
              <Decoration.BallonRed />
            </div>
          </h3>
          <ul className={cs(classes.list, "w-100")}>
            {features.map(
              ({
                Illustration,
                title_translation_key,
                text_translation_key,
              }) => (
                <li key={title_translation_key} className={classes.item}>
                  <div className={classes.illustration}>
                    <Illustration />
                  </div>
                  <h4 className={classes.title}>{t(title_translation_key)}</h4>
                  <p className={classes.text}>{t(text_translation_key)}</p>
                </li>
              )
            )}
          </ul>
        </div>
      </section>

      <div className={cs(classes.anchor)}>
        <div className={cs(classes.decoration, classes.celebration)}>
          <Decoration.ChooseCelebration />
        </div>
        <Button size="asm" onClick={onScrollToPackageSection}>
          {t("projects.maor-kids.choose_my_celebration")}
        </Button>
      </div>
    </div>
  );
});

export default MyMaorPackages;
