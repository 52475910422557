import Bugsnag from "@bugsnag/js";
import { COOKIES } from "../constants";
import Cookies from "../utilities/cookies";
import { APIError } from "../utilities/errors";

const API = class {
  constructor() {
    // eslint-disable-next-line no-undef
    this.url = process.env.REACT_APP_API_URL;
  }

  async request(path, { body, headers, method = "GET" } = {}) {
    const authorization = Cookies.get({ name: COOKIES.AUTHORIZATION });

    const options = {
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorization,
      },
      method,
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    if (headers) {
      options.headers = { ...options.headers, ...headers };
    }

    const promise = fetch(`${this.url}${path}`, options);

    promise
      .then((response) => {
        if (response.status >= 300 && response.status != 422) {
          response
            .json()
            .then((body) => {
              const apiError = new APIError({ response: response, body: body });
              Bugsnag.notify(apiError);
              return { response: response, body: body };
            })
            .catch((error) => {
              const apiError = new APIError({
                response: response,
                body: error,
              });
              Bugsnag.notify(apiError);
            });
        }
      })
      .catch(console.log);

    return promise;
  }
};

export default API;
