import { observer } from "mobx-react-lite";
import React from "react";
import cs from "classnames";
import classes from "./Winner.module.scss";
import prizeIcon from "../../../assets/images/prize.png";
import buldIcon from "../../../assets/images/bulb.png";
import useWinners from "./useWinners";
import winnerTrophy from "../../../assets/images/winner-prize.png";
import winnerChild from "../../../assets/images/winner-child.png";

const Winner = observer(() => {
  const winners = useWinners();
  console.log(winners, "winners");

  const capitalize = (value) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  let lastWinnerName = [];
  if (winners.raffle) {
    if (winners.raffle.data && winners.raffle.data.winner) {
      lastWinnerName.push(capitalize(winners.raffle.data.winner.first_name));
      lastWinnerName.push(capitalize(winners.raffle.data.winner.last_name));
      lastWinnerName = lastWinnerName.join(" ");
    }
  }

  let lastWinnerAddress = [];
  if (winners.raffle) {
    if (winners.raffle.data && winners.raffle.data.winner) {
      if (winners.raffle.data.winner.state == undefined) {
        lastWinnerAddress.push(capitalize(winners.raffle.data.winner.city));
        lastWinnerAddress.push(capitalize(winners.raffle.data.winner.country));
        lastWinnerAddress = lastWinnerAddress.join(", ");
      } else {
        lastWinnerAddress.push(capitalize(winners.raffle.data.winner.city));
        if (winners.raffle.data.winner.state.length <= 2) {
          lastWinnerAddress.push(
            winners.raffle.data.winner.state.toUpperCase()
          );
        } else {
          lastWinnerAddress.push(capitalize(winners.raffle.data.winner.state));
        }
        lastWinnerAddress = lastWinnerAddress.join(", ");
      }
    }
  }

  let winnerName = [];
  if (winners.maor && winners.maor.first_name) {
    winnerName = capitalize(winners.maor.first_name);
    winnerName += " " + capitalize(winners.maor.last_name);
    // winnerName += " - " + winners.maor.score;
  }

  let winnerAddress = [];
  if (winners.maor && winners.maor.first_name) {
    if (winners.maor.state == undefined) {
      winnerAddress.push(capitalize(winners.maor.city));
      winnerAddress.push(capitalize(winners.maor.country));
      winnerAddress = winnerAddress.join(", ");
    } else {
      winnerAddress.push(capitalize(winners.maor.city));
      if (winners.maor.state.length <= 2) {
        winnerAddress.push(winners.maor.state.toUpperCase());
      } else {
        winnerAddress.push(capitalize(winners.maor.state));
      }
      winnerAddress = winnerAddress.join(", ");
    }
  }

  if (winners.raffle) {
    if (winners.raffle.data.winner) {
      console.log(winnerAddress, "winners");
    }
  }

  return (
    <div className={cs(classes.winnerContainer, "bxsh-soft")}>
      <div className={cs(classes.winnertextConatiner)}>
        {winners.raffle ? (
          <>
            <div className={cs(classes.iconText)}>
              <img src={prizeIcon} alt="prizeIcon" />
              <p className={cs(classes.winnerIno)}>
                winner raffle #
                {winners.raffle ? winners.raffle.data.raffle_code : ""}
              </p>
            </div>
            {winners.raffle ? (
              <p>
                <span className={cs(classes.winnerName)}>{lastWinnerName}</span>{" "}
                from {lastWinnerAddress}
              </p>
            ) : (
              <div className={cs(classes.name)}>No Winner yet</div>
            )}{" "}
          </>
        ) : (
          <div className={cs(classes.name)}>No Winner yet</div>
        )}
      </div>
      <div className={cs(classes.winnertextConatiner)}>
        {winners.maor ? (
          <>
            <div className={cs(classes.iconText)}>
              <img src={buldIcon} alt="buldIcon" />
              <p className={cs(classes.winnerIno)}>
                answerd the most questions
              </p>
            </div>
            {winners.maor.first_name ? (
              <p>
                <span className={cs(classes.winnerName)}> {winnerName} </span>{" "}
                from {winnerAddress} -{" "}
                <span className={cs(classes.noWrap)}>
                  <b> {winners.maor.score} </b> Answers!{" "}
                </span>
              </p>
            ) : (
              <div className={cs(classes.name)}>No questions answered yet</div>
            )}
          </>
        ) : (
          <div className={cs(classes.name)}>No questions answered yet</div>
        )}
      </div>
      <img
        className={cs(classes.winnerTrophy)}
        src={winnerTrophy}
        alt="winnerTrophy"
      />
      <img
        className={cs(classes.winnerChild)}
        src={winnerChild}
        alt="winnerChild"
      />
    </div>
  );
});

export default Winner;
