import { observer } from "mobx-react-lite";
import React, { useCallback, useContext } from "react";
import PageTitle from "../../atoms/PageTitle";
import { APPLICATION_ROUTES, PAGE_TITLES } from "../../../constants";
import Layout from "../../templates/Layout";
import classes from "./Referaal.module.scss";
import cs from "classnames";
import { StoreContext } from "../../../contexts";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import Button from "../../atoms/Button";
import referralImage from "../../../assets/images/refer.png";
import { ACTIONS } from "../../../store";
import { scrollToTop } from "../../../utilities/helpers";
import { useHistory } from "react-router-dom";
import Loader from "../../atoms/Loader";
import { useState } from "react";
import Icon from "../../atoms/Icon";
import twiiterLogo from "../../../assets/images/twitter.png";
import instargramLogo from "../../../assets/images/instargram.png";
import facebookLogo from "../../../assets/images/facebook.png";
import emailLogo from "../../../assets/images/yellow-email.png";
import telegramLogo from "../../../assets/images/telegram.png";
import whatsappLogo from "../../../assets/images/whatsapp.png";
import linkedInLogo from "../../../assets/images/linkedIn.png";
import Modal from "../../organisms/Modal";
import { useModal } from "../../../hooks";

const Referral = observer(() => {
  const { authorization } = useContext(StoreContext);
  const history = useHistory();
  const termsConditions = useModal();

  let shareUrl = "";
  let visibleShareUrl = "";
  let signUpLink = "";
  // let quote = "";
  const [copySuccess, setCopySuccess] = useState(false);

  if (authorization.user != null) {
    visibleShareUrl =
      "Maor.org/sign-up/?referral=" + authorization.user.unique_referral_code;
    const domain =
      window.location.host == "www.maor.org"
        ? "Maor.org"
        : window.location.origin;
    signUpLink =
      domain +
      APPLICATION_ROUTES.SIGN_UP_SHORT +
      "/?referral=" +
      authorization.user.unique_referral_code;
    // quote =
    //   `Hey! Our children absolutely love the MyMaor Rebbe videos, and the best part is, it's completely free every week. We highly recommend it!\n\nIf you use my referral link to sign up for the free account, my kids will receive a free 12 Pesukim comic book (you can also get one for free!).\n\nHere is the link: ${signUpLink}`;
    shareUrl = signUpLink;
  }

  const handleCopyLink = () => {
    const textArea = document.createElement("textarea");
    textArea.value = shareUrl;

    document.body.appendChild(textArea);
    textArea.select();
    console.error("Copy ");
    try {
      document.execCommand("copy");
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 3000);
    } catch (err) {
      console.error("Copy failed:", err);
    }

    document.body.removeChild(textArea);
  };

  const onNavigateToSignIn = useCallback(() => {
    history.push(
      APPLICATION_ROUTES.SIGN_IN + "?redirect=" + APPLICATION_ROUTES.REFERRAL
    );
    scrollToTop();
  });

  //   const text = `This offer is valid until December 4, 2023 (כ"א כסלו התשפ"ד)`;

  const instagramOpen = () => {
    handleCopyLink();
    window.open("https://www.instagram.com/", "_blank", "noreferrer");
  };

  return (
    <>
      <PageTitle value={PAGE_TITLES.MAOR_PLEDGE} />
      <div className={cs(classes.backgroundRefer)}>
        <Layout logotype="my-maor">
          <div className={cs(classes.container, "wrapper-m")}>
            <div className={cs(classes.imageContainer, "d-none")}>
              <img src={referralImage} alt="referralImage" />
              <div className={cs("mt-02")}>
                <p>
                  <b>Limited Amount Available!</b>
                </p>
                <p>
                  By participating in the Campaign, you agree to all the{" "}
                  <span
                    className={cs(classes.termsConditions)}
                    onClick={termsConditions.onOpen}
                  >
                    {" "}
                    Terms and Conditions
                  </span>
                  .
                </p>
              </div>
            </div>
            <div className={cs(classes.sideSection)}>
              <div className={cs(classes.headerConatiner)}>
                <div className={cs(classes.shareText)}>
                  <h2 className={cs(classes.lightPinkColor)}>
                    Share the Light!
                  </h2>
                  <p>
                    Share the treasure of thousands of Rebbe videos for <br />{" "}
                    children and adults with your family, friends, and students.{" "}
                  </p>
                </div>
              </div>
              {authorization.isAuthenticated ? (
                <div className={cs(classes.centerReferralCount)}>
                  <div className={cs(classes.referralCountContainer)}>
                    <p>
                      How many new <br /> verified families <br /> have you signed
                      up?
                    </p>
                    <div className={cs(classes.arrow)}>
                      <Icon name="pink-arrow" size="xl" />
                    </div>
                    <div className={cs(classes.referralCount)}>
                      <b>
                        <p className={cs(classes.count)}>
                          {authorization.user.referral_count}
                        </p>
                      </b>
                      <p>Referred</p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className={cs(classes.bottom)}>
                <>
                  {authorization.isLoadingSignIn ||
                    authorization[
                    `isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`
                    ] ? (
                    <>
                      <Loader />{" "}
                    </>
                  ) : (
                    <>
                      {authorization.isAuthenticated ? (
                        <>
                          <p>
                            Use this ready-made message with your unique link{" "}
                            <br /> and share the Rebbe&apos;s message with the
                            world!
                          </p>
                          <div className={cs(classes.shareLinkContainer)}>
                            <p>
                              <b>{visibleShareUrl}</b>
                            </p>
                            <Button size="xs" onClick={handleCopyLink}>
                              {" "}
                              {copySuccess ? "Copied" : "Copy Link"}
                            </Button>
                          </div>
                          <div className={cs(classes.socialMediaIconContainer)}>
                            <EmailShareButton url={shareUrl}>
                              <div className={cs(classes.logoBg)}>
                                <img src={emailLogo} alt="emailLogo" />
                              </div>
                            </EmailShareButton>
                            <WhatsappShareButton url={shareUrl}>
                              <div className={cs(classes.logoBg)}>
                                <img src={whatsappLogo} alt="whatsappLogo" />
                              </div>
                            </WhatsappShareButton>

                            <div
                              onClick={instagramOpen}
                              className={cs(classes.logoBg, classes.instaLogo)}
                            >
                              <img src={instargramLogo} alt="instargramLogo" />
                            </div>

                            <FacebookShareButton url={signUpLink}>
                              <div className={cs(classes.logoBg)}>
                                <img src={facebookLogo} alt="facebookLogo" />
                              </div>
                            </FacebookShareButton>
                            <TwitterShareButton url={shareUrl}>
                              <div className={cs(classes.logoBg)}>
                                <img src={twiiterLogo} alt="twiiterLogo" />
                              </div>
                            </TwitterShareButton>
                            <TelegramShareButton url={shareUrl}>
                              <div className={cs(classes.logoBg)}>
                                <img src={telegramLogo} alt="telegramLogo" />
                              </div>
                            </TelegramShareButton>
                            <LinkedinShareButton url={signUpLink}>
                              <div className={cs(classes.logoBg)}>
                                <img src={linkedInLogo} alt="linkedInLogo" />
                              </div>
                            </LinkedinShareButton>
                          </div>
                        </>
                      ) : (
                        <>
                          <Button size="xs" onClick={onNavigateToSignIn}>
                            Sign in to get Your Sharing Link!
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </Layout>
      </div>
      <Modal opened={termsConditions.isOpened}>
        <div className={cs(classes.popUpTermsConditions)}>
          <div className={cs(classes.termsConditionsModalCard)}>
            <div className={cs(classes.closeButtonContainer)}>
              <button
                className={classes.close}
                onClick={termsConditions.onClose}
              >
                <Icon name="exit" size="l" />
              </button>
            </div>
            <div className={cs(classes.termsConditionsContainer)}>
              <h1>Campaign Terms and Conditions</h1>
              <ul>
                <li>
                  <p> By participating in the Campaign, you agree to all the Terms and Conditions.</p>
                </li>
                <li>
                  <p> To participate in this campaign, individuals must have an active account on maor.org.</p>
                </li>
                <li>
                  <p>Referrals should be families who do not already have an existing account, and no one in their household should have an account.</p>
                </li>
                <li>
                  <p>Referrals need to sign up by themselves, provide full names, and verify their email addresses.</p>
                </li>
                <li>
                  <p>Referrals should be families who do not already have an existing account, and no one in their household should have an account. Referrals need to provide full names and verify their email addresses.</p>
                </li>
                <li>
                  <p>To submit referrals, participants must use their unique referral link provided by Maor.</p>
                </li>
                <li>
                  <p>The number of referrals displayed on the share page is for reference purposes only. The actual count of referrals will be determined based on Maor&apos;s database records.</p>
                </li>
                <li>
                  <p>Once a participant successfully refers five new families who create free accounts, they are eligible to receive a free 12 Pesukim comic book.</p>
                </li>
                <li>
                  <p>The 12 Pesukim comic book will be provided by Maor and will be made available around Hey Teves 5784. Maor will notify participants when it becomes available.</p>
                </li>
                <li>
                  <p>Maor reserves the right to verify the authenticity of referrals and may disqualify participants if there is any suspicion of fraudulent or invalid referrals.</p>
                </li>
                <li>
                  <p>This campaign is subject to change or termination at Maor&apos;s discretion, and any changes will be communicated to participants.</p>
                </li>
                <li>
                  <p>If you have read all the terms and conditions regarding how we count new referrals, and you still feel that according to those rules there is a mistake in the amount displayed, please contact us at: <a href="mailto:office@maor.org">office@maor.org</a>.</p>
                </li>
              </ul>

            </div>
          </div>
        </div>
      </Modal>

    </>
  );
});

Referral.displayName = 'Referral';
export default Referral;

