import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Button, { COLORS as BUTTON_COLORS } from "../../atoms/Button";
import Logotype from "../../atoms/Logotype";
import { APPLICATION_ROUTES } from "../../../constants";
import { scrollToTop } from "../../../utilities/helpers";
import classes from "./EmailVerificationSuccess.module.scss";

const EmailVerifiedSuccess = () => {
  const history = useHistory();
  const onNavigateToPartners = useCallback(() => {
    history.push(APPLICATION_ROUTES.PARTNERS_PACKAGES);
    scrollToTop();
  }, []);
  const onNavigateToHome = useCallback(() => {
    history.push(APPLICATION_ROUTES.HOME);
    scrollToTop();
  }, []);

  const { t } = useTranslation();

  return (
    <div className="bg-gold-gradient--horizontal c-grey-dark text-m">
      <div className="wrapper-s wrapper-vertical-s-a d-flex fd-column ai-center minh-100vh">
        <nav>
          <Logotype />
        </nav>
        <div className="mt-11">
          <h2 className="heading-l-a ta-center c-blue-dark">{t("pages.authorization.email_verified_success.title")}</h2>
        </div>
        <ul className={classes.navigation}>
          <li className={classes.item}>
            <Button onClick={onNavigateToPartners}>{t("pages.authorization.email_verified_success.labels.1")}</Button>
          </li>
          <li className={classes.item}>
            <Button color={BUTTON_COLORS.GHOST} onClick={onNavigateToHome}>
              {t("pages.authorization.email_verified_success.labels.2")}
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default React.memo(EmailVerifiedSuccess);
