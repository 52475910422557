import React, { useCallback, useContext } from "react";
import { renderToString } from "react-dom/server";
import { useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import { APPLICATION_ROUTES } from "../../../constants";
import { StoreContext } from "../../../contexts";
import { useCheckbox, useInput } from "../../../hooks";
import { scrollToTop } from "../../../utilities/helpers";

const useForm = () => {
  const { authorization } = useContext(StoreContext);

  const history = useHistory();
  const onNavigateToQuestionnaireSuccess = useCallback(() => {
    history.push(APPLICATION_ROUTES.QUESTIONNAIRE_SUCCESS);
    scrollToTop();
  }, []);

  const { t } = useTranslation();
  const QUESTION_1 = t("pages.authorization.questionnaire_main.questions.1");
  const QUESTION_2 = t("pages.authorization.questionnaire_main.questions.2");
  const QUESTION_3 = t("pages.authorization.questionnaire_main.questions.3");
  const QUESTION_4 = t("pages.authorization.questionnaire_main.questions.4");
  const QUESTION_5 = t("pages.authorization.questionnaire_main.questions.5");
  const QUESTION_6 = t("pages.authorization.questionnaire_main.questions.6");
  const ANSWER_1 = <Trans components={{ a: <b /> }} i18nKey="pages.authorization.questionnaire_main.answers.1" t={t} />;
  const ANSWER_2 = <Trans components={{ a: <b /> }} i18nKey="pages.authorization.questionnaire_main.answers.2" t={t} />;
  const ANSWER_3 = t("pages.authorization.questionnaire_main.answers.3");
  const ANSWER_4 = t("pages.authorization.questionnaire_main.answers.4");
  const ANSWER_5 = t("pages.authorization.questionnaire_main.answers.5");
  const ANSWER_6 = t("pages.authorization.questionnaire_main.answers.6");
  const ANSWER_7 = t("pages.authorization.questionnaire_main.answers.7");
  const ANSWER_8 = t("pages.authorization.questionnaire_main.answers.8");
  const ANSWER_9 = t("pages.authorization.questionnaire_main.answers.9");
  const ANSWER_10 = t("pages.authorization.questionnaire_main.answers.10");
  const ANSWER_11 = t("pages.authorization.questionnaire_main.answers.11");
  const ANSWER_12 = t("pages.authorization.questionnaire_main.answers.12");
  const ANSWER_13 = t("pages.authorization.questionnaire_main.answers.13");
  const ANSWER_14 = t("pages.authorization.questionnaire_main.answers.14");

  const findOutAd = useCheckbox({ label: ANSWER_11 });
  const findOutAdDetails = useInput({ label: QUESTION_5 });
  const findOutOther = useCheckbox({ label: ANSWER_14 });
  const findOutOtherDetails = useInput();
  const findOutReferral = useCheckbox({ label: ANSWER_13 });
  const findOutVideo = useCheckbox({ label: ANSWER_10 });
  const findOutVideoDetails = useInput({ label: QUESTION_4 });
  const findOutWhatsApp = useCheckbox({ label: ANSWER_12 });
  const findOutWhatsAppDetails = useInput({ label: QUESTION_6 });
  const goalChassidisheEntertainment = useCheckbox({ label: ANSWER_3 });
  const goalFreeResource = useCheckbox({ label: ANSWER_4 });
  const goalOther = useCheckbox({ label: ANSWER_5 });
  const goalOtherDetails = useInput();
  const goalSeeRebbe = useCheckbox({ label: ANSWER_1 });
  const goalUnderstandRebbe = useCheckbox({ label: ANSWER_2 });
  const mediaTypeBoth = useCheckbox({ label: ANSWER_8 });
  const mediaTypeChabadEntertainment = useCheckbox({ label: ANSWER_6 });
  const mediaTypeJewishEntertainment = useCheckbox({ label: ANSWER_7 });
  const mediaTypeOther = useCheckbox({ label: ANSWER_9 });
  const mediaTypeOtherDetails = useInput();

  const isCheckedGoalStep =
    goalChassidisheEntertainment.checked ||
    goalFreeResource.checked ||
    goalOther.checked ||
    goalSeeRebbe.checked ||
    goalUnderstandRebbe.checked;
  const isCheckedMediaTypeStep =
    mediaTypeBoth.checked ||
    mediaTypeChabadEntertainment.checked ||
    mediaTypeJewishEntertainment.checked ||
    mediaTypeOther.checked;
  const isCheckedFindOutStep =
    findOutAd.checked ||
    findOutOther.checked ||
    findOutReferral.checked ||
    findOutVideo.checked ||
    findOutWhatsApp.checked;

  const isValid = isCheckedFindOutStep && isCheckedGoalStep && isCheckedMediaTypeStep;

  const onSubmit = (event) => {
    event.preventDefault();

    const data = {
      data: {
        questionnaire: {
          questions: [
            {
              title: QUESTION_1,
              answers: [
                goalSeeRebbe.checked && { title: goalSeeRebbe.label, text: null, clarification: null },
                goalUnderstandRebbe.checked && { title: goalUnderstandRebbe.label, text: null, clarification: null },
                goalChassidisheEntertainment.checked && {
                  title: goalChassidisheEntertainment.label,
                  text: null,
                  clarification: null,
                },
                goalFreeResource.checked && { title: goalFreeResource.label, text: null, clarification: null },
                goalOther.checked && { title: goalOther.label, text: goalOtherDetails.value, clarification: null },
              ]
                .filter((item) => item)
                .map((item) => ({ ...item, title: renderToString(item.title) })),
            },
            {
              title: QUESTION_2,
              answers: [
                mediaTypeChabadEntertainment.checked && {
                  title: mediaTypeChabadEntertainment.label,
                  text: null,
                  clarification: null,
                },
                mediaTypeJewishEntertainment.checked && {
                  title: mediaTypeJewishEntertainment.label,
                  text: null,
                  clarification: null,
                },
                mediaTypeBoth.checked && { title: mediaTypeBoth.label, text: null, clarification: null },
                mediaTypeOther.checked && {
                  title: mediaTypeOther.label,
                  text: mediaTypeOtherDetails.value,
                  clarification: null,
                },
              ].filter((item) => item),
            },
            {
              title: QUESTION_3,
              answers: [
                findOutVideo.checked && {
                  title: findOutVideo.label,
                  text: findOutVideoDetails.value,
                  clarification: findOutVideoDetails.label,
                },
                findOutAd.checked && {
                  title: findOutAd.label,
                  text: findOutAdDetails.value,
                  clarification: findOutAdDetails.label,
                },
                findOutWhatsApp.checked && {
                  title: findOutWhatsApp.label,
                  text: findOutWhatsAppDetails.value,
                  clarification: findOutWhatsAppDetails.label,
                },
                findOutReferral.checked && { title: findOutReferral.label, text: null, clarification: null },
                findOutOther.checked && {
                  title: findOutOther.label,
                  text: findOutOtherDetails.value,
                  clarification: null,
                },
              ].filter((item) => item),
            },
          ],
        },
      },
      onSuccess: onNavigateToQuestionnaireSuccess,
    };

    authorization.sendQuestionnaire(data);
  };

  return {
    findOutAd,
    findOutAdDetails,
    findOutOther,
    findOutOtherDetails,
    findOutReferral,
    findOutVideo,
    findOutVideoDetails,
    findOutWhatsApp,
    findOutWhatsAppDetails,
    goalChassidisheEntertainment,
    goalFreeResource,
    goalOther,
    goalOtherDetails,
    goalSeeRebbe,
    goalUnderstandRebbe,
    isCheckedFindOutStep,
    isCheckedGoalStep,
    isCheckedMediaTypeStep,
    isValid: isValid,
    mediaTypeBoth,
    mediaTypeChabadEntertainment,
    mediaTypeJewishEntertainment,
    mediaTypeOther,
    mediaTypeOtherDetails,
    onSubmit,
  };
};

export default useForm;
