/* eslint-disable */

function useIsDateWithinTwoMonths(dateString) {
    // Parse the input date
    const inputDate = new Date(dateString);

    // Get the current date
    const currentDate = new Date();

    // Calculate the date 2 months from today
    const twoMonthsFromNow = new Date();
    twoMonthsFromNow.setMonth(currentDate.getMonth() + 2);

    // Compare the input date with the calculated date
    return inputDate <= twoMonthsFromNow;
}

export default useIsDateWithinTwoMonths;
