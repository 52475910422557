import React from "react";
import cs from "classnames";
import classes from "./RefreshPage.module.scss";
import { useTranslation } from "react-i18next";

const RefreshPage = () => {
  const { t } = useTranslation();

  const reload = () => {
    location.reload();
  };

  return (
    <div onClick={reload} className={cs("text-l c-orange fw-700 curs-pointer", classes.container)}>
      {t("components.atoms.refresh_page.text.1")}
    </div>
  );
};

export default RefreshPage;
