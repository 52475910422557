import { API_ROUTES } from "../constants";
import API from "./_api";

const ErrorAPI = class extends API {
  notifyError({ error }) {
    return this.request(API_ROUTES.NOTIFY_ERROR, {
      body: {
        error: error
      },
      method: "POST",
    });
  }
};

export default ErrorAPI;
