import { observer } from "mobx-react-lite";
import React from "react";

import cs from "classnames";
import classes from "./OurTeam.module.scss";
import male from "../../../../../assets/images/male.png";
import female from "../../../../../assets/images/female.png";

const OurTeam = observer(() => {
  const team = [
    {
      firstName: "Chanoch ",
      lastName: "Chaskind",
      designation: "Director",
      gender: male,
    },
    {
      firstName: "Mushka ",
      lastName: "Cohen",
      designation: "Office Manager",
      gender: female,
    },
    {
      firstName: "Yossi ",
      lastName: "Cohen",
      designation: "Project Manager",
      gender: male,
    },
    {
      firstName: "Chava",
      lastName: "Davidoff",
      designation: "Project Manager",
      gender: female,
    },
    {
      firstName: "Rochel ",
      lastName: "Gringras",
      designation: "Producer",
      gender: female,
    },
    {
      firstName: "Mendy ",
      lastName: "Yarmove",
      designation: "Video Editor",
      gender: male,
    },
    {
      firstName: "Moussia ",
      lastName: "Mondshine",
      designation: "Marketing and Design",
      gender: female,
    },
    {
      firstName: "Levi ",
      lastName: "Raskin",
      designation: "Researcher",
      gender: male,
    },
    {
      firstName: "Mendel ",
      lastName: "Marinovsky",
      designation: "Researcher",
      gender: male,
    },
    {
      firstName: "Chaya ",
      lastName: "Slonim",
      designation: "Writer",
      gender: female,
    },
    {
      firstName: "Sorole ",
      lastName: "Golomb",
      designation: "Writer",
      gender: female,
    },
    {
      firstName: "Rabbi Aharon",
      lastName: " Raskin",
      designation: "Rabbinical Vaad",
      gender: male,
    },
    {
      firstName: "Rabbi Schneur",
      lastName: " Ossowiecki",
      designation: "Rabbinical Vaad",
      gender: male,
    },
    {
      firstName: "Levi ",
      lastName: "Posner",
      designation: "Translation",
      gender: male,
    },
    {
      firstName: "Zushe ",
      lastName: "Greisman",
      designation: "Translation",
      gender: male,
    },
    {
      firstName: "Reuven",
      lastName: "Deray",
      designation: "Narration",
      gender: male,
    },
    {
      firstName: "Shimi ",
      lastName: "Hershkovitz",
      designation: "Narration",
      gender: male,
    },
    {
      firstName: "Avremy ",
      lastName: "Rapaport",
      designation: "Narration",
      gender: male,
    },
    {
      firstName: "Yosef ",
      lastName: "Chaim Said",
      designation: "Voices",
      gender: male,
    },
    {
      firstName: "Shmuli ",
      lastName: "Zalmenov",
      designation: "Voices",
      gender: male,
    },
    {
      firstName: "Clara ",
      lastName: "Chaya Epstein",
      designation: "Voices",
      gender: female,
    },
    {
      firstName: "Mendy ",
      lastName: "Brill",
      designation: "Drawings",
      gender: male,
    },
    {
      firstName: "Levi ",
      lastName: "Liberow",
      designation: "Editor",
      gender: male,
    },
    {
      firstName: "Raizel ",
      lastName: "Liberow",
      designation: "Creative",
      gender: female,
    },
    {
      firstName: "Rechyl ",
      lastName: "Sirota",
      designation: "Writer",
      gender: female,
    },
  ];

  return (
    <div className={cs(classes.container, "wrapper-m", "bgc-gold")}>
      <div className={cs(classes.headingContainer)}>
        <h3>Our team</h3>
      </div>

      <div className={cs(classes.filedContainer)}>
        {team.map((curr, i) => {
          return (
            <div className={cs(classes.profileCard, "bgc-white")} key={i}>
              <img src={curr.gender} alt="gender" />
              <h3>
                {curr.firstName}
                <br /> {curr.lastName}{" "}
              </h3>
              <p>{curr.designation}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default React.memo(OurTeam);
