import API from "./_api";
import { API_ROUTES } from "../constants";

const PersonsAPI = class extends API {
  addChild(data) {
    return this.request(`${API_ROUTES.PERSONS.ADD_CHILD}`, {
      method: "POST",
      body: { ...data },
    });
  }

  deleteChild(personId) {
    return this.request(`${API_ROUTES.PERSONS.DELETE_CHILD}`, {
      method: "POST",
      body: { person_id: personId },
    });
  }
};

export default PersonsAPI;
