import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import { observer } from "mobx-react-lite";

import AutoSignIn from "../../pages/AutoSignIn";
import Checkout from "../../pages/Checkout";
import ComingSoon from "../../pages/ComingSoon";
import DonationRefundPolicy from "../../pages/DonationRefundPolicy";
import EmailVerificationRequest from "../../pages/EmailVerificationRequest";
import EmailVerificationSuccess from "../../pages/EmailVerificationSuccess";
// import Home from "../../pages/Home";
import IlluminateMain from "../../pages/IlluminateMain";
import Library from "../../pages/Library";
import LibraryProtected from "../../pages/Library/LibraryProtected";
// import MyMaorMain from "../../pages/MyMaorMain";
import MyMaorOrder from "../../pages/MyMaorOrder";
import MyMaorPackages from "../../pages/MyMaorPackages";
import MyMaorCheckout from "../../pages/MyMaorCheckout";
import NotFound from "../../pages/NotFound";
import PartnersDonation from "../../pages/PartnersDonation";
import PartnersOrder from "../../pages/PartnersOrder";
import PartnersPackages from "../../pages/PartnersPackages";
import PartnersSuccess from "../../pages/PartnersSuccess";
import PasswordRecoveryRequest from "../../pages/PasswordRecoveryRequest";
import PasswordRecoverySuccess from "../../pages/PasswordRecoverySuccess";
import PasswordRecoveryUpdate from "../../pages/PasswordRecoveryUpdate";
import PrivacyPolicy from "../../pages/PrivacyPolicy";
import QuestionnaireMain from "../../pages/QuestionnaireMain";
import QuestionnaireSuccess from "../../pages/QuestionnaireSuccess";
import SignInPage from "../../pages/SignInPage";
import SignUpPage from "../../pages/SignUpPage";
import TestPage from "../../pages/TestPage";
import { APPLICATION_ROUTES } from "../../../constants";
import { StoreContext } from "../../../contexts";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import MyMaorSuccess from "../../pages/MyMaorSuccess";
import MailingList from "../../pages/MyAccount/MailingList";
import PaymentMethod from "../../pages/MyAccount/PaymentMethod";
import List from "../../pages/MyAccount/PaymentMethod/List";
import PartnersMobileDonation from "../../pages/PartnersMobileDonation";
import PartnersPageMain from "../../pages/PartnersPageMain/PartnersPageMain";
import DailyRebbeVideoMain from "../../pages/DailyRebbeVideoMain/DailyRebbeVideoMain";
import UnsubscribePage from "../../pages/UnsubscribedPage/UnsubscribePage";
import LegacyGift from "../../pages/LegacyGift";
import Sponsors from "../../pages/Sponsors";
import DonationSuccess from "../../pages/DonationSuccess";
import ROUTE_KEY from "./utilities";
import MaorPledge from "../../pages/MaorPledge";
import Referral from "../../pages/Referral";
import VerifyEmail from "../../pages/VerifyEmail";
import BirthdayPackagesPage from "../../pages/BirthdayPackagesPage";
import { isMobileDevice } from "../../../utilities/helpers";
import MyMaor from "../../pages/MyMaor";
import Partners from "../../pages/Partners";
// import Profile from "../../pages/Profile";
// import AudioPlayer from "../AudioPlayer";
// import PartnersPageTest from "../../pages/PartnersPageTest/PartnersPageTest";
import Addresses from "../../pages/MyAccount/Addresses";
// import MyMaorMainCopy from "../../pages/MyMaorMainCopy/MyMaorMainCopy";

const Routing = observer(() => {
  const { authorization, common } = useContext(StoreContext);
  const { t } = useTranslation();
  const isOnlyNumbers = (str) => /^\d+(\.\d+)?$/.test(str);

  useEffect(() => {
    common.set("refreshMaorPage", false);
  }, [window.location.href]);

  return (
    <Router>
      <Switch>
        <Route path="/YudShvat">
          <Redirect
            to={
              APPLICATION_ROUTES.LIBRARY +
              "?holiday=104&holiday-label=Yud+Shevat"
            }
          />
        </Route>
        <Route path="/YudShevat">
          <Redirect
            to={
              APPLICATION_ROUTES.LIBRARY +
              "?holiday=104&holiday-label=Yud+Shevat"
            }
          />
        </Route>
        <Route path={APPLICATION_ROUTES.AUTO_SIGN_IN}>
          <AutoSignIn />
        </Route>
        <Route path={APPLICATION_ROUTES.CHECKOUT_PREFILLED}>
          <Checkout />
        </Route>
        <Route path={APPLICATION_ROUTES.CHECKOUT}>
          <Checkout />
        </Route>
        <Route path={APPLICATION_ROUTES.MY_ACCOUNT.ADDRESSES}>
          <Addresses />
        </Route>
        <Route exact path={APPLICATION_ROUTES.DONATE}>
          <Redirect to={APPLICATION_ROUTES.PARTNERS_DONATION} />
        </Route>
        <Route path={APPLICATION_ROUTES.PARTNERS_DONATION}>
          <PartnersDonation />
        </Route>
        <Route path={APPLICATION_ROUTES.MY_MAOR_APP_DONATION}>
          <PartnersMobileDonation />
        </Route>
        <Route path={APPLICATION_ROUTES.PARTNERS_ORDER_PREFILLED}>
          <PartnersOrder />
        </Route>
        <Route path={APPLICATION_ROUTES.PARTNERS_ORDER}>
          <PartnersOrder />
        </Route>
        <Route path={APPLICATION_ROUTES.PARTNERS_SUCCESS}>
          <PartnersSuccess />
        </Route>
        <Route path={APPLICATION_ROUTES.DONATION_SUCCESS}>
          <DonationSuccess />
        </Route>
        <Route path={APPLICATION_ROUTES.MY_MAOR_SUCCESS}>
          <MyMaorSuccess />
        </Route>
        <Route path={APPLICATION_ROUTES.QUESTIONNAIRE_MAIN}>
          {authorization.isAuthenticated ? (
            <QuestionnaireMain />
          ) : (
            <Redirect to={APPLICATION_ROUTES.HOME} />
          )}
        </Route>
        <Route path={APPLICATION_ROUTES.QUESTIONNAIRE_SUCCESS}>
          <QuestionnaireSuccess />
        </Route>
        <Route path={APPLICATION_ROUTES.SIGNUP_SUCCESS}>
          <QuestionnaireSuccess />
        </Route>
        <Route path={APPLICATION_ROUTES.SPONSORS}>
          <Sponsors />
        </Route>
        {/* {authorization.isAuthenticated && !authorization.user.answered_questions && (
          <Route path="*">
            <Redirect to={APPLICATION_ROUTES.QUESTIONNAIRE_MAIN} />
          </Route>
        )} */}

        <Route path={APPLICATION_ROUTES.COMING_SOON}>
          <ComingSoon />
        </Route>
        <Route
          key={ROUTE_KEY.DAILY_REBBE_VIDEO}
          path={APPLICATION_ROUTES.DAILY_REBBE_VIDEO_MAIN}
        >
          <DailyRebbeVideoMain />
        </Route>
        <Route path={APPLICATION_ROUTES.EMAIL_VERIFICATION_REQUEST}>
          <EmailVerificationRequest />
        </Route>
        <Route path={APPLICATION_ROUTES.EMAIL_VERIFY}>
          <EmailVerificationRequest />
        </Route>
        <Route path={APPLICATION_ROUTES.EMAIL_VERIFICATION_SUCCESS}>
          <EmailVerificationSuccess />
        </Route>
        <Route key={ROUTE_KEY.ILLUMINATE} path={APPLICATION_ROUTES.ILLUMINATE}>
          <IlluminateMain />
        </Route>
        <Route path={APPLICATION_ROUTES.LIBRARY}>
          <Library />
        </Route>
        <Route path={APPLICATION_ROUTES.LIBRARY_PROECTED}>
          <LibraryProtected />
        </Route>
        <Route path={APPLICATION_ROUTES.MY_MAOR_CHECKOUT}>
          {/* <Redirect to={APPLICATION_ROUTES.COMING_SOON} /> */}
          <MyMaorCheckout />
        </Route>
        <Route path={APPLICATION_ROUTES.MY_MAOR_ORDER}>
          {/* <Redirect to={APPLICATION_ROUTES.COMING_SOON} /> */}
          <MyMaorOrder />
        </Route>

        <Route path={APPLICATION_ROUTES.MY_MAOR_MAIN_OLD}>
          <Redirect to={APPLICATION_ROUTES.MY_MAOR_MAIN} />
        </Route>
        <Route path={APPLICATION_ROUTES.MY_MAOR_PACKAGES}>
          {/* <Redirect to={APPLICATION_ROUTES.COMING_SOON} /> */}
          <MyMaorPackages />
        </Route>
        <Route path={APPLICATION_ROUTES.DONATION_REFUND_POLICY}>
          <DonationRefundPolicy />
        </Route>
        <Route path={APPLICATION_ROUTES.NOT_FOUND}>
          <NotFound />
        </Route>
        {/* <Route path={APPLICATION_ROUTES.PARTNERS_MAIN}>
          {/* <PartnersMain /> 
          <PartnersPageT
          est />
        </Route> */}
        <Route path={APPLICATION_ROUTES.PARTNERS_MAIN_HOME}>
          {/* <PartnersPageTest /> */}
          <Partners />
        </Route>
        <Route path={APPLICATION_ROUTES.HOME_PAGE}>
          <PartnersPageMain />
        </Route>
        <Route path={APPLICATION_ROUTES.SALE}>
          <Redirect to={APPLICATION_ROUTES.PARTNERS_PACKAGES} />
        </Route>
        <Route path={APPLICATION_ROUTES.VERIFY_EMAIL}>
          <VerifyEmail />
        </Route>
        <Route path={APPLICATION_ROUTES.PARTNERS_PACKAGES}>
          <PartnersPackages />
        </Route>
        <Route path={APPLICATION_ROUTES.BIRTHDAY_PACKAGES_PAGE}>
          <BirthdayPackagesPage />
        </Route>
        <Route path={APPLICATION_ROUTES.PASSWORD_RECOVERY_REQUEST}>
          <PasswordRecoveryRequest />
        </Route>
        <Route path={APPLICATION_ROUTES.PASSWORD_RECOVERY_SUCCESS}>
          <PasswordRecoverySuccess />
        </Route>
        <Route path={APPLICATION_ROUTES.PASSWORD_RECOVERY_UPDATE}>
          <PasswordRecoveryUpdate />
        </Route>
        <Route path={APPLICATION_ROUTES.PRIVACY_POLICY}>
          <PrivacyPolicy />
        </Route>
        <Route exact path={APPLICATION_ROUTES.LEGACY_GIFT}>
          <LegacyGift />
        </Route>
        <Route path={APPLICATION_ROUTES.PARTNER}>
          <Redirect to={APPLICATION_ROUTES.PARTNERS_MAIN_HOME} />
        </Route>
        <Route path={APPLICATION_ROUTES.PARTNERS}>
          <Redirect to={APPLICATION_ROUTES.PARTNERS_MAIN_HOME} />
        </Route>
        <Route path={APPLICATION_ROUTES.PLEDGE}>
          <MaorPledge />
        </Route>
        <Route path={APPLICATION_ROUTES.SIGN_UP_SHORT}>
          {authorization.isAuthenticated ? (
            <Redirect to={APPLICATION_ROUTES.HOME} />
          ) : (
            <SignUpPage />
          )}
        </Route>
        <Route path={APPLICATION_ROUTES.REFERRAL}>
          <Referral />
        </Route>
        <Route
          exact
          key={ROUTE_KEY.MY_MAOR}
          path={APPLICATION_ROUTES.MY_MAOR_MAIN}
        >
          {/* <Redirect to={APPLICATION_ROUTES.COMING_SOON} /> */}
          {/* <MyMaorMain /> */}
          <MyMaor />
        </Route>
        {/* <Route
          exact
          path={APPLICATION_ROUTES.MY_MAOR_MAIN_2}
        >
          <MyMaorMainCopy />
        </Route> */}
        <Route path={APPLICATION_ROUTES.TEST}>
          <TestPage />
        </Route>
        <Route
          exact
          path={APPLICATION_ROUTES.MY_MAOR_MAIN_ID}
          render={(props) => {
            let { id } = props.match.params;

            // console.log(id, "params")

            const isBase64 = (str) => {
              try {
                const decodedStr = atob(str);
                const lowerDecodedStr = decodedStr.toLowerCase();
                return ["k", "d", "i"].some((prefix) =>
                  lowerDecodedStr.startsWith(prefix)
                );
              } catch (err) {
                return false;
              }
            };

            if (isBase64(id)) {
              id = atob(id);
            }

            // Check the first character of the id
            const firstCharacter = id.charAt(0).toLowerCase();
            //Check if the remaining characters are only numbers
            const remainingCharacters = id.slice(1);
            const areRemainingCharactersAreNumbers =
              isOnlyNumbers(remainingCharacters);

            common.set("refreshMaorPage", true);

            if (areRemainingCharactersAreNumbers) {
              switch (firstCharacter.toLowerCase()) {
                case "k":
                  return <MyMaor />;
                case "h":
                  return <MyMaor />;
                case "i":
                  return <IlluminateMain />;
                case "d":
                  return <DailyRebbeVideoMain />;
                default:
                  return <NotFound />;
              }
            } else {
              return <NotFound />;
            }
          }}
        />

        <Route path={APPLICATION_ROUTES.PROFILE}>
          <Redirect to={APPLICATION_ROUTES.COMING_SOON} />
          {/* <Profile /> */}
        </Route>
        <Route path={APPLICATION_ROUTES.SIGN_IN}>
          {authorization.isAuthenticated ? (
            <Redirect to={APPLICATION_ROUTES.HOME} />
          ) : (
            <SignInPage />
          )}
        </Route>

        <Route path={APPLICATION_ROUTES.SIGN_UP}>
          {authorization.isAuthenticated ? (
            <Redirect to={APPLICATION_ROUTES.HOME} />
          ) : (
            <SignUpPage />
          )}
        </Route>
        <Route path={APPLICATION_ROUTES.MY_ACCOUNT.MAILING_LIST}>
          {/* {!authorization.isAuthenticated ? <Redirect to={APPLICATION_ROUTES.SIGN_IN} /> : <MailingList />} */}
          <MailingList />
        </Route>
        <Route path={APPLICATION_ROUTES.MY_ACCOUNT.PAYMENT_METHOD_ALL}>
          <List />
        </Route>
        <Route path={APPLICATION_ROUTES.MY_ACCOUNT.PAYMENT_METHOD}>
          <PaymentMethod />
        </Route>

        <Route path={APPLICATION_ROUTES.SUBSCRIPTION_CANCELED}>
          <UnsubscribePage />
        </Route>

        <Route exact path={APPLICATION_ROUTES.HOME}>
          {/* <Home /> */}
          {/* <Redirect to={APPLICATION_ROUTES.PARTNERS_MAIN} /> */}
          {isMobileDevice() ? (
            <Redirect to={APPLICATION_ROUTES.MY_MAOR_MAIN} />
          ) : (
            <Redirect to={APPLICATION_ROUTES.HOME_PAGE} />
          )}{" "}
          {/* <Redirect to={APPLICATION_ROUTES.MY_MAOR_MAIN} /> */}
        </Route>
        <Route path="*">
          <Redirect to={APPLICATION_ROUTES.NOT_FOUND} />
        </Route>
      </Switch>
      <CookieConsent
        cookieName="MaorCookieConsent"
        buttonText={
          navigator.cookieEnabled ? t("generals.cookies.agreeText") : " "
        }
        style={{ bottom: "80px" }}
        buttonStyle={
          navigator.cookieEnabled
            ? {
                backgroundColor: "#FF8A34",
                color: "#FFFFFF",
                borderRadius: "22px",
              }
            : {
                display: "none",
              }
        }
      >
        {navigator.cookieEnabled ? (
          <>
            {" "}
            {t("generals.cookies.message")}{" "}
            <Link to={APPLICATION_ROUTES.PRIVACY_POLICY}>
              {t("layout.privacy_policy")}
            </Link>{" "}
          </>
        ) : (
          <>
            {" "}
            <Trans
              components={{
                b: <b></b>,
              }}
              i18nKey="generals.cookies.disabled"
              t={t}
            />
          </>
        )}
      </CookieConsent>
    </Router>
  );
});

export default Routing;
