import { observer } from "mobx-react-lite";
import React from "react";

import classes from "./Unsubscribe.module.scss";
import cs from "classnames";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import logo from "../../../assets/images/logotype-maor.png";
import letterOne from "../../../assets/images/letter-1.png";
import letterTwo from "../../../assets/images/letter-2.png";
import letterThree from "../../../assets/images/letter-3.png";
import { STORAGE } from "../../../constants";

const UnsubscribePage = observer(() => {
  const openWhatsapp = () => {
    window.open(STORAGE.WHATSAPP_GROUP_LINK, "_blank");
  };

  return (
    <div className={cs(classes.container, "bgc-white", "wrapper-xl")}>
      <div className={cs(classes.logoContainer)}>
        <img src={logo} alt="maor-logo" />
      </div>
      <div className={cs(classes.imgContainer)}>
        <img src={letterOne} alt="img" className={cs(classes.upperLetter)} />
        <img
          src={letterTwo}
          alt="img"
          className={cs(classes.bottomRightLetter)}
        />
        <img
          src={letterThree}
          alt="img"
          className={cs(classes.bottomLeftLetter)}
        />
      </div>
      <div className={cs(classes.textContainer)}>
        <h3>We&apos;re sad to see you go! </h3>
        <p>You will be removed from our mailing list.</p>
      </div>

      <Button onClick={openWhatsapp} size="xs">
        {" "}
        <Icon name="whatsapp" size="s" /> Sign Up for the Whatsapp notifications{" "}
      </Button>
    </div>
  );
});

export default UnsubscribePage;
