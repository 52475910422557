import React from "react";
import cs from "classnames";

import PageTitle from "../../atoms/PageTitle";
import Layout from "../../templates/Layout";
import { CONTACTS, PAGE_TITLES } from "../../../constants";
import classes from "./DonationRefundPolicy.module.scss";

const DonationRefundPolicy = () => {
  return (
    <>
      <PageTitle value={PAGE_TITLES.DONATION_REFUND_POLICY} />
      <Layout>
        <div className={cs(classes.container, classes.wrapper, "wrapper-xl")}>
          <h2 className={classes.title}>Donation Refund Policy</h2>
          <p className="paragraph">
            If you have made an error in making your donation or change your mind about contributing to Maor.org, we
            will honor your request for a refund made within 15 days of your donation.
          </p>
          <p className="paragraph">
            To request a refund, call <a href={`tel:${CONTACTS.PHONE}`}>+1(718) 687-8900</a>. Refunds are returned using
            the original method of payment. If you made your donation by credit card, your refund will be credited to
            that same credit card.
          </p>
        </div>
      </Layout>
    </>
  );
};

export default React.memo(DonationRefundPolicy);
