import { observer } from "mobx-react-lite";
import React from "react";

import cs from "classnames";
import classes from "./OurStory.module.scss";
import telephone from "../../../../../assets/images/telephone.png";
import Icon from "../../../../atoms/Icon";

const OurStory = observer(() => {
  return (
    <div className={cs(classes.container, "wrapper-m")}>
      <div className={cs(classes.headingContainer)}>
        <h3>Our Story</h3>
        <p>
          “Maor” refers to both the Rebbe, our source of light, and the light
          (“or”) of Moshiach (“mem”).
          <br /> Everything the organization does comes back to the same
          mission: bringing Moshiach.
        </p>
      </div>
      <div className={cs(classes.content)}>
        <div className={cs(classes.imgContainer)}>
          <img src={telephone} alt="telephone"></img>
        </div>
        <div className={cs(classes.storyContainer)}>
          <div className={cs(classes.story)}>
            <div className={cs(classes.Icon)}>
              <Icon name="cloud-story" size="l" />
            </div>
            <p>
              It started on Gimmel Tamuz 5774 (2014), when a group of
              yungerleit were brainstorming how to move beyond a debilitating
              status quo and get serious about our mission to actually bring
              Moshiach.
            </p>
          </div>
          <div className={cs(classes.story)}>
            <div className={cs(classes.Icon)}>
              <Icon name="bulb" size="l" />
            </div>
            <p>
              At the time, devoted parents, dedicated educators and caring
              community members all shared the same concern: How can we pass on
              to the younger generation the sense of urgency the Rebbe always
              conveys when it comes to our sole mission of bringing Moshiach?
            </p>
          </div>
          <div className={cs(classes.story)}>
            <div className={cs(classes.Icon)}>
              <Icon name="chat" size="l" />
            </div>
            <p>
              By sending inspirational clips of the Rebbe via WhatsApp,{" "}
              <span> Rabbi Chanoch Chaskind </span> realized it was possible to
              use the popular social media and instant messaging platform to
              bring the Rebbe directly in his voice to Chassidim worldwide,
              young and old alike.
            </p>
          </div>

          <div className={cs(classes.story, classes.maorStory)}>
            <div className={cs(classes.Icon)}>
              <Icon name="maor-story" size="xl" />
            </div>
            <p>
              Within a month, Rabbi Chaskind collaborated with the Israel-based
              <span className={cs(classes.italic)}>
                {" "}
                Lirot Et Malkeinu{" "}
              </span>{" "}
              organization and Maor launched the Daily Rebbe videos in English
              for adults.
            </p>
          </div>
          <span className={cs(classes.ml)}>The feedback was outstanding.</span>
          <div className={cs(classes.story, classes.maorStory)}>
            <div className={cs(classes.Icon)}>
              {" "}
              <Icon name="child-story" size="xl" />
            </div>
            <p>
              As more and more families subscribed, Maor decided to take this up
              a notch and help young children to be inspired directly from the
              Rebbe too.
            </p>
          </div>
          <div className={cs(classes.story)}>
            <div className={cs(classes.Icon)}>
              {" "}
              <Icon name="increase" size="l" />
            </div>
            <p>
              That Elul, Maor ran its very first campaign that raised $25,000
              and the first MyMaor video was launched on Yud Tes Kislev. In the
              years since, Maor has expanded to include - in addition to the
              daily Rebbe videos -- a rotation of engaging videos for kids
              produced to date in three additional languages, Hebrew, French and
              Russian and since Elul 5780 (September 2020) also Illuminate, an
              illustrated series for adult beginners.
            </p>
          </div>
          <h4 className={cs(classes.ml)}>
            Today, thanks to Maor, thousands of families live with Rebbe.{" "}
          </h4>
        </div>
      </div>
    </div>
  );
});

export default React.memo(OurStory);
