import { API_ROUTES } from "../constants";
import API from "./_api";


const AdAPI = class extends API {
    getAd() {
        return this.request(API_ROUTES.AD, {
            method: "GET",
        })
    }
}

export default AdAPI;