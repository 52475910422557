import MESSAGES from "./messages";
import NAMES from "./names";
import REGEX from "./regex";

class Validation {
  constructor({ value }) {
    this.value = value;
    this.errors = [];
  }

  [NAMES.CARD_EXPIRATION_DATE]({ message = MESSAGES[NAMES.CARD_EXPIRATION_DATE] } = {}) {
    return this.pattern({
      message: message,
      regex: REGEX[NAMES.CARD_EXPIRATION_DATE],
    });
  }

  [NAMES.CHARACTERS_DIGITS_SYMBOLS]({ message = MESSAGES[NAMES.CHARACTERS_DIGITS_SYMBOLS] } = {}) {
    return this.pattern({
      message: message,
      regex: REGEX[NAMES.CHARACTERS_DIGITS_SYMBOLS],
    });
  }

  [NAMES.COMPLETED_PHONE_NUMBER]({ code, format, message = MESSAGES[NAMES.COMPLETED_PHONE_NUMBER] } = {}) {
    const countries = ["by", "de", "ca", "es", "fr", "gb", "il", "it", "pl", "ru", "ua", "us"];
    const shouldBeChecked = countries.includes(code);

    if (shouldBeChecked) {
      const totalLength = format.split("").reduce((total, item) => total + +(item === "."), 0);
      const currentLength = this.value.length;
      const isNotCompleted = currentLength !== totalLength;

      if (isNotCompleted) this.errors.push(message);
    }

    return this;
  }

  [NAMES.DIGITS]({ message = MESSAGES[NAMES.DIGITS] } = {}) {
    return this.pattern({
      message: message,
      regex: REGEX[NAMES.DIGITS],
    });
  }

  [NAMES.DIGITS_DOTS]({ message = MESSAGES[NAMES.DIGITS_DOTS] } = {}) {
    return this.pattern({
      message: message,
      regex: REGEX[NAMES.DIGITS_DOTS],
    });
  }

  [NAMES.EMAIL]({ message = MESSAGES[NAMES.EMAIL] } = {}) {
    return this.pattern({
      message: message,
      regex: REGEX[NAMES.EMAIL],
    });
  }

  [NAMES.EQUAL]({ message = MESSAGES[NAMES.EQUAL], value } = {}) {
    const isNotEqual = this.value !== value;

    if (isNotEqual) this.errors.push(message);

    return this;
  }

  [NAMES.GREATER_THAN_VALUE]({ message = MESSAGES[NAMES.GREATER_THAN_VALUE], value = 0 } = {}) {
    if (this.value === "") return this;

    const currentValue = Number(this.value);
    const isNotGreater = !(currentValue > value);

    if (isNotGreater) this.errors.push(message);

    return this;
  }

  [NAMES.HEBREW_LATIN]({ message = MESSAGES[NAMES.HEBREW_LATIN] } = {}) {
    return this.pattern({
      message: message,
      regex: REGEX[NAMES.HEBREW_LATIN],
    });
  }

  [NAMES.HEBREW_LATIN_DIGITS]({ message = MESSAGES[NAMES.HEBREW_LATIN_DIGITS] } = {}) {
    return this.pattern({
      message: message,
      regex: REGEX[NAMES.HEBREW_LATIN_DIGITS],
    });
  }

  [NAMES.HEBREW_LATIN_DIGITS_SYMBOLS]({ message = MESSAGES[NAMES.HEBREW_LATIN_DIGITS_SYMBOLS] } = {}) {
    return this.pattern({
      message: message,
      regex: REGEX[NAMES.HEBREW_LATIN_DIGITS_SYMBOLS],
    });
  }

  [NAMES.HEBREW_LATIN_SPACES]({ message = MESSAGES[NAMES.HEBREW_LATIN_SPACES] } = {}) {
    return this.pattern({
      message: message,
      regex: REGEX[NAMES.HEBREW_LATIN_SPACES],
    });
  }

  [NAMES.LATIN]({ message = MESSAGES[NAMES.LATIN] } = {}) {
    return this.pattern({
      message: message,
      regex: REGEX[NAMES.LATIN],
    });
  }

  [NAMES.LATIN_DIGITS]({ message = MESSAGES[NAMES.LATIN_DIGITS] } = {}) {
    return this.pattern({
      message: message,
      regex: REGEX[NAMES.LATIN_DIGITS],
    });
  }

  [NAMES.HEBREW_LATIN_SPACES_HYPHENS]({ message = MESSAGES[NAMES.HEBREW_LATIN_SPACES_HYPHENS] } = {}) {
    return this.pattern({
      message: message,
      regex: REGEX[NAMES.HEBREW_LATIN_SPACES_HYPHENS],
    });
  }

  [NAMES.MAX_10]({ message = MESSAGES[NAMES.MAX_10] } = {}) {
    return this.pattern({
      message: message,
      regex: REGEX[NAMES.MAX_10],
    });
  }

  [NAMES.MAX_64]({ message = MESSAGES[NAMES.MAX_64] } = {}) {
    return this.pattern({
      message: message,
      regex: REGEX[NAMES.MAX_64],
    });
  }

  [NAMES.MIN_FOUR_SYMBOLS]({ message = MESSAGES[NAMES.MIN_FOUR_SYMBOLS] } = {}) {
    return this.pattern({
      message: message,
      regex: REGEX[NAMES.MIN_FOUR_SYMBOLS],
    });
  }

  [NAMES.MIN_FIVE_MAX_FIFTEEN_SYMBOLS]({ message = MESSAGES[NAMES.MIN_FIVE_MAX_FIFTEEN_SYMBOLS] } = {}) {
    return this.pattern({
      message: message,
      regex: REGEX[NAMES.MIN_FIVE_MAX_FIFTEEN_SYMBOLS],
    });
  }

  [NAMES.MIN_SIX_SYMBOLS]({ message = MESSAGES[NAMES.MIN_SIX_SYMBOLS] } = {}) {
    return this.pattern({
      message: message,
      regex: REGEX[NAMES.MIN_SIX_SYMBOLS],
    });
  }

  [NAMES.MIN_2]({ message = MESSAGES[NAMES.MIN_2] } = {}) {
    return this.pattern({
      message: message,
      regex: REGEX[NAMES.MIN_2],
    });
  }

  [NAMES.MIN_3]({ message = MESSAGES[NAMES.MIN_3] } = {}) {
    return this.pattern({
      message: message,
      regex: REGEX[NAMES.MIN_3],
    });
  }

  [NAMES.MIN_5]({ message = MESSAGES[NAMES.MIN_5] } = {}) {
    return this.pattern({
      message: message,
      regex: REGEX[NAMES.MIN_5],
    });
  }

  [NAMES.PHONE]({ message = MESSAGES[NAMES.PHONE] } = {}) {
    return this.pattern({
      message: message,
      regex: REGEX[NAMES.PHONE],
    });
  }

  [NAMES.PATTERN]({ message, regex } = {}) {
    const isNotValid = !regex.test(this.value);
    if (isNotValid) this.errors.push(message);

    return this;
  }

  [NAMES.REQUIRED]({ message = MESSAGES[NAMES.REQUIRED] } = {}) {
    if (this.value === null || this.value === undefined){
      this.errors.push(message);
      return this.pattern({
        message: message,
        regex: REGEX[NAMES.REQUIRED],
      });
    }

    return this.pattern({
      message: message,
      regex: REGEX[NAMES.REQUIRED],
    });
  }
}

export { REGEX };
export default Validation;
