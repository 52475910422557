import { default as SingleLayer } from "../../../assets/svg/single-layer.svg";
import { default as DoubleLayer } from "../../../assets/svg/double-layer.svg";
import { default as TripleDecker } from "../../../assets/svg/triple-decker.svg";
import { default as SLayerExplosion } from "../../../assets/svg/7-layer-explosion.svg";

import { APPLICATION_ROUTES, PROJECTS } from "../../../constants";

const COVERS = {
  ANIMATION: {
    icon: "video-tape",
    title: {
      key: "pages.partners.main.sections.packages.items.11",
    },
  },
  LIVE_ARTIST: {
    icon: "person-and-musical-note",
    title: {
      key: "pages.partners.main.sections.packages.items.10",
    },
  },
  RESEARCH: {
    icon: "book-and-magnifier",
    title: {
      key: "pages.partners.main.sections.packages.items.8",
    },
  },
  SCRIPTING: {
    icon: "text-symbol",
    title: {
      key: "pages.partners.main.sections.packages.items.9",
    },
  },
};

const FEATURES = {
  BIRTHDAY_ANNOUNCEMENT: {
    classes: "",
    icon: "cake",
    title: {
      key: "pages.birthdays.main.sections.packages.items.1",
    },
    tooltip: {
      key: "",
    },
  },
  PERSONAL_POSTER: {
    classes: "",
    icon: "personal-poster",
    title: {
      key: "pages.birthdays.main.sections.packages.items.2",
    },
    tooltip: {
      key: "",
    },
  },
  FULL_PARTY_GIFT_PACKAGE: {
    classes: "",
    icon: "full-gift-party",
    title: {
      key: "pages.birthdays.main.sections.packages.items.3",
    },
    tooltip: {
      key: "pages.birthdays.main.sections.packages.tooltips.3",
    },
  },
  TOUR_OF_MAOR_STUDIOS: {
    classes: "",
    icon: "tour",
    title: {
      key: "pages.birthdays.main.sections.packages.items.4",
    },
    tooltip: {
      key: "pages.birthdays.main.sections.packages.tooltips.4",
    },
  },
  CELEBRATION_DEDICATION: {
    classes: "fw-700",
    icon: "celebration",
    title: {
      key: "pages.birthdays.main.sections.packages.items.5",
    },
    tooltip: {
      key: "pages.birthdays.main.sections.packages.tooltips.5",
    },
  },
  COMIC_BOOK: {
    classes: "",
    icon: "book-and-magnifier",
    title: {
      key: "pages.birthdays.main.sections.packages.items.6",
    },
    tooltip: {
      key: "pages.birthdays.main.sections.packages.tooltips.6",
      icon: "comic-tooltip"
    },
  },
  PERSONALIZED_MESSAGE: {
    classes: "",
    icon: "star",
    title: {
      key: "pages.birthdays.main.sections.packages.items.7",
    },
    tooltip: {
      key: "pages.birthdays.main.sections.packages.tooltips.7",
      // icon: "message-tooltip"
    },
  },
};

const items = [
  {
    ID: PROJECTS.MY_MAOR.PACKAGES.SINGLE_LAYER.ID,
    cost: PROJECTS.MY_MAOR.PACKAGES.SINGLE_LAYER.COST,
    covers: [COVERS.RESEARCH],
    features: [FEATURES.BIRTHDAY_ANNOUNCEMENT],
    illustration: SingleLayer,
    marked: false,
    gradientClass: "",
    title: { key: "pages.birthdays.main.sections.packages.subtitles.1" },
    url: `${APPLICATION_ROUTES.MY_MAOR_ORDER}?type=${PROJECTS.MY_MAOR.PACKAGES.SINGLE_LAYER.ID}`,
  },
  {
    ID: PROJECTS.MY_MAOR.PACKAGES.DOUBLE_LAYER.ID,
    cost: PROJECTS.MY_MAOR.PACKAGES.DOUBLE_LAYER.COST,
    covers: [COVERS.SCRIPTING],
    features: [FEATURES.BIRTHDAY_ANNOUNCEMENT, FEATURES.FULL_PARTY_GIFT_PACKAGE],
    illustration: DoubleLayer,
    marked: true,
    gradientClass: "",
    title: { key: "pages.birthdays.main.sections.packages.subtitles.2" },
    url: `${APPLICATION_ROUTES.MY_MAOR_ORDER}?type=${PROJECTS.MY_MAOR.PACKAGES.DOUBLE_LAYER.ID}`,
  },
  {
    ID: PROJECTS.MY_MAOR.PACKAGES.TRIPLE_DECKER.ID,
    cost: PROJECTS.MY_MAOR.PACKAGES.TRIPLE_DECKER.COST,
    covers: [COVERS.LIVE_ARTIST],
    features: [FEATURES.CELEBRATION_DEDICATION, FEATURES.BIRTHDAY_ANNOUNCEMENT, FEATURES.FULL_PARTY_GIFT_PACKAGE, FEATURES.COMIC_BOOK],
    illustration: TripleDecker,
    marked: false,
    gradientClass: " ",
    title: { key: "pages.birthdays.main.sections.packages.subtitles.3" },
    url: `${APPLICATION_ROUTES.MY_MAOR_ORDER}?type=${PROJECTS.MY_MAOR.PACKAGES.TRIPLE_DECKER.ID}`,
  },
  {
    ID: PROJECTS.MY_MAOR.PACKAGES.SEVEN_LAYER_EXPLOSION.ID,
    cost: PROJECTS.MY_MAOR.PACKAGES.SEVEN_LAYER_EXPLOSION.COST,
    covers: [COVERS.ANIMATION],
    features: [FEATURES.CELEBRATION_DEDICATION, FEATURES.BIRTHDAY_ANNOUNCEMENT, FEATURES.FULL_PARTY_GIFT_PACKAGE, FEATURES.COMIC_BOOK, FEATURES.PERSONALIZED_MESSAGE],
    illustration: SLayerExplosion,
    marked: false,
    gradientClass: " ",
    title: { key: "pages.birthdays.main.sections.packages.subtitles.4" },
    url: `${APPLICATION_ROUTES.MY_MAOR_ORDER}?type=${PROJECTS.MY_MAOR.PACKAGES.SEVEN_LAYER_EXPLOSION.ID}`,
  },
];

export { items };
