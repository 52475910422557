import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";

import { createLanguages, getDetails, getURL } from "./utilities";
import classes from "./Preview.module.scss";
import Icon from "../Icon";
import cs from "classnames";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../../../contexts";
import { APPLICATION_ROUTES } from "../../../constants";
import Loader from "../Loader";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { useModal } from "../../../hooks";
import { Authorization } from "../../organisms/RestrictionModal/RestrictionModal";

const Preview = observer(
  ({
    locales,
    categories,
    questions,
    tags,
    file,
    holiday,
    placeholder,
    onSelect,
    project,
    projectType,
  }) => {
    // const {
    //   // t,
    //   i18n: { language },
    // } = useTranslation();

    const { playlist, authorization, partnership } = useContext(StoreContext);

    const downloadFile = (url) => {
      window.open(url);
    };

    const [descriptionShown, setDescriptionShown] = useState(false);

    // const details = getDetails(language, locales);
    const details = getDetails("en", locales);
    const languages = createLanguages(locales);

    const addClasses = () => {
      if (project == "illuminate") {
        return classes.illuminate;
      }
      if (project == "daily-rebbe-video") {
        return classes.dailyRebbeVideo;
      }
      return classes[project];
    };

    const showModal = useModal();

    return (
      <div className={cs(classes.container, addClasses())}>
        <div
          className={cs(isMobile ? classes.mobilePreview : "", classes.preview)}
          role="button"
          style={{
            backgroundImage: `url(${details.thumbnail || placeholder})`,
          }}
        >
          <div
            onClick={() => {
              playlist.setCurrentPlayingVideoId(details.id);
              onSelect({
                end: details.end_time,
                start: details.start_time,
                url: getURL(details.link, { start: details.start_time }),
                name: details.name,
                description: details.description,
                number: details.number,
                categories: categories,
                questions: questions,
                tags: tags,
                holiday: holiday,
                date: details.created_at,
                file: file,
              });
            }}
            className={cs(classes.playerControlWrapper)}
          >
            <Icon className={cs(classes.playerPlay)} name="player-play" />
            {details.duration_formatted && (
              <span className={cs(classes.videoTime)}>
                {details.duration_formatted}
              </span>
            )}
          </div>

          {file && (
            <div
              onClick={() => {
                if (authorization.isUserMember) {
                  downloadFile(file);
                } else {
                  if (
                    !location.pathname.includes(
                      APPLICATION_ROUTES.LIBRARY_PROECTED
                    )
                  ) {
                    playlist.setCurrentPlayingVideoId(details.id);
                    onSelect({
                      end: details.end_time,
                      start: details.start_time,
                      url: getURL(details.link, { start: details.start_time }),
                      name: details.name,
                      description: details.description,
                      number: details.number,
                      categories: categories,
                      questions: questions,
                      tags: tags,
                      holiday: holiday,
                      date: details.created_at,
                      file: details.file,
                    });
                  }
                }
              }}
              className={cs(classes.downloadButton)}
            >
              <Icon name="pdf" />
            </div>
          )}

          <div className={cs(classes.watchlistButton)}>
            {playlist.loadingIds.includes(details.id) ? (
              <Loader />
            ) : (
              <Icon
                onClick={() => {
                  if (authorization.isAuthenticated) {
                    if (
                      !authorization.isUserMember &&
                      !projectType == "daily-rebbe"
                    ) {
                      showModal.onOpen();
                      return;
                    }
                  }

                  if (authorization.isAuthenticated) {
                    console
                    if (
                      authorization.isUserMember ||
                      projectType == "daily-rebbe"
                    ) {
                      playlist.handleAddDelete(details.id);
                    } else {
                      if (
                        !location.pathname.includes(
                          APPLICATION_ROUTES.LIBRARY_PROECTED
                        )
                      ) {
                        playlist.setCurrentPlayingVideoId(details.id);
                        onSelect({
                          end: details.end_time,
                          start: details.start_time,
                          url: getURL(details.link, {
                            start: details.start_time,
                          }),
                          name: details.name,
                          description: details.description,
                          number: details.number,
                          categories: categories,
                          questions: questions,
                          tags: tags,
                          holiday: holiday,
                          date: details.created_at,
                          file: details.file,
                        });
                      }
                    }
                  }
                }}
                title={
                  !authorization.isAuthenticated
                    ? "Please sign in to add videos to Playlist"
                    : ""
                }
                color="primary"
                cursorPointer={true}
                name={
                  authorization.isAuthenticated &&
                  playlist.videoExists(details.id)
                    ? "exit"
                    : "watchlist-button"
                }
              />
            )}
          </div>
        </div>

        <div
          className={cs(isMobile ? classes.mobileDetails : "", classes.details)}
        >
          {isMobile ? (
            <MobileView>
              <div className={classes.videoHeader}>
                <h1>
                  <div>
                    <strong>{details.name}</strong>
                  </div>
                  <span className={classes.videoDetails}>
                    #{details.number}
                    {categories.length > 0 && <i></i>}
                    {categories.length &&
                      categories.map((category) => {
                        return category.name_english;
                      })}
                  </span>
                </h1>

                {details.description && (
                  <Icon
                    onClick={() => {
                      setDescriptionShown(!descriptionShown);
                    }}
                    name={descriptionShown ? "arrow-dropup" : "arrow-dropdown"}
                  />
                )}
              </div>
              <div className={cs(descriptionShown ? "d-block" : "d-none")}>
                {details.description}
              </div>
            </MobileView>
          ) : (
            <BrowserView>
              <h4 className={classes.title}>
                <span>#{details.number} | </span>{" "}
                <strong>{details.name}</strong>
              </h4>
              <div className={classes.languageViewsContainer}>
                <ul className={classes.languages}>
                  {languages.map((item, index) => (
                    <li className={classes.item} key={`${item.link}${index}`}>
                      <button
                        className={classes.button}
                        onClick={() => {
                          playlist.setCurrentPlayingVideoId(item.id);
                          onSelect({
                            end: item.end_time,
                            start: item.start_time,
                            url: getURL(item.link, { start: item.start_time }),
                            name: item.name,
                            description: item.description,
                            number: item.number,
                            categories: categories,
                            questions: questions,
                            tags: tags,
                            holiday: holiday,
                            date: item.created_at,
                            file: file,
                          });
                        }}
                      >
                        {item.language}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </BrowserView>
          )}
        </div>
        <Authorization
          popup={showModal}
          authorization={authorization}
          partnership={partnership}
        />
      </div>
    );
  }
);

Preview.propTypes = {
  id: PropTypes.string.isRequired,
  locales: PropTypes.shape({
    // en: PropTypes.shape({
    //   link: PropTypes.string.isRequired,
    // }),
  }).isRequired,
  placeholder: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  project: PropTypes.string,
};

Preview.defaultProps = {
  project: "",
};

export default React.memo(Preview);
