import JSCookie from "js-cookie";

class Cookies {
  static get({ name }) {
    return JSCookie.get(name);
  }

  static set({ name, value, options }) {
    JSCookie.set(name, value, options);
  }

  static delete({ name }) {
    JSCookie.remove(name);
  }
}

export default Cookies;
