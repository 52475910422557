import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cs from "classnames";

import PlainInput from "../../../../atoms/PlainInput";
import SelectR from "../../../../atoms/SelectR";
import TextArea from "../../../../atoms/TextArea";
import classes from "./DeliveryDetails.module.scss";
import Autocomplete from "react-google-autocomplete";

import Loader from "../../../../atoms/Loader";
import { StoreContext } from "../../../../../contexts";
import { ACTIONS } from "../../../../../store";
import { useModal, useQuery } from "../../../../../hooks";
import { animateScroll as scroll } from "react-scroll";
import Icon, { NAMES } from "../../../../atoms/Icon";
import Modal from "../../../../organisms/Modal";
import Button, { COLORS } from "../../../../atoms/Button";
import { observer } from "mobx-react-lite";

// eslint-disable-next-line react/prop-types
const DeliveryDetails = observer(({
  address_id,
  deliveryApartment,
  deliveryCity,
  deliveryCountry,
  deliveryState,
  deliveryStreet,
  deliveryZip,
  from,
  message,
  to,
  addAddress,
  setAddAddress,
  // hasDelivery,
  // hasDelivery,
  // location,
  setLocation,
  setDeleteAddressSuccess,
  deleteAddressSuccess
}) => {
  const { t } = useTranslation();
  const ADDRESS = t("projects.maor-kids.5_address");
  const CHARACTERS_LEFT = t("forms.labels.characters_left");
  const WHERE_IS_THE_GIFT_PACKAGE_GOING = t(
    "projects.maor-kids.where_is_the_gift_package_going"
  );
  const WISHES_OPTIONAL = t("projects.maor-kids.6_wishes_optional");
  const [myAddresses, setMyAddresses] = useState(null);
  const { authorization } = useContext(StoreContext);
  const query = useQuery();
  const deleteModal = useModal();
  const [selectedAddressId, setSelectedAddressId] = useState(null);

  const deleteAddressError = authorization[`error${ACTIONS.AUTHORIZATION.DELETE_ADDRESS}`];
  const deleteAddressLoading = authorization[`isLoading${ACTIONS.AUTHORIZATION.DELETE_ADDRESS}`];

  console.log(authorization[`success${ACTIONS.AUTHORIZATION.DELETE_ADDRESS}`], "deleteAddressLoading")

  const handleAddAddress = () => {
    setAddAddress(!addAddress);
  };

  useEffect(() => {
    setDeleteAddressSuccess(authorization[`success${ACTIONS.AUTHORIZATION.DELETE_ADDRESS}`]);
    if (deleteAddressSuccess) {
      deleteModal.onClose();
      authorization[`setSuccess`](ACTIONS.AUTHORIZATION.DELETE_ADDRESS, false);
    }
  }, [
    authorization[`success${ACTIONS.AUTHORIZATION.DELETE_ADDRESS}`],
    authorization[`isLoading${ACTIONS.AUTHORIZATION.DELETE_ADDRESS}`],
    authorization,
  ]);

  useEffect(() => {
    // if (query.get("id")) {
    //   setAddAddress(true);
    // }

    if (authorization.address.length == 0 || query.get("id")) {
      setAddAddress(true)
    }
  }, [authorization.address.length, authorization[`isLoading${ACTIONS.AUTHORIZATION.GET_ADDRESS}`],
  ]);

  useEffect(() => {
    setMyAddresses(authorization.address);
  }, [authorization[`isLoading${ACTIONS.AUTHORIZATION.GET_ADDRESS}`]]);


  const addNewAddress = () => {
    address_id.onChange({ target: { value: "" }, touched: false });
    deliveryState.onChange({ target: { value: " " }, touched: false });
    deliveryStreet.onChange({ target: { value: " " }, touched: false });
    deliveryCity.onChange({ target: { value: " " }, touched: false });
    deliveryZip.onChange({ target: { value: " " }, touched: false });
    deliveryApartment.onChange({ target: { value: " " }, touched: false });

    handleAddAddress();
  }

  if (authorization[`isLoading${ACTIONS.AUTHORIZATION.GET_ADDRESS}`]) {
    return (
      <div className={classes.container}>
        <div className={cs(classes.wrapper)}>
          <Loader />
        </div>
      </div>
    );
  }

  const handlePickAddressClick = (selectedAddress) => {
    address_id.onChange({ target: { value: selectedAddress.id } });
    deliveryState.onChange({ target: { value: selectedAddress.state } });
    deliveryStreet.onChange({ target: { value: selectedAddress.street } });
    deliveryCity.onChange({ target: { value: selectedAddress.city } });
    deliveryZip.onChange({ target: { value: selectedAddress.zip_code } });
    deliveryApartment.onChange({
      target: { value: selectedAddress.apartment },
    });

    handleAddAddress();
    scroll.scrollToTop();
  };

  const handlePlaceSelected = (place) => {

    console.log("Selected place", place);
    // Extract city, state, and country from address components
    const addressComponents = place.address_components || [];
    let city = getAddressComponent(addressComponents, "locality") || "";
    const state = getAddressComponent(addressComponents, "administrative_area_level_1") || "";
    let country = getAddressComponent(addressComponents, "country") || "";
    const street_number = getAddressComponent(addressComponents, "street_number") || "";
    let street = getAddressComponent(addressComponents, "route") || "";
    const postalCode = getAddressComponent(addressComponents, "postal_code") || "";
    const subLocality = getAddressComponent(addressComponents, "sublocality_level_1") || "";

    if (country && (country == "United States" || country == "USA" || country == "US")) {
      country = { label: "USA", value: "USA" };
    }

    if (street_number) {
      street = `${street_number} ${street}`;
    }

    if (!city) {
      city = subLocality;
    }

    document.querySelector(".pac-target-input").value = street;

    setLocation({
      city,
      state,
      country,
      street,
      postalCode
    });
  };

  const getAddressComponent = (addressComponents, type) => {
    const component = addressComponents.find(component => component.types.includes(type));
    return component ? component.long_name : "";
  };

  const deleteAddress = () => {
    authorization[`${ACTIONS.AUTHORIZATION.DELETE_ADDRESS}`](selectedAddressId)
    console.log("Delete child with ID:", selectedAddressId, deleteAddressSuccess);
    if (deleteAddressSuccess) {
      deleteModal.onClose();
    }
  }

  return (
    <div>
      <h2 className="heading-l ta-center c-blue-dark">
        {WHERE_IS_THE_GIFT_PACKAGE_GOING}
      </h2>

      {!addAddress ? (
        <div className={classes.addressCardContainer}>
          <div className={classes.addAddressBox} onClick={addNewAddress}>
            <p style={{ fontSize: "32px" }}>+</p>
            <p style={{ fontSize: "18px", marginTop: "8px" }}>Add Address</p>
          </div>


          {myAddresses?.map((data, i) => {
            return (
              <div
                className={classes.pickAddressBox}
                key={i}
                onClick={() => handlePickAddressClick(data)}
              >
                <div className={cs(classes.crossButton)} onClick={(e) => {
                  setSelectedAddressId(data.id);
                  e.stopPropagation();
                  deleteModal.onOpen();
                }}>
                  <Icon name={NAMES.CrossButton} size="xxs" />
                </div>
                <div className={classes.addressBoxContent}>
                  <p style={{ fontSize: "16px" }}>{data.street}{" "}{data.apartment}</p>
                </div>

                <div className={classes.addressBoxContent}>
                  <p style={{ fontSize: "16px" }}>
                    {data.city}, {data.state}
                  </p>
                </div>

                <div className={classes.addressBoxContent}>
                  <p style={{ fontSize: "16px" }}>
                    {data.country} {data.zip_code}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          <h3 className="heading-m mt-04 c-blue">{ADDRESS}</h3>
          <ul className={cs(classes.address, classes.top)}>
            <li className={cs(classes.item, classes.street)}>
              <PlainInput
                error={deliveryStreet.error}
                id="my-maor-order-form-delivery-street"
                label={deliveryStreet.label}
                stretched
                value={deliveryStreet.value}
                onChange={deliveryStreet.onChange}
                customComponent={(additionalProps) => (<Autocomplete
                  {...additionalProps}
                  options={{
                    types: ["address"],
                    fields: [
                      "address_components",
                      "geometry.location",
                      "place_id",
                      "formatted_address",
                    ],
                    componentRestrictions: { country: "us" },
                  }}
                  onChange={deliveryStreet.onChange}
                  defaultValue={deliveryStreet.value}
                  placeholder=""
                  apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                  onPlaceSelected={handlePlaceSelected}
                  style={{ width: "100%", borderRadius: "8px", borderColor: "#C1BBBB", color: "#636363", borderWidth: "1px", borderStyle: "solid", padding: "17px 16px", fontSize: "18px", fontWeight: "bold" }}
                />)}
              />
            </li>
            <li className={cs(classes.item, classes.city)}>
              <PlainInput
                error={deliveryApartment.error}
                id="my-maor-order-form-delivery-apartment"
                label={deliveryApartment.label}
                value={deliveryApartment.value}
                onChange={deliveryApartment.onChange}
              />
            </li>
          </ul>
          <ul className={cs(classes.address, classes.bottom)}>
            <li className={cs(classes.item, classes.city)}>
              <PlainInput
                error={deliveryCity.error}
                id="my-maor-order-form-delivery-city"
                label={deliveryCity.label}
                value={deliveryCity.value}
                onChange={deliveryCity.onChange}
              />
            </li>
            <li className={cs(classes.item, classes.state)}>
              <PlainInput
                error={deliveryState.error}
                id="my-maor-order-form-delivery-state"
                label={deliveryState.label}
                value={deliveryState.value}
                onChange={deliveryState.onChange}
              />
            </li>
            <li className={cs(classes.item, classes.zip)}>
              <PlainInput
                error={deliveryZip.error}
                id="my-maor-order-form-delivery-zip"
                label={deliveryZip.label}
                value={deliveryZip.value}
                onChange={deliveryZip.onChange}
              />
            </li>
            <li className={cs(classes.item, classes.country)}>
              <SelectR
                label={deliveryCountry.label}
                options={deliveryCountry.options}
                value={deliveryCountry.value}
                onChange={deliveryCountry.onChange}
              />
              <small className={cs(classes.redText, "ta-center w-100 d-block")}>
                We are currently shipping only within USA.
              </small>
            </li>
          </ul>
          <h3 className="heading-m mt-12 c-blue">{WISHES_OPTIONAL}</h3>
          <ul className={classes.wishes}>
            <li className={cs(classes.item, classes.from)}>
              <PlainInput
                error={from.error}
                id="my-maor-order-form-from"
                label={from.label}
                value={from.value}
                onChange={from.onChange}
              />
            </li>
            <li className={cs(classes.item, classes.to)}>
              <PlainInput
                error={to.error}
                id="my-maor-order-form-to"
                label={to.label}
                value={to.value}
                onChange={to.onChange}
              />
            </li>
            <li className={cs(classes.item, classes.message)}>
              <TextArea
                error={message.error}
                id="my-maor-order-form--message"
                label={message.label}
                max="100"
                stretched
                title={CHARACTERS_LEFT}
                value={message.value}
                onChange={message.onChange}
              />
            </li>
          </ul>
        </div>
      )}
      <Modal opened={deleteModal.isOpened}>
        <div className={classes.deleteModalConatiner}>
          <div className={classes.deleteModalCard}>
            <div className={cs(classes.main)}>
              <h4 className="title">Are you sure you want to delete this record?</h4>
            </div>
            {
              deleteAddressError && <p>Something Went Wrong</p>
            }
            <div className={cs("d-flex jc-center gap-10 mt-07")}>
              <button
                className={cs(classes.addBtn, "fw-700 c-orange")}
                onClick={deleteAddress}
                disabled={deleteAddressLoading}
              >
                {deleteAddressLoading ? (
                  "Please wait..."
                ) : (
                  <>
                    <p>Confirm</p>
                  </>
                )}
              </button>
              <Button color={COLORS.GHOST} onClick={deleteModal.onClose}>
                <p>Cancel</p>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});

const inputPropTypes = PropTypes.shape({
  error: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  emptyErrors: PropTypes.func.isRequired
}).isRequired;

const selectPropTypes = PropTypes.shape({
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  emptyErrors: PropTypes.func.isRequired,
  error: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  bool: PropTypes.bool,
  selectedValue: PropTypes.string,
}).isRequired;

DeliveryDetails.propTypes = {
  deliveryApartment: inputPropTypes,
  deliveryCity: inputPropTypes,
  deliveryCountry: selectPropTypes,
  deliveryState: inputPropTypes,
  deliveryStreet: inputPropTypes,
  deliveryZip: inputPropTypes,
  message: inputPropTypes,
  from: inputPropTypes,
  to: inputPropTypes,
  address_id: inputPropTypes,
  setAddAddress: selectPropTypes,
  addAddress: selectPropTypes,
  // hasDelivery: PropTypes.bool.isRequired,
  hasDelivery: PropTypes.bool.isRequired,
  setLocation: inputPropTypes,
  location: inputPropTypes,
  deleteAddressSuccess: selectPropTypes,
  setDeleteAddressSuccess: selectPropTypes
};

export default DeliveryDetails;
