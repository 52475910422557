import { makeAutoObservable } from "mobx";

import { CartAPI } from "../../api";
import { COOKIES } from "../../constants";
import Cookies from "../../utilities/cookies";
import { ACTIONS } from "./utilities";

const cartAPI = new CartAPI();

class Cart {
  constructor() {
    const actions = Object.values(ACTIONS);

    for (let i = 0; i < actions.length; i++) {
      const ACTION = actions[i];

      this[`isLoading${ACTION}`] = false;
      this[`error${ACTION}`] = "";
    }

    this.items = [];

    this.cartType = "";

    makeAutoObservable(this);
  }

  get isEmpty() {
    return !this.items.length;
  }

  get isNotEmpty() {
    return !!this.items.length;
  }

  setIsLoading(name, value) {
    this[`isLoading${name}`] = value;
  }

  setError(name, value) {
    this[`error${name}`] = value;
  }

  setValue(name, value) {
    this[name] = value;
  }

  add({ data, onSuccess }) {
    this.setIsLoading(ACTIONS.ADD, true);

    const cartID = Cookies.get({ name: COOKIES.CART_ID });

    cartAPI
      .add({ cartID: cartID, data })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        if (data.cart.id) Cookies.set({ name: COOKIES.CART_ID, value: data.cart.id });

        this.setIsLoading(ACTIONS.ADD, false);
        onSuccess && onSuccess();
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.ADD, message);
        this.setIsLoading(ACTIONS.ADD, false);
      });
  }

  clear() {
    const actions = Object.values(ACTIONS);

    for (let i = 0; i < actions.length; i++) {
      const ACTION = actions[i];

      this[`isLoading${ACTION}`] = false;
      this[`error${ACTION}`] = "";
    }

    this.items = [];
  }

  delete({ id, onSuccess }) {
    this.setIsLoading(ACTIONS.DELETE, true);

    const token = Cookies.get({ name: COOKIES.CART_ID });

    cartAPI
      .delete({ cartID: token, itemID: id })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        if (data.cart.id) Cookies.set({ name: COOKIES.CART_ID, value: data.cart.id });

        onSuccess && onSuccess();

        this.setValue("items", data.cart.items);
        this.setIsLoading(ACTIONS.DELETE, false);
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.DELETE, message);
        this.setIsLoading(ACTIONS.DELETE, false);
      });
  }

  edit({ data, id, onSuccess }) {
    this.setIsLoading(ACTIONS.EDIT, true);

    const token = Cookies.get({ name: COOKIES.CART_ID });

    cartAPI
      .edit({ cartID: token, data, itemID: id })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        if (data.cart.id) Cookies.set({ name: COOKIES.CART_ID, value: data.cart.id });

        this.setIsLoading(ACTIONS.EDIT, false);
        onSuccess && onSuccess();
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.EDIT, message);
        this.setIsLoading(ACTIONS.EDIT, false);
      });
  }

  get() {
    this.setIsLoading(ACTIONS.GET, true);

    const token = Cookies.get({ name: COOKIES.CART_ID });

    if (token == "" || token == undefined || token == null) {
      return;
    }

    let data = {};
    if (this.cartType != "") {
      data = { cartID: token, cartType: this.cartType };
    } else {
      data = { cartID: token };
    }

    cartAPI
      .get(data)
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        if (data.cart.id) Cookies.set({ name: COOKIES.CART_ID, value: data.cart.id });
        if (typeof data.cart.items === "object" && data.cart.items !== null) {
          let items = [];
          Object.keys(data.cart.items).forEach((value) => {
            items.push(data.cart.items[value]);
          });
          this.setValue("items", items);
        } else {
          this.setValue("items", data.cart.items);
        }
        this.setIsLoading(ACTIONS.GET, false);
        this.cartType = "";
      })
      .catch(({ message }) => {
        this.setValue("items", []);
        this.setError(ACTIONS.GET, message);
        this.setIsLoading(ACTIONS.GET, false);
        this.cartType = "";
      });
  }
}

export default Cart;
