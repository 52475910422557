import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "../../../../atoms/Button";
import Checkbox from "../../../../atoms/Checkbox";
import OptionalInput from "../../../../atoms/OptionalInput";

const MediaType = ({
  isValid,
  mediaTypeBoth,
  mediaTypeChabadEntertainment,
  mediaTypeJewishEntertainment,
  mediaTypeOther,
  mediaTypeOtherDetails,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const LABEL_SUBMIT = t("pages.authorization.questionnaire_main.labels.submit_1");
  const TITLE = t("pages.authorization.questionnaire_main.questions.2");

  return (
    <div>
      <h3 className="heading-m ta-center c-blue-dark">{TITLE}</h3>
      <form
        autoComplete="off"
        className="mt-07 mr-auto ml-auto w-100p"
        style={{ maxWidth: "480px" }}
        onSubmit={onSubmit}
      >
        <div>
          <Checkbox checked={mediaTypeChabadEntertainment.checked} onChange={mediaTypeChabadEntertainment.onChange}>
            {mediaTypeChabadEntertainment.label}
          </Checkbox>
        </div>
        <div className="mt-06">
          <Checkbox checked={mediaTypeJewishEntertainment.checked} onChange={mediaTypeJewishEntertainment.onChange}>
            {mediaTypeJewishEntertainment.label}
          </Checkbox>
        </div>
        <div className="mt-06">
          <Checkbox checked={mediaTypeBoth.checked} onChange={mediaTypeBoth.onChange}>
            {mediaTypeBoth.label}
          </Checkbox>
        </div>
        <div className="mt-06">
          <Checkbox checked={mediaTypeOther.checked} onChange={mediaTypeOther.onChange}>
            {mediaTypeOther.label}
          </Checkbox>
        </div>
        {mediaTypeOther.checked && (
          <div className="mt-01 ml-09">
            <OptionalInput
              id="questionnaire-form-media-type-step-other-details"
              value={mediaTypeOtherDetails.value}
              onChange={mediaTypeOtherDetails.onChange}
            />
          </div>
        )}
        <div className="d-flex fd-column ai-center mt-08">
          <Button disabled={!isValid} type="submit">
            {LABEL_SUBMIT}
          </Button>
        </div>
      </form>
    </div>
  );
};

const checkboxPropTypes = PropTypes.shape({
  checked: PropTypes.bool.isRequired,
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
}).isRequired;
const inputPropTypes = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
});

MediaType.propTypes = {
  isValid: PropTypes.bool.isRequired,
  mediaTypeBoth: checkboxPropTypes,
  mediaTypeChabadEntertainment: checkboxPropTypes,
  mediaTypeJewishEntertainment: checkboxPropTypes,
  mediaTypeOther: checkboxPropTypes,
  mediaTypeOtherDetails: inputPropTypes,
  onSubmit: PropTypes.func.isRequired,
};

export default MediaType;
