import { observer } from "mobx-react-lite";
import React from "react";
import cs from "classnames";
import classes from "./GetInvolved.module.scss";
import Button from "../../../../atoms/Button";
import home from "../../../../../assets/images/homeCake.png";
import hand from "../../../../../assets/images/homeHand.png";
import maorPartners from "../../../../../assets/images/logotype-maor-partners.png";
import gift from "../../../../../assets/images/homeGift.png";
import { useHistory } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../../../../constants";
import { scrollToTop } from "../../../../../utilities/helpers";
import { Trans } from "react-i18next";

const GetInvolved = observer(() => {
  const history = useHistory();

  const navigateToDonatePage = () => {
    history.push(APPLICATION_ROUTES.PARTNERS_DONATION);
    scrollToTop();
  };

  const navigateToBirthday = () => {
    history.push(APPLICATION_ROUTES.BIRTHDAY_PACKAGES);
    scrollToTop();
  };

  // const navigateToDedication = () => {
  //   history.push(APPLICATION_ROUTES.DAILY_REBBE_VIDEO_DEDICATION_SECTION);
  //   scrollToTop();
  // };

  const navigateToMaorPartners = () => {
    history.push(APPLICATION_ROUTES.PARTNERS_PACKAGES);
    scrollToTop();
  };

  const navigateToLegacyGift = () => {
    history.push(APPLICATION_ROUTES.LEGACY_GIFT);
    scrollToTop();
  };

  let data = [
    {
      title: "Birthdays",
      info:
        "Celebrate your child's birthday with exciting announcements dedications and <br /> a fun gift package.",
      btnText: "Submit a Birthday",
      imgAlt: "cake",
      imgSrc: home,
      navigateTo: navigateToBirthday,
    },
    {
      title: "Dedications",
      info:
        "Dedicate a video in honor of a yahrzeit, refuah sheleima, or special occasion.",
      btnText: "Dedicate a Video",
      imgAlt: "hand",
      imgSrc: hand,
      navigateTo: navigateToDonatePage,
    },
    {
      title: "Maor Partners",
      info:
        "Join our community of 30,000+ children and an adults, who learn from the Rebbe everyday with 3500+ videos.",
      btnText: "Become a Partner",
      imgAlt: "maor",
      imgSrc: maorPartners,
      navigateTo: navigateToMaorPartners,
    },
    {
      title: "Legacy Gift",
      info:
        "Make a charitable bequest in your will to Maor and ensure the world clarity and direction, until the coming of Moshiach. ",
      btnText: "Make a Gift",
      imgAlt: "gift",
      imgSrc: gift,
      navigateTo: navigateToLegacyGift,
    },
  ]

  return (
    <div className={cs(classes.container, "wrapper-m")}>
      <div className={cs(classes.headingContainer)}>
        <h3>Get Involved</h3>
        <p>
          Join Us in Sharing the Rebbe&apos;s Message with as many Chassidim as
          Possible
        </p>
      </div>

      <div className={cs(classes.cardsContainer)}>
        {data.map((cur, i) => {
          return (
            <div className={cs(classes.card, "bgc-gold")} key={i}>
              <img src={cur.imgSrc} alt={cur.imgAlt} />
              <div className={cs(classes.cardTextContainer)}>
                <h4>{cur.title}</h4>
                {/* <p>{cur.info}</p> */}
                <Trans components={{ a: <br /> }}>
                  <p>{cur.info}</p>
                </Trans>
              </div>
              <Button onClick={cur.navigateTo} size="xs">
                {cur.btnText}
              </Button>
            </div>
          );
        })}
      </div>

      <div className={cs(classes.btnWrap)}>
        <Button onClick={navigateToDonatePage}>I just want to donate</Button>
      </div>
    </div>
  );
});

export default React.memo(GetInvolved);
