import { observer } from "mobx-react-lite";
import React from "react";
import cs from "classnames";
import classes from "./Options.module.scss";
import { useState } from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";

const Options = observer(({ items, value, onChange }) => {
  const [hideText, setHideText] = useState(true);

  useEffect(() => {
    if (value == "donation_monthly" || value == "donation_yearly") {
      setHideText(false);
    } else {
      setHideText(true);
    }
  }, [value]);

  return (
    <div className={cs(classes.optionsContainer)}>
      <ul className={cs(classes.buttonContainer, "mr-auto ml-auto")}>
        {items.map((item) => (
          <li className={cs(classes.radioButton)} key={item.id}>
            <input
              type="radio"
              id={item.id}
              value={item.value}
              onChange={onChange}
              checked={item.value === value}
            />
            <label className={cs(classes.radioLabel)} htmlFor={item.id}>
              {item.label}
            </label>
          </li>
        ))}
      </ul>
      <p className={cs(hideText ? classes.hide : classes.text)}>
        You may cancel or change this amount at any time.
      </p>
    </div>
  );
});

Options.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

Options.defaultProps = {
  items: [],
};

export default React.memo(Options);
