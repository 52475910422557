import { observer } from "mobx-react-lite";
import React from "react";

import cs from "classnames";
import classes from "./Carousel.module.scss";

import { useState } from "react";

import imageOne from "../../../../../../../assets/images/carouselOne.png";
import imageTwo from "../../../../../../../assets/images/carouselTwo.png";
import imageThree from "../../../../../../../assets/images/carouselThree.png";
import imageFour from "../../../../../../../assets/images/carouselFour.png";
import imageFive from "../../../../../../../assets/images/carouselFive.png";
import imageSix from "../../../../../../../assets/images/carouselSix.png";
import imageSeven from "../../../../../../../assets/images/carouselSeven.png";
import imageEight from "../../../../../../../assets/images/carouselEight.png";
import Slider from "./components/Slider";
// import Icon from "../../../../../../atoms/Icon";

const Carousel = observer(() => {
  const [slider, setSlider] = useState(null);
  const sliderRef = {
    instance: slider,
    set: setSlider,
  };

  const centerItemOnClick = (index) => {
    if (sliderRef.instance) {
      sliderRef.instance.slickGoTo(index);
    }
  };

  let sliderSettings = {
    dots: true,
    infinite: true,
    centerMode: true,
    autoPlay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={cs(classes.sliderWrap)}>
      <div className={cs(classes.sliderContainer, "mt-09")}>
        <Slider {...sliderSettings} sliderRef={sliderRef} className="slider-v1">
          <div
            className={cs(classes.item, classes.slideLayout)}
            onClick={() => centerItemOnClick(0)}
          >
            <img src={imageTwo} />
          </div>{" "}
          <div
            className={cs(classes.item, classes.slideLayout)}
            onClick={() => centerItemOnClick(1)}
          >
            <img src={imageThree} />
          </div>
          <div
            className={cs(classes.item, classes.slideLayout)}
            onClick={() => centerItemOnClick(2)}
          >
            <img src={imageFour} />
          </div>
          <div
            className={cs(classes.item, classes.slideLayout)}
            onClick={() => centerItemOnClick(3)}
          >
            <img src={imageFive} />
          </div>
          <div
            className={cs(classes.item, classes.slideLayout)}
            onClick={() => centerItemOnClick(4)}
          >
            <img src={imageSix} />
          </div>
          <div
            className={cs(classes.item, classes.slideLayout)}
            onClick={() => centerItemOnClick(5)}
          >
            <img src={imageOne} />
          </div>
          <div
            className={cs(classes.item, classes.slideLayout)}
            onClick={() => centerItemOnClick(6)}
          >
            <img src={imageSeven} />
          </div>
          <div
            className={cs(classes.item, classes.slideLayout)}
            onClick={() => centerItemOnClick(7)}
          >
            <img src={imageEight} />
          </div>
          {/* <div className={cs(classes.slideLayout, "bgc-gold")}>
            <h4>&quot;What a special gift for uys and our children&quot;</h4>
            <div className={cs(classes.info)}>
              &quot;I want to give MyMaor a tremendous thank you! Our children
              watch the video every night when we tuck them into bed. It&apos;s
              so nice to see how they look forward to it and really enjoy it.
              the presentation is superb and really on a level for a child tp
              understand. What a special gift for us and our children!&quot;
            </div>
            <div className={cs(classes.slideBottom)}>
              <div className={cs(classes.slideAbout)}>
                <strong>Miriam Heber</strong>
                <p>Crown Heights, NY</p>
              </div>
              <div className={classes.arrowNavigation}>
                <button
                  onClick={() => {
                    sliderRef.instance.slickPrev();
                  }}
                >
                  <Icon name="leftarrow" />
                </button>
                <button
                  onClick={() => {
                    sliderRef.instance.slickNext();
                  }}
                >
                  <Icon name="rightarrow" />
                </button>
              </div>
            </div>
          </div> */}
        </Slider>
      </div>
    </div>
  );
});

export default React.memo(Carousel);
