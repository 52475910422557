import { observer } from 'mobx-react-lite'
import React from 'react'
import usePledgePartners from './usePledgePartners';
import cs from "classnames"
import classes from "./PledgePartners.module.scss"

const PledgePartners = observer(() => {
    const pledgeDonation = true;
    const pledgePartners = usePledgePartners({ pledgeDonation })
    const pledgePartnersArray = Object.values(pledgePartners)[0];

    return (
        <>
            <div className={cs(classes.myPledgeContainer)}>
                {pledgePartnersArray.length > 1 &&
                    <div className={cs(classes.pledgePartnersContainer)}>
                        <h1>I already pledged! <br className={cs(classes.displayMobile)} /> Join me.</h1>
                        <div className={cs(classes.pledgePartners)}>
                            {pledgePartnersArray.length > 1 &&
                                <>
                                    {pledgePartnersArray.map((cur, index) => {
                                        return <p key={index}>{cur}</p>

                                    })}
                                </>
                            }
                        </div>
                    </div>
                }
            </div>

        </>
    )
})

export default PledgePartners