import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import cs from "classnames";

import Share from "../../molecules/Share";
// import illustration from "../../../assets/images/maor-projects-on-devices-screen.png";
import illustration from "../../../assets/images/ThankYou.png";
import { APPLICATION_ROUTES } from "../../../constants";
import { StoreContext } from "../../../contexts";
import classes from "./PartnersSuccess.module.scss";

const PartnersSuccess = observer(() => {
  const { authorization, products } = useContext(StoreContext);
  const { t } = useTranslation();
  console.log(authorization.user, "auth");
  useEffect(() => {
    products.getCampaign();
  }, []);

  if (authorization && authorization.user && (authorization.user.tier == "m_partner" || authorization.user.tier == "m_silver-card" || authorization.user.tier == "m_silver_card")) {
    return (
      <div className="bgc-gold">
        <div className="wrapper-xl wrapper-vertical-m-a minh-100vh">
          <div className={classes.grid}>
            <div className={classes.message}>
              <h2 className="heading-l-a m-0 tt-uppercase c-orange">
                Welcome {authorization.lastName} Family!
              </h2>
              <br/>
              <div className={classes.partnerSectionText}>
                <p>Get ready to connect more and spend less.</p><br/>
                <p>You should receive a confirmation email shortly at: <strong>{authorization.user.email}</strong></p><br/>
                <p>Your new partner card will be sent to you within 1-2 weeks.</p><br/>
                <p>Thank you for joining us.</p>
                <br />
                <p>
                  <i>Moshiach now!</i>
                </p>
                <p><i>Chanoch Chaskind</i>
                </p>
                <p><i>Maor</i>
                </p>
                
                
              </div>
              
              <div className={cs("mt-05 fw-700 c-orange", classes.messageOne)}>
                <Link to={APPLICATION_ROUTES.LIBRARY}>{t("pages.partners.thank_you.labels.1")}</Link>
              </div>
            </div>
            <div className={classes.illustration}>
              <img src={illustration} alt="devices with maor website" draggable={false} />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="bgc-gold">
        <div className="wrapper-xl wrapper-vertical-m-a minh-100vh">
          <div className={classes.grid}>
            <div className={classes.message}>
              <h2 className="heading-l-a m-0 tt-uppercase c-orange">
                <Trans
                  components={{ a: <br /> }}
                  i18nKey="pages.partners.thank_you.titles.1"
                  values={{ value: authorization.lastName }}
                />
              </h2>
              <div>
                {products.hasCampaignGoal && (
                  <p className={cs(classes.messageOne, "m-0 mt-05 fw-700")}>
                    {/* {t("pages.partners.thank_you.texts.1", {
                      value1: products.joinedMembers,
                      value2: products.neededMembers,
                    })} */}
                    Welcome to a world where your child will beg to see the Rebbe!
                  </p>
                )}
                <p className={cs("m-0 mt-05", classes.messageOne)}>
                  <Link to={APPLICATION_ROUTES.MY_MAOR_PACKAGES} className="c-orange fw-700">
                    {/* {t("pages.partners.thank_you.click_here")} */}
                    Let&apos;s get started
                  </Link>{" "}
                  {t("pages.partners.thank_you.texts.2")}
                </p>
                <p className={cs("m-0 mt-05", classes.messageTwo)}>
                  {/* {t("pages.partners.thank_you.texts.3")} */}
                  If you would like to submit birthdays at a later time, go to:{" "}
                  <Link to={APPLICATION_ROUTES.MY_MAOR_PACKAGES}>Birthday Packages</Link> for any questions, please
                  contact us at <a href="mailto:office@Maor.org">office@Maor.org</a> |{" "}
                  <a href="tel:+17186878900">+1-718-687-8900</a>
                </p>
                <p className={cs("m-0 mt-05", classes.messageOne)}>{t("pages.partners.thank_you.texts.4")}</p>
              </div>
              <div className={cs(classes.share, classes.messageOne, "mt-02")}>
                <Share />
              </div>
              <div className={cs("mt-05 fw-700 c-orange", classes.messageOne)}>
                <Link to={APPLICATION_ROUTES.LIBRARY}>{t("pages.partners.thank_you.labels.1")}</Link>
              </div>
            </div>
            <div className={classes.illustration}>
              <img src={illustration} alt="devices with maor website" draggable={false} />
            </div>
          </div>
        </div>
      </div>
    );
  }

});



export default PartnersSuccess;
