import React from "react";
import cs from "classnames";
import classes from "./GoldPartners.module.scss";

import { observer } from "mobx-react-lite";
import useGoldPartners from "./useGoldPartners";
import medal from "../../../../../assets/images/medal.png";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";

const GoldPartners = observer(({ layout }) => {
  let memberLimit = 4;
  const goldPartners = useGoldPartners({ memberLimit });

  return (
    <div className={cs(classes[layout])}>
      <div
        className={cs(
          classes.goldPartnersList,
          isMobile ? classes.mobileGoldPartners : ""
        )}
      >
        <div className={cs(classes.imgTextWrap)}>
          <img src={medal} />
          <div className={cs(classes.titleWrap)}>
            <h3 className={cs(classes.title, "fw-700 mb-03")}>
              Thank you to our GOLD Partners:
            </h3>
            <ul>
              {Object.entries(goldPartners.latestGoldPartners).map(
                ([key, value]) => (
                  <li key={key}>
                    The{" "}
                    <strong>
                      {value.charAt(0).toUpperCase() + value.slice(1)}
                    </strong>{" "}
                    Family
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
});

GoldPartners.propTypes = {
  layout: PropTypes.string,
};

GoldPartners.defaultProps = {
  layout: "horizontal",
};
export default React.memo(GoldPartners);
