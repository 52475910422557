import * as Hebcal from "hebcal";
import { convertToHebrew, addDates } from "jewish-dates-core";
import { heMonths, heMonthsEnglish, heMonthsLeap, heMonthsLeapEnglish } from "./heMonths";
import heYear from "./heYear";

const formatJewishDate = (date, bornAfterSunset = false, useHeTranslation = false) => {
  if (bornAfterSunset) {
    date = addDates(date, 1);
  }

  const isLeapYear = new Hebcal.HDate(date).isLeapYear();
  let hDate = new Hebcal.HDate(date);

  let day = "";
  let month = "";
  let year = "";


  if (hDate.day && hDate.year) {
    if (useHeTranslation) {
      day = convertToHebrew(hDate.day, false, false);
      month = isLeapYear ? heMonthsLeap[hDate.month] : heMonths[hDate.month];
      year = heYear(hDate.year);
    } else {
      day = hDate.day;
      month = isLeapYear ? heMonthsLeapEnglish[hDate.month] : heMonthsEnglish[hDate.month];
      year = hDate.year;
    }
  } else {
    return "";
  }

  return `${day} ${month} ${year}`;
};

export default formatJewishDate;
