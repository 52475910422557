import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import cs from "classnames";

import Button from "../../atoms/Button";
import PageTitle from "../../atoms/PageTitle";
import Layout from "../../templates/Layout";
import { APPLICATION_ROUTES, PAGE_TITLES } from "../../../constants";
import { scrollToTop } from "../../../utilities/helpers";

import classes from "./NotFound.module.scss";

const NotFound = () => {
  const history = useHistory();
  const onNavigate = useCallback(() => {
    history.push(APPLICATION_ROUTES.PARTNERS_MAIN);
    scrollToTop();
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <PageTitle value={PAGE_TITLES.NOT_FOUND} />
      <Layout>
        <div className={cs(classes.container, classes.wrapper, "wrapper-xl")}>
          <h2 className={classes.title}>
            <Trans components={{ a: <br /> }} i18nKey="pages.not_found.titles.1" />
          </h2>
          <p className={classes.paragraph}>{t("pages.not_found.texts.1")}</p>
          <div className={classes.button}>
            <Button onClick={onNavigate}>{t("pages.not_found.labels.1")}</Button>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default React.memo(NotFound);
