import NAMES from "./names";

const MESSAGES = {
  [NAMES.CARD_EXPIRATION_DATE]: "The field value does not match the credit card expiration date format!",
  [NAMES.CHARACTERS_DIGITS_SYMBOLS]: "The field should contain only characters, digits and symbols: .,!",
  [NAMES.COMPLETED_PHONE_NUMBER]: "The phone number is not fully entered!",
  [NAMES.DIGITS]: "The field must contain only digits!",
  [NAMES.DIGITS_DOTS]: "The field must contain only digits and dots!",
  [NAMES.EMAIL]: "The field value does not match the email format!",
  [NAMES.EQUAL]: "The field value does not match the expected value!",
  [NAMES.GREATER_THAN_VALUE]: "The field value must be greater than this!",
  [NAMES.HEBREW_LATIN]: "The field value must contain only Latin and Hebrew characters!",
  [NAMES.HEBREW_LATIN_DIGITS]: "The field must contain only Latin and Hebrew characters and numbers!",
  [NAMES.HEBREW_LATIN_DIGITS_SYMBOLS]:
    "The field must contain only Latin and Hebrew characters, spaces, digits and symbols: .,!",
  [NAMES.HEBREW_LATIN_SPACES]: "The field must contain only Latin or Hebrew characters, and spaces!",
  [NAMES.HEBREW_LATIN_SPACES_HYPHENS]: "The field must contain only Latin or Hebrew characters, spaces and hyphens!",
  [NAMES.LATIN]: "The field value must contain only Latin letters!",
  [NAMES.LATIN_DIGITS]: "The field must contain only Latin characters and digits!",
  [NAMES.MAX_10]: "The field must contain no more than 10 symbols!",
  [NAMES.MIN_FOUR_SYMBOLS]: "The field should contain at least 4 symbols!",
  [NAMES.MIN_FIVE_MAX_FIFTEEN_SYMBOLS]: "The field should contain from 5 to 15 characters!",
  [NAMES.MIN_SIX_SYMBOLS]: "The field should contain at least 6 symbols!",
  [NAMES.MIN_2]: "The field must contain at least 2 symbols!",
  [NAMES.MIN_3]: "The field must contain at least 3 symbols!",
  [NAMES.MIN_5]: "The field must contain at least 5 symbols!",
  [NAMES.PHONE]: "The field value does not match the phone format!",
  [NAMES.REQUIRED]: "This field is required!",
};

export default MESSAGES;
