/* eslint-disable */
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import cs from "classnames";
import { convertToHebrew } from "jewish-dates-core";

import Icon, { NAMES as ICON_NAMES, SIZES as ICON_SIZES } from "../../atoms/Icon";
import PlainInput from "../../atoms/PlainInput";
import Popover, { usePopover, POSITIONS as POPOVER_POSITIONS, WIDTH as POPOVER_WIDTH } from "../../organisms/Popover";
import { formatDate, formatJewishDate, heYear } from "../../../utilities/helpers";
import classes from "./Datepicker.module.scss";
import Checkbox from "../../atoms/Checkbox";
import { heMonths, heMonthsEnglish, heMonthsLeap, heMonthsLeapEnglish } from "../../../utilities/helpers/heMonths";

const WEEK_DAYS = ["1", "2", "3", "4", "5", "6", "7"];

const Datepicker = ({
  hebrew,
  id,
  days,
  month,
  value,
  isLeapYear,
  year,
  toggleMode,
  setDay,
  label,
  decrementMonth,
  incrementMonth,
  decrementYear,
  incrementYear,
  promptSunsetCheck,
  bornAfterSunsetCheckbox,
  useHeTranslation,
}) => {

  const popover = usePopover({
    position: POPOVER_POSITIONS.LEFT_BOTTOM_OUT,
    selector: `#${id}`,
    width: POPOVER_WIDTH.TARGET,
  });

  const onSelect = useCallback(({ target: { value } }) => {
    setDay(value, bornAfterSunsetCheckbox.checked);
    popover.close();
  }, []);

  const onSelectHe = useCallback(({ target: { value } }) => {
    bornAfterSunsetCheckbox.setChecked(false);
    setDay(value);
    popover.close();
  }, []);

  let hebrewMonth;
  if (useHeTranslation) {
    hebrewMonth = isLeapYear ? heMonthsLeap[month] : heMonths[month];
  } else {
    hebrewMonth = isLeapYear ? heMonthsLeapEnglish[month] : heMonthsEnglish[month];
  }

  const handleToggleMode = () => {
    if (!hebrew && bornAfterSunsetCheckbox.checked) {
      bornAfterSunsetCheckbox.setChecked(false);
    } 
    toggleMode();
  }

  const heWeekDay = useHeTranslation
    ? `components.molecules.date_picker.week_days_he`
    : `components.molecules.date_picker.week_days`;

  return (
    <div className={classes.container}>
      <div className={classes.anchor} id={id}>
        <PlainInput
          label={label}
          readOnly
          stretched
          value={formatJewishDate(value, bornAfterSunsetCheckbox.checked, useHeTranslation)}
        />
        <div className={classes.englishDateLabel}>
          {formatDate(value)}
        </div>
        <button className={classes.trigger} onClick={popover.open} />
      </div>

      <Popover disablePortalSelector={true} close={popover.close} style={popover.style}>
        <div className={classes.body}>
          <div className={classes.bar}>
            <button className={classes.button} onClick={decrementYear}>
              <Icon name={ICON_NAMES.CHEVRON_LEFT_DOUBLE} size={ICON_SIZES.M} />
            </button>
            <button className={classes.button} onClick={decrementMonth}>
              <Icon name={ICON_NAMES.CHEVRON_LEFT} size={ICON_SIZES.M} />
            </button>
            <span className={classes.title}>
              {hebrew ? (
                <Trans i18nKey={`${hebrewMonth}`} />
              ) : (
                <Trans i18nKey={`components.molecules.date_picker.months.${month + 1}`} />
              )}
              <strong className="mr-02 ml-02">
                {hebrew && useHeTranslation ? heYear(year) : year}
              </strong>
            </span>
            <button className={classes.button} onClick={incrementMonth}>
              <Icon name={ICON_NAMES.CHEVRON_RIGHT} size={ICON_SIZES.M} />
            </button>
            <button className={classes.button} onClick={incrementYear}>
              <Icon name={ICON_NAMES.CHEVRON_RIGHT_DOUBLE} size={ICON_SIZES.M} />
            </button>
          </div>
          <div className={classes.mode}>
            <button className={cs(classes.button, { [classes.selected]: !hebrew })} onClick={handleToggleMode}>
              <Trans i18nKey="components.molecules.date_picker.labels.1" />
            </button>
            <button className={cs(classes.button, { [classes.selected]: hebrew })} onClick={handleToggleMode}>
              <Trans i18nKey="components.molecules.date_picker.labels.2" />
            </button>
          </div>
          {promptSunsetCheck && !hebrew && (
            <div className={classes.bornAfterCheckboxWrapper}>
              <Checkbox
                id="born-after-sunset-checkbox"
                onClick={() => {
                  setDay(value);
                }}
                size="s"
                {...bornAfterSunsetCheckbox}
              >
                <Trans i18nKey={`components.molecules.date_picker.labels.3`} />
              </Checkbox>
            </div>
          )}
          <ul className={cs(classes.list, classes.weeks)}>
            {WEEK_DAYS.map((item, index) => (
              <li className={classes.item} key={item + index}>
                {hebrew ? (
                  <Trans i18nKey={`${heWeekDay}.${item}`} />
                ) : (
                  <Trans i18nKey={`components.molecules.date_picker.week_days.${item}`} />
                )}
              </li>
            ))}
          </ul>
          <ul className={cs(classes.list, classes.days, { [classes.rtl]: hebrew && useHeTranslation })}>
            {days.map((item) => (
              <li className={classes.item} key={`${item.value.getTime()}-${item.value.getDate()}`}>
                <button
                  className={cs(classes.button, { [classes.selected]: item.selected })}
                  disabled={item.disabled}
                  value={item.value}
                  onClick={hebrew ? onSelectHe : onSelect}
                >
                  {hebrew && useHeTranslation ? convertToHebrew(item.title, false, false) : item.title}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </Popover>
    </div>
  );
};

Datepicker.propTypes = {
  hebrew: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  days: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool.isRequired,
      selected: PropTypes.bool.isRequired,
      title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
      value: PropTypes.instanceOf(Date).isRequired,
    }).isRequired
  ).isRequired,
  month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.instanceOf(Date).isRequired,
  isLeapYear: PropTypes.bool.isRequired,
  year: PropTypes.number.isRequired,
  toggleMode: PropTypes.func.isRequired,
  setDay: PropTypes.func.isRequired,
  decrementMonth: PropTypes.func.isRequired,
  incrementMonth: PropTypes.func.isRequired,
  decrementYear: PropTypes.func.isRequired,
  incrementYear: PropTypes.func.isRequired,
  promptSunsetCheck: PropTypes.bool,
  bornAfterSunsetCheckbox: PropTypes.object,
  useHeTranslation: PropTypes.bool.isRequired,
};

export default React.memo(Datepicker);
