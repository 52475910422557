const ACTIONS = {
  ADD: "AddPlaylist",
  ADD_SEARCH_FILTER: "AddSearchFilter",
  DELETE: "DeletePlaylist",
  GET: "GET",
  CLEAR_PLAYLIST: "ClearPlaylist",
  ADD_TO_PLAYLIST: "AddToPlaylist",
};

export { ACTIONS };
