import { makeAutoObservable } from "mobx";
import { WinnersAPI } from "../../api";
import { ACTIONS } from "./utilities";

const winnersAPI = new WinnersAPI();

const Winners = class {
  constructor({ store }) {
    const actions = Object.values(ACTIONS);

    for (let i = 0; i < actions.length; i++) {
      const ACTION = actions[i];

      this[`isLoading${ACTION}`] = false;
      this[`error${ACTION}`] = "";
    }

    this.raffle = null;
    this.maor = null;
    this.store = store;

    makeAutoObservable(this);
  }

  setIsLoading(name, value) {
    this[`isLoading${name}`] = value;
  }

  setError(name, value) {
    this[`error${name}`] = value;
  }

  setValue(name, value) {
    this[name] = value;
  }

  getRaffleWinner() {
    this.setIsLoading(ACTIONS.GET_RAFFLE_WINNER, true);
    winnersAPI
      .getRaffleWinner()
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then((data) => {
        this.setIsLoading(ACTIONS.GET_RAFFLE_WINNER, false);
        this.setValue("raffle", data);
      })
      .catch(({ message }) => {
        this.setValue("raffle", null);
        this.setError(ACTIONS.GET_RAFFLE_WINNER, message);
        this.setIsLoading(ACTIONS.GET_RAFFLE_WINNER, false);
      });
  }

  getMaorWinner() {
    this.setIsLoading(ACTIONS.GET_MAOR_WINNER, true);
    winnersAPI
      .getMaorWinner()
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then((data) => {
        this.setIsLoading(ACTIONS.GET_MAOR_WINNER, false);
        this.setValue("maor", data.data);
      })
      .catch(({ message }) => {
        this.setValue("maor", null);
        this.setError(ACTIONS.GET_MAOR_WINNER, message);
        this.setIsLoading(ACTIONS.GET_MAOR_WINNER, false);
      });
  }
};

export default Winners;
