import Authorization from "./authorization";
import Cart from "./cart";
import Checkout from "./checkout";
import Library from "./library";
import Playlist from "./playlist";
import Products from "./products";
import Audios from "./audios";
import Partnership from "./partnership";
import Winners from "./winners";
import Persons from "./persons";
import Common from "./common";
import MailingList from "./mailinglist";
import PaymentMethod from "./paymentmethod";
import TrackDonation from "./trackdonation";
import Error from "./error";
import Donation from "./donation";
import FreeDonorAccess from "./freedonoraccess";
import VideoDates from "./videodates";
import Ad from "./ad";
class Store {
  constructor() {
    this.notifyError = new Error({ store: this });
    this.authorization = new Authorization({ store: this });
    this.cart = new Cart({ store: this });
    this.checkout = new Checkout({ store: this });
    this.library = new Library({ store: this });
    this.playlist = new Playlist({ store: this });
    this.products = new Products({ store: this });
    this.audios = new Audios({ store: this });
    this.partnership = new Partnership({ store: this });
    this.winners = new Winners({ store: this });
    this.persons = new Persons({ store: this });
    this.common = new Common({ store: this });
    this.mailinglist = new MailingList({ store: this });
    this.paymentmethod = new PaymentMethod({ store: this });
    this.trackdonation = new TrackDonation({ store: this });
    this.donation = new Donation({ store: this });
    this.freedonoraccess = new FreeDonorAccess({ store: this });
    this.videodates = new VideoDates({ store: this });
    this.ad = new Ad({ store: this });
  }
}

const store = new Store();

export default store;
