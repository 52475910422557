import { observer } from 'mobx-react-lite'
import React from 'react'
import PageTitle from '../../atoms/PageTitle'
import { PAGE_TITLES } from '../../../constants'
import classes from './MaorPledge.module.scss';
import cs from "classnames";
// import maorPLedge from "../../../assets/images/maor-pledge-logo.png";
import maorMoney from "../../../assets/images/pledge-money.png"
import { Trans } from 'react-i18next';
import PledgeDonation from './components/PledgeDonation';
import Logotype, { TYPES as LOGOTYPE_TYPES } from '../../atoms/Logotype';
import { useQuery } from '../../../hooks';
import { useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import pledgePdfHeb from "../../../assets/pdf/Maor-Booklet-Heb.pdf";
import pledgePdfEng from "../../../assets/pdf/Maor-Booklet-Eng.pdf";
import PledgePartners from './components/PledgePartners/PledgePartners';

const MaorPledge = observer(() => {

    const query = useQuery();
    const pledgeDonationSection = query.get("donation");

    console.log(pledgeDonationSection, "pledgeDonationSection")

    useEffect(() => {
        if (pledgeDonationSection != null) {
            console.log(pledgeDonationSection, " pledgeDonationSection")
            scroller.scrollTo("maorPledgeContainer", {
                duration: 500,
                smooth: true,
                offset: -24,
            });
        }
    }, []);

    const hebrewBottomText = `משיחת נצבים-וילך תשמ"ג - לחץ כאן לקריאת השיחה    `;

    return (
        <>
            <PageTitle value={PAGE_TITLES.MAOR_PLEDGE} />
            <div className={cs("c-blue-dark", classes.backgroundPledge)}>
                <div className={cs("wrapper-m", classes.container)}>
                    <nav className={classes.logotype}>
                        <Logotype type={LOGOTYPE_TYPES.MAOR_PLEDGE} size="c" />
                    </nav>
                    <div className={cs(classes.headerText)}>
                        <h1 className={cs(classes.desktopHeading)}> <span className={cs(classes.wordSpacing)}> Do you want to </span> <br /> be <span className={cs(classes.changeColor, classes.richText)}> rich </span> this year?</h1>
                        <h1 className={cs(classes.mobileHeading)}> <span className={cs(classes.wordSpacing)}> Do you want  <br /> to </span> be <span className={cs(classes.changeColor, classes.richText)}> rich </span>  <br />  this year?</h1>
                    </div>
                    <div id="maorPledgeContainer" className={cs(classes.maorPledgeContainer)}>
                        <Element name="maorPledgeContainer" />
                        <PledgeDonation />
                    </div>
                  
                    <div className={cs(classes.mainContainer)}>
                        <div className={cs(classes.topConatiner)}>
                            <div className={cs(classes.rebbeTopConatiner)}>
                                <div className={cs(classes.rebbeContainer)}>
                                    <h2 className={cs(classes.fontFamilyLeagueSpartan)}>The Rebbe:</h2>

                                    <p className={cs("mt-02", classes.mobileMarginRight, classes.desktopDisplay)}>If you want Hashem to bless you with <span className={cs(classes.brownBackground)}>children, health and livelihood <span className={cs(classes.bold)}> in abundance,</span></span> <br />
                                        you need to give as <span className={cs(classes.brownBackground)}> generously as a <span className={cs(classes.bold)}>wealthy person!
                                        </span></span> </p>

                                    <p className={cs("mt-02", classes.mobileMarginRight, classes.mobileDisplay)}>&quot;If you want Hashem to bless you with <span className={cs(classes.brownBackground)}> children, health and livelihood <span className={cs(classes.bold)}> in abundance,</span></span>
                                        you need to give as <span className={cs(classes.brownBackground)}>  generously as a <span className={cs(classes.bold)}>wealthy person!&quot;
                                        </span> </span></p>

                                    <p className={cs("mt-05", classes.marginRight)}>When a Yid pledges to donate a substantial amount to Tzedakah, Hashem <br className={cs(classes.mobileDisplayNone)} />
                                        opens a new channel to fulfill his pledge.</p>

                                </div>
                                <div className={cs(classes.pledgeMoney)}>
                                    <img src={maorMoney} alt='maorMoney' />
                                </div>
                            </div>


                            <div className={cs("mt-07")}>
                                <p className={cs(classes.changeColor, classes.bold)}>The Rebbe also gave clear instructions on how to do it:</p>
                                <p className={cs("mt-04")}>Put your pledge in writing, stating how much tzedakah you intend to give each month, <br className={cs(classes.mobileDisplayNone)} /> and Hashem will see to it that you can give not only that amount, but more!</p>
                                <p className={cs(classes.smallText, classes.textEnd, classes.justifyEnd, "mt-04")}><span>Shabbos Parshas Nitzovim-Vayelech 5743 </span>
                                    <span> <a href={pledgePdfEng} download={true}>  Click here to read the Sicha</a> </span> </p>
                            </div>

                        </div>

                        <div className={cs(classes.hebrewConatiner)}>
                            <h1 className={cs(classes.changeColor, classes.fontFamilyLeagueSpartan)}>***</h1>

                            <div className={cs(classes.hebrewText)}>
                                <div className={cs(classes.hebrewText1)}></div>
                                <div className={cs(classes.hebrewText2)}></div>
                                <div className={cs(classes.hebrewText3)}></div>
                                <p className={cs(classes.smallText, classes.selfStart, "mt-05")}> <a href={pledgePdfHeb} download={true}><Trans i18nKey={`${hebrewBottomText}`} /></a> </p>
                            </div>
                        </div>
                    </div>
                    <PledgePartners />
                </div>
            </div>
        </>
    )
})

export default MaorPledge