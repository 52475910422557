import { PROJECTS } from "../../../../../constants";
import DonationItem from "./components/DonationItem";
import PartnersItem from "./components/PartnersItem";
import UnknownItem from "./components/UnknownItem";

const getItem = (id) => {
  switch (id) {
    case PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID:
    case PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID:
      case PROJECTS.PARTNERS.PACKAGES.SILVER_CARD_TIER.ID:
    case PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID:
    case PROJECTS.PARTNERS.PACKAGES.PLATINUM_TIER.ID:
    case PROJECTS.PARTNERS.PACKAGES.PARTNER_TIER.ID:
      return PartnersItem;
    case PROJECTS.DONATION.TYPE.ID:
    case PROJECTS.DONATION.TYPE.MONTHLY:
    case PROJECTS.DONATION.TYPE.YEARLY:
    case PROJECTS.DONATION.TYPE.PLEDGE_DONATION:
      return DonationItem;
    default:
      return UnknownItem;
  }
};

export { getItem };
