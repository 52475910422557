import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";

import classes from "./Braintree.module.scss";
import { StoreContext } from "../../../../../../../../../contexts";
import { useQuery } from "../../../../../../../../../hooks";


const IDENTIFIERS = {
  BUTTON: "braintree-button",
  CONTAINER: "braintree-container",
};

const Braintree = ({ locale, onInitialize, onSaleSuccess }) => {

  const query = useQuery();
  const { common } = useContext(StoreContext);

  // Check if common.get("options") is equal to "donation"
  const isDonation = common.get("options") === "donation_monthly" || common.get("options") === "donation_yearly" || common.get("options") === "donation_monthly_pledge";
  // If it's a donation, set donation_recurring to true, otherwise, set it to undefined
  let donationRecurring = isDonation ? true : false;

  if (query.get("page") == "pledge" || query.get("type") == "donation_monthly" || query.get("type") == "donation_yearly") {
    donationRecurring = true
  }

  useEffect(() => {
    onInitialize({
      buttonSelector: `#${IDENTIFIERS.BUTTON}`,
      containerSelector: `#${IDENTIFIERS.CONTAINER}`,
      locale: locale,
      onSaleSuccess: onSaleSuccess,
      donationRecurring: donationRecurring,
    });
  }, []);

  return <div className={classes.container} id={IDENTIFIERS.CONTAINER} />;
};

Braintree.propTypes = {
  locale: PropTypes.string.isRequired,
  onInitialize: PropTypes.func.isRequired,
  onSaleSuccess: PropTypes.func.isRequired,
};

export default React.memo(Braintree);
