import { makeAutoObservable } from "mobx";

import { ProductsAPI } from "../../api";
import { ACTIONS } from "./utilities";

const productsAPI = new ProductsAPI();

const Products = class {
  constructor() {
    const actions = Object.values(ACTIONS);

    for (let i = 0; i < actions.length; i++) {
      const ACTION = actions[i];

      this[`isLoading${ACTION}`] = false;
      this[`error${ACTION}`] = "";
    }

    this.campaign = null;
    this.members = [];
    this.nextPageOfMembers = 1;
    this.activeMembers = [];
    this.totalActiveMembers = null;
    this.totalPartnerGoal = null;

    makeAutoObservable(this);
  }

  get hasCampaign() {
    return !!this.campaign;
  }

  get hasNoCampaign() {
    return !this.campaign;
  }

  get hasNoMembers() {
    return !this.members.length;
  }

  get hasNextPageOfMembers() {
    return !!this.nextPageOfMembers;
  }

  get hasCampaignExpirationDate() {
    return !!(this.hasCampaign && this.campaign.expiration_date);
  }

  get hasCampaignGoal() {
    return !!(this.hasCampaign && this.campaign.expected_members);
  }

  get joinedMembers() {
    return this.hasCampaign ? this.campaign.joined_members : 0;
  }

  get neededMembers() {
    if (this.hasCampaign) {
      const shortage = this.campaign.expected_members - this.campaign.joined_members;

      return shortage > 0 ? shortage : 0;
    }

    return 0;
  }

  setIsLoading(name, value) {
    this[`isLoading${name}`] = value;
  }

  setError(name, value) {
    this[`error${name}`] = value;
  }

  setValue(name, value) {
    this[name] = value;
  }

  addMembers(items) {
    if (items && items.length) {
      const members = [...this.members, ...items];
      this.members = members;
    }
  }

  getCampaign() {
    this.setIsLoading(ACTIONS.GET_CAMPAIGN, true);

    productsAPI
      .getCampaign()
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        this.setValue("campaign", data);
        this.setIsLoading(ACTIONS.GET_CAMPAIGN, false);
      })
      .catch((error) => {
        this.setError(ACTIONS.GET_CAMPAIGN, error.message);
        this.setIsLoading(ACTIONS.GET_CAMPAIGN, false);
      });
  }

  getMembers() {
    this.setIsLoading(ACTIONS.GET_MEMBERS, true);

    productsAPI
      .getMembers({ page: this.nextPageOfMembers })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        this.addMembers(data.users);
        this.setValue("nextPageOfMembers", data.next_page);
        this.setIsLoading(ACTIONS.GET_MEMBERS, false);
      })
      .catch((error) => {
        this.setError(ACTIONS.GET_MEMBERS, error.message);
        this.setIsLoading(ACTIONS.GET_MEMBERS, false);
      });
  }

  getActiveMembers() {
    this.setIsLoading(ACTIONS.GET_ACTIVE_MEMBERS, true);

    productsAPI
      .getActiveMembers()
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        this.setValue("activeMembers", data.users);
        this.setValue("totalActiveMembers", data.total);
        this.setValue("totalPartnerGoal", data.target);

        this.setIsLoading(ACTIONS.GET_ACTIVE_MEMBERS, false);
      })
      .catch((error) => {
        this.setError(ACTIONS.GET_ACTIVE_MEMBERS, error.message);
        this.setIsLoading(ACTIONS.GET_ACTIVE_MEMBERS, false);
      });
  }
};

export default Products;
