import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cs from "classnames";

import Button from "../../../../../../atoms/Button";
import Icon from "../../../../../../atoms/Icon";
import Loader from "../../../../../../atoms/Loader";
import classes from "./ModalBody.module.scss";

const ModalBody = ({ isLoading, onDelete, onClose }) => {
  const { t } = useTranslation();

  return (
    <div className={cs(classes.container, classes.wrapper)}>
      <div className={classes.card}>
        <header className={classes.header}>
          <button className={classes.close} onClick={onClose}>
            <Icon name="exit" size="l" />
          </button>
        </header>
        <div className={classes.main}>
          <h4 className={classes.title}>{t("pages.checkout.titles.1")}</h4>
        </div>
        <footer className={classes.footer}>
          {isLoading ? <Loader /> : <Button onClick={onDelete}>{t("pages.checkout.labels.1")}</Button>}
        </footer>
      </div>
    </div>
  );
};

ModalBody.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalBody;
