import React from "react";
import cs from "classnames";
import classes from "./SubmitAnswerButton.module.scss";
import PropTypes from "prop-types";

const SubmitAnswerButton = ({ submitAnswer, size, color }) => {
  return (
    <div
      className={cs(classes.previewButton, classes[size], classes[color])}
      onClick={submitAnswer}
    >
      Submit Answer
    </div>
  );
};

SubmitAnswerButton.propTypes = {
  submitAnswer: PropTypes.func.isRequired,
  size: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default SubmitAnswerButton;
