import { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { COOKIES, PROJECTS } from "../../../constants";
import { StoreContext } from "../../../contexts";
import { NAMES } from "../../../utilities/validation";
import { useCheckbox, useDoubleInput, useInput, useQuery, useRadio, useSelectR, useToggle } from "../../../hooks";
import { getInitials, checkHasDelivery } from "./utilities";
import Cookies from "js-cookie";
import { convertToHebrew } from "jewish-dates-core";
import {
  convertJewishEngToJewish,
  formatJewishDate,
  getJewishEnglishMonth,
} from "../../../utilities/helpers";
import { NAMES as VALIDATIONS_NAMES } from "../../../utilities/validation";
import { useState } from "react";
import Hebcal from "hebcal";

const showDetails = true;
let jewishDays = [];
let enDays = [];
for (let i = 1; i <= 30; i++) {
  jewishDays.push({
    label: convertToHebrew(i, false, false),
    value: convertToHebrew(i, false, false),
    englishDay: i,
  });
}

for (let i = 1; i <= 31; i++) {
  enDays.push({
    label: i,
    value: i,
  });
}

const heMonths = {
  0: {
    jewish: "תשרי",
    jewishEng: "Tishrei",
  },
  1: {
    jewish: "חשון",
    jewishEng: "Cheshvan",
  },
  2: {
    jewish: "כסלו",
    jewishEng: "Kislev",
  },
  3: {
    jewish: "טבת",
    jewishEng: "Tevet",
  },
  4: {
    jewish: "שבט",
    jewishEng: "Shevat",
  },
  5: {
    jewish: "אדר",
    jewishEng: "Adar",
  },
  6: {
    jewish: "אדר א",
    jewishEng: "Adar 1",
  },
  7: {
    jewish: "אדר ב",
    jewishEng: "Adar 2",
  },
  8: {
    jewish: "ניסן",
    jewishEng: "Nissan",
  },
  9: {
    jewish: "אייר",
    jewishEng: "Iyyar",
  },
  10: {
    jewish: "סיון",
    jewishEng: "Sivan",
  },
  11: {
    jewish: "תמוז",
    jewishEng: "Tamuz",
  },
  12: {
    jewish: "אב",
    jewishEng: "Av",
  },
  13: {
    jewish: "אלול",
    jewishEng: "Elul",
  },
};

const englishMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

let jewishMonths = [];
let enMonths = [];

for (const key in heMonths) {
  jewishMonths.push({
    label: heMonths[key].jewish,
    value: heMonths[key].jewish,
  });
}

englishMonths.forEach(function (value) {
  enMonths.push({
    label: value,
    value: value,
  });
});

let jewishYears = [];
let enYears = [];
for (let i = 5784; i >= 5769; i--) {
  let year = convertToHebrew(i, true, true);
  let originalYear = i;
  year = year.substring(1, year.length);
  jewishYears.push({
    label: year,
    value: year,
    englishYear: originalYear,
  });
}

const currentYear = new Date().getFullYear();

for (let i = 2009; i <= currentYear; i++) {
  let year = i;
  enYears.push({
    label: year,
    value: year,
  });
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getMonthName = (monthNumber) => {
  if (monthNumber >= 1 && monthNumber <= 12) {
    return months[monthNumber - 1];
  } else {
    return "Invalid month number";
  }
};

// for (let i = 5769; i <= 5783; i++) {
//   let year = convertToHebrew(i, true, true);
//   year = year.substring(1, year.length);
//   years.push({
//     label: year,
//     value: year,
//   });
// }

const useForm = () => {
  const { cart, authorization } = useContext(StoreContext);
  const [deleteChildSuccess, setDeleteChildSuccess] = useState(false);
  const [deleteAddressSuccess, setDeleteAddressSuccess] = useState(false);

  useEffect(() => {
    if (authorization.isAuthenticated) {
      // authorization.getPersons({});
      email.onChangeOriginal({ target: { value: authorization.user.email } });
      parentFirstName.onChange({
        target: { value: authorization.user.first_name || "" },
      });
      parentLastName.onChange({
        target: { value: authorization.user.last_name },
      });
    }
  }, [authorization.isAuthenticated]);

  useEffect(() => {
    if (authorization.isAuthenticated) {
      authorization.getPersons({});
    }
  }, [authorization.isAuthenticated, deleteChildSuccess]);

  useEffect(() => {
    if (authorization.isAuthenticated) {
      authorization.getAddress();
    }
  }, [authorization.isAuthenticated, deleteAddressSuccess]);

  const persons = authorization.persons;
  const [isAutoRenewalBirthday, setIsAutoRenewalBirthday] = useState(false);
  const [isAfterShkiyaChecked, setIsAfterShkiyaChecked] = useState(false);
  const [isCivil, setIsCivil] = useState(true);
  const [addChild, setAddChild] = useState(false);
  const [addAddress, setAddAddress] = useState(false);
  const [location, setLocation] = useState({
    street: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
  });

  useEffect(() => {
    deliveryStreet.onChange({
      target: { value: location.street },
    });
    deliveryCity.onChange({
      target: { value: location.city },
    });
    deliveryState.onChange({
      target: { value: location.state },
    });
    if (initials.delivery.country.value == "") {
      deliveryCountry.onChange(location.country);
    }
    deliveryZip.onChange({
      target: { value: location.postalCode },
    });
  }, [location])


  const { t } = useTranslation();
  const APARTMENT = t("forms.labels.apartment");
  const BOY = t("forms.labels.boy");
  const CITY = t("forms.labels.city");
  const COUNTRY = t("forms.labels.country");
  // const DATE = t("forms.labels.date");
  const FIRST_NAME = t("forms.labels.first_name");
  const FROM = t("forms.labels.from");
  const GIRL = t("forms.labels.girl");
  const LAST_NAME = t("forms.labels.last_name");
  const MESSAGE = t("forms.labels.message");
  const PRONUNCIATION = t("forms.labels.pronunciation");
  const PERSON_ID = t("forms.labels.person_id");
  const ADDRESS_ID = t("forms.labels.address_id");
  const TZIVOS_RANK_HASHEM = t("forms.labels.tzivos_rank_hashem");
  // const RANK_TZIVOS_HASHEM = t("forms.labels.rank");
  const STATE = t("forms.labels.state");
  const STREET = t("forms.labels.street");

  const EMAIL = t("forms.labels.email");
  const PASSWORD = t("forms.labels.password");
  const PASSWORD_OPTIONAL = t("forms.labels.password_optional");
  const CONFIRM_PASSWORD = t("forms.labels.confirm_password");
  const CONFIRM_EMAIL = t("forms.labels.confirm_email");

  const THE_FIELD_MUST_CONTAIN_AT_LEAST_2_SYMBOLS = t(
    "forms.messages.the_field_must_contain_at_least_number_symbols",
    {
      number: 2,
    }
  );
  // const THE_FIELD_MUST_CONTAIN_AT_LEAST_3_SYMBOLS = t("forms.messages.the_field_must_contain_at_least_number_symbols", {
  //   number: 3,
  // });
  const THE_FIELD_MUST_CONTAIN_AT_LEAST_5_SYMBOLS = t(
    "forms.messages.the_field_must_contain_at_least_number_symbols",
    {
      number: 5,
    }
  );
  const THE_FIELD_MUST_CONTAIN_NO_MORE_THAN_10_SYMBOLS = t(
    "forms.messages.the_field_must_contain_no_more_than_number_symbols",
    { number: 10 }
  );
  // const THE_FIELD_MUST_CONTAIN_ONLY_LATIN_CHARACTERS = t("forms.messages.the_field_must_contain_only_latin_characters");
  // const THE_FIELD_MUST_CONTAIN_ONLY_LATIN_CHARACTERS_AND_DIGITS = t(
  //   "forms.messages.the_field_must_contain_only_latin_characters_and_digits"
  // );
  // const THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS = t(
  //   "forms.messages.the_field_must_contain_only_latin_or_hebrew_characters"
  // );
  // const THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_AND_NUMBERS = t(
  //   "forms.messages.the_field_must_contain_only_latin_or_hebrew_characters_and_numbers"
  // );
  // const THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_AND_SPACES = t(
  //   "forms.messages.the_field_must_contain_only_latin_or_hebrew_characters_and_spaces"
  // );
  // const THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_NUMBERS_SPACES_AND_SYMBOLS = t(
  //   "forms.messages.the_field_must_contain_only_latin_or_hebrew_characters_numbers_spaces_and_symbols"
  // );
  const THIS_FIELD_IS_REQUIRED = t("forms.messages.this_field_is_required");
  const TO = t("forms.labels.to");
  const ZIP = t("forms.labels.zip");

  const THIS_EMAIL_IS_NOT_VALID = t("forms.messages.this_email_is_not_valid");
  const AT_LEAST_SIX_SYMBOLS = t("forms.messages.at_least_number_symbols", {
    number: 6,
  });
  const THE_FIELD_DOES_NOT_MATCH = t("forms.messages.the_field_does_not_match");

  const query = useQuery();
  const type = query.get("type") || PROJECTS.MY_MAOR.PACKAGES.DOUBLE_LAYER.ID;
  const id = query.get("id");
  const initials = getInitials({ id: id, items: cart.items, user: persons });

  const hasDelivery = checkHasDelivery(type);

  /* birthday details */
  // const date = useInput({
  //   label: DATE,
  // });

  // let childOptions = authorization.persons.map((person) => {
  //   return { label: `${person.first_name} ${person.last_name}`, value: person.id };
  // });

  // child.options = child.options.concat(childOptions);
  // console.log(toJS(person));

  let city = useInput({
    label: CITY + " *",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      // {
      //   name: NAMES.HEBREW_LATIN_SPACES,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_AND_SPACES,
      //   },
      // },
    ],
    value: initials.details.city,
  });

  const country = useInput({
    label: COUNTRY + " *",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      // { name: NAMES.HEBREW_LATIN, options: { message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS } },
      // { name: NAMES.MIN_3, options: { message: THE_FIELD_MUST_CONTAIN_AT_LEAST_3_SYMBOLS } },
    ],
    value: initials.details.country,
  });

  const firstName = useInput({
    label: FIRST_NAME + " *",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      // {
      //   name: NAMES.HEBREW_LATIN_SPACES,
      //   options: { message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_AND_SPACES },
      // },
    ],
    value: initials.details.first_name,
  });
  const lastName = useInput({
    label: LAST_NAME + " *",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      // {
      //   name: NAMES.HEBREW_LATIN_SPACES,
      //   options: { message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_AND_SPACES },
      // },
    ],
    value: initials.details.last_name,
  });

  // Parent details
  const parentFirstName = useInput({
    label: FIRST_NAME + " *",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      // {
      //   name: NAMES.HEBREW_LATIN_SPACES,
      //   options: { message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_AND_SPACES },
      // },
    ],
    value: authorization.isAuthenticated ? authorization.user.first_name : "",
  });
  const parentLastName = useInput({
    label: LAST_NAME + " *",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      // {
      //   name: NAMES.HEBREW_LATIN_SPACES,
      //   options: { message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_AND_SPACES },
      // },
    ],
    value: authorization.isAuthenticated ? authorization.user.last_name : "",
  });

  const pronunciation = useInput({
    label: PRONUNCIATION,
    validators: [
      // {
      //   name: NAMES.HEBREW_LATIN_SPACES,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_AND_SPACES,
      //   },
      // },
    ],
    value: initials.details.pronunciation,
  });

  const person_id = useInput({
    label: PERSON_ID,
    value: initials.details.person_id,
  });

  const address_id = useInput({
    label: ADDRESS_ID,
    value: initials.details.address_id,
  });
  // const date = useInput({
  //   label: DATE + " *",
  //   validators: [{ name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } }],
  //   value: "",
  // });

  const enDay = useSelectR({
    label: "Day",
    options: enDays,
    value: initials.details.day,
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
  });


  const enMonth = useSelectR({
    label: "Month",
    options: enMonths,
    value: initials.details.month,
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
  });

  const enYear = useSelectR({
    label: "Year",
    options: enYears,
    value: initials.details.year,
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
  });

  const jewishDay = useSelectR({
    label: "Day",
    options: jewishDays,
    value: initials.details.jewishDay,
    validators: [
      {
        name: NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
    ],
  });

  const jewishMonth = useSelectR({
    label: "Month",
    options: jewishMonths,
    value: initials.details.jewishMonth,
    validators: [
      {
        name: NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
    ],
  });

  const jewishYear = useSelectR({
    label: "Year",
    options: jewishYears,
    value: initials.details.jewishYear,
    validators: [
      {
        name: NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
    ],
  });

  // const birthday = useDatepicker({
  //   label: "Birthday",
  //   value: initials.details.date || new Date(2015, 0, 1),
  //   promptSunsetCheck: true,
  //   useHeTranslation: true,
  //   bornAfterSunset: initials.details.born_after_sunset || false,
  // });

  const renewBirthday = useCheckbox({
    callback: ({ checked }) => {
      setIsAutoRenewalBirthday(checked);
    },
    checked: initials.birthday_recurring || false,
    value: initials.birthday_recurring || isAutoRenewalBirthday,
  });

  const afterShkiyaCheckbox = useCheckbox({
    callback: ({ checked }) => {
      setIsAfterShkiyaChecked(checked);
    },
    checked: false || initials.details.born_after_sunset,
    label: `After Shkiya`,
    value: initials.details.born_after_sunset || isAfterShkiyaChecked,
  });

  useEffect(() => {
    // if (!isCivil) {
    //   afterShkiyaCheckbox.setChecked(false);
    //   setIsAfterShkiyaChecked(false);
    //   initials.details.born_after_sunset = false;
    // }

    if (isCivil) {
      if (initials.details.born_after_sunset == true) {
        setIsAfterShkiyaChecked(true);
      }
    }
  }, [isCivil, initials.details.born_after_sunset]);

  useEffect(() => {
    if (!isAfterShkiyaChecked) {
      initials.details.born_after_sunset = false;
    }
  }, [isAfterShkiyaChecked])

  const languageToggleBox = useToggle("Civil", ({ selectedValue }) => {
    // Do something with selectedValue when it changes
    if (selectedValue == "Civil") {
      setIsCivil(true);
    } else {
      setIsCivil(false);
    }
  });

  const rank = useInput({
    label: TZIVOS_RANK_HASHEM,
    validators: [
      // {
      //   name: NAMES.HEBREW_LATIN_DIGITS,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_AND_NUMBERS,
      //   },
      // },
    ],
    value: initials.details.rank,
  });

  // const sex = useSelectR({
  //   label: "Gender *",
  //   options: [
  //     {
  //       label: "Gender *",
  //       value: "",
  //     },
  //     {
  //       label: BOY,
  //       value: "boy",
  //     },
  //     {
  //       label: GIRL,
  //       value: "girl",
  //     },
  //   ],
  //   validators: [{ name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } }],
  //   value: initials.details.sex || "",
  // });

  const SEX = {
    BOY: "boy",
    GIRL: "girl",
  };

  const sex = useRadio({
    options: [
      { id: "my-maor-order-form-sex-man", label: BOY, value: SEX.BOY },
      { id: "my-maor-order-form-sex-woman", label: GIRL, value: SEX.GIRL },
    ],
    value: initials.details.sex,
    validators: [{ name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } }],
  });

  console.log(sex, "sexx");

  const state = useInput({
    label: STATE,
    validators: [
      // { name: NAMES.LATIN, options: { message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_CHARACTERS } },
      // { name: NAMES.MIN_2, options: { message: THE_FIELD_MUST_CONTAIN_AT_LEAST_2_SYMBOLS } },
    ],
    value: initials.details.state,
  });
  /* delivery details */
  const deliveryApartment = useInput({
    label: APARTMENT,
    validators: [
      // {
      //   name: NAMES.HEBREW_LATIN_DIGITS_SYMBOLS,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_NUMBERS_SPACES_AND_SYMBOLS,
      //   },
      // },
    ],
    value: initials.delivery.apartment,
  });
  const deliveryCity = useInput({
    label: CITY + " *",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      // {
      //   name: NAMES.HEBREW_LATIN_SPACES,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_AND_SPACES,
      //   },
      // },
    ],
    value: initials.delivery.city,
  });

  const deliveryCountry = useSelectR({
    label: COUNTRY + " *",
    options: [
      { label: "USA", value: "USA" },
      // { label: "Canada", value: "Canada" },
    ],
    value: initials.delivery.country || { label: "USA", value: "USA" },
  });

  const deliveryState = useInput({
    label: STATE + " *",
    validators: [
      // {
      //   name: NAMES.HEBREW_LATIN,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS,
      //   },
      // },
      {
        name: NAMES.REQUIRED,
        options: { message: THE_FIELD_MUST_CONTAIN_AT_LEAST_2_SYMBOLS },
      },
    ],
    value: initials.delivery.state,
  });

  const deliveryStreet = useInput({
    label: STREET + " *",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      // {
      //   name: NAMES.HEBREW_LATIN_DIGITS_SYMBOLS,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_NUMBERS_SPACES_AND_SYMBOLS,
      //   },
      // },
    ],
    value: initials.delivery.street,
  });
  const deliveryZip = useInput({
    label: ZIP + " *",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      // {
      //   name: NAMES.LATIN_DIGITS,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_CHARACTERS_AND_DIGITS,
      //   },
      // },
      {
        name: NAMES.MIN_5,
        options: { message: THE_FIELD_MUST_CONTAIN_AT_LEAST_5_SYMBOLS },
      },
      {
        name: NAMES.MAX_10,
        options: { message: THE_FIELD_MUST_CONTAIN_NO_MORE_THAN_10_SYMBOLS },
      },
    ],
    value: initials.delivery.zip_code,
  });
  const from = useInput({
    label: FROM,
    validators: [
      // {
      //   name: NAMES.HEBREW_LATIN_SPACES,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_AND_SPACES,
      //   },
      // },
    ],
    value: initials.wishes.from,
  });
  const message = useInput({
    label: MESSAGE,
    value: initials.wishes.message,
  });
  const to = useInput({
    label: TO,
    validators: [
      // {
      //   name: NAMES.HEBREW_LATIN_SPACES,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_AND_SPACES,
      //   },
      // },
    ],
    value: initials.wishes.to,
  });

  let email;
  email = useDoubleInput({
    first: "Original",
    second: "Confirm",
    labelOfFirst: EMAIL,
    labelOfSecond: CONFIRM_EMAIL,
    validatorsOfFirst: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      {
        name: VALIDATIONS_NAMES.EMAIL,
        options: { message: THIS_EMAIL_IS_NOT_VALID },
      },
    ],
    validatorsOfSecond: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      {
        name: VALIDATIONS_NAMES.EMAIL,
        options: { message: THIS_EMAIL_IS_NOT_VALID },
      },
      {
        name: VALIDATIONS_NAMES.EQUAL,
        options: { message: THE_FIELD_DOES_NOT_MATCH },
      },
    ],
  });

  /* password */
  let password;
  if (authorization.isAuthenticated) {
    password = useDoubleInput({
      first: "Original",
      second: "Confirm",
      labelOfFirst: PASSWORD_OPTIONAL,
      labelOfSecond: CONFIRM_PASSWORD,
      validatorsOfFirst: [
        {
          name: VALIDATIONS_NAMES.MIN_SIX_SYMBOLS,
          options: { message: AT_LEAST_SIX_SYMBOLS },
        },
      ],
      validatorsOfSecond: [
        {
          name: VALIDATIONS_NAMES.MIN_SIX_SYMBOLS,
          options: { message: AT_LEAST_SIX_SYMBOLS },
        },
        {
          name: VALIDATIONS_NAMES.EQUAL,
          options: { message: THE_FIELD_DOES_NOT_MATCH },
        },
      ],
    });
  } else {
    password = useDoubleInput({
      first: "Original",
      second: "Confirm",
      labelOfFirst: PASSWORD,
      labelOfSecond: CONFIRM_PASSWORD,
      validatorsOfFirst: [
        {
          name: VALIDATIONS_NAMES.REQUIRED,
          options: { message: THIS_FIELD_IS_REQUIRED },
        },
        {
          name: VALIDATIONS_NAMES.MIN_SIX_SYMBOLS,
          options: { message: AT_LEAST_SIX_SYMBOLS },
        },
      ],
      validatorsOfSecond: [
        {
          name: VALIDATIONS_NAMES.REQUIRED,
          options: { message: THIS_FIELD_IS_REQUIRED },
        },
        {
          name: VALIDATIONS_NAMES.MIN_SIX_SYMBOLS,
          options: { message: AT_LEAST_SIX_SYMBOLS },
        },
        {
          name: VALIDATIONS_NAMES.EQUAL,
          options: { message: THE_FIELD_DOES_NOT_MATCH },
        },
      ],
    });
  }

  const isValidEmail = authorization.isAuthenticated
    ? email.errorsOfOriginal.length
    : email.errorsOfOriginal.length + email.errorsOfConfirm.length;
  const isValidPassword = authorization.isAuthenticated
    ? 0
    : password.errorsOfOriginal.length + password.errorsOfConfirm.length;

  const isNotValidPersonal =
    parentLastName.errors.length || isValidEmail || isValidPassword;

  const isNotValidBirthdayDetails = !!(
    firstName.errors.length ||
    lastName.errors.length ||
    city.errors.length ||
    sex.errors.length ||
    (isCivil &&
      (enDay.errors.length ||
        enMonth.errors.length ||
        enYear.errors.length ||
        enDay.value.value == undefined ||
        enMonth.value.value == undefined ||
        enYear.value.value == undefined)
    ) ||
    (!isCivil && (
      jewishDay.errors.length ||
      jewishMonth.errors.length ||
      jewishYear.errors.length ||
      jewishDay.errors.length == undefined ||
      jewishMonth.errors.length == undefined ||
      jewishYear.errors.length == undefined
    )) ||
    // state.errors.length ||
    country.errors.length
  );

  let isNotValidDeliveryDetails = false;
  if (hasDelivery) {
    isNotValidDeliveryDetails = !!(
      deliveryStreet.errors.length ||
      deliveryCity.errors.length ||
      deliveryState.errors.length ||
      deliveryZip.errors.length
    );
  }

  let isEnglishDate = false;
  isEnglishDate = (
    enDay.errors.length ||
    enMonth.errors.length ||
    enYear.errors.length ||
    enDay.value.value == undefined || enMonth.value.value == undefined || enYear.value.value == undefined)

  let isJewishDate = false;
  isJewishDate = (
    jewishDay.errors.length ||
    jewishMonth.errors.length ||
    jewishYear.errors.length ||
    jewishDay.value.value == undefined || jewishMonth.value.value == undefined || jewishYear.value.value == undefined)

  let referredFrom = "";
  if (navigator.cookieEnabled) {
    referredFrom = localStorage.getItem("referral-code");
  }

  // For covert english to Jewsih for Frontend
  let englishDate = `${enDay.value.value} ${enMonth.value.value} ${enYear.value.value}`;

  const jewishDate = formatJewishDate(
    new Date(englishDate),
    isAfterShkiyaChecked
  );
  const englishToJewishDate = convertJewishEngToJewish(jewishDate);

  let jewishToEnglishDate;
  let jewishToEnglishMonth;
  let jewishToEnglishYear;

  let englishToJewishDateArray = englishToJewishDate.split(" ").reverse();
  if (englishToJewishDateArray.length > 3) {
    jewishToEnglishDate =
      englishToJewishDateArray[englishToJewishDateArray.length - 1];
    jewishToEnglishMonth =
      englishToJewishDateArray[2] + " " + englishToJewishDateArray[1];
    jewishToEnglishYear = englishToJewishDateArray[0];
  } else {
    jewishToEnglishDate =
      englishToJewishDateArray[englishToJewishDateArray.length - 1];
    jewishToEnglishMonth = englishToJewishDateArray[1];
    jewishToEnglishYear = englishToJewishDateArray[0];
  }

  useEffect(() => {
    if (!isCivil) {
      jewishDay.onChange({
        label: jewishToEnglishDate,
        value: jewishToEnglishDate,
      });
      jewishMonth.onChange({
        label: jewishToEnglishMonth,
        value: jewishToEnglishMonth,
      });
      jewishYear.onChange({
        label: jewishToEnglishYear,
        value: jewishToEnglishYear,
      });
    }
  }, [isCivil]);

  // Enlish Date For backend Payload
  const englishDateString = new Date(
    `${enDay.value.value} ${enMonth.value.value} ${enYear.value.value}`
  );

  const englishDateDay = englishDateString.getDate();
  const englishDateMonth = englishDateString.getMonth() + 1;
  const englishDateYear = englishDateString.getFullYear();
  const englishFullDate = `${englishDateDay} ${englishDateMonth} ${englishDateYear}`;

  // Convert jewish to jewishEnglish language
  let yearData = "";
  Object.values(jewishYears).find((year) => {
    if (year.value === jewishYear.value.value) {
      yearData = year.englishYear;
    }
  });

  let dayData = "";
  Object.values(jewishDays).find((day) => {
    if (day.value === jewishDay.value.value) {
      dayData = day.englishDay;
    }
  });

  const jewEngLishMonth = getJewishEnglishMonth(jewishMonth.value.value);
  const jewEngLishYear = yearData;
  const jewEngLishDay = dayData;
  const jewishFullDate = `${jewEngLishDay} ${jewEngLishMonth} ${jewEngLishYear}`;

  // Converte JewishEnglish to English
  let heDate = new Hebcal.HDate(jewishFullDate);
  let prevDate = heDate.prev().greg();
  const gregorianDate = heDate.greg();

  const prevMonth = prevDate.getMonth() + 1;
  const prevYear = prevDate.getFullYear();
  const prevDay = prevDate.getDate();

  const gregorianMonth = gregorianDate.getMonth() + 1;
  const gregorianYear = gregorianDate.getFullYear();
  const gregorianDay = gregorianDate.getDate();

  // For backend payload
  const gregorianDateFullDate = `${gregorianDay} ${gregorianMonth} ${gregorianYear}`;
  // For FrontEnd
  const gregorianDateText = `${isAfterShkiyaChecked ? prevMonth : gregorianMonth}/${isAfterShkiyaChecked ? prevDay : gregorianDay}/${isAfterShkiyaChecked ? prevYear : gregorianYear}`;

  const isInitialRender = useRef(true);

  useEffect(() => {
    if (!isInitialRender.current) {
      if (!gregorianDay) {
        enDay.onChange({ label: "", value: "" }, false);
      } else {
        enDay.onChange({ label: isAfterShkiyaChecked ? prevDay : gregorianDay, value: isAfterShkiyaChecked ? prevDay : gregorianDay });
      }
      if (!gregorianMonth) {
        enMonth.onChange({ label: "", value: "" }, false);
      } else {
        enMonth.onChange({ label: isAfterShkiyaChecked ? getMonthName(prevMonth) : getMonthName(gregorianMonth), value: isAfterShkiyaChecked ? getMonthName(prevMonth) : getMonthName(gregorianMonth) });
      }
      if (!gregorianYear) {
        enYear.onChange({ label: "", value: "" }, false);
      } else {
        enYear.onChange({ label: isAfterShkiyaChecked ? prevYear : gregorianYear, value: isAfterShkiyaChecked ? prevYear : gregorianYear });
      }
    } else {
      // Set the flag to false after initial render
      isInitialRender.current = false;
    }
  }, [isCivil]);


  const triggerValidationsPersonal = () => {
    firstName.touch();
    lastName.touch();
    city.touch();
    country.touch();
    sex.touch();
    enMonth.touch();
    enYear.touch();
    enDay.touch();
  }

  const triggerValidationsDelivery = () => {
    deliveryState.touch();
    deliveryStreet.touch();
    deliveryCity.touch();
    deliveryZip.touch();
  }

  const onSubmit = ({ callback }) => {
    const data = {
      type: type,
      birthday_recurring: renewBirthday.checked,
      client: {
        first_name: parentFirstName.value,
        last_name: parentLastName.value,
        email: email.valueOfOriginal,
      },
      details: {
        // child: child.value,
        city: city.errors.length ? "" : city.value,
        state: state.errors.length ? "" : state.value,
        country: country.value,
        // date: `${birthday.value.getFullYear()}-${birthday.value.getMonth() + 1}-${birthday.value.getDate()}`,
        date: isCivil
          ? {
            en: englishFullDate,
            he: jewishDate,
          }
          : {
            en: gregorianDateFullDate,
            he: jewishFullDate,
          },
        hebrew_date: isCivil
          ? englishToJewishDate
          : `${jewishDay.value.value} ${jewishMonth.value.value} ${jewishYear.value.value}`,
        // born_after_sunset: !birthday.hebrew ? birthday.bornAfterSunset : null,
        born_after_sunset: isCivil ? isAfterShkiyaChecked : false,
        first_name: firstName.value,
        last_name: lastName.value,
        rank: rank.errors.length ? "" : rank.value,
        sex: sex.value,
        pronunciation: pronunciation.errors.length ? "" : pronunciation.value,
        person_id: person_id.errors.length ? "" : person_id.value,
        date_language: isCivil ? "english" : "hebrew",
      },
      delivery: {
        apartment: deliveryApartment.errors.length
          ? ""
          : deliveryApartment.value,
        city: deliveryCity.value,
        country: deliveryCountry.value.value,
        state: deliveryState.value,
        street: deliveryStreet.value,
        zip_code: deliveryZip.value,
        address_id: address_id.errors.length ? "" : address_id.value,
      },
      wishes: {
        from: from.errors.length ? "" : from.value,
        message: message.errors.length ? "" : message.value,
        to: to.errors.length ? "" : to.value,
      },
    };
    console.log("data #3aman", data);

    // data.details.he_date = formatJewishDate(new Date(data.details.date), data.details.born_after_sunset, true);
    // data.details.he_date = isCivil ? `${enDay.value.value} ${enMonth.value.value} ${enYear.value.value}` : `${jewishDay.value.value} ${jewishMonth.value.value} ${jewishYear.value.value}`;

    console.log("Born After Sunset", data);
    if (!data.delivery.apartment) delete data.delivery.apartment;
    if (!data.delivery.state) delete data.delivery.state;
    if (!data.details.first_name) {
      delete data.client.first_name;
      delete data.details.first_name;
    }

    if (referredFrom) data.referred_from = referredFrom;

    if (!data.details.state) data.details.state = "";

    if (!hasDelivery) delete data.delivery;

    // if (birthday.hebrew) {
    //   console.log("Born After Sunset: Deleting");
    //   delete data.details.born_after_sunset;
    // }

    if (Cookies.get(COOKIES.CART_ID)) {
      data.id = Cookies.get(COOKIES.CART_ID);
    }

    // if (!isNaN(child.value)) {
    //   data.member_id = child.value;
    // }

    if (id) {
      cart.edit({ data, id: Number(id), onSuccess: callback });
      return;
    }

    cart.add({ data, onSuccess: callback });
    if (navigator.cookieEnabled) {
      localStorage.removeItem("referral-code");
    }
  };


  return {
    // child,
    jewishDay,
    jewishMonth,
    jewishYear,
    jewishDate,
    gregorianDateText,
    englishToJewishDate,
    jewishFullDate,
    enDay,
    enMonth,
    enYear,
    afterShkiyaCheckbox,
    languageToggleBox,
    // date,
    city,
    country,
    firstName,
    parentFirstName,
    parentLastName,
    isNotValidPersonal,
    isNotValidBirthdayDetails,
    isNotValidDeliveryDetails,
    lastName,
    pronunciation,
    person_id,
    address_id,
    rank,
    sex,
    state,
    deliveryApartment,
    deliveryCity,
    deliveryCountry,
    deliveryState,
    from,
    message,
    deliveryStreet,
    to,
    deliveryZip,
    hasDelivery,
    // birthday,
    showDetails,
    email,
    password,
    renewBirthday: renewBirthday,
    triggerValidationsPersonal: triggerValidationsPersonal,
    triggerValidationsDelivery: triggerValidationsDelivery,
    onSubmit: onSubmit,
    setAddChild: setAddChild,
    addChild: addChild,
    setAddAddress: setAddAddress,
    addAddress: addAddress,
    location: location,
    setLocation: setLocation,
    isEnglishDate: isEnglishDate,
    isJewishDate: isJewishDate,
    setDeleteChildSuccess,
    deleteChildSuccess,
    deleteAddressSuccess,
    setDeleteAddressSuccess,
    isAfterShkiyaChecked: isAfterShkiyaChecked,
    setIsAfterShkiyaChecked: setIsAfterShkiyaChecked,
  };
};

export default useForm;
