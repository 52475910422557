// import Personal from "./components/Personal";
import { DELIVERY_COUNTRIES } from "../../../constants";

import { getPackageDetails } from "../../../utilities/helpers";
import DeliveryDetails from "./components/DeliveryDetails/DeliveryDetails";

const checkHasDelivery = (type) => {
  const details = getPackageDetails(type);
  const hasDelivery = details.DELIVERY;

  return hasDelivery;
};

const getDots = (steps, currentStepNumber) => {
  const title_translation_keys = ["partners.address"];

  const dots = [
    { active: true, checked: true, title_translation_key: "partners.packages" },
    ...steps.map((item, index) => ({
      active: index + 1 <= currentStepNumber,
      checked: index + 1 < currentStepNumber,
      title_translation_key: title_translation_keys[index],
    })),
    {
      active: false,
      checked: false,
      title_translation_key: "partners.checkout",
    },
  ];

  return dots;
};

// const getRangerValueOfNumberOfPayments = (value) => {
//   const number = Number(value);

//   switch (number) {
//     case 1:
//       return 1;
//     case 2:
//       return 2;
//     case 3:
//       return 3;
//     case 4:
//       return 4;
//     case 6:
//       return 5;
//     case 12:
//       return 6;
//     default:
//       return 1;
//   }
// };

const getInitials = ({ id, items }) => {
  console.log("getInitials", id, items);
  const item = items.find((item) => item.item_id === Number(id));

  if (item) {
    return {
      details: {
        email: item.data.details.email || "",
        firstName: item.data.details.first_name || "",
        interval: item.interval || 1,
        lastName: item.data.details.last_name || "",
      },
      address: {
        apartment: (item.data.delivery && item.data.delivery.apartment) || "",
        city: (item.data.delivery && item.data.delivery.city) || "",
        country:
          (item.data.delivery && {
            label: item.data.delivery.country,
            value: item.data.delivery.country,
          }) ||
          DELIVERY_COUNTRIES.USA,
        state: (item.data.delivery && item.data.delivery.state) || "",
        street: (item.data.delivery && item.data.delivery.street) || "",
        zip: (item.data.delivery && item.data.delivery.zip) || "",
      },
    };
  }

  return {
    details: {
      email: "",
      firstName: "",
      interval: [1],
      lastName: "",
    },
    address: {
      apartment: "",
      city: "",
      country: "",
      state: "",
      street: "",
      zip: "",
    },
  };
};

const getNumberOfPayments = (value) => {
  switch (value) {
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 3;
    case 4:
      return 4;
    case 5:
      return 6;
    case 6:
      return 12;
    default:
      return 1;
  }
};

const getPackageView = (type) => {
  const details = getPackageDetails(type);
  return {
    title_translation_key: details.TITLE_TRANSLATION_KEY,
    Illustration: details.Illustration,
  };
};

const getPaymentsDetails = ({ type, typeOfPayments }) => {
  // const numberOfPayments = 12 / typeOfPayments;
  const PACKAGE = getPackageDetails(type);
  const COST = typeOfPayments == 12 ? PACKAGE.COST_YEAR : PACKAGE.COST_MONTH;
  const CREDITS =
    typeOfPayments == 12 ? PACKAGE.CREDITS_TOTAL : PACKAGE.CREDITS_MONTH;

  // const paymentPerMonth = Math.floor(COST / numberOfPayments);
  // const creditsPerMonth = Math.floor(CREDITS / numberOfPayments);

  return { payment: COST, credits: CREDITS };
};

const getSteps = ({ form }) => {
  console.log(form.isNotValidUserAddress, "addAddress");
  const steps = [
    // {
    //   props: form,
    //   isNotValid: form.isNotValidPersonal || form.isNotValid,
    //   View: Personal,
    // },
    {
      props: form,
      isNotAddressScreen: form.addAddress,
      isNotValid: form.isNotValidUserAddress,
      triggerValidations: form.triggerValidationsUserAddress,
      View: DeliveryDetails,
    },
  ];
  return steps;
};

const getValueWithoutError = (item) => {
  return item.errors.length ? "" : item.value;
};

export {
  checkHasDelivery,
  getDots,
  getInitials,
  getNumberOfPayments,
  getPackageView,
  getPaymentsDetails,
  getSteps,
  getValueWithoutError,
};
