const getTranslationKeyOfError = (value) => {
  switch (value) {
    case "422":
      return "authorization.something_went_wrong_please_try_again_later";
    case "duplicate_email":
      return "authorization.duplicate_email";
    case "invalid_referral_code":
      return "authorization.invalid_referral_code";
    default:
      return "authorization.something_went_wrong_please_try_again_later";
  }
};

export { getTranslationKeyOfError };
