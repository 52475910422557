import React from "react";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import cs from "classnames";

import { COLORS, SIZES } from "./utilities";
import FlagIcon from "../../../assets/svg/flag.svg";
import classes from "./Checkbox.module.scss";

const Checkbox = ({ children, color, size, birthday = false, ...properties }) => (
  <label className={cs(classes.container, classes[color], classes[size], birthday && classes.birthdayCheckBox, birthday && properties.checked ? classes.borderOrange : "")}>
    <div className={classes.iconBox}>
      <input type="checkbox" className={classes.input} {...properties} />
      <span className={cs(classes.icon, birthday && classes.birthdayIcon)}>
        {!birthday &&
          <ReactSVG className={classes.flag} src={FlagIcon} />
        }
      </span>
    </div>
    <div className={cs(classes.textBox, birthday && classes.birthdayTextBox, birthday && properties.checked ? classes.birthdayCheckedFont : "")}>{children}</div>
  </label>
);

Checkbox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  color: PropTypes.oneOf(Object.values(COLORS)),
  size: PropTypes.oneOf(Object.values(SIZES)),
  birthday: PropTypes.bool
};

Checkbox.defaultProps = {
  color: COLORS.PRIMARY,
  size: SIZES.MEDIUM,
};

export default Checkbox;
