import { makeAutoObservable } from "mobx";

const TYPES = {
  CHILD: "child",
  DAILY_REBBE_VIDEO: "daily-rebbe-video",
  DEFAULT: "default",
  ILLUMINATE: "illuminate",
  MY_MAOR: "my-maor",
  PARTNERS: "partners",
};

class Common {
  constructor() {
    this.project = TYPES.DEFAULT;
    this.selectProjectScreen = true;
    this.options = "default";

    makeAutoObservable(this);
  }

  get(key) {
    return this[key];
  }

  set(key, value) {
    console.log(key, value);
    this[key] = value;
  }
}

export default Common;
