import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "../../../../atoms/Button";
import Checkbox from "../../../../atoms/Checkbox";
import OptionalInput from "../../../../atoms/OptionalInput";

const FindOut = ({
  findOutAd,
  findOutAdDetails,
  findOutOther,
  findOutOtherDetails,
  findOutReferral,
  findOutVideo,
  findOutVideoDetails,
  findOutWhatsApp,
  findOutWhatsAppDetails,
  isValid,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const LABEL_SUBMIT = t("pages.authorization.questionnaire_main.labels.submit_2");
  const TITLE = t("pages.authorization.questionnaire_main.questions.3");

  return (
    <div>
      <h3 className="heading-m ta-center c-blue-dark">{TITLE}</h3>
      <form
        autoComplete="off"
        className="mt-07 mr-auto ml-auto w-100p"
        style={{ maxWidth: "480px" }}
        onSubmit={onSubmit}
      >
        <div>
          <Checkbox checked={findOutVideo.checked} onChange={findOutVideo.onChange}>
            {findOutVideo.label}
          </Checkbox>
        </div>
        {findOutVideo.checked && (
          <div className="mt-06 ml-09">
            <OptionalInput
              id="questionnaire-form-find-out-step-video-details"
              label={findOutVideoDetails.label}
              value={findOutVideoDetails.value}
              onChange={findOutVideoDetails.onChange}
            />
          </div>
        )}
        <div className="mt-06">
          <Checkbox checked={findOutAd.checked} onChange={findOutAd.onChange}>
            {findOutAd.label}
          </Checkbox>
        </div>
        {findOutAd.checked && (
          <div className="mt-06 ml-09">
            <OptionalInput
              id="questionnaire-form-find-out-step-ad-details"
              label={findOutAdDetails.label}
              value={findOutAdDetails.value}
              onChange={findOutAdDetails.onChange}
            />
          </div>
        )}
        <div className="mt-06">
          <Checkbox checked={findOutWhatsApp.checked} onChange={findOutWhatsApp.onChange}>
            {findOutWhatsApp.label}
          </Checkbox>
        </div>
        {findOutWhatsApp.checked && (
          <div className="mt-06 ml-09">
            <OptionalInput
              id="questionnaire-form-find-out-step-whatsapp-details"
              label={findOutWhatsAppDetails.label}
              value={findOutWhatsAppDetails.value}
              onChange={findOutWhatsAppDetails.onChange}
            />
          </div>
        )}
        <div className="mt-06">
          <Checkbox checked={findOutReferral.checked} onChange={findOutReferral.onChange}>
            {findOutReferral.label}
          </Checkbox>
        </div>
        <div className="mt-06">
          <Checkbox checked={findOutOther.checked} onChange={findOutOther.onChange}>
            {findOutOther.label}
          </Checkbox>
        </div>
        {findOutOther.checked && (
          <div className="mt-06 ml-09">
            <OptionalInput
              id="questionnaire-form-find-out-step-other-details"
              value={findOutOtherDetails.value}
              onChange={findOutOtherDetails.onChange}
            />
          </div>
        )}
        <div className="d-flex fd-column ai-center mt-08">
          <Button disabled={!isValid} type="submit">
            {LABEL_SUBMIT}
          </Button>
        </div>
      </form>
    </div>
  );
};

const checkboxPropTypes = PropTypes.shape({
  checked: PropTypes.bool.isRequired,
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
}).isRequired;
const inputPropTypes = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
});

FindOut.propTypes = {
  findOutAd: checkboxPropTypes,
  findOutAdDetails: inputPropTypes,
  findOutOther: checkboxPropTypes,
  findOutOtherDetails: inputPropTypes,
  findOutReferral: checkboxPropTypes,
  findOutVideo: checkboxPropTypes,
  findOutVideoDetails: inputPropTypes,
  findOutWhatsApp: checkboxPropTypes,
  findOutWhatsAppDetails: inputPropTypes,
  isValid: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FindOut;
