import React from "react";
import cs from "classnames";
import classes from "./GoldPartners.module.scss";
import { observer } from "mobx-react-lite";
import useGoldPartners from "./useGoldPartners";

const GoldPartners = observer(() => {
  const memberLimit = 12;
  const goldPartners = useGoldPartners({ memberLimit });

  return (
    <div className={cs(classes.container)}>
      <div className={cs(classes.headingContainer)}>
        <h3>Gold partners</h3>
      </div>
      <div className={cs(classes.partners)}>
        <ul>
          {Object.entries(goldPartners.latestGoldPartners).map(
            ([key, value]) => (
              <li key={key}>
                <p>
                  {" "}
                  The {value.charAt(0).toUpperCase() +
                    value.slice(1)} Family{" "}
                </p>
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
});

export default React.memo(GoldPartners);
