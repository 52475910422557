import React from "react";
// import { useHistory } from "react-router-dom";
// import { useTranslation } from "react-i18next";
// import { useHistory } from "react-router-dom";

import { observer } from "mobx-react-lite";
import useForm from "../../../../useForm";
import PlainInput from "../../../../../../atoms/PlainInput";
// import Button from "../../../../../../atoms/Button";
import classes from "./AvailableCredits.module.scss";
import cs from "classnames";

const AvailableCredits = observer(({ cartTotal, availableCredits }) => {
  const form = useForm();
  return (
    <>
      {/* <p className={cs(classes.creditText, "mb-06 ta-center d-block")}>Available Credits: ${availableCredits}</p> */}
      <div className={cs(classes.creditWrap, "mb-05")}>
        <div className={cs(classes.creditInnerWrap)}>
          <div className={cs(classes.availCredits, "d-flex ai-center")}>
            <span className={cs(classes.creditText, "c-orange fw-700")}>
              MaorCredit Available:
            </span>
            <div className="d-flex ai-center">
              <span className={cs(classes.creditText, "c-orange fw-700 mr-01")}>
                ${" "}
              </span>
              <PlainInput
                additionalClass="creditInput"
                id="use-credits"
                label={form.useCredits.label}
                type="number"
                max={
                  cartTotal < availableCredits ? cartTotal : availableCredits
                }
                min="0"
                value={form.useCredits.value}
                onChange={form.useCredits.onChange}
              />
            </div>
          </div>
          <button
            className={classes.applyButton}
            type="submit"
            onClick={form.applyCredits}
            disabled={form.applyingCredits}
          >
            Apply
          </button>
        </div>

        <div className={cs(classes.subTotal, "ta-center w-100 mb-02")}>
          Sub Total: ${cartTotal}
        </div>
        <div className={cs(classes.creditsUsed, "ta-center w-100 mb-02")}>
          MaorCredit: -${form.creditValue}
        </div>
        <input
          type="hidden"
          id="grand-total"
          value={
            cartTotal > form.creditValue ? cartTotal - form.creditValue : 0
          }
        />
        <div
          className={cs(classes.paymentAmount, "fw-700 ta-center w-100 mb-02")}
        >
          Grand Total: $
          {cartTotal > form.creditValue ? cartTotal - form.creditValue : 0}
        </div>

        {form.creditsError && (
          <div className={cs(classes.error)}>{form.creditsError}</div>
        )}
      </div>
    </>
  );
});

export default AvailableCredits;
