import React, { useContext, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import cs from "classnames";

import Loader from "../../../../atoms/Loader";
import { StoreContext } from "../../../../../contexts";
import { ACTIONS } from "../../../../../store";
import { getItem } from "./utilities";
import classes from "./Cart.module.scss";

const Cart = observer(() => {
  const { t } = useTranslation();

  const { cart } = useContext(StoreContext);
  const hasError = cart[`error${ACTIONS.CART.GET}`];
  const isEmpty = cart.isEmpty;
  const isLoadingGet = cart[`isLoading${ACTIONS.CART.GET}`];
  const isLoadingDelete = cart[`isLoading${ACTIONS.CART.DELETE}`];
  const items = cart.items;

  useEffect(() => {
    cart.cartType = "packages";
    cart.get();

    return () => {
      cart.clear();
    };
  }, []);

  if (hasError) {
    return (
      <div className={classes.container}>
        <div className={cs(classes.wrapper, classes.message)}>{t("generals.warnings.1")}</div>
      </div>
    );
  }
  

  if (isLoadingGet) {
    return (
      <div className={classes.container}>
        <div className={cs(classes.wrapper)}>
          <Loader />
        </div>
      </div>
    );
  }

  if (isEmpty) {
    return (
      <div className={cs(classes.container, "mb-10")}>
        <div className={cs(classes.wrapper, classes.message)}>{t("pages.checkout.texts.1")}</div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <ul className={classes.list}>
          {items.map((item) => {
            const Item = getItem(item.product_code);

            const onDelete = ({ id, onSuccess }) => {
              cart.delete({ id, onSuccess });
            };

            return (
              <li className={classes.item} key={item.item_id}>
                <Item {...item} isLoadingDelete={isLoadingDelete} onDelete={onDelete} />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
});

export default Cart;
