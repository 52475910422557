import * as Hebcal from "hebcal";

const NUMBER_OF_DAYS_PER_WEEK = 7;
const MAX_NUMBER_OF_WEEKS_TO_SHOW = 6;
const MAX_NUMBER_OF_DAYS_TO_SHOW = NUMBER_OF_DAYS_PER_WEEK * MAX_NUMBER_OF_WEEKS_TO_SHOW;

const checkIsTheSameDate = (date1, date2) => {
  if (date1.getDate() !== date2.getDate()) return false;
  if (date1.getMonth() !== date2.getMonth()) return false;
  if (date1.getFullYear() !== date2.getFullYear()) return false;

  return true;
};

const getTheTotalNumberOfDaysInAMonth = (date) => {
  const d = new Date(date);
  d.setMonth(d.getMonth() + 1, 0);

  return d.getDate();
};

const getFirstDayOfTheWeekOfTheMonth = (currentDate) => {
  const date = new Date(currentDate);
  date.setDate(1);

  return date.getDay();
};

const getDays = (currentDate) => {
  const FIRST_DAY_OF_THE_WEEK_OF_THE_MONTH = getFirstDayOfTheWeekOfTheMonth(currentDate);
  const NUMBER_OF_DAYS_BEFORE = FIRST_DAY_OF_THE_WEEK_OF_THE_MONTH; /* Note: From 0 to 6 */
  const daysBefore = new Array(NUMBER_OF_DAYS_BEFORE)
    .fill({ disabled: true }) /* Note: Сan not be selected in the calendar */
    .map((item, index) => {
      const date = new Date(currentDate);
      date.setDate(index * -1); /* Note: Calculating days in reverse order from the first day of the month */

      return {
        ...item,
        id: date.getTime(),
        selected: false,
        title: date.getDate(),
        value: date,
      };
    })
    .reverse(); /* Note: Bringing the list of days to the traditional order */

  const NUMBER_OF_DAYS_IN_A_CURRENT_MONTH = getTheTotalNumberOfDaysInAMonth(currentDate);

  const availableDays = new Array(NUMBER_OF_DAYS_IN_A_CURRENT_MONTH)
    .fill({ disabled: false }) /* Note: Сan be selected in the calendar */
    .map((item, index) => {
      const date = new Date(currentDate);
      date.setDate(index + 1);
      const selected = checkIsTheSameDate(currentDate, date);

      return {
        ...item,
        id: date.getTime(),
        selected: selected,
        title: date.getDate(),
        value: date,
      };
    });

  const NUMBER_OF_DAYS_OF_AVAILABLE_DAYS_AND_DAYS_BEFORE = [...daysBefore, ...availableDays].length;
  const NUMBER_OF_DAYS_AFTER = MAX_NUMBER_OF_DAYS_TO_SHOW - NUMBER_OF_DAYS_OF_AVAILABLE_DAYS_AND_DAYS_BEFORE;
  const daysAfter = new Array(NUMBER_OF_DAYS_AFTER)
    .fill({ disabled: true }) /* Note: Сan not be selected in the calendar */
    .map((item, index) => {
      const date = new Date(currentDate);
      date.setMonth(date.getMonth() + 1, index + 1);

      return {
        ...item,
        id: date.getTime(),
        selected: false,
        title: date.getDate(),
        value: date,
      };
    });

  const days = [...daysBefore, ...availableDays, ...daysAfter];

  return days;
};

const getHebrewDays = (currentGregorianDate) => {
  const currentDate = new Hebcal.HDate(currentGregorianDate);
  const currentMonth = currentDate.getMonthObject();
  const previousMonth = currentMonth.prev();
  const nextMonth = currentMonth.next();

  const FIRST_DAY_OF_THE_WEEK_OF_THE_MONTH = currentDate.getDay();
  const NUMBER_OF_DAYS_BEFORE = FIRST_DAY_OF_THE_WEEK_OF_THE_MONTH;
  const daysBefore = previousMonth.days.slice(previousMonth.length - NUMBER_OF_DAYS_BEFORE).map((item) => {
    return {
      disabled: true,
      id: item.greg().getTime(),
      selected: false,
      title: item.getDate(),
      value: item.greg(),
      valueHebrew: item,
    };
  });

  const availableDays = currentMonth.days.map((item) => {
    const selected = item.isSameDate(currentDate);

    return {
      disabled: false,
      id: item.greg().getTime(),
      selected: selected,
      title: item.getDate(),
      value: item.greg(),
      valueHebrew: item,
    };
  });

  const NUMBER_OF_DAYS_OF_AVAILABLE_DAYS_AND_DAYS_BEFORE = daysBefore.length + availableDays.length;
  const NUMBER_OF_DAYS_AFTER = MAX_NUMBER_OF_DAYS_TO_SHOW - NUMBER_OF_DAYS_OF_AVAILABLE_DAYS_AND_DAYS_BEFORE;
  const daysAfter = nextMonth.days.slice(0, NUMBER_OF_DAYS_AFTER).map((item) => {
    return {
      disabled: true,
      id: item.greg().getTime(),
      selected: false,
      title: item.getDate(),
      value: item.greg(),
      valueHebrew: item,
    };
  });

  const days = [...daysBefore, ...availableDays, ...daysAfter];

  return days;
};

export { getDays, getHebrewDays };
