import { observer } from "mobx-react-lite";
import React from "react";
import cs from "classnames";
import classes from "./BusinessCard.module.scss";
import { Trans } from "react-i18next";
import { isMobile } from "react-device-detect";

const BusinessCard = observer(
  ({ card, index, handleBusinessCardClick, flippedBusinessCard }) => {
    // console.log(index, "BusinessCard")
    return (
      <div
        className={cs(
          classes.businessCard,
          !isMobile && classes.businessCardBrowser,
          {
            [classes.rotate]: flippedBusinessCard == index && isMobile,
          }
        )}
        onClick={() => isMobile && handleBusinessCardClick(index)}
      >
        <div className={cs(classes.businessCardInner)}>
          <div className={cs(classes.businessCardFront)}>
            <p className={cs(classes.cardTitle)}>{card.name}</p>
            <img
              className={cs(classes.businessCardImage)}
              src={card.logoSrc}
              alt="text"
            />
            <div className={cs(classes.offerCardFooter)}>
              <h4 className={cs(classes.businessCardOffer)}>{card.discount}</h4>
              <p className={cs(classes.cardExplaination)}>
                {card.discount_terms}
              </p>
            </div>
          </div>
          <div className={cs(classes.businessCardBack)}>
            <p className={cs(classes.cardInfo)}>
              <Trans components={{ br: <br /> }} i18nKey={card.description} />
            </p>
          </div>
        </div>
      </div>
    );
  }
);

export default BusinessCard;
