import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import { COLORS, SIZES } from "./utilities";
import classes from "./Button.module.scss";

const Button = ({
  children,
  color,
  size,
  stretched,
  borderRadius,
  borderRadiusPixel,
  ...properties
}) => (
  <button
    className={cs(
      classes.container,
      classes[color],
      classes[`borderRadius${borderRadiusPixel}`],
      classes[size],
      { [classes.stretched]: stretched },
      { [classes.borderRadius]: borderRadius }
    )}
    {...properties}
  >
    {children}
  </button>
);

Button.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(Object.values(COLORS)),
  size: PropTypes.oneOf(Object.values(SIZES)),
  stretched: PropTypes.bool,
  borderRadius: PropTypes.string,
  borderRadiusPixel: PropTypes.string,
};

Button.defaultProps = {
  color: COLORS.PRIMARY,
  size: SIZES.M,
  stretched: false,
};

export default Button;
