import React from "react";
// import React, { useCallback } from "react";
// import { useHistory } from "react-router-dom";
// import cs from "classnames";

// import Button from "../../atoms/Button";
import Logotype from "../../atoms/Logotype";
import PageTitle from "../../atoms/PageTitle";
// import { APPLICATION_ROUTES, PAGE_TITLES } from "../../../constants";
import { APPLICATION_ROUTES, PAGE_TITLES } from "../../../constants";
import { Link } from "react-router-dom";
import classes from "./QuestionnaireSuccess.module.scss";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { StoreContext } from "../../../contexts";
import cs from "classnames";
import NotificationButtons from "../../organisms/NotificationButtons/NotificationButtons";

const QuestionnaireSuccess = observer(() => {
  // const history = useHistory();
  const { authorization } = useContext(StoreContext);
  // const goToHomePage = useCallback(() => {
  //   history.push(APPLICATION_ROUTES.HOME);
  // }, []);
  // const goToMembershipPage = useCallback(() => {
  //   history.push(APPLICATION_ROUTES.PARTNERS_MAIN);
  // }, []);

  return (
    <>
      <PageTitle value={PAGE_TITLES.QUESTIONNAIRE} />
      <main className="bg-gold-gradient--horizontal c-grey-dark text-m">
        <div className="wrapper-vertical-s-a minh-100vh">
          <div className="d-flex fd-column ai-center">
            <Logotype size="l" />
          </div>
          <div className="mt-11 text-center">
            {!authorization.isAuthenticated ? (
              <h3 className={cs("c-blue-dark text-initial", classes.heading)}>Welcome!</h3>
            ) : (
              <h3 className={cs("c-blue-dark text-initial", classes.heading)}>
                Welcome {authorization.user.last_name} Family!
              </h3>
            )}
          </div>
          <div className={cs("ta-center", classes.innerHeading)}>
            <div className="mt-05">
              <p>We’re glad you’re here. Let’s get started!</p>
              <p>
                Go to{" "}
                <Link className={classes.link} to={APPLICATION_ROUTES.MY_MAOR_MAIN}>
                  MyMaor page
                </Link>{" "}
                or{" "}
                <Link className={classes.link} to={APPLICATION_ROUTES.LIBRARY}>
                  Explore Library
                </Link>
              </p>
            </div>

            <div className="mt-06 mb-06">
              <p className={classes.dots}>...</p>
            </div>


            <div>
              <p className="mb-02 fw-700">Never miss a video!</p>
              <p className="mb-06">Sign up to receive a notification when a new video is published.</p>
              <NotificationButtons />
            </div>
          </div>

          {/* <div className="mt-11">
            <h2 className="heading-l-a ta-center c-blue-dark fw-400 text-initial">
              {t("pages.authorization.questionnaire_success.title")}
            </h2>
            <h3 className="heading-l-a ta-center c-blue-dark text-initial">
              {t("pages.authorization.questionnaire_success.subtitle")}
            </h3>
            <p className="ta-center c-blue-dark text-initial textpara">We’re glad you’re here. Let’s get started! <a href="#" className="textorange">Explore Library</a></p>
          </div>
          <div className={cs(classes.row, "d-flex jc-center ai-center mt-08")}>
            <span>
              <Button onClick={goToMembershipPage}>{t("pages.authorization.questionnaire_success.labels.1")}</Button>
            </span>
            <span className={classes.button}>
              <Button color="ghost" onClick={goToHomePage}>
                {t("pages.authorization.questionnaire_success.labels.2")}
              </Button>
            </span>
          </div> */}
        </div>
      </main>
    </>
  );
});

export default React.memo(QuestionnaireSuccess);
