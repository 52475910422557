import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { StoreContext } from "../../../contexts";
import { useDoubleInput, useModal, useQuery } from "../../../hooks";
import { NAMES } from "../../../utilities/validation";

const useForm = () => {
  const { authorization } = useContext(StoreContext);

  const query = useQuery();
  const token = query.get("token");

  const { t } = useTranslation();
  const CONFIRM_PASSWORD = t("forms.labels.confirm_password");
  const PASSWORD = t("forms.labels.password");
  const AT_LEAST_SIX_SYMBOLS = t("forms.messages.at_least_number_symbols", { number: 6 });
  const THE_FIELD_DOES_NOT_MATCH = t("forms.messages.the_field_does_not_match");
  const THIS_FIELD_IS_REQUIRED = t("forms.messages.this_field_is_required");

  const modal = useModal();

  /* form */
  const password = useDoubleInput({
    first: "Original",
    second: "Confirm",
    labelOfFirst: PASSWORD,
    labelOfSecond: CONFIRM_PASSWORD,
    validatorsOfFirst: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      { name: NAMES.MIN_SIX_SYMBOLS, options: { message: AT_LEAST_SIX_SYMBOLS } },
    ],
    validatorsOfSecond: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      { name: NAMES.MIN_SIX_SYMBOLS, options: { message: AT_LEAST_SIX_SYMBOLS } },
      { name: NAMES.EQUAL, options: { message: THE_FIELD_DOES_NOT_MATCH } },
    ],
  });

  /* views */
  const isValid = !(password.errorsOfOriginal.length || password.errorsOfConfirm.length);

  /* handlers */
  const onSubmit = (event) => {
    event.preventDefault();

    const data = {
      password: password.valueOfOriginal,
      token: token,
      onSuccess: modal.onOpen,
    };

    authorization.updatePassword(data);
  };

  return {
    modal,
    isValid,
    password,
    onSubmit,
  };
};

export default useForm;
