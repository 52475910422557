import { useContext, useEffect } from "react";
import { StoreContext } from "../../../../../contexts";
import { ACTIONS } from "../../../../../store";

const useWinners = ({ memberLimit }) => {
  const { authorization } = useContext(StoreContext);
  const latestGoldPartners = authorization[`latestGoldPartners`];

  useEffect(() => {
    authorization[`${ACTIONS.AUTHORIZATION.GET_LATEST_GOLD_PARTNERS}`]({
      memberLimit,
    });
  }, [memberLimit]);

  return {
    latestGoldPartners,
  };
};

export default useWinners;
