import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import placeholder from "../../../assets/images/avatar-placeholder.png";
import { SIZES } from "./utilities";
import classes from "./Avatar.module.scss";

const Avatar = ({ image, size, ...properties }) => {
  return (
    <div
      style={{ backgroundImage: `url(${image})` }}
      className={cs(classes.container, classes[size])}
      {...properties}
    />
  );
};

Avatar.propTypes = {
  image: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
};

Avatar.defaultProps = {
  image: placeholder,
  size: SIZES.MEDIUM,
};

export default Avatar;
