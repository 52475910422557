import React, { useContext, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import cs from "classnames";

import Loader from "../../../../atoms/Loader";
import { StoreContext } from "../../../../../contexts";
import { ACTIONS } from "../../../../../store";
import { getItem } from "./utilities";
import classes from "./Cart.module.scss";
import RefreshPage from "../../../../atoms/RefreshPage";
import { useState } from "react";

const Cart = observer(() => {
  const { t } = useTranslation();

  const { cart, checkout } = useContext(StoreContext);
  let hasError = cart[`error${ACTIONS.CART.GET}`];
  const isEmpty = cart.isEmpty;
  const isLoadingGet = cart[`isLoading${ACTIONS.CART.GET}`];
  const isLoadingDelete = cart[`isLoading${ACTIONS.CART.DELETE}`];
  const [itemPayloadValues, setItemPayloadValues] = useState([]);

  useEffect(() => {
    cart.cartType = "birthday";
    cart.get();
    hasError = cart[`error${ACTIONS.CART.GET}`];
    return () => {
      cart.clear();
    };
  }, []);

  const handleItemPayloadValueChange = (item_id, value) => {
    setItemPayloadValues((prevItemPayloadValues) => ({
      ...prevItemPayloadValues,
      [item_id]: value,
    }));
  };

  const items = cart.items;

  // Initialize an array to store the matched objects
  const matchedObjects = [];

  if (checkout.orderDeatils) {
    // Iterate through the orderDetails array
    for (const orderDetail of checkout.orderDeatils) {
      const itemPayload = itemPayloadValues[orderDetail.item_id];

      // Check if there is a matching itemPayload for the orderDetail
      if (itemPayload) {
        const matchedObject = {
          video_id: itemPayload.video_id,
          order_detail_id: orderDetail.order_deatils_id,
        };

        matchedObjects.push(matchedObject);
      }
    }
  }

  console.log(matchedObjects, "orderDetails");
  checkout.setValue("videoDates", matchedObjects);

  if (hasError) {
    return (
      <div className={classes.container}>
        <div className={cs(classes.wrapper, classes.message)}>
          {t("generals.warnings.1")} <RefreshPage />
        </div>
      </div>
    );
  }

  if (isLoadingGet) {
    return (
      <div className={classes.container}>
        <div className={cs(classes.wrapper)}>
          <Loader />
        </div>
      </div>
    );
  }

  if (isEmpty) {
    return (
      <div className={classes.container}>
        <div className={cs(classes.wrapper, classes.message)}>
          {t("pages.checkout.texts.1")}
        </div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <ul className={classes.list}>
          {items.map((item) => {
            const Item = getItem(item.product_code);
            console.log("Item", item.product_code);
            if (Item == null) {
              return null;
            }
            const onDelete = ({ id, onSuccess }) => {
              cart.delete({ id, onSuccess });
            };

            return (
              <li className={classes.item} key={item.item_id}>
                <Item
                  {...item}
                  isLoadingDelete={isLoadingDelete}
                  onDelete={onDelete}
                  itemPayloadValue={itemPayloadValues[item.item_id] || null}
                  setItemPayloadValue={(value) =>
                    handleItemPayloadValueChange(item.item_id, value)
                  }
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
});

export default Cart;
