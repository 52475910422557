import { APPLICATION_ROUTES } from "../../../constants";

const DIRECTIONS = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};

let projectedPage = location.pathname.includes(
  APPLICATION_ROUTES.LIBRARY_PROECTED
);

const items = [
  {
    href: projectedPage
      ? APPLICATION_ROUTES.LIBRARY_PROECTED
      : APPLICATION_ROUTES.LIBRARY,
    title_translation_key: "layout.all",
  },
  {
    href: projectedPage
      ? APPLICATION_ROUTES.MY_MAOR_LIBRARY_PROTECTED
      : APPLICATION_ROUTES.MY_MAOR_LIBRARY,
    title_translation_key: "layout.mymaor",
  },
  {
    href: projectedPage
      ? APPLICATION_ROUTES.DAILY_REBBE_VIDEO_LIBRARY_PROTECTED
      : APPLICATION_ROUTES.DAILY_REBBE_VIDEO_LIBRARY,
    title_translation_key: "layout.daily_rebbe_video",
  },
  {
    href: projectedPage
      ? APPLICATION_ROUTES.ILLUMINATE_LIBRARY_PROTECTED
      : APPLICATION_ROUTES.ILLUMINATE_LIBRARY,
    title_translation_key: "layout.illuminate",
  },
];

export { DIRECTIONS, items };
