import { POSITIONS as POPOVER_POSITIONS } from "../../../organisms/Popover";

const ANGLES = {
  TOP_LEFT: "angleTopLeft",
  TOP_RIGHT: "angleTopRight",
  BOTTOM_RIGHT: "angleBottomRight",
  BOTTOM_LEFT: "angleBottomLeft",
  WITHOUT: "angleWithout",
};

const ATTRIBUTES = {
  TOOLTIP: "data-cr-tooltip",
};

const COLORS = {
  VIOLET: "violet",
  WHITE: "white",
};

const POSITIONS = POPOVER_POSITIONS;

const SIZES = {
  AUTO: "auto",
  MEDIUM: "m",
};

export { ANGLES, ATTRIBUTES, COLORS, POSITIONS, SIZES };
