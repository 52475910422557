import { useState } from "react";

const useToggle = (initialValue = '', callback) => {
    const [selectedValue, setSelectedValue] = useState(initialValue);

    const handleInputChange = (event) => {
        setSelectedValue(event.target.value);
        callback && callback({ selectedValue: event.target.value }); // Invoke the callback with selectedValue
    };

    return {
        selectedValue,
        handleInputChange,
    };
};

export default useToggle;
