import React from "react";
import PropTypes from "prop-types";
import "./VideoSlider.css";
import Slider from "../../organisms/Slider";
import { NAMES } from "../../atoms/Icon";

const VideoSlider = ({ children }) => {
  let sliderSettings = {
    dots: false,
    infinite: false,
    centerMode: false,
    autoPlay: true,
    speed: 500,
    slidesToShow: 4.8,
    slidesToScroll: 4,
    initialSlide: 0,
    arrows: true,
    // variableWidth: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <Slider
      {...sliderSettings}
      arrowname={NAMES.CarouselArrow}
      rotated={false}
      className="videolist-slider"
    >
      {children}
    </Slider>
  );
};

VideoSlider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default VideoSlider;
