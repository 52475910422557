import React from "react";
import classes from "./Switch.module.scss";
import cs from "classnames";
import PropTypes from "prop-types";

const Switch = ({ checked, handleToggle, design }) => {
  return (
    <div className={cs(classes[design])}>
      <input
        checked={checked}
        onChange={handleToggle}
        className={cs(classes.reactSwitchCheckbox)}
        id={`reactSwitchNew`}
        type="checkbox"
      />
      <label className={cs(classes.reactSwitchLabel, { [classes.checked]: checked })} htmlFor={`reactSwitchNew`}>
        <span className={cs(classes.reactSwitchButton)} />
      </label>
    </div>
  );
};

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  design: PropTypes.string,
};

Switch.defaultProps = {
  design: "default",
};

export default Switch;
