import { useCallback, useState } from "react";
import { createState, validate } from "./utilities";

const useSelectR = ({ label, options = [], validators = [], value = { label: "", value: "" } } = {}) => {
  const [state, setState] = useState(createState({ validators, value }));

  const onChange = useCallback(
    (value, touched = true) => {
      let optionValue = value.value;

      setState((state) => {
        const errors = validate(optionValue, state.validators);

        return {
          ...state,
          errors,
          touched: touched,
          value,
        };
      });
    },
    [setState]
  );

  const setValue = (value) => {
    setState((state) => {
      return {
        ...state,
        value,
      };
    });
  };

  const touch = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        touched: true,
      };
    });
  });

  return {
    error: state.touched && state.errors.length ? state.errors[0] : "",
    errors: state.errors,
    label: label,
    options,
    touched: state.touched,
    value: state.value,
    setValue,
    onChange,
    touch,
  };
};

export default useSelectR;
