import { makeAutoObservable } from "mobx";
import { PartnershipAPI } from "../../api";
import { ACTIONS } from "./utilities";

const partnershipAPI = new PartnershipAPI();

const Partnership = class {
  constructor({ store }) {
    const actions = Object.values(ACTIONS);

    for (let i = 0; i < actions.length; i++) {
      const ACTION = actions[i];

      this[`isLoading${ACTION}`] = false;
      this[`error${ACTION}`] = "";
    }

    this.store = store;
    this.details = null;
    this.businessPartners = null;
    makeAutoObservable(this);
  }

  setIsLoading(name, value) {
    this[`isLoading${name}`] = value;
  }

  setError(name, value) {
    this[`error${name}`] = value;
  }

  setValue(name, value) {
    this[name] = value;
  }

  get hasDetails() {
    return !!this.details;
  }

  get hasBusinessPartners() {
    return !!this.businessPartners;
  }

  get isExpired() {
    if (this.hasDetails) {
      return "expired" === this.details.status;
    }
    return false;
  }

  get isActive() {
    if (this.hasDetails) {
      return "active" === this.details.status;
    }
    return false;
  }

  getPartnership() {
    this.setIsLoading(ACTIONS.GET_PARTNERSHIP, true);
    partnershipAPI
      .get()
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then((data) => {
        this.setIsLoading(ACTIONS.GET_PARTNERSHIP, false);
        this.setValue("details", {
          id: data.id,
          status: data.status,
          plan: data.plan_name,
          price: data.plan_price,
          interval: data.recurring_interval,
          updated_at: data.updated_at,
          next_payment_date: data.next_payment_date,
          silverPlanPrice: data.silverPrice,
          silverCardPlanPrice: data.silverCardPrice,
          partnerPlanPrice: data.partnerPrice,
          expiration_date: data.expiration_date,
        });
      })
      .catch(({ message }) => {
        this.setValue("details", null);
        this.setError(ACTIONS.GET_PARTNERSHIP, message);
        this.setIsLoading(ACTIONS.GET_PARTNERSHIP, false);
      });
  }

  getAllBusinessPartners() {
    this.setIsLoading(ACTIONS.GET_ALL_BUSINESS_PARTNERS, true);
    partnershipAPI
      .getAllBusinessPartners()
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then((data) => {
        this.setIsLoading(ACTIONS.GET_ALL_BUSINESS_PARTNERS, false);
        this.setValue("businessPartners", data.data);
      })
      .catch(({ message }) => {
        this.setValue("businessPartners", null);
        this.setError(ACTIONS.GET_ALL_BUSINESS_PARTNERS, message);
        this.setIsLoading(ACTIONS.GET_ALL_BUSINESS_PARTNERS, false);
      });
  }

  turnOnAutoRenewal({ onSuccess }) {
    this.setIsLoading(ACTIONS.AUTO_RENEWAL_ON, true);
    partnershipAPI
      .turnOnAutoRenewal()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          onSuccess && onSuccess();
          return response.json();
        }
        throw new Error(response.status);
      })
      .then(() => {
        this.setIsLoading(ACTIONS.AUTO_RENEWAL_ON, false);
      })
      .catch(() => {
        this.setError(
          ACTIONS.AUTO_RENEWAL_ON,
          "There was an error in processing your request."
        );
        this.setIsLoading(ACTIONS.AUTO_RENEWAL_ON, false);
      });
  }
  upgrade(plan, billing_cycle, onSuccess) {
    this.setIsLoading(ACTIONS.UPGRADE, true);
    partnershipAPI
      .upgrade({ plan, id: this.details["id"], billing_cycle })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          onSuccess && onSuccess();
          return response.json();
        }
        throw new Error(response.status);
      })
      .then(() => {
        this.setIsLoading(ACTIONS.UPGRADE, false);
      })
      .catch(() => {
        this.setError(
          ACTIONS.UPGRADE,
          "There was an error in processing your request."
        );
        this.setIsLoading(ACTIONS.UPGRADE, false);
      });
  }

  cancel() {
    this.setIsLoading(ACTIONS.CANCEL, true);
    partnershipAPI
      .cancel({
        id: this.details["id"],
      })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        this.setIsLoading(ACTIONS.CANCEL, false);
        this.setValue("partnership", data);
        this.getPartnership();
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.CANCEL, message);
        this.setIsLoading(ACTIONS.CANCEL, false);
      });
  }

  getIsLoading(value) {
    return this[`isLoading${value}`];
  }
};

export default Partnership;
