/* eslint-disable no-useless-escape */
import NAMES from "./names";

const REGEX = {
  [NAMES.CARD_EXPIRATION_DATE]: /^$|^(0[1-9]|1[0-2])\/?([2-9][0-9])$/,
  [NAMES.CHARACTERS_DIGITS_SYMBOLS]: /^$|^[a-zA-Z0-9., -]*$/,
  [NAMES.DIGITS]: /^$|^[0-9]*$/,
  [NAMES.DIGITS_DOTS]: /^$|^[0-9.]*$/,
  [NAMES.EMAIL]: /^\s*$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(?=\s*$)/,
  [NAMES.HEBREW_LATIN]: /^$|^[a-z\u0590-\u05fe]+$/i,
  [NAMES.HEBREW_LATIN_DIGITS]: /^$|^[a-zA-Z\u0590-\u05fe0-9]+$/,
  [NAMES.HEBREW_LATIN_DIGITS_SYMBOLS]: /^$|^[a-zA-Z\u0590-\u05fe0-9., -:!]+$/,
  [NAMES.HEBREW_LATIN_SPACES]: /^$|^[a-zA-Z\u0590-\u05fe ]+$/,
  [NAMES.HEBREW_LATIN_SPACES_HYPHENS]: /^$|^[a-zA-Z\u0590-\u05fe- -]+$/,
  [NAMES.LATIN]: /^$|^[a-zA-Z]+$/,
  [NAMES.LATIN_DIGITS]: /^$|^[a-zA-Z0-9]+$/,
  [NAMES.MAX_10]: /^$|^.{0,10}$/,
  [NAMES.MAX_64]: /^$|^.{0,64}$/,
  [NAMES.MIN_FOUR_SYMBOLS]: /^$|^.{4,}$/,
  [NAMES.MIN_FIVE_MAX_FIFTEEN_SYMBOLS]: /^$|^.{5,15}$/,
  [NAMES.MIN_SIX_SYMBOLS]: /^$|^.{6,}$/,
  [NAMES.MIN_2]: /^$|^.{2,}$/,
  [NAMES.MIN_3]: /^$|^.{3,}$/,
  [NAMES.MIN_5]: /^$|^.{5,}$/,
  [NAMES.PHONE]: /^$|[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
  [NAMES.REQUIRED]: /([^\s])/,
};

export default REGEX;
