import React from "react";

import cs from "classnames";
import classes from "./VideosContainer.module.scss";
import { observer } from "mobx-react-lite";
import VideoList from "../../../../organisms/VideoList/VideoList";
import { isMobile } from "react-device-detect";
import { ACTIONS } from "../../../../../store";
import useMyMaor from "../../../MyMaorMain/useMyMaor";
import useLibrary from "../../../Library/useLibrary";
import { TYPES as LOGOTYPE_TYPES } from "../../../../atoms/Logotype";
import { APPLICATION_ROUTES, PROJECTS } from "../../../../../constants";
import Button from "../../../../atoms/Button";
import { scrollToTop } from "../../../../../utilities/helpers";
import { useHistory } from "react-router-dom";

const VideosContainer = observer(() => {
  const history = useHistory();
  const { search, sorting, holiday } = useMyMaor();

  

  const relatedVideos = useLibrary({
    ACTION: ACTIONS.LIBRARY.GET_DAILY_REBBE_VIDEO,
    NAME: "DailyRebbeVideo",
  });

  const navigateToMaorLibrary = () => {
    history.push(APPLICATION_ROUTES.DAILY_REBBE_VIDEO_LIBRARY);
    scrollToTop();
  };

  return (
    <div className={cs(classes.container)}>
      <section
        className={cs(
          classes.MoreVideosSec,
          isMobile ? classes.mobileVideos : ""
        )}
      >
        <div className={cs(classes.videosWrapper)}>
          <div className={cs(classes.centerContent)}>
            <h2 className="mb-06">Watch more</h2>
          </div>
          <div>
            <div className={cs(classes.MoreVideosWrap)}>
              <h3 className={" mb-03 fw-700"}>Related Videos</h3>
              <VideoList
                hasItems={relatedVideos.hasItems}
                hasNextPage={relatedVideos.hasNextPage}
                items={relatedVideos.items}
                totalItems={relatedVideos.totalItems}
                isLoading={relatedVideos.isLoading}
                logotype={LOGOTYPE_TYPES.MY_MAOR}
                onLoad={relatedVideos.onLoad}
                search={search.value}
                order={sorting.value}
                holidayId={holiday.value}
                setRequestPayload={relatedVideos.setRequestPayload}
                limitRecord={5}
                resetPage={relatedVideos.resetPage}
                title={PROJECTS.TITLES.MY_MAOR}
                projectType={PROJECTS.DAILY_REBBE_VIDEO.ID}
                freeAccess={true}
              />
            </div>

            <div className={cs(classes.MoreVideosWrap)}>
              <h3 className={" mb-03 fw-700"}>Rosh Hashana</h3>
              <VideoList
                hasItems={relatedVideos.hasItems}
                hasNextPage={relatedVideos.hasNextPage}
                items={relatedVideos.items}
                totalItems={relatedVideos.totalItems}
                isLoading={relatedVideos.isLoading}
                logotype={LOGOTYPE_TYPES.MY_MAOR}
                onLoad={relatedVideos.onLoad}
                search={search.value}
                order={sorting.value}
                holidayId={holiday.value}
                setRequestPayload={relatedVideos.setRequestPayload}
                limitRecord={5}
                resetPage={relatedVideos.resetPage}
                title={PROJECTS.TITLES.MY_MAOR}
                projectType={PROJECTS.DAILY_REBBE_VIDEO.ID}
                freeAccess={true}
              />
            </div>
          </div>

          <div className={cs(classes.btnContainer)}>
            <Button onClick={navigateToMaorLibrary}>Full Library</Button>
          </div>
        </div>
      </section>
    </div>
  );
});

export default React.memo(VideosContainer);
