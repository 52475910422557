import { makeAutoObservable } from "mobx";
import { ACTIONS } from "./utilities";
import { VideoDatesAPI } from "../../api";

const videoDatesApi = new VideoDatesAPI();

const VideoDates = class {
    constructor() {
        const actions = Object.values(ACTIONS)

        for (let i = 0; i < actions.length; i++) {
            const ACTION = actions[i];

            this[`isLoading${ACTION}`] = false;
            this[`error${ACTION}`] = "";
            this[`success${ACTION}`] = false;
        }

        this.videoDates = {};

        makeAutoObservable(this);
    }

    setIsLoading(name, value) {
        this[`isLoading${name}`] = value;
    }

    setError(name, value) {
        this[`error${name}`] = value;
    }

    setSuccess(name, value) {
        this[`success${name}`] = value;
    }

    setValue(name, value) {
        this[name] = value;
    }

    get hasData() {
        return !!this.videoDates; 
    }

    getVideoDates({ birthdayDate, item_id }) {
        this.setIsLoading(ACTIONS.GET_VIDEO_DATES, true);
        this.setSuccess(ACTIONS.GET_VIDEO_DATES, false);
        this.setError(ACTIONS.GET_VIDEO_DATES, false);
        console.log("API called");
        videoDatesApi.getVideoDates({ birthdayDate }).then((response) => {
            if (response.status === 200) return response.json();
            throw new Error(response.status);
        })
            .then((data) => {
                this.setIsLoading(ACTIONS.GET_VIDEO_DATES, false);
                // this.setValue("videoDates", data.data);
                this.videoDates[item_id] = data.data;
                console.log(data.data, "videoDates")
                this.setSuccess(ACTIONS.GET_VIDEO_DATES, true);
                console.log(this[`success${ACTIONS.GET_VIDEO_DATES}`], "loading");
            })
            .catch((error) => {
                this.setError(ACTIONS.GET_VIDEO_DATES, error.message);
                this.setIsLoading(ACTIONS.GET_VIDEO_DATES, false);
            });
    }

    getIsLoading(value) {
        return this[`isLoading${value}`];
    }

}

export default VideoDates;