import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { StoreContext } from "../../../contexts";
import { NAMES } from "../../../utilities/validation";
import { useCheckbox, useInput } from "../../../hooks";

const useForm = ({ onSuccess }) => {
  const { t } = useTranslation();
  const AT_LEAST_SIX_SYMBOLS = t("forms.messages.at_least_number_symbols", {
    number: 6,
  });
  const EMAIL = t("forms.labels.email");
  const PASSWORD = t("forms.labels.password");
  const THIS_EMAIL_IS_NOT_VALID = t("forms.messages.this_email_is_not_valid");
  const THIS_FIELD_IS_REQUIRED = t("forms.messages.this_field_is_required");

  const { authorization } = useContext(StoreContext);

  const login = useInput({
    label: EMAIL,
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      { name: NAMES.EMAIL, options: { message: THIS_EMAIL_IS_NOT_VALID } },
    ],
  });
  const password = useInput({
    label: PASSWORD,
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      {
        name: NAMES.MIN_SIX_SYMBOLS,
        options: { message: AT_LEAST_SIX_SYMBOLS },
      },
    ],
  });
  const remember = useCheckbox({ checked: true });

  const isNotValidLogin = !!login.errors.length;
  const isNotValidPassword = !!password.errors.length;
  const isNotValidForm = isNotValidLogin || isNotValidPassword;

  const onSubmit = (event) => {
    event.preventDefault();

    const data = {
      email: login.value,
      password: password.value,
      token: remember.checked,
      onSuccess: onSuccess,
    };

    authorization.signIn(data);
  };

  return {
    isNotValidForm,
    isNotValidLogin,
    isNotValidPassword,
    login,
    password,
    remember,
    onSubmit,
  };
};

export default useForm;
