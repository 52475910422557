import { makeAutoObservable } from "mobx";
import { PaymentMethodAPI } from "../../api";
import { ACTIONS } from "./utilities";

import dropin from "braintree-web-drop-in";
const paymentMethodAPI = new PaymentMethodAPI();

const PaymentMethod = class {
  constructor() {
    this.items = [];

    const actions = Object.values(ACTIONS);

    for (let i = 0; i < actions.length; i++) {
      const ACTION = actions[i];

      this[`error${ACTION}`] = "";
      this[`isLoading${ACTION}`] = false;
      this[`success${ACTION}`] = false;
    }

    this.loadingIds = [];
    this.totalItems = 0;
    this.nextPage = null;
    this.currentPage = 1;
    this.token = null;
    this.bInitialized = false;

    makeAutoObservable(this);
  }

  setLoadingId(id) {
    this.loadingIds.push(id);
  }

  removeLoadingId(id) {
    this.loadingIds = this.loadingIds.filter((item) => item != id);
  }

  get hasItems() {
    return !!this.items.length;
  }

  get hasNextPage() {
    return !!this.nextPage;
  }

  get isEmpty() {
    return !this.items.length;
  }

  get({ onSuccess = null, resetPage = false, refreshCurrentPage = false }) {
    this.setIsLoading(ACTIONS.GET_PM, true);

    if (resetPage) {
      this.setValue("nextPage", 1);
    }

    if (refreshCurrentPage) {
      this.setValue("nextPage", this.currentPage);
    }

    paymentMethodAPI
      .get({
        page: this.nextPage,
      })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        this.setValue("items", data);
        console.log("pm data", data);
        this.setIsLoading(ACTIONS.GET_PM, false);

        onSuccess && onSuccess();
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.GET_PM, message);
        this.setIsLoading(ACTIONS.GET_PM, false);
      });
  }

  getToken({ onSuccess = null }) {
    this.setIsLoading(ACTIONS.GET_TOKEN, true);
    this.setError(ACTIONS.GET_TOKEN, false);

    paymentMethodAPI
      .getToken({ gateway: "braintree" })
      .then((response) => {
        if (response.status == 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        this.setValue("token", data.token);
        this.setError(ACTIONS.GET_TOKEN, false);
        this.setIsLoading(ACTIONS.GET_TOKEN, false);
        onSuccess && onSuccess();
      })
      .catch(({ message }) => {
        this.setIsLoading(ACTIONS.GET_TOKEN, false);
        this.setError(ACTIONS.GET_TOKEN, message);
      });
  }

  initialize({ buttonSelector, containerSelector, locale, onSuccess }) {
    // this.setIsLoading(ACTIONS.INITIALIZE_PAYMENT_METHOD, true);
    const token = this.token;
    const container = document.querySelector(containerSelector);

    /* Note: clear up the DOM-node before Braintree SDK will use it */
    if (container) container.innerHTML = "";

    dropin
      .create({
        authorization: token,
        container: containerSelector,
        locale: locale,
        // paymentOptionPriority: ["card"],
        paypal: {
          flow: "vault",
        },
        preselectVaultedPaymentMethod: false,
      })
      .then((instance) => {
        const button = document.querySelector(buttonSelector);
        this.setValue("bInitialized", true);
        document.querySelector(
          "[data-braintree-id='choose-a-way-to-pay']"
        ).innerHTML = "Choose a Payment Method";
        document.querySelector(".braintree-sheet__text").innerHTML =
          "Card Information";
        button.addEventListener("click", () => {
          this.setIsLoading(ACTIONS.REQUEST_PAYLOAD_METHOD, true);

          instance
            .requestPaymentMethod()
            .then((payload) => {
              this.setValue("bInitialized", false);
              this.setIsLoading(ACTIONS.REQUEST_PAYLOAD_METHOD, false);
              console.log("Payload", payload);
              this.save({
                paymentmethod_type:
                  payload.type == "PayPalAccount" ? "paypal" : "card",
                nonce: payload.nonce,
                onSuccess: onSuccess,
              });
            })
            .catch(({ message }) => {
              this.setError(ACTIONS.REQUEST_PAYLOAD_METHOD, message);
              this.setIsLoading(ACTIONS.REQUEST_PAYLOAD_METHOD, false);
            });
        });

        // this.setIsLoading(ACTIONS.INITIALIZE_PAYMENT_METHOD, false);
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.INITIALIZE_PAYMENT_METHOD, message);
        // this.setIsLoading(ACTIONS.INITIALIZE_PAYMENT_METHOD, false);
      });
  }

  save({ nonce, make_default = true, paymentmethod_type, onSuccess }) {
    this.setIsLoading(ACTIONS.SAVE, true);
    this.setError(ACTIONS.SAVE, false);
    this.setSuccess(ACTIONS.SAVE, false);

    paymentMethodAPI
      .save({
        paymentmethod_type: paymentmethod_type,
        nonce: nonce,
        make_default: make_default,
      })
      .then(async (response) => {
        if (response.status === 200) return response.json();
        if (response.status == 422) {
          let data = await response.json();
          throw new Error(JSON.stringify(data));
        }
        throw new Error(response);
      })
      .then(() => {
        this.setError(ACTIONS.SAVE, false);
        this.setIsLoading(ACTIONS.SAVE, false);
        this.setSuccess(ACTIONS.SAVE, true);

        // this.makeDefault({ id: data.id });

        onSuccess && onSuccess();
      })
      .catch((error) => {
        try {
          let data = JSON.parse(error.message);
          this.setError(ACTIONS.SAVE, data);
          this.setIsLoading(ACTIONS.SAVE, false);
        } catch (e) {
          this.setError(
            ACTIONS.SAVE,
            "Something went wrong. Please try again."
          );
          this.setIsLoading(ACTIONS.SAVE, false);
        }
      });
  }

  makeDefault({ id, onSuccess = null }) {
    this.setLoadingId(id);

    paymentMethodAPI
      .makeDefault({
        id: id,
      })
      .then(async (response) => {
        if (response.status === 200) return response.json();
        throw new Error(response);
      })
      .then(() => {
        this.removeLoadingId(id);
        onSuccess && onSuccess();
      })
      .catch((error) => {
        this.setError(ACTIONS.DEFAULT_PM, error.message);
        this.setIsLoading(ACTIONS.DEFAULT_PM, false);
      });
  }

  delete({ id, onSuccess }) {
    this.setLoadingId(id);

    paymentMethodAPI
      .delete({ id: id })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response);
      })
      .then(() => {
        this.removeLoadingId(id);
        onSuccess && onSuccess();
      })
      .catch((error) => {
        this.setError(ACTIONS.DELETE, error.message);
        this.setIsLoading(ACTIONS.DELETE, false);
      });
  }

  setValue(name, value) {
    this[`${name}`] = value;
  }

  setIsLoading(name, value) {
    this[`isLoading${name}`] = value;
  }

  setError(name, value) {
    this[`error${name}`] = value;
  }

  getError(name) {
    return this[`error${name}`];
  }

  setSuccess(name, value) {
    this[`success${name}`] = value;
  }

  getSuccess(name) {
    return this[`success${name}`];
  }

  getIsLoading(name) {
    return this[`isLoading${name}`];
  }
};

export default PaymentMethod;
