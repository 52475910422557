import API from "./_api";
import { API_ROUTES } from "../constants";

const DonationAPI = class extends API {

    get() {
        return this.request(`${API_ROUTES.DONATION.GET}`, {
            method: "GET",
        })
    }

    cancel({ id }) {
        return this.request(`${API_ROUTES.DONATION.CANCEL}/${id}`, {
            method: "GET",
        })
    }


    getDonationPartners({ pledgeDonation }) {
        return this.request(API_ROUTES.DONATION.GET_DONATION_PARTNERS + "?for_pledge=" + pledgeDonation, {
            method: "GET",
        })
    }

    getAllSubscription() {
        return this.request(`${API_ROUTES.DONATION.GET_ALL_SUBSCRIPTION}`, {
            method: "GET",
        });
    }

}

export default DonationAPI; ` `