const STORAGE = {
  CART_ITEMS: "cart_items",
  PODCAST_EMBED_URL:
    "https://player.captivate.fm/show/8d0c0185-c914-485d-8509-e3a4d8cc315c",
  FEATURE_VIDEO: "https://vimeo.com/764281875",
  OUR_IMPACT: " https://vimeo.com/575115468",
  OUR_METHOD_TOP: "https://vimeo.com/user141029899/ourmethod1",
  OUR_METHOD_BOTTOM: "https://vimeo.com/user141029899/ourmethod",
  SKIPPED_VIDEO: 5,
  WHATSAPP_GROUP_LINK: "https://chat.whatsapp.com/EfMMQW2fbIe8CQo7DInvQV",
  WHATSAPP_GROUP_LINK_ILLUMINATE : "https://chat.whatsapp.com/HQ3hU82z2u7FQqKN23BF0d",
  WHATSAPP_GROUP_LINK_DRV : "https://chat.whatsapp.com/F12pJBx93B4ErUliUOcW3p",
};

export default STORAGE;
