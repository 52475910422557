import { useTranslation } from "react-i18next";
import { useCheckbox, useInput, useSelectR } from "../../../../hooks";
import { NAMES } from "../../../../utilities/validation";
import { getInitials } from "./utilities";
import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../../../contexts";

const useForm = () => {
  const { t } = useTranslation();
  const APARTMENT = t("forms.labels.apartment");
  const CITY = t("forms.labels.city");
  const COUNTRY = t("forms.labels.country");
  const STATE = t("forms.labels.state");
  const STREET = t("forms.labels.street");
  const ZIP = t("forms.labels.zip");

  const THIS_FIELD_IS_REQUIRED = t("forms.messages.this_field_is_required");
  const THE_FIELD_MUST_CONTAIN_AT_LEAST_5_SYMBOLS = t(
    "forms.messages.the_field_must_contain_at_least_number_symbols",
    {
      number: 5,
    }
  );
  const THE_FIELD_MUST_CONTAIN_AT_LEAST_2_SYMBOLS = t(
    "forms.messages.the_field_must_contain_at_least_number_symbols",
    {
      number: 2,
    }
  );
  const THE_FIELD_MUST_CONTAIN_NO_MORE_THAN_10_SYMBOLS = t(
    "forms.messages.the_field_must_contain_no_more_than_number_symbols",
    { number: 10 }
  );
  const ADDRESS_ID = t("forms.labels.address_id");

  const { authorization } = useContext(StoreContext);
  const initials = getInitials({ id: null, items: [] });
  const [addAddress, setAddAddress] = useState(false);
  const [location, setLocation] = useState({
    street: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
  });
  const [deleteAddressSuccess, setDeleteAddressSuccess] = useState(false);
  
  useEffect(() => {
    deliveryStreet.onChange({
      target: { value: location.street },
    });
    deliveryCity.onChange({
      target: { value: location.city },
    });
    deliveryState.onChange({
      target: { value: location.state },
    });
    if (initials.delivery.country.value == "") {
      deliveryCountry.onChange(location.country);
    }
    deliveryZip.onChange({
      target: { value: location.postalCode },
    });
  }, [location]);

  /* delivery details */
  const deliveryApartment = useInput({
    label: APARTMENT,
    validators: [
      // {
      //   name: NAMES.HEBREW_LATIN_DIGITS_SYMBOLS,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_NUMBERS_SPACES_AND_SYMBOLS,
      //   },
      // },
    ],
    value: initials.delivery.apartment,
  });
  const deliveryCity = useInput({
    label: CITY + " *",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      // {
      //   name: NAMES.HEBREW_LATIN_SPACES,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_AND_SPACES,
      //   },
      // },
    ],
    value: initials.delivery.city,
  });

  const deliveryCountry = useSelectR({
    label: COUNTRY + " *",
    options: [
      { label: "USA", value: "USA" },
      // { label: "Canada", value: "Canada" },
    ],
    value: initials.delivery.country || { label: "USA", value: "USA" },
  });

  const deliveryState = useInput({
    label: STATE + " *",
    validators: [
      // {
      //   name: NAMES.HEBREW_LATIN,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS,
      //   },
      // },
      {
        name: NAMES.REQUIRED,
        options: { message: THE_FIELD_MUST_CONTAIN_AT_LEAST_2_SYMBOLS },
      },
    ],
    value: initials.delivery.state,
  });

  const deliveryStreet = useInput({
    label: STREET + " *",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      // {
      //   name: NAMES.HEBREW_LATIN_DIGITS_SYMBOLS,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_NUMBERS_SPACES_AND_SYMBOLS,
      //   },
      // },
    ],
    value: initials.delivery.street,
  });
  const deliveryZip = useInput({
    label: ZIP + " *",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      // {
      //   name: NAMES.LATIN_DIGITS,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_CHARACTERS_AND_DIGITS,
      //   },
      // },
      {
        name: NAMES.MIN_5,
        options: { message: THE_FIELD_MUST_CONTAIN_AT_LEAST_5_SYMBOLS },
      },
      {
        name: NAMES.MAX_10,
        options: { message: THE_FIELD_MUST_CONTAIN_NO_MORE_THAN_10_SYMBOLS },
      },
    ],
    value: initials.delivery.zip_code,
  });

  const defaultAddress = useCheckbox({
    checked: initials.delivery.is_default,
    label: `Make this default`,
    value: initials.delivery.is_default ? 1 : 0,
  });

  const address_id = useInput({
    label: ADDRESS_ID,
    value: "",
  });

  let isNotValidDeliveryDetails = false;
  isNotValidDeliveryDetails = !!(
    deliveryStreet.errors.length ||
    deliveryCity.errors.length ||
    deliveryState.errors.length ||
    deliveryZip.errors.length
  );

  const triggerValidationsDelivery = () => {
    deliveryState.touch();
    deliveryStreet.touch();
    deliveryCity.touch();
    deliveryZip.touch();
  };

  const onSubmit = ({ callback, isDefault = null }) => {
    
    const data = {
      client_id: authorization.user.id,
      apartment: deliveryApartment.errors.length ? "" : deliveryApartment.value,
      city: deliveryCity.value,
      country: deliveryCountry.value.value,
      state: deliveryState.value,
      street: deliveryStreet.value,
      zip_code: deliveryZip.value,
      id: address_id.errors.length ? "" : address_id.value,
      is_default: isDefault == null ? defaultAddress.checked ? "1" : "0" : isDefault,
    };
    
    if (!data.apartment) delete data.apartment;
    if (!data.is_default) delete data.is_default;
    if (!data.id) delete data.id;

    if (data.id) {
      authorization.editAddress({ data, onSuccess: callback });
      return;
    }

    authorization.addAddress({ data, onSuccess: callback });
  };

  return {
    isNotValidDeliveryDetails,
    address_id,
    deliveryApartment,
    deliveryCity,
    deliveryCountry,
    deliveryState,
    deliveryStreet,
    deliveryZip,
    defaultAddress,
    triggerValidationsDelivery: triggerValidationsDelivery,
    onSubmit: onSubmit,
    setAddAddress: setAddAddress,
    addAddress: addAddress,
    location: location,
    setLocation: setLocation,
    deleteAddressSuccess,
    setDeleteAddressSuccess,
  };
};

export default useForm;
