import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "../../../../atoms/Button";
import Checkbox from "../../../../atoms/Checkbox";
import OptionalInput from "../../../../atoms/OptionalInput";

const Goal = ({
  goalChassidisheEntertainment,
  goalFreeResource,
  goalOther,
  goalOtherDetails,
  goalSeeRebbe,
  goalUnderstandRebbe,
  isValid,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const LABEL_SUBMIT = t("pages.authorization.questionnaire_main.labels.submit_1");
  const TITLE = t("pages.authorization.questionnaire_main.questions.1");

  return (
    <div>
      <h3 className="heading-m ta-center c-blue-dark">{TITLE}</h3>
      <form
        autoComplete="off"
        className="mt-07 mr-auto ml-auto w-100p"
        style={{ maxWidth: "480px" }}
        onSubmit={onSubmit}
      >
        <div>
          <Checkbox checked={goalSeeRebbe.checked} onChange={goalSeeRebbe.onChange}>
            {goalSeeRebbe.label}
          </Checkbox>
        </div>
        <div className="mt-06">
          <Checkbox checked={goalUnderstandRebbe.checked} onChange={goalUnderstandRebbe.onChange}>
            {goalUnderstandRebbe.label}
          </Checkbox>
        </div>
        <div className="mt-06">
          <Checkbox checked={goalChassidisheEntertainment.checked} onChange={goalChassidisheEntertainment.onChange}>
            {goalChassidisheEntertainment.label}
          </Checkbox>
        </div>
        <div className="mt-06">
          <Checkbox checked={goalFreeResource.checked} onChange={goalFreeResource.onChange}>
            {goalFreeResource.label}
          </Checkbox>
        </div>
        <div className="mt-06">
          <Checkbox checked={goalOther.checked} onChange={goalOther.onChange}>
            {goalOther.label}
          </Checkbox>
        </div>
        {goalOther.checked && (
          <div className="mt-01 ml-09">
            <OptionalInput
              id="questionnaire-form-goal-step-other-details"
              value={goalOtherDetails.value}
              onChange={goalOtherDetails.onChange}
            />
          </div>
        )}
        <div className="d-flex fd-column ai-center mt-08">
          <Button disabled={!isValid} type="submit">
            {LABEL_SUBMIT}
          </Button>
        </div>
      </form>
    </div>
  );
};

const checkboxPropTypes = PropTypes.shape({
  checked: PropTypes.bool.isRequired,
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
}).isRequired;
const inputPropTypes = PropTypes.shape({
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
});

Goal.propTypes = {
  goalChassidisheEntertainment: checkboxPropTypes,
  goalFreeResource: checkboxPropTypes,
  goalOther: checkboxPropTypes,
  goalOtherDetails: inputPropTypes,
  goalSeeRebbe: checkboxPropTypes,
  goalUnderstandRebbe: checkboxPropTypes,
  isValid: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Goal;
