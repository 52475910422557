const getTranslationKeyOfError = (value) => {
  switch (value) {
    case "404":
      return "pages.authorization.password_recovery_request.texts.1";
    case "422":
      return "pages.authorization.password_recovery_request.texts.2";
    default:
      return "generals.warnings.1";
  }
};

export { getTranslationKeyOfError };
