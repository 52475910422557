import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import cs from "classnames";
import classes from "./MaorCredit.module.scss";
import maorCreditLogo from "../../../assets/images/maor-credit.png";
import { StoreContext } from "../../../contexts";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { APPLICATION_ROUTES } from "../../../constants";

const MaorCredit = observer(() => {
  const { authorization } = useContext(StoreContext);
  const history = useHistory();

  return (
    <>
      {authorization.isAuthenticated ? (
        <>
          <p
            className={cs(classes.creditInfo)}
            onClick={() => {
              history.push(APPLICATION_ROUTES.BIRTHDAY_PACKAGES_PAGE);
            }}
          >
            You have{" "}
            <span className={cs(classes.maorbold)}>
              ${authorization.user.maor_credit}
            </span>{" "}
            <br />
            <span className={cs(classes.creditSpan)}>
              {" "}
              <img
                src={maorCreditLogo}
                alt="maorCreditLogo"
                className={cs(classes.maorCreditLogo)}
              />{" "}
              available{" "}
            </span>
          </p>
        </>
      ) : (
        ""
      )}
    </>
  );
});

export default MaorCredit;
