import API from "./_api";
import { API_ROUTES } from "../constants";

const MailingListAPI = class extends API {
  get() {
    return this.request(`${API_ROUTES.MAILINGLIST.GET}`, {
      method: "GET",
    });
  }

  save({ first_name, last_name, email, mailing_list_id }) {
    return this.request(`${API_ROUTES.MAILINGLIST.SAVE}`, {
      method: "POST",
      body: {
        first_name: first_name,
        last_name: last_name,
        email: email,
        mailing_list_id: mailing_list_id,
      },
    });
  }

  delete({ id }) {
    return this.request(`${API_ROUTES.MAILINGLIST.DELETE}`, {
      method: "POST",
      body: {
        id: id,
      },
    });
  }
};

export default MailingListAPI;
