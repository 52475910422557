import { makeAutoObservable } from "mobx";
import PersonsAPI from "../../api/persons";
import { ACTIONS } from "./utilities";

const personsAPI = new PersonsAPI();

const Persons = class {
  constructor({ store }) {
    const actions = Object.values(ACTIONS);

    for (let i = 0; i < actions.length; i++) {
      const ACTION = actions[i];

      this[`isLoading${ACTION}`] = false;
      this[`error${ACTION}`] = "";
      this[`success${ACTION}`] = false;
    }

    this.store = store;

    makeAutoObservable(this);
  }

  setIsLoading(name, value) {
    this[`isLoading${name}`] = value;
  }

  setError(name, value) {
    this[`error${name}`] = value;
  }

  setSuccess(name, value) {
    this[`success${name}`] = value;
  }

  setValue(name, value) {
    this[name] = value;
  }

  addChild(data) {
    this.setIsLoading(ACTIONS.ADD_CHILD, true);
    this.setSuccess(ACTIONS.ADD_CHILD, false);
    this.setError(ACTIONS.ADD_CHILD, false);
    console.log("API called");
    personsAPI
      .addChild(data)
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(() => {
        console.log("Child added", this[`success${ACTIONS.ADD_CHILD}`]);
        this.setIsLoading(ACTIONS.ADD_CHILD, false);
        this.setSuccess(ACTIONS.ADD_CHILD, true);
        console.log(this[`success${ACTIONS.ADD_CHILD}`]);
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.ADD_CHILD, message);
        this.setIsLoading(ACTIONS.ADD_CHILD, false);
      });
  }

  deleteChild(personId) {
    this.setIsLoading(ACTIONS.DELETE_CHILD, true);
    this.setSuccess(ACTIONS.DELETE_CHILD, false);
    this.setError(ACTIONS.DELETE_CHILD, false);
    console.log("API called");
    personsAPI
      .deleteChild(personId)
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(() => {
        console.log("Child added", this[`success${ACTIONS.DELETE_CHILD}`]);
        this.setIsLoading(ACTIONS.DELETE_CHILD, false);
        this.setSuccess(ACTIONS.DELETE_CHILD, true);
        console.log(this[`success${ACTIONS.DELETE_CHILD}`]);
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.DELETE_CHILD, message);
        this.setIsLoading(ACTIONS.DELETE_CHILD, false);
      });
  }
};

export default Persons;
