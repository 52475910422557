/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import Icon from "../../atoms/Icon";
import Popover, { usePopover, POSITIONS, WIDTH } from "../../organisms/Popover";
import { ATTRIBUTES, COLORS, SIZES, createID } from "./utilities";
import classes from "./Select.module.scss";

const Select = ({
  color,
  options,
  label,
  size,
  stretched,
  value,
  onChange,
  useObject,
  error,
  onBlur: onBlurCustom,
  onFocus: onFocusCustom,
}) => {
  const [id] = useState(createID());
  const elementRef = useRef(null);

  const select = usePopover({
    position: POSITIONS.CENTER_BOTTOM_OUT,
    selector: `[${ATTRIBUTES.SELECT}="${id}"]`,
    width: WIDTH.TARGET,
  });

  let optionLabelValue = value;

  if (useObject) {
    let option = options.find((option) => option.value == value);
    optionLabelValue = option ? option.label : "";
  }

  const [optionLabel, setOptionlabel] = useState(optionLabelValue);

  const onSelect = useCallback((event) => {
    select.close();
    setOptionlabel(event.target.innerHTML);
    if (event.target.matches(`[data-name="select-button"]`)) onChange(event);

    onBlur();
  }, []);

  const [isFocused, setIsFocused] = useState(false);

  const onBlur = () => {
    setIsFocused(false);
    onBlurCustom && onBlurCustom();
  };
  const onFocus = () => {
    setIsFocused(true);
    onFocusCustom && onFocusCustom();
  };

  return (
    <>
      <div
        className={cs(classes.container, classes[color], classes[size], { [classes.stretched]: stretched })}
        data-cr-select={id}
      >
        <button
          type="button"
          className={cs(classes.target, { [classes.opened]: select.style })}
          onClick={() => {
            select.open();

            onFocus();
            setTimeout(() => {
              try {
                let popupDiv = document.querySelector(`#popup-${id}`);
                let size = popupDiv.getClientRects()[0].y + popupDiv.getClientRects()[0].height;
                if (size > window.innerHeight) {
                  let top = size - window.innerHeight;
                  top += 20;
                  window.scroll({
                    top: top,
                  });
                  let parentElement = popupDiv.parentElement;
                  let topValue = parseInt(window.getComputedStyle(parentElement, null).getPropertyValue("top"));
                  topValue -= top;
                  parentElement.style.top = topValue + "px";
                }
              } catch (e) {
                console.error(e);
              }
            }, 100);
          }}
        >
          <div className={cs(classes.value, { [classes.selected]: value })}>{optionLabel}</div>
          <div className={cs(classes.icon, { [classes.rotated]: select.style })}>
            <Icon name="arrow-dropdown" size="s" />
          </div>
        </button>
        {!isFocused && error && <div className={cs(classes.tooltip, classes.errorText)}>{error}</div>}
      </div>
      <Popover
        close={() => {
          select.close();
          onBlur();
        }}
        style={select.style}
      >
        <ul ref={elementRef} id={`popup-${id}`} className={cs(classes.list, classes[color], classes[size])}>
          {options.map((item) => {
            let option = {
              label: "",
              value: "",
            };

            if (useObject) {
              option.label = item.label;
              option.value = item.value;
            } else {
              option.label = item;
              option.value = item;
            }

            return (
              <li key={option.label} className={cs(classes.item, { [classes.selected]: option.value == value })}>
                <button
                  className={classes.button}
                  data-name="select-button"
                  data-value={option.value}
                  value={option.value}
                  onClick={onSelect}
                >
                  {option.label}
                </button>
              </li>
            );
          })}
        </ul>
      </Popover>
    </>
  );
};

Select.propTypes = {
  color: PropTypes.oneOf(Object.values(COLORS)),
  options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  label: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
  stretched: PropTypes.bool,
  value: PropTypes.string,
  useObject: PropTypes.bool,
};

Select.defaultProps = {
  color: COLORS.PRIMARY,
  label: "Select",
  size: SIZES.MEDIUM,
  stretched: false,
  value: "",
  useObject: false,
};

export default Select;
