import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

import { PAGE_TITLES } from "../../../constants";
import { getTranslationKey } from "./utilities";

const PageTitle = ({ value }) => {
  const translation_key = getTranslationKey(value);
  const { t } = useTranslation();
  const MAOR = t("layout.maor");
  const TITLE = t(translation_key);

  return (
    <Helmet>
      <title>
        {MAOR} | {TITLE}
      </title>
    </Helmet>
  );
};

PageTitle.propTypes = {
  value: PropTypes.oneOf(Object.values(PAGE_TITLES)),
};

PageTitle.defaultProps = {
  value: "",
};

export default PageTitle;
