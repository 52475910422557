/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";

import PageTitle from "../../atoms/PageTitle";
import Layout from "../../templates/Layout";
import { APPLICATION_ROUTES, PAGE_TITLES, PROJECTS } from "../../../constants";
import AsyncAutocompleteSearch from "../../atoms/AsyncAutocompleteSearch";
import SearchBar from "../../atoms/SearchBar";

import Icon from "../../atoms/Icon";
import classes from "./MyMaor.module.scss";

import cs from "classnames";
import useMyMaor from "./useMyMaor";
import { observer } from "mobx-react-lite";
import useLibraryFilter from "../Library/useLibraryFilter";
import BirthdayPackages from "../../organisms/BirthdayPackages";
import { NavLink, useHistory, useParams } from "react-router-dom";
import useLibrary from "../Library/useLibrary";
import { ACTIONS } from "../../../store";
import { TYPES as LOGOTYPE_TYPES } from "../../atoms/Logotype";
import VideoList from "../../organisms/VideoList/VideoList";
import Slider from "../../organisms/Slider";
import gift from "../../../assets/svg/birthdayGift.svg";
import ballon from "../../../assets/svg/ballons.svg";
import { useModal, useQuery } from "../../../hooks";
import headphone from "../../../assets/images/headphones.png";
import tv from "../../../assets/images/tv.png";

import Preview from "./components/Preview";
import useVideo from "../Library/components/Project/useVideo";
import placeholder from "../../../assets/images/video-thumbnail-placeholder.png";
import { getDetails } from "../Library/components/Project/components/Preview/utilities";
import Modal from "../../organisms/Modal";
import PlaylistModal from "../../organisms/Playlist/PlaylistModal";
import Button, { COLORS, SIZES } from "../../atoms/Button";
import { useContext } from "react";
import { StoreContext } from "../../../contexts";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import Loader from "../../atoms/Loader";
import Winners from "../Library/components/Winners";
import { Element, scroller } from "react-scroll";

import { getURL } from "../Library/components/Project/components/Preview/utilities";
import {
  Authorization,
  AuthorizationCommon,
  AuthorizationKids,
  EmptyPlaylist,
} from "../../organisms/RestrictionModal/RestrictionModal";
import AudioPlayer from "../../templates/AudioPlayer";
import { Decoration, features } from "../MyMaorPackages/utilities";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { Link } from "react-scroll";
import { scrollToTop } from "../../../utilities/helpers";
import MailingModal from "../../organisms/MailingModal/MailingModal";
import { STORAGE } from "../../../constants";
import NotificationButtons from "../../organisms/NotificationButtons/NotificationButtons";
import videoError from "../../../assets/images/video-error.png";
import videoData from "../../../video.json";
import { Helmet } from "react-helmet-async";
import { number } from "prop-types";
import PageMetaTags from "../../atoms/PageMetaTags";
import MaorButton from "./components/MaorButton";
import Ad from "../../organisms/Ad";
import Winner from "../../organisms/Winner";
import MyPlaylist from "../../atoms/MyPlaylist";
import ProjectList from "../../organisms/ProjectList";
import ColoringProjectList from "../../organisms/ColoringProjectList";
import VideoButtons from "./components/VideoButtons";
import AudioList from "../../organisms/AudioList/AudioList";
import LibraryFilterClear from "../../molecules/LibraryFilterClear";
import Project from "../../organisms/Project/Project";

const themeColors = [
  "orange",
  "blue",
  "green",
  "pink",
  "yellow",
  "orange",
  "purple",
];

const MyMaor = observer(() => {
  const {
    // search,
    searching,
    sorting,
    holiday,
    video,
    coloringPages,
    onNavigateToSearch,
    setVideoRequest,
  } = useMyMaor();
  const { filterData, maorType, projectType, appliedFilters, search } =
    useLibraryFilter();
  const query = useQuery();
  const history = useHistory();
  const videoId = query.get("video");
  const birthdaySection = query.get("birthday");
  const videoParams = useParams();
  const maorRef = useRef(null);
  let libraryUrl = new URL(location.href);

  const { authorization, playlist, partnership, library, common } =
    useContext(StoreContext);

  const [slider, setSlider] = useState(null);
  const sliderRef = {
    instance: slider,
    set: setSlider,
  };

  const videoAction = useVideo();
  const playlistVideoAction = useVideo();
  const { t } = useTranslation();

  const [currentAudio, setCurrentAudio] = useState({});

  const audioHandler = {
    currentAudio: currentAudio,
    setCurrentAudio: setCurrentAudio,
  };
  const [videoIdMatch, setVideoIdMatch] = useState(false);
  const [isVideoNotFree, setIsVideoNotFree] = useState(false);
  const [baseVideoCode, setBaseVideoCode] = useState(false);
  const [isVideoFreeWithoutSignIn, setIsVideoFreeWithoutSignIn] =
    useState(false);

  const isBase64 = (str) => {
    try {
      const decodedStr = atob(str);
      const lowerDecodedStr = decodedStr.toLowerCase();
      return ["k", "d", "i"].some((prefix) =>
        lowerDecodedStr.startsWith(prefix)
      );
    } catch (err) {
      return false;
    }
  };

  const toggleAudio = () => {
    if (audioHandler.currentAudio.musicSrc) {
      audioHandler.setCurrentAudio({
        musicSrc: "",
      });
    } else {
      audioHandler.setCurrentAudio({
        musicSrc: "d",
        showAudioList: true,
      });
    }
  };

  const openWhatsapp = () => {
    window.open(STORAGE.WHATSAPP_GROUP_LINK, "_blank");
  };

  useEffect(() => {
    if (libraryUrl.searchParams.get("keyword")) {
      onScrollToMyMaorSection();
    }

    if (isBase64(videoParams.id)) {
      setIsVideoNotFree(false);
      setBaseVideoCode(true);
      setIsVideoFreeWithoutSignIn(true);
      videoParams.id = atob(videoParams.id);
    }

    filterData.holidaysToday.onLoad();

    //To check if the video-id params has any id then send the id-value to the API as payload
    if (videoParams != null) {
      setVideoRequest(videoParams);
    } else {
      setVideoRequest({});
    }

    // video.onLoad({ projectType: "MyMaor" });
    video.onLoad();

    if (birthdaySection != null) {
      scroller.scrollTo("packages", {
        duration: 500,
        smooth: true,
        offset: -24,
      });
    }
  }, []);

  useEffect(() => {
    if (libraryUrl.searchParams.get("keyword")) {
      onScrollToMyMaorSection();
    }
  }, [libraryUrl.searchParams.get("keyword")]);

  let videoInfo = video.record;
  let details;
  let category;

  if (videoInfo != null) {
    if (videoInfo.id) {
      details = getDetails("en", videoInfo.locales);
      category = videoInfo.categories[0] ?? null;
    }
  }

  let allowedVideos = [
    "k971",
    "k882",
    "k393",
    "k668",
    "k780",
    "k849",
    "k811",
    "k835",
    "k771",
    "k745",
    "k1000",
  ];
  //To check is the video is free to to watch or user need to subscribe

  // To check if the video-params-id has correct id-value in video.json if yes then check match the video-id number within json file and change the description and title of the page
  let videoNumber = videoParams.id;
  let videoTitle = "";
  let videoDescription = "";

  useEffect(() => {
    if (videoInfo != null) {
      if (isBase64(videoParams.id) || baseVideoCode) {
        setIsVideoNotFree(false);
        setIsVideoFreeWithoutSignIn(true);
      } else if (videoInfo.free == false) {
        setIsVideoNotFree(true);
      }
      if (videoNumber != undefined && allowedVideos.includes(videoNumber)) {
        setIsVideoNotFree(false);
      }
    }
  }, [videoInfo, videoNumber]);

  const keys = Object.keys(videoData);

  if (videoNumber != undefined) {
    let videoNumberLowerCase = videoNumber.toLowerCase();
    useEffect(() => {
      for (const key of keys) {
        if (key === videoNumberLowerCase) {
          setVideoIdMatch(true);
        }
      }
    }, []);

    // check match the video-id number within json file and change the description and title of the page
    if (videoIdMatch) {
      if (videoNumber != undefined && videoData) {
        videoTitle = videoData[videoNumberLowerCase].title;
        videoDescription = videoData[videoNumberLowerCase].description;
      }
    }
  }

  //Navigation functions
  const onNavigateToPartnersMain = useCallback(() => {
    history.push(APPLICATION_ROUTES.PARTNERS_PACKAGES);
  }, []);

  const onNavigateToLibrary = () => {
    history.push(APPLICATION_ROUTES.LIBRARY);
    scrollToTop();
  };

  const onScrollToPackageSection = useCallback(() => {
    scroller.scrollTo("packages", { duration: 500, smooth: true, offset: -24 });
  }, [scroller]);

  const onScrollToMyMaorSection = () => {
    // scroller.scrollTo("myMaor", { duration: 500, smooth: true, offset: -24 });
    document.querySelector("#myMaor").scrollIntoView({ behavior: "smooth" });
  };

  const relatedVideos = useLibrary({
    ACTION: ACTIONS.LIBRARY.GET_RELATED,
    NAME: "Related",
  });
  const upcomingHolidayVideos = useLibrary({
    ACTION: ACTIONS.LIBRARY.GET_UPCOMING_HOLIDAY,
    NAME: "UpcomingHoliday",
  });

  let sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const popup = useModal();
  const popupKids = useModal();
  const playlistEmptyModal = useModal();
  const videoNotificationPopup = useModal();

  const handlePlaylist = () => {
    try {
      document.querySelector(".destroy-btn").click();
    } catch (e) {
      console.log(e);
    }

    if (authorization.isAuthenticated) {
      if (playlist.totalItems == 0) {
        playlistEmptyModal.onOpen();
      } else {
        if (authorization.isUserMember) {
          let playlistItem = playlist.items[0];
          playlist.setCurrentPlayingVideoId(playlistItem.video.id);
          playlistVideoAction.onOpen({
            end: playlistItem.video.end,
            start: playlistItem.video.start,
            url: getURL(playlistItem.video.url, {
              start: playlistItem.video.start,
            }),
            name: playlistItem.video.name,
            description: playlistItem.video.description,
            number: playlistItem.video.number,
            date: playlistItem.video.created_at,
            categories: playlistItem.video.video_directory.video_categories,
            tags: playlistItem.video.video_directory.video_tags,
            holiday: playlistItem.video.video_directory.holiday,
            file: playlistItem.video.video_directory.file,
            questions: playlistItem.video.video_questions,
            internal_id: playlistItem.video.video_directory.internal_id,
          });
        } else {
          popup.onOpen();
        }
      }
    } else {
      popup.onOpen();
    }
  };

  const myMaor = useLibrary({
    ACTION: ACTIONS.LIBRARY.GET_MY_MAOR,
    NAME: "MyMaor",
  });

  const myMaorCombined = useLibrary({
    ACTION: ACTIONS.LIBRARY.GET_COMBINED,
    NAME: "Combined",
  });

  useEffect(() => {
    if (details && details.id) {
      myMaorCombined.onLoad({ section: "all", videoId: details.id });
    }
  }, [details]);

  useEffect(() => {
    if (authorization.isAuthenticated) {
      authorization.getPersons({});
    }
  }, [authorization.isAuthenticated]);

  useEffect(() => {
    partnership.getPartnership();
  }, [authorization.isAuthenticated]);

  return (
    <>
      {videoIdMatch ? (
        <PageMetaTags
          videoDescription={videoDescription}
          videoTitle={videoTitle}
        />
      ) : (
        <PageTitle value={PAGE_TITLES.MY_MAOR} />
      )}
      <Layout
        data={{
          filterData: filterData,
          coloringPages: coloringPages,
          onNavigateToSearch: onNavigateToSearch,
          autocomplete: {
            search: search,
            searching: searching,
            onNavigateToSearch: onNavigateToSearch,
          },
          audioHandler: audioHandler,
        }}
        logotype="my-maor"
      >
        <Modal
          opened={playlistVideoAction.isOpened}
          style={playlistVideoAction.style}
        >
          <PlaylistModal page="my-maor" video={playlistVideoAction} />
        </Modal>

        <EmptyPlaylist popup={playlistEmptyModal} />
        <Authorization
          popup={popup}
          authorization={authorization}
          partnership={partnership}
        />
        <AuthorizationKids
          popup={popupKids}
          authorization={authorization}
          partnership={partnership}
        />
        <AuthorizationCommon
          popup={popupKids}
          authorization={authorization}
          partnership={partnership}
        />

        <section
          className={cs(
            classes.searchSec,
            isMobile ? classes.mobileSearch : ""
          )}
        >
          {!isMobile ? (
            <BrowserView>
              <div className={cs(classes.myMaorTop)}>
                <MaorButton />
                <div className={cs(classes.searchTop)}>
                  <SearchBar
                    id="async-autocomplete-search"
                    placeholder="Search Library"
                    value={search.value}
                    page="kids"
                    onSearch={searching.onSearch}
                    searchingOptions={searching.options}
                    isSearching={searching.isSearching}
                    onChange={(value) => {
                      onScrollToMyMaorSection();
                      search.onChange(value);
                    }}
                  >
                    <Icon
                      cursorPointer={true}
                      onClick={() => {
                        let searchValue =
                          document.querySelector(".rbt-input-main").value;
                        if (searchValue != "") {
                          onScrollToMyMaorSection();
                          search.onChange(searchValue);
                        }
                      }}
                      name="search-white"
                      size="l"
                    />
                  </SearchBar>
                  <MyPlaylist />
                </div>
              </div>
            </BrowserView>
          ) : null}

          {isMobile ? (
            <MobileView>
              <div className={cs(classes.myMaorTop)}>
                <MaorButton audioHandler={audioHandler} />
              </div>
            </MobileView>
          ) : null}

          <div
            className={cs(
              classes.videoContentMain,
              !details?.dedication && classes.alignCenter
              // isMobile ? classes.mobileVideoContent : ""
            )}
          >
            {!video.isLoading ? (
              <>
                {" "}
                {videoInfo == null ? (
                  <div className={classes.videoNotFoundContainer}>
                    <div className={classes.videoNotFound}>
                      <img src={videoError} />
                      <h2>Video not found!</h2>
                    </div>
                  </div>
                ) : (
                  <>
                    {videoInfo.id && (
                      <>
                        <div className={classes.videoWrapMain}>
                          <div className={classes.videoBox}>
                            <div className={classes.videoBoxInner}>
                              {/* Video */}
                              <div className={classes.videoPlayer}>
                                <>
                                  {videoAction.isOpened &&
                                  (isVideoFreeWithoutSignIn ||
                                    authorization.isAuthenticated) ? (
                                    <>
                                      {isVideoNotFree ? (
                                        <>
                                          {!authorization.isUserMember ? (
                                            <Preview
                                              {...videoInfo}
                                              internal_id={videoInfo.id}
                                              placeholder={placeholder}
                                              onSelect={videoAction.onOpen}
                                              videoAction={videoAction}
                                              videoCount={video.record.count}
                                              videoInfo={videoInfo}
                                            />
                                          ) : (
                                            <PlaylistModal
                                              video={videoAction}
                                              hideLibrary="1"
                                              hasPlaylist={true}
                                            />
                                          )}
                                        </>
                                      ) : (
                                        <PlaylistModal
                                          video={videoAction}
                                          hideLibrary="1"
                                          hasPlaylist={true}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <Preview
                                      {...videoInfo}
                                      internal_id={videoInfo.id}
                                      placeholder={placeholder}
                                      onSelect={videoAction.onOpen}
                                      videoAction={videoAction}
                                      videoCount={video.record.count}
                                      videoInfo={videoInfo}
                                    />
                                  )}
                                </>
                              </div>
                            </div>
                          </div>
                          <div
                            className={cs(classes.showNotificationForPortrait)}
                          >
                            <NotificationButtons />
                          </div>
                        </div>
                        <div className={cs(classes.ContentWrapMain, !details?.dedication && classes.marginBottomForPortrait )}>
                          {/* {isMobile ? (
                            <MobileView>
                              <div className={classes.videoHeader}>
                                <h3 className={"mb-01"}>{details.name}</h3>
                              </div>
                              <div className={cs("d-block")}>
                                {details.description}
                              </div>
                            </MobileView>
                          ) : (
                            <BrowserView> */}
                          <div className={cs(classes.titleContainer)}>
                            <div className={cs(classes.myMaorTitle, "mb-03")}>
                              <h3 className={cs("fw-200", classes.noWrap)}>
                                #{details.number} |
                              </h3>{" "}
                              <h3 className={"c-orange"}>{details.name}</h3>
                            </div>
                            {details.description && (
                              <p>{details.description}</p>
                            )}
                          </div>
                          {/* </BrowserView>
                          )} */}

                          {/* <div className={cs(classes.boxedlayout)}></div> */}
                          {details?.dedication && (
                            <div className={cs(classes.videoDedicationSection)}>
                              {!details.dedication && (
                                <div className={cs(classes.waveContainer)}>
                                  <div className={cs(classes.wavyLine)} />
                                </div>
                              )}

                              {details.dedication && (
                                <>
                                  <div className={cs(classes.dedicationText)}>
                                    <h3
                                      className={cs(classes.dedicationHeading)}
                                    >
                                      Dedicated in honor of:
                                    </h3>
                                    <div className={cs(classes.waveContainer)}>
                                      <div className={cs(classes.wavyLine)} />
                                    </div>
                                  </div>

                                  <div className={cs(classes.textContainer)}>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: details.dedication,
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                              <div className={cs(classes.dedicateVideo)}>
                                <Button
                                  size={SIZES.XS}
                                  color={COLORS.PINK}
                                  onClick={() => {
                                    history.push(
                                      APPLICATION_ROUTES.BIRTHDAY_PACKAGES_PAGE
                                    );
                                  }}
                                >
                                  Dedicate a Video
                                </Button>
                              </div>
                            </div>
                          )}
                          <div
                            className={cs(classes.hideNotificationForPortrait)}
                          >
                            <NotificationButtons />
                          </div>
                        </div>

                        {/* <MailingModal popup={videoNotificationPopup} /> */}
                      </>
                    )}
                  </>
                )}{" "}
              </>
            ) : (
              <div className={classes.loadingBox}>
                <p className={classes.innerBox}>
                  <Loader size="large" />
                  <h3>Please Wait...</h3>
                </p>
              </div>
            )}
          </div>
        </section>

        <section>
          {!isVideoFreeWithoutSignIn ? (
            authorization.isAuthenticated ? (
              <>
                {isVideoNotFree ? (
                  <>
                    {" "}
                    {!authorization.isUserMember ? (
                      <AuthorizationCommon popup={videoAction} />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                {isVideoNotFree ? (
                  <>
                    {!authorization.isUserMember ? (
                      <AuthorizationCommon popup={videoAction} />
                    ) : (
                      ""
                    )}{" "}
                  </>
                ) : (
                  <AuthorizationKids popup={videoAction} />
                )}
              </>
            )
          ) : (
            ""
          )}
        </section>

        <section
          className={cs(
            classes.packagesSec,
            isMobile ? classes.mobilePackagesSec : ""
          )}
        >
          <main
            className={cs(
              isMobile && classes.mainMobilePadding,
              "my-maor-main-content"
            )}
          >
            <>
              <div className={cs("pb-12 wrapper-l")}>
                <Ad />

                <div className={cs("pt-10")}>
                  <Winner />
                </div>

                <div className={cs(classes.searchMobile)}>
                  <SearchBar
                    id="async-autocomplete-search"
                    placeholder="Search Library"
                    value={search.value}
                    page="kids"
                    onSearch={searching.onSearch}
                    searchingOptions={searching.options}
                    isSearching={searching.isSearching}
                    onChange={(value) => {
                      onScrollToMyMaorSection();
                      search.onChange(value);
                    }}
                  >
                    <Icon
                      cursorPointer={true}
                      onClick={() => {
                        let searchValue =
                          document.querySelector(".rbt-input-main").value;
                        if (searchValue != "" && !isMobile) {
                          onScrollToMyMaorSection();
                          search.onChange(searchValue);
                        }
                      }}
                      name="search-white"
                      size="l"
                    />
                  </SearchBar>
                  <MyPlaylist />
                </div>
              </div>

              <Element name="myMaor" />
              <div id="myMaor"></div>
              {Object.keys(appliedFilters.data).length != 0 && (
                <>
                  <LibraryFilterClear
                    filters={appliedFilters.data}
                    onClear={appliedFilters.onClear}
                  />
                  <div className="mb-05">
                    {(projectType == "" ||
                      projectType == "MyMaor" ||
                      common.get("project") == "my-maor") && (
                      <section ref={maorRef} className={cs(classes.section, classes.searchResultSection)}>
                        <Project
                          hasItems={myMaor.hasItems}
                          hasNextPage={myMaor.hasNextPage}
                          items={myMaor.items}
                          totalItems={myMaor.totalItems}
                          isLoading={myMaor.isLoading}
                          logotype={LOGOTYPE_TYPES.MY_MAOR}
                          onLoad={myMaor.onLoad}
                          search={search.value}
                          order={sorting.value}
                          holidayId={holiday.value}
                          videoType={maorType.value}
                          coloringPages={coloringPages}
                          setRequestPayload={myMaor.setRequestPayload}
                          resetPage={myMaor.resetPage}
                          title={PROJECTS.TITLES.MY_MAOR}
                          projectType={PROJECTS.MY_MAOR.ID}
                          // freeAccess={myMaor.freeAccess}
                          // freeAccess={freeAccessDonor()}
                          appliedFilters={appliedFilters}
                          limitRecord={projectType != "" ? 20 : 48}
                          myMaorPage={true}
                        />
                      </section>
                    )}
                  </div>
                </>
              )}

              {library[`isLoading${ACTIONS.LIBRARY.ALL_SECTIONS}`] ? (
                <Loader />
              ) : (
                <div className={cs(classes.videoListContainer)}>
                  <Element name="videoList" />
                  {myMaorCombined.items &&
                    Object.keys(myMaorCombined.items).map((key, index) => {
                      const item = myMaorCombined.items[key];
                      const colorIndex = index >= 7 ? index % 7 : index;

                      let itemComponent = null;

                      const audioProps = {
                        hasItems: true,
                        isLoading: false,
                        onLoad: () => {},
                        items: item,
                        title: key,
                        themeColor: themeColors[colorIndex],
                      };

                      const props = {
                        hasItems: true,
                        hasNextPage: item.next_page,
                        items: item.items,
                        totalItems: item.total_items,
                        isLoading: library[`isLoading${key}`],
                        onLoad: myMaorCombined.onLoad,
                        details: details,
                        // search: search.value,
                        // order: sorting.value,
                        // holidayId: holiday.value,
                        // videoType: maorType.value,
                        coloringPages: coloringPages,
                        setRequestPayload: myMaor.setRequestPayload,
                        resetPage: myMaor.resetPage,
                        title: key,
                        projectType: PROJECTS.MY_MAOR.ID,
                        // freeAccess: myMaor.freeAccess
                        // freeAccess: freeAccessDonor()
                        // appliedFilters: appliedFilters,
                        limitRecord: 8,
                        themeColor: themeColors[colorIndex],
                      };

                      if (item.category_id) {
                        props.categoryId = item.category_id;
                      } else if (item.holiday_id) {
                        props.holidayId = item.holiday_id;
                      }

                      switch (key) {
                        case "Audio - Shlichus Adventures & Stories":
                          itemComponent = <AudioList {...audioProps} />;
                          break;

                        case "Coloring Pages":
                          itemComponent = <ColoringProjectList {...props} />;
                          break;

                        default:
                          itemComponent = <ProjectList {...props} />;
                          break;
                      }

                      return itemComponent;
                    })}
                </div>
              )}

              {/* {library[`isLoading${ACTIONS.LIBRARY.GET_COMBINED}`] && (
                <Loader />
              )} */}
              {/* <div
                className={cs(
                  classes.wrapper,
                  "d-flex fd-column jc-center ai-center"
                )}
              >
                <Element name="packages" />
                <div id="birthday-package" className={cs(classes.sectionTitle)}>
                  <div>
                    <img src={gift} className={cs(classes.leftImg)} />
                  </div>
                  <div>
                    <h2 className={cs(classes.pinkColor, "mb-06")}>
                      Make Your child&apos;s birthday special!
                    </h2>
                    <h3>
                      Take your childs special day to the next level by
                      combining{" "}
                      <span className={cs(classes.greenColor, "fw-700")}>
                        meaning & a thrilling gift.
                      </span>
                    </h3>
                  </div>
                  <div>
                    <img src={ballon} className={cs(classes.rightImg)} />
                  </div>
                </div>
              </div>

              <BirthdayPackages /> */}
            </>

            {/* <section
              className={cs(
                classes.features,
                isMobile ? classes.mobileFeatures : ""
              )}
            >
              <div
                className={cs(
                  classes.wrapper,
                  isMobile ? classes.mobileWrapper : "",
                  "d-flex fd-column jc-center ai-center"
                )}
              >
                <ul className={cs(classes.list, "w-100")}>
                  {features.map(
                    ({
                      Illustration,
                      title_translation_key,
                      text_translation_key,
                    }) => (
                      <li key={title_translation_key} className={classes.item}>
                        <div className={classes.illustration}>
                          <Illustration />
                        </div>
                        <h4 className={classes.title}>
                          {t(title_translation_key)}
                        </h4>
                        <p className={classes.text}>
                          {t(text_translation_key)}
                        </p>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </section> */}
          </main>
        </section>

        {/* <section
          className={cs(
            classes.testimonialSec,
            isMobile ? classes.mobileTestimonial : ""
          )}
        >
          <main
            className={cs(
              classes.paddingTestimonial,
              isMobile && classes.paddingMobileTestimonial
            )}
          >
            <div className={cs(classes.testimonialInner)}>
              <div className={cs(classes.sliderWrap)}>
                <h3 className="mb-05">What happy parents say:</h3>
                <div className={classes.sliderContainer}>
                  <Slider
                    {...sliderSettings}
                    sliderRef={sliderRef}
                    className={isMobile ? "" : "mb-15"}
                  >
                    <div className={cs(classes.slideLayout)}>
                      <div className="c-orange ta-left">
                        “From all the exciting things that happened on my kid's
                        birthday, from birthday present surprise to pink
                        cupcakes, the most priceless moment was when she heard
                        her name announced on the video with a special Mazel Tov
                        and bracha. Thank You!”
                      </div>
                      <div className={cs(classes.slideAbout)}>
                        <strong>Mimi Fehler</strong>
                        <p>Cedarhurst, NY</p>
                      </div>
                    </div>
                    <div className={cs(classes.slideLayout)}>
                      <div className="c-orange ta-left">
                        “I have to let you know my son was so excited to see and
                        hear his name announced on the video. I have to admit,
                        we all were.{" "}
                        <strong>
                          That really was the best gift I've ever given my child
                        </strong>{" "}
                        and I look forward to giving the same gift to my other
                        two children.”
                      </div>
                      <div className={cs(classes.slideAbout)}>
                        <strong>C. S.</strong>
                        <p>Crown Heights</p>
                      </div>
                    </div>
                    <div className={cs(classes.slideLayout)}>
                      <div className="c-orange ta-left">
                        “Leah was so excited to be able to customize and pick
                        the truck for a Maor scene. Every single video we
                        watched after that, she waited with baited breath to see
                        if it was in. When she actually saw it, it was the
                        biggest simcha. She ran around the house announcing,
                        “This is the video I made!”
                      </div>
                      <div className={cs(classes.slideAbout)}>
                        <strong>Chany Itkin, Parent</strong>
                        <p>Pittsburgh, PA</p>
                      </div>
                    </div>
                  </Slider>
                  <div className={classes.arrowNavigation}>
                    <button
                      onClick={() => {
                        sliderRef.instance.slickPrev();
                      }}
                    >
                      <Icon name="leftarrow" />
                    </button>
                    <button
                      onClick={() => {
                        sliderRef.instance.slickNext();
                      }}
                    >
                      <Icon name="rightarrow" />
                    </button>
                  </div>
                </div>

                <div className={cs(classes.btnWrap, "mt-13")}>
                  <Button onClick={onScrollToPackageSection}>
                    Select Birthday Bundle!
                  </Button>
                </div>
              </div>
            </div>
          </main>
        </section> */}

        {/* <section
          className={cs(
            classes.MoreVideosSec,
            isMobile ? classes.mobileVideos : ""
          )}
        >
          <div className={cs(classes.videosTopImg, classes.videoSecImg)}>
            <img src={headphone} className={cs(classes.headphoneImg)} />
            <img src={tv} className={cs(classes.tvImg)} />
          </div>

          <div className={cs(classes.videosWrapper, "wrapper-xl")}>
            <div className={cs(classes.centerContent)}>
              <h2 className="mb-05">Watch more</h2>
            </div>
            {category && (
              <div>
                <div className={cs(classes.MoreVideosWrap)}>
                  <h3 className={"c-orange mb-06 fw-700"}>Related Videos</h3>
                  <VideoList
                    hasItems={relatedVideos.hasItems}
                    hasNextPage={relatedVideos.hasNextPage}
                    items={relatedVideos.items}
                    totalItems={relatedVideos.totalItems}
                    isLoading={relatedVideos.isLoading}
                    logotype={LOGOTYPE_TYPES.MY_MAOR}
                    onLoad={relatedVideos.onLoad}
                    search={search.value}
                    order={sorting.value}
                    holidayId={holiday.value}
                    videoType={category.id}
                    setRequestPayload={relatedVideos.setRequestPayload}
                    limitRecord={5}
                    resetPage={relatedVideos.resetPage}
                    title={PROJECTS.TITLES.MY_MAOR}
                    projectType={PROJECTS.MY_MAOR.ID}
                    freeAccess={relatedVideos.freeAccess}
                  />
                </div>
              </div>
            )}
            <div className={cs(classes.MoreVideosWrap, "d-none")}>
              <h3 className={"c-orange mt-08 mb-06 fw-700"}>
                Upcoming Holiday
              </h3>
              <VideoList
                hasItems={upcomingHolidayVideos.hasItems}
                hasNextPage={upcomingHolidayVideos.hasNextPage}
                items={upcomingHolidayVideos.items}
                totalItems={upcomingHolidayVideos.totalItems}
                isLoading={upcomingHolidayVideos.isLoading}
                logotype={LOGOTYPE_TYPES.MY_MAOR}
                onLoad={upcomingHolidayVideos.onLoad}
                order={sorting.value}
                upcomingHoliday={true}
                setRequestPayload={upcomingHolidayVideos.setRequestPayload}
                limitRecord={4}
                resetPage={upcomingHolidayVideos.resetPage}
                title={PROJECTS.TITLES.MY_MAOR}
                projectType={PROJECTS.MY_MAOR.ID}
                freeAccess={upcomingHolidayVideos.freeAccess}
              />
            </div>
          </div>
        </section> */}

        {audioHandler.currentAudio.musicSrc && (
          <AudioPlayer audioHandler={audioHandler} />
        )}
      </Layout>
    </>
  );
});

MyMaor.displayName = "MyMaor";
export default React.memo(MyMaor);
