import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cs from "classnames";

import { DIRECTIONS, items } from "./utilities";
import classes from "./LibraryNavigation.module.scss";
// import { useContext } from "react";
// import { StoreContext } from "../../../contexts";
// import { useEffect } from "react";
// import { ACTIONS } from "../../../store";

const LibraryNavigation = ({ direction, onClick }) => {
  const { t } = useTranslation();
  // const { freedonoraccess } = useContext(StoreContext)

  // const currentURL = window.location.href;
  // const fdaSession = sessionStorage.getItem("fda");
  // let fdaURL = currentURL.replace(/&fda=1/, '');
  // fdaURL = fdaURL.replace(/\?fda=1/, '');

  // useEffect(() => {

  //   if (fdaSession) {
  //     freedonoraccess[`${ACTIONS.FREE_DONOR_ACCESS.GET}`]({
  //       fdaURL
  //     })
  //   }
  // }, [onClick])

  return (
    <div className={cs(classes.librarymain)}>
      <ul className={cs(classes.container, classes[direction], classes.librarylist)}>
        {items.map((item) => (
          <li key={item.title_translation_key} className={classes.item}>
            <NavLink
              activeClassName={classes.active}
              className={cs(classes.link, { [classes.disabled]: item.disabled })}
              exact
              to={item.href + window.location.search}
              onClick={onClick}
            >
              {t(item.title_translation_key)}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

LibraryNavigation.propTypes = {
  direction: PropTypes.oneOf(Object.values(DIRECTIONS)),
  onClick: PropTypes.func,
};

LibraryNavigation.defaultProps = {
  direction: DIRECTIONS.HORIZONTAL,
};

export default LibraryNavigation;
