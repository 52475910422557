import { GoogleLogin } from '@react-oauth/google'
import { jwtDecode } from 'jwt-decode'
import React from 'react'
import PropTypes from "prop-types";
import { useCallback } from 'react';
import { APPLICATION_ROUTES, PROJECTS } from '../../../constants';
import { useHistory } from "react-router-dom";
import { useContext } from 'react';
import { StoreContext } from '../../../contexts';
import cs from "classnames";
import classes from "./GoogleAuth.module.scss"
import { observer } from "mobx-react-lite";
import { useQuery, useRedirect } from '../../../hooks';

const GoogleAuth = observer(({ reauthenticate, setClosePopUp }) => {

    const { authorization, checkout, common } = useContext(StoreContext);
    const history = useHistory();
    const { onRedirect } = useRedirect();
    const query = useQuery();

    const onNavigateToQuestionnaire = useCallback((account_created) => {
        if (account_created) {
            history.push(APPLICATION_ROUTES.SIGNUP_SUCCESS);
        } else {
            onRedirect();
            // history.push(APPLICATION_ROUTES.HOME);
        }
    }, []);

    let referredFrom = "";
    if (navigator.cookieEnabled) {
        referredFrom = localStorage.getItem("referral-code");
    }

    const onSuccess = (credentialResponse) => {
        const userData = jwtDecode(credentialResponse.credential);
        const data = {
            firstName: userData.given_name,
            email: userData.email,
            // email: "test@gmail.com",
            lastName: userData.family_name,
            googleToken: userData.sub,
            picture: userData.picture,
            referredFrom: referredFrom,
            // phone: "",
            onSuccess: reauthenticate == true
                ? () => {
                    if (setClosePopUp !== null) {
                        setClosePopUp(false);
                        common.set("isAuthorize", "true");
                        checkout.createBirthdayTransaction({
                            projectID: PROJECTS.MY_MAOR.ID,
                            reAuthenticated: true,
                        });
                    }
                }
                : () => {
                    if (query.get('redirect')) {
                        window.location = query.get('redirect')
                    } else {
                        onNavigateToQuestionnaire
                    }
                },
        };

        authorization.signUp(data);
        if (navigator.cookieEnabled) {
            localStorage.removeItem("referral-code");
        }
    }

    const onFailure = (res) => {
        console.log("FAILED ", res)
    }

    const handleGoogleLastName = () => {
        window.open("https://myaccount.google.com/u/3/personal-info", "_blank", "noreferrer");
    };

    return (
        <div>
            <div className={cs(classes.googleContainer)}>
                <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    text={"signin_with"}
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    cookiePolicy={'single_host_origin'}
                    size={"large"}
                    width={"290px"}
                    logo_alignment={"center"}
                />
            </div>

            <div>
                {authorization.errorSignUp && <div className={`c-red mt-10`}><p>The Google account you selected doesn&apos;t have your Last Name updated.
                    It&apos;s needed in order to continue.</p><p className='mt-05'> <a style={{ textDecoration: 'underline', cursor: 'pointer' }} className='c-orange' onClick={handleGoogleLastName}>Click here</a> to add your Last Name and try again.</p></div>}
            </div>
        </div>
    )
});

const inputPropTypes = PropTypes.shape({
    error: PropTypes.string.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    label: PropTypes.string.isRequired,
    touched: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
}).isRequired;

GoogleAuth.propTypes = {
    email: inputPropTypes,
    lastName: inputPropTypes,
    firstName: inputPropTypes,
    onSubmit: PropTypes.func.isRequired,
};

export default GoogleAuth