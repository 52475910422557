import React, { useCallback, useContext, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import cs from "classnames";

import Button from "../../atoms/Button";
import Checkbox from "../../atoms/Checkbox";
import Icon from "../../atoms/Icon";
import Loader from "../../atoms/Loader";
import Logotype from "../../atoms/Logotype";
import ProgressDots from "../../atoms/ProgressDots";
import { StoreContext } from "../../../contexts";
import { ACTIONS } from "../../../store";
import { TYPES } from "./utilities";
import classes from "./Order.module.scss";
import Cookies from "../../../utilities/cookies";
import { APPLICATION_ROUTES, COOKIES } from "../../../constants";
import { useHistory } from "react-router-dom";

const Order = observer(({ options, type }) => {
  const { authorization } = useContext(StoreContext);
  const history = useHistory();
  const onSignOut = useCallback(() => {
    Cookies.delete({ name: COOKIES.AUTO_SIGNIN });
    Cookies.delete({ name: COOKIES.CART_ID });

    history.push(APPLICATION_ROUTES.SIGN_IN);
    const url = new URL(window.location.href);
    url.searchParams.delete("id");
    window.history.replaceState(null, "", url.toString());
    window.location = url.toString();
    authorization.signOut();
  });
  const [isOpened, setIsOpened] = useState(false);
  const onToggleIsOpened = useCallback(() => {
    setIsOpened((state) => !state);
  }, []);

  const { t } = useTranslation();

  console.log(
    options,
    options.step.isNotChildScreen,
    options.stepIndex,
    "options.view.Illustration"
  );

  return (
    <div className={cs(classes.container, classes[type])}>
      <div className={cs(classes.wrapper, "wrapper-xl")}>
        <div className={cs(classes.logotype, "d-none")}>
          <Logotype type={type} />
        </div>
        <div className={cs(classes.card)}>
          {isOpened && (
            <div className={cs(classes.overlay)} onClick={onToggleIsOpened} />
          )}
          <aside className={cs(classes.aside, { [classes.opened]: isOpened })}>
            {/* <nav className={classes.logotype}>
              <Logotype type={type} />
            </nav> */}
            <div className={classes.progress}>
              <ProgressDots items={options.dots} vertical={true} />
            </div>
            <nav className={classes.package}>
              <div className={classes.illustration}>
                <options.view.Illustration />
              </div>
              <div className={classes.title}>
                {t(options.view.title_translation_key)}
              </div>
              <div className={classes.link}>
                <Link to={options.backURL}>
                  {t("components.templates.order.labels.3")}
                </Link>
              </div>
            </nav>
            <button
              className={cs(classes.button, { [classes.rotated]: isOpened })}
              onClick={onToggleIsOpened}
            >
              <Icon name="triangle-empty" />
            </button>
          </aside>
          <header className={classes.header}>
            {authorization[`isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`] && (
              <Loader />
            )}
            {!authorization.isAuthenticated &&
              !authorization[`isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`] && (
                <>
                  <span className={classes.loggedInText}>
                    <span className={classes.createAnAccount}>
                      {t("partners.create_an_account_or")}&nbsp;
                    </span>
                    or{" "}
                    <a
                      className={classes.logoutLink}
                      size="xs"
                      onClick={options.onNavigateToSignIn}
                    >
                      {t("layout.sign_in")}
                    </a>
                  </span>
                </>
              )}
            {authorization.isAuthenticated && (
              <p>
                <span className={classes.loggedInText}>
                  {t("layout.logged_in_as")}
                  <span className={classes.familyText}>
                    <strong>{authorization.lastName} </strong>{" "}
                    {t("layout.family").toLowerCase()}
                  </span>
                </span>
                <a className={classes.logoutLink} onClick={onSignOut} href="#">
                  {t("layout.log_out")}
                </a>
                {/* <Button color="ghost" size="xs" onClick={onSignOut}>
                  {t("layout.log_out")}
                </Button> */}
              </p>
            )}
          </header>
          <main className={classes.main}>
            <options.step.View {...options.step.props} />
          </main>
          <footer className={classes.footer}>
            {options.anonymous && (
              <div hidden>
                <Checkbox
                  size="s"
                  checked={options.anonymous.checked}
                  onChange={options.anonymous.onChange}
                >
                  {options.anonymous.label}
                </Checkbox>
              </div>
            )}
            <div className={classes.bar}>
              <div className={classes.item}>
                {(options.page != "partners"
                  ? options.step.isNotChildScreen && options.stepIndex == 0
                  : options.step.isNotAddressScreen) && (
                  <Button color="ghost" onClick={options.onGoPrevious}>
                    <div className="mr-03">
                      <Icon name="arrow-pointer" rotated size="xs" />
                    </div>
                    <div>{t("components.templates.order.labels.1")}</div>
                  </Button>
                )}
                {(options.page != "partners"
                  ? !options.step.isNotChildScreen && options.stepIndex != 0
                  : false) && (
                  <Button color="ghost" onClick={options.onGoPrevious}>
                    <div className="mr-03">
                      <Icon name="arrow-pointer" rotated size="xs" />
                    </div>
                    <div>{t("components.templates.order.labels.1")}</div>
                  </Button>
                )}
              </div>

              {!options.hasNextStep &&
                options.onSubmitAndNavigateToPackages && (
                  <div className={classes.item}>
                    <Button
                      disabled={options.step.isNotValid || options.isLoading}
                      onClick={options.onSubmitAndNavigateToPackages}
                    >
                      <div>{options.ALTERNATIVE_SUBMIT_TITLE}</div>
                    </Button>
                  </div>
                )}

              <div className={classes.item}>
                {(options.page != "partners"
                  ? options.step.isNotChildScreen && options.stepIndex == 0
                  : false) && (
                  <Button
                    // disabled={options.step.isNotValid || options.isLoading}
                    onClick={() => {
                      if (!options.step.isNotValid) {
                        options.onGoNext();
                      } else {
                        options.step.triggerValidations &&
                          options.step.triggerValidations();
                        try {
                          const errorMessageElement = document.querySelector(
                            '[class*="errorText"]'
                          );
                          if (errorMessageElement) {
                            errorMessageElement.scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                            });
                          }
                        } catch (e) {
                          console.warn(e);
                        }
                      }
                    }}
                    color={options.step.isNotValid ? "grayColor" : "primary"}
                  >
                    <div>{t("components.templates.order.labels.2")}</div>
                    <div className="ml-03">
                      <Icon name="arrow-pointer" size="xs" />
                    </div>
                  </Button>
                )}
                {(options.page != "partners"
                  ? !options.step.isNotChildScreen && options.stepIndex != 0
                  : options.step.isNotAddressScreen) && (
                  <Button
                    // disabled={options.step.isNotValid || options.isLoading}
                    onClick={() => {
                      if (!options.step.isNotValid) {
                        options.onGoNext();
                      } else {
                        options.step.triggerValidations &&
                          options.step.triggerValidations();
                        try {
                          const errorMessageElement = document.querySelector(
                            '[class*="errorText"]'
                          );
                          if (errorMessageElement) {
                            errorMessageElement.scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                            });
                          }
                        } catch (e) {
                          console.warn(e);
                        }
                      }
                    }}
                    color={options.step.isNotValid ? "grayColor" : "primary"}
                  >
                    {!options.hasNextStep &&
                    options.onSubmitAndNavigateToPackages ? (
                      <>{t("components.templates.order.labels.5")}</>
                    ) : (
                      <div>{t("components.templates.order.labels.2")}</div>
                    )}
                    <div className="ml-03">
                      <Icon name="arrow-pointer" size="xs" />
                    </div>
                  </Button>
                )}
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
});

Order.propTypes = {
  options: PropTypes.shape({
    backURL: PropTypes.string.isRequired,
    view: PropTypes.shape({
      title_translation_key: PropTypes.string.isRequired,
    }).isRequired,
    onGoNext: PropTypes.func.isRequired,
    onGoPrevious: PropTypes.func.isRequired,
  }).isRequired,
  type: PropTypes.oneOf(Object.values(TYPES)),
};

Order.defaultProps = {
  type: TYPES.DEFAULT,
};

export default Order;
