import { useContext, useEffect } from "react";
import { StoreContext } from "../../../../../contexts";
import { ACTIONS } from "../../../../../store";

const useWinners = () => {
  const { winners } = useContext(StoreContext);
  const raffle = winners[`raffle`];
  const maor = winners[`maor`];

  useEffect(() => {
    winners[`${ACTIONS.WINNERS.GET_RAFFLE_WINNER}`]();
    winners[`${ACTIONS.WINNERS.GET_MAOR_WINNER}`]();
  }, []);

  console.log(raffle, "winners raffel")
  console.log(maor, "winners maor")

  return {
    raffle: raffle,
    maor: maor,
  };
};

export default useWinners;
