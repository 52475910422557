import { API_ROUTES } from "../constants";
import API from "./_api";

const VideoDatesAPI = class extends API {
    getVideoDates({ birthdayDate }) {
        return this.request(`${API_ROUTES.VIDEO_DATES}`, {
            method: "POST",
            body: { birthday_date: birthdayDate, },
        })
    }
}

export default VideoDatesAPI;