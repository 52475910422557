const COLORS = {
  PRIMARY: "primary",
};

const SIZES = {
  MEDIUM: "medium",
};

const calculateIndicatorStyle = (items, { vertical }) => {
  const current = items.filter((item) => item.active).length;
  const length = items.length;
  const name = vertical ? "height" : "width";
  const value = (100 / (length - 1)) * (current - 1);

  return {
    [name]: `${value}%`,
  };
};

export { COLORS, SIZES, calculateIndicatorStyle };
