import { observer } from "mobx-react-lite";
import React from "react";

import cs from "classnames";
import classes from "./Banner.module.scss";
import Button from "../../../../atoms/Button";
import { APPLICATION_ROUTES } from "../../../../../constants";
import { scrollToTop } from "../../../../../utilities/helpers";
import { useHistory } from "react-router-dom";

const Banner = observer(() => {
  const history = useHistory();

  const navigateToPackages = () => {
    history.push(APPLICATION_ROUTES.PARTNERS_PACKAGES);
    scrollToTop();
  };

  return (
    <div className={cs(classes.bannerContainer)}>
      <div className={cs(classes.content)}>
        <div className={cs(classes.title)}>
          <hr /> <p>MAOR</p>
        </div>
        <div className={cs(classes.text)}>
          <h3>
            We’re Raising a New <br /> Generation of Chassidim
          </h3>
          <p>
            Our mission remains unchanged: Bringing Moshiach. <br />
            Maor is here to provide a direct link to the Rebbe’s guidance.
          </p>
        </div>

          <div className={cs(classes.btn)}>
            <Button size="s" onClick={navigateToPackages}>
              Become a Partner
            </Button>
          </div>
      </div>
    </div>
  );
});

export default React.memo(Banner);
