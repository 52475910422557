import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import ReactJkMusicPlayer from "react-jinke-music-player";
import "./core.css";
import useAudioPlayer from "../../../hooks/useAudioPlayer";
import { ACTIONS } from "../../../store";
import { StoreContext } from "../../../contexts";
// import Modal from "../../organisms/Modal";
import classes from "./AudioPlayer.module.scss";
// import { useHistory } from "react-router-dom";
// import { APPLICATION_ROUTES } from "../../../constants";
// import { useTranslation } from "react-i18next";
// import Button from "../../atoms/Button";
// import { NavLink } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Authorization } from "../../organisms/RestrictionModal/RestrictionModal";

const AudioPlayer = observer(({ audioHandler }) => {
  const player = useAudioPlayer({ ACTION: ACTIONS.AUDIOS.GET_PLAYLIST });
  // const [audioInstance, setAudioInstance] = useState(null);
  const { authorization, partnership } = useContext(StoreContext);
  const [audioInstance, setAudioInstance] = useState(null);

  // const { t } = useTranslation();

  // const history = useHistory();

  // let currentPlayIndex = audioHandler.currentAudio.index;
  // console.log("#1", currentPlayIndex);

  // const onNavigateToPartnersMain = useCallback(() => {
  //   player.onClose();
  //   history.push(APPLICATION_ROUTES.PARTNERS_PACKAGES);
  // }, []);

  useEffect(() => {
    delete window.initialLoad;
    delete window.handleAudioCalled;

    console.log("Step 1", window.initialLoad);
    player.onLoad();
  }, []);

  useEffect(() => {
    partnership.getPartnership();
  }, [authorization.isAuthenticated]);

  useEffect(() => {
    console.log("Step 2.1", window.initialLoad);

    if (player.playlist.length > 0) {
      player.options.audioLists = player.playlist;
      // player.options.renderAudioTitle = function renderAudioTitle(audioInfo, isMobile) {
      player.options.renderAudioTitle = function renderAudioTitle(audioInfo) {
        return (
          <>
            <span className={classes.autioTitle}>{audioInfo.name}</span>
            {/*{!isMobile && (
              <span className={classes.episodeTitle}>
                {player.playlist.length}{" "}
                {player.playlist.length > 1
                  ? t("pages.audio.episode_available_plural")
                  : t("pages.audio.episode_available_singular")}
              </span>
            )}*/}
          </>
        );
      };

      console.log("Step 2.2", window.initialLoad);
      if (!(window.initialLoad == false)) {
        window.initialLoad = true;
        console.log("Step 2.3", window.initialLoad);
      }

      const audioPlayer = document.querySelector(".music-player-audio");
      audioPlayer.onplay = function () {
        // console.log("Step 3.1", window.initialLoad);
        // if (window.initialLoad == true) {
        //   window.initialLoad = false;
        //   console.log("Step 3.2", window.initialLoad);
        //   player.onClose();

        //   setTimeout(() => {
        //     audioPlayer.pause();
        //   }, 100);
        //   return;
        // }
        // console.log("Step 3.3", window.initialLoad);
        if (
          !(authorization.isUserMember && authorization.canAccessAudioPlayer)
        ) {
          console.log("Step 3.4", window.initialLoad);
          player.onOpen();
          setTimeout(() => {
            audioPlayer.pause();
          }, 100);
        } else {
          try {
            if (isMobile) {
              document.querySelector(".audio-lists-btn").click();
            }

            if (audioHandler.currentAudio.showAudioList == true) {
              console.log("test 34");
              try {
                document.querySelector(".audio-lists-btn").style.display =
                  "flex";
              } catch (e) {
                console.log(e);
              }
            }
          } catch (e) {
            console.log(e);
          }
        }
      };
    }
  }, [player.playlist]);

  // if (audioHandler.currentAudio.index) {
  //   currentPlayIndex = audioHandler.currentAudio.index;
  // }
  // const handleAudioPlayerClicked = () => {
  //   console.log("Step 3.1", window.initialLoad);
  //   if (window.initialLoad == true) {
  //     window.initialLoad = false;
  //     console.log("Step 3.2", window.initialLoad);
  //     player.onClose();

  //     setTimeout(() => {
  //       audioInstance.pause();
  //     }, 100);
  //     return;
  //   }
  //   console.log("Step 3.3", window.initialLoad);
  //   if (!(authorization.isUserMember && authorization.canAccessAudioPlayer)) {
  //     console.log("Step 3.4", window.initialLoad);
  //     player.onOpen();
  //     setTimeout(() => {
  //       audioInstance.pause();
  //     }, 100);
  //   }
  // };
  // console.log(handleAudioPlayerClicked);

  useEffect(() => {
    if (audioInstance && audioHandler.currentAudio.index >= 0) {
      // Programmatically play the correct audio based on the current index
      audioInstance.playByIndex(audioHandler.currentAudio.index);
    }
  }, [audioInstance, audioHandler.currentAudio.index]);

  return (
    <>
      <Authorization
        popup={player}
        authorization={authorization}
        partnership={partnership}
      />
      {/* <Modal opened={player.isOpened} style={player.style}>
        <div data-name="video-overlay" className={classes.overlay} role="button" onClick={player.onClickOverlay}>
          {partnership.isExpired ? (
            <div className={classes.card}>
              <h4 className={classes.title}>{t("pages.library.titles.2")}</h4>
              <p className={classes.text}>{t("pages.library.texts.4")}</p>
              <nav className={classes.navigation}>
                <Button onClick={onNavigateToPartnersMain}>{t("pages.library.labels.7")}</Button>
              </nav>
            </div>
          ) : (
            <div className={classes.card}>
              <h4 className={classes.title}>{t("pages.audio.membership_heading")}</h4>
              <p className={classes.text}>{t("pages.audio.membership_text")}</p>
              <nav className={classes.navigation}>
                {authorization.isAuthenticated ? (
                  <>
                    {authorization.haveBasicPlan ? (
                      <Button onClick={onNavigateToPartnersMain}>{t("pages.library.labels.4")}</Button>
                    ) : (
                      <Button onClick={onNavigateToPartnersMain}>{t("pages.library.labels.2")}</Button>
                    )}
                  </>
                ) : (
                  <>
                    <NavLink
                      onClick={() => {
                        player.onClose();
                      }}
                      className={classes.link}
                      to={APPLICATION_ROUTES.SIGN_IN}
                    >
                      {t("layout.sign_in")}
                    </NavLink>
                    <span className={classes.orText}>or</span>
                    <Button onClick={onNavigateToPartnersMain}>{t("pages.library.labels.2")}</Button>
                  </>
                )}
              </nav>
            </div>
          )}
        </div>
      </Modal> */}
      

      {player.playlist.length > 0 && (
        <div>
          <ReactJkMusicPlayer
            // getAudioInstance={() => {
            //   console.log(audioHandler);
            //   if (audioHandler.showAudioList) {
            //     console.log("test 34");
            //     try {
            //       document.querySelector(".audio-lists-btn").style.display = "flex";
            //     } catch (e) {
            //       console.log(e);
            //     }
            //   }
            // }}
            sortableOptions={{
              swap: false,
              sort: false,
              animation: 100,
              disabled: true,
              swapClass: "audio-lists-panel-sortable-highlight-bg",
            }}
            responsive={false}
             playIndex={audioHandler.currentAudio.index}
            autoPlay={true}
            showDestroy={true}
            autoPlayInitLoadPlayList={false}
            clearPriorAudioLists={true}
            onBeforeDestroy={() => {
              return new Promise((resolve, reject) => {
                audioHandler.setCurrentAudio({});
                reject();
              });
            }}
            getAudioInstance={(instance) => {
              setAudioInstance(instance);
            }}
            // onAudioPlay={() => {
            //   handleAudioPlayerClicked();
            // }}
            {...player.options}
          />
        </div>
      )}
    </>
  );
});

export default AudioPlayer;
