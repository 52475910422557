import { observer } from "mobx-react-lite";
import React from "react";
import cs from "classnames";
import classes from "./CorporateSponsors.module.scss";
import Bugsnag from "../../../../../assets/images/bugsnag.png";
import CIA from "../../../../../assets/images/CIA.png";
import Delmar from "../../../../../assets/images/delmar.png";
import Kirsh from "../../../../../assets/images/kirsh.png";
import PinkLeaf from "../../../../../assets/images/pink-leaf.png";

const CorporateSponsors = observer(() => {
  return (
    <div className={cs(classes.container)}>
      <div className={cs(classes.headingContainer)}>
        <h3>corporate sponsors</h3>
      </div>
      <div className={cs(classes.cardsContainer)}>
        <div className={cs(classes.card, classes.kirshCard, "bgc-gold")}>
          <img src={Kirsh} alt="Kirsh" />

          <div className={cs(classes.btn)}>
            {" "}
            <a href="http://instagram.com/kirsh_jewelers" target="blank">
              <p>Visit Website</p>
            </a>
          </div>
        </div>

        <div className={cs(classes.card, classes.pinkCard, "bgc-gold")}>
          <img src={PinkLeaf} alt="PinkLeaf" />

          <div className={cs(classes.btn)}>
            {" "}
            <a href="https://www.instagram.com/shoppinkleaf" target="blank">
              <p>Visit Website</p>
            </a>
          </div>
        </div>

        <div className={cs(classes.card, classes.ciaCard, "bgc-gold")}>
          <img src={CIA} alt="CIA" />

          <div className={cs(classes.btn)}>
            {" "}
            <a href="https://www.chein-insurance.com/" target="blank">
              <p>Visit Website</p>
            </a>
          </div>
        </div>

        <div className={cs(classes.card, classes.bugsnagCard, "bgc-gold")}>
          <img src={Bugsnag} alt="Bugsnag" />

          <div className={cs(classes.btn)}>
            {" "}
            <a href="https://www.bugsnag.com/" target="blank">
              <p>Visit Website</p>
            </a>
          </div>
        </div>

        <div className={cs(classes.card, classes.delmarCard, "bgc-gold")}>
          <img src={Delmar} alt="Delmar" />

          <div className={cs(classes.btn)}>
            {" "}
            <a href="https://delmarintl.ca/" target="blank">
              <p>Visit Website</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});

export default React.memo(CorporateSponsors);
