import React, { useCallback, useContext } from "react";
import { observer } from "mobx-react-lite";
import cs from "classnames";

import Icon, { NAMES as ICON_NAMES, SIZES as ICON_SIZES } from "../../atoms/Icon";
import Player from "../../organisms/Player";
import { StoreContext } from "../../../contexts";
// import classes from "./Playlist.module.scss";

const Playlist = () => {
  const { playlist } = useContext(StoreContext);

  const onPause = useCallback(() => {
    playlist.pause();
  }, []);
  const onPlay = useCallback(() => {
    playlist.play();
  }, []);
  // const onToggle = useCallback(() => {
  //   playlist.toggle();
  // }, []);
  const onToggleRolling = useCallback(() => {
    playlist.toggleRolling();
  }, []);
  const onSelect = useCallback(({ target: { value } }) => {
    playlist.select(Number(value));
  }, []);
  const onSelectPrevious = useCallback(() => {
    playlist.selectPrevious();
  }, []);
  const onSelectNext = useCallback(() => {
    playlist.selectNext();
  }, []);

  return (
    <div className="ov-hidden bxsh-hard br-03">
      {playlist.hasItems && (
        <div>
          <Player
            autoplay
            controls={true}
            paused={playlist.paused}
            video={playlist.currentVideoURL}
            onEnd={onSelectNext}
            onPause={onPause}
            onPlay={onPlay}
          />
        </div>
      )}
      <div className="text-m pt-04 pb-04 pl-03 pr-04 bgc-blue-dark c-white">
        {playlist.hasItems && !playlist.rolled && (
          <ul style={{ maxHeight: "188px" }} className="ovy-auto mt-04--nft">
            {playlist.items.map((item) => (
              <li
                className={cs("d-flex ai-start mt-02--nft", {
                  "c-orange": playlist.currentVideoID === item.id,
                })}
                key={item.id}
              >
                <button
                  className="d-flex ai-start b-none p-0 w-100 ta-left bgc-transparent"
                  value={item.id}
                  onClick={onSelect}
                >
                  <div className="mr-02 c-orange pe-none">
                    <Icon name={ICON_NAMES.PLAY} size={ICON_SIZES.S} events={false} />
                  </div>
                  <h3 className="text-m fw-400 pe-none">{item.title}</h3>
                </button>
              </li>
            ))}
          </ul>
        )}
        {playlist.isEmpty && <ul className="mt-04--nft">There is no items in playlist</ul>}
        <ul className="d-flex ai-center mt-04--nft">
          <li className="mr-02">
            <button
              className="d-block b-none p-0 w-100 bgc-transparent c-orange"
              disabled={playlist.isCurrentVideoFirst}
              onClick={onSelectPrevious}
            >
              <Icon name={ICON_NAMES.CHEVRON_LEFT} size={ICON_SIZES.S} />
            </button>
          </li>
          <li className="mr-02">
            <button
              className="d-block b-none p-0 w-100 bgc-transparent c-orange"
              disabled={playlist.isCurrentVideoLast}
              onClick={onSelectNext}
            >
              <Icon name={ICON_NAMES.CHEVRON_RIGHT} size={ICON_SIZES.S} />
            </button>
          </li>
          <li className="ml-auto">
            <button className="d-block b-none p-0 w-100 bgc-transparent c-orange" onClick={onToggleRolling}>
              <Icon
                name={playlist.rolled ? ICON_NAMES.CHEVRON_DOWN_DOUBLE : ICON_NAMES.CHEVRON_UP_DOUBLE}
                size={ICON_SIZES.S}
              />
            </button>
          </li>
          {/* <li className="ml-04">
            <button
              className="d-block b-none p-0 w-100 bgc-transparent c-orange"
              onClick={() => console.log("should be closed")}
            >
              <Icon name={ICON_NAMES.X} size={ICON_SIZES.S} />
            </button>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default observer(Playlist);
