import React from "react";

import PageTitle from "../../atoms/PageTitle";
import Layout from "../../templates/Layout";
import { PAGE_TITLES } from "../../../constants";
import cs from "classnames";
import classes from "./DailyRebbeVideoMain.module.scss";
import Member from "./Components/Member";
// import Package from "./Components/Package";
import VideosContainer from "./Components/VideosContainer";
import { Element } from "react-scroll";
import { useEffect } from "react";
import { useQuery } from "../../../hooks";

const DailyRebbeVideoMain = () => {
  const query = useQuery();
  const dedicationSection = query.get("dedication");

  useEffect(() => {
    if (dedicationSection != null) {
      setTimeout(function () {
        document.getElementById("DRV-dedication").scrollIntoView();
      }, 500);
      // scroller.scrollTo("dedication", {
      //   duration: 100,
      //   smooth: true,
      //   offset: 40,
      // });
    }
  }, []);

  return (
    <>
      <PageTitle value={PAGE_TITLES.DAILY_REBBE_VIDEO} />
      <Layout logotype="daily-rebbe-video" size="m">
        <div className={cs(classes.container)}>
          <div className={cs(classes.memberContainer)}>
            <Member />
          </div>
          <Element name="dedication" />
          <div
            id="DRV-dedication"
            className={cs(classes.packageContainer, "bgc-yellow")}
          >
            {/* <Package /> */}
          </div>
          <div className={cs(classes.videosContainer)}>
            <VideosContainer />
          </div>
        </div>
      </Layout>
    </>
  );
};

DailyRebbeVideoMain.displayName = 'DailyRebbeVideoMain';
export default React.memo(DailyRebbeVideoMain);

