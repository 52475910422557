import { observer } from "mobx-react-lite";
import React from "react";

import cs from "classnames";
import classes from "./InfoContainer.module.scss";
import { useState } from "react";
import { isBrowser, isMobile } from "react-device-detect";
import Icon from "../../../../../../atoms/Icon";
import NotificationButtons from "../../../../../../organisms/NotificationButtons/NotificationButtons";

const InfoContainer = observer(({ details }) => {
  const [descriptionShown, setDescriptionShown] = useState(false);

  return (
    <div className={cs(classes.infoContainer)}>
      <div className={cs(classes.headingContainer)}>
        <div className={cs(classes.arrowContainer)}>
          <h3>{details.name}</h3>{" "}
          <div className={cs(isMobile ? "d-block" : "d-none")}>
            <Icon
              onClick={() => {
                setDescriptionShown(!descriptionShown);
              }}
              name={descriptionShown ? "arrow-dropup" : "arrow-dropdown"}
            />
          </div>
        </div>

        <p
          className={cs(
            isBrowser && "d-block",
            isMobile && descriptionShown ? "d-none" : "d-block",
          )}
        >
          {details.description}
        </p>
      </div>

      <div className={cs(classes.boxedlayout)}></div>
      <div className={cs(classes.dedicatedContainer)}>

        {details.dedication &&
          <>
            <h3>Dedicated in honor of:</h3>
            <div className={cs(classes.textContainer)}>
              <div dangerouslySetInnerHTML={{ __html: details.dedication }} />
              {/* <p>
            <span className={cs(classes.bold)}>Mendel Bryski’s</span> birthday |
            Brooklyn, NY
          </p>
          <p>
            <span className={cs(classes.bold)}>John Doe’s </span> yahrzeit |
            19th Av
          </p>
          <p>
            <span className={cs(classes.bold)}>Chana </span> and{" "}
            <span className={cs(classes.bold)}> Meir Cohen’s </span> wedding | 8
            Shevat
          </p>
          <p>
            <span className={cs(classes.bold)}>Yossi Shmueli’s</span> Upshernish
            | Pittsburgh, PA
          </p>
          <p>
            <span className={cs(classes.bold)}>Chananya Shusterman’s</span> Bar
            Mitzvah | Rehovot, Israel
          </p> */}
            </div>
          </>}

        <NotificationButtons page="daily-rebbe-video" />
      </div>
    </div>
  );
});

export default React.memo(InfoContainer);
