import { useRef, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";

const Portal = ({ children, selector }) => {
  const ref = useRef();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    ref.current = document.querySelector(selector);
  }, [selector]);

  return isMounted && createPortal(children, ref.current);
};

Portal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  selector: PropTypes.string,
};

Portal.defaultProps = {
  children: null,
  selector: "#modal-root",
};

export default Portal;
