import API from "./_api";
import { API_ROUTES } from "../constants";

const CartAPI = class extends API {
  add({ cartID, data }) {
    return this.request(API_ROUTES.CART.ADD, {
      body: {
        data: data,
        id: cartID,
      },
      method: "POST",
    });
  }

  delete({ cartID, itemID }) {
    return this.request(API_ROUTES.CART.DELETE, {
      body: {
        data: {
          id: cartID,
          item_id: itemID,
        },
      },
      method: "POST",
    });
  }

  edit({ cartID, data, itemID }) {
    return this.request(API_ROUTES.CART.EDIT, {
      body: {
        data: {
          id: cartID,
          item_id: itemID,
          ...data,
        },
      },
      method: "POST",
    });
  }

  get({ cartID, cartType = "packages" }) {
    return this.request(API_ROUTES.CART.GET, {
      body: {
        data: {
          id: cartID,
          cartType: cartType,
        },
      },
      method: "POST",
    });
  }
};

export default CartAPI;
