import React from "react";
import PropTypes from "prop-types";

import classes from "./ProgressBar.module.scss";

const ProgressBar = ({ current, length }) => {
  const percent = (100 / length) * current;

  return (
    <div className={classes.container}>
      <div style={{ width: `${percent}%` }} className={classes.indicator} />
    </div>
  );
};

ProgressBar.propTypes = {
  current: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
};

export default ProgressBar;
