import { useCallback, useState } from "react";

import FirstStep from "./components/FirstStep";
import SecondStep from "./components/SecondStep";
import useForm from "./useForm";

const useSteps = () => {
  const {
    ageStatus,
    email,
    firstName,
    referralCode,
    isNotValidFirstStep,
    isNotValidSecondStep,
    isNotValidForm,
    lastName,
    password,
    phone,
    onSubmit,
  } = useForm();

  const [stepIndex, setStepIndex] = useState(0);
  const steps = [
    { Component: FirstStep, fields: { email, firstName, lastName, referralCode }, isNotValid: isNotValidFirstStep },
    {
      Component: SecondStep,
      fields: { ageStatus, firstName, phone, password, email, lastName, referralCode },
      isNotValid: isNotValidSecondStep || isNotValidForm,
      setStepIndex: setStepIndex,
    }
  ];
  const allStepsLength = steps.length;
  const currentStepNumber = stepIndex + 1;
  const hasNextStep = currentStepNumber < allStepsLength;

  const fields = steps[stepIndex].fields;
  const Step = steps[stepIndex].Component;
  const isNotValid = steps[stepIndex].isNotValid;

  const goNextStep = useCallback((event) => {
    event.preventDefault();
    setStepIndex((index) => index + 1);
  }, []);

  return {
    allStepsLength,
    currentStepNumber,
    fields,
    isNotValid,
    setStepIndex,
    Step,
    onSubmit: hasNextStep ? goNextStep : onSubmit,
  };
};

export default useSteps;
