import { useEffect, useState } from "react";
import throttle from "lodash.throttle";

import { calculateDirection, calculatePercent, createState, round } from "./utilities";

const useDocumentScroll = () => {
  const [state, setState] = useState(createState());

  useEffect(() => {
    const onScroll = throttle(
      () => {
        setState((state) => {
          const element = document.documentElement;

          const full = element.scrollHeight;
          const visible = element.clientHeight;
          const scrolled = round(element.scrollTop, { max: full - visible });
          const direction = calculateDirection({
            current: scrolled,
            previous: state.vertical.scrolled,
            previousDirection: state.vertical.direction,
          });
          const percent = calculatePercent({ full, scrolled, visible });

          return {
            ...state,
            vertical: {
              ...state.vertical,
              direction: direction,
              full: full,
              percent: percent,
              scrolled: scrolled,
              visible: visible,
            },
            percent: percent,
          };
        });
      },
      100,
      {
        leading: true,
        trailing: true,
      }
    );

    onScroll();
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return {
    vertical: {
      direction: state.vertical.direction,
      full: state.vertical.full,
      percent: state.vertical.percent,
      scrolled: state.vertical.scrolled,
      visible: state.vertical.visible,
    },
  };
};

export default useDocumentScroll;
