class APIError extends Error {
  constructor({ response = {}, body = "Body was not provided." } = {}, ...parameters) {
    super(
      `
      HOST: ${typeof window !== "undefined" ? window.location.host : "Undefined"}
      URL: ${response.url}
      Status: ${response.status}
      Text: ${response.statusText}
      Body: ${JSON.stringify(body)}
    `,
      ...parameters
    );

    this.name = "API Error";
  }
}

export { APIError };
