import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { APPLICATION_ROUTES } from "../../../constants";
import { StoreContext } from "../../../contexts";
import { useInput } from "../../../hooks";
import { NAMES } from "../../../utilities/validation";

const useForm = () => {
  const { authorization } = useContext(StoreContext);
  const history = useHistory();
  const { t } = useTranslation();
  const EMAIL = t("forms.labels.email");
  const THIS_EMAIL_IS_NOT_VALID = t("forms.messages.this_email_is_not_valid");
  const THIS_FIELD_IS_REQUIRED = t("forms.messages.this_field_is_required");

  const email = useInput({
    label: EMAIL,
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      { name: NAMES.EMAIL, options: { message: THIS_EMAIL_IS_NOT_VALID } },
    ],
  });

  const isNotValid = !!email.errors.length;

  const onSubmit = (event) => {
    event.preventDefault();

    const data = {
      email: email.value,
      onSuccess: () => {
        history.push(APPLICATION_ROUTES.PASSWORD_RECOVERY_SUCCESS);
      },
    };

    authorization.requestPasswordRecovery(data);
  };

  return {
    email,
    isNotValid,
    onSubmit,
  };
};

export default useForm;
