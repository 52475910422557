import React, { useContext, useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import cs from "classnames";

import Loader from "../../../../atoms/Loader";
import { PROJECTS } from "../../../../../constants";
import { StoreContext } from "../../../../../contexts";
import { ACTIONS } from "../../../../../store";
import Gateway from "./components/Gateway";
import classes from "./Payment.module.scss";
// import RefreshPage from "../../../../atoms/RefreshPage";
import AvailableCredits from "./components/AvailableCredits";
import { useQuery } from "../../../../../hooks";

const Payment = observer(() => {
  // const { t } = useTranslation();

  const { cart, checkout } = useContext(StoreContext);
  const hasError =
    checkout[`error${ACTIONS.CHECKOUT.CREATE_TRANSACTION}`] ||
    checkout[`error${ACTIONS.CHECKOUT.SALE}`];
  const isLoadingGet =
    checkout[`isLoading${ACTIONS.CHECKOUT.CREATE_TRANSACTION}`];
  // let cartTotal = 0;
  const [cartTotal, setCartTotal] = useState(0);
  const query = useQuery();
  const hasErrorMessage = query.get("hasErrorMessage");

  useEffect(() => {
    if (cart.isNotEmpty) {
      // TODO: remove projectID from request
      checkout.createBirthdayTransaction({
        projectID: PROJECTS.MY_MAOR.ID,
      });

      let total = 0;
      cart.items.map((item) => {
        console.log("Cart item ", item, item.price);
        total += item.price;
      });
      setCartTotal(total);
    }

    return () => {
      checkout.clear();
    };
  }, [cart.items.length]);
  console.log(cart);
  if (checkout[`error${ACTIONS.CHECKOUT.SALE}`] == "not_enough_credits") {
    return (
      <div className={classes.container}>
        <div
          className={cs(
            classes.wrapper,
            classes.message,
            "c-red",
            "fw-700 text-xl"
          )}
        >
          Insufficient Maor Credits.
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (hasError) {
      if (!window.location.href.includes("hasErrorMessage")) {
        window.location = window.location.href + `?hasErrorMessage=${hasError}`;
      } else {
        window.location = window.location.href;
      }
    }
  }, [hasError]);

  // if (hasError) {
  //   return (
  //     <div className={classes.container}>
  //       <div className={cs(classes.wrapper, classes.message)}>
  //         {checkout.transaction.errors
  //           ? checkout.transaction.errors.message
  //           : t("generals.warnings.1")}
  //         <RefreshPage />
  //       </div>
  //     </div>
  //   );
  // }

  if (isLoadingGet) {
    return (
      <div className={classes.container}>
        <div className={cs(classes.wrapper, classes.loader)}>
          <Loader />
        </div>
      </div>
    );
  }

  // if (
  //   checkout.transaction &&
  //   checkout.transaction.payment &&
  //   checkout.transaction.payment.available_credit
  // ) {
  //   // return null;
  //   return (
  //     <div className={classes.container}>
  //       <div className={cs(classes.wrapper, "d-flex jc-center")}>
  //         <h2 className="text-center c-orange">
  //           You have ${checkout.transaction.payment.available_credit} Maor Credit left.
  //         </h2>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        {checkout.transaction &&
        checkout.transaction.payment &&
        checkout.transaction.payment.available_credit ? (
          <>
            <AvailableCredits
              cartTotal={cartTotal}
              availableCredits={checkout.transaction.payment.available_credit}
            />
          </>
        ) : (
          ""
        )}
        <Gateway hasErrorMessage={hasErrorMessage} />
      </div>
    </div>
  );
  // return null;
});

export default Payment;
