import React from "react";

import { useDocumentScroll } from "../../../hooks";
import classes from "./Scrollbar.module.scss";

const Scrollbar = () => {
  const parameters = useDocumentScroll();
  const style = { width: `${parameters.vertical.percent}%` };

  return (
    <div className={classes.container}>
      <div style={style} className={classes.indicator} />
    </div>
  );
};

export default Scrollbar;
