import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../hooks";

const useRedirect = () => {
  const history = useHistory();
  const query = useQuery();
  const onRedirect = useCallback(() => {
    const redirect = query.get("redirect");
    const url = redirect || "/";

    history.push(url);
  }, []);

  return { onRedirect };
};

export default useRedirect;
