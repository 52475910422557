import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { StoreContext } from "../../../contexts";
import { useModal, useSelectR } from "../../../hooks";
import { ACTIONS } from "../../../store";
import { NAMES } from "../../../utilities/validation";
import { getURL } from "../../atoms/Preview/utilities";

// import { useModal, useSelectR } from "../../../../../hooks";
// import { ACTIONS } from "../../../../../store";
// import { NAMES } from "../../../../../utilities/validation";
// import { StoreContext } from "../../../contexts";
// import { getURL } from "./components/Preview/utilities";

const countDownValue = 10;
const initialState = {
  end: null,
  paused: false,
  start: null,
  url: "",
  answers: {},
  currentAnswer: 0,
  currentQuestion: 1,
  totalQuestions: 0,
  child: 0,
  questionTimer: 0,
  showQuestion: false,
};

// const videoInfo = {
//   name: "",
//   description: "",
//   date: "",
//   tags: {},
//   category: {}
// };

const useVideo = () => {
  const modal = useModal();
  const [state, setState] = useState(initialState);
  const [skipDedication, setSkipDedication] = useState(false);
  const { library, authorization, playlist } = useContext(StoreContext);
  const Ref = useRef(null);
  const { t } = useTranslation();

  const THIS_FIELD_IS_REQUIRED = t("forms.messages.this_field_is_required");

  const currentPlaylistId = playlist.getCurrentPlayingVideoId();

  useEffect(() => {
    if (authorization.isAuthenticated) {
      authorization.getPersons({});
    }
  }, [authorization.isAuthenticated]);

  useEffect(() => {
    if (
      library[`success${ACTIONS.LIBRARY.SAVE_VIDEO_QUESTION_OPTION}`] &&
      !library[`isLoading${ACTIONS.LIBRARY.SAVE_VIDEO_QUESTION_OPTION}`]
    ) {
      if (state.currentQuestion < state.totalQuestions && state.currentAnswer) {
        setState((state) => ({
          ...state,
          currentQuestion: state.currentQuestion + 1,
          currentAnswer: 0,
          questionTimer: countDownValue,
        }));
      } else if (state.currentQuestion == state.totalQuestions) {
        setState((state) => ({
          ...state,
          showQuestion: false,
          // totalQuestions: 0,
        }));
        playNextVideo();
      }
    } else if (!library[`isLoading${ACTIONS.LIBRARY.SAVE_VIDEO_QUESTION_OPTION}`]) {
      if (Ref.current) clearInterval(Ref.current);

      const id = setInterval(() => {
        setState((state) => ({
          ...state,
          questionTimer: state.questionTimer - 1,
        }));
      }, 1000);
      Ref.current = id;
    }
  }, [library[`isLoading${ACTIONS.LIBRARY.SAVE_VIDEO_QUESTION_OPTION}`], library]);

  useEffect(() => {
    if (state.questionTimer == countDownValue) {
      if (Ref.current) clearInterval(Ref.current);

      const id = setInterval(() => {
        setState((state) => ({
          ...state,
          questionTimer: state.questionTimer - 1,
        }));
      }, 1000);
      Ref.current = id;
    }

    if (state.questionTimer == 0 && state.currentQuestion < state.totalQuestions) {
      setState((state) => ({
        ...state,
        questionTimer: countDownValue,
        currentQuestion: state.currentQuestion + 1,
      }));
    } else if (state.questionTimer == 0) {
      setState((state) => ({
        ...state,
        showQuestion: false,
        // totalQuestions: 0,
      }));
      playNextVideo();
      if (Ref.current) clearInterval(Ref.current);
    }
  }, [state.questionTimer]);

  const playNextVideo = () => {
    let currentVideoIndex = -1;
    let nextVideoIndex = 0;
    playlist.items.map((playlistItem, index) => {
      if (playlistItem.video.id == currentPlaylistId) {
        currentVideoIndex = index;
      }
    });

    if (playlist.items.length) {
      if (currentVideoIndex < playlist.items.length - 1) {
        nextVideoIndex = currentVideoIndex + 1;
        const playlistItem = playlist.items[nextVideoIndex];

        playlist.setCurrentPlayingVideoId(playlistItem.video.id);
        console.log(
          "Video questions",
          nextVideoIndex,
          currentVideoIndex,
          playlistItem.video.id,
          playlistItem.video.video_questions
        );
        updateVideo({
          end: playlistItem.video.end,
          start: playlistItem.video.start,
          url: getURL(playlistItem.video.url, {
            start: playlistItem.video.start,
          }),
          name: playlistItem.video.name,
          description: playlistItem.video.description,
          number: playlistItem.video.number,
          date: playlistItem.video.created_at,
          categories: playlistItem.video.video_directory.video_categories,
          questions: playlistItem.video.video_questions,
          tags: playlistItem.video.video_directory.video_tags,
          holiday: playlistItem.video.video_directory.holiday,
          file: playlistItem.video.video_directory.file,
          showQuestion: false,
        });
      }
    }
  };

  const childOptions = [];
  authorization.persons.map((person) => {
    childOptions.push({
      label: `${person.first_name} ${person.last_name}`,
      value: person.id,
    });
  });

  const children = useSelectR({
    label: "Select a child",
    options: childOptions,
    validators: [{ name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } }],
    value: "",
  });

  const isNotValid = !state.currentAnswer || !children.value;

  const setAnswer = (e, question) => {
    const answersData = state.answers;
    answersData[question] = e.target.value;
    setState((state) => ({
      ...state,
      answers: answersData,
      currentAnswer: e.target.value,
    }));
  };

  const setChild = (e) => {
    setState((state) => ({
      ...state,
      child: e.value,
    }));
  };

  const skipQuestion = () => {
    if (state.currentQuestion < state.totalQuestions) {
      setState((state) => ({
        ...state,
        currentQuestion: state.currentQuestion + 1,
        currentAnswer: 0,
      }));
    } else {
      setState((state) => ({
        ...state,
        showQuestion: false,
        // totalQuestions: 0,
      }));
      playNextVideo();
    }
  };

  const submitQuestion = () => {
    if (Ref.current) clearInterval(Ref.current);
    saveQuestionOption(state.currentAnswer);
  };

  const saveQuestionOption = (option_id) => {
    library[`${ACTIONS.LIBRARY.SAVE_VIDEO_QUESTION_OPTION}`]({
      option_id,
      person_id: children.value.value,
    });
  };

  const onPause = useCallback(() => {
    setState((state) => ({
      ...state,
      paused: true,
    }));
  }, []);

  const onSelect = useCallback(
    ({
      end = Infinity,
      start = 0,
      url,
      name,
      description,
      categories,
      questions,
      tags,
      file,
      number,
      date,
      showQuestion,
    }) => {
      if (!skipDedication) {
        end = Infinity;
        start = 0;
      }
      console.log("On Select", questions);
      date = mapDate(date);
      setState((state) => ({
        ...state,
        end: end,
        paused: false,
        start: start,
        url: url,
        name: name,
        description: description,
        categories: categories,
        questions: questions,
        tags: tags,
        number: number,
        date: date,
        file: file,
        totalQuestions: questions ? questions.length : 0,
        showQuestion,
        currentQuestion: 1,
      }));
    },
    [skipDedication]
  );

  const onUpdate = useCallback(
    ({ seconds }) => {
      if (state.end != null && seconds > state.end) onPause();
    },
    [state.end]
  );

  const onEnd = useCallback(() => {
    if (state.totalQuestions) {
      setState((state) => ({
        ...state,
        showQuestion: true,
        currentQuestion: 1,
        questionTimer: countDownValue,
      }));
    }
  });

  const onClose = useCallback(() => {
    setState((state) => ({
      ...state,
      paused: false,
      showQuestion: false,
      currentAnswer: 0,
      totalQuestions: 0,
      answers: {},
    }));

    modal.onClose();
  }, []);

  const onOpen = useCallback(
    ({ end, start, url, name, description, categories, questions, tags, file, number, date }) => {
      console.log("On Open", questions);
      onSelect({
        end,
        start,
        url,
        name,
        description,
        categories,
        questions,
        tags,
        file,
        number,
        date,
      });
      let audioPlayer = document.querySelector(".music-player-audio");
      if (audioPlayer) {
        audioPlayer.pause();
      }
      modal.onOpen();
    },
    []
  );

  const updateVideo = useCallback(
    ({ end, start, url, name, description, categories, questions, tags, file, number, date, showQuestion }) => {
      console.log("updated Questions", questions);
      onSelect({
        end,
        start,
        url,
        name,
        description,
        categories,
        questions,
        tags,
        file,
        number,
        date,
        showQuestion,
      });
    }
  );

  const onClickOverlay = useCallback((event) => {
    if (event.target.matches(`[data-name="video-overlay"]`)) onClose();
  }, []);

  const mapDate = (date) => {
    let dateObj = new Date(date);
    let monthName = dateObj.toLocaleString("en-us", { month: "short" });
    return `${monthName} ${dateObj.getDate()}, ${dateObj.getFullYear()}`;
  };

  return useMemo(() => {
    return {
      end: state.end,
      isOpened: modal.isOpened,
      paused: state.paused,
      start: state.start,
      url: state.url,
      name: state.name,
      description: state.description,
      categories: state.categories,
      questions: state.questions,
      tags: state.tags,
      file: state.file,
      number: state.number,
      date: state.date,
      showQuestion: state.showQuestion,
      currentAnswer: state.currentAnswer,
      currentQuestion: state.currentQuestion,
      totalQuestions: state.totalQuestions,
      answers: state.answers,
      questionTimer: state.questionTimer,
      onClickOverlay: onClickOverlay,
      onClose: onClose,
      onOpen: onOpen,
      onUpdate: onUpdate,
      updateVideo: updateVideo,
      onEnd: onEnd,
      skipDedication: skipDedication,
      setSkipDedication: setSkipDedication,
      setAnswer: setAnswer,
      setChild: setChild,
      skipQuestion: skipQuestion,
      submitQuestion: submitQuestion,
      children: children,
      isNotValid: isNotValid,
    };
  }, [
    modal.isOpened,
    state.end,
    state.paused,
    state.start,
    state.url,
    state.name,
    state.showQuestion,
    state.currentAnswer,
    state.answers,
    state.questions,
    state.currentQuestion,
    state.totalQuestions,
    state.questionTimer,
    isNotValid,
    children,
    skipDedication,
  ]);
};

export default useVideo;
