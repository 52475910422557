import useDateSeparate from "../useDateSeprate";

const useDateFormat = (date, format = "T", timeZone = false) => {
    // console.log("Date formatmm", date);

    if (date == null || date == undefined) {
        return null;
    }
    let utcDateObject;

    switch (format) {
        case "T": {
            try {
                if (date.includes("T")) {
                    const { year, month, day } = useDateSeparate(date, "T");
                    utcDateObject = new Date(year, month - 1, day);
                }
            } catch (e) {
                return null;
            }
            break;
        }
        case "/": {
            const { year, month, day } = useDateSeparate(date, "/");
            utcDateObject = new Date(year, month - 1, day);
            break;
        }
        case "-": {
            const { year, month, day } = useDateSeparate(date, "-");
            utcDateObject = new Date(year, month - 1, day);
            break;
        }
        default:
            return null;
    }


    // Check if timeZone is true before converting to EST
    let estDate;
    if (utcDateObject) {
        if (timeZone) {
            estDate = utcDateObject.toLocaleString('en-US', {
                timeZone: 'America/New_York',
            });
        } else {
            estDate = utcDateObject.toLocaleString('en-US');
        }
    }

    const formattedDate = new Date(estDate).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' });

    return formattedDate;
};

export default useDateFormat;
