import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { Trans, useTranslation } from "react-i18next";

import BackButton from "../../atoms/BackButton";
import Loader from "../../atoms/Loader";
import Logotype from "../../atoms/Logotype";
import PageTitle from "../../atoms/PageTitle";
import ProgressDots from "../../atoms/ProgressDots";
import { PAGE_TITLES } from "../../../constants";
import { StoreContext } from "../../../contexts";
import { ACTIONS } from "../../../store";
import useSteps from "./useSteps";
import classes from "./QuestionnaireMain.module.scss";

const QuestionnaireMain = observer(() => {
  const { authorization } = useContext(StoreContext);

  const { dots, fields, hasPreviousStep, isValid, Step, goPreviousStep, onSubmit } = useSteps();

  const { t } = useTranslation();
  const ERROR_TEXT = t("generals.warnings.1");
  const TITLE = t("pages.authorization.questionnaire_main.title", { value: authorization.user.last_name });
  const TEXT = <Trans components={{ a: <br />, b: <b /> }} i18nKey="pages.authorization.questionnaire_main.text" />;

  return (
    <>
      <PageTitle value={PAGE_TITLES.QUESTIONNAIRE} />
      <main className="bg-gold-gradient--horizontal c-grey-dark text-m">
        <div className="wrapper-vertical-s-a minh-100vh">
          <div className="wrapper-l d-flex fd-column ai-center">
            <Logotype />
          </div>
          <div className="wrapper-l mt-04">
            <h2 className="heading-m-a ta-center c-blue-dark">{TITLE}</h2>
          </div>
          <div className="wrapper-l mt-05 ta-center">{TEXT}</div>
          <div className="wrapper-l d-flex fd-column ai-center mt-10">
            <div className="w-100p" style={{ maxWidth: "300px" }}>
              <ProgressDots items={dots} />
            </div>
          </div>
          <div className="wrapper-s mt-07">
            <div className="br-06 pt-07 pr-07 pb-10 pl-07 w-100p bxsh-soft bgc-white">
              <Step
                {...fields}
                isValid={isValid && !authorization[`isLoading${ACTIONS.AUTHORIZATION.SEND_QUESTIONNAIRE}`]}
                onSubmit={onSubmit}
              />
              {authorization[`error${ACTIONS.AUTHORIZATION.SEND_QUESTIONNAIRE}`] && (
                <div className="mt-08 ta-center c-red">{ERROR_TEXT}</div>
              )}
              {authorization[`isLoading${ACTIONS.AUTHORIZATION.SEND_QUESTIONNAIRE}`] && (
                <div className="mt-08">
                  <Loader />
                </div>
              )}
            </div>
          </div>

          {hasPreviousStep && (
            <div className={classes.back}>
              <BackButton onClick={goPreviousStep} />
            </div>
          )}
        </div>
      </main>
    </>
  );
});

export default QuestionnaireMain;
