import { useState, useContext, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import scrollToTop from "../../../utilities/helpers/scrollToTop";
import { APPLICATION_ROUTES } from "../../../constants";
import { StoreContext } from "../../../contexts";
import { ACTIONS } from "../../../store";

const useLibraryFilter = () => {
  let history = useHistory();
  const { library } = useContext(StoreContext);
  let libraryUrl = new URL(location.href);
  let coloringPageToggle =
    libraryUrl.searchParams.get("coloring-pages") == "true" ? true : false;

  let projectedPage = location.pathname.includes(
    APPLICATION_ROUTES.LIBRARY_PROECTED
  );

  let myMaorPage = location.pathname.includes(APPLICATION_ROUTES.MY_MAOR_MAIN);

  const [keyword, setKeyword] = useState(
    libraryUrl.searchParams.get("keyword") ?? ""
  );
  const [projectType, setProjectType] = useState("");
  const [order, setOrder] = useState(
    libraryUrl.searchParams.get("order") ?? "new"
  );
  const [maorTypeValue, setMaorTypeValue] = useState(0);
  const [dailyRebbeTypeValue, setDailyRebbeTypeValue] = useState("");
  const [illuminateTypeValue, setIlluminateTypeValue] = useState("");
  const [tagsValue, setTagsValue] = useState([]);
  const [holidayId, setHolidayId] = useState(
    libraryUrl.searchParams.get("holiday") ?? 0
  );
  const [appliedFilterValues, setAppliedFiltersValues] = useState({});
  const [coloringPagesValue, setColoringPagesValue] =
    useState(coloringPageToggle);

  useEffect(() => {
    if (keyword != "") {
      appliedFilters.add("keyword", keyword, {
        paramValue: keyword,
      });
    }

    if (
      libraryUrl.searchParams.get("category") &&
      libraryUrl.searchParams.get("category-label")
    ) {
      appliedFilters.add(
        "category",
        libraryUrl.searchParams.get("category-label"),
        {
          paramValue: libraryUrl.searchParams.get("category"),
        }
      );
    }

    if (
      libraryUrl.searchParams.get("holiday") &&
      libraryUrl.searchParams.get("holiday-label")
    ) {
      appliedFilters.add(
        "holiday",
        libraryUrl.searchParams.get("holiday-label"),
        {
          paramValue: libraryUrl.searchParams.get("holiday"),
        }
      );
    }

    if (coloringPageToggle) {
      console.log("Here 3", coloringPageToggle);
      appliedFilters.add("coloring-pages", "Coloring Pages", {
        paramValue: true,
      });
    }
  }, []);

  const removeColoringPageFilter = () => {
    setColoringPagesValue(false);
    appliedFilters.remove("coloring-pages");
  };

  const handleNavigationChange = (href) => {
    console.log("href", href);
    if (
      href.includes(
        projectedPage
          ? APPLICATION_ROUTES.MY_MAOR_LIBRARY_PROTECTED
          : APPLICATION_ROUTES.MY_MAOR_LIBRARY
      )
    ) {
      setProjectType("MyMaor");
      setMaorTypeValue(libraryUrl.searchParams.get("category") ?? "");
      setDailyRebbeTypeValue("");
      setIlluminateTypeValue("");
      tagsValue.length > 0
        ? setKeyword(tagsValue[0])
        : setKeyword(libraryUrl.searchParams.get("keyword") ?? "");
    } else if (
      href.includes(
        projectedPage
          ? APPLICATION_ROUTES.DAILY_REBBE_VIDEO_LIBRARY_PROTECTED
          : APPLICATION_ROUTES.DAILY_REBBE_VIDEO_LIBRARY
      )
    ) {
      setMaorTypeValue("");
      setDailyRebbeTypeValue(libraryUrl.searchParams.get("category") ?? "");
      setIlluminateTypeValue("");
      setProjectType("DailyRebbeVideo");
      tagsValue.length > 0
        ? setKeyword(tagsValue[0])
        : setKeyword(libraryUrl.searchParams.get("keyword") ?? "");
    } else if (
      href.includes(
        projectedPage
          ? APPLICATION_ROUTES.ILLUMINATE_LIBRARY_PROTECTED
          : APPLICATION_ROUTES.ILLUMINATE_LIBRARY
      )
    ) {
      setMaorTypeValue("");
      setIlluminateTypeValue(libraryUrl.searchParams.get("category") ?? "");
      setDailyRebbeTypeValue("");
      setProjectType("Illuminate");
      tagsValue.length > 0
        ? setKeyword(tagsValue[0])
        : setKeyword(libraryUrl.searchParams.get("keyword") ?? "");
    } else {
      setProjectType("");
      setMaorTypeValue("");
      setDailyRebbeTypeValue("");
      setIlluminateTypeValue("");
      appliedFilters.remove("category");
      tagsValue.length > 0
        ? setKeyword(tagsValue[0])
        : setKeyword(libraryUrl.searchParams.get("keyword") ?? "");
    }
  };

  const onNavigationChange = (event) => {
    handleNavigationChange(event.target.href);
  };

  let search = {
    
    onChange: (value) => {
      setKeyword(value);
      appliedFilters.add("keyword", value, {
        paramValue: value,
      });
      removeColoringPageFilter();
    },
    value: keyword,
  };

  let sorting = {
    onChange: ({ target: { value } }) => {
      setOrder(value);

      libraryUrl.searchParams.set("order", value);
      history.push({
        search: libraryUrl.search,
      });
    },
    value: order,
  };

  let holiday = {
    onChange: ({ target: { dataset, value } }) => {
      setHolidayId(value);
      history.push(APPLICATION_ROUTES.LIBRARY);
      appliedFilters.add("holiday", dataset.filterlabel, {
        paramValue: value,
      });
      removeColoringPageFilter();
    },
    value: holidayId,
  };

  let searching = {
    options: library.searchingOptions,
    onSearch: useCallback((query) => {
      library[`getSearchingOptions`](query);
    }),
    isLoading: library[`isLoading${ACTIONS.LIBRARY.SEARCHING_OPTIONS}`],
    error: library[`error${ACTIONS.LIBRARY.SEARCHING_OPTIONS}`],
  };

  let maorType = {
    onChange: ({ target: { dataset, value } }) => {
      console.log("TG", dataset);
      setMaorTypeValue(value);
      // Reset Search
      setDailyRebbeTypeValue("DailyRebbeVideo");
      setIlluminateTypeValue("Illuminate");
      setProjectType("MyMaor");
      // setKeyword("");
      setTagsValue([]);
      // setOrder("");

      history.push({
        pathname: projectedPage
          ? APPLICATION_ROUTES.MY_MAOR_LIBRARY_PROTECTED
          : APPLICATION_ROUTES.MY_MAOR_LIBRARY,
      });
      appliedFilters.add("category", dataset.filterlabel, {
        paramValue: value,
      });

      removeColoringPageFilter();
    },
    value: maorTypeValue,
  };

  let dailyRebbeType = {
    onChange: ({ target: { dataset, value } }) => {
      setDailyRebbeTypeValue(value);
      // Reset Search
      setMaorTypeValue("");
      setIlluminateTypeValue("");
      setProjectType("DailyRebbeVideo");
      // setKeyword("");
      setTagsValue([]);

      // setOrder("");
      history.push({
        pathname: projectedPage
          ? APPLICATION_ROUTES.DAILY_REBBE_VIDEO_LIBRARY_PROTECTED
          : APPLICATION_ROUTES.DAILY_REBBE_VIDEO_LIBRARY,
      });
      appliedFilters.add("category", dataset.filterlabel, {
        paramValue: value,
      });

      removeColoringPageFilter();
    },
    value: dailyRebbeTypeValue,
  };

  let illuminateType = {
    onChange: ({ target: { dataset, value } }) => {
      setIlluminateTypeValue(value);
      // Reset Search
      setMaorTypeValue("");
      setDailyRebbeTypeValue("");
      setProjectType("Illuminate");
      // setKeyword("");
      setTagsValue([]);
      // setOrder("");
      history.push({
        pathname: projectedPage
          ? APPLICATION_ROUTES.ILLUMINATE_LIBRARY_PROTECTED
          : APPLICATION_ROUTES.ILLUMINATE_LIBRARY,
      });

      appliedFilters.add("category", dataset.filterlabel, {
        paramValue: value,
      });

      removeColoringPageFilter();
    },
    value: illuminateTypeValue,
  };

  let tag = {
    onChange: ({ target: { value } }) => {
      let updatedTags = tagsValue.map((item) => item);
      if (updatedTags.includes(value)) {
        updatedTags = updatedTags.filter((item) => item !== value);
        setKeyword("");
        appliedFilters.remove("keyword");
      } else {
        setKeyword(value);
        updatedTags = [value];

        appliedFilters.add("keyword", value, {
          paramValue: value,
        });
        document.querySelector(".rbt-input-main").value = value;
      }
      setTagsValue(updatedTags);
      removeColoringPageFilter();
      scrollToTop();
    },
    value: tagsValue,
  };

  const coloringPages = {
    onChange: () => {
      if (!coloringPagesValue) {
        appliedFilters.add("coloring-pages", "Coloring Pages", {
          paramValue: true,
        });
      } else {
        appliedFilters.remove("coloring-pages");
      }
      setColoringPagesValue(!coloringPagesValue);
    },
    value: coloringPagesValue,
  };

  let appliedFilters = {
    data: appliedFilterValues,
    clearAll: () => {
      setKeyword("");
      setTagsValue([]);
      appliedFilters.remove("keyword");
      document.querySelector("#hiddenAsyncInputClear").click();

      setDailyRebbeTypeValue("");
      setIlluminateTypeValue("");
      setMaorTypeValue("");
      setProjectType("");
      history.push({
        pathname: projectedPage
          ? APPLICATION_ROUTES.MY_MAOR_LIBRARY_PROTECTED
          : myMaorPage
          ? APPLICATION_ROUTES.MY_MAOR_MAIN
          : APPLICATION_ROUTES.MY_MAOR_LIBRARY,
      });
      appliedFilters.remove("category");

      setHolidayId("");
      appliedFilters.remove("holiday");

      setColoringPagesValue(false);
      appliedFilters.remove("coloring-pages");
    },
    onClear: (type) => {
      switch (type) {
        case "keyword":
          setKeyword("");
          setTagsValue([]);
          appliedFilters.remove("keyword");
          document.querySelector("#hiddenAsyncInputClear").click();
          break;

        case "category":
          setDailyRebbeTypeValue("");
          setIlluminateTypeValue("");
          setMaorTypeValue("");
          setProjectType("");
          history.push({
            pathname: projectedPage
              ? APPLICATION_ROUTES.LIBRARY_PROECTED
              : APPLICATION_ROUTES.LIBRARY,
          });
          appliedFilters.remove("category");
          break;

        case "holiday":
          setHolidayId("");
          appliedFilters.remove("holiday");
          break;

        case "coloring-pages":
          setColoringPagesValue(false);
          appliedFilters.remove("coloring-pages");
          break;
      }
    },
    add: (key, value, data = {}) => {
      let filters = appliedFilterValues;
      filters[key] = value;
      setAppliedFiltersValues(filters);

      libraryUrl.searchParams.set(key, data.paramValue);
      // Special Label case
      if (key == "category" || key == "holiday") {
        libraryUrl.searchParams.set(`${key}-label`, value);
      }

      history.push({
        search: libraryUrl.search,
      });
    },
    remove: (key) => {
      let filters = appliedFilterValues;
      delete filters[key];
      setAppliedFiltersValues(filters);

      libraryUrl.searchParams.delete(key);
      // Special Label case
      if (key == "category" || key == "holiday") {
        libraryUrl.searchParams.delete(`${key}-label`);
      }
      history.push({
        search: libraryUrl.search,
      });
    },
  };

  let filterData = {
    categories: {
      onLoad: useCallback(() => {
        library[`getCategories`]();
      }, []),
      data: library.filterData.categories.data,
      isLoading: library.filterData.categories.isLoading,
      isError: library.filterData.categories.isError,
    },
    tags: {
      onLoad: useCallback(() => {
        library[`getTags`]();
      }, []),
      data: library.filterData.tags.data,
      isLoading: library.filterData.tags.isLoading,
      isError: library.filterData.tags.isError,
    },
    holidaysToday: {
      onLoad: useCallback(() => {
        library[`getTodayHolidays`]();
      }, []),
      data: library.filterData.holidays.data,
      isLoading: library.filterData.holidays.isLoading,
      isError: library.filterData.holidays.isError,
    },
  };

  return {
    search,
    projectType,
    setProjectType,
    handleNavigationChange,
    onNavigationChange,
    sorting,
    maorType,
    dailyRebbeType,
    illuminateType,
    coloringPages,
    tag,
    filterData,
    holiday,
    appliedFilters,
    searching,
  };
};

export default useLibraryFilter;
