import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";

import Head from "../../atoms/Head";
import { StoreContext } from "../../../contexts";
import {
  getMode,
  initializeAnalytics,
  initializeSentry,
} from "../../../utilities/helpers";
import Routing from "../../templates/Routing";
import TagManager from "react-gtm-module";
import { clarity } from 'react-microsoft-clarity';
import { ACTIONS } from "../../../store";
import { COOKIES } from "../../../constants";
import Cookies from "../../../utilities/cookies";

const App = observer(() => {
  const { authorization, products, playlist, partnership } = useContext(
    StoreContext
  );
  const { isProduction } = getMode();
  const queryParams = new URLSearchParams(window.location.search);
  // const history = useHistory()

  const tagManagerArgs = {
    gtmId: "GTM-KLMZPVQW"
  }

  useEffect(() => {
    if (isProduction) {
      TagManager.initialize(tagManagerArgs);
      clarity.init("jdeyl408ak");
    }
  }, []);

  const authorizationToken = Cookies.get({ name: COOKIES.AUTHORIZATION })

  console.log("authorizationToken", authorizationToken)

  useEffect(() => {
    if (!queryParams.get("token")) {
      authorization.resignIn();
    }
    products.getCampaign();
    products.getActiveMembers();

    if (isProduction) {
      initializeAnalytics();
      initializeSentry();
    }

    if (queryParams.get("referral")) {
      if (navigator.cookieEnabled) {
        localStorage.setItem("referral-code", queryParams.get("referral"))
      }
    }
  }, []);

  let isAuthLoading = authorization[`isLoading${ACTIONS.AUTHORIZATION.SIGN_IN}`] ||
    authorization[`isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`];

  // useEffect(() => {
  //   if (isMobileDevice() && location.pathname != APPLICATION_ROUTES.SIGN_IN) {
  //     if (!authorizationToken) {
  //       window.location.href = `${location.origin}${APPLICATION_ROUTES.SIGN_IN}`;
  //     }
  //   }
  // }, [isAuthLoading]);

  useEffect(() => {
    if (authorization.isAuthenticated) {
      partnership.getPartnership();
      playlist.get({ onSuccess: null });
    }
  }, [authorization.isAuthenticated]);



  return (
    <>
      {isAuthLoading ? (
        <input type="hidden" name="maor-app-logged-unknown" value="1" />
      ) : (
        <>
          {authorization.isAuthenticated ? (
            <input type="hidden" name="maor-app-logged-in" value={authorization.isAuthenticated ? 1 : 0} />
          ) : (
            <input type="hidden" name="maor-app-logged-out" value={authorization.isAuthenticated ? 1 : 0} />
          )}
        </>
      )}
      <Head />
      <Routing />
    </>
  );
});

export default App;
