import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import { COLORS, SIZES } from "./utilities";
import classes from "./ButtonClear.module.scss";
import Icon from "../Icon";

const ButtonClear = ({ children, color, size, stretched, onClear, ...properties }) => (
  <div
    className={cs(classes.container, classes[color], classes[size], { [classes.stretched]: stretched })}
    {...properties}
  >
    {children}
    <button className={cs(classes.button)} onClick={onClear}>
      <Icon size="xxs" name="x" />
    </button>
  </div>
);

ButtonClear.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(Object.values(COLORS)),
  size: PropTypes.oneOf(Object.values(SIZES)),
  stretched: PropTypes.bool,
  onClear: PropTypes.func.isRequired,
};

ButtonClear.defaultProps = {
  color: COLORS.PRIMARY,
  size: SIZES.M,
  stretched: false,
};

export default ButtonClear;
