import { makeAutoObservable } from "mobx";
import { MailingListAPI } from "../../api";
import { ACTIONS } from "./utilities";

const mailinglistAPI = new MailingListAPI();

const MailingList = class {
  constructor() {
    this.items = [];

    const actions = Object.values(ACTIONS);

    for (let i = 0; i < actions.length; i++) {
      const ACTION = actions[i];

      this[`error${ACTION}`] = "";
      this[`isLoading${ACTION}`] = false;
      this[`success${ACTION}`] = false;
    }

    this.loadingIds = [];
    this.totalItems = 0;
    this.nextPage = null;
    this.currentPage = 1;

    makeAutoObservable(this);
  }

  setLoadingId(id) {
    this.loadingIds.push(id);
  }

  removeLoadingId(id) {
    this.loadingIds = this.loadingIds.filter((item) => item != id);
  }

  get hasItems() {
    return !!this.items.length;
  }

  get hasNextPage() {
    return !!this.nextPage;
  }

  get isEmpty() {
    return !this.items.length;
  }

  get({ onSuccess = null, resetPage = false, refreshCurrentPage = false }) {
    this.setIsLoading(ACTIONS.GET, true);

    if (resetPage) {
      this.setValue("nextPage", 1);
    }

    if (refreshCurrentPage) {
      this.setValue("nextPage", this.currentPage);
    }

    mailinglistAPI
      .get({
        page: this.nextPage,
      })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        this.setValue("items", data);
        console.log("data", data);
        this.setIsLoading(ACTIONS.GET, false);
        // this.setValue("totalItems", data.total_items);
        // this.setValue("nextPage", data.next_page);
        // this.setValue("currentPage", data.current_page);

        onSuccess && onSuccess();
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.GET, message);
        this.setIsLoading(ACTIONS.GET, false);
      });
  }

  save({ firstName, lastName, email, mailinglistID, onSuccess }) {
    this.setIsLoading(ACTIONS.SAVE, true);
    this.setError(ACTIONS.SAVE, false);
    this.setSuccess(ACTIONS.SAVE, false);

    mailinglistAPI
      .save({
        first_name: firstName,
        last_name: lastName,
        email: email,
        mailing_list_id: mailinglistID,
      })
      .then(async (response) => {
        if (response.status === 200) return response.json();
        if (response.status == 422) {
          let data = await response.json();
          throw new Error(JSON.stringify(data));
        }
        throw new Error(response);
      })
      .then(() => {
        this.setError(ACTIONS.SAVE, false);
        this.setIsLoading(ACTIONS.SAVE, false);
        this.setSuccess(ACTIONS.SAVE, true);
        onSuccess && onSuccess();
      })
      .catch((error) => {
        try {
          let data = JSON.parse(error.message);
          this.setError(ACTIONS.SAVE, data);
          this.setIsLoading(ACTIONS.SAVE, false);
        } catch (e) {
          this.setError(
            ACTIONS.SAVE,
            "Something went wrong. Please try again."
          );
          this.setIsLoading(ACTIONS.SAVE, false);
        }
      });
  }

  delete({ id, onSuccess }) {
    this.setLoadingId(id);

    mailinglistAPI
      .delete({ id: id })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response);
      })
      .then(() => {
        this.removeLoadingId(id);
        onSuccess && onSuccess();
      })
      .catch((error) => {
        this.setError(ACTIONS.DELETE, error.message);
        this.setIsLoading(ACTIONS.DELETE, false);
      });
  }

  setValue(name, value) {
    this[`${name}`] = value;
  }

  setIsLoading(name, value) {
    this[`isLoading${name}`] = value;
  }

  setError(name, value) {
    this[`error${name}`] = value;
  }

  getError(name) {
    return this[`error${name}`];
  }

  setSuccess(name, value) {
    this[`success${name}`] = value;
  }

  getSuccess(name) {
    return this[`success${name}`];
  }

  getIsLoading(name) {
    return this[`isLoading${name}`];
  }
};

export default MailingList;
