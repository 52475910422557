import API from "./_api";
import { API_ROUTES } from "../constants";

const PartnershipAPI = class extends API {
  get() {
    return this.request(`${API_ROUTES.PARTNERSHIP.GET}`, {
      method: "GET",
    });
  }

  turnOnAutoRenewal() {
    return this.request(`${API_ROUTES.PARTNERSHIP.AUTO_RENEWAL_ON}`, {
      method: "POST",
      body: {
        reActivate: true,
      },
    });
  }

  upgrade({ id, plan, billing_cycle }) {
    return this.request(`${API_ROUTES.PARTNERSHIP.UPGRADE}/${id}`, {
      method: "POST",
      body: {
        plan_name: plan,
        billing_cycle: billing_cycle,
      },
    });
  }

  getAllBusinessPartners() {
    return this.request(`${API_ROUTES.PARTNERSHIP.GET_ALL_BUSINESS_PARTNERS}`, {
      method: "GET",
    });
  }

  cancel({ id }) {
    return this.request(`${API_ROUTES.PARTNERSHIP.CANCEL}/${id}`, {
      method: "GET",
    });
  }
};

export default PartnershipAPI;
