import { PROJECTS } from "../../../../../../../constants";
import { getPackageDetails } from "../../../../../../../utilities/helpers";

const TYPES = {
  SINGLE_LAYER: PROJECTS.MY_MAOR.PACKAGES.SINGLE_LAYER.ID,
  DOUBLE_LAYER: PROJECTS.MY_MAOR.PACKAGES.DOUBLE_LAYER.ID,
  TRIPLE_DECKER: PROJECTS.MY_MAOR.PACKAGES.TRIPLE_DECKER.ID,
  SEVEN_LAYER_EXPLOSION: PROJECTS.MY_MAOR.PACKAGES.SEVEN_LAYER_EXPLOSION.ID,
};

const getPackageView = (type) => {
  const details = getPackageDetails(type);

  return { title_translation_key: details.TITLE_TRANSLATION_KEY, Illustration: details.Illustration };
};



export { TYPES, getPackageView };
