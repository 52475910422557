import React from "react";
import PropTypes from "prop-types";
import ScrollLock from "react-scrolllock";

import Portal from "../Portal";

const Popover = ({ children, portalSelector, disablePortalSelector, style, close, ...properties }) => {
  const onClick = (event) => {
    if (event.target.matches(`[data-cr-popover="overlay"]`)) close();
  };

  if (disablePortalSelector) {
    let datepickerStyle = { ...style };
    // delete datepickerStyle.top;
    // delete datepickerStyle.left;

    return (
      style && (
        <>
          {close ? (
            <>
              <div
                data-cr-popover="overlay"
                style={{
                  overflowY: "hidden",
                  position: "fixed",
                  top: 0,
                  left: 0,
                  zIndex: 200,
                  width: "100%",
                  height: "100%",
                }}
                onClick={onClick}
              />
              <div style={{ position: "absolute", zIndex: 200, ...datepickerStyle }} {...properties}>
                {children}
              </div>
            </>
          ) : (
            <div style={{ position: "absolute", zIndex: 200, ...style }} {...properties}>
              {children}
            </div>
          )}
        </>
      )
    );
  } else {
    return (
      style && (
        <Portal selector={portalSelector}>
          {close ? (
            <>
              <div
                data-cr-popover="overlay"
                style={{
                  overflowY: "scroll",
                  position: "fixed",
                  top: 0,
                  left: 0,
                  zIndex: 200,
                  width: "100%",
                  height: "100%",
                }}
                onClick={onClick}
              />
              <ScrollLock>
                <div style={{ position: "fixed", zIndex: 999999, ...style }} {...properties}>
                  {children}
                </div>
              </ScrollLock>
            </>
          ) : (
            <div style={{ position: "fixed", zIndex: 999999, ...style }} {...properties}>
              {children}
            </div>
          )}
        </Portal>
      )
    );
  }
};

Popover.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  portalSelector: PropTypes.string,
  style: PropTypes.shape({}),
  close: PropTypes.func,
  disablePortalSelector: PropTypes.any,
};

Popover.defaultProps = {
  portalSelector: "#popover-root",
  disablePortalSelector: false,
};

export default Popover;
