import { makeAutoObservable } from "mobx";

import { AuthorizationAPI } from "../../api";
import { PROJECTS, COOKIES } from "../../constants";
import Cookies from "../../utilities/cookies";
import { ACTIONS } from "./utilities";
import { APPLICATION_ROUTES } from "../../constants";
import { isMobileDevice } from "../../utilities/helpers";

const authorizationAPI = new AuthorizationAPI();

class Authorization {
  constructor() {
    const actions = Object.values(ACTIONS);

    for (let i = 0; i < actions.length; i++) {
      const ACTION = actions[i];

      this[`error${ACTION}`] = "";
      this[`isLoading${ACTION}`] = false;
      this[`success${ACTION}`] = false;
    }

    this.user = null;
    this.persons = [];
    this.address = [];
    this.latestGoldPartners = [];
    this.loadingIds = [];
    this.newAccount = null;

    makeAutoObservable(this);
  }

  setLoadingId(id) {
    this.loadingIds.push(id);
  }

  removeLoadingId(id) {
    this.loadingIds = this.loadingIds.filter((item) => item != id);
  }

  setSuccess(name, value) {
    this[`success${name}`] = value;
  }

  setIsLoading(name, value) {
    this[`isLoading${name}`] = value;
  }

  setError(name, value) {
    this[`error${name}`] = value;
  }

  setValue(name, value) {
    this[name] = value;
  }

  setUserValue(name, value) {
    this.user[name] = value;
  }

  saveUser(user) {
    if (user.token)
      Cookies.set({
        name: COOKIES.AUTHORIZATION,
        value: user.token,
        options: { expires: 365 },
      });
    this.user = user;
  }

  clearError(name) {
    this.setError(name, "");
  }

  get canAccessLibraryProtectedPage() {
    if (!navigator.cookieEnabled) {
      return false;
    }

    let cipher = null;
    if (navigator.cookieEnabled) {
      cipher = localStorage.getItem("cipher");
    }

    if (!location.pathname.includes(APPLICATION_ROUTES.LIBRARY_PROECTED)) {
      return false;
    }

    if (cipher == null || cipher == undefined) {
      return false;
    }

    return true;
  }

  get isAuthenticated() {
    return !!this.user;
  }

  get isNotAuthenticated() {
    return !this.user;
  }

  get isUserMember() {
    const PARTNERS_PACKAGES_ID = Object.values(PROJECTS.PARTNERS.PACKAGES).map(
      ({ ID }) => ID
    );
    if (this.canAccessLibraryProtectedPage) {
      return true;
    }
    
    return (
      this.isAuthenticated && PARTNERS_PACKAGES_ID.includes(this.user.tier)
    );
  }

  get membership() {
    if (!this.isUserMember) {
      return null;
    }

    let detail = null;
    for (const property in PROJECTS.PARTNERS.PACKAGES) {
      if (PROJECTS.PARTNERS.PACKAGES[property].ID == this.user.tier) {
        detail = PROJECTS.PARTNERS.PACKAGES[property];
      }
    }

    return detail;
  }

  getPersons({ type = "child", onSuccess = null }) {
    this.setIsLoading(ACTIONS.GET_PERSONS, true);
    this.setError(ACTIONS.GET_PERSONS, "");

    authorizationAPI
      .getPersons({ type })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        this.setValue("persons", data.items);
        this.setIsLoading(ACTIONS.GET_PERSONS, false);
        onSuccess && onSuccess();
      })
      .catch((error) => {
        console.log("Error", error);
        this.setError(ACTIONS.GET_PERSONS, error);
        this.setIsLoading(ACTIONS.GET_PERSONS, false);
      });
  }

  getAddress(onSuccess = null) {
    this.setIsLoading(ACTIONS.GET_ADDRESS, true);
    this.setError(ACTIONS.GET_ADDRESS, "");

    authorizationAPI
      .getAddress()
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        this.setValue("address", data);
        this.setIsLoading(ACTIONS.GET_ADDRESS, false);
        console.log(this[`isLoading${ACTIONS.GET_ADDRESS}`], "address");
        onSuccess && onSuccess();
      })
      .catch((error) => {
        console.log("Error", error);
        this.setError(ACTIONS.GET_ADDRESS, error);
        this.setIsLoading(ACTIONS.GET_ADDRESS, false);
      });
  }

  addAddress({ data, onSuccess = null }) {
    this.setIsLoading(ACTIONS.ADD_ADDRESS, true);
    this.setSuccess(ACTIONS.ADD_ADDRESS, false);
    this.setError(ACTIONS.ADD_ADDRESS, false);
    authorizationAPI
      .addAddress(data)
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(() => {
        this.setIsLoading(ACTIONS.ADD_ADDRESS, false);
        this.setSuccess(ACTIONS.ADD_ADDRESS, true);
        console.log("ADD_ADDRESS", this[`success${ACTIONS.ADD_ADDRESS}`]);
        onSuccess && onSuccess();
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.ADD_ADDRESS, message);
        this.setIsLoading(ACTIONS.ADD_ADDRESS, false);
      });
  }

  editAddress({ data, onSuccess = null }) {
    this.setIsLoading(ACTIONS.EDIT_ADDRESS, true);
    this.setSuccess(ACTIONS.EDIT_ADDRESS, false);
    this.setError(ACTIONS.EDIT_ADDRESS, false);
    console.log("API called");
    authorizationAPI
      .editAddress(data)
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(() => {
        this.setIsLoading(ACTIONS.EDIT_ADDRESS, false);
        this.setSuccess(ACTIONS.EDIT_ADDRESS, true);
        console.log("EDIT_ADDRESS", this[`success${ACTIONS.EDIT_ADDRESS}`]);
        onSuccess && onSuccess();
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.EDIT_ADDRESS, message);
        this.setIsLoading(ACTIONS.EDIT_ADDRESS, false);
      });
  }

  deleteAddress(addressId) {
    this.setIsLoading(ACTIONS.DELETE_ADDRESS, true);
    this.setSuccess(ACTIONS.DELETE_ADDRESS, false);
    this.setError(ACTIONS.DELETE_ADDRESS, false);
    console.log("API called");
    authorizationAPI
      .deleteAddress(addressId)
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(() => {
        this.setIsLoading(ACTIONS.DELETE_ADDRESS, false);
        this.setSuccess(ACTIONS.DELETE_ADDRESS, true);
        console.log("DELETE_ADDRESS", this[`success${ACTIONS.DELETE_ADDRESS}`]);
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.DELETE_ADDRESS, message);
        this.setIsLoading(ACTIONS.DELETE_ADDRESS, false);
      });
  }

  setDefaultAddress(addressId) {
    this.setLoadingId(addressId);

    this.setIsLoading(ACTIONS.SET_DEFAULT_ADDRESS, true);
    this.setSuccess(ACTIONS.SET_DEFAULT_ADDRESS, false);
    this.setError(ACTIONS.SET_DEFAULT_ADDRESS, false);
    console.log("API called");
    authorizationAPI
      .setDefaultAddress(addressId)
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(() => {
        this.removeLoadingId(addressId);
        this.setIsLoading(ACTIONS.SET_DEFAULT_ADDRESS, false);
        this.setSuccess(ACTIONS.SET_DEFAULT_ADDRESS, true);
        console.log(
          "SET_DEFAULT_ADDRESS",
          this[`success${ACTIONS.SET_DEFAULT_ADDRESS}`]
        );
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.SET_DEFAULT_ADDRESS, message);
        this.setIsLoading(ACTIONS.SET_DEFAULT_ADDRESS, false);
      });
  }

  getLatestGoldPartners({ memberLimit, onSuccess = null }) {
    this.setIsLoading(ACTIONS.GET_LATEST_GOLD_PARTNERS, true);
    this.setError(ACTIONS.GET_LATEST_GOLD_PARTNERS, "");
    authorizationAPI
      .getLatestGoldPartners({ memberLimit })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        this.setValue("latestGoldPartners", data);
        console.log(data, "partnersssssss");
        this.setIsLoading(ACTIONS.GET_LATEST_GOLD_PARTNERS, false);
        onSuccess && onSuccess();
      })
      .catch((error) => {
        console.log("Error", error);
        this.setError(ACTIONS.GET_LATEST_GOLD_PARTNERS, error);
        this.setIsLoading(ACTIONS.GET_LATEST_GOLD_PARTNERS, false);
      });
  }

  // setLatestGoldPartners({ number, onSuccess = null }) {
  //   this.setIsLoading(ACTIONS.SET_LATEST_GOLD_PARTNERS, true);

  //   authorizationAPI
  //     .setLatestGoldPartners({ number })
  //     .then((response) => {
  //       if (response.status === 200) return response.json();
  //       throw new Error(response.status);
  //     })
  //     .then(() => {
  //       this.setError(ACTIONS.SET_LATEST_GOLD_PARTNERS, "");
  //       this.setIsLoading(ACTIONS.SET_LATEST_GOLD_PARTNERS, false);
  //       onSuccess && onSuccess();
  //     })
  //     .catch((error) => {
  //       this.setError(ACTIONS.SET_LATEST_GOLD_PARTNERS, error.message);
  //       this.setIsLoading(ACTIONS.SET_LATEST_GOLD_PARTNERS, false);
  //     });
  // }

  get canAccessAudioPlayer() {
    // let PARTNERS_PACKAGES_ID = Object.values(PROJECTS.PARTNERS.PACKAGES).filter(
    //   ({ ID }) => ID != PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID
    // );
    let PARTNERS_PACKAGES_ID = Object.values(PROJECTS.PARTNERS.PACKAGES);

    PARTNERS_PACKAGES_ID = Object.values(PARTNERS_PACKAGES_ID).map(
      ({ ID }) => ID
    );

    if (this.canAccessLibraryProtectedPage) {
      return true;
    }

    return (
      this.isAuthenticated && PARTNERS_PACKAGES_ID.includes(this.user.tier)
    );
  }

  get haveBasicPlan() {
    let PARTNERS_PACKAGES_ID = Object.values(PROJECTS.PARTNERS.PACKAGES).filter(
      ({ ID }) => ID == PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID
    );

    PARTNERS_PACKAGES_ID = Object.values(PARTNERS_PACKAGES_ID).map(
      ({ ID }) => ID
    );

    return (
      this.isAuthenticated && PARTNERS_PACKAGES_ID.includes(this.user.tier)
    );
  }

  get lastName() {
    return this.isAuthenticated ? this.user.last_name : "";
  }

  autheticatePassword(email, password, onSuccess) {
    let token = false;
    this.setIsLoading(ACTIONS.AUTHENTICATE_PASSWORD, true);
    this.setError(ACTIONS.AUTHENTICATE_PASSWORD, "");

    authorizationAPI
      .signIn({ email, password, token })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        console.log("DATA", data);
        Cookies.set({ name: COOKIES.AUTO_SIGNIN, value: 1 });
        this.setIsLoading(ACTIONS.AUTHENTICATE_PASSWORD, false);
        onSuccess && onSuccess();
      })
      .catch((error) => {
        console.log("Error", error);
        this.setError(ACTIONS.AUTHENTICATE_PASSWORD, "password_match_failed");
        this.setIsLoading(ACTIONS.AUTHENTICATE_PASSWORD, false);
      });
  }

  resignIn({ onSuccess } = {}) {
    const token = Cookies.get({ name: COOKIES.AUTHORIZATION });

    if (token) {
      this.setIsLoading(ACTIONS.RESIGN_IN, true);

      authorizationAPI
        .resignIn()
        .then((response) => {
          if (response.status === 200) return response.json();
          if (response.status === 401)
            Cookies.delete({ name: COOKIES.AUTHORIZATION });

          throw new Error(response.status);
        })
        .then(({ data }) => {
          if (data.user.tier == "m_silver_card") {
            data.user.tier = "m_silver-card";
          }
          this.saveUser(data.user);
          this.setError(ACTIONS.RESIGN_IN, "");
          this.setIsLoading(ACTIONS.RESIGN_IN, false);

          onSuccess && onSuccess();
        })
        .catch((error) => {
          this.setError(ACTIONS.RESIGN_IN, error.message);
          this.setIsLoading(ACTIONS.RESIGN_IN, false);
        });

      return;
    }
  }

  requestPasswordRecovery({ email, onSuccess }) {
    this.setIsLoading(ACTIONS.PASSWORD_RECOVERY_REQUEST, true);

    authorizationAPI
      .requestPasswordRecovery({ email })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(() => {
        this.setIsLoading(ACTIONS.PASSWORD_RECOVERY_REQUEST, false);
        onSuccess && onSuccess();
      })
      .catch((error) => {
        this.setError(ACTIONS.PASSWORD_RECOVERY_REQUEST, error.message);
        this.setIsLoading(ACTIONS.PASSWORD_RECOVERY_REQUEST, false);
      });
  }

  sendQuestionnaire({ data, onSuccess }) {
    this.setIsLoading(ACTIONS.SEND_QUESTIONNAIRE, true);

    authorizationAPI
      .sendQuestionnaire({ data })
      .then((response) => {
        if (response.status === 200) return response;
        throw new Error(response.status);
      })
      .then(() => {
        this.setUserQuestionnaireToChecked();
        this.setError(ACTIONS.SEND_QUESTIONNAIRE, "");
        this.setIsLoading(ACTIONS.SEND_QUESTIONNAIRE, false);

        onSuccess && onSuccess();
      })
      .catch((error) => {
        this.setError(ACTIONS.SEND_QUESTIONNAIRE, error.message);
        this.setIsLoading(ACTIONS.SEND_QUESTIONNAIRE, false);
      });
  }

  setUserQuestionnaireToChecked() {
    this.user.answered_questions = true;
  }

  signIn({ email, password, token, onSuccess }) {
    this.setIsLoading(ACTIONS.SIGN_IN, true);

    email = email.trim();

    authorizationAPI
      .signIn({ email, password, token })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        this.saveUser(data.user);
        this.setError(ACTIONS.SIGN_IN, "");
        this.setIsLoading(ACTIONS.SIGN_IN, false);
        Cookies.set({ name: COOKIES.AUTO_SIGNIN, value: 1 });

        onSuccess && onSuccess();
      })
      .catch((error) => {
        this.setError(ACTIONS.SIGN_IN, error.message);
        this.setIsLoading(ACTIONS.SIGN_IN, false);
      });
  }

  signOut() {
    const token = Cookies.get({ name: COOKIES.AUTHORIZATION });
    this.setValue("user", null);
    Cookies.delete({ name: COOKIES.AUTHORIZATION });
    // Cookies.delete({ name: COOKIES.CART_ID });
    // Cookies.delete({ name: COOKIES.PAYMENT });

    if (token) {
      this.setIsLoading(ACTIONS.SIGN_IN, true);

      authorizationAPI
        .signOut({ token })
        .then((response) => {
          if (response.status === 200) return response.json();
          throw new Error(response.status);
        })
        .then(() => {
          this.setIsLoading(ACTIONS.SIGN_IN, false);
          if (isMobileDevice()) {
            window.location.href = `${location.origin}${APPLICATION_ROUTES.SIGN_IN}`;
          }
        })
        .catch((error) => {
          this.setError(ACTIONS.SIGN_IN, error.message);
          this.setIsLoading(ACTIONS.SIGN_IN, false);
        });
    }
  }

  signUp({
    ageStatus,
    email,
    firstName,
    lastName,
    password,
    phone,
    referralCode,
    onSuccess,
    referredFrom,
    googleToken,
    picture,
  }) {
    this.setIsLoading(ACTIONS.SIGN_UP, true);

    email = email.trim();

    authorizationAPI
      .signUp({
        ageStatus,
        email,
        firstName,
        lastName,
        password,
        phone,
        referralCode,
        referredFrom,
        googleToken,
        picture,
      })
      .then(async (response) => {
        if (response.status === 200) return response.json();
        if (response.status == 422) {
          let data = await response.json();
          throw new Error(JSON.stringify(data));
        }
        throw new Error(response);
      })
      .then(({ data, account_created }) => {
        this.newAccount = account_created;
        this.saveUser(data.user);
        this.setIsLoading(ACTIONS.SIGN_UP, false);
        onSuccess && onSuccess(account_created);
      })
      .catch((error) => {
        let data = JSON.parse(error.message);
        if (data.type) {
          this.setError(ACTIONS.SIGN_UP, data.type);
        } else {
          this.setError(ACTIONS.SIGN_UP, "");
        }
        this.setIsLoading(ACTIONS.SIGN_UP, false);
      });
  }

  updatePassword({ password, token, onSuccess }) {
    this.setIsLoading(ACTIONS.PASSWORD_RECOVERY_UPDATE, true);

    authorizationAPI
      .updatePassword({ password, token })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(() => {
        this.setIsLoading(ACTIONS.PASSWORD_RECOVERY_UPDATE, false);
        onSuccess && onSuccess();
      })
      .catch((error) => {
        this.setError(ACTIONS.PASSWORD_RECOVERY_UPDATE, error.message);
        this.setIsLoading(ACTIONS.PASSWORD_RECOVERY_UPDATE, false);
      });
  }

  verifyEmail({ token, onSuccess }) {
    this.setIsLoading(ACTIONS.VERIFY_EMAIL, true);

    authorizationAPI
      .verifyEmail({ token })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(() => {
        this.setIsLoading(ACTIONS.VERIFY_EMAIL, false);
        onSuccess && onSuccess();
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.VERIFY_EMAIL, message);
        this.setIsLoading(ACTIONS.VERIFY_EMAIL, false);
      });
  }
}

export default Authorization;
