import { makeAutoObservable } from "mobx";

import { AudiosAPI } from "../../api";
import { ACTIONS } from "./utilities";

const audiosApi = new AudiosAPI();

const Audios = class {
  constructor() {
    const actions = Object.values(ACTIONS);

    for (let i = 0; i < actions.length; i++) {
      const ACTION = actions[i];

      this[`isLoading${ACTION}`] = false;
      this[`error${ACTION}`] = "";
    }

    this.playlist = [];

    makeAutoObservable(this);
  }

  get hasPlaylist() {
    return !!this.playlist;
  }

  setIsLoading(name, value) {
    this[`isLoading${name}`] = value;
  }

  setError(name, value) {
    this[`error${name}`] = value;
  }

  setValue(name, value) {
    this[name] = value;
  }

  getPlaylist() {
    this.setIsLoading(ACTIONS.GET_PLAYLIST, true);

    audiosApi
      .getPlaylist()
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        this.setValue("playlist", data);
        this.setIsLoading(ACTIONS.GET_PLAYLIST, false);
      })
      .catch((error) => {
        this.setError(ACTIONS.GET_PLAYLIST, error.message);
        this.setIsLoading(ACTIONS.GET_PLAYLIST, false);
      });
  }
};

export default Audios;
