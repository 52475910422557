import { PROJECTS } from "../../constants";

const getPackageDetails = (id) => {
  switch (id) {
    case PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID:
      return PROJECTS.PARTNERS.PACKAGES.BASIC_TIER;
    case PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID:
      return PROJECTS.PARTNERS.PACKAGES.SILVER_TIER;
    case PROJECTS.PARTNERS.PACKAGES.SILVER_CARD_TIER.ID:
      return PROJECTS.PARTNERS.PACKAGES.SILVER_CARD_TIER;
    case PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID:
      return PROJECTS.PARTNERS.PACKAGES.GOLD_TIER;
    case PROJECTS.PARTNERS.PACKAGES.PLATINUM_TIER.ID:
      return PROJECTS.PARTNERS.PACKAGES.PLATINUM_TIER;
    case PROJECTS.PARTNERS.PACKAGES.PARTNER_TIER.ID:
      return PROJECTS.PARTNERS.PACKAGES.PARTNER_TIER;

    case PROJECTS.MY_MAOR.PACKAGES.SINGLE_LAYER.ID:
      return PROJECTS.MY_MAOR.PACKAGES.SINGLE_LAYER;
    case PROJECTS.MY_MAOR.PACKAGES.DOUBLE_LAYER.ID:
      return PROJECTS.MY_MAOR.PACKAGES.DOUBLE_LAYER;
    case PROJECTS.MY_MAOR.PACKAGES.TRIPLE_DECKER.ID:
      return PROJECTS.MY_MAOR.PACKAGES.TRIPLE_DECKER;
    case PROJECTS.MY_MAOR.PACKAGES.SEVEN_LAYER_EXPLOSION.ID:
      return PROJECTS.MY_MAOR.PACKAGES.SEVEN_LAYER_EXPLOSION;
    default:
      return null;
  }
};

export default getPackageDetails;
