import { ReactComponent as BallonRed } from "../../../assets/svg/balloon-red.svg";
import { ReactComponent as Cake1 } from "../../../assets/svg/cake-1.svg";
import { ReactComponent as Cake2 } from "../../../assets/svg/cake-2.svg";
import { ReactComponent as Cake3 } from "../../../assets/svg/cake-3.svg";
import { ReactComponent as Cake4 } from "../../../assets/svg/cake-4.svg";
import { ReactComponent as ChooseCelebration } from "../../../assets/svg/choose-celebration.svg";
import { ReactComponent as ConfettiGreen } from "../../../assets/svg/confetti-green.svg";
import { ReactComponent as ConfettiOrange } from "../../../assets/svg/confetti-orange.svg";
import { ReactComponent as DotPatternHorizontal } from "../../../assets/svg/dot-pattern-horizontal.svg";
import { ReactComponent as DotPatternVertical } from "../../../assets/svg/dot-pattern-vertical.svg";
import { ReactComponent as Illustration1 } from "../../../assets/svg/birthdayCap.svg";
import { ReactComponent as Illustration2 } from "../../../assets/svg/ballons.svg";
import { ReactComponent as Illustration3 } from "../../../assets/svg/birthdayGift.svg";
import { ReactComponent as Illustration4 } from "../../../assets/svg/popper.svg";
// import { ReactComponent as Illustration5 } from "../../../assets/svg/kids-feature-illustration-5.svg";
// import { ReactComponent as Illustration6 } from "../../../assets/svg/kids-feature-illustration-6.svg";
import { PROJECTS } from "../../../constants";

const Decoration = {
  BallonRed,
  ChooseCelebration,
  ConfettiGreen,
  ConfettiOrange,
  DotPatternHorizontal,
  DotPatternVertical,
};

const features = [
  {
    Illustration: Illustration1,
    title_translation_key: "projects.maor-kids.birthday_announcement",
    text_translation_key: "projects.maor-kids.pick_the_day_and_watch_your_child_etc",
  },
  {
    Illustration: Illustration2,
    title_translation_key: "projects.maor-kids.what_a_zechus",
    text_translation_key: "projects.maor-kids.show_how_much_the_rebbe_means_etc",
  },
  {
    Illustration: Illustration3,
    title_translation_key: "projects.maor-kids.full_party_gift_package",
    text_translation_key: "projects.maor-kids.complete_with_tzedakah_a_treat_birthday_balloons_etc",
  },
  {
    Illustration: Illustration4,
    title_translation_key: "projects.maor-kids.celebration_dedication",
    text_translation_key: "projects.maor-kids.the_whole_family_will_cheer_as_etc",
  },

  // {
  //   Illustration: Illustration5,
  //   title_translation_key: "projects.maor-kids.personal_poster",
  //   text_translation_key: "projects.maor-kids.let_bubby_zaidy_uncle_mendy_shloimy_etc",
  // },
  // {
  //   Illustration: Illustration6,
  //   title_translation_key: "projects.maor-kids.maor_experience",
  //   text_translation_key: "projects.maor-kids.who_creates_a_maor_video_etc",
  // },
];

const packages = [
  {
    cost: PROJECTS.MY_MAOR.PACKAGES.SINGLE_LAYER.COST,
    features: [
      { text_translation_key: "projects.maor-kids.birthday_announcement" },
      { text_translation_key: "projects.maor-kids.personal_poster" },
    ],
    Illustration: Cake1,
    name: PROJECTS.MY_MAOR.PACKAGES.SINGLE_LAYER.NAME,
    title_translation_key: "projects.maor-kids.single_layer",
  },
  {
    cost: PROJECTS.MY_MAOR.PACKAGES.DOUBLE_LAYER.COST,
    features: [
      { text_translation_key: "projects.maor-kids.birthday_announcement" },
      { text_translation_key: "projects.maor-kids.personal_poster" },
      {
        message_translation_key: "projects.maor-kids.with_personalized_message",
        text_translation_key: "projects.maor-kids.full_party_gift_package",
      },
    ],
    isMarked: true,
    Illustration: Cake2,
    name: PROJECTS.MY_MAOR.PACKAGES.DOUBLE_LAYER.NAME,
    title_translation_key: "projects.maor-kids.double_layer",
  },
  {
    cost: PROJECTS.MY_MAOR.PACKAGES.TRIPLE_DECKER.COST,
    features: [
      {
        message_translation_key: "projects.maor-kids.personalized_dedication_at_the_beginning_of_the_video",
        text_translation_key: "projects.maor-kids.celebration_dedication",
        style: "fw-700",
      },
      { text_translation_key: "projects.maor-kids.birthday_announcement" },
      { text_translation_key: "projects.maor-kids.personal_poster" },
      {
        message_translation_key: "projects.maor-kids.with_personalized_message",
        text_translation_key: "projects.maor-kids.full_party_gift_package",
      },
    ],
    Illustration: Cake3,
    name: PROJECTS.MY_MAOR.PACKAGES.TRIPLE_DECKER.NAME,
    title_translation_key: "projects.maor-kids.triple_decker",
  },
  {
    cost: PROJECTS.MY_MAOR.PACKAGES.SEVEN_LAYER_EXPLOSION.COST,
    features: [
      {
        message_translation_key: "projects.maor-kids.personalized_dedication_at_the_beginning_of_the_video",
        text_translation_key: "projects.maor-kids.celebration_dedication",
        style: "fw-700",
      },
      { text_translation_key: "projects.maor-kids.birthday_announcement" },
      { text_translation_key: "projects.maor-kids.personal_poster" },
      {
        message_translation_key: "projects.maor-kids.with_personalized_message",
        text_translation_key: "projects.maor-kids.full_party_gift_package",
      },
      {
        message_translation_key: "projects.maor-kids.live_or_virtual",
        text_translation_key: "projects.maor-kids.tour_of_maor_studios",
      },
    ],
    Illustration: Cake4,
    name: PROJECTS.MY_MAOR.PACKAGES.SEVEN_LAYER_EXPLOSION.NAME,
    title_translation_key: "projects.maor-kids.seven_layer_explosion",
  },
];

export { Decoration, features, packages };
