/* eslint-disable */
import { useCallback, useEffect, useState } from "react";

import { createState, validate } from "./utilities";

const useInput = ({ label, validators = [], value = "" } = {}) => {
  const [state, setState] = useState(createState({ validators, value }));

  useEffect(() => {
    setState((state) => {
      const errors = validate(value, validators);
      if (label == "Last Name *") {
        console.log(label, value);
        console.log("Errors", errors);
      }
      return {
        ...state,
        errors,
        value,
      };
    });
  }, [value]);

  const onChange = useCallback(
    ({ target: { value }, touched = true }) => {
      setState((state) => {
        const errors = validate(value, state.validators);

        return {
          ...state,
          errors,
          touched: touched,
          value,
        };
      });
    },
    [setState]
  );

  const touch = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        touched: true,
      };
    });
  });

  const emptyErrors = useCallback(() => {
    console.log("Empty Errors", emptyErrors);
    setState((state) => {
      return {
        ...state,
        error: '',
        errors: [],
        touched: false,
      };
    })
  });

  return {
    error: state.touched && state.errors.length ? state.errors[0] : "",
    errors: state.errors,
    label: label,
    touched: state.touched,
    value: state.value,
    onChange,
    touch,
    emptyErrors
  };
};

export default useInput;
