import React, { useEffect } from "react";
import PropTypes from "prop-types";

import classes from "./Braintree.module.scss";

const IDENTIFIERS = {
  BUTTON: "braintree-button",
  CONTAINER: "braintree-container",
};

const Braintree = ({ locale, onInitialize, onSaleSuccess }) => {
  useEffect(() => {
    onInitialize({
      buttonSelector: `#${IDENTIFIERS.BUTTON}`,
      containerSelector: `#${IDENTIFIERS.CONTAINER}`,
      locale: locale,
      onSaleSuccess: onSaleSuccess,
    });
  }, []);

  return <div className={classes.container} id={IDENTIFIERS.CONTAINER} />;
};

Braintree.propTypes = {
  locale: PropTypes.string.isRequired,
  onInitialize: PropTypes.func.isRequired,
  onSaleSuccess: PropTypes.func.isRequired,
};

export default React.memo(Braintree);
