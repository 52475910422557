// const LANGUAGES = {
//   HE: "he",
// };

const getDirection = (language) => {
  language;
  // if (language === LANGUAGES.HE) return "rtl";
  return "ltr";
};

export { getDirection };
