/* eslint-disable */
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffect, useContext } from "react";
import { StoreContext } from "../../../../contexts";
// import { ACTIONS } from "../../../../store/paymentmethod/utilities";
import Layout from "../../../templates/Layout";
import cs from "classnames";
import classes from "./PaymentMethod.module.scss";
import Button from "../../../atoms/Button";
import { ACTIONS } from "../../../../store";
import { APPLICATION_ROUTES, PARTNERSHIP_STATUS } from "../../../../constants";
import { Link, useHistory } from "react-router-dom";
// import { PlanCanceled } from "../../../organisms/RestrictionModal/RestrictionModal";
import { useModal } from "../../../../hooks";
import { getPackageDetails } from "../../../../utilities/helpers";
import { useTranslation } from "react-i18next";

const IDENTIFIERS = {
  BUTTON: "braintree-button",
  CONTAINER: "braintree-container",
};

const PaymentMethod = observer(() => {
  const { paymentmethod, authorization, partnership } =
    useContext(StoreContext);
  const history = useHistory();
  const { t } = useTranslation();
  const [currentPlan, setCurrentPlan] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [partnershipLastUpdated, setPartnershipLastUpdated] = useState("");
  const [partnershipExpiration, setPartnershipExpiration] = useState("");
  const cancelAction = useModal();

  useEffect(() => {
    if (authorization.isAuthenticated) {
      paymentmethod.getToken({ onSuccess: showDropin });
    }
  }, [authorization.isAuthenticated]);

  useEffect(() => {
    partnership[`${ACTIONS.PARTNERSHIP.GET_PARTNERSHIP}`]();
  }, []);

  useEffect(() => {
    if (partnership.hasDetails) {
      setCurrentPlan(
        partnership.details["plan"].toLowerCase().replace("-", "_")
      );
      setPartnershipLastUpdated(partnership.details["updated_at"]);
      setPartnershipExpiration(partnership.details["next_payment_date"]);
      setExpirationDate(partnership.details["expiration_date"]);

      // Plan Canceled.
      // if (Date.now() > new Date(partnership.details["expiration_date"])) {
      if (
        partnership.details["status"] == PARTNERSHIP_STATUS.CANCELED &&
        Date.now() > new Date(partnership.details["expiration_date"])
      ) {
        cancelAction.onOpen();
      }
    }
  }, [partnership.details]);

  const addCard = () => {
    paymentmethod.getToken({ onSuccess: showDropin });
  };

  const showDropin = () => {
    paymentmethod.initialize({
      buttonSelector: `#${IDENTIFIERS.BUTTON}`,
      containerSelector: `#${IDENTIFIERS.CONTAINER}`,
      onSuccess: () => {
        history.push(APPLICATION_ROUTES.MY_ACCOUNT.PAYMENT_METHOD_ALL);
      },
    });
  };

  const closeAddCard = () => {
    history.push(APPLICATION_ROUTES.MY_ACCOUNT.PAYMENT_METHOD_ALL);
  };

  const planName = () => {
    if (currentPlan) {
      const packageDetails = getPackageDetails(currentPlan);
      return t(packageDetails.NAME_TRANSLATION_KEY);
    } else {
      return "";
    }
  };

  return (
    <Layout>
      <div className="wrapper-m mt-05 mb-10 text-m-a">
        <div className={cs(classes.headingContainer, "mb-07")}>
          <div className={cs(classes.headingItem, classes.headingItemContent)}>
            <h1 className="">My Payment Methods</h1>
            <div className="mb-05 mt-05 d-none">
              <Button
                disabled={paymentmethod.getIsLoading(
                  ACTIONS.PAYMENTMETHOD.GET_TOKEN
                )}
                size="s"
                onClick={() => {
                  addCard();
                }}
              >
                {paymentmethod.getIsLoading(ACTIONS.PAYMENTMETHOD.GET_TOKEN)
                  ? "Loading..."
                  : "Add Payment Method"}
              </Button>
            </div>
            <div className="mb-02">
              <p className="mt-02 mb-02">
                Put a Payment Method (Credit card or PayPal) on file to make
                checkouts a breeze.
              </p>
              <p className="mt-02 mb-02">
                Your payment info is safely encrypted and secure.
              </p>
              <p className="mt-02 mb-02">
                When you add a new payment method it will automatically become
                the default card that the system will charge. You always have
                the option of manually changing to a different payment at any
                time.
              </p>
            </div>
          </div>
        </div>
        {authorization.isAuthenticated ? (
          <div
            className={cs(
              classes.braintreeContainer,
              paymentmethod.bInitialized && "mb-10"
            )}
          >
            <div id={`${IDENTIFIERS.CONTAINER}`} />
            <div className="d-flex gap-5">
              <Button
                disabled={paymentmethod.getIsLoading(
                  ACTIONS.PAYMENTMETHOD.SAVE
                )}
                // style={{ display: paymentmethod.bInitialized ? "block" : "none" }}
                id={`${IDENTIFIERS.BUTTON}`}
              >
                {paymentmethod.getIsLoading(ACTIONS.PAYMENTMETHOD.SAVE)
                  ? "Loading..."
                  : "Save"}
              </Button>
              <Button
                onClick={() => {
                  closeAddCard();
                }}
                color="ghost"
                // style={{ display: paymentmethod.bInitialized ? "block" : "none" }}
                id={`${IDENTIFIERS.BUTTON}`}
              >
                Close
              </Button>
            </div>
          </div>
        ) : (
          <div className="heading-m">
            Please{" "}
            <Link
              class="c-orange"
              to={
                APPLICATION_ROUTES.SIGN_IN +
                "?redirect=" +
                APPLICATION_ROUTES.MY_ACCOUNT.PAYMENT_METHOD
              }
            >
              Login
            </Link>{" "}
            to add payment method.
          </div>
        )}
      </div>
      {/* {partnership.hasDetails && (
        <PlanCanceled
          popup={cancelAction}
          plan={planName()}
          partnership={partnership}
          expirationDate={new Date(expirationDate)}
        />
      )} */}
    </Layout>
  );
});

export default React.memo(PaymentMethod);
