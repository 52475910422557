import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { getDirection } from "./utilities";

const Head = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const direction = getDirection(language);

  return (
    <Helmet>
      <html dir={direction} />
    </Helmet>
  );
};

export default React.memo(Head);
