/* eslint-disable prettier/prettier */

import { useRef, useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
// import { DELIVERY_COUNTRIES } from "../../../../../constants";
import { StoreContext } from "../../../../../contexts";
import {
  useCheckbox,
  useInput,
  useRadio,
  useSelectR,
  useToggle,
} from "../../../../../hooks";
import { ACTIONS } from "../../../../../store";
import {
  NAMES,
  NAMES as VALIDATIONS_NAMES,
} from "../../../../../utilities/validation";
import { convertToHebrew } from "jewish-dates-core";
import {
  convertJewishEngToJewish,
  formatJewishDate,
  getJewishEnglishMonth,
} from "../../../../../utilities/helpers";
import Hebcal from "hebcal";

let jewishDays = [];
let enDays = [];
for (let i = 1; i <= 30; i++) {
  jewishDays.push({
    label: convertToHebrew(i, false, false),
    value: convertToHebrew(i, false, false),
    englishDay: i,
  });
}

for (let i = 1; i <= 31; i++) {
  enDays.push({
    label: i,
    value: i,
  });
}

const heMonths = {
  0: {
    jewish: "תשרי",
    jewishEng: "Tishrei",
  },
  1: {
    jewish: "חשון",
    jewishEng: "Cheshvan",
  },
  2: {
    jewish: "כסלו",
    jewishEng: "Kislev",
  },
  3: {
    jewish: "טבת",
    jewishEng: "Tevet",
  },
  4: {
    jewish: "שבט",
    jewishEng: "Shevat",
  },
  5: {
    jewish: "אדר",
    jewishEng: "Adar",
  },
  6: {
    jewish: "אדר א",
    jewishEng: "Adar 1",
  },
  7: {
    jewish: "אדר ב",
    jewishEng: "Adar 2",
  },
  8: {
    jewish: "ניסן",
    jewishEng: "Nissan",
  },
  9: {
    jewish: "אייר",
    jewishEng: "Iyyar",
  },
  10: {
    jewish: "סיון",
    jewishEng: "Sivan",
  },
  11: {
    jewish: "תמוז",
    jewishEng: "Tamuz",
  },
  12: {
    jewish: "אב",
    jewishEng: "Av",
  },
  13: {
    jewish: "אלול",
    jewishEng: "Elul",
  },
};

const englishMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

let jewishMonths = [];
let enMonths = [];

for (const key in heMonths) {
  jewishMonths.push({
    label: heMonths[key].jewish,
    value: heMonths[key].jewish,
  });
}

englishMonths.forEach(function (value) {
  enMonths.push({
    label: value,
    value: value,
  });
});

let jewishYears = [];
let enYears = [];
for (let i = 5784; i >= 5769; i--) {
  let year = convertToHebrew(i, true, true);
  let originalYear = i;
  year = year.substring(1, year.length);
  jewishYears.push({
    label: year,
    value: year,
    englishYear: originalYear,
  });
}

const currentYear = new Date().getFullYear();

for (let i = 2009; i <= currentYear; i++) {
  let year = i;
  enYears.push({
    label: year,
    value: year,
  });
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getMonthName = (monthNumber) => {
  if (monthNumber >= 1 && monthNumber <= 12) {
    return months[monthNumber - 1];
  } else {
    return "Invalid month number";
  }
};

const useForm = () => {
  const { persons, authorization } = useContext(StoreContext);
  const [success, setSuccess] = useState(false);

  const successMsg = persons[`success${ACTIONS.PERSONS.ADD_CHILD}`];
  const error = persons[`error${ACTIONS.PERSONS.ADD_CHILD}`];
  const loading = persons[`isLoading${ACTIONS.PERSONS.ADD_CHILD}`];
  const [isCivil, setIsCivil] = useState(true);
  const [isAfterShkiyaChecked, setIsAfterShkiyaChecked] = useState(false);

  const user = authorization.persons ? authorization.persons[0] : [];

  const { t } = useTranslation();
  /* labels */
  const CITY = t("forms.labels.city");
  const COUNTRY = t("forms.labels.country");
  const FIRST_NAME = t("forms.labels.first_name");
  const LAST_NAME = t("forms.labels.last_name");
  const STATE = t("forms.labels.state");
  const BOY = t("forms.labels.boy");
  const GIRL = t("forms.labels.girl");

  const THE_FIELD_MUST_CONTAIN_AT_LEAST_2_SYMBOLS = t(
    "forms.messages.the_field_must_contain_at_least_number_symbols",
    {
      number: 2,
    }
  );

  const THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS = t(
    "forms.messages.the_field_must_contain_only_latin_or_hebrew_characters"
  );

  const THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_SPACES_AND_HYPHENS =
    t(
      "forms.messages.the_field_must_contain_only_latin_or_hebrew_characters_spaces_and_hyphens"
    );
  const THIS_FIELD_IS_REQUIRED = t("forms.messages.this_field_is_required");

  const enDay = useSelectR({
    label: "Day",
    options: enDays,
    value: "",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
  });

  const enMonth = useSelectR({
    label: "Month",
    options: enMonths,
    value: "",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
  });

  const enYear = useSelectR({
    label: "Year",
    options: enYears,
    value: "",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
  });

  const jewishDay = useSelectR({
    label: "Day",
    options: jewishDays,
    value: "",
    validators: [
      {
        name: NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
    ],
  });

  const jewishMonth = useSelectR({
    label: "Month",
    options: jewishMonths,
    value: "",
    validators: [
      {
        name: NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
    ],
  });

  const jewishYear = useSelectR({
    label: "Year",
    options: jewishYears,
    value: "",
    validators: [
      {
        name: NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
    ],
  });

  let initials = {
    details: { born_after_sunset: false },
  };

  const afterShkiyaCheckbox = useCheckbox({
    callback: ({ checked }) => {
      setIsAfterShkiyaChecked(checked);
    },
    checked: false || initials.details.born_after_sunset,
    label: `After Shkiya`,
    value: initials.details.born_after_sunset || isAfterShkiyaChecked,
  });

  useEffect(() => {
    // if (!isCivil) {
    //   afterShkiyaCheckbox.setChecked(false);
    //   setIsAfterShkiyaChecked(false);
    //   initials.details.born_after_sunset = false;
    // }

    if (isCivil) {
      if (initials.details.born_after_sunset == true) {
        setIsAfterShkiyaChecked(true);
      }
    }
  }, [isCivil]);

  const languageToggleBox = useToggle("Civil", ({ selectedValue }) => {
    // Do something with selectedValue when it changes
    if (selectedValue == "Civil") {
      setIsCivil(true);
    } else {
      setIsCivil(false);
    }
  });

  /* client */
  const lastName = useInput({
    label: LAST_NAME,
    validators: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      {
        name: VALIDATIONS_NAMES.MAX_64,
        options: {
          message: t("forms.messages.max_64_characters", {
            value: 64,
            field: t("forms.labels.last_name"),
          }),
        },
      },
      // {
      //   name: VALIDATIONS_NAMES.HEBREW_LATIN_SPACES_HYPHENS,
      //   options: {
      //     message:
      //       THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_SPACES_AND_HYPHENS,
      //   },
      // },
    ],
    value: user
      ? user.last_name
      : authorization
        ? authorization.user.last_name
        : "",
  });

  /* details */
  const firstName = useInput({
    label: FIRST_NAME,
    validators: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      // {
      //   name: VALIDATIONS_NAMES.HEBREW_LATIN_SPACES_HYPHENS,
      //   options: {
      //     message:
      //       THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_SPACES_AND_HYPHENS,
      //   },
      // },
      {
        name: VALIDATIONS_NAMES.MAX_64,
        options: {
          message: t("forms.messages.max_64_characters", {
            value: 64,
            field: t("forms.labels.first_name"),
          }),
        },
      },
    ],
    value: "",
  });

  const city = useInput({
    label: CITY,
    validators: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      {
        name: VALIDATIONS_NAMES.HEBREW_LATIN_SPACES_HYPHENS,
        options: {
          message:
            THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_SPACES_AND_HYPHENS,
        },
      },
    ],
    value: user ? user.city : "",
  });
  const state = useInput({
    label: STATE,
    validators: [
      {
        name: VALIDATIONS_NAMES.HEBREW_LATIN,
        options: {
          message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS,
        },
      },
      {
        name: VALIDATIONS_NAMES.MIN_2,
        options: { message: THE_FIELD_MUST_CONTAIN_AT_LEAST_2_SYMBOLS },
      },
    ],
    value: user ? user.state : "",
  });

  const country = useInput({
    label: COUNTRY,
    value: user ? user.country : "",
  });

  const SEX = {
    BOY: "boy",
    GIRL: "girl",
  };

  const sex = useRadio({
    options: [
      { id: "my-maor-order-form-sex-man", label: BOY, value: SEX.BOY },
      { id: "my-maor-order-form-sex-woman", label: GIRL, value: SEX.GIRL },
    ],
    value: initials.details.sex,
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
  });

  let isEnglishDate = false;

  isEnglishDate =
    enDay.errors.length ||
    enMonth.errors.length ||
    enYear.errors.length ||
    enDay.value.value == undefined ||
    enMonth.value.value == undefined ||
    enYear.value.value == undefined;

  let isJewishDate = false;
  isJewishDate = (
    jewishDay.errors.length ||
    jewishMonth.errors.length ||
    jewishYear.errors.length ||
    jewishDay.value.value == undefined || jewishMonth.value.value == undefined || jewishYear.value.value == undefined)

  // For covert english to Jewsih for Frontend
  let englishDate = `${enDay.value.value} ${enMonth.value.value} ${enYear.value.value}`;
  const jewishDate = formatJewishDate(
    new Date(englishDate),
    isAfterShkiyaChecked
  );
  const englishToJewishDate = convertJewishEngToJewish(jewishDate);

  let jewishToEnglishDate;
  let jewishToEnglishMonth;
  let jewishToEnglishYear;

  let englishToJewishDateArray = englishToJewishDate.split(" ").reverse();
  if (englishToJewishDateArray.length > 3) {
    jewishToEnglishDate =
      englishToJewishDateArray[englishToJewishDateArray.length - 1];
    jewishToEnglishMonth =
      englishToJewishDateArray[2] + " " + englishToJewishDateArray[1];
    jewishToEnglishYear = englishToJewishDateArray[0];
  } else {
    jewishToEnglishDate =
      englishToJewishDateArray[englishToJewishDateArray.length - 1];
    jewishToEnglishMonth = englishToJewishDateArray[1];
    jewishToEnglishYear = englishToJewishDateArray[0];
  }

  useEffect(() => {
    if (!isCivil) {
      jewishDay.onChange({
        label: jewishToEnglishDate,
        value: jewishToEnglishDate,
      });
      jewishMonth.onChange({
        label: jewishToEnglishMonth,
        value: jewishToEnglishMonth,
      });
      jewishYear.onChange({
        label: jewishToEnglishYear,
        value: jewishToEnglishYear,
      });
    }
  }, [isCivil]);

  // Enlish Date For backend Payload
  const englishDateString = new Date(
    `${enDay.value.value} ${enMonth.value.value} ${enYear.value.value}`
  );
  const englishDateDay = englishDateString.getDate();
  const englishDateMonth = englishDateString.getMonth() + 1;
  const englishDateYear = englishDateString.getFullYear();
  const englishFullDate = `${englishDateDay} ${englishDateMonth} ${englishDateYear}`;

  // Convert jewish to jewishEnglish language
  let yearData = "";
  Object.values(jewishYears).find((year) => {
    if (year.value === jewishYear.value.value) {
      yearData = year.englishYear;
    }
  });

  let dayData = "";
  Object.values(jewishDays).find((day) => {
    if (day.value === jewishDay.value.value) {
      dayData = day.englishDay;
    }
  });

  const jewEngLishMonth = getJewishEnglishMonth(jewishMonth.value.value);
  const jewEngLishYear = yearData;
  const jewEngLishDay = dayData;
  const jewishFullDate = `${jewEngLishDay} ${jewEngLishMonth} ${jewEngLishYear}`;

  // Converte JewishEnglish to English
  let heDate = new Hebcal.HDate(jewishFullDate);
  let prevDate = heDate.prev().greg();
  const gregorianDate = heDate.greg();

  const prevMonth = prevDate.getMonth() + 1;
  const prevYear = prevDate.getFullYear();
  const prevDay = prevDate.getDate();

  const gregorianMonth = gregorianDate.getMonth() + 1;
  const gregorianYear = gregorianDate.getFullYear();
  const gregorianDay = gregorianDate.getDate();

  // For backend payload
  const gregorianDateFullDate = `${gregorianDay} ${gregorianMonth} ${gregorianYear}`;
  // For FrontEnd
  const gregorianDateText = `${isAfterShkiyaChecked ? prevMonth : gregorianMonth}/${isAfterShkiyaChecked ? prevDay : gregorianDay}/${isAfterShkiyaChecked ? prevYear : gregorianYear}`;

  const isInitialRender = useRef(true);

  useEffect(() => {
    if (!isInitialRender.current) {
      if (!gregorianDay) {
        enDay.onChange({ label: "", value: "" }, false);
      } else {
        enDay.onChange({ label: isAfterShkiyaChecked ? prevDay : gregorianDay, value: isAfterShkiyaChecked ? prevDay : gregorianDay });
      }
      if (!gregorianMonth) {
        enMonth.onChange({ label: "", value: "" }, false);
      } else {
        enMonth.onChange({ label: isAfterShkiyaChecked ? getMonthName(prevMonth) : getMonthName(gregorianMonth), value: isAfterShkiyaChecked ? getMonthName(prevMonth) : getMonthName(gregorianMonth) });
      }
      if (!gregorianYear) {
        enYear.onChange({ label: "", value: "" }, false);
      } else {
        enYear.onChange({ label: isAfterShkiyaChecked ? prevYear : gregorianYear, value: isAfterShkiyaChecked ? prevYear : gregorianYear });
      }
    } else {
      // Set the flag to false after initial render
      isInitialRender.current = false;
    }
  }, [isCivil]);

  const submitChild = () => {
    persons[`${ACTIONS.PERSONS.ADD_CHILD}`]({
      first_name: firstName.value,
      last_name: lastName.value,
      city: city.value,
      state: state.value,
      country: country.value,
      sex: sex.value,
      date_language: isCivil ? "english" : "hebrew",
      born_after_sunset: isCivil ? isAfterShkiyaChecked : false,
      hebrew_date: isCivil
        ? englishToJewishDate
        : `${jewishDay.value.value} ${jewishMonth.value.value} ${jewishYear.value.value}`,
      date: isCivil
        ? {
          en: englishFullDate,
          he: jewishDate,
        }
        : {
          en: gregorianDateFullDate,
          he: jewishFullDate,
        },
    });
  };

  useEffect(() => {
    setSuccess(persons[`success${ACTIONS.PERSONS.ADD_CHILD}`]);
    persons[`setSuccess`](ACTIONS.PERSONS.ADD_CHILD, false);
  }, [
    persons[`success${ACTIONS.PERSONS.ADD_CHILD}`],
    persons[`isLoading${ACTIONS.PERSONS.ADD_CHILD}`],
    persons,
  ]);

  const isNotValid =
    !country.value ||
    ((country.value.toLowerCase() == "usa" ||
      country.value.toLowerCase() == "us") &&
      !state.value) ||
    !city.value ||
    !firstName.value ||
    !lastName.value ||
    sex.errors.length ||
    sex.value == "" ||
    (isCivil &&
      (enDay.errors.length ||
        enMonth.errors.length ||
        enYear.errors.length ||
        enDay.value.value == undefined ||
        enMonth.value.value == undefined ||
        enYear.value.value == undefined)
    ) ||
    (!isCivil && (
      jewishDay.errors.length ||
      jewishMonth.errors.length ||
      jewishYear.errors.length ||
      jewishDay.errors.length == undefined ||
      jewishMonth.errors.length == undefined ||
      jewishYear.errors.length == undefined
    ));

  return {
    city: city,
    country: country,
    firstName: firstName,
    lastName: lastName,
    state: state,
    success: success,
    submitChild: submitChild,
    isNotValid: isNotValid,
    successMsg: successMsg,
    loading: loading,
    error: error,
    user: user,
    jewishDay: jewishDay,
    jewishMonth: jewishMonth,
    jewishYear: jewishYear,
    gregorianDateText: gregorianDateText,
    enDay: enDay,
    enMonth: enMonth,
    enYear: enYear,
    englishToJewishDate: englishToJewishDate,
    jewishDate: jewishDate,
    jewishFullDate: jewishFullDate,
    isEnglishDate: isEnglishDate,
    languageToggleBox: languageToggleBox,
    afterShkiyaCheckbox: afterShkiyaCheckbox,
    sex: sex,
    isAfterShkiyaChecked: isAfterShkiyaChecked,
    setIsAfterShkiyaChecked: setIsAfterShkiyaChecked,
    isJewishDate: isJewishDate,
  };
};

export default useForm;
