import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import { DIRECTIONS } from "./utilities";
import classes from "./LibraryFilterClear.module.scss";
import ButtonClear from "../../atoms/ButtonClear";
import { isMobile } from "react-device-detect";
import { useContext } from "react";
import { StoreContext } from "../../../contexts";
import { ACTIONS } from "../../../store";
import { useQuery } from "../../../hooks";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";

const LibraryFilterClear = observer(({ direction, filters, onClear, onClick }) => {

  const { freedonoraccess } = useContext(StoreContext);

  const query = useQuery();

  // FreeDonorAccess
  const fda = query.get("fda");
  let fdaSession = "";
  if (navigator.cookieEnabled) {
    fdaSession = sessionStorage.getItem("fda");
  }

  const currentURL = window.location.href;
  let fdaURL = currentURL.replace(/&fda=1/, '');
  fdaURL = fdaURL.replace(/\?fda=1/, '');

  useEffect(() => {
    if (fda || fdaSession) {
      freedonoraccess[`${ACTIONS.FREE_DONOR_ACCESS.GET}`]({
        fdaURL
      })
    }
  }, [onClick])

  return (
    <ul className={cs(classes.container, classes[direction])}>
      {Object.keys(filters).map((key, index) => (
        <li key={`library-${key}-${index}`} className={cs(classes.item, isMobile ? classes.itemMobile : "")}>
          <ButtonClear
            onClear={() => {
              onClear(key);
            }}
          >
            {filters[key]}
          </ButtonClear>
        </li>
      ))}
    </ul>
  );
});

LibraryFilterClear.propTypes = {
  direction: PropTypes.oneOf(Object.values(DIRECTIONS)),
  onClick: PropTypes.func,
  filters: PropTypes.object,
  onClear: PropTypes.func.isRequired,
};

LibraryFilterClear.defaultProps = {
  direction: DIRECTIONS.HORIZONTAL,
};

export default LibraryFilterClear;
