/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import Select from "react-select";
import { ATTRIBUTES, COLORS, SIZES, createID } from "./utilities";

import classes from "./Select.module.scss";

const SelectR = ({ selectStyle, ...properties }) => {
  let customStyles = {};
  let customClass = "";

  switch (selectStyle) {
    case "answers":
      customClass = "dropdown-inner-wrap";
      customStyles = {
        control: (provided, state) => ({
          ...provided,
          padding: "8px 5px",
          borderWidth: "2px",
          borderColor: "#6a7480!important",
          boxShadow: "none!important",
        }),
        indicatorSeparator: (provided, state) => ({
          ...provided,
          backgroundColor: "#2b345e",
        }),
        indicatorContainer: (provided, state) => ({
          ...provided,
          color: "#2b345e",
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          textAlign: "left",
        }),
        option: (provided, state) => ({
          ...provided,
          textAlign: "left",
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: "#636363",
          fontWeight: "bold",
        }),
      };
    break;

    default:
      customStyles = {
        control: (provided, state) => ({
          ...provided,
          padding: "8px 5px",
          borderColor: "#C1BBBB!important",
          boxShadow: "none!important",
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: "#636363",
          fontWeight: "bold",
        }),
      };
  }

  const [isFocused, setIsFocused] = useState(false);

  const onBlur = () => {
    setIsFocused(false);
    properties.onBlurCustom && properties.onBlurCustom();
  };
  const onFocus = () => {
    setIsFocused(true);
    properties.onFocusCustom && properties.onFocusCustom();
  };

  return (
    <>
      <div
        className={cs(classes.container,"child-dropdown", classes[selectStyle], classes[properties.color], classes[properties.size], {
          [classes.stretched]: properties.stretched,
        })}
      >
        <Select
          className={customClass}
          // menuIsOpen={true}
          autoFocus={true}
          onBlur={onBlur}
          onFocus={onFocus}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#FF8A34",
            },
          })}
          styles={customStyles}
          {...properties}
        />
        <span className="PlainInput_indicator__1m49l"></span>
        {!isFocused && properties.error && <div className={cs(classes.tooltip, classes.errorText)}>{properties.error}</div>}
      </div>
    </>
  );
};

SelectR.propTypes = {};

SelectR.defaultProps = {
  color: COLORS.PRIMARY,
  label: "Select",
  size: SIZES.MEDIUM,
  stretched: false,
  value: "",
  useObject: false,
  isSearchable: false,
  selectStyle: "",
};

export default SelectR;
