import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import { COLORS, SIZES } from "./utilities";
import classes from "./SearchBar.module.scss";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { observer } from "mobx-react-lite";
import { isMobile } from "react-device-detect";
import Icon, { NAMES } from "../Icon";

const SearchBar = observer(
  ({
    children: icon,
    error,
    page,
    id,
    type,
    isSearching,
    onSearch,
    searchingOptions,
    onChange,
    value,
    ...properties
  }) => {
    const ref = React.createRef();
    const searchBarRef = useRef(null);
    const [isOutOfViewport, setIsOutOfViewport] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        onChange(e.target.value);
        ref.current.hideMenu();
      }
    };

    useEffect(() => {
      console.log("Searvh Value", value);
      let timer = setTimeout(() => {
        document.querySelector(".rbt-input-main").value = value;
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }, [value]);

    const addClasses = () => {
      if (page == "library") {
        return classes.library;
      }
      if (page == "kids") {
        return classes.kidsLayout;
      }
      if (page == "illuminate") {
        return classes.illuminate;
      }
      if (page == "daily-rebbe-video") {
        return classes.dailyRebbeVideo;
      }
      return classes[page];
    };

    const [isFloatingVisible, setIsFloatingVisible] = useState(false);

    const getOffsetTop = (elem) => {
      let distance = 0;
      if (elem.offsetParent) {
        do {
          distance += elem.offsetTop;
          elem = elem.offsetParent;
        } while (elem);
      }
      return distance < 0 ? 0 : distance;
    };

    const handleScroll = () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const mainSearchBarOffsetTop = getOffsetTop(searchBarRef.current);

      if (scrollTop > mainSearchBarOffsetTop) {
        setIsFloatingVisible(true);
      } else {
        setIsFloatingVisible(false);
      }
    };

    useEffect(() => {
      if (isMobile) {
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }
    }, []);
    console.log(isFloatingVisible, "isFloatingVisible");

    useLayoutEffect(() => {
      const searchBarMain = document.querySelector(".searchBarMain");

      const observeSearchBarTop = () => {
        if ("IntersectionObserver" in window) {
          const observer = new IntersectionObserver((entries) => {
            const [entry] = entries;
            console.log(entries, "entries\n entry", entry, "\n", searchBarMain);
            setIsOutOfViewport(!entry.isIntersecting);
          });

          if (searchBarMain) {
            observer.observe(searchBarMain);
          }

          return () => {
            console.log("search value: inside return");
          };
        }
      };
      observeSearchBarTop();
    }, [searchBarRef]);

    useEffect(() => {
      const handleClickOutside = () => {
        // if (
        //   searchBarRef.current &&
        //   !searchBarRef.current.contains(event.target)
        // ) {
        //   console.log(event.target)
        // }
        setIsClicked(false);
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [searchBarRef]);

    const focusTypeahead = () => {
      const inputElement = document.querySelector(
        ".rbt-input-main.form-control.rbt-input"
      );
      if (inputElement) {
        inputElement.focus();
      }
    };

    const shouldShowFloatingBar = isMobile
      ? isFloatingVisible && isOutOfViewport
      : isOutOfViewport;

    return (
      <>
        <div className={cs(classes.container, addClasses())} ref={searchBarRef}>
          <div className="searchBarMain">
            <AsyncTypeahead
              emptyLabel=""
              ref={ref}
              isLoading={isSearching}
              onSearch={onSearch}
              options={searchingOptions}
              id={id}
              type={type}
              onChange={(value) => {
                onChange(value.pop());
              }}
              onInputChange={(text) => {
                try {
                  let items = document.querySelector(
                    "#async-autocomplete-search"
                  );
                  let firstChild = items.firstChild;
                  if (firstChild.classList.contains("disabled")) {
                    items.style.visibility = "hidden";
                  } else {
                    items.style.visibility = "";
                  }
                } catch (e) {
                  console.warn("Soft Warn:", e);
                }
                if (text == "") {
                  ref.current.clear();
                }
              }}
              defaultInputValue={value}
              onKeyDown={handleKeyDown}
              // inputProps={{
              //   value: value,
              // }}
              // clearButton={true}
              className={cs(classes.asyncWrapper, {
                [classes.error]: error,
                [classes.withIcon]: icon,
              })}
              {...properties}
            />
            {icon && (
              <div
                onClick={focusTypeahead}
                className={cs(classes.icon, { [classes.error]: error })}
              >
                {icon}
              </div>
            )}
            <button
              style={{ display: "none" }}
              id="hiddenAsyncInputClear"
              onClick={() => {
                ref.current.clear();
              }}
            >
              Clear
            </button>
          </div>
        </div>
        {shouldShowFloatingBar && (
          <div
            className={cs(
              classes.floatContainer,
              classes.fixedSearchBar,
              !isMobile && isClicked && classes.widthFull,
              isMobile && !isClicked && classes.mobileFixedSearchBar,
              isMobile && isClicked && classes.mobileWidthFull,
              addClasses()
            )}
            onClick={() => setIsClicked(true)}
          >
            <AsyncTypeahead
              emptyLabel=""
              ref={ref}
              isLoading={isSearching}
              onSearch={onSearch}
              options={searchingOptions}
              id={id}
              type={type}
              onChange={(value) => {
                onChange(value.pop());
              }}
              onInputChange={(text) => {
                try {
                  let items = document.querySelector(
                    "#async-autocomplete-search"
                  );
                  let firstChild = items.firstChild;
                  if (firstChild.classList.contains("disabled")) {
                    items.style.visibility = "hidden";
                  } else {
                    items.style.visibility = "";
                  }
                } catch (e) {
                  console.warn("Soft Warn:", e);
                }
                if (text == "") {
                  ref.current.clear();
                }
              }}
              defaultInputValue={value}
              onKeyDown={handleKeyDown}
              // inputProps={{
              //   value: value,
              // }}
              // clearButton={true}
              className={cs(classes.asyncWrapper, addClasses(), {
                [classes.error]: error,
                [classes.withIcon]: icon,
              })}
              {...properties}
            />
            {icon && (
              <div
                onClick={focusTypeahead}
                className={cs(classes.icon, { [classes.error]: error })}
              >
                {icon}
              </div>
            )}
            {isMobile && isClicked && (
              <div className={cs(classes.crossIcon)}>
                <Icon
                  name={NAMES.CrossButton}
                  size="xs"
                  onClick={() => setIsClicked(false)}
                />
              </div>
            )}
            <button
              style={{ display: "none" }}
              id="hiddenAsyncInputClear"
              onClick={() => {
                ref.current.clear();
              }}
            >
              Clear
            </button>
          </div>
        )}
      </>
    );
  }
);

SearchBar.displayName = "SearchBar";

SearchBar.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(Object.values(COLORS)),
  error: PropTypes.string,
  id: PropTypes.string,
  page: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
  stretched: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.oneOf(["number", "password", "text"]),
  value: PropTypes.string,
  isSearching: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  searchingOptions: PropTypes.array,
};

SearchBar.defaultProps = {
  children: null,
  color: COLORS.PRIMARY,
  error: "",
  page: "",
  size: SIZES.MEDIUM,
  stretched: false,
  label: "",
  type: "text",
  isSearching: false,
  searchingOptions: [],
};

export default SearchBar;

// useEffect(() => {
//   const observer = new IntersectionObserver(
//     (entries) => {
//       const [entry] = entries;
//       setIsOutOfViewport(!entry.isIntersecting);
//     },
//     { rootMargin: "0px 0px -50px 0px" } // Adjust the bottom margin value as needed
//   );

//   if (searchBarRef.current) {
//     const searchBarTop = searchBarRef.current.getBoundingClientRect().top;
//     const observerTarget = document.elementFromPoint(
//       window.innerWidth / 2,
//       searchBarTop
//     );

//     observer.observe(observerTarget);

//     return () => {
//       observer.unobserve(observerTarget);
//     };
//   }
// }, []);
