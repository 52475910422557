const isMobileDevice = () => {
    // console.log("location", location.hostname, process.env.REACT_APP_MOBILE_URL)
    if (location.hostname == process.env.REACT_APP_MOBILE_URL) {
        // if ("mobile.maor.org" == process.env.REACT_APP_BASE_URL) {
        return true;
    } else {
        return false;
    }
}

export default isMobileDevice;