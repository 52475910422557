import React from "react";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import cs from "classnames";

import { COLORS, NAMES, SIZES, getIcon } from "./utilities";
import classes from "./Icon.module.scss";

const Icon = ({ color, name, rotated, size, events, cursorPointer, ...properties }) => {
  const Icon = getIcon(name);

  return (
    <ReactSVG
      className={cs(classes.container, classes[size], classes[color], {
        [classes.rotated]: rotated,
        [classes.withoutEvents]: !events,
        [classes.cursorPointer]: cursorPointer,
      })}
      {...properties}
      data-name={name}
      src={Icon}
    />
  );
};

Icon.propTypes = {
  color: PropTypes.oneOf(Object.values(COLORS)),
  name: PropTypes.oneOf(Object.values(NAMES)).isRequired,
  rotated: PropTypes.bool,
  size: PropTypes.oneOf([...Object.values(SIZES), ""]),
  events: PropTypes.bool,
  cursorPointer: PropTypes.bool,
};

Icon.defaultProps = {
  color: COLORS.DEFAULT,
  rotated: false,
  size: SIZES.M,
  events: true,
  cursorPointer: false,
};

export default Icon;
