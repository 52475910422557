/* eslint-disable prettier/prettier */

import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { StoreContext } from "../../../contexts";
import { useDoubleInput, useInput, useQuery } from "../../../hooks";
import { ACTIONS } from "../../../store";
import { NAMES as VALIDATIONS_NAMES } from "../../../utilities/validation";

const useForm = ({ mailinglistID }) => {
  const { persons, authorization, mailinglist } = useContext(StoreContext);
  const [success, setSuccess] = useState(false);
  const query = useQuery();

  const successMsg = persons[`success${ACTIONS.PERSONS.ADD_CHILD}`];
  // const error = persons[`error${ACTIONS.PERSONS.ADD_CHILD}`];
  const loading = persons[`isLoading${ACTIONS.PERSONS.ADD_CHILD}`];

  const user = authorization.persons ? authorization.persons[0] : [];

  let firstNameRegistered = query.get("name");
  let lastNameRegistered = query.get("last_name");
  let emailRegistered = query.get("email");

  const { t } = useTranslation();

  /* labels */
  const FIRST_NAME = t("pages.mymaor.forms.labels.first_name");
  const LAST_NAME = t("pages.mymaor.forms.labels.last_name");
  const EMAIL = t("pages.mymaor.forms.labels.email");
  const CONFIRM_EMAIL = t("forms.labels.confirm_email");
  const THIS_EMAIL_IS_NOT_VALID = t("forms.messages.this_email_is_not_valid");

  // const THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_SPACES_AND_HYPHENS = t(
  //   "forms.messages.the_field_must_contain_only_latin_or_hebrew_characters_spaces_and_hyphens"
  // );
  const THIS_FIELD_IS_REQUIRED = t("forms.messages.this_field_is_required");
  const THE_FIELD_DOES_NOT_MATCH = t("forms.messages.the_field_does_not_match");

  /* client */
  const lastName = useInput({
    label: LAST_NAME,
    validators: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      {
        name: VALIDATIONS_NAMES.MAX_64,
        options: {
          message: t("forms.messages.max_64_characters", {
            value: 64,
            field: t("organisms.mailingmodal.forms.labels.last_name"),
          }),
        },
      },
      // {
      //   name: VALIDATIONS_NAMES.HEBREW_LATIN_SPACES_HYPHENS,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_SPACES_AND_HYPHENS,
      //   },
      // },
    ],
    // value: user
    //   ? user.last_name ?? ""
    //   : authorization
    //   ? authorization.user.last_name ?? ""
    //   : "",
    value: lastNameRegistered ? lastNameRegistered : "",
  });

  /* details */
  const firstName = useInput({
    label: FIRST_NAME,
    validators: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      // {
      //   name: VALIDATIONS_NAMES.HEBREW_LATIN_SPACES_HYPHENS,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_SPACES_AND_HYPHENS,
      //   },
      // },
      {
        name: VALIDATIONS_NAMES.MAX_64,
        options: {
          message: t("forms.messages.max_64_characters", {
            value: 64,
            field: t("organisms.mailingmodal.forms.labels.first_name"),
          }),
        },
      },
    ],
    value: firstNameRegistered ? firstNameRegistered : "",
  });

  // const email = useInput({
  //   label: EMAIL,
  //   validators: [
  //     {
  //       name: VALIDATIONS_NAMES.REQUIRED,
  //       options: { message: THIS_FIELD_IS_REQUIRED },
  //     },
  //     { name: VALIDATIONS_NAMES.EMAIL, options: { message: THIS_EMAIL_IS_NOT_VALID } },
  //     {
  //       name: VALIDATIONS_NAMES.MAX_64,
  //       options: {
  //         message: t("forms.messages.max_64_characters", {
  //           value: 64,
  //           field: t("organisms.mailingmodal.forms.labels.email"),
  //         }),
  //       },
  //     },
  //   ],
  //   value: "",
  // });

  let email = useDoubleInput({
    first: "Original",
    second: "Confirm",
    labelOfFirst: EMAIL,
    labelOfSecond: CONFIRM_EMAIL,
    validatorsOfFirst: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      {
        name: VALIDATIONS_NAMES.EMAIL,
        options: { message: THIS_EMAIL_IS_NOT_VALID },
      },
    ],
    validatorsOfSecond: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      {
        name: VALIDATIONS_NAMES.EMAIL,
        options: { message: THIS_EMAIL_IS_NOT_VALID },
      },
      {
        name: VALIDATIONS_NAMES.EQUAL,
        options: { message: THE_FIELD_DOES_NOT_MATCH },
      },
    ],
    emailValue: emailRegistered ? emailRegistered : "",
  });

  const submitMailingList = () => {
    mailinglist.save({
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.valueOfOriginal,
      mailinglistID: mailinglistID,
    });
  };

  useEffect(() => {
    setSuccess(persons[`success${ACTIONS.PERSONS.ADD_CHILD}`]);
    persons[`setSuccess`](ACTIONS.PERSONS.ADD_CHILD, false);
  }, [
    persons[`success${ACTIONS.PERSONS.ADD_CHILD}`],
    persons[`isLoading${ACTIONS.PERSONS.ADD_CHILD}`],
    persons,
  ]);

  const isValidEmail =
    email.errorsOfOriginal.length + email.errorsOfConfirm.length;

  const isNotValid =
    firstName.errors.length || lastName.errors.length || isValidEmail;

  return {
    firstName: firstName,
    lastName: lastName,
    mailinglistID: mailinglistID,
    email: email,
    success: success,
    isLoading: mailinglist.getIsLoading(ACTIONS.MAILINGLIST.SAVE),
    submitMailingList: submitMailingList,
    isNotValid: isNotValid,
    successMsg: successMsg,
    done: mailinglist.getSuccess(ACTIONS.MAILINGLIST.SAVE),
    loading: loading,
    error: mailinglist.getError(ACTIONS.MAILINGLIST.SAVE),
    user: user,
    mailinglist: mailinglist,
  };
};

export default useForm;
