import React from "react";
import cs from "classnames";
// import PartnersPackagesSection from "../../organisms/PartnersPackages";
// import Packages from "../../organisms/PartnersPackages/Packages";
import PartnersSection from "../../organisms/PartnersSection";

const PartnersPackages = () => {
  return (
    <main className={cs("minh-100vh pt-10")}>
      {/* <Packages /> */}
      {/* <PartnersPackagesSection /> */}
      <PartnersSection />
    </main>
  );
};

export default PartnersPackages;
