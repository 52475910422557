const ATTRIBUTES = {
  PACKAGE: "data-cr-popover",
};

const POSITIONS = {
  CENTER_BOTTOM_OUT: "center bottom-out",
  CENTER_BOTTOM: "center bottom",
  CENTER_CENTER: "center center",
  CENTER_TOP: "center top",
  CENTER_TOP_OUT: "center top-out",
  LEFT_BOTTOM_OUT: "left bottom-out",
  LEFT_BOTTOM: "left bottom",
  LEFT_CENTER: "left center",
  LEFT_TOP: "left top",
  LEFT_TOP_OUT: "left top-out",
  LEFT_OUT_BOTTOM_OUT: "left-out bottom-out",
  LEFT_OUT_BOTTOM: "left-out bottom",
  LEFT_OUT_CENTER: "left-out center",
  LEFT_OUT_TOP: "left-out top",
  LEFT_OUT_TOP_OUT: "left-out top-out",
  RIGHT_BOTTOM_OUT: "right bottom-out",
  RIGHT_BOTTOM: "right bottom",
  RIGHT_CENTER: "right center",
  RIGHT_TOP: "right top",
  
  RIGHT_TOP_OUT: "right top-out",
  RIGHT_OUT_BOTTOM_OUT: "right-out bottom-out",
  RIGHT_OUT_BOTTOM: "right-out bottom",
  RIGHT_OUT_CENTER: "right-out center",
  RIGHT_OUT_TOP: "right-out top",
  RIGHT_OUT_TOP_OUT: "right-out top-out",
  RIGHT_TOP_MOBILE: "right top-mobile"
};

const WIDTH = {
  DEFAULT: "default",
  TARGET: "target",
};

export { ATTRIBUTES, POSITIONS, WIDTH };
