import React, { useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

import Button from "../../atoms/Button";
import Loader from "../../atoms/Loader";
import Logotype from "../../atoms/Logotype";
import PageTitle from "../../atoms/PageTitle";
import PasswordInput from "../../atoms/PasswordInput";
import Modal from "../../organisms/Modal";
import { APPLICATION_ROUTES, PAGE_TITLES } from "../../../constants";
import { StoreContext } from "../../../contexts";
import useForm from "./useForm";
import { getTranslationKeyOfError } from "./utilities";

const PasswordRecoveryUpdate = observer(() => {
  const { authorization } = useContext(StoreContext);
  const { isValid, modal, password, onSubmit } = useForm({
    authorization,
  });
  const { t } = useTranslation();

  const history = useHistory();
  const navigateLogin = useCallback(() => {
    history.push(APPLICATION_ROUTES.SIGN_IN);
  }, []);

  return (
    <>
      <PageTitle value={PAGE_TITLES.PASSWORD_RECOVERY} />
      <main className="bg-gold-gradient--horizontal c-grey">
        <div className="wrapper-s wrapper-vertical-s-a d-flex fd-column ai-center minh-100vh">
          <div>
            <Logotype />
          </div>
          <h2 className="heading-m-a mt-11 ta-center c-blue-dark">
            {t("pages.authorization.password_recovery_update.titles.1")}
          </h2>
          <form autoComplete="off" className="d-flex fd-column ai-center mt-07 w-100p" onSubmit={onSubmit}>
            <div className="w-100p" style={{ maxWidth: "320px" }}>
              <PasswordInput
                error={password.errorOfOriginal}
                id="password-recovery-update-form-password"
                label={password.labelOfOriginal}
                value={password.valueOfOriginal}
                onChange={password.onChangeOriginal}
              />
            </div>
            <div className="mt-06 w-100p" style={{ maxWidth: "320px" }}>
              <PasswordInput
                error={password.errorOfConfirm}
                id="password-recovery-update-form-password-repeat"
                label={password.labelOfConfirm}
                value={password.valueOfConfirm}
                onChange={password.onChangeConfirm}
              />
            </div>
            <div className="mt-07">
              <Button disabled={!isValid || authorization.isLoadingPasswordRecoveryUpdate} type="submit">
                {t("pages.authorization.password_recovery_update.labels.1")}
              </Button>
            </div>
          </form>
          {authorization.isLoadingPasswordRecoveryUpdate && (
            <div className="mt-05">
              <Loader />
            </div>
          )}
          {authorization.errorPasswordRecoveryUpdate && (
            <div className="text-l mt-07 w-100p ta-center c-red" style={{ maxWidth: "320px" }}>
              {t(getTranslationKeyOfError(authorization.errorPasswordRecoveryUpdate))}
            </div>
          )}
          <Modal disableOverlay={true} opened={modal.isOpened}>
            <div className="wrapper-s pt-12 pb-12">
              <div className="br-06 pt-10 pb-10 pr-06 pl-06 bgc-white">
                <h3 className="heading-l-a ta-center c-blue-dark">
                  {t("pages.authorization.password_recovery_update.titles.2")}
                </h3>
                <div className="d-flex jc-center mt-07">
                  <Button onClick={navigateLogin}>{t("pages.authorization.password_recovery_update.labels.2")}</Button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </main>
    </>
  );
});

export default PasswordRecoveryUpdate;
