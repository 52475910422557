import React, { useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { scroller } from "react-scroll";
import { Trans, useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
// import Countdown from "react-countdown";
import cs from "classnames";

import Icon from "../../atoms/Icon";
// import Loader from "../../atoms/Loader";
import flameSRC from "../../../assets/images/flame.png";
import { APPLICATION_ROUTES } from "../../../constants";
import { StoreContext } from "../../../contexts";
import { ACTIONS } from "../../../store";
// import Clock from "./components/Clock";
import { getErrorTranslationKey } from "./utilities";
import classes from "./CampaignBanner.module.scss";

const CampaignBanner = observer(() => {
  const { products } = useContext(StoreContext);

  const history = useHistory();
  const onNavigateToPartnersPackagesSection = useCallback(() => {
    Promise.resolve()
      .then(() => {
        history.push(APPLICATION_ROUTES.PARTNERS_MAIN);
      })
      .then(() => {
        scroller.scrollTo("packages", { duration: 1000, smooth: true, offset: -24 });
      })
      .catch(console.error);
  }, []);

  const { t } = useTranslation();
  const ERROR_MESSAGE = t(getErrorTranslationKey(products[`error${ACTIONS.PRODUCTS.GET_CAMPAIGN}`]));

  // const D = t("components.organisms.campaign_banner.labels.2");
  // const H = t("components.organisms.campaign_banner.labels.3");
  // const M = t("components.organisms.campaign_banner.labels.4");
  // const S = t("components.organisms.campaign_banner.labels.5");
  // const TITLES = { D, H, M, S };

  // const date = new Date(products.hasCampaign ? products.campaign.expiration_date : "Tue Jun 01 2021 15:49:57 GMT+0300");

  if (products[`error${ACTIONS.PRODUCTS.GET_CAMPAIGN}`] === "404") {
    return null;
  }

  if (products[`error${ACTIONS.PRODUCTS.GET_CAMPAIGN}`]) {
    return (
      <div className={classes.container}>
        <div className={classes.title}>{ERROR_MESSAGE}</div>
      </div>
    );
  }

  // if (products.hasNoCampaign && products[`isLoading${ACTIONS.PRODUCTS.GET_CAMPAIGN}`]) {
  //   return (
  //     <div className={classes.container}>
  //       <Loader />
  //     </div>
  //   );
  // }

  if (products.hasNoCampaign) {
    return null;

    // return (
    //   <div className={classes.container}>
    //     <div className={classes.title}>{A_NEW_CAMPAIGN_WILL_BE_LAUNCHED_SHORTLY}</div>
    //   </div>
    // );
  }

  return (
    <div className={cs(classes.container, "text-l-a")}>
      <div className={classes.icon}>
        <img alt="flame" className={classes.image} src={flameSRC} />
      </div>
      <div className={classes.number}>
        <div className={classes.icon}>
          <Icon name="users" />
        </div>
        <div className="ml-02">
          <Trans
            components={{ a: <b /> }}
            i18nKey="components.organisms.campaign_banner.texts.1"
            // values={{ value: products.joinedMembers }}
            values={{ value: products.totalActiveMembers }}
          />
          {/* {t("components.organisms.campaign_banner.texts.1", {
            value: products.joinedMembers,
          })} */}
        </div>
      </div>
      {/* {!products.campaign.is_expired && products.hasCampaignExpirationDate && (
        <div className={classes.timer}>
          <div className={classes.icon}>
            <Icon name="clock" />
          </div>
          <div className="ml-02">
            {t("components.organisms.campaign_banner.texts.2")}{" "}
            <Countdown date={date} intervalDelay={1000} renderer={Clock} titles={TITLES} />
          </div>
        </div>
      )} */}
      <div className="ml-07">
        <button className={classes.button} onClick={onNavigateToPartnersPackagesSection}>
          {t("components.organisms.campaign_banner.labels.1")}
        </button>
      </div>
    </div>
  );
});

export default CampaignBanner;
