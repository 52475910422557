import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";

import Order from "../../templates/Order";
import useOrder from "./useOrder";
import classes from "./PartnersOrder.module.scss";
import { scrollToTop } from "../../../utilities/helpers";
import { StoreContext } from "../../../contexts";

const PartnersOrder = observer(() => {
  const options = useOrder();
  const { cart } = useContext(StoreContext);
  
  console.log("Options", options);
  
  useEffect(() => {
    cart.get();
    scrollToTop();
  }, []);

  return (
    <div className={classes.container}>
      <Order options={options} type="partners" />
    </div>
  );
});

export default PartnersOrder;
