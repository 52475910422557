import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { EmailShareButton, WhatsappShareButton } from "react-share";
import cs from "classnames";

import Icon, { NAMES } from "../../atoms/Icon";
import { APPLICATION_ROUTES, COOKIES } from "../../../constants";
import { StoreContext } from "../../../contexts";
import classes from "./Share.module.scss";

const Share = observer(({ path }) => {
  const { authorization } = useContext(StoreContext);
  const { t } = useTranslation();

  if (authorization.isNotAuthenticated) {
    return null;
  }

  const origin = window.location.origin;
  const url = `${origin}${path}?${COOKIES.INVITING_USER_ID}=${authorization.user.id}`;

  return (
    <div className={cs(classes.container, "d-flex ai-center")}>
      <div className="mr-04">{t("components.molecules.share.texts.1")}</div>
      <ul className={cs(classes.list, "d-flex ai-center list-style-type-none m-0 p-0 c-orange")}>
        <li className={classes.item}>
          <EmailShareButton
            body={t("components.molecules.share.texts.3")}
            subject={t("components.molecules.share.texts.2")}
            url={url}
          >
            <Icon name={NAMES.LETTER} size="m" />
          </EmailShareButton>
        </li>
        <li className={classes.item}>
          <WhatsappShareButton title={t("components.molecules.share.texts.2")} url={url}>
            <Icon name={NAMES.WHATSAPP} size="m" />
          </WhatsappShareButton>
        </li>
      </ul>
    </div>
  );
});

Share.propTypes = {
  path: PropTypes.string,
};

Share.defaultProps = {
  path: APPLICATION_ROUTES.PARTNERS_MAIN,
};

export default Share;
