const COLORS = {
  PRIMARY: "primary",
  PURPLE: "purple",
};

const SIZES = {
  SMALL: "s",
  MEDIUM: "m",
};

export { COLORS, SIZES };
