import { makeAutoObservable } from "mobx";

import { TrackDonationAPI } from "../../api";
import { ACTIONS } from "./utilities.js";

const trackDonationAPI = new TrackDonationAPI();

const TrackDonation = class {
  constructor() {
    const actions = Object.values(ACTIONS);

    for (let i = 0; i < actions.length; i++) {
      const ACTION = actions[i];

      this[`isLoading${ACTION}`] = false;
      this[`error${ACTION}`] = "";
    }

    this.data = {
      collected: 0,
      funds_target: 35000,
      collected_percentage: 0,
      records: [],
    };

    makeAutoObservable(this);
  }

  getFormmatedAmount(price) {
    return "$" + price.toLocaleString();
  }

  setIsLoading(name, value) {
    this[`isLoading${name}`] = value;
  }

  setError(name, value) {
    this[`error${name}`] = value;
  }

  setValue(name, value) {
    this[name] = value;
  }

  get() {
    this.setIsLoading(ACTIONS.GET, true);

    trackDonationAPI
      .get()
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        this.setValue("data", data);
        this.setIsLoading(ACTIONS.GET, false);
      })
      .catch((error) => {
        this.setError(ACTIONS.GET, error.message);
        this.setIsLoading(ACTIONS.GET, false);
      });
  }
};

export default TrackDonation;
