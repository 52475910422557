import Validation from "../../../utilities/validation";

const validate = (value, validators) => {
  if (!validators.length) return [];

  try {
    if (value != undefined) {
      value = value.trim();
    }
  } catch (e) {
    console.log(e);
  }
  const validation = new Validation({ value });

  validators.forEach(({ name, options, validate }) => {
    if (validation[name]) {
      validation[name](options);
      return;
    }

    validate.call(validation, options);
  });

  return validation.errors;
};

export default validate;
