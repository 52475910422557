/* eslint-disable prettier/prettier */
import React, { useCallback, useContext, useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import cs from "classnames";


import { APPLICATION_ROUTES, COOKIES } from "../../../../../../../constants";
import { StoreContext } from "../../../../../../../contexts";
import Braintree from "./components/Braintree";
import Cookies from "../../../../../../../utilities/cookies";
import classes from "./Gateway.module.scss";
import Icon, { NAMES } from "../../../../../../atoms/Icon";

import Modal from "../../../../../../organisms/Modal";
import SignInPage from "../../../../../SignInPage";
// import { PROJECTS } from "../../../../../../../constants";

const Gateway = observer(({ hasErrorMessage }) => {
  const onNavigateToThankYou = useCallback(() => {
    // history.push(APPLICATION_ROUTES.PARTNERS_SUCCESS);
    Cookies.set({ name: COOKIES.ADD_ANOTHER_CHILD, value: 0 });
    window.location = APPLICATION_ROUTES.MY_MAOR_SUCCESS;
  }, []);

  const {
    i18n: { language },
  } = useTranslation();

  const { paymentmethod, checkout, common } = useContext(StoreContext);

  const onInitialize = useCallback((...args) => {
    checkout.initializeBirthdayTransaction(...args);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const isAuthorize = common.get("isAuthorize");
  
  useEffect(() => {
    paymentmethod.get({});
  }, []);

  return (
    <>
      {!isAuthorize && (
        <p>
          {paymentmethod.items.length == 0 ? <>Choose a way to pay</> : ""}
          {paymentmethod.items.length > 0 && (
            <span>
              
              <u
                style={{
                  color: "#FF8A34",
                  cursor: "pointer",
                  fontWeight: "600",
                }}
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >Authorize
              </u> {isModalOpen}
              to use your saved payment method.
            </span>
          )}
        </p>
      )}

      <Modal opened={isModalOpen}>
        <div className={classes.wrapper}>
          <div className={classes.card}>
            <span className={classes.close}>
              <Icon
                name={NAMES.X}
                className={classes.crossStyle}
                onClick={() => setIsModalOpen(false)}
              />
            </span>
            <SignInPage reauthenticate={true} setClosePopUp={setIsModalOpen} />
          </div>
        </div>
      </Modal>

      <Braintree
        locale={language}
        onInitialize={onInitialize}
        onSaleSuccess={onNavigateToThankYou}
      />

{hasErrorMessage ? <p className={cs(classes.errorMessage)}> {hasErrorMessage} </p> : ""}

    </>
  );
});

export default Gateway;
