const getErrorTranslationKey = (value) => {
  switch (value) {
    case "404":
      return "components.organisms.campaign_banner.texts.3";
    default:
      return "generals.warnings.1";
  }
};

export { getErrorTranslationKey };
