/* eslint-disable */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { useTranslation, Trans } from "react-i18next";

import Ranger from "../../atoms/Ranger";
import Datepicker from "../../molecules/Datepicker";
import TimePassedCounter from "../../molecules/TimePassedCounter";
import Tooltip from "../../molecules/Tooltip";
import Playlist from "../../organisms/Playlist";
import Popover, { usePopover } from "../../organisms/Popover";
import Select from "react-select";

import Layout from "../../templates/Layout";
import { useDatepicker, usePhone, useRanger, useSelect } from "../../../hooks";
import { calculateDateDifference, formatJewishDate } from "../../../utilities/helpers";
import classes from "./Test.module.scss";
import Switch from "../../atoms/Switch";
import { StoreContext } from "../../../contexts";
import useVideo from "../Library/components/Project/useVideo";
import cs from "classnames";
import Loader from "../../atoms/Loader";
import Icon from "../../atoms/Icon";
import { getURL } from "../../pages/Library/components/Project/components/Preview/utilities";
// import placeholder from "../../../../assets/images/video-thumbnail-placeholder.png";
import placeholder from "../../../assets/images/video-thumbnail-placeholder.png";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import InfiniteScroll from "react-infinite-scroll-component";
import { convertToHebrew, getHebJewishMonths, getJewishMonths } from "jewish-dates-core";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import PlainInput from "../../atoms/PlainInput";

const Text = () => {
  return (
    <p>
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
      industry&apos;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
      scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
      electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
      Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus
      PageMaker including versions of Lorem Ipsum.
    </p>
  );
};

const DatePickerDemo = observer(() => {
  const heDatepicker = useDatepicker({
    label: "Birthday",
    useHeTranslation: true,
    value: new Date(2015, 0, 1),
    promptSunsetCheck: true,
    bornAfterSunset: false,
  });

  const enDatepicker = useDatepicker({
    label: "Birthday",
    useHeTranslation: false,
    value: new Date(2015, 0, 1),
    promptSunsetCheck: true,
    bornAfterSunset: false,
  });

  return (
    <div class="d-flex m-10 jc-center gap-5">
      <div><Datepicker id="date1" {...heDatepicker} /></div>
      <div><Datepicker id="date2" {...enDatepicker} /></div>
    </div>
  );
});

const TestPage = observer(() => {
  // return formatJewishDate(new Date(2009, 5, 22), false, true);
  const [street, setStreet] = useState(" test 2");
 
  const handlePlaceSelected = (place) => {
    setStreet("Test 1");
    document.querySelector(".pac-target-input").value = "Teest 1";
  }

  return (
    <>
      <div style={{ "margin": "10px" }}>
       <PlainInput />
      </div>
      
      <Autocomplete
        options={{
          types: ["address"],
          fields: [
            "address_components",
            "geometry.location",
            "place_id",
            "formatted_address",
          ],
        }}
        defaultValue={street}
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        onPlaceSelected={handlePlaceSelected}
        placeholder="Search location"
        style={{ width: "100%", height: "45px", borderRadius: "8px", borderColor: "#C1BBBB", color: "#636363", borderWidth: "1px", borderStyle: "solid", padding: "17px 16px", fontSize: "18px", fontWeight: "bold" }}
      />
      <DatePickerDemo />
    </>
  )
  // console.log(getJewishMonths(5782, true));
  // let h = getHebJewishMonths().map((value) => <h1>{value.text}</h1>);
  // return h;


  let heYears = [];
  for (let i = 5760; i <= 5782; i++) {
    heYears.push(i);
  }

  let str = heYears.map((value) => {
    let year = convertToHebrew(value, true, true);
    year = year.slice(1, year.length);
    return (
      <h2 className='ml-10 mb-05'>{value}  : {year}</h2>
    )
  });
  return str;
  return (
    <p>{str}</p>
  );

  let heDays = {};
  for (let i = 1; i <= 12; i++) {
    heDays[convertToHebrew(i)] = i;
  }
  console.log("********");
  console.log(JSON.stringify(heYears));
  console.log(JSON.stringify(heDays));
  console.log("********");
  const datepicker = useDatepicker({
    label: "Date",
  });
  // const { t } = useTranslation();
  const phone = usePhone({ validators: [{ name: "required" }] });
  const select = useSelect({ value: "Hello, world!", options: ["Hello, world", "Goodbye, world", "Learn React"] });

  const id = "my-target";
  const popover = usePopover({ selector: `#${id}` });

  const ranger = useRanger({ labels: ["1", "2", "3", "4", "6", "12"], max: 6, min: 1, values: [1] });
  const [checked, setChecked] = useState(false);

  const { playlist, authorization } = useContext(StoreContext);
  const [items, setItems] = useState([]);
  const video = useVideo();
  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];
  const [selectedOption, setSelectedOption] = useState(null);
  return (
    <Layout>
      <div className="wrapper-xl">
        {/* <Switch
          checked={checked}
          handleToggle={() => {
            setChecked(!checked);
          }}
        /> */}

        <Select
          defaultValue={selectedOption}
          onChange={setSelectedOption}
          options={options}
        />

        <div className={classes.test}>Hello, world!</div>
        <Text />
        <div style={{ maxWidth: "480px" }}>{/* <Playlist /> */}</div>
        <Text />
        <Tooltip message="Goodbye, world!">
          <div>Hello, world!</div>
        </Tooltip>
        <TimePassedCounter end={new Date("2021-05-08")} start={new Date("2021-05-07")} />
        <Datepicker {...datepicker} id="test-page-datepicker" />
        <Text />
        <Select options={select.options} value={select.value} onChange={select.onChange} />
        <Ranger
          labels={ranger.labels}
          max={ranger.max}
          min={ranger.min}
          step={ranger.step}
          values={ranger.values}
          onChange={ranger.onChange}
        />
        <Text />
        <div id={id} onClick={popover.open}>
          Hello, world!
        </div>
        <Popover close={popover.close} style={popover.style}>
          <p style={{ backgroundColor: "orange" }} onClick={popover.close}>
            GoodBye, world!
          </p>
        </Popover>
      </div>
    </Layout>
  );
});

export default React.memo(TestPage);
