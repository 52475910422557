import React, { useCallback, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import cs from "classnames";

import Icon from "../../../../../../atoms/Icon";
import IconButton from "../../../../../../atoms/IconButton";
import Modal from "../../../../../../organisms/Modal";
import {
  APPLICATION_ROUTES,
  PARTNERSHIP_STATUS,
  PROJECTS,
} from "../../../../../../../constants";
import {
  useDateFormat,
  useIsDateWithinTwoMonths,
  useModal,
  useQuery,
} from "../../../../../../../hooks";
import ModalBody from "../ModalBody";
import { TYPES, getPackageView } from "./utilities";
import classes from "./PartnersItem.module.scss";
import { getPackageDetails } from "../../../../../../../utilities/helpers";
import { StoreContext } from "../../../../../../../contexts";
import { ACTIONS } from "../../../../../../../store";
// import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const PartnersItem = ({
  full_credit,
  data,
  interval,
  item_id,
  isLoadingDelete,
  price,
  next_auto_renewal_date,
  client_membership_expiration,
  credit_expiration,
  product_code,
  onDelete,
  purchase_details,
  upgrade_cost,
}) => {
  console.log("upgrade_cost", upgrade_cost)
  const [currentPlan, setCurrentPlan] = useState("");
  const [currentInterval, setCurrentInterval] = useState("");
  const [currentPrice, setCurrentPrice] = useState("");
  const { partnership } = useContext(StoreContext);

  const modal = useModal();

  const query = useQuery();
  const from = query.get("from");
  // const {plan, interval} = useParams();

  // const product_code = plan;
  console.log(price, full_credit, purchase_details, "interval");
  const history = useHistory();
  const onEdit = useCallback(({ id, type }) => {
    if (from === "donation")
      return history.push(
        `${APPLICATION_ROUTES.PARTNERS_DONATION}?type=${type}&id=${id}`
      );

    // const duration = interval == 12 ? "anually" : "monthly";
    history.push(
      `${APPLICATION_ROUTES.PARTNERS_ORDER}/${type}/${interval}?id=${id}`
    );
  }, []);

  const { t } = useTranslation();

  const view = getPackageView(product_code);

  const pricePerRecurring = price;
  let billingCycle = "monthly";

  if (interval == 6) {
    billingCycle = interval + " months";
  } else if (interval == 12) {
    billingCycle = " yearly";
  }

  const getPlanBenefits = () => {
    if (product_code) {
      const packageDetails = getPackageDetails(product_code);
      if (
        currentPlan &&
        packageDetails.ID == PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID
      ) {
        return (
          <Trans
            components={{
              a: <b />,
              value: packageDetails.CREDITS_TOTAL,
            }}
            i18nKey="pages.checkout.texts.5"
            t={t}
          />
        );
      } else {
        return (
          <Trans
            components={{
              a: <b />,
            }}
            i18nKey={packageDetails.BENEFITS_TRANSLATION_KEY}
            t={t}
          />
        );
      }
    }
    return "";
  };

  useEffect(() => {
    partnership[`${ACTIONS.PARTNERSHIP.GET_PARTNERSHIP}`]();
  }, []);

  useEffect(() => {
    if (
      partnership.hasDetails &&
      (partnership.details["status"] == PARTNERSHIP_STATUS.ACTIVE ||
        partnership.details["status"] == PARTNERSHIP_STATUS.PENDING)
    ) {
      setCurrentPlan(
        partnership.details["plan"].toLowerCase().replace("-", "_")
      );
      setCurrentInterval(partnership.details["interval"]);
      setCurrentPrice(partnership.details["price"]);
    }
    console.log(partnership, "partnership");
  }, [partnership.details]);

  const planName = (planId) => {
    if (planId) {
      const packageDetails = getPackageDetails(planId);
      return t(packageDetails.NAME_TRANSLATION_KEY);
    } else {
      return "";
    }
  };

  const getRecurringInterval = (interval) => {
    return interval == 1 ? "Monthly" : "Yearly";
  };

  let apartment = "";
  if (data.delivery?.apartment) {
    apartment = data.delivery.apartment ? data.delivery.apartment + " " : "";
  } else {
    apartment = "";
  }

  // const firstCycleDiscount = () => {
  //   let key = "";
  //   if (product_code == "") {
  //     return 0;
  //   }
  //   switch (product_code) {
  //     case PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID:
  //       key = "BASIC_TIER";
  //       break;
  //     case PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID:
  //       key = "SILVER_TIER";
  //       break;
  //     case PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID:
  //       key = "GOLD_TIER";
  //       break;
  //   }
  //   if (interval == 12) {
  //     return PROJECTS.PARTNERS.PACKAGES[key].DISCOUNT_YEAR;
  //   } else {
  //     return PROJECTS.PARTNERS.PACKAGES[key].DISCOUNT_MONTH;
  //   }
  // };

  // const firstCycleDiscountTotal = () => {
  //   let key = "";
  //   if (product_code == "") {
  //     return 0;
  //   }
  //   switch (product_code) {
  //     case PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID:
  //       key = "BASIC_TIER";
  //       break;
  //     case PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID:
  //       key = "SILVER_TIER";
  //       break;
  //     case PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID:
  //       key = "GOLD_TIER";
  //       break;
  //   }
  //   let price = 0;
  //   if (interval == 12) {
  //     price = PROJECTS.PARTNERS.PACKAGES[key].COST_YEAR - PROJECTS.PARTNERS.PACKAGES[key].DISCOUNT_YEAR;
  //   } else {
  //     price = PROJECTS.PARTNERS.PACKAGES[key].COST_MONTH - PROJECTS.PARTNERS.PACKAGES[key].DISCOUNT_MONTH;
  //   }
  //   return price;
  // };

  // let discountText =
  //   billingCycle.trim() == "yearly" ? (
  //     <div style={{ color: "#f21b54" }}>
  //       First Year Discount Price: <strong>${firstCycleDiscountTotal()}</strong>
  //     </div>
  //   ) : (
  //     <div style={{ color: "#f21b54" }}>
  //       First Month Discount Price: <strong>${firstCycleDiscountTotal()}</strong>
  //     </div>
  //   );

  // if (currentPlan.length > 0) {
  //   discountText = null;
  // }

  // Discount for Yearly 2023
  // let discountText =
  //   billingCycle.trim() == "yearly" && (
  //     <div style={{ color: "#f21b54" }}>
  //       First Year Discount Price: <strong>${firstCycleDiscountTotal()}</strong>
  //     </div>)

  // if (currentPlan.length > 0) {
  //   discountText = null;
  // }


  const isUpgradingToPartner = currentPlan == PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID &&
    currentInterval == 12 &&
    view.title_translation_key == "partners.partner" &&
    !useIsDateWithinTwoMonths(
      partnership.details?.next_payment_date
    );

  const isUpgradingToSilver = currentPlan == PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID &&
    currentInterval == 12 &&
    view.title_translation_key == "partners.silver_card_partner" &&
    !useIsDateWithinTwoMonths(
      partnership.details?.next_payment_date
    );

  const isUpgradingFromBasicToSilverCard = currentPlan == PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID &&
    currentInterval == 12 && view.title_translation_key == "partners.silver_card_partner" &&
    !useIsDateWithinTwoMonths(
      partnership.details?.next_payment_date
    );

  const isUpgradingFromBasicToSilver = currentPlan == PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID &&
    currentInterval == 12 && view.title_translation_key == "partners.silver_partner" &&
    !useIsDateWithinTwoMonths(
      partnership.details?.next_payment_date
    );

  const isUpgradingFromPartnerToSilverCard = currentPlan == PROJECTS.PARTNERS.PACKAGES.PARTNER_TIER.ID &&
    currentInterval == 12 && view.title_translation_key == "partners.silver_card_partner" &&
    !useIsDateWithinTwoMonths(
      partnership.details?.next_payment_date
    );

  console.log("isUpgradingToSilver", currentPlan);

  let renewal_date = "";
  if (isUpgradingToPartner || isUpgradingToSilver || isUpgradingFromBasicToSilverCard || isUpgradingFromBasicToSilver || isUpgradingFromPartnerToSilverCard) {
    renewal_date = useDateFormat(client_membership_expiration, "T");
  } else {
    renewal_date = useDateFormat(next_auto_renewal_date, "T");
  }

  console.log("Auth #1", data);
  return (
    <>
      <div className={cs(classes.container, classes.grid)}>
        <div className={classes.main}>
          <div className={classes.package}>
            <div className={cs(classes.illustration, view.title_translation_key == "partners.partner" ? classes.partnerIllustration : '')}>
              <view.Illustration />
            </div>
            <h4 className={classes.title}>{t(view.title_translation_key)}</h4>
            {/* <div className={classes.title}>{t("pages.checkout.titles.3", { value: price_per_month })}</div> */}
          </div>
        </div>
        <div className={classes.details}>
          <ul className={classes.list}>
            <li className={classes.item}>
              <div className={classes.icon}>
                <Icon name="badge" size="s" />
              </div>
              <div className={classes.text}>
                <b>
                  {data.details.first_name && (
                    <span className="mr-01">{data.details.first_name}</span>
                  )}
                  <span>{data.details.last_name}</span>
                </b>
              </div>
            </li>
            {full_credit > 0 && (
              <li className={classes.item}>
                <div className={classes.icon}>
                  <Icon name="star" size="s" />
                </div>
                <div className={classes.text}>{getPlanBenefits()}</div>
              </li>
            )}

            <li className={classes.item}>
              <div className={classes.icon}>
                <Icon name="credit-card" size="s" />
              </div>
              <div className={classes.text}>
                {(isUpgradingToPartner || isUpgradingToSilver || isUpgradingFromBasicToSilverCard || isUpgradingFromBasicToSilver || isUpgradingFromPartnerToSilverCard) ? (
                  <p>
                    You will be charged <strong>${upgrade_cost}</strong> now. <br />
                    Your subscription will renew at the regular price.
                  </p>
                ) : (
                  <Trans
                    components={{
                      a: <b />,
                      price: "$" + Math.round(pricePerRecurring),
                      billingCycle:
                        billingCycle === "monthly" ? billingCycle : "",
                    }}
                    i18nKey={
                      currentPlan
                        ? "pages.checkout.texts.16"
                        : "pages.checkout.texts.7"
                    }
                    t={t}
                  />
                )}
                {/* {discountText} */}
              </div>
            </li>
            {full_credit > 0 && (
              <li className={classes.item}>
                <div className={classes.icon}>
                  <Icon name="calendar" size="s" />
                </div>
                <div className={classes.text}>
                  <Trans
                    components={{
                      a: <b />,
                      date: useDateFormat(credit_expiration, "T"),
                    }}
                    i18nKey="pages.checkout.texts.11"
                    t={t}
                  />
                </div>
              </li>
            )}

            <li className={classes.item}>
              <div className={classes.icon}>
                <Icon name="calendar" size="s" color="primary" />
              </div>
              <div className={classes.text}>
                <Trans
                  components={{
                    a: <b />,
                    date: renewal_date,
                  }}
                  i18nKey={
                    (isUpgradingToPartner || isUpgradingToSilver || isUpgradingFromBasicToSilverCard || isUpgradingFromBasicToSilver || isUpgradingFromPartnerToSilverCard) ? "pages.checkout.texts.23" : currentPlan
                      ? "pages.checkout.texts.17"
                      : "pages.checkout.texts.10"
                  }
                  t={t}
                />
              </div>
            </li>

            {"delivery" in data && data.delivery != null && (
              <li className={classes.item}>
                <div className={classes.icon}>
                  <Icon name="deliverytruck" color="primary" size="s" />
                </div>

                <div className={classes.text}>
                  {/* <span>Delivery: </span> <br /> */}
                  {`${data.delivery.street} ${apartment}`}
                  <br />
                  {`${data.delivery.city}, ${data.delivery.state} ${data.delivery.zip}`}
                </div>
              </li>
            )}
          </ul>
        </div>
        <div className={classes.options}>
          <div className={classes.buttons}>
            <IconButton
              iconName="edit"
              iconSize="xs"
              onClick={() => onEdit({ id: item_id, type: product_code })}
            />
            <IconButton
              color="warning"
              iconName="x"
              iconSize="xs"
              onClick={modal.onOpen}
            />
          </div>
        </div>
      </div>
      <Modal opened={modal.isOpened} onClose={modal.onClose}>
        <ModalBody
          isLoading={isLoadingDelete}
          onClose={modal.onClose}
          onDelete={() => onDelete({ id: item_id, onSuccess: modal.onClose })}
        />
      </Modal>
      {purchase_details && false && (
        <div className={classes.paymentInformation}>
          {purchase_details.action == "cancel_and_create" &&
            purchase_details.days_for_next_billing != undefined && (
              <>
                You are currently a {planName(currentPlan)} Partner with a
                {getRecurringInterval(currentInterval)} donation of $
                {currentPrice}.
                <br />
                Your are upgrading to a {planName(product_code)} Partner.
                <br />
                Your {getRecurringInterval(interval)} donation will be ${price}.
                <br />
                Your Partnership is active till{" "}
                {useDateFormat(next_auto_renewal_date, "T")}
                <br />
                Your next charge will be at{" "}
                {useDateFormat(next_auto_renewal_date, "T")}
              </>
            )}

          {purchase_details.action == "cancel_and_create" &&
            purchase_details.days_for_next_billing == undefined && (
              <>
                You are currently a {planName(currentPlan)} Partner, your are
                about to cancel your partnership, you will lose your benefit as
                of {new Date()}
              </>
            )}

          {purchase_details.action == "change_billing_cycle" && (
            <>
              You are currently a {planName(currentPlan)} Partner with a{" "}
              {getRecurringInterval(currentInterval)} donation of $
              {currentPrice}.
              <br />
              Your are about to change your partnership to{" "}
              {getRecurringInterval(interval)} cycle.
              <br />
              Your next charge will be at{" "}
              {useDateFormat(next_auto_renewal_date, "T")} for the amount of $
              {price}.
            </>
          )}

          {purchase_details.action == "update" && (
            <>
              You are currently a {planName(currentPlan)} Partner with a{" "}
              {getRecurringInterval(currentInterval)} donation of $
              {currentPrice}.
              <br />
              Your are upgrading to a {planName(product_code)} Partner.
              <br />
              Your {getRecurringInterval(interval)} donation will be ${price}.
              <br />
              You have {purchase_details.remaining_amount} remaining in your
              account.
              <br />
              This remaining amount will be adjusted when you make payment.
            </>
          )}
        </div>
      )}
    </>
  );
};

PartnersItem.propTypes = {
  full_credit: PropTypes.number.isRequired,
  data: PropTypes.shape({
    delivery: PropTypes.shape({
      apartment: PropTypes.string,
      street: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
      country: PropTypes.string,
    }),
    details: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  interval: PropTypes.number.isRequired,
  item_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isLoadingDelete: PropTypes.bool.isRequired,
  price: PropTypes.number.isRequired,
  next_auto_renewal_date: PropTypes.string.isRequired,
  upgrade_cost: PropTypes.number,
  client_membership_expiration: PropTypes.string.isRequired,
  credit_expiration: PropTypes.string.isRequired,
  price_per_month: PropTypes.string.isRequired,
  product_code: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(TYPES)).isRequired,
  onDelete: PropTypes.func.isRequired,
  purchase_details: PropTypes.func.isRequired,
};

export default React.memo(PartnersItem);
