import React, { useState } from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { COLORS, SIZES } from "./utilities";
import classes from "./PhoneInput.module.scss";

const PhoneInput = ({ color, error, size, onBlur: onBlurCustom, onFocus: onFocusCustom, ...properties }) => {
  const [isFocused, setIsFocused] = useState(false);

  const onBlur = () => {
    setIsFocused(false);
    onBlurCustom && onBlurCustom();
  };
  const onFocus = () => {
    setIsFocused(true);
    onFocusCustom && onFocusCustom();
  };

  return (
    <div className={cs(classes.wrapper, classes[color], classes[size], { [classes.error]: !isFocused && !!error })}>
      <ReactPhoneInput
        buttonClass={classes.button}
        containerClass={classes.container}
        dropdownClass={classes.dropdown}
        inputClass={classes.input}
        searchClass={classes.search}
        disableSearchIcon
        enableSearch
        country="us"
        preferredCountries={["us", "ca", "il", "fr", "ru"]}
        searchPlaceholder=""
        onBlur={onBlur}
        onFocus={onFocus}
        {...properties}
      />
      {!isFocused && error && <div className={classes.tooltip}>{error}</div>}
    </div>
  );
};

PhoneInput.propTypes = {
  color: PropTypes.oneOf(Object.values(COLORS)),
  error: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

PhoneInput.defaultProps = {
  color: COLORS.PRIMARY,
  error: "",
  size: SIZES.MEDIUM,
};

export default PhoneInput;
