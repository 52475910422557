import React from "react";
import cs from "classnames";

import GlobalNavigation from "../../../../molecules/GlobalNavigation";
import ProfileMenu from "../../../../molecules/ProfileMenu";
import classes from "./LinearMenu.module.scss";
import { scrollToTop } from "../../../../../utilities/helpers";
import MaorCredit from "../../../../molecules/MaorCredit";

const LinearMenu = () => {
  return (
    <div className="d-flex ai-center">
      <nav className="ml-auto">
        <GlobalNavigation onClick={() => scrollToTop()} />
      </nav>
      <div className={cs(classes.profile, "d-flex jc-end ai-center ml-auto")}>
        <div className='mr-05'>
          <MaorCredit />
        </div>
        <ProfileMenu id="linear-menu-profile-menu" />
      </div>
    </div>
  );
};

export default LinearMenu;
