import { useState } from "react";

import FindOut from "./components/FindOut";
import Goal from "./components/Goal";
import MediaType from "./components/MediaType";
import useForm from "./useForm";

const useSteps = () => {
  const {
    findOutAd,
    findOutAdDetails,
    findOutOther,
    findOutOtherDetails,
    findOutReferral,
    findOutVideo,
    findOutVideoDetails,
    findOutWhatsApp,
    findOutWhatsAppDetails,
    goalChassidisheEntertainment,
    goalFreeResource,
    goalOther,
    goalOtherDetails,
    goalSeeRebbe,
    goalUnderstandRebbe,
    isCheckedFindOutStep,
    isCheckedGoalStep,
    isCheckedMediaTypeStep,
    mediaTypeBoth,
    mediaTypeChabadEntertainment,
    mediaTypeJewishEntertainment,
    mediaTypeOther,
    mediaTypeOtherDetails,
    onSubmit,
  } = useForm();

  const [stepIndex, setStepIndex] = useState(0);
  const steps = [
    {
      Component: Goal,
      fields: {
        goalChassidisheEntertainment,
        goalFreeResource,
        goalOther,
        goalOtherDetails,
        goalSeeRebbe,
        goalUnderstandRebbe,
      },
      isValid: isCheckedGoalStep,
    },
    {
      Component: MediaType,
      fields: {
        mediaTypeBoth,
        mediaTypeChabadEntertainment,
        mediaTypeJewishEntertainment,
        mediaTypeOther,
        mediaTypeOtherDetails,
      },
      isValid: isCheckedMediaTypeStep,
    },
    {
      Component: FindOut,
      fields: {
        findOutAd,
        findOutAdDetails,
        findOutOther,
        findOutOtherDetails,
        findOutReferral,
        findOutVideo,
        findOutVideoDetails,
        findOutWhatsApp,
        findOutWhatsAppDetails,
      },
      isValid: isCheckedFindOutStep,
    },
  ];
  const allStepsLength = steps.length;
  const currentStepNumber = stepIndex + 1;
  const hasNextStep = currentStepNumber < allStepsLength;
  const hasPreviousStep = currentStepNumber > 1;

  const fields = steps[stepIndex].fields;
  const isValid = steps[stepIndex].isValid;
  const Step = steps[stepIndex].Component;

  const dots = steps.map((item, index) => ({
    active: index + 1 <= currentStepNumber,
    checked: index + 1 < currentStepNumber,
    title: index + 1,
  }));

  /* handlers */
  const goNextStep = (event) => {
    event.preventDefault();
    setStepIndex((index) => index + 1);
  };
  const goPreviousStep = () => {
    setStepIndex((index) => index - 1);
  };

  return {
    dots,
    fields,
    hasPreviousStep,
    isValid,
    Step,
    goPreviousStep,
    onSubmit: hasNextStep ? goNextStep : onSubmit,
  };
};

export default useSteps;
