import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import cs from "classnames";

import Share from "../../molecules/Share";
import illustration from "../../../assets/images/maor-projects-on-devices-screen.png";
import { APPLICATION_ROUTES } from "../../../constants";
import { StoreContext } from "../../../contexts";
import classes from "./MyMaorSuccess.module.scss";

const MyMaorSuccess = observer(() => {
  const { products } = useContext(StoreContext);
  const { t } = useTranslation();

  useEffect(() => {
    products.getCampaign();
  }, []);

  return (
    <div className="bg-gold-gradient--horizontal c-blue-dark">
      <div className={cs(classes.wrapper, "wrapper-xl")}>
        <div className={cs(classes.grid, classes.successWrap)}>
          <div className={classes.message}>
            <h2 className="heading-l-a m-0 tt-uppercase">Thanks for your support and happy Birthday!</h2>
            <div className={cs(classes.share, "mt-05")}>
              <Share />
            </div>
            <div className="heading-m mt-05 fw-700 c-orange">
              <Link to={APPLICATION_ROUTES.LIBRARY}>{t("pages.partners.thank_you.labels.1")}</Link>
            </div>
          </div>
          <div className={classes.illustration}>
            <img src={illustration} alt="devices with maor website" draggable={false} />
          </div>
        </div>
      </div>
    </div>
  );
});

export default MyMaorSuccess;
