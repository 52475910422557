import { APPLICATION_ROUTES } from "../../../constants";

const DIRECTIONS = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};

const items = [
  {
    disabled: false,
    hidden: false,
    href: APPLICATION_ROUTES.HOME_PAGE,
    title: { key: "components.molecules.global_navigation.items.1" },
    color: "home",
  },
  {
    disabled: false,
    hidden: false,
    handleRefresh: true,
    href: APPLICATION_ROUTES.MY_MAOR_MAIN,
    title: { key: "components.molecules.global_navigation.items.2" },
    color: "myMaor",
  },
  {
    disabled: false,
    hidden: false,
    href: APPLICATION_ROUTES.LIBRARY,
    title: { key: "components.molecules.global_navigation.items.5" },
    color: "library",
  },
  {
    disabled: false,
    hidden: false,
    href: APPLICATION_ROUTES.DAILY_REBBE_VIDEO_MAIN,
    title: { key: "components.molecules.global_navigation.items.3" },
    color: "drv",
  },
  {
    disabled: false,
    hidden: false,
    href: APPLICATION_ROUTES.ILLUMINATE,
    title: { key: "components.molecules.global_navigation.items.4" },
    color: "illuminate",
  },
  {
    disabled: false,
    hidden: false,
    href: APPLICATION_ROUTES.PARTNERS_MAIN_HOME,
    title: { key: "components.molecules.global_navigation.items.6" },
    color: "maorPartners",
  },
  {
    disabled: false,
    hidden: false,
    href: APPLICATION_ROUTES.PLEDGE,
    title: { key: "components.molecules.global_navigation.items.7" },
    color: "pledge",
  },
  // {
  //   disabled: false,
  //   hidden: false,
  //   href: APPLICATION_ROUTES.REFERRAL,
  //   title: { key: "components.molecules.global_navigation.items.8" },
  //   color: "referral",
  // },
  {
    disabled: false,
    hidden: false,
    href: "https://maor.shop/",
    title: { key: "components.molecules.global_navigation.items.9" },
  },
];

export { DIRECTIONS, items };
