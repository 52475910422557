import { convertToHebrew } from "jewish-dates-core";
import heYear from "./heYear";
import * as Hebcal from "hebcal";
import getJewishMonth from "./getJewishMonth";

const convertJewishEngToJewish = (value) => {

    let day = "";
    let month = "";
    let year = "";

    if (value != "") {
        let hDate = new Hebcal.HDate(value).toString();
        hDate = hDate.split(" ");

        // let day = Number(hDate[0]);
        // let month = hDate[1];
        // let year = Number(hDate[hDate.length - 1]);

        if (hDate.length > 3) {
            day = Number(hDate[0]);
            month = hDate[1] + " " + hDate[2];
            year = Number(hDate[hDate.length - 1]);
        } else {
            day = Number(hDate[0]);
            month = hDate[1];
            year = Number(hDate[hDate.length - 1]);
        }

        day = convertToHebrew(day, false, false);
        month = getJewishMonth(month);
        year = heYear(year);
    }

    return `${day} ${month} ${year}`
}

export default convertJewishEngToJewish