const payMethodCreditsValidation = () => {
    const brainTreelement = document.getElementById("braintree-container");
    const choseWayTextelement = document.querySelector('[data-braintree-id="choose-a-way-to-pay"]');

    if (brainTreelement) {
        if (brainTreelement.classList.contains("braintree-show-card") || brainTreelement.classList.contains("braintree-show-card")) {
            choseWayTextelement.style.color = 'black';
            brainTreelement.style.border = "none";
        } else {
            choseWayTextelement.style.color = 'red';
            brainTreelement.style.border = "1px solid red";
            brainTreelement.style.padding = "5px 5px";
        }
    }
}

export default payMethodCreditsValidation;