import React, { useCallback, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import cs from "classnames";

import Loader from "../../atoms/Loader";
import Logotype from "../../atoms/Logotype";
import { StoreContext } from "../../../contexts";
import { COOKIES } from "../../../constants";
import { ACTIONS } from "../../../store";
import { useQuery } from "../../../hooks";
import Cookies from "../../../utilities/cookies";
import classes from "./AutoSignIn.module.scss";

const AutoSignIn = observer(() => {
  const { authorization } = useContext(StoreContext);
  const error = authorization[`error${ACTIONS.AUTHORIZATION.RESIGN_IN}`];
  const isLoading = authorization[`isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`];

  const query = useQuery();
  const cartID = query.get("cart_id");
  const path = query.get("path");
  const token = query.get("token");

  if (cartID) Cookies.set({ name: COOKIES.CART_ID, value: cartID });

  const history = useHistory();
  const onNavigate = useCallback(() => {
    path && history.push(path);
  }, []);

  const { t } = useTranslation();
  const YOU_WILL_BE_REDIRECTED_WITHIN_A_FEW_SECONDS = t("authorization.you_will_be_redirected_within_a_few_seconds");

  useEffect(() => {
    if (token)
      Cookies.set({
        name: COOKIES.AUTHORIZATION,
        value: token,
        options: { expires: 14 },
      });
    authorization.resignIn({ onSuccess: onNavigate });
    Cookies.set({ name: COOKIES.AUTO_SIGNIN, value: 1 });
  }, []);

  return (
    <div className={classes.container}>
      <div className={cs(classes.wrapper, "wrapper-xl")}>
        <div className={classes.logotype}>
          <Logotype />
        </div>
        <h2 className={classes.title}>{YOU_WILL_BE_REDIRECTED_WITHIN_A_FEW_SECONDS}</h2>
        {isLoading && (
          <div className={classes.loader}>
            <Loader />
          </div>
        )}

        {error && <div className={classes.error}>{error}</div>}
      </div>
    </div>
  );
});

export default AutoSignIn;
