import React from "react";
import PropTypes from "prop-types";

import PlainInput from "../../../../atoms/PlainInput";

const Login = ({ login }) => {
  return (
    <PlainInput
      error={login.error}
      id="sign-in-form-login"
      label={login.label}
      value={login.value}
      onChange={login.onChange}
      type="email"
    />
  );
};

const inputPropTypes = PropTypes.shape({
  error: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  touched: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}).isRequired;

Login.propTypes = {
  login: inputPropTypes,
};

export default Login;
