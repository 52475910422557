import { APPLICATION_ROUTES } from "../../../constants";

const items = [
  {
    title: { key: "components.organisms.footer.items.1" },
    url: APPLICATION_ROUTES.HOME,
  },
  {
    title: { key: "components.organisms.footer.items.2" },
    url: APPLICATION_ROUTES.MY_MAOR_MAIN,
  },
  {
    title: { key: "components.organisms.footer.items.3" },
    url: APPLICATION_ROUTES.DAILY_REBBE_VIDEO_MAIN,
  },
  {
    title: { key: "components.organisms.footer.items.4" },
    url: APPLICATION_ROUTES.ILLUMINATE_MAIN,
  },
  {
    title: { key: "components.organisms.footer.items.5" },
    url: APPLICATION_ROUTES.LIBRARY,
  },
  {
    title: { key: "components.organisms.footer.items.6" },
    url: APPLICATION_ROUTES.PARTNERS_MAIN,
  },
  {
    title: { key: "components.organisms.footer.items.7" },
    url: APPLICATION_ROUTES.CONTACT,
  },
];

const getLanguages = ({ items, language }) => {
  const getTitle = (value) => {
    const TITLES = {
      en: "English",
      fr: "Française",
      he: "עִברִית",
      ru: "Русский",
    };
    const fallback = TITLES["en"];
    const title = TITLES[value] || fallback;

    return title;
  };

  const languages = items.map((item) => ({ active: language === item, title: getTitle(item), value: item }));

  return languages;
};

export { items, getLanguages };
