import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Trans, useTranslation } from "react-i18next";

import Button from "../../atoms/Button";
import Checkbox from "../../atoms/Checkbox";
import Loader from "../../atoms/Loader";
import Logotype from "../../atoms/Logotype";
import PageTitle from "../../atoms/PageTitle";
// import ProgressBar from "../../atoms/ProgressBar";
import { APPLICATION_ROUTES, PAGE_TITLES } from "../../../constants";
import { StoreContext } from "../../../contexts";
import { ACTIONS } from "../../../store";
import useSteps from "./useSteps";
import { getTranslationKeyOfError } from "./utilities";
import cs from "classnames";
import classes from "./SignInPage.module.scss";
import GoogleAuth from "../../organisms/GoogleAuth";
import { useQuery } from "../../../hooks";

const SignInPage = observer((props) => {
  const { setClosePopUp = null, reauthenticate = false, partnersCheckout = false } = props;

  const { authorization } = useContext(StoreContext);
  const { t } = useTranslation();
  const LABEL_CHECKBOX = t("pages.authorization.sign_in.labels.checkbox");
  const LABEL_LINK = t("pages.authorization.sign_in.labels.link");
  const LABEL_SUBMIT = t("pages.authorization.sign_in.labels.submit");
  const TITLE = t("pages.authorization.sign_in.title");

  const query = useQuery();
  const redirectPageURL = query.get("redirect");

  const TEXT = (
    <Trans
      i18nKey="pages.authorization.sign_in.text"
      t={t}
      components={{
        a: (
          <Link
            to={
              !redirectPageURL
                ? APPLICATION_ROUTES.SIGN_UP
                : APPLICATION_ROUTES.SIGN_UP + "?redirect=" + redirectPageURL
            }
            className="fw-700 c-orange"
          />
        ),
      }}
    />
  );
  const { fields, isNotValid, remember, Step, onSubmit } = useSteps({
    authorization,
    reauthenticate,
    setClosePopUp,
    partnersCheckout
  });
  const ERROR_MESSAGE = t(getTranslationKeyOfError(authorization.errorSignIn));

  useEffect(() => {
    return () => {
      authorization.clearError(ACTIONS.AUTHORIZATION.SIGN_IN);
    };
  }, []);

  return (
    <>
      <PageTitle value={PAGE_TITLES.SIGN_IN} />
      <main className={cs("bg-gold-gradient--horizontal c-grey-dark text-m", reauthenticate && classes.whiteBg)}>
        <form
          className={cs("wrapper-s wrapper-vertical-s-a d-flex fd-column ai-center minh-100vh", reauthenticate && classes.popUpSignIn)}
          onSubmit={onSubmit}
        >
          <div>
            <Logotype />
          </div>
          {/* <div className="mt-03 w-100p" style={{ maxWidth: "300px" }}>
            <ProgressBar current={currentStepNumber} length={allStepsLength} />
          </div> */}
          <div className="mt-10">
            <h2 className="heading-m-a ta-center c-blue-dark">{TITLE}</h2>
          </div>
          <div className="mt-10 w-100p" style={{ maxWidth: "320px" }}>
            <Step {...fields} />
          </div>
          {authorization.errorSignIn && (
            <div
              className="mt-02 w-100p color-red fw-700"
              style={{ maxWidth: "320px" }}
            >
              {ERROR_MESSAGE}
            </div>
          )}
          <div
            className="d-flex jc-space-between ai-center mt-06 w-100p"
            style={{ maxWidth: "320px" }}
          >
            <Checkbox
              checked={remember.checked}
              size="s"
              onChange={remember.onChange}
            >
              {LABEL_CHECKBOX}
            </Checkbox>
            <Link
              to={APPLICATION_ROUTES.PASSWORD_RECOVERY_REQUEST}
              className="c-orange"
            >
              {LABEL_LINK}
            </Link>
          </div>
          <div
            className={cs(
              "mt-10 mb-10",
              classes.optionsContainer,
              classes.bottomContainer
            )}
          >
            <Button
              disabled={isNotValid || authorization.isLoadingSignIn}
              borderRadius="8"
              type="submit"
            >
              {LABEL_SUBMIT}
            </Button>
            <div className={cs(classes.orSeparator, "mt-05", "mb-05", reauthenticate && classes.orSeparatorWhite)}>
              <label>OR</label>
            </div>
            <div>
              <GoogleAuth reauthenticate={reauthenticate} setClosePopUp={setClosePopUp} />
            </div>
          </div>
          {!reauthenticate && <div className="mt-05 ta-center">{TEXT}</div>}
          {authorization.isLoadingSignIn && (
            <div className="mt-05">
              <Loader />
            </div>
          )}
        </form>
      </main>
    </>
  );
});

export default SignInPage;
