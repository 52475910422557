import React from "react";
import { Trans } from "react-i18next";

import Logotype from "../../atoms/Logotype";
import PageTitle from "../../atoms/PageTitle";
import { PAGE_TITLES } from "../../../constants";

const PasswordRecoverySuccess = () => {
  return (
    <>
      <PageTitle value={PAGE_TITLES.PASSWORD_RECOVERY} />
      <main className="bg-gold-gradient--horizontal">
        <div className="wrapper-s wrapper-vertical-s-a d-flex fd-column ai-center minh-100vh">
          <div>
            <Logotype />
          </div>
          <h2 className="heading-l-a mt-11 ta-center c-blue-dark">
            <Trans i18nKey="pages.authorization.password_recovery_success.titles.1" />
          </h2>
        </div>
      </main>
    </>
  );
};

export default PasswordRecoverySuccess;
