import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import App from "./components/templates/App";
import ErrorBoundary from "./components/organisms/ErrorBoundary";
import { StoreContext } from "./contexts";
import store from "./store";

import "./utilities/localization";
import "./styles/index.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";

Bugsnag.start({
  // eslint-disable-next-line no-undef
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  // enabledReleaseStages: ["production"],
  plugins: [new BugsnagPluginReact()],
  // eslint-disable-next-line no-undef
  // releaseStage: process.env.REACT_APP_RELEASE_TYPE,
});

ReactDOM.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}  >
    <React.StrictMode>
      <HelmetProvider>
        <StoreContext.Provider value={store}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </StoreContext.Provider>
      </HelmetProvider>
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);
