import validate from "./validate";

const createState = ({ validators, value }) => {
  const errors = validate(value.value, validators);

  return {
    errors,
    touched: !!value,
    validators,
    value,
  };
};

export default createState;
