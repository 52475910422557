import API from "./_api";
import { API_ROUTES, APPLICATION_ROUTES } from "../constants";

const AuthorizationAPI = class extends API {
  updatePassword({ password, token }) {
    return this.request(API_ROUTES.AUTHORIZATION.PASSWORD_RECOVERY_UPDATE, {
      body: { password, token },
      method: "POST",
    });
  }

  requestPasswordRecovery({
    email,
    path = APPLICATION_ROUTES.PASSWORD_RECOVERY_UPDATE,
  }) {
    return this.request(API_ROUTES.AUTHORIZATION.PASSWORD_RECOVERY_REQUEST, {
      body: { email, path },
      method: "POST",
    });
  }

  sendQuestionnaire({ data }) {
    return this.request(API_ROUTES.AUTHORIZATION.SEND_QUESTIONNAIRE, {
      body: data,
      method: "POST",
    });
  }

  signIn({ email, password, token }) {
    return this.request(API_ROUTES.AUTHORIZATION.SIGN_IN, {
      body: { email, password, token },
      method: "POST",
    });
  }

  signOut({ token }) {
    return this.request(API_ROUTES.AUTHORIZATION.SIGN_OUT, {
      headers: { Authorization: token },
      method: "POST",
    });
  }

  signUp({
    ageStatus,
    email,
    firstName,
    lastName,
    password,
    phone,
    referralCode,
    referredFrom = null,
    googleToken = null,
    picture = null,
  }) {
    let referred_from = referredFrom != null ? referredFrom : undefined;
    let google_token = googleToken != null ? googleToken : undefined;
    let picture_url = picture != null ? picture : undefined;
    return this.request(API_ROUTES.AUTHORIZATION.SIGN_UP, {
      body: {
        ageStatus,
        email,
        first_name: firstName,
        last_name: lastName,
        referral_code: referralCode,
        password,
        phone,
        referred_from,
        google_token,
        picture_url,
      },
      method: "POST",
    });
  }

  resignIn() {
    return this.request(API_ROUTES.AUTHORIZATION.RESIGN_IN, {
      method: "POST",
    });
  }

  verifyEmail({ token }) {
    return this.request(API_ROUTES.AUTHORIZATION.VERIFY_EMAIL, {
      body: { token: token },
      method: "POST",
    });
  }

  getPersons({ type = "" }) {
    return this.request(API_ROUTES.AUTHORIZATION.GET_PERSONS, {
      method: "POST",
      body: { type: type },
    });
  }

  getAddress() {
    return this.request(API_ROUTES.AUTHORIZATION.GET_ADDRESS, {
      method: "POST",
    });
  }

  addAddress(data) {
    return this.request(`${API_ROUTES.AUTHORIZATION.ADD_ADDRESS}`, {
      method: "POST",
      body: { data: { ...data } },
    });
  }

  editAddress(data) {
    return this.request(`${API_ROUTES.AUTHORIZATION.EDIT_ADDRESS}`, {
      method: "POST",
      body: { data: { ...data } },
    });
  }

  deleteAddress(addressId) {
    return this.request(`${API_ROUTES.AUTHORIZATION.DELETE_ADDRESS}`, {
      method: "POST",
      body: { address_id: addressId },
    });
  }

  setDefaultAddress(addressId) {
    return this.request(`${API_ROUTES.AUTHORIZATION.SET_DEFAULT_ADDRESS}`, {
      method: "POST",
      body: { address_id: addressId },
    });
  }

  getLatestGoldPartners({ memberLimit = 4 }) {
    return this.request(
      API_ROUTES.AUTHORIZATION.GET_LATEST_GOLD_PARTNERS +
        "?memberLimit=" +
        memberLimit,
      {
        method: "GET",
      }
    );
  }
};

export default AuthorizationAPI;
