import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import cs from "classnames";
import classes from "./Ad.module.scss";
import { StoreContext } from "../../../contexts";
import { ACTIONS } from "../../../store";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { animateScroll as scroll } from "react-scroll";

const Ad = observer(() => {
  const { ad } = useContext(StoreContext);
  const history = useHistory();
  // const error = ad[`error${ACTIONS.AD.GET_AD}`];
  const isLoading = ad[`isLoading${ACTIONS.AD.GET_AD}`];
  const adData = ad.ad;
  // const hasItems = ad.hasAd;

  useEffect(() => {
    ad[`getAd`]();
  }, []);

  const openLink = () => {
    if (adData.ad_link) {
      const url = new URL(adData.ad_link);
      const path = url.pathname;
      const host = url.host;
      if (host.includes("maor.org")) {
        history.push(path);
        scroll.scrollToTop();
      } else {
        window.open(url, "_blank");
      }
    }
  };

  return (
    <>
      {!isLoading && adData ? (
        <div
          className={cs(classes.adContainer, adData.ad_link && classes.pointer)}
        >
          {adData.ad_posted && (
            <img
              src={adData.ad_url}
              alt="ad"
              onClick={openLink}
              className={cs(adData?.mobile_ad_url && classes.mobileHideBanner)}
            />
          )}
          {adData.ad_posted && adData?.mobile_ad_url && (
            <img
              src={adData.mobile_ad_url}
              alt="ad"
              onClick={openLink}
              className={cs(classes.browserHideBanner, classes.mobileBanner)}
            />
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
});

export default Ad;
