// import { useCallback, useState } from "react";

// const useRadio = ({ options = [], value: initialState = "" } = {}) => {
//   const [value, setValue] = useState(initialState);

//   const onChange = useCallback(({ target: { value } }) => {
//     setValue(value);
//   }, []);

//   const setValueExternal = (newValue) => {
//     setValue(newValue);
//   };

//   return {
//     options,
//     value,
//     onChange,
//     setValueExternal: setValueExternal, // Add the setValue method
//   };
// };

// export default useRadio;

import { useCallback, useState } from "react";
import { createState, validate } from "../useInput/utilities";

const useRadio = ({ options = [], value: initialState = "", validators = [] } = {}) => {
  const [state, setState] = useState(createState({ value: initialState, validators }));

  const onChange = useCallback(({ target: { value }, touched = true }) => {
    setState((state) => {
      const errors = validate(value, validators);
      return {
        ...state,
        errors,
        value,
        touched: touched,
      };
    });
  }, [validators]);

  const setValueExternal = (newValue) => {
    setState((state) => {
      const errors = validate(newValue, validators);
      return {
        ...state,
        errors,
        value: newValue,
      };
    });
  };

  const touch = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        touched: true,
      };
    });
  });

  return {
    options,
    value: state.value,
    error: state.touched && state.errors.length ? state.errors[0] : "",
    errors: state.errors,
    onChange,
    setValueExternal,
    touch
  };
};

export default useRadio;
