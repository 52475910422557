import API from "./_api";
import { API_ROUTES } from "../constants";

const AudiosAPI = class extends API {
  getPlaylist() {
    return this.request(API_ROUTES.AUDIOS.GET_PLAYLIST, {
      method: "GET",
    });
  }
};

export default AudiosAPI;
