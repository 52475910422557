import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import Button from "../../atoms/Button";
import Loader from "../../atoms/Loader";
import Logotype from "../../atoms/Logotype";
import PageTitle from "../../atoms/PageTitle";
import PlainInput from "../../atoms/PlainInput";
import { PAGE_TITLES } from "../../../constants";
import { StoreContext } from "../../../contexts";
import useForm from "./useForm";
import { getTranslationKeyOfError } from "./utilities";

const PasswordRecoveryRequest = observer(() => {
  const { authorization } = useContext(StoreContext);
  const { email, isNotValid, onSubmit } = useForm();
  const { t } = useTranslation();

  return (
    <>
      <PageTitle value={PAGE_TITLES.PASSWORD_RECOVERY} />
      <main className="bg-gold-gradient--horizontal c-grey">
        <div className="wrapper-s wrapper-vertical-s-a d-flex fd-column ai-center minh-100vh">
          <div>
            <Logotype />
          </div>
          <h2 className="heading-m-a mt-11 ta-center c-blue-dark">
            {t("pages.authorization.password_recovery_request.titles.1")}
          </h2>
          <form className="d-flex fd-column ai-center mt-07 w-100p" onSubmit={onSubmit}>
            <div className="w-100p" style={{ maxWidth: "320px" }}>
              <PlainInput
                error={email.error}
                id="password-recovery-request-form-email"
                label={email.label}
                value={email.value}
                onChange={email.onChange}
              />
            </div>
            <div className="mt-07">
              <Button disabled={isNotValid || authorization.isLoadingPasswordRecoveryRequest} type="submit">
                {t("pages.authorization.password_recovery_request.labels.1")}
              </Button>
            </div>
          </form>
          {authorization.isLoadingPasswordRecoveryRequest && (
            <div className="mt-05">
              <Loader />
            </div>
          )}
          {authorization.errorPasswordRecoveryRequest && (
            <div className="mt-05 w-100p text-l ta-center c-red" style={{ maxWidth: "320px" }}>
              {t(getTranslationKeyOfError(authorization.errorPasswordRecoveryRequest))}
            </div>
          )}
        </div>
      </main>
    </>
  );
});

export default PasswordRecoveryRequest;
