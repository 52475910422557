const createLanguages = (locales) => {
  const languages = Object.entries(locales)
    .map((item) => ({ language: item[0], ...item[1] }))
    .filter((item) => item.link);
  return languages;
};

const getFormattedViews = (views) => {
  const millions = Math.floor(views / 1000000);

  if (millions > 1) return `${millions}m`;

  const thousands = Math.floor(views / 1000);

  if (thousands > 1) return `${thousands}k`;

  return views;
};

const getDetails = (language, locales) => {
  if (locales[language] && locales[language].link) return locales[language];

  if (locales["en"].link) return locales["en"];
  if (locales["he"].link) return locales["he"];
  if (locales["fr"].link) return locales["fr"];
  if (locales["ru"].link) return locales["ru"];

  return locales["en"];
};

const getURL = (path, { start = 0 } = {}) => {
  return `${path}#t=${start}s`;
};

export { createLanguages, getFormattedViews, getDetails, getURL };
