import { DELIVERY_COUNTRIES } from "../../../constants";
import { getPackageDetails } from "../../../utilities/helpers";

const checkHasDelivery = (type) => {
  if (!type) return false;

  const details = getPackageDetails(type);
  const hasDelivery = details.DELIVERY;

  return hasDelivery;
};

const getInitials = ({ id, items }) => {
  const item = items.find((item) => item.item_id === Number(id));

  if (item) {
    return {
      details: {
        amount: item.data.details.amount || "",
        email: item.data.details.email || "",
        firstName: item.data.details.first_name || "",
        lastName: item.data.details.last_name || "",
      },
      delivery: {
        apartment: (item.data.delivery && item.data.delivery.apartment) || "",
        city: (item.data.delivery && item.data.delivery.city) || "",
        country: (item.data.delivery && item.data.delivery.country) || DELIVERY_COUNTRIES.USA,
        state: (item.data.delivery && item.data.delivery.state) || "",
        street: (item.data.delivery && item.data.delivery.street) || "",
        zip: (item.data.delivery && item.data.delivery.zip) || "",
      },
    };
  }

  return {
    details: {
      amount: "",
      email: "",
      firstName: "",
      lastName: "",
    },
    delivery: {
      apartment: "",
      city: "",
      country: DELIVERY_COUNTRIES.USA,
      state: "",
      street: "",
      zip: "",
    },
  };
};

const getValueWithoutError = (item) => {
  return item.errors.length ? "" : item.value;
};

export { checkHasDelivery, getInitials, getValueWithoutError };
