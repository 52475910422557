import API from "./_api";
import { API_ROUTES } from "../constants";

const PaymentMethodAPI = class extends API {
  get() {
    return this.request(`${API_ROUTES.PAYMENTMETHOD.GET}`, {
      method: "GET",
    });
  }

  getToken({ gateway, add_card = true }) {
    return this.request(`${API_ROUTES.PAYMENTMETHOD.GET_TOKEN}`, {
      method: "POST",
      body: {
        add_card: add_card,
        data: {
          gateway: gateway,
          language: "en",
        },
      },
    });
  }

  makeDefault({ id }) {
    return this.request(`${API_ROUTES.PAYMENTMETHOD.MAKE_DEFAULT}`, {
      method: "POST",
      body: {
        payment_method_id: id,
      },
    });
  }

  save({ nonce, make_default = true, paymentmethod_type }) {
    return this.request(`${API_ROUTES.PAYMENTMETHOD.SAVE}`, {
      method: "POST",
      body: {
        payment_method_type: paymentmethod_type,
        nonce: nonce,
        isDefault: make_default,
      },
    });
  }

  delete({ id }) {
    return this.request(`${API_ROUTES.PAYMENTMETHOD.DELETE}`, {
      method: "POST",
      body: {
        id: id,
      },
    });
  }
};

export default PaymentMethodAPI;
