import API from "./_api";
import { API_ROUTES } from "../constants";

const WinnersAPI = class extends API {
  getRaffleWinner() {
    return this.request(`${API_ROUTES.WINNERS.RAFFLE}`, {
      method: "GET",
    });
  }

  getMaorWinner() {
    return this.request(`${API_ROUTES.WINNERS.MAOR}`, {
      method: "GET",
    });
  }
};

export default WinnersAPI;
