import Validation from "../../../utilities/validation";

const validate = ({ validatorsOfFirst, validatorsOfSecond, valueOfFirst, valueOfSecond }) => {
  let validationOfFirst = new Validation({ value: valueOfFirst != undefined ? valueOfFirst.trim() : valueOfFirst });
  let validationOfSecond = new Validation({ value: valueOfSecond != undefined ? valueOfSecond.trim() : valueOfSecond });


  validatorsOfFirst.forEach(({ name, options, validate }) => {
    if (validationOfFirst[name]) {
      validationOfFirst[name](options);
      return;
    }

    validate.call(validationOfFirst, options);
  });

  validatorsOfSecond.forEach(({ name, options, validate }) => {
    if (validationOfSecond[name]) {
      validationOfSecond[name]({ ...options, value: valueOfFirst });
      return;
    }

    validate.call(validationOfSecond, options);
  });

  return {
    first: validationOfFirst.errors,
    second: validationOfSecond.errors,
  };
};

export default validate;
