const COOKIES = {
  ANONYMOUS_PAYMENT: "anonymous_payment",
  AUTHORIZATION: "jwt",
  PAYMENT: "payment",
  CART_ID: "cart_id",
  INVITING_USER_ID: "inviting_user_id",
  LOCALE: "locale",
  AUTO_SIGNIN: "auto_signin",
  ADD_ANOTHER_CHILD: "add_another_child",
};

export default COOKIES;
