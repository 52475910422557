import { observer } from "mobx-react-lite";
import React from "react";
import { useEffect, useContext } from "react";
import { StoreContext } from "../../../../contexts";
import { ACTIONS } from "../../../../store/mailinglist/utilities";
import classes from "./MailingList.module.scss";
import cs from "classnames";
import mymaor from "../../../../assets/images/my-maor.png";
// import hebrew from "../../../../assets/images/hebrew.png";
// import rabbi from "../../../../assets/images/rabbi.png";
// import rebbe from "../../../../assets/images/dailyrebbe.png";
// import Illuminate from "../../../../assets/images/illuminate.png";
import subs from "../../../../assets/images/subs.png";
import hamburger from "../../../../assets/images/hamburger.png";
import Layout from "../../../templates/Layout";
import { useHistory } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../../../constants";

const MailingList = observer(() => {
  const { mailinglist, authorization } = useContext(StoreContext);
  const history = useHistory();

  if (!authorization.isAuthenticated) {
    history.push(APPLICATION_ROUTES.HOME);
  }


  useEffect(() => {
    mailinglist.get({});
  }, []);

  const handleUnsubscribe = (id) => {
    if (!confirm("Are you sure want to unsubscribe?")) {
      return;
    }
    mailinglist.delete({
      id: id,
      onSuccess: () => {
        mailinglist.get({});
      },
    });
  };

  if (mailinglist.getIsLoading(ACTIONS.GET)) {
    return null;
  }

  return (
    <Layout>
      <div className={cs(classes.maindiv)}>
        <img className={cs(classes.mobileicon)} src={hamburger} />
        <div className={cs(classes.sidebar)}>
          <ul>
            <li>
              <a className={cs(classes.active, classes.links)} href="#">
                <div className={classes.anchorContainer}>
                  <img src={subs} /> My Subscriptions
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div className={cs(classes.maincontainer)}>
          <div className={cs(classes.topheading, "d-none hide")}>
            {/* <h4>Subscribe</h4> */}
            <div className={cs(classes.savecontainer)}>
              <a className={cs(classes.fillbtn)} href="#">
                Save and Exit
              </a>
              <a href="#">Exit Without Saving</a>
            </div>
          </div>

          {/* Main Content */}
          <div className={cs(classes.maincontent)}>
            {mailinglist.items &&
              mailinglist.items.map((item, index) => {
                return (
                  <div key={`mailing-list-item-${item}-${index}`} className={cs(classes.maincontentflex)}>
                    <div className={cs(classes.flexcontainer)}>
                      <img src={mymaor} />
                      {/* <p>English</p> */}
                      <h4>Via:</h4>
                      <div className={cs(classes.selectfield)}>
                        <label>Email</label>
                        <div>
                          <h4>{item.email}</h4>
                        </div>
                        {/* <select>
                          <option value="Family Member">Family Member</option>
                          <option value="Member">Member</option>
                        </select> */}
                        <button
                          disabled={mailinglist.loadingIds.includes(item.id)}
                          className={classes.unsubscribeBtn}
                          onClick={() => {
                            handleUnsubscribe(item.id);
                          }}
                        >
                          {mailinglist.loadingIds.includes(item.id) ? "Please wait..." : "Unsubscribe"}
                        </button>
                      </div>
                      {/* <div className={cs(classes.selectfield)}>
                        <label>Whatsapp</label>
                        <select>
                          <option value="Family Member">Family Member</option>
                          <option value="Member">Member</option>
                        </select>
                      </div> */}
                    </div>
                  </div>
                );
              })}

            {!mailinglist.hasItems && <div className={classes.noResult}>No Subscriptions Yet!</div>}
            {/* 1st Row */}
            {/* <div className={cs(classes.maincontentflex)}>
              <div className={cs(classes.flexcontainer)}>
                <img src={mymaor} />
                <p>English</p>
                <h4>Via:</h4>
                <div className={cs(classes.selectfield)}>
                  <label>Email</label>
                  <select>
                    <option value="Family Member">Family Member</option>
                    <option value="Member">Member</option>
                  </select>
                </div>
                <div className={cs(classes.selectfield)}>
                  <label>Whatsapp</label>
                  <select>
                    <option value="Family Member">Family Member</option>
                    <option value="Member">Member</option>
                  </select>
                </div>
              </div>

              <div className={cs(classes.flexcontainer)}>
                <img src={hebrew} />
                <p>English</p>
                <h4>Via:</h4>
                <div className={cs(classes.selectfield)}>
                  <label>Email</label>
                  <select>
                    <option value="Family Member">Family Member</option>
                    <option value="Member">Member</option>
                  </select>
                </div>
                <div className={cs(classes.selectfield)}>
                  <label>Whatsapp</label>
                  <select>
                    <option value="Family Member">Family Member</option>
                    <option value="Member">Member</option>
                  </select>
                </div>
              </div>

              <div className={cs(classes.flexcontainer)}>
                <img src={rabbi} />
                <p>English</p>
                <h4>Via:</h4>
                <div className={cs(classes.selectfield)}>
                  <label>Email</label>
                  <select>
                    <option value="Family Member">Family Member</option>
                    <option value="Member">Member</option>
                  </select>
                </div>
                <div className={cs(classes.selectfield)}>
                  <label>Whatsapp</label>
                  <select>
                    <option value="Family Member">Family Member</option>
                    <option value="Member">Member</option>
                  </select>
                </div>
              </div>
            </div> */}

            {/* 2nd Row */}
            {/* <div className={cs(classes.maincontentflex)}>
              <div className={cs(classes.flexcontainer)}>
                <img src={rebbe} />
                <p>English</p>
                <h4>Via:</h4>
                <div className={cs(classes.selectfield)}>
                  <label>Email</label>
                  <select>
                    <option value="Family Member">Family Member</option>
                    <option value="Member">Member</option>
                  </select>
                </div>
                <div className={cs(classes.selectfield)}>
                  <label>Whatsapp</label>
                  <select>
                    <option value="Family Member">Family Member</option>
                    <option value="Member">Member</option>
                  </select>
                </div>
              </div>
            </div> */}

            {/* 3rd Row */}
            {/* <div className={cs(classes.maincontentflex)}>
              <div className={cs(classes.flexcontainer)}>
                <img src={Illuminate} />
                <p>English</p>
                <h4>Via:</h4>
                <div className={cs(classes.selectfield)}>
                  <label>Email</label>
                  <select>
                    <option value="Family Member">Family Member</option>
                    <option value="Member">Member</option>
                  </select>
                </div>
                <div className={cs(classes.selectfield)}>
                  <label>Whatsapp</label>
                  <select>
                    <option value="Family Member">Family Member</option>
                    <option value="Member">Member</option>
                  </select>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Layout>
  );
});

export default React.memo(MailingList);
