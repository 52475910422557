import { observer } from "mobx-react-lite";
import React from "react";
import { useEffect, useContext } from "react";
import { StoreContext } from "../../../../contexts";
// import { ACTIONS } from "../../../../store/paymentmethod/utilities";
import Layout from "../../../templates/Layout";
import cs from "classnames";
import classes from "./PaymentMethod.module.scss";
import Button from "../../../atoms/Button";
import { ACTIONS } from "../../../../store";
import { Link, useHistory } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../../../constants";

const List = observer(() => {
  const { paymentmethod, authorization } = useContext(StoreContext);
  const history = useHistory();

  useEffect(() => {
    if (authorization.isAuthenticated) {
      paymentmethod.get({});
    }
  }, [authorization.isAuthenticated]);

  const makeDefault = (id) => {
    paymentmethod.makeDefault({
      id: id,
      onSuccess: () => {
        paymentmethod.get({});
      },
    });
  };

  const addCard = () => {
    history.push(APPLICATION_ROUTES.MY_ACCOUNT.PAYMENT_METHOD);
  };

  return (
    <Layout>
      <div className="wrapper-m mt-05 mb-10 text-m-a">
        <div className={cs(classes.headingContainer)}>
          <div className={cs(classes.headingItem, classes.headingItemContent)}>
            <h1 className="">My Payment Methods</h1>
            <div className="mb-05 mt-05">
              <Button
                disabled={paymentmethod.getIsLoading(ACTIONS.PAYMENTMETHOD.GET_TOKEN)}
                size="s"
                onClick={() => {
                  addCard();
                }}
              >
                {paymentmethod.getIsLoading(ACTIONS.PAYMENTMETHOD.GET_TOKEN) ? "Loading..." : "Add Payment Method"}
              </Button>
            </div>
            <div className="mb-02">
              <p className="mt-02 mb-02">
                Put a Payment Method (Credit card or PayPal) on file to make checkouts a breeze.
              </p>
              <p className="mt-02 mb-02">Your payment info is safely encrypted and secure.</p>
              <p className="mt-02 mb-02">
                When you add a new payment method it will automatically become the default card that the system will
                charge. You always have the option of manually changing to a different payment at any time.
              </p>
            </div>
          </div>
        </div>

        <div className="mt-07 mb-07">
          {authorization.isAuthenticated ? (
            <>
              {paymentmethod.items.length ? (
                <div className={cs(classes.cardContainer)}>
                  {paymentmethod.items.map((item, index) => {
                    // if (item.card_number == null) {
                    //   return null;
                    // }

                    return (
                      <div key={index} className={cs(classes.cardItem)}>
                        <div className={cs(classes.numberContainer)}>
                          {item.payment_type == "PayPal" ? (
                            <>
                              <div className={cs(classes.numberItem)}>PayPal</div>
                              <div className={cs(classes.numberItem)}>{item.email}</div>
                            </>
                          ) : (
                            <>
                              <div className={cs(classes.numberItem)}>{item.card_number.substring(0, 4)}</div>
                              <div className={cs(classes.numberItem)}>{item.card_number.substring(4, 8)}</div>
                              <div className={cs(classes.numberItem)}>{item.card_number.substring(8, 12)}</div>
                              <div className={cs(classes.numberItem)}>{item.card_number.substring(12, 16)}</div>
                            </>
                          )}
                        </div>

                        {item.payment_type != "PayPal" ? (
                          <div className={cs(classes.infoContainer)}>
                            <div className={cs(classes.infoItem)}>{item.date_to}</div>
                            <div className={cs(classes.infoItem)}>{item.card_type}</div>
                          </div>
                        ) : (
                          <div style={{ visibility: "hidden" }} className={cs(classes.infoContainer)}>
                            .
                          </div>
                        )}

                        <div className={cs(classes.actionContainer)}>
                          <div className={cs(classes.actionItem)}>
                            {item.is_deafult == 1 ? (
                              <span className={cs("fw-700 c-orange")}>Default</span>
                            ) : (
                              <Button
                                disabled={paymentmethod.loadingIds.includes(item.id)}
                                onClick={() => makeDefault(item.id)}
                                size="xs"
                              >
                                {paymentmethod.loadingIds.includes(item.id) ? "Please wait..." : "Make Default"}
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <>
                  {paymentmethod.getIsLoading(ACTIONS.PAYMENTMETHOD.GET_PM) ? (
                    <p className="text-xl">Please wait...</p>
                  ) : (
                    <div className="heading-s">
                      You have no payment methods.{" "}
                      <Link className="c-orange" to={APPLICATION_ROUTES.MY_ACCOUNT.PAYMENT_METHOD}>Click here</Link> to add payment method.
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <div className="heading-m">
              Please{" "}
              <Link
                class="c-orange"
                to={APPLICATION_ROUTES.SIGN_IN + "?redirect=" + APPLICATION_ROUTES.MY_ACCOUNT.PAYMENT_METHOD_ALL}
              >
                Login
              </Link>{" "}
              to view your payment methods.
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
});

export default React.memo(List);
