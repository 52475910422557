import { useCallback, useState } from "react";

import { createState, validate } from "./utilities";

const useDoubleInput = ({
  first,
  labelOfFirst,
  labelOfSecond,
  second,
  validatorsOfFirst = [],
  validatorsOfSecond = [],
  emailValue,
} = {}) => {
  const [state, setState] = useState(
    createState({ validatorsOfFirst, validatorsOfSecond, emailValue })
  );

  // useEffect(() => {
  //   onChangeFirst({ target: { value: "" }});
  // }, []);

  const onChangeFirst = useCallback(
    ({ target: { value } }) => {
      setState((state) => {
        const errors = validate({
          validatorsOfFirst,
          validatorsOfSecond,
          valueOfFirst: value,
          valueOfSecond: state.valueOfSecond,
        });

        return {
          ...state,
          errorsOfFirst: errors.first,
          errorsOfSecond: errors.second,
          touchedFirst: true,
          valueOfFirst: value,
        };
      });
    },
    [setState]
  );

  const onChangeSecond = useCallback(
    ({ target: { value } }) => {
      setState((state) => {
        const errors = validate({
          validatorsOfFirst,
          validatorsOfSecond,
          valueOfFirst: state.valueOfFirst,
          valueOfSecond: value,
        });

        return {
          ...state,
          errorsOfFirst: errors.first,
          errorsOfSecond: errors.second,
          touchedSecond: true,
          valueOfSecond: value,
        };
      });
    },
    [setState]
  );

  return {
    [`errorOf${first}`]:
      state.touchedFirst && state.errorsOfFirst.length
        ? state.errorsOfFirst[0]
        : "",
    [`errorOf${second}`]:
      state.touchedSecond && state.errorsOfSecond.length
        ? state.errorsOfSecond[0]
        : "",
    [`errorsOf${first}`]: state.errorsOfFirst,
    [`errorsOf${second}`]: state.errorsOfSecond,
    [`labelOf${first}`]: labelOfFirst,
    [`labelOf${second}`]: labelOfSecond,
    [`touched${first}`]: state.touchedFirst,
    [`touched${second}`]: state.touchedSecond,
    [`valueOf${first}`]: state.valueOfFirst,
    [`valueOf${second}`]: state.valueOfSecond,
    [`onChange${first}`]: onChangeFirst,
    [`onChange${second}`]: onChangeSecond,
  };
};

export default useDoubleInput;
