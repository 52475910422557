import { useCallback, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { APPLICATION_ROUTES, COOKIES, PROJECTS } from "../../../constants";
import { StoreContext } from "../../../contexts";
import { useCheckbox } from "../../../hooks";
import { ACTIONS } from "../../../store";
import Cookies from "../../../utilities/cookies";
import { scrollToTop } from "../../../utilities/helpers";
import useForm from "./useForm";
import { getDots, getPackageView, getSteps } from "./utilities";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const useOrder = () => {
  const { cart, authorization } = useContext(StoreContext);

  let isLoading =
    cart[`isLoading${ACTIONS.CART.ADD}`] ||
    cart[`isLoading${ACTIONS.CART.EDIT}`] ||
    authorization[`isLoading${ACTIONS.AUTHORIZATION.AUTHENTICATE_PASSWORD}`];

  const { t } = useTranslation();
  const I_WANT_TO_DONATE_ANONYMOUSLY = t(
    "partners.i_want_to_donate_anonymously"
  );
  const { plan, interval } = useParams();

  const type = plan || PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID;

  const history = useHistory();
  const onNavigateToPackages = useCallback(() => {
    history.push(APPLICATION_ROUTES.PARTNERS);
    scrollToTop();
  }, [history]);

  const onNavigateToPayment = useCallback(() => {
    history.push(APPLICATION_ROUTES.CHECKOUT);
    scrollToTop();
  }, [history]);

  const onNavigateToSignIn = useCallback(() => {
    history.push(
      `${APPLICATION_ROUTES.SIGN_IN}?redirect=${encodeURIComponent(
        `${APPLICATION_ROUTES.PARTNERS_ORDER}/${type}/${interval}&login=true`
      )}`
    );
    scrollToTop();
  }, [history, type]);

  const form = useForm();
  const anonymous = useCheckbox({
    callback: ({ checked }) => {
      Cookies.set({
        name: COOKIES.ANONYMOUS_PAYMENT,
        value: checked ? "true" : "",
      });
    },
    checked: Boolean(Cookies.get({ name: COOKIES.ANONYMOUS_PAYMENT })),
    label: I_WANT_TO_DONATE_ANONYMOUSLY,
  });

  const view = getPackageView(type);

  const [stepIndex, setStepIndex] = useState(0);
  const steps = getSteps({ form });
  const hasNextStep = stepIndex < steps.length - 1;
  const hasPreviousStep = stepIndex > 0;
  const step = steps[stepIndex];
  const dots = getDots(steps, stepIndex + 1).map((item) => ({
    ...item,
    title: t(item.title_translation_key),
  }));
  const onDecrementIndex = () => {
    setStepIndex((value) => value - 1);
  };
  const onIncrementIndex = () => {
    setStepIndex((value) => value + 1);
  };

  const onSubmitAndNavigateToPayment = () => {
    form.onSubmit({
      onSuccess: function () {
        authorization.resignIn();
        onNavigateToPayment();
      },
    });
  };

  const onGoNext = hasNextStep
    ? onIncrementIndex
    : onSubmitAndNavigateToPayment;
  const onGoPrevious = () => {
    if (step.isNotAddressScreen || form.addAddress) {
      form.setAddAddress(false);
    } else {
      hasPreviousStep ? onDecrementIndex() : onNavigateToPackages();
    }
  };

  return {
    anonymous: anonymous,
    backURL: APPLICATION_ROUTES.PARTNERS_PACKAGES,
    dots: dots,
    isLoading: isLoading,
    hasNextStep: hasNextStep,
    step: step,
    type: type,
    view: view,
    onGoNext: onGoNext,
    onGoPrevious: onGoPrevious,
    onNavigateToSignIn: onNavigateToSignIn,
    page: "partners",
  };
};

export default useOrder;
