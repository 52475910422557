import React, { useContext } from "react";
import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";

import classes from "./Preview.module.scss";
import cs from "classnames";
import { observer } from "mobx-react-lite";
import { isMobile } from "react-device-detect";
import { StoreContext } from "../../../../../../../../../contexts";
import {
  getDetails,
  getURL,
} from "../../../../../../../Library/components/Project/components/Preview/utilities";
import { formatJewishDate } from "../../../../../../../../../utilities/helpers";
import { APPLICATION_ROUTES } from "../../../../../../../../../constants";
import Icon from "../../../../../../../../atoms/Icon";

const Preview = observer(
  ({
    locales,
    categories,
    questions,
    tags,
    file,
    holiday,
    placeholder,
    internal_id,
    onSelect,

  }) => {
    // const {
    //   // t,
    //   i18n: { language },
    // } = useTranslation();
    const { playlist, authorization } = useContext(StoreContext);

    const downloadFile = (url) => {
      window.open(url);
    };

    // const submitAnswer = (e) => {
    //   playlist.setCurrentPlayingVideoId(details.id);
    //   onSelect({
    //     end: details.end_time,
    //     start: details.start_time,
    //     url: getURL(details.link, { start: details.start_time }),
    //     name: details.name,
    //     description: details.description,
    //     number: details.number,
    //     categories: categories,
    //     questions: questions,
    //     tags: tags,
    //     holiday: holiday,
    //     date: details.created_at,
    //     file: file,
    //   });
    //   videoAction.showQuestionScreen(e);
    // };

    const sharePage = async () => {
      try {
        await navigator.share({
          url: window.location.origin + "/" + details.video_url,
          title: details.title,
        });
      } catch (e) {
        console.error(e);
      }
    };

    // const details = getDetails(language, locales);
    const details = getDetails("en", locales);

    // let time = new Date(details.showing_date + " 00:00:00");
    // let time = new Date();
    let time = new Date(details.showing_date == null ? details.created_at : details.showing_date + " 00:00:00");
    time = formatJewishDate(time, false, true);
    console.log(time, "time")

    return (
      <div className={classes.container}>
        <div className={classes.videoInfoWrap}>
          <span className={classes.videoInfoText}>
            <strong>#{details.number}</strong> | {time}
          </span>

          <div className={classes.videoInfoButtons}>

            {file && (
              <div
                onClick={() => {
                  if (authorization.isAuthenticated) {
                    downloadFile(file);
                  } else {
                    if (
                      !location.pathname.includes(
                        APPLICATION_ROUTES.LIBRARY_PROECTED
                      )
                    ) {
                      playlist.setCurrentPlayingVideoId(details.id);
                      onSelect({
                        end: details.end_time,
                        start: details.start_time,
                        url: getURL(details.link, {
                          start: details.start_time,
                        }),
                        name: details.name,
                        description: details.description,
                        number: details.number,
                        categories: categories,
                        questions: questions,
                        tags: tags,
                        holiday: holiday,
                        date: details.created_at,
                        file: details.file,
                        internal_id: internal_id,
                      });
                    }
                  }
                }}
                className={cs(classes.pdfIcon)}
              >
                <Icon name="pdf" />
              </div>
            )}
            <div onClick={sharePage} className={classes.shareIcon}>
              <Icon name="share-maroon" />
            </div>
          </div>
        </div>
        <div
          className={cs(classes.preview, "curs-pointer", isMobile ? classes.previewMobile : "")}
          role="button"
          style={{
            backgroundImage: `url(${details.thumbnail || placeholder})`,
          }}
          onClick={() => {
            playlist.setCurrentPlayingVideoId(details.id);
            onSelect({
              end: details.end_time,
              start: details.start_time,
              url: getURL(details.link, { start: details.start_time }),
              name: details.name,
              description: details.description,
              number: details.number,
              categories: categories,
              questions: questions,
              tags: tags,
              holiday: holiday,
              date: details.created_at,
              file: file,
              internal_id: internal_id,
            });
          }}
        >
          <div
            className={cs(classes.playerControlWrapper)}
          >
            <Icon className={cs(classes.playerPlay)} name="pink-play" />
            {details.duration_formatted && (
              <span className={cs(classes.videoTime)}>
                {details.duration_formatted}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
);

Preview.propTypes = {
  id: PropTypes.string.isRequired,
  locales: PropTypes.shape({
    // en: PropTypes.shape({
    //   link: PropTypes.string.isRequired,
    // }),
  }).isRequired,
  placeholder: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  videoAction: PropTypes.object,
};

export default React.memo(Preview);
