import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import cs from "classnames";

import Button from "../../../../atoms/Button";
import Icon from "../../../../atoms/Icon";
import Tooltip from "../../../../molecules/Tooltip";
import { scrollToTop } from "../../../../../utilities/helpers";
import classes from "./Item.module.scss";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import popular from "../../../../../assets/images/mostPopular.png";
import { useQuery } from "../../../../../hooks";
import { StoreContext } from "../../../../../contexts";
import { APPLICATION_ROUTES } from "../../../../../constants";

const Item = ({
  cost,
  features,
  illustration,
  marked,
  title,
  url,
  gradientClass,
  // canSelect,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const { authorization } = useContext(StoreContext);

  if (query.get("action")) {
    url += "&action=" + query.get("action");
  }

  console.log(marked, "title")

  return (
    <div
      className={cs(
        classes.container,
        classes.lightBgColorGradient,
        isMobile ? classes.mobileContainer : "",
        { [classes.marked]: marked }
      )}
    >
      {marked && (
        <div
          className={cs(
            isMobile ? "" : classes.bgBrownColor,
            isMobile ? "" : classes.background,
            "br-06"
          )}
        >
          {isMobile ? (
            <MobileView>
              <div className={cs(classes.tape, classes.popularIcon, "fw-700")}>
                <img src={popular} />
              </div>
            </MobileView>
          ) : (
            <BrowserView>
              <div className={cs(classes.tape, classes.popularIcon, "fw-700")}>
                <img className={cs(classes.maxWidth130)} src={popular} />
              </div>
            </BrowserView>
          )}
        </div>
      )}

      <div className="d-flex fd-column ml-auto mr-auto">
        <div className="pos-relative">
          <div
            className={cs(classes.illustration, "d-flex jc-center ai-center")}
          >
            <img
              alt={t(title.key)}
              className={classes.image}
              src={illustration}
            />
          </div>
          <h3
            className={cs("heading-s-a ta-center c-blue-dark", gradientClass)}
          >
            {t(title.key)}
          </h3>
        </div>
        <div className={cs(classes.packageFeatures, "ai-center mt-05 mb-06")}>
          <ul>
            {features.map((subitem) => (
              <li
                key={subitem.title.key}
                className={cs(classes.packageList, "d-flex ai-center mt-03")}
              >
                <div
                  className={`as-start mr-02 ${marked ? "c-orange" : "c-orange"
                    }`}
                >
                  <Icon name={subitem.icon} size="m" />
                </div>
                <p className={cs("m-0", subitem.classes)}>
                  {t(subitem.title.key, subitem.title.values)}
                </p>
                {subitem.tooltip.key && (
                  <div
                    style={{ marginTop: "6px" }}
                    className={cs(
                      classes.tooltip,
                      "ml-02 as-start c-grey-dark"
                    )}
                  >
                    <Tooltip
                      message={
                        <Trans
                          components={{ a: <br /> }}
                          i18nKey={subitem.tooltip.key}
                          t={t}
                        />
                      }
                      icon={subitem.tooltip.icon}
                    >
                      <Icon name="question-mark-in-circle" size="xxs" />
                    </Tooltip>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className={cs(classes.boundaryLayout, "mt-auto")}></div>
        {/* <hr className={cs(classes.boundary, "mt-auto mr-auto mb-04 ml-auto")} /> */}
        <div>
          <div
            className={cs(
              "ta-center fd-column ai-center mt-04",
              classes.cakePrice,
              {
                // [classes.lineThrough]: canSelect,
              }
            )}
          >
            {t("pages.birthdays.main.sections.packages.texts.5", {
              value: cost,
            })}
          </div>
          <>
            {/*{canSelect && <div className="ta-center mt-02 text-xl">Free!</div>}*/}
            <div className="d-flex fd-column ai-center mt-04">
              {isMobile ? (
                <MobileView>
                  <button
                    className={classes.mobileBtn}
                    onClick={() => {
                      if (authorization.user === null) {
                        history.push(
                          `${APPLICATION_ROUTES.SIGN_IN}?redirect=${url}`
                        );
                      } else {
                        history.push(url);
                        scrollToTop();
                      }
                    }}
                  >
                    Select Package
                  </button>
                </MobileView>
              ) : (
                <BrowserView>
                  <Button
                    size="s"
                    color={marked ? "birthdayButtonMarked" : "birthdayButton"}
                    onClick={() => {
                      if (authorization.user === null) {
                        history.push(
                          `${APPLICATION_ROUTES.SIGN_IN}?redirect=${url}`
                        );
                      } else {
                        history.push(url);
                        scrollToTop();
                      }
                    }}
                  >
                    Select Package
                  </Button>
                </BrowserView>
              )}
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

Item.propTypes = {
  cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  // covers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  features: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  illustration: PropTypes.string.isRequired,
  marked: PropTypes.bool.isRequired,
  gradientClass: PropTypes.string.isRequired,
  title: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }).isRequired,
  url: PropTypes.string.isRequired,
  // canSelect: PropTypes.bool,
};

export default Item;
