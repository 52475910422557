import { observer } from "mobx-react-lite";
import React from "react";

import cs from "classnames";
import classes from "./LegacyGift.module.scss";
import maorLogo from "../../../assets/images/logotype-maor.png";
import pdf from "../../../assets/pdf/Sample-Bequest-Wording-Maor.pdf";
import { PAGE_TITLES } from "../../../constants";
import PageTitle from "../../atoms/PageTitle";
import Layout from "../../templates/Layout";

const LegacyGift = observer(() => {
  return (
    <>
      <PageTitle value={PAGE_TITLES.LEGACY_GIFT} />
      <Layout logotype="maor">
        <div className={cs("text-m-a", "bgc-gold", )}>
          <div className={cs(classes.container, "wrapper-m")}>
            <div className={cs(classes.whiteContainer)}>
              <img src={maorLogo} alt="maor-logo" />
              <div className={cs(classes.headingContainer)}>
                <h3>Legacy Gift</h3>
              </div>
              <div className={cs(classes.bodyContainer)}>
                <p>
                  Would you like to create a lasting, personal legacy you’ll be
                  proud of forever? Make a charitable bequest in your will to
                  Maor.org.
                </p>
                <p>
                  Your gift will ensure a vibrant Chassidic tomorrow for
                  thousands across the globe for generations to come.
                </p>

                <p className={cs(classes.title, "fw-700")}>
                  {" "}
                  It&apos;s Easier Than You Think{" "}
                </p>

                <p>
                  A bequest is one of the easiest and most impactful ways to
                  leave a legacy. Even if you&apos;re someone of modest means,
                  your gift can go a long way.
                </p>

                <p>
                  <a href={pdf} download={true}>
                    Click here
                  </a>{" "}
                  for Sample Bequest Wording.
                </p>

                <p>Here&apos;s how a bequest works:</p>

                <div className={cs(classes.list)}>
                  <ul>
                    <li>
                      <p>
                        A bequest does not cost you anything during your
                        lifetime
                      </p>
                    </li>
                    <li>
                      {" "}
                      <p>
                        With a{" "}
                        <span className={cs(classes.bold)}>
                          Residuary Bequest,
                        </span>{" "}
                        you designate a percentage of your estate to Maor.
                      </p>
                    </li>
                    <li>
                      {" "}
                      <p>
                        With an{" "}
                        <span className={cs(classes.bold)}>
                          {" "}
                          Outright Bequest,
                        </span>{" "}
                        you leave a specific dollar amount or a specific asset,
                        like securities or real estate, to Maor.
                      </p>
                    </li>
                    <li>
                      {" "}
                      <p>
                        After your passing, your trustee will transfer the
                        bequest money or real estate to Maor.
                      </p>
                    </li>
                    <li>
                      {" "}
                      <p>
                        Bequests are revocable and can be changed or modified
                        during your lifetime{" "}
                      </p>
                    </li>
                    <li>
                      <p>Bequests relieve the tax burden on your family</p>
                    </li>
                    <li>
                      {" "}
                      <p>
                        This is the most effective way to leave a Chassidishe
                        legacy
                      </p>
                    </li>
                  </ul>
                </div>

                <div className={cs(classes.space)}>
                  <p className={cs(classes.title, "fw-700")}>
                    We&apos;re Here To Help
                  </p>

                  <p>
                    We’d be honored to work with you, your family and/or your
                    financial adviser/attorney, to develop a personalized
                    strategy for your charity-giving. Please email Rabbi Chanoch
                    Chaskind at: chanoch@maor.org or call us at: +1-929-333-0248
                    so we can set up a time to talk.
                  </p>
                  <p>
                    If you have been so generous as to include a bequest to Maor
                    as part of your estate plan, please take the time to let us
                    know. <br />
                    We would like to recognize you and your family for your
                    generosity.
                  </p>
                </div>

                <div className={cs(classes.space)}>
                  <p className={cs(classes.title, "fw-700")}>
                    {" "}
                    More About Your Bequest
                  </p>
                  <p>
                    The method used to make a bequest will depend on the kind of
                    gift you choose to leave to Maor.
                  </p>

                  <p>
                    Bequests of real estate, personal property, business
                    interests and cash are typically made by way of a will,
                    revocable trust or even a simple codicil to your current
                    estate plan. Your estate-planning attorney can assist you in
                    preparing the necessary papers for you to complete your
                    bequest.(
                    <a href={pdf} download={true}>
                      Click here
                    </a>{" "}
                    for Sample Bequest Wording.)
                  </p>

                  <p>
                    Other bequests, such as those involving retirement assets,
                    insurance policies, bank accounts and stocks and bonds, are
                    typically made by completing a beneficiary designation form.
                    Simply contact your retirement plan administrator, life
                    insurance company, bank or investment broker and ask them to
                    send you the right &quot;beneficiary designation&quot; or
                    &quot;payable upon death&quot; form. To complete your
                    bequest, you will need to complete and sign the form and
                    return it to them.
                  </p>

                  <p>
                    The last step in leaving any bequest involves the transfer
                    to charity. Upon your passing, full value of the gift will
                    be transferred to Maor tax-free and your estate will receive
                    an estate tax charitable deduction.
                  </p>
                </div>

                <div className={cs(classes.space)}>
                  <p className={cs(classes.title, "fw-700")}>
                    {" "}
                    Sample Bequest Wording
                  </p>

                  <p>
                    I bequeath to Maor, 478 Albany Ave. Suite #106 Brooklyn, NY
                    11203,
                  </p>

                  <p>
                    the residue of my estate after all other obligations have
                    been fulfilled,
                  </p>

                  <p> OR </p>

                  <p>the sum of ______ dollars,</p>

                  <p> OR</p>

                  <p> a percentage of _____ of my residuary estate,</p>

                  <p>
                    {" "}
                    to be used by Maor, for its charitable purposes of providing
                    Jewish education resources to users worldwide.{" "}
                  </p>

                  <p> Maor, Tax ID #32-0500422, is a 501(c)(3).</p>

                  <div className={cs(classes.space)}>
                    <p className={cs(classes.italic)}>
                      When planning or writing a will, please contact an estate
                      attorney who has experience in estate planning and make
                      sure to discuss your wishes with your family and financial
                      advisor.
                    </p>

                    <a className={cs("fw-700")} href={pdf} download={true}>
                      <p> Click here to download</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
});

export default React.memo(LegacyGift);
