import React from "react";

import cs from "classnames";
import classes from "./Feature.module.scss";
import { observer } from "mobx-react-lite";
import vector from "../../../../../../../assets/images/Vector.png";
import school from "../../../../../../../assets/images/school.png";
import take from "../../../../../../../assets/images/take.png";
import money from "../../../../../../../assets/images/money.png";
import group from "../../../../../../../assets/images/group.png";
import flag from "../../../../../../../assets/images/flag.png";

const Feature = observer(() => {
  return (
    <div className={cs(classes.container, "bgc-white")}>
      <div className={cs(classes.itemContainer)}>
        <img src={vector} alt="vector" />
        <div className={cs(classes.itemInfo)}>
          <h4>4 Languages</h4>
          <p>
            English, Hebrew, French <br /> and Russian
          </p>
        </div>
      </div>
      <div className={cs(classes.itemContainer)}>
        <img src={group} alt="vector" />
        <div className={cs(classes.itemInfo)}>
          <h4>16 People and 40 Hours</h4>
          <p>
            Are needed to create an <br /> average MyMaor clip 
          </p>
        </div>
      </div>
      <div className={cs(classes.itemContainer)}>
        <img src={take} alt="vector" />
        <div className={cs(classes.itemInfo)}>
          <h4> 3500+ Video Clips</h4>
          <p>Produced to date</p>
        </div>
      </div>
      <div className={cs(classes.itemContainer)}>
        <img src={money} alt="vector" />
        <div className={cs(classes.itemInfo)}>
          <h4>$513,000</h4>
          <p>Annual operating budget</p>
        </div>
      </div>
      <div className={cs(classes.itemContainer)}>
        <img src={school} alt="vector" />
        <div className={cs(classes.itemInfo)}>
          <h4>75 Schools</h4>
          <p>
            Showed videos in <br /> their classrooms
          </p>
        </div>
      </div>

      <div className={cs(classes.itemContainer)}>
        <img src={flag} alt="vector" />
        <div className={cs(classes.itemInfo)}>
          <h4>51 Countries</h4>
          <p>
            Where Maor videos <br /> are watched
          </p>
        </div>
      </div>
    </div>
  );
});

export default React.memo(Feature);
