import { useState, useContext, useCallback } from "react";
// import { useHistory } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../../constants";
import { StoreContext } from "../../../contexts";
import { ACTIONS } from "../../../store";

const useMyMaor = () => {
  const { library } = useContext(StoreContext);
  let libraryUrl = new URL(location.href);

  const [keyword, setKeyword] = useState(
    libraryUrl.searchParams.get("keyword") ?? ""
  );
  const [order, setOrder] = useState("new");
  const [holidayId, setHolidayId] = useState(0);
  const [holidayLabel, setHolidayLabel] = useState("");
  const [coloringPagesValue, setColoringPagesValue] = useState(false);
  // const history = useHistory();

  let search = {
    onChange: (value) => {
      setKeyword(value);
    },
    value: keyword,
  };

  let searching = {
    options: library.searchingOptions,
    onSearch: useCallback((query) => {
      library[`getSearchingOptions`](query);
    }),
    isLoading: library[`isLoading${ACTIONS.LIBRARY.SEARCHING_OPTIONS}`],
    error: library[`error${ACTIONS.LIBRARY.SEARCHING_OPTIONS}`],
  };

  let sorting = {
    onChange: ({ target: { value } }) => {
      setOrder(value);
    },
    value: order,
  };

  let holiday = {
    onChange: ({ target: { dataset, value } }) => {
      setHolidayId(value);
      setHolidayLabel(dataset.filterlabel);
    },
    value: holidayId,
    label: holidayLabel,
  };

  const setVideoRequest = useCallback((payload) => {
    library[`setVideoRequest`](payload);
  }, []);

  const video = {
    // onLoad: useCallback(({ projectType = "" }) => {
    //   library[`getVideo`]({ projectType });
    // }, [])
    onLoad: useCallback(() => {
      library[`getVideo`]();
    }, [])
    ,
    record: library[`video`],
    isLoading: library[`isLoading${ACTIONS.LIBRARY.GET_VIDEO}`],
    error: library[`error${ACTIONS.LIBRARY.GET_VIDEO}`],
  };

  const coloringPages = {
    onChange: (value) => {
      setColoringPagesValue(value);
    },
    value: coloringPagesValue,
  };

  const onNavigateToSearch = (coloringPages = false, searchVal = "", routes = APPLICATION_ROUTES.MY_MAOR_LIBRARY) => {
    console.log(routes, "okk")
    let libraryUrl = new URL(
      location.protocol + "//" + location.host + routes
    );

    if (search.value) {
      libraryUrl.searchParams.set("keyword", search.value);
    }

    if (searchVal != "") {
      libraryUrl.searchParams.set("keyword", searchVal);
    }

    if (coloringPages) {
      libraryUrl.searchParams.set("coloring-pages", true);
    }
    // if (sorting.value) {
    //   libraryUrl.searchParams.set("order", sorting.value);
    // }

    // libraryUrl.searchParams.set("coloring-pages", true);
    // if (holiday.value) {
    //   libraryUrl.searchParams.set("holiday", holiday.value);
    //   libraryUrl.searchParams.set("holiday-label", holiday.label);
    // }
    window.open(routes + libraryUrl.search, "_self");
    // console.log();
    // history.push(APPLICATION_ROUTES.LIBRARY + libraryUrl.search);
    // console.log("Search:", libraryUrl.href);
    // history.push({
    //   pathname: APPLICATION_ROUTES.LIBRARY,
    //   search: libraryUrl.search,
    // });
  };

  return {
    coloringPages,
    search,
    searching,
    sorting,
    holiday,
    video,
    setVideoRequest,
    onNavigateToSearch,
  };
};

export default useMyMaor;
