/* eslint-disable prettier/prettier */
import React, { useCallback, useContext, useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

import { APPLICATION_ROUTES } from "../../../../../../../constants";
import { StoreContext } from "../../../../../../../contexts";
import Braintree from "./components/Braintree";
import Modal from "../../../../../../organisms/Modal";
import Icon, { NAMES } from "../../../../../../atoms/Icon";
import SignInPage from "../../../../../SignInPage";
import classes from "./Gateway.module.scss";
// import { useParams } from "react-router-dom";

const Gateway = observer(() => {
  // const history = useHistory();
  const { paymentmethod, checkout, common } = useContext(StoreContext);

  // const { partnership } = useParams();

  const isDonation =
    common.get("options") === "donation_monthly" ||
    common.get("options") === "donation_yearly" ||
    common.get("options") === "donation" ||
    common.get("options") === "donation_monthly_pledge";

  const onNavigateToThankYou = useCallback(() => {
    // history.push(APPLICATION_ROUTES.PARTNERS_SUCCESS);
    if (isDonation) {
      window.location = APPLICATION_ROUTES.DONATION_SUCCESS;
    } else {
      window.location = APPLICATION_ROUTES.PARTNERS_SUCCESS;
    }
  }, []);

  const {
    i18n: { language },
  } = useTranslation();

  const onInitialize = useCallback((...args) => {
    checkout.initialize(...args);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const isAuthorize = common.get("isAuthorize");

  useEffect(() => {
    paymentmethod.get({});
  }, []);

  return (
    <>
      {!isAuthorize && (
        <p>
          {paymentmethod.items.length == 0 ? <>Choose a way to pay</> : ""}
          {!isDonation && paymentmethod.items.length > 0 && (
            <span>
              <u
                style={{
                  color: "#FF8A34",
                  cursor: "pointer",
                  fontWeight: "600",
                }}
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >Authorize</u> {isModalOpen}
              to use your saved payment method.
            </span>
          )}
        </p>
      )}

      <Modal opened={isModalOpen}>
        <div className={classes.wrapper}>
          <div className={classes.card}>
            <span className={classes.close}>
              <Icon
                name={NAMES.X}
                className={classes.crossStyle}
                onClick={() => setIsModalOpen(false)}
              />
            </span>
            <SignInPage
              reauthenticate={true}
              setClosePopUp={setIsModalOpen}
              partnersCheckout={true}
            />
          </div>
        </div>
      </Modal>

      <Braintree
        locale={language}
        onInitialize={onInitialize}
        onSaleSuccess={onNavigateToThankYou}
      />
    </>
  );
});

export default Gateway;
