import React from 'react'
import cs from "classnames";
import BirthdayPackages from '../../organisms/BirthdayPackages/BirthdayPackages';
import classes from "./BirthdayPackagesPage.module.scss"
import ballon from "../../../assets/svg/ballons.svg";
import gift from "../../../assets/svg/birthdayGift.svg";
import { isMobile } from 'react-device-detect';

const BirthdayPackagesPage = () => {
    return (
        // <main className={cs("minh-100vh")}>
        <div className={cs("minh-100vh", classes.packagesSec, isMobile ? classes.mobilePackagesSec : "")}>
            <div
                className={cs(
                    classes.wrapper,
                    "d-flex fd-column jc-center ai-center"
                )}
            >
                <div id="birthday-package" className={cs(classes.sectionTitle)}>
                    <div>
                        <img src={gift} className={cs(classes.leftImg)} />
                    </div>
                    <div>
                        <h2 className={cs(classes.pinkColor, "mb-06")}>
                            Make Your child&apos;s birthday special!
                        </h2>
                        <h3>
                            Take your childs special day to the next level by combining{" "}
                            <span className={cs(classes.greenColor, "fw-700")}>
                                meaning & a thrilling gift.
                            </span>
                        </h3>
                    </div>
                    <div>
                        <img src={ballon} className={cs(classes.rightImg)} />
                    </div>
                </div>
            </div>
            <BirthdayPackages />
        </div>
        // </main>
    )
}

export default BirthdayPackagesPage