import React from "react";

import Icon, { NAMES } from "../../atoms/Icon";
import classes from "./BackButton.module.scss";

const BackButton = ({ ...properties }) => (
  <button className={classes.container} {...properties}>
    <Icon name={NAMES.ARROW_IN_CIRCLE} size="l" />
  </button>
);

export default BackButton;
