import React, { cloneElement, useState } from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import Popover, { usePopover } from "../../organisms/Popover";
import { ATTRIBUTES, COLORS, POSITIONS, SIZES, createID, getAngle } from "./utilities";
import classes from "./Tooltip.module.scss";
import Icon from "../../atoms/Icon";

const Tooltip = ({ children, color, message, icon, position, size, ...properties }) => {
  const [id] = useState(createID());
  const tooltip = usePopover({ selector: `[${ATTRIBUTES.TOOLTIP}="${id}"]`, position: position });
  const angle = getAngle(position);

  const anchor = cloneElement(children, {
    [ATTRIBUTES.TOOLTIP]: id,
    onMouseEnter: tooltip.open,
    onMouseOut: tooltip.close,
  });

  console.log(message, icon)

  return (
    <>
      {anchor}
      <Popover style={tooltip.style} position={position} {...properties}>
        <div
          className={cs(classes.container, classes[angle], classes[color], classes[size], icon && classes.iconToolTip, icon == "message-tooltip" && classes.messageTooltip)}
          onMouseEnter={tooltip.open}
        onMouseOut={tooltip.close}
        >
          <>
          {icon ? 
          <div className="p-02">
            <Icon name={icon} size="custom-popp-img" />
            {message && message}
          </div> : null
          }
          {message && !icon &&
            message
          } 
          </>
        </div>
      </Popover>
    </>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(Object.values(COLORS)),
  message: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]).isRequired,
  position: PropTypes.oneOf(Object.values(POSITIONS)),
  size: PropTypes.oneOf(Object.values(SIZES)),
  icon: PropTypes.shape({
    type: PropTypes.oneOf(['img', 'svg']),
  }),
};

Tooltip.defaultProps = {
  color: COLORS.WHITE,
  position: POSITIONS.CENTER_TOP_OUT,
  size: SIZES.AUTO,
};

export default Tooltip;
