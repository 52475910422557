import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";
import cs from "classnames";

import PlainInput from "../../../../atoms/PlainInput";
import SelectR from "../../../../atoms/SelectR";
import classes from "./DeliveryDetails.module.scss";
import Autocomplete from "react-google-autocomplete";

import Loader from "../../../../atoms/Loader";
import { StoreContext } from "../../../../../contexts";
import { ACTIONS } from "../../../../../store";
import { useModal, useQuery } from "../../../../../hooks";
import { animateScroll as scroll } from "react-scroll";
import Icon, { NAMES } from "../../../../atoms/Icon";
import Modal from "../../../../organisms/Modal";
import Button, { COLORS } from "../../../../atoms/Button";
import { observer } from "mobx-react-lite";

// eslint-disable-next-line react/prop-types
const DeliveryDetails = observer(
  ({
    address_id,
    addressApartment,
    addressCity,
    addressCountry,
    addressState,
    addressStreet,
    addressZip,
    addAddress,
    setAddAddress,
    // hasDelivery,
    // hasDelivery,
    // location,
    setLocation,
    setDeleteAddressSuccess,
    deleteAddressSuccess,
  }) => {
    // const { t } = useTranslation();
    // const ADDRESS = t("projects.maor-kids.5_address");
    // const WHERE_IS_THE_GIFT_PACKAGE_GOING = t(
    //   "projects.maor-kids.where_is_the_gift_package_going"
    // );
    const [myAddresses, setMyAddresses] = useState(null);
    const { authorization } = useContext(StoreContext);
    const query = useQuery();
    const deleteModal = useModal();
    const [selectedAddressId, setSelectedAddressId] = useState(null);

    const deleteAddressError =
      authorization[`error${ACTIONS.AUTHORIZATION.DELETE_ADDRESS}`];
    const deleteAddressLoading =
      authorization[`isLoading${ACTIONS.AUTHORIZATION.DELETE_ADDRESS}`];

    console.log(
      authorization[`success${ACTIONS.AUTHORIZATION.DELETE_ADDRESS}`],
      "deleteAddressLoading"
    );

    const handleAddAddress = () => {
      setAddAddress(!addAddress);
    };

    useEffect(() => {
      setDeleteAddressSuccess(
        authorization[`success${ACTIONS.AUTHORIZATION.DELETE_ADDRESS}`]
      );
      if (deleteAddressSuccess) {
        deleteModal.onClose();
        authorization[`setSuccess`](
          ACTIONS.AUTHORIZATION.DELETE_ADDRESS,
          false
        );
      }
    }, [
      authorization[`success${ACTIONS.AUTHORIZATION.DELETE_ADDRESS}`],
      authorization[`isLoading${ACTIONS.AUTHORIZATION.DELETE_ADDRESS}`],
      authorization,
    ]);

    console.log(query.get("id"), "idddd");

    useEffect(() => {
      if (query.get("id")) {
        setAddAddress(true);
      }

      // if (authorization.address.length == 0) {
      //   setAddAddress(true);
      // }

      if (authorization.address.length !== 0 && !query.get("id")) {
        setAddAddress(false);
      }
    }, [
      authorization.address,
      authorization[`isLoading${ACTIONS.AUTHORIZATION.GET_ADDRESS}`],
    ]);

    useEffect(() => {
      setMyAddresses(authorization.address);
    }, [authorization[`isLoading${ACTIONS.AUTHORIZATION.GET_ADDRESS}`]]);

    const addNewAddress = () => {
      address_id.onChange({ target: { value: "" }, touched: false });
      addressState.onChange({ target: { value: "" }, touched: false });
      addressStreet.onChange({ target: { value: "" }, touched: false });
      addressCity.onChange({ target: { value: "" }, touched: false });
      addressZip.onChange({ target: { value: "" }, touched: false });
      addressApartment.onChange({ target: { value: "" }, touched: false });

      handleAddAddress();
    };

    if (authorization[`isLoading${ACTIONS.AUTHORIZATION.GET_ADDRESS}`]) {
      return (
        <div className={classes.container}>
          <div className={cs(classes.wrapper)}>
            <Loader />
          </div>
        </div>
      );
    }

    const handlePickAddressClick = (selectedAddress) => {
      address_id.onChange({ target: { value: selectedAddress.id } });
      addressState.onChange({ target: { value: selectedAddress.state } });
      addressStreet.onChange({ target: { value: selectedAddress.street } });
      addressCity.onChange({ target: { value: selectedAddress.city } });
      addressZip.onChange({ target: { value: selectedAddress.zip_code } });
      addressApartment.onChange({
        target: { value: selectedAddress.apartment },
      });

      handleAddAddress();
      scroll.scrollToTop();
    };

    const handlePlaceSelected = (place) => {
      console.log("Selected place", place);
      // Extract city, state, and country from address components
      const addressComponents = place.address_components || [];
      let city = getAddressComponent(addressComponents, "locality") || "";
      const state =
        getAddressComponent(addressComponents, "administrative_area_level_1") ||
        "";
      let country = getAddressComponent(addressComponents, "country") || "";
      const street_number =
        getAddressComponent(addressComponents, "street_number") || "";
      let street = getAddressComponent(addressComponents, "route") || "";
      const postalCode =
        getAddressComponent(addressComponents, "postal_code") || "";
      const subLocality =
        getAddressComponent(addressComponents, "sublocality_level_1") || "";

      if (
        country &&
        (country == "United States" || country == "USA" || country == "US")
      ) {
        country = { label: "USA", value: "USA" };
      }

      if (street_number) {
        street = `${street_number} ${street}`;
      }

      if (!city) {
        city = subLocality;
      }

      document.querySelector(".pac-target-input").value = street;

      setLocation({
        city,
        state,
        country,
        street,
        postalCode,
      });
    };

    const getAddressComponent = (addressComponents, type) => {
      const component = addressComponents.find((component) =>
        component.types.includes(type)
      );
      return component ? component.long_name : "";
    };

    const deleteAddress = () => {
      authorization[`${ACTIONS.AUTHORIZATION.DELETE_ADDRESS}`](
        selectedAddressId
      );
      console.log(
        "Delete child with ID:",
        selectedAddressId,
        deleteAddressSuccess
      );
      if (deleteAddressSuccess) {
        deleteModal.onClose();
      }
    };

    return (
      <div>
        <h2 className="heading-l ta-center c-blue-dark">
          Select your shipping address
        </h2>

        {!addAddress ? (
          <div className={classes.addressCardContainer}>
            <div className={classes.addAddressBox} onClick={addNewAddress}>
              <p style={{ fontSize: "32px" }}>+</p>
              <p style={{ fontSize: "18px", marginTop: "8px" }}>Add Address</p>
            </div>

            {myAddresses?.map((data, i) => {
              return (
                <div
                  className={classes.pickAddressBox}
                  key={i}
                  onClick={() => handlePickAddressClick(data)}
                >
                  <div
                    className={cs(classes.crossButton)}
                    onClick={(e) => {
                      setSelectedAddressId(data.id);
                      e.stopPropagation();
                      deleteModal.onOpen();
                    }}
                  >
                    <Icon name={NAMES.CrossButton} size="xxs" />
                  </div>
                  <div className={classes.addressBoxContent}>
                    <p style={{ fontSize: "16px" }}>
                      {data.street} {data.apartment}
                    </p>
                  </div>

                  <div className={classes.addressBoxContent}>
                    <p style={{ fontSize: "16px" }}>
                      {data.city}, {data.state}
                    </p>
                  </div>

                  <div className={classes.addressBoxContent}>
                    <p style={{ fontSize: "16px" }}>
                      {/* {data.country}  */}
                      {data.zip_code}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            <h3 className="heading-m mt-04 c-blue">Address</h3>
            <ul className={cs(classes.address, classes.top)}>
              <li className={cs(classes.item, classes.street)}>
                <PlainInput
                  error={addressStreet.error}
                  id="my-maor-order-form-address-street"
                  label={addressStreet.label}
                  stretched
                  value={addressStreet.value}
                  onChange={addressStreet.onChange}
                  customComponent={(additionalProps) => (
                    <Autocomplete
                      {...additionalProps}
                      options={{
                        types: ["address"],
                        fields: [
                          "address_components",
                          "geometry.location",
                          "place_id",
                          "formatted_address",
                        ],
                        componentRestrictions: { country: "us" },
                      }}
                      onChange={addressStreet.onChange}
                      defaultValue={addressStreet.value}
                      placeholder=""
                      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                      onPlaceSelected={handlePlaceSelected}
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                        borderColor: "#C1BBBB",
                        color: "#636363",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        padding: "17px 16px",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    />
                  )}
                />
              </li>
              <li className={cs(classes.item, classes.city)}>
                <PlainInput
                  error={addressApartment.error}
                  id="my-maor-order-form-address-apartment"
                  label={addressApartment.label}
                  value={addressApartment.value}
                  onChange={addressApartment.onChange}
                />
              </li>
            </ul>
            <ul className={cs(classes.address, classes.bottom)}>
              <li className={cs(classes.item, classes.city)}>
                <PlainInput
                  error={addressCity.error}
                  id="my-maor-order-form-address-city"
                  label={addressCity.label}
                  value={addressCity.value}
                  onChange={addressCity.onChange}
                />
              </li>
              <li className={cs(classes.item, classes.state)}>
                <PlainInput
                  error={addressState.error}
                  id="my-maor-order-form-address-state"
                  label={addressState.label}
                  value={addressState.value}
                  onChange={addressState.onChange}
                />
              </li>
              <li className={cs(classes.item, classes.zip)}>
                <PlainInput
                  error={addressZip.error}
                  id="my-maor-order-form-address-zip"
                  label={addressZip.label}
                  value={addressZip.value}
                  onChange={addressZip.onChange}
                />
              </li>
              <li className={cs(classes.item, classes.country)}>
                <SelectR
                  label={addressCountry.label}
                  options={addressCountry.options}
                  value={addressCountry.value}
                  onChange={addressCountry.onChange}
                />
                <small className={"ta-center w-100 d-block"}>
                  We are currently shipping only within USA.
                </small>
              </li>
            </ul>
          </div>
        )}
        <Modal opened={deleteModal.isOpened}>
          <div className={classes.deleteModalConatiner}>
            <div className={classes.deleteModalCard}>
              <div className={cs(classes.main)}>
                <h4 className="title">
                  Are you sure you want to delete this record?
                </h4>
              </div>
              {deleteAddressError && <p>Something Went Wrong</p>}
              <div className={cs("d-flex jc-center gap-10 mt-07")}>
                <button
                  className={cs(classes.addBtn, "fw-700 c-orange")}
                  onClick={deleteAddress}
                  disabled={deleteAddressLoading}
                >
                  {deleteAddressLoading ? (
                    "Please wait..."
                  ) : (
                    <>
                      <p>Confirm</p>
                    </>
                  )}
                </button>
                <Button color={COLORS.GHOST} onClick={deleteModal.onClose}>
                  <p>Cancel</p>
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
);

const inputPropTypes = PropTypes.shape({
  error: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  emptyErrors: PropTypes.func.isRequired,
}).isRequired;

const selectPropTypes = PropTypes.shape({
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  emptyErrors: PropTypes.func.isRequired,
  error: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  bool: PropTypes.bool,
  selectedValue: PropTypes.string,
}).isRequired;

DeliveryDetails.propTypes = {
  addressApartment: inputPropTypes,
  addressCity: inputPropTypes,
  addressCountry: selectPropTypes,
  addressState: inputPropTypes,
  addressStreet: inputPropTypes,
  addressZip: inputPropTypes,
  message: inputPropTypes,
  from: inputPropTypes,
  to: inputPropTypes,
  address_id: inputPropTypes,
  setAddAddress: selectPropTypes,
  addAddress: selectPropTypes,
  // hasDelivery: PropTypes.bool.isRequired,
  hasDelivery: PropTypes.bool.isRequired,
  setLocation: inputPropTypes,
  location: inputPropTypes,
  deleteAddressSuccess: selectPropTypes,
  setDeleteAddressSuccess: selectPropTypes,
};

export default DeliveryDetails;
