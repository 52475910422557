import React from "react";
import cs from "classnames";
import classes from "./SlideBar.module.scss";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { projectTypes } from "../../../molecules/LibrarySidebar/utilities";
import { useContext } from "react";
import { StoreContext } from "../../../../contexts";
// import OptionSelector from "../../../atoms/OptionSelector";

const SlideBar = observer(({ filterData, maorType, dailyRebbeType, holidayOptions, holiday }) => {
  useEffect(() => {
    filterData.categories.onLoad();
  }, []);
  const { common } = useContext(StoreContext);

  // Transforming data
  let projectTypesData = [];
  let categories = [];
  let handle;

  if (!filterData.categories.isLoading && !filterData.categories.isError) {
    projectTypes.forEach((projectType) => {
      projectTypesData[projectType.value] = [];
      filterData.categories.data[projectType.value].forEach((category) => {
        projectTypesData[projectType.value].push({
          id: `${projectType.id}-${category.id}`,
          label: category.name_english,
          value: Number(category.id),
        });
      });
    });
    console.log("projectTypesData", projectTypesData);
    switch (common.get("project")) {
      case "daily-rebbe-video":
        categories = projectTypesData["Daily Rebbe Video"];
        handle = dailyRebbeType;
        break;

      default:
        categories = projectTypesData["MyMaor"];
        handle = maorType;
        break;
    }
  }

  return (
    <section className={cs(classes.mobileTags)}>
      <ul className={cs(classes.tagsListWrap)}>
        {holidayOptions.length != 0 &&
          holidayOptions.map((item, index) => {
            const checked = item.value == holiday.value;
            return (
              <li key={item.id + "-" + index} className={classes.itemHoliday}>
                <input
                  data-filterlabel={item.label}
                  checked={checked}
                  className={classes.input}
                  id={item.id}
                  type="radio"
                  value={item.value}
                  onChange={holiday.onChange}
                />
                <label htmlFor={item.id} className={cs(classes.labelHoliday, checked ? classes.checkedHoliday : "")}>
                  {item.label}
                </label>
              </li>
            );
          })}
        {holidayOptions.length != 0 && "|"}
        {!filterData.categories.isLoading &&
          !filterData.categories.isError &&
          categories.map((item, index) => {
            const checked = item.value == maorType.value;

            return (
              <li
                data-filterlabel={item.label}
                value={handle.value}
                id={item.id}
                onClick={() => {
                  handle.onChange({
                    target: {
                      value: item.value,
                      dataset: {
                        filterlabel: item.label,
                      },
                    },
                  });
                }}
                key={`project-type-${index}`}
                className={cs(
                  classes.tagsList,
                  checked ? classes.checked : "",
                  common.get("project") == "daily-rebbe-video" ? classes.tagListDRV : ""
                )}
              >
                <span>{item.label}</span>
              </li>
            );
          })}
      </ul>
    </section>
  );
});

export default SlideBar;
