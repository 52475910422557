import { useCallback, useState } from "react";

const useModal = ({ opened: initialState = false } = {}) => {
  const [state, setState] = useState(initialState);

  const onClose = useCallback(() => {
    setState(false);
  }, []);
  const onOpen = useCallback(() => {
    setState(true);
  }, []);
  const onToggle = useCallback(() => {
    setState((state) => !state);
  }, []);

  return {
    isOpened: state,
    onClose: onClose,
    onOpen: onOpen,
    onToggle: onToggle,
  };
};

export default useModal;
