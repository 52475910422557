import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import classes from "./Addresses.module.scss";
import Autocomplete from "react-google-autocomplete";

import { animateScroll as scroll } from "react-scroll";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../../../../contexts";
import { ACTIONS } from "../../../../store";
import PlainInput from "../../../atoms/PlainInput";
import SelectR from "../../../atoms/SelectR";
import Modal from "../../../organisms/Modal";
import Button, { COLORS } from "../../../atoms/Button";
import Icon, { NAMES, SIZES } from "../../../atoms/Icon";
import Layout from "../../../templates/Layout";
import PageTitle from "../../../atoms/PageTitle";
import { APPLICATION_ROUTES, PAGE_TITLES } from "../../../../constants";
import { useModal, useQuery } from "../../../../hooks";
import useForm from "./useForm";
import { scrollToTop } from "../../../../utilities/helpers";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import Loader from "../../../atoms/Loader";
import Checkbox from "../../../atoms/Checkbox";

// eslint-disable-next-line react/prop-types
const Addresses = observer(() => {
  const [myAddresses, setMyAddresses] = useState(null);
  const { authorization } = useContext(StoreContext);
  const query = useQuery();
  const deleteModal = useModal();
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const history = useHistory();
  const [haveDefaultAddress, setHaveDefaultAddress] = useState(true);

  const deleteAddressError =
    authorization[`error${ACTIONS.AUTHORIZATION.DELETE_ADDRESS}`];
  const deleteAddressLoading =
    authorization[`isLoading${ACTIONS.AUTHORIZATION.DELETE_ADDRESS}`];

  const {
    address_id,
    deliveryApartment,
    deliveryCity,
    deliveryCountry,
    deliveryState,
    deliveryStreet,
    deliveryZip,
    defaultAddress,
    addAddress,
    setAddAddress,
    // location,
    setLocation,
    setDeleteAddressSuccess,
    deleteAddressSuccess,
    isNotValidDeliveryDetails,
    triggerValidationsDelivery,
    onSubmit,
  } = useForm();

  const handleAddAddress = () => {
    setAddAddress(!addAddress);
  };

  useEffect(() => {
    if (authorization.isAuthenticated) {
      authorization.getAddress();
    }
  }, [
    authorization.isAuthenticated,
    deleteAddressSuccess,
    authorization[`success${ACTIONS.AUTHORIZATION.ADD_ADDRESS}`],
    authorization[`success${ACTIONS.AUTHORIZATION.EDIT_ADDRESS}`],
    authorization[`success${ACTIONS.AUTHORIZATION.SET_DEFAULT_ADDRESS}`],
  ]);

  useEffect(() => {
    setDeleteAddressSuccess(
      authorization[`success${ACTIONS.AUTHORIZATION.DELETE_ADDRESS}`]
    );
    if (deleteAddressSuccess) {
      deleteModal.onClose();
      authorization[`setSuccess`](ACTIONS.AUTHORIZATION.DELETE_ADDRESS, false);
    }
  }, [
    authorization[`success${ACTIONS.AUTHORIZATION.DELETE_ADDRESS}`],
    authorization[`isLoading${ACTIONS.AUTHORIZATION.DELETE_ADDRESS}`],
    authorization,
  ]);

  useEffect(() => {
    let is_default_selected = false;

    myAddresses?.forEach((address) => {
      console.log("#001", address);
      if (address.is_default == 1) {
        is_default_selected = true;
      }
    });

    setHaveDefaultAddress(is_default_selected);
  }, [myAddresses]);

  useEffect(() => {
    // if (query.get("id")) {
    //   setAddAddress(true);
    // }
    if (!authorization.address.length == 0 && !query.get("id")) {
      setAddAddress(false);
    }
  }, [
    authorization.address.length,
    authorization[`isLoading${ACTIONS.AUTHORIZATION.GET_ADDRESS}`],
  ]);

  useEffect(() => {
    setMyAddresses(authorization.address);
  }, [authorization[`isLoading${ACTIONS.AUTHORIZATION.GET_ADDRESS}`]]);

  const addNewAddress = () => {
    address_id.onChange({ target: { value: "" }, touched: false });
    deliveryState.onChange({ target: { value: "" }, touched: false });
    deliveryStreet.onChange({ target: { value: "" }, touched: false });
    deliveryCity.onChange({ target: { value: "" }, touched: false });
    deliveryZip.onChange({ target: { value: "" }, touched: false });
    deliveryApartment.onChange({ target: { value: "" }, touched: false });

    handleAddAddress();
  };

  const onNavigateToAddresses = () => {
    history.push(APPLICATION_ROUTES.MY_ACCOUNT.ADDRESSES);
    scrollToTop();
  };

  const handlePickAddressClick = (selectedAddress) => {
    address_id.onChange({ target: { value: selectedAddress.id } });
    deliveryState.onChange({ target: { value: selectedAddress.state } });
    deliveryStreet.onChange({ target: { value: selectedAddress.street } });
    deliveryCity.onChange({ target: { value: selectedAddress.city } });
    deliveryZip.onChange({ target: { value: selectedAddress.zip_code } });
    deliveryApartment.onChange({
      target: { value: selectedAddress.apartment },
    });
    defaultAddress.onChange({ target: { value: selectedAddress.is_default } });
    defaultAddress.setChecked(selectedAddress.is_default == 1 ? true : false);
    console.log("Selected data #001", selectedAddress)
    handleAddAddress();
    scroll.scrollToTop();
  };

  const handlePlaceSelected = (place) => {
    console.log("Selected place", place);
    // Extract city, state, and country from address components
    const addressComponents = place.address_components || [];
    let city = getAddressComponent(addressComponents, "locality") || "";
    const state =
      getAddressComponent(addressComponents, "administrative_area_level_1") ||
      "";
    let country = getAddressComponent(addressComponents, "country") || "";
    const street_number =
      getAddressComponent(addressComponents, "street_number") || "";
    let street = getAddressComponent(addressComponents, "route") || "";
    const postalCode =
      getAddressComponent(addressComponents, "postal_code") || "";
    const subLocality =
      getAddressComponent(addressComponents, "sublocality_level_1") || "";

    if (
      country &&
      (country == "United States" || country == "USA" || country == "US")
    ) {
      country = { label: "USA", value: "USA" };
    }

    if (street_number) {
      street = `${street_number} ${street}`;
    }

    if (!city) {
      city = subLocality;
    }

    document.querySelector(".pac-target-input").value = street;

    setLocation({
      city,
      state,
      country,
      street,
      postalCode,
    });
  };

  const getAddressComponent = (addressComponents, type) => {
    const component = addressComponents.find((component) =>
      component.types.includes(type)
    );
    return component ? component.long_name : "";
  };

  const deleteAddress = () => {
    authorization[`${ACTIONS.AUTHORIZATION.DELETE_ADDRESS}`](selectedAddressId);
    if (deleteAddressSuccess) {
      deleteModal.onClose();
    }
  };

  const makeDefaultAddress = (id) => {
    authorization.setDefaultAddress(id);
  };

  console.log(authorization.address, "myAddresses");

  return (
    <>
      <PageTitle value={PAGE_TITLES.ADDRESS} />
      <Layout>
        <div className="wrapper-m mt-05 mb-10 text-m-a">
          <div className={cs(classes.headingContainer)}>
            <h1>My Addresses</h1>
            <p className="mt-05 mb-02">
              Manage your saved addresses here for a faster and more convenient
              checkout experience.
            </p>
            <p className="mt-02 mb-02">
              Having your addresses saved helps you complete your orders quickly
              and ensures your purchases arrive at the right location without
              any hassle.
            </p>
          </div>



          {authorization.isAuthenticated ? (
            <>
              {!authorization[`isLoading${ACTIONS.AUTHORIZATION.GET_ADDRESS}`] ? (
                <>
                  {!haveDefaultAddress && (
                    <div className={cs(classes.alert)}>Please add a default address or select an existing one.</div>
                  )}
                  <div className={cs(classes.addressContainer, "mt-07 mb-07")}>
                    {!addAddress ? (
                      <div className={classes.addressCardContainer}>
                        <div
                          className={classes.addAddressBox}
                          onClick={addNewAddress}
                        >
                          <p style={{ fontSize: "32px" }}>+</p>
                          <p style={{ fontSize: "18px", marginTop: "8px" }}>
                            Add Address
                          </p>
                        </div>

                        {myAddresses?.map((data, i) => {
                          return (
                            <div
                              className={classes.pickAddressBox}
                              key={i}
                              onClick={() => handlePickAddressClick(data)}
                            >
                              <div
                                className={cs(classes.crossButton)}
                                onClick={(e) => {
                                  setSelectedAddressId(data.id);
                                  e.stopPropagation();
                                  deleteModal.onOpen();
                                }}
                              >
                                <Icon name={NAMES.CrossButton} size="xxs" />
                              </div>
                              <div className={classes.addressBoxContent}>
                                <p style={{ fontSize: "16px" }}>
                                  {data.street} {data.apartment}
                                </p>
                              </div>

                              <div className={classes.addressBoxContent}>
                                <p style={{ fontSize: "16px" }}>
                                  {data.city}, {data.state}
                                </p>
                              </div>

                              <div className={classes.addressBoxContent}>
                                <p style={{ fontSize: "16px" }}>
                                  {data.country} {data.zip_code}
                                </p>
                              </div>

                              <div className={cs(classes.actionContainer)}>
                                {data.is_default ? (
                                  <p className={cs("c-orange fw-800 mr-02")}>
                                    Default
                                  </p>
                                ) : (
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      makeDefaultAddress(data.id);
                                    }}
                                    disabled={authorization.loadingIds.includes(
                                      data.id
                                    )}
                                    color={
                                      authorization.loadingIds.includes(data.id)
                                        ? "grayColor"
                                        : "primary"
                                    }
                                    size={SIZES.XS}
                                  >
                                    {authorization.loadingIds.includes(data.id)
                                      ? "Please wait.."
                                      : " Make Default"}
                                  </Button>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="wrapper-m">
                        <h3 className="heading-m mt-04 c-blue">ADDRESS</h3>
                        <ul className={cs(classes.address, classes.top)}>
                          <li className={cs(classes.item, classes.street)}>
                            <PlainInput
                              error={deliveryStreet.error}
                              id="my-maor-order-form-delivery-street"
                              label={deliveryStreet.label}
                              stretched
                              value={deliveryStreet.value}
                              onChange={deliveryStreet.onChange}
                              customComponent={(additionalProps) => (
                                <Autocomplete
                                  {...additionalProps}
                                  options={{
                                    types: ["address"],
                                    fields: [
                                      "address_components",
                                      "geometry.location",
                                      "place_id",
                                      "formatted_address",
                                    ],
                                    componentRestrictions: { country: "us" },
                                  }}
                                  onChange={deliveryStreet.onChange}
                                  defaultValue={deliveryStreet.value}
                                  placeholder=""
                                  apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                  onPlaceSelected={handlePlaceSelected}
                                  style={{
                                    width: "100%",
                                    borderRadius: "8px",
                                    borderColor: "#C1BBBB",
                                    color: "#636363",
                                    borderWidth: "1px",
                                    borderStyle: "solid",
                                    padding: "17px 16px",
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                  }}
                                />
                              )}
                            />
                          </li>
                          <li className={cs(classes.item, classes.city)}>
                            <PlainInput
                              error={deliveryApartment.error}
                              id="my-maor-order-form-delivery-apartment"
                              label={deliveryApartment.label}
                              value={deliveryApartment.value}
                              onChange={deliveryApartment.onChange}
                            />
                          </li>
                        </ul>
                        <ul className={cs(classes.address, classes.bottom)}>
                          <li className={cs(classes.item, classes.city)}>
                            <PlainInput
                              error={deliveryCity.error}
                              id="my-maor-order-form-delivery-city"
                              label={deliveryCity.label}
                              value={deliveryCity.value}
                              onChange={deliveryCity.onChange}
                            />
                          </li>
                          <li className={cs(classes.item, classes.state)}>
                            <PlainInput
                              error={deliveryState.error}
                              id="my-maor-order-form-delivery-state"
                              label={deliveryState.label}
                              value={deliveryState.value}
                              onChange={deliveryState.onChange}
                            />
                          </li>
                          <li className={cs(classes.item, classes.zip)}>
                            <PlainInput
                              error={deliveryZip.error}
                              id="my-maor-order-form-delivery-zip"
                              label={deliveryZip.label}
                              value={deliveryZip.value}
                              onChange={deliveryZip.onChange}
                            />
                          </li>
                          <li className={cs(classes.item, classes.country)}>
                            <SelectR
                              label={deliveryCountry.label}
                              options={deliveryCountry.options}
                              value={deliveryCountry.value}
                              onChange={deliveryCountry.onChange}
                            />
                            <small
                              className={cs(
                                classes.redText,
                                "ta-center w-100 d-block"
                              )}
                            >
                              We are currently shipping only within USA.
                            </small>
                          </li>
                        </ul>
                        <ul className={cs(classes.defaultAddressRow)}>
                          <li className={cs(classes.item, classes.is_default)}>
                            <Checkbox
                              {...defaultAddress}
                            >
                              {defaultAddress.label}
                            </Checkbox>
                          </li>
                        </ul>
                        <div className={cs(classes.addressFooter)}>
                          <Button
                            onClick={() => {
                              if (!isNotValidDeliveryDetails) {
                                onSubmit({ callback: onNavigateToAddresses() });
                              } else {
                                triggerValidationsDelivery &&
                                  triggerValidationsDelivery();
                              }
                            }}
                            color={
                              isNotValidDeliveryDetails
                                ? "grayColor"
                                : authorization[
                                  `isLoading${ACTIONS.AUTHORIZATION.ADD_ADDRESS}`
                                ] ||
                                  authorization[
                                  `isLoading${ACTIONS.AUTHORIZATION.EDIT_ADDRESS}`
                                  ]
                                  ? "grayColor"
                                  : "primary"
                            }
                          >
                            {authorization[
                              `isLoading${ACTIONS.AUTHORIZATION.ADD_ADDRESS}`
                            ] ||
                              authorization[
                              `isLoading${ACTIONS.AUTHORIZATION.EDIT_ADDRESS}`
                              ]
                              ? "Loading.."
                              : "SAVE"}
                          </Button>
                          <Button
                            onClick={() => setAddAddress(false)}
                            color="ghost"
                          >
                            BACK
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div className="mt-10">
                  <Loader />
                </div>
              )}
            </>
          ) : (
            <div className="heading-m mt-10">
              Please{" "}
              <Link
                class="c-orange"
                to={
                  APPLICATION_ROUTES.SIGN_IN +
                  "?redirect=" +
                  APPLICATION_ROUTES.MY_ACCOUNT.ADDRESSES
                }
              >
                Login
              </Link>{" "}
              to view your addresses.
            </div>
          )}

          <Modal opened={deleteModal.isOpened}>
            <div className={classes.deleteModalConatiner}>
              <div className={classes.deleteModalCard}>
                <div className={cs(classes.main)}>
                  <h4 className="title">
                    Are you sure you want to delete this record?
                  </h4>
                </div>
                {deleteAddressError && <p>Something Went Wrong</p>}
                <div className={cs("d-flex jc-center gap-10 mt-07")}>
                  <button
                    className={cs(classes.addBtn, "fw-700 c-orange")}
                    onClick={deleteAddress}
                    disabled={deleteAddressLoading}
                  >
                    {deleteAddressLoading ? (
                      "Please wait..."
                    ) : (
                      <>
                        <p>Confirm</p>
                      </>
                    )}
                  </button>
                  <Button color={COLORS.GHOST} onClick={deleteModal.onClose}>
                    <p>Cancel</p>
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </Layout>
    </>
  );
});

const inputPropTypes = PropTypes.shape({
  error: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  emptyErrors: PropTypes.func.isRequired,
}).isRequired;

const selectPropTypes = PropTypes.shape({
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  emptyErrors: PropTypes.func.isRequired,
  error: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  bool: PropTypes.bool,
  selectedValue: PropTypes.string,
}).isRequired;

Addresses.propTypes = {
  deliveryApartment: inputPropTypes,
  deliveryCity: inputPropTypes,
  deliveryCountry: selectPropTypes,
  deliveryState: inputPropTypes,
  deliveryStreet: inputPropTypes,
  deliveryZip: inputPropTypes,
  message: inputPropTypes,
  from: inputPropTypes,
  to: inputPropTypes,
  address_id: inputPropTypes,
  setAddAddress: selectPropTypes,
  addAddress: selectPropTypes,
  // hasDelivery: PropTypes.bool.isRequired,
  hasDelivery: PropTypes.bool.isRequired,
  setLocation: inputPropTypes,
  location: inputPropTypes,
  deleteAddressSuccess: selectPropTypes,
  setDeleteAddressSuccess: selectPropTypes,
};

export default Addresses;
