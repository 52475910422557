const heMonths = {
  1: "ניסן",
  2: "אייר",
  3: "סיון",
  4: "תמוז",
  5: "אב",
  6: "אלול",
  7: "תשרי",
  8: "חשון",
  9: "כסלו",
  10: "טבת",
  11: "שבט",
  12: "אדר",
  13: "אדר ב",
};

const heMonthsLeap = {
  1: "ניסן",
  2: "אייר",
  3: "סיון",
  4: "תמוז",
  5: "אב",
  6: "אלול",
  7: "תשרי",
  8: "חשון",
  9: "כסלו",
  10: "טבת",
  11: "שבט",
  12: "אדר א",
  13: "אדר ב",
};

const heMonthsEnglish = {
  1: "Nisan",
  2: "Iyyar",
  3: "Sivan",
  4: "Tamuz",
  5: "Av",
  6: "Elul",
  7: "Tishri",
  8: "Cheshvan",
  9: "Kislve",
  10: "Tevet",
  11: "Shevat",
  12: "Adar",
  13: "Adar II",
};

const heMonthsLeapEnglish = {
  1: "Nisan",
  2: "Iyyar",
  3: "Sivan",
  4: "Tamuz",
  5: "Av",
  6: "Elul",
  7: "Tishri",
  8: "Cheshvan",
  9: "Kislve",
  10: "Tevet",
  11: "Shevat",
  12: "Adar I",
  13: "Adar II",
};

export { heMonths, heMonthsLeap, heMonthsEnglish, heMonthsLeapEnglish };
