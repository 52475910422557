/* TODO: optimize number of calculations */

const calculateDifferenceInMinutes = (startTime, endTime) => {
  return Math.floor((endTime - startTime) / 1000 / 60);
};

const calculateDifferenceInHours = (startTime, endTime) => {
  return Math.floor((endTime - startTime) / 1000 / 60 / 60);
};

const calculateDifferenceInDays = (startTime, endTime) => {
  return Math.floor((endTime - startTime) / 1000 / 60 / 60 / 24);
};

const calculateDifferenceInWeeks = (startTime, endTime) => {
  return Math.floor((endTime - startTime) / 1000 / 60 / 60 / 24 / 7);
};

const calculateDifferenceInMonths = (start, end) => {
  const startYear = start.getFullYear();
  const endYear = end.getFullYear();
  const startMonth = start.getMonth();
  const endMonth = end.getMonth();
  const startDate = start.getDate();
  const endDate = end.getDate();
  const partialMonthCorrection = endDate < startDate ? -1 : 0;

  return (endYear - startYear) * 12 + (endMonth - startMonth) + partialMonthCorrection;
};

const calculateDifferenceInYears = (start, end) => {
  const startYear = start.getFullYear();
  const endYear = end.getFullYear();

  return endYear - startYear;
};

const calculateDateDifference = (start = new Date(), end = new Date()) => {
  const startTime = start.getTime();
  const endTime = end.getTime();

  if (startTime > endTime)
    console.error(`Error: in calculateDateDifference function "end" (Date) argument is earlier than "start" (Date)!`);

  const minutes = calculateDifferenceInMinutes(startTime, endTime);
  const hours = calculateDifferenceInHours(startTime, endTime);
  const days = calculateDifferenceInDays(startTime, endTime);
  const weeks = calculateDifferenceInWeeks(startTime, endTime);
  const months = calculateDifferenceInMonths(start, end);
  const years = calculateDifferenceInYears(start, end);

  return {
    minutes: minutes,
    hours: hours,
    days: days,
    weeks: weeks,
    months: months,
    years: years,
  };
};

export default calculateDateDifference;
