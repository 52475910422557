/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import cs from "classnames";

import Button from "../../atoms/Button";
import Loader from "../../atoms/Loader";
import Logotype, { TYPES as LOGOTYPE_TYPES } from "../../atoms/Logotype";
import Modal from "../../organisms/Modal";
import thumbnail from "../../../assets/images/video-thumbnail-placeholder.png";
import { StoreContext } from "../../../contexts";
import Preview from "./components/Preview";
import useVideo from "./useVideo";
import classes from "./ProjectList.module.scss";
import PlaylistModal from "../../organisms/Playlist/PlaylistModal";
import { ACTIONS } from "../../../store";
import Icon from "../../atoms/Icon";
import { getURL } from "./components/Preview/utilities";
import FeatureModal from "../../organisms/FeatureModal/FeatureModal";
import { useModal } from "../../../hooks";
import {
  Authorization,
  AuthorizationKids,
} from "../../organisms/RestrictionModal/RestrictionModal";
import VideoSlider from "../../templates/VideoSlider/VideoSlider";
import { isMobile } from "react-device-detect";

const ProjectList = observer(
  ({
    error,
    hasItems,
    hasNextPage,
    isLoading,
    items,
    logotype,
    placeholder,
    onLoad,
    search,
    order,
    videoType,
    resetPage,
    setRequestPayload,
    totalItems,
    title,
    projectType,
    limitRecord,
    freeAccess,
    coloringPages,
    themeColor,
    holidayId,
    categoryId,
    details,
  }) => {
    const { authorization, playlist, partnership } = useContext(StoreContext);
    const featureModal = useModal();
    const [freeDonorWatch, setFreeDonorWatch] = useState(false);
    const [showAllList, setShowAllList] = useState(false);

    // const history = useHistory();

    // const onNavigateToPartnersMain = useCallback(() => {
    //   history.push(APPLICATION_ROUTES.PARTNERS_PACKAGES);
    // }, []);

    // const onNavigateToSignIn = useCallback(() => {
    //   history.push(APPLICATION_ROUTES.SIGN_IN);
    //   scrollToTop();
    // }, []);

    const video = useVideo();
    const showModalOnPlaylist = useModal();
    const { t } = useTranslation();

    let fdaSession = "";
    if (navigator.cookieEnabled) {
      fdaSession = sessionStorage.getItem("fda");
    }

    useEffect(() => {
      if (fdaSession === "1") {
        setFreeDonorWatch(true);
      }
    }, [fdaSession]);

    useEffect(() => {
      const timer = setTimeout(() => {
        resetPage();
        setRequestPayload({
          search: search,
          order: order,
          videoType: videoType,
          holidayId: holidayId,
          limitRecord: limitRecord,
          coloringPages: coloringPages.value,
        });
        // onLoad();
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }, [search, order, videoType, coloringPages.value, limitRecord, holidayId]);


    console.log("PT", projectType);
    if (projectType == "my-maor" && video.number == 1000) {
      freeAccess = true;
    }

    console.log(video, details, "asdascasfas");

    // const [slider, setSlider] = useState(null);
    // const sliderRef = {
    //   instance: slider,
    //   set: setSlider,
    // };

    // if (!authorization.isAuthenticated) {
    //   freeAccess = false;
    // }

    const toggleShowList = () => {
      setShowAllList(!showAllList);
    };

    return (
      <div className={cs(classes.container, "wrapper-l", classes[themeColor])}>
        <div className={cs(classes.main)}>
          <div className={classes.header}>
            <div className={classes.logoWrap}>
              <div className={cs(classes.logotype)}>
                <div className={cs(classes.logotypeWrapper, "d-none")}>
                  <Logotype type={logotype} size="m" url="#" />
                </div>
                <div>
                  <p className={classes.projectTitle}>{title}</p>
                  {totalItems == 0 || totalItems == "" ? (
                    <small className={cs(classes.totalVideosCount, "d-none")}>
                      {t("pages.library.labels.no_videos")}
                    </small>
                  ) : (
                    <small className={cs(classes.totalVideosCount, "d-none")}>
                      {totalItems > 1
                        ? t("pages.library.labels.video_count_plural", {
                            value: totalItems,
                          })
                        : t("pages.library.labels.video_count_singular", {
                            value: totalItems,
                          })}
                    </small>
                  )}
                </div>
              </div>

              <div className={cs(classes.sideHeader)}>
                {showAllList && (
                  <>
                    {search != "" ||
                    videoType != "" ||
                    holidayId != "" ||
                    coloringPages ? (
                      <div>
                        <button
                          className={classes.playlistBtn}
                          title="Please sign in to add videos to Playlist"
                          disabled={playlist.getIsLoading(
                            ACTIONS.PLAYLIST.ADD_TO_PLAYLIST
                          )}
                          onClick={() => {
                            if (
                              authorization.isAuthenticated &&
                              authorization.isUserMember
                            ) {
                              const payload = holidayId
                                ? {
                                    holiday_id: holidayId,
                                  }
                                : categoryId
                                ? {
                                    category_id: categoryId,
                                  }
                                : {
                                    section: title,
                                  };

                              playlist.addToPlaylist({
                                payload: {
                                  search: search,
                                  order: order,
                                  videoType: videoType,
                                  action: "add_to_playlist",
                                  video_id: details ? details.id : "",
                                  ...payload,
                                },
                                onSuccess: () => {
                                  playlist.get({ onSuccess: null });
                                },
                              });
                            } else {
                              showModalOnPlaylist.onOpen();
                            }
                          }}
                        >
                          {playlist.getIsLoading(
                            ACTIONS.PLAYLIST.ADD_TO_PLAYLIST
                          ) ? (
                            "Loading..."
                          ) : (
                            <div className={cs(classes.addToPlayList)}>
                              {isMobile ? (
                                <Icon name="playlist-icon-white" />
                              ) : (
                                <Icon name={`${themeColor}-playlist`} />
                              )}
                              <p>Add all to playlist</p>
                            </div>
                          )}
                        </button>
                      </div>
                    ) : null}
                  </>
                )}
                <div>
                  <p
                    onClick={toggleShowList}
                    className={cs(classes.viewAllText)}
                  >
                    {showAllList ? "Hide" : "View All"}
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className={cs(classes.sidebarButtons, "d-none")}>
                <button onClick={coloringPages.onChange}>
                  <Icon name="color-pallate" />
                </button>
                {authorization.isUserMember && playlist.items.length > 0 ? (
                  <button
                    onClick={() => {
                      let playlistItem = playlist.items[0];
                      playlist.setCurrentPlayingVideoId(playlistItem.video.id);
                      video.onOpen({
                        end: playlistItem.video.end,
                        start: playlistItem.video.start,
                        url: getURL(playlistItem.video.url, {
                          start: playlistItem.video.start,
                        }),
                        name: playlistItem.video.name,
                        description: playlistItem.video.description,
                        number: playlistItem.video.number,
                        date: playlistItem.video.created_at,
                        categories:
                          playlistItem.video.video_directory.video_categories,
                        tags: playlistItem.video.video_directory.video_tags,
                        holiday: playlistItem.video.video_directory.holiday,
                        file: playlistItem.video.video_directory.file,
                        allinfo: playlistItem,
                      });
                    }}
                  >
                    <Icon name="youtube-white" />
                  </button>
                ) : null}
                <button
                  onClick={() => {
                    console.log("test");
                    featureModal.onOpen();
                  }}
                >
                  <Icon name="play-white" />
                </button>
              </div>
            </div>
          </div>

          {hasItems ? (
            <>
              <ul
                className={cs(
                  !showAllList ? classes.showASlider : classes.hideSlider
                )}
              >
                <VideoSlider>
                  {items.map((item, index) => (
                    <li
                      // style={{ width: "255px" }}
                      data-find="1"
                      key={item.id + "_" + index}
                      className={cs(classes.item, classes.itemPadding)}
                    >
                      <div>
                        <Preview
                          {...item}
                          projectType={projectType}
                          internal_id={item.id}
                          placeholder={placeholder}
                          onSelect={video.onOpen}
                          showAllList={showAllList}
                        />
                      </div>
                    </li>
                  ))}
                  {items.length > 4 && (
                    <li className={cs(classes.itemPadding)}>
                      <div
                        onClick={toggleShowList}
                        className={cs(classes.viewAllTextSlider)}
                      >
                        <p className={cs(classes.viewAllText)}>View All</p>
                      </div>
                    </li>
                  )}
                </VideoSlider>
              </ul>
              <ul
                className={cs(
                  showAllList ? classes.listShown : classes.listHide
                )}
              >
                {items.map((item, index) => (
                  <li
                    data-find="1"
                    key={item.id + "_" + index}
                    className={classes.item}
                  >
                    <Preview
                      {...item}
                      projectType={projectType}
                      internal_id={item.id}
                      placeholder={placeholder}
                      onSelect={video.onOpen}
                      showAllList={showAllList}
                    />
                  </li>
                ))}
              </ul>

              {freeAccess && freeDonorWatch ? (
                <Modal
                  hasPlaylist={true}
                  opened={video.isOpened}
                  style={video.style}
                >
                  <PlaylistModal video={video} />
                </Modal>
              ) : (
                <>
                  {!authorization.isAuthenticated && freeAccess ? (
                    <AuthorizationKids
                      popup={video}
                      authorization={authorization}
                      partnership={partnership}
                    />
                  ) : (
                    <>
                      {authorization.isUserMember || freeAccess ? (
                        <Modal
                          hasPlaylist={true}
                          opened={video.isOpened}
                          style={video.style}
                        >
                          <PlaylistModal video={video} />
                        </Modal>
                      ) : (
                        <>
                          <Authorization
                            popup={video}
                            authorization={authorization}
                            partnership={partnership}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <div
              className={cs(classes.message, {
                [classes.empty]: !hasItems,
                [classes.error]: !!error,
              })}
            >
              {!isLoading && (error ? t("generals.warnings.1") : "No result")}
            </div>
          )}
        </div>
        {hasNextPage && showAllList && (
          <div className={classes.footer}>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <button
                  className={classes.button}
                  onClick={() => {
                    const payload = holidayId
                      ? { holidayId, page: hasNextPage, categoryName: title }
                      : categoryId
                      ? { categoryId, page: hasNextPage, categoryName: title }
                      : {
                          section: title,
                          page: hasNextPage,
                          categoryName: title,
                        };

                    onLoad(payload);
                  }}
                >
                  {t("pages.library.labels.1")}
                </button>
              </>
            )}
          </div>
        )}

        {!showAllList && isLoading && <Loader />}
        <FeatureModal modal={featureModal} />
        <Authorization
          popup={showModalOnPlaylist}
          authorization={authorization}
          partnership={partnership}
        />
      </div>
    );
  }
);

ProjectList.propTypes = {
  error: PropTypes.bool,
  hasItems: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  isLoading: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  logotype: PropTypes.oneOf(Object.values(LOGOTYPE_TYPES)),
  placeholder: PropTypes.string,
  onLoad: PropTypes.func.isRequired,
  holidayId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalItems: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string.isRequired,
  projectType: PropTypes.string.isRequired,
  freeAccess: PropTypes.bool,
  appliedFilters: PropTypes.any,
};

ProjectList.defaultProps = {
  error: false,
  hasItems: false,
  hasNextPage: false,
  isLoading: false,
  items: [],
  logotype: LOGOTYPE_TYPES.DEFAULT,
  placeholder: thumbnail,
  holidayId: 0,
  totalItems: 0,
  limitRecord: 8,
  freeAccess: false,
};

export default React.memo(ProjectList);
