const COLORS = {
  PRIMARY: "primary",
  WARNING: "warning",
};

const SIZES = {
  MEDIUM: "medium",
};

export { COLORS, SIZES };
