import React, { useCallback } from "react";
import { animateScroll as scroll } from "react-scroll";
import cs from "classnames";

import Icon from "../../atoms/Icon";
import { useDocumentScroll } from "../../../hooks";
import classes from "./PageAnchor.module.scss";

const PageAnchor = () => {
  const {
    vertical: { scrolled },
  } = useDocumentScroll();

  const onClick = useCallback(() => {
    scroll.scrollToTop();
  }, []);

  return (
    <div className={cs(classes.container, { [classes.visible]: scrolled > 200 })}>
      <button className={classes.button} onClick={onClick}>
        <Icon name="anchor" size="xl" />
      </button>
    </div>
  );
};

export default PageAnchor;
