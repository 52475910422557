import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cs from "classnames";

import Icon from "../../../../../../atoms/Icon";
import IconButton from "../../../../../../atoms/IconButton";
import Modal from "../../../../../../organisms/Modal";
import { APPLICATION_ROUTES } from "../../../../../../../constants";
import { useDateFormat, useModal, useRadio } from "../../../../../../../hooks";
import ModalBody from "../ModalBody";
// import { TYPES, getPackageView } from "./utilities";
import { getPackageView } from "./utilities";
import classes from "./BirthdayItem.module.scss";
import {
  formatJewishDate,
  getPackageDetails,
} from "../../../../../../../utilities/helpers";
import { toJS } from "mobx";
import { useEffect } from "react";
import { useState } from "react";
import RadioDots from "../../../../../../atoms/RadioDots";
import Button from "../../../../../../atoms/Button";
import { useContext } from "react";
import { StoreContext } from "../../../../../../../contexts";
import { ACTIONS } from "../../../../../../../store";
import { observer } from "mobx-react-lite";

// const useDateFormatNew = (date) => {

//   const [day, month, year] = date.split('/').map(Number);

//   if (isNaN(day) || isNaN(month) || isNaN(year)) {
//     return 'Invalid Date';
//   }

//   const dateObject = new Date(year, month - 1, day);

//   // Convert the date to Eastern Standard Time (EST)
//   // const estDate = dateObject.toLocaleString('en-US', { timeZone: 'America/New_York' });

//   // Formatting the EST Date object to 'MMMM/D/YYYY'
//   const formattedDate = new Date(dateObject).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' });

//   return formattedDate;
// };


const BirthdayItem = observer(
  ({
    // credit,
    data,
    // interval,
    item_id,
    isLoadingDelete,
    // full_price,
    price_per_month,
    product_code,
    onDelete,
    setItemPayloadValue,
  }) => {

    const modal = useModal();
    const videoModal = useModal();
    const { videodates } = useContext(StoreContext);
    const history = useHistory();
    const [lastestVideo, setLatestVideo] = useState(null);
    const [videoDatesArray, setVideoDatesArray] = useState([]);
    const [groupedArray, setGroupedArray] = useState(null)
    const { t } = useTranslation();


    const view = getPackageView(product_code);
    const packageDetail = getPackageDetails(product_code);

    const onEdit = useCallback(({ id, type }) => {
      history.push(`${APPLICATION_ROUTES.MY_MAOR_ORDER}?type=${type}&id=${id}`);
    }, []);

    const SEX = {
      boy: "Boy",
      girl: "Girl",
    };
    let apartment = "";
    if (packageDetail.DELIVERY) {
      apartment = data.delivery.apartment ? data.delivery.apartment + " " : "";
    } else {
      apartment = "";
    }

    let detailLocation = [];
    detailLocation.push(data.details.city);
    if (data.details.state) {
      detailLocation.push(data.details.state);
    }
    detailLocation.push(data.details.country.toUpperCase());
    detailLocation = detailLocation.join(", ");
    console.log("Data", toJS(data));

    // For birthday date
    let birthdayHe = data.details.date;
    let dateEng = birthdayHe.en.split(" ");
    let birthdayEngDate = `${dateEng[1]}/${dateEng[0]}/${dateEng[2]}`;

    useEffect(() => {
      if (birthdayHe) {
        let birthdayDate = birthdayHe.en;
        videodates[`${ACTIONS.VIDEO_DATES.GET_VIDEO_DATES}`]({
          birthdayDate,
          item_id,
        });
      }
    }, [birthdayHe]);

    // For Video Dates
    useEffect(() => {
      if (videodates.videoDates[item_id]) {
        const objectsArray = videodates.videoDates[item_id].map((value) => {
          if (value.selected) {
            setLatestVideo(value);
            videoDatesShown.setValueExternal(value.id.toString());
          }
          // value.showing_date_en = new Date(value.showing_date_en)
          // value.showing_date_en = (value.showing_date_en.getMonth() + 1) + '/' + value.showing_date_en.getDate() + '/' + value.showing_date_en.getFullYear();
          value.showing_date_en = useDateFormat(value.showing_date_en, "-");

          return {
            id: value.id.toString(),
            label: `${value.showing_date_en}, ${value.showing_date_he}`,
            value: value.id.toString(),
          };
        });

        let groupedByYear = videodates.videoDates[item_id].reduce((acc, item) => {
          let year;
          let hebrewYear = new Date(item.showing_date_en);

          const hebDate = formatJewishDate(hebrewYear, false, true);
          let englishToJewishDate = hebDate.split(" ").reverse();

          let englishToJewishDateArray = englishToJewishDate;
          if (englishToJewishDateArray.length > 3) {
            year = englishToJewishDateArray[0];
          } else {
            year = englishToJewishDateArray[0];
          }

          // console.log(year, item.showing_date_en, "group")

          if (!acc[year]) {
            acc[year] = [];
          }

          acc[year].push(item);

          return acc;
        }, {});

        // Convert the groupedByYear object into an array of objects with year and dates
        let groupedArrayRes = Object.entries(groupedByYear).map(([year, dates]) => ({
          year: year,
          dates,
        }));

        setGroupedArray(groupedArrayRes)

        setVideoDatesArray(objectsArray);
      }
    }, [videodates.videoDates[item_id]]);

    const videoDatesShown = useRadio({
      options: videoDatesArray,
      value: "",
    });

    // Define a function to update setLatestVideo based on the selected ID
    const updateLatestVideo = () => {
      const selectedId = videoDatesShown.value;
      if (selectedId && videodates.videoDates[item_id] != null) {
        const selectedVideo = videodates.videoDates[item_id].find(
          (video) => video.id.toString() === selectedId
        );
        setLatestVideo(selectedVideo);
      }
    };

    const handleSaveClick = () => {
      updateLatestVideo();
      videoModal.onClose();
    };

    useEffect(() => {
      const newObject = {
        video_id: lastestVideo ? lastestVideo.id : "",
        order_detail_id: item_id,
      };

      if (lastestVideo) {
        setItemPayloadValue(newObject);
      }
    }, [lastestVideo]);

    return (
      <>
        <div className={cs(classes.container, classes.grid)}>
          <div className={classes.main}>
            <span className={classes.gradientBorder}></span>
            <div className={classes.package}>
              <div className={classes.illustration}>
                <view.Illustration />
              </div>
              <h4 className={classes.title}>{t(view.title_translation_key)}</h4>
              <div
                className={cs(classes.title, "c-orange")}
              >{`$${price_per_month}`}</div>
            </div>
          </div>
          <div className={classes.details}>
            <ul className={classes.list}>
              <li className={classes.item}>
                <div className={cs(classes.icon, classes.userIcon)}>
                  <Icon name="user-profile" size="s" />
                </div>
                <div className={cs(classes.text, classes.userName)}>
                  <div className={cs(classes.fullName)}>
                    {data.details.first_name && (
                      <span className="mr-0">{data.details.first_name}</span>
                    )}
                    <span className="mr-0">{data.details.last_name}</span>
                  </div>
                  <div>
                    {data.details.pronunciation && (
                      <span>
                        ({data.details.pronunciation})
                      </span>
                    )}{" "}
                    <sapn className="ml-01 mr-01"> | </sapn> {" "}<span>{SEX[data.details.sex]}</span>
                  </div>
                </div>
              </li>
              <li className={classes.item}>
                <div className={classes.icon}>
                  <Icon name="new-cake" size="s" />
                </div>
                {/* <div
                className={classes.text}
              >{`${birthdayHe}  (${birthday})`}</div> */}
                {birthdayEngDate != undefined &&
                  <div
                    className={classes.text}
                  >{`(${useDateFormat(birthdayEngDate, "/")}) ${data.details.hebrew_date}`}</div>
                }
              </li>
              <li className={classes.item}>
                <div className={classes.icon}>
                  <Icon name="map-marker" color="primary" size="s" />
                </div>
                <span>{detailLocation}</span>
              </li>
              {data.details.rank && (
                <li className={classes.item}>
                  <div className={cs("d-flex gap-2 fw-wrap")}>
                    <div className={classes.text}>
                      <strong>{t("forms.labels.tzivos_rank_hashem")}: </strong>
                      <span>{data.details.rank}</span>
                    </div>
                  </div>
                </li>
              )}

              {!packageDetail.DELIVERY && lastestVideo && (
                <>
                  <li className={cs(classes.item)}>
                    <div className={cs(classes.icon)}>
                      <Icon name="calendar" size="s" />
                    </div>
                    <p>
                      Dedication will be shown on{" "}  <br />
                      <b>{`(${useDateFormat(lastestVideo.showing_date_en, "/")}) ${lastestVideo.showing_date_he}`}</b>
                    </p>
                    <p
                      onClick={videoModal.onOpen}
                      className={cs(classes.changeButton, "c-orange")}
                    >
                      Change
                    </p>
                  </li>
                  <li className={cs(classes.item)}>
                    <div className={classes.icon}>
                      <Icon name="auto-renewal" color="primary" size="s" />
                    </div>
                    <span>
                      Auto-Renewal {data.birthday_recurring ? "ON" : "OFF"}
                    </span>
                  </li>
                </>
              )}

              {packageDetail.DELIVERY && (
                <>
                  {/* <li className={cs(classes.item, "mt-02")}>
                  <strong>{t("pages.checkout.labels.5")}:</strong>
                </li> */}
                  <li className={classes.item}>
                    <div className={classes.icon}>
                      <Icon name="deliverytruck" color="primary" size="s" />
                    </div>
                    <div className={classes.text}>
                      <span>Delivery: </span> <br />
                      {`${data.delivery.street} ${apartment}`}<br />
                      {`${data.delivery.city}, ${data.delivery.state} ${data.delivery.zip_code}`}
                    </div>
                  </li>
                  {lastestVideo && (
                    <>
                      <li className={cs(classes.item)}>
                        <div className={cs(classes.icon)}>
                          <Icon name="calendar" size="s" />
                        </div>
                        <p>
                          Dedication will be shown on{" "} <br />
                          <b>{`(${useDateFormat(lastestVideo.showing_date_en, "/")}) ${lastestVideo.showing_date_he}`}</b>
                        </p>
                        <p
                          onClick={videoModal.onOpen}
                          className={cs(classes.changeButton, "c-orange")}
                        >
                          Change
                        </p>
                      </li>
                      <li className={cs(classes.item)}>
                        <div className={classes.icon}>
                          <Icon name="auto-renewal" color="primary" size="s" />
                        </div>
                        <span>
                          Auto-Renewal {data.birthday_recurring ? "ON" : "OFF"}
                        </span>
                      </li>
                    </>
                  )}
                  {data.wishes.from &&
                    data.wishes.to &&
                    data.wishes.message && (
                      <>
                        <li className={cs(classes.item, "mt-02")}>
                          <strong>{t("pages.checkout.labels.6")}:</strong>
                        </li>
                        <li className={classes.item}>
                          <div className={cs("d-flex fw-wrap gap-2")}>
                            <div>
                              <strong>{t("pages.checkout.labels.7")}: </strong>
                              <span>{data.wishes.from}</span>
                            </div>
                            <div>
                              <strong>{t("pages.checkout.labels.8")}: </strong>
                              <span>{data.wishes.to}</span>
                            </div>
                          </div>
                        </li>
                        <li className={classes.item}>
                          <div>
                            <strong>{t("pages.checkout.labels.9")}: </strong>
                            <span>{data.wishes.message}</span>
                          </div>
                        </li>
                      </>
                    )}
                </>
              )}
            </ul>
          </div>
          <div className={classes.options}>
            <div className={classes.buttons}>
              <IconButton
                iconName="edit"
                noBorder="1"
                iconSize="s"
                onClick={() => onEdit({ id: item_id, type: product_code })}
              />
              <IconButton
                color="warning"
                noBorder="1"
                iconName="trashicon"
                iconSize="s"
                onClick={modal.onOpen}
              />
            </div>
          </div>
        </div>
        <Modal opened={modal.isOpened} onClose={modal.onClose}>
          <ModalBody
            isLoading={isLoadingDelete}
            onClose={modal.onClose}
            onDelete={() => onDelete({ id: item_id, onSuccess: modal.onClose })}
          />
        </Modal>
        <Modal opened={videoModal.isOpened}>
          <div className={cs(classes.videoSelectModalWrapper)}>
            <div className={cs(classes.videoModalCard)}>
              <div className={cs(classes.closeButtonContainer)}>
                <button className={classes.close} onClick={videoModal.onClose}>
                  <Icon name="exit" size="l" />
                </button>
              </div>
              <div className={cs(classes.videoDateMain)}>
                <div>
                  <h1>Choose a video to feature the birthday:</h1>
                  <p>Videos are released weekly.</p>
                </div>
                <>
                  {groupedArray &&
                    <div>
                      {
                        groupedArray.map((yearData) => (
                          <div key={yearData.year}>
                            <label className={cs(classes.videoLabel)}>{yearData.year}</label>
                            <RadioDots
                              items={yearData.dates.map(date => ({
                                id: date.id,
                                label: `(${useDateFormat(date.showing_date_en, "/")}) ${date.showing_date_he}`,
                                value: date.id.toString(), // Assuming id is a unique identifier for dates
                              }))}
                              value={videoDatesShown.value}
                              onChange={videoDatesShown.onChange}
                            />
                          </div>
                        ))
                      }
                    </div>
                  }
                  {/* <RadioDots
                    items={videoDatesShown.options}
                    value={videoDatesShown.value}
                    onChange={videoDatesShown.onChange}
                  /> */}
                </>
              </div>
              <div className={cs(classes.saveButtonContainer)}>
                <Button onClick={handleSaveClick}>SAVE</Button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
);

BirthdayItem.propTypes = {
  credit: PropTypes.number.isRequired,
  data: PropTypes.shape({
    delivery: PropTypes.shape({
      apartment: PropTypes.string,
      street: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip_code: PropTypes.string,
      country: PropTypes.string,
    }),

    details: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      hebrew_date: PropTypes.string,
      born_after_sunset: PropTypes.bool,
      pronunciation: PropTypes.string,
      rank: PropTypes.string,
      sex: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
    }).isRequired,
    wishes: PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
      message: PropTypes.string,
    }),
  }).isRequired,
  interval: PropTypes.number.isRequired,
  item_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isLoadingDelete: PropTypes.bool.isRequired,
  full_price: PropTypes.number.isRequired,
  membership_expiration: PropTypes.string.isRequired,
  price_per_month: PropTypes.string.isRequired,
  product_code: PropTypes.string.isRequired,
  // type: PropTypes.oneOf(Object.values(TYPES)).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default React.memo(BirthdayItem);
