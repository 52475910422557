import React from 'react'
import Login from '../Login'
import Password from '../Password'
import PropTypes from "prop-types";

const SingInStep = ({ login, password }) => {
    return (
        <>
            <div className='mb-6'>
                <Login login={login} />
            </div>
            <div className='mt-05'>
                <Password password={password} />
            </div>
        </>
    )
}

const inputPropTypes = PropTypes.shape({
    error: PropTypes.string.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    label: PropTypes.string.isRequired,
    touched: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
}).isRequired;

SingInStep.propTypes = {
    password: inputPropTypes,
    login: inputPropTypes
};

export default SingInStep