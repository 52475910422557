import { useCallback, useContext } from "react";

import { StoreContext } from "../../../contexts";

const useLibrary = ({ ACTION, NAME }) => {
  const { library } = useContext(StoreContext);

  const error = library[`error${ACTION}`];
  const hasItems = library[`hasItemsOf${NAME}`];
  const hasNextPage = library[`hasNextPageOf${NAME}`];
  const isLoading = library[`isLoading${ACTION}`];
  const items = library[`itemsOf${NAME}`];
  const totalItems = library[`totalItemsOf${NAME}`];
  const freeAccess = library[`freeAccess`];

  console.log(items, "categoryName");

  const resetPage = useCallback(() => {
    library[`resetPage${NAME}`]();
  }, []);

  const setRequestPayload = useCallback((payload) => {
    library[`setRequestPayload${NAME}`](payload);
  }, []);

  const onLoad = useCallback((options = {}) => {
    const {
      section = "all",
      categoryId,
      holidayId,
      page = 1,
      categoryName,
      action,
      videoId,
    } = options;
    const params = {};

    if (categoryId) {
      params.categoryId = categoryId;
    } else if (holidayId) {
      params.holidayId = holidayId;
    } else if (section) {
      params.section = section;
    }

    if (action) {
      params.action = action;
    }

    library[`get${NAME}`]({ ...params, page, categoryName, videoId });
  }, []);

  return {
    error: error,
    hasItems: hasItems,
    hasNextPage: hasNextPage,
    isLoading: isLoading,
    items: items,
    totalItems: totalItems,
    onLoad: onLoad,
    resetPage: resetPage,
    setRequestPayload: setRequestPayload,
    freeAccess: freeAccess,
  };
};

export default useLibrary;
