import { useContext, useEffect } from "react";
import { ACTIONS } from "../../../../../store";
import { StoreContext } from "../../../../../contexts";

const usePledgePartners = ({ pledgeDonation }) => {
    const { donation } = useContext(StoreContext);
    const pledgePartners = donation[`pledgePartners`];

    useEffect(() => {
        donation[`${ACTIONS.DONATION.GET_DONATION_PARTNERS}`]({
            pledgeDonation,
        });
    }, []);

    return {
        pledgePartners,
    };
};

export default usePledgePartners;