import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import cs from "classnames";
import classes from "./MaorButton.module.scss";
import Icon, { NAMES, SIZES } from "../../../../atoms/Icon";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { APPLICATION_ROUTES } from "../../../../../constants";
import { scroller } from "react-scroll";
import { isMobile } from "react-device-detect";

const MaorButton = observer(() => {
  const history = useHistory();

  const onScrollToAudioSection = useCallback(() => {
    scroller.scrollTo("audio", { duration: 500, smooth: true, offset: -24 });
  }, [scroller]);

  const onScrollToVideoSection = useCallback(() => {
    scroller.scrollTo("videoList", {
      duration: 500,
      smooth: true,
      offset: -24,
    });
  }, [scroller]);

  const onScrollToColoringSection = useCallback(() => {
    scroller.scrollTo("coloring", { duration: 500, smooth: true, offset: -24 });
  }, [scroller]);
  const MyMaorButtons = [
    {
      title: "Audio Series",
      color: "dark-green",
      icon: NAMES.HeadphoneIcon,
      navigator: () => {
        onScrollToAudioSection();
      },
    },
    {
      title: "Coloring Pages",
      color: "green",
      icon: NAMES.ColoringIcon,
      navigator: () => {
        onScrollToColoringSection();
      },
    },
    {
      title: "Video Library",
      color: "yellow",
      icon: NAMES.VideoLibrary,
      navigator: () => {
        onScrollToVideoSection();
      },
    },
    {
      title: "Birthday Celebration",
      color: "pink",
      icon: NAMES.BirthdayCelebration,
      navigator: () => {
        history.push(APPLICATION_ROUTES.BIRTHDAY_PACKAGES_PAGE);
      },
    },
  ];

  return (
    <div className={cs(classes.maorButtonConatiner)}>
      {MyMaorButtons.map((item) => {
        return (
          <div
            key={item.title}
            className={cs(
              classes.maorButton,
              classes[item.color],
              item.title === "Birthday Celebration" && classes.lastChild
            )}
            onClick={() => {
              item.navigator();
            }}
          >
            <div className={cs(classes.buttonIcon)}>
              <Icon name={item.icon} size={isMobile ? SIZES.L : SIZES.XXL} />
            </div>
            <p>{item.title}</p>
          </div>
        );
      })}
    </div>
  );
});

export default MaorButton;
