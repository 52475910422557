import React from "react";
import PropTypes from "prop-types";

import { getRandomColor, createImageFromInitials } from "./utilities";
import Avatar from "../Avatar";

const LetterPicture = ({ name, color }) => <Avatar image={createImageFromInitials(100, name, color)} />;

LetterPicture.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
};

LetterPicture.defaultProps = {
  color: getRandomColor(),
};

export default LetterPicture;
