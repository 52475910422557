import API from "./_api";
import { API_ROUTES } from "../constants";

const LibraryAPI = class extends API {
  get({
    page,
    project,
    search,
    order,
    videoType,
    tags,
    holidayId,
    limitRecord,
    coloringPages,
    upcomingHoliday = false,
  }) {
    return this.request(`${API_ROUTES.LIBRARY.GET}?page=${page}`, {
      body: {
        project: project,
        search: search,
        order: order,
        videoType: videoType,
        tags: tags,
        holidayId: holidayId,
        limitRecord: limitRecord,
        coloringPages: coloringPages,
        upcomingHoliday: upcomingHoliday,
      },
      method: "POST",
    });
  }

  // getCombined({ project, section, page }) {
  //   return this.request(`${API_ROUTES.LIBRARY.COMBINED_LIBRARY}?page=${page}`, {
  //     body: {
  //       project: project,
  //       section: section,
  //     },
  //     method: "POST",
  //   });
  // }

  getCombined({
    project,
    section,
    page,
    categoryId,
    holidayId,
    action,
    videoId,
  }) {
    const body = { page, video_id: videoId, project };

    if (section) {
      body.section = section;
    } else if (categoryId) {
      body.category_id = categoryId;
    } else if (holidayId) {
      body.holiday_id = holidayId;
    }

    if (action) {
      body.action = action;
    }

    return this.request(`${API_ROUTES.LIBRARY.COMBINED_LIBRARY}?page=${page}`, {
      body,
      method: "POST",
    });
  }

  getCategories() {
    return this.request(`${API_ROUTES.CATEGORIES.GET}`, {
      method: "GET",
    });
  }

  getTags({ next_page }) {
    return this.request(`${API_ROUTES.TAGS.GET + "?page=" + next_page}`, {
      method: "GET",
    });
  }

  getTodayHolidays() {
    return this.request(`${API_ROUTES.HOLIDAYS.GET_TODAY}`, {
      method: "GET",
    });
  }

  getSearchingOptions(query) {
    return this.request(`${API_ROUTES.GET_AUTOCOMPLETE}?query=${query}`, {
      method: "GET",
    });
  }

  getVideo(request) {
    return this.request(`${API_ROUTES.LIBRARY.VIDEO.GET}`, {
      body: request,
      method: "POST",
    });
  }

  saveVideoQuestionOption(request) {
    return this.request(`${API_ROUTES.LIBRARY.VIDEO.SAVE_QUESTION_OPTION}`, {
      body: request,
      method: "POST",
    });
  }
};

export default LibraryAPI;
