import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import cs from "classnames";
import classes from './MyPlaylist.module.scss'
import { StoreContext } from '../../../contexts';
import useVideo from '../../pages/Library/components/Project/useVideo';
import { useModal } from '../../../hooks';
import Icon, { SIZES } from '../Icon';
import PlaylistModal from '../../organisms/Playlist/PlaylistModal';
import Modal from '../../organisms/Modal';
import { Authorization, EmptyPlaylist } from '../../organisms/RestrictionModal/RestrictionModal';
import { getURL } from '../../pages/Library/components/Project/components/Preview/utilities';
import Button from '../Button';

const MyPlaylist = observer(() => {
    const { authorization, playlist, partnership } = useContext(StoreContext);
    const video = useVideo();
    const popup = useModal();
    const playlistEmptyModal = useModal();

    return (
        <>
            <Button
                className={cs(classes.myPlaylistButton, classes.sidebarButton)}
                onClick={() => {
                    if (authorization.isAuthenticated) {
                        if (playlist.totalItems == 0) {
                            console.log('LOADING pLAYLIST')
                            playlistEmptyModal.onOpen();
                        } else {
                            if (authorization.isUserMember) {
                                let playlistItem = playlist.items[0];
                                playlist.setCurrentPlayingVideoId(playlistItem.video.id);
                                video.onOpen({
                                    end: playlistItem.video.end,
                                    start: playlistItem.video.start,
                                    url: getURL(playlistItem.video.url, {
                                        start: playlistItem.video.start,
                                    }),
                                    name: playlistItem.video.name,
                                    description: playlistItem.video.description,
                                    number: playlistItem.video.number,
                                    date: playlistItem.video.created_at,
                                    categories: playlistItem.video.video_directory.video_categories,
                                    tags: playlistItem.video.video_directory.video_tags,
                                    holiday: playlistItem.video.video_directory.holiday,
                                    file: playlistItem.video.video_directory.file,
                                    questions: playlistItem.video.video_questions,
                                    internal_id: playlistItem.video.video_directory.internal_id,
                                    info: {
                                        projectType: "",
                                    },
                                });
                            } else {
                                popup.onOpen();
                            }
                        }
                    } else {
                        popup.onOpen();
                    }
                }}
            >
                <Icon name="playlist" size={SIZES.S} /> My Playlist ({playlist.totalItems})
            </Button>

            <Modal opened={video.isOpened} style={video.style}>
                <PlaylistModal video={video} />
            </Modal>
            <Authorization popup={popup} authorization={authorization} partnership={partnership} />
            <EmptyPlaylist popup={playlistEmptyModal} />
        </>
    )
})

export default MyPlaylist;