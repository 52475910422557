import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const initializeSentry = () => {
  Sentry.init({
    dsn: "https://16d35b68834741a3a79f0ea97b07b7a7@o1357824.ingest.sentry.io/6644549",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
};

export default initializeSentry;
