import { useCallback, useState } from "react";
import { createState, validate } from "./utilities";

// const useSelect = ({ label = "", options = [], value: initialValue = "" }) => {
//   const [value, setValue] = useState(initialValue);

//   const onChange = useCallback(
//     (event) => {
//       setValue(event.target.value);
//     },
//     [setValue]
//   );

//   return {
//     label,
//     options,
//     value,
//     onChange,
//   };
// };

const useSelect = ({ label, options = [], validators = [], value = "" } = {}) => {
  const [state, setState] = useState(createState({ validators, value }));

  const onChange = useCallback(
    ({ target: { value } }) => {
      setState((state) => {
        const errors = validate(value, state.validators);

        return {
          ...state,
          errors,
          touched: true,
          value,
        };
      });
    },
    [setState]
  );

  return {
    error: state.touched && state.errors.length ? state.errors[0] : "",
    errors: state.errors,
    label: label,
    options,
    touched: state.touched,
    value: state.value,
    onChange,
  };
};

export default useSelect;
