import React from "react";
import cs from "classnames";

import PageTitle from "../../atoms/PageTitle";
import Layout from "../../templates/Layout";
import { CONTACTS, PAGE_TITLES } from "../../../constants";
import classes from "./PrivacyPolicy.module.scss";

const PrivacyPolicy = () => {
  return (
    <>
      <PageTitle value={PAGE_TITLES.PRIVACY_POLICY} />
      <Layout>
        <div className={cs(classes.container, classes.wrapper, "wrapper-xl")}>
          <h2 className={classes.title}>Privacy Policy</h2>
          <p className={cs(classes.paragraph, 'mt-05')}>
            Maor.org, (Maor), is committed to keeping any and all personal information collected of those individuals
            that visit our website and make use of our online facilities and services accurate,confidential, secure and
            private. Our privacy policy has been designed and created to ensure those affiliated with Maor.org of our
            commitment and realization of our obligation not only to meet but to exceed most existing privacy standards.
          </p>
          <p className={classes.paragraph}>
            <b>THEREFORE</b>, this Privacy Policy Agreement shall apply to Maor.org , and thus it shall govern any and
            all data collection and usage thereof. Through the use of Maor.org you are here in consenting to the
            following data procedures expressed within this agreement.
          </p>
          <h3 className={classes.subtitle}>Collection of Information</h3>
          <p className={classes.paragraph}>This website collects various types of information, such as:</p>
          <ul className={classes.list}>
            <li className={classes.item}>
              Voluntarily provided information which may include your name, address, email address, billing and/or
              credit card information etc., which may be used when you purchase products and/or services and to deliver
              the services you have requested.
            </li>
            <li className={classes.item}>
              Information automatically collected when visiting our website, which may include cookies, third party
              tracking technologies and server logs.
            </li>
          </ul>
          <p className={classes.paragraph}>
            Please rest assured that this site shall only collect personal information that you knowingly and willingly
            provide by way of surveys, completed membership forms, and emails. It is the intent of this site to use
            personal information only for the purpose for which it was requested and any additional uses specifically
            provided on this site.
          </p>
          <p className={classes.paragraph}>
            Maor.org may have the occasion to collect anonymous demographic information, such as age, gender, household
            income, political affiliation, race and religion at a later time.We may also gather information about the
            type of browser you are using, IP address or type of operating system to assist us in providing and
            maintaining superior quality service.
          </p>
          <p className={classes.paragraph}>
            It is highly recommended and suggested that you review the privacy policies and statements of any website
            you choose to use or frequent as a means to better understand the way in which other websites garner, make
            use of and share information collected.
          </p>
          <p className={classes.paragraph}>
            We partner with Microsoft Clarity and Microsoft Advertising to capture how you use and interact with our website through behavioral metrics, heatmaps, and session replay to improve and market our products/services. Website usage data is captured using first and third-party cookies and other tracking technologies to determine the popularity of products/services and online activity. Additionally, we use this information for site optimization, fraud/security purposes, and advertising. For more information about how Microsoft collects and uses your data, visit the <a href="https://privacy.microsoft.com/en-US/privacystatement" target="_blank" rel="noreferrer"> Microsoft Privacy Statement </a>.
          </p>

          <h3 className={classes.subtitle}>Use of Information Collected</h3>
          <p className={classes.paragraph}>
            Maor.org may collect and may make use of personal information to assist in the operation of our website and
            to ensure delivery of the services you need and request. At times, we may find it necessary to use
            personally identifiable information as a means to keep you informed of other possible products and/or
            services that may be available to you from Maor.org.
          </p>
          <p className={classes.paragraph}>
            Maor.org may also be in contact with you with regards to completing surveys and/or research questionnaires
            related to your opinion of current or potential future services that may be offered.
          </p>
          <p className={classes.paragraph}>
            Maor.org does not now, nor will it in the future, sell, rent or lease any of our customer lists and/or names
            to any third parties.
          </p>
          <p className={classes.paragraph}>
            Maor.org may feel it necessary, from time to time, to make contact with you on behalf of other external
            business partners with regards to a potential new offer which may be of interest to you. If you consent or
            show interest in presented offers, then, at that time, specific identifiable information, such as name,
            email address and/or telephone number, may be shared with the third party.
          </p>
          <p className={classes.paragraph}>
            Maor.org may deem it necessary to follow websites and/or pages that our users may frequent in an effort to
            gleam what types of services and/or products may be the most popular to customers or the general public.
          </p>
          <p className={classes.paragraph}>
            Maor.org may disclose your personal information, without prior notice to you, only if required to do so in
            accordance with applicable laws and/or in a good faith belief that such action is deemed necessary or is
            required in an effort to:
          </p>
          <ul className={classes.list}>
            <li className={classes.item}>
              Remain in conformance with any decrees, laws and/or statutes or in an effort to comply with any process
              which may be served upon Maor.org and/or our website;
            </li>
            <li className={classes.item}>
              Maintain, safeguard and/or preserve all the rights and/or property of Maor.org; and
            </li>
            <li className={classes.item}>
              Perform under demanding conditions in an effort to safeguard the personal safety of users of Maor.org
              and/or the general public.
            </li>
          </ul>
          <h3 className={classes.subtitle}>Unsubscribe or Opt-Out</h3>
          <p className={classes.paragraph}>
            All users and/or visitors to our website have the option to discontinue receiving communication from us
            and/or reserve the right to discontinue receiving communications by way of email or newsletters. To
            discontinue or unsubscribe to our website please send an email that you wish to unsubscribe to{" "}
            <a href="mailto:list@maor.org">list@maor.org</a>. If you wish to unsubscribe or opt-out from any third party
            websites, you must go to that specific website to unsubscribe and/or opt-out.
          </p>
          <h3 className={classes.subtitle}>Links to Other Web Sites</h3>
          <p className={classes.paragraph}>
            Our website does contain links to affiliate and other websites. Maor.org does not claim nor accept
            responsibility for any privacy policies, practices and/or procedures of other such websites. Therefore, we
            encourage all users and visitors to be aware when they leave our website and to read the privacy statements
            of each and every website that collects personally identifiable information. The aforementioned Privacy
            Policy Agreement applies only and solely to the information collected by our website.
          </p>
          <h3 className={classes.subtitle}>Security</h3>
          <p className={classes.paragraph}>
            Maor.org shall endeavor and shall take every precaution to maintain adequate physical, procedural and
            technical security with respect to our offices and information storage facilities so as to prevent any loss,
            misuse, unauthorized access, disclosure or modification of the user&apos;s personal information under our
            control.
          </p>
          <p className={classes.paragraph}>
            The company also uses Secure Socket Layer (SSL) for authentication and private communications in an effort
            to build user&apos;s trust and confidence in the internet and website use by providing simple and secure
            access and communication of credit card and personal information.
          </p>
          <h3 className={classes.subtitle}>Changes to Privacy Policy Agreement</h3>
          <p className={classes.paragraph}>
            Maor.org reserves the right to update and/or change the terms of our privacy policy, and as such we will
            post those change to our website homepage at Maor.org, so that our users and/or visitors are always aware of
            the type of information we collect, how it will be used, and under what circumstances, if any, we may
            disclose such information. If at any point in time Maor.org decides to make use of any personally
            identifiable information on file, in a manner vastly different from that which was stated when this
            information was initially collected, the user or users shall be promptly notified by email. Users at that
            time shall have the option as to whether or not to permit the use of their information in this separate
            manner.
          </p>
          <h3 className={classes.subtitle}>Acceptance of Terms</h3>
          <p className={classes.paragraph}>
            Through the use of this website, you are hereby accepting the terms and conditions stipulated within the
            aforementioned Privacy Policy Agreement. If you are not in agreement with our terms and conditions, then you
            should refrain from further use of our sites. In addition, your continued use of our website following the
            posting of any updates or changes to our terms and conditions shall mean that you are in agreement and
            acceptance of such changes.
          </p>
          <h3 className={classes.subtitle}>How to Contact Us</h3>
          <p className={classes.paragraph}>
            If you have any questions or concerns regarding the Privacy Policy Agreement related to our website, please
            feel free to contact us at the following email, telephone number or mailing address.
          </p>
          <p className={classes.paragraph}>
            <span>
              <b>Email:</b> <a href="mailto:office@maor.org"> office@maor.org</a>
            </span>
            <br />
            <span>
              <b>Telephone Number:</b> <a href={`tel:${CONTACTS.PHONE}`}>{CONTACTS.PHONE}</a>
            </span>
            <br />
            <span>
              <b>Mailing Address:</b>
            </span>
            <br />
            <span>Maor.org 478 Albany Ave. #106</span>
            <br />
            <span>Brooklyn, New York 11203</span>
            <br />
          </p>
        </div>
      </Layout>
    </>
  );
};

export default React.memo(PrivacyPolicy);
