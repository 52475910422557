import React, { useCallback, useContext, useEffect } from "react";

import Order from "../../templates/Order";
import useOrder from "./useOrder";
import { observer } from "mobx-react-lite";
import classes from "./MyMaorOrder.module.scss";
import { StoreContext } from "../../../contexts";
// import { APPLICATION_ROUTES } from "../../../constants";
// import { Link } from "react-router-dom";
import CartIcon from "../../atoms/CartIcon/CartIcon";
import { APPLICATION_ROUTES, COOKIES } from "../../../constants";
import { scrollToTop } from "../../../utilities/helpers";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "../../../utilities/cookies";

// import { StoreContext } from "../../../contexts";
// import { APPLICATION_ROUTES } from "../../../constants";
// import { ACTIONS } from "../../../store";

const MyMaorOrder = observer(() => {
  const { cart } = useContext(StoreContext);
  const { t } = useTranslation();

  const history = useHistory();

  useEffect(() => {
    cart.cartType = "birthday";
    cart.get();
  }, []);

  const navigateToCart = useCallback(() => {
    history.push(APPLICATION_ROUTES.MY_MAOR_CHECKOUT);
    scrollToTop();
  }, []);
  const cartID = Cookies.get({ name: COOKIES.CART_ID });

  console.log(cartID);
  // useEffect(() => {
  //   if (
  //     !(
  //       authorization[`isLoading${ACTIONS.AUTHORIZATION.SIGN_IN}`] &&
  //       authorization[`isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`]
  //     )
  //   ) {
  //     if (!authorization.isAuthenticated) {
  //       window.location = APPLICATION_ROUTES.MY_MAOR_PACKAGES;
  //     }
  //   }
  // }, [authorization.isAuthenticated]);

  const options = useOrder();
  return (
    <>
      <Order options={options} type="child" />
      {cart.items.length > 0 && cartID && (
        <div className={classes.fixed}>
          <CartIcon
            title={t("pages.checkout.texts.18")}
            itemsCount={cart.items.length}
            onClick={navigateToCart}
          />
        </div>
      )}
    </>
  );
});

export default MyMaorOrder;
