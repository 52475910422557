import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import PlainInput from "../../../../atoms/PlainInput";
// import RadioDots from "../../../../atoms/RadioDots";
import classes from "./BirthdayDetails.module.scss";
import SelectR from "../../../../atoms/SelectR";
import cs from "classnames";
import Checkbox from "../../../../atoms/Checkbox";
import TextToggleCheckbox from "../../../../atoms/ToggleBox/ToggleBox";
import { useEffect } from "react";
import { useState } from "react";
import Icon, { NAMES } from "../../../../atoms/Icon";
// import Datepicker from "../../../../molecules/Datepicker";
import Loader from "../../../../atoms/Loader";
import { StoreContext } from "../../../../../contexts";
import { ACTIONS } from "../../../../../store";
import { useDateFormat, useQuery, useModal } from "../../../../../hooks";
import { observer } from "mobx-react-lite";
import RadioDots from "../../../../atoms/RadioDots";
import { animateScroll as scroll } from "react-scroll";
import Modal from "../../../../organisms/Modal";
import Button, { COLORS } from "../../../../atoms/Button";

const BirthdayDetails = observer(({
  jewishDay,
  jewishMonth,
  jewishYear,
  gregorianDateText,
  enDay,
  enMonth,
  enYear,
  city,
  country,
  firstName,
  lastName,
  pronunciation,
  person_id,
  rank,
  sex,
  state,
  afterShkiyaCheckbox,
  languageToggleBox,
  englishToJewishDate,
  jewishDate,
  jewishFullDate,
  setAddChild,
  addChild,
  isEnglishDate,
  isJewishDate,
  deleteChildSuccess,
  setDeleteChildSuccess,
  isAfterShkiyaChecked,
  setIsAfterShkiyaChecked,
  // birthday,
}) => {
  const {
    t,
    // i18n: { language },
  } = useTranslation();
  const NAME = t("projects.maor-kids.1_name");
  const BIRTHDAY = t("projects.maor-kids.2_birthday");
  // const DETAILS = t("projects.maor-kids.3_details");
  const LOCATION = t("projects.maor-kids.4_location");
  const MAZAL_TOV_SO_WHOS_THE_LUCKY_CHILD = t(
    "projects.maor-kids.mazal_tov_so_who_is_the_lucky_child"
  );
  // const isNotHebrew = language !== "he";
  // const [selectedValue, setSelectedValue] = useState("Civil");

  // const handleInputChange = (event) => {
  //   setSelectedValue(event.target.value);
  // };
  const query = useQuery();
  const [isCivil, setIsCivil] = useState(true);
  const { authorization, persons } = useContext(StoreContext)

  const personsData = authorization.persons;
  const deleteModal = useModal();
  const [selectedChildId, setSelectedChildId] = useState(null);

  // const deletChildSuccessMsg = persons[`success${ACTIONS.PERSONS.DELETE_CHILD}`];
  const deleteChildError = persons[`error${ACTIONS.PERSONS.DELETE_CHILD}`];
  const deleteChildLoading = persons[`isLoading${ACTIONS.PERSONS.DELETE_CHILD}`];

  useEffect(() => {
    if (languageToggleBox.selectedValue === "Civil") {
      setIsCivil(true);
    } else {
      setIsCivil(false);
    }
  });

  useEffect(() => {
    setDeleteChildSuccess(persons[`success${ACTIONS.PERSONS.DELETE_CHILD}`]);
    persons[`setSuccess`](ACTIONS.PERSONS.DELETE_CHILD, false);
    if (deleteChildSuccess) {
      deleteModal.onClose();
    }
  }, [
    persons[`success${ACTIONS.PERSONS.DELETE_CHILD}`],
    persons[`isLoading${ACTIONS.PERSONS.DELETE_CHILD}`],
    persons,
  ]);

  const addChildHandler = () => {
    setAddChild(!addChild);
    console.log(addChild, "personspersons");
  };

  const addNewChild = () => {
    firstName.onChange({ target: { value: " " }, touched: false });
    person_id.onChange({ target: { value: " " }, touched: false });
    rank.onChange({ target: { value: " " }, touched: false });
    sex.onChange({
      target: {
        value: "",
      },
      touched: false
    });
    state.onChange({ target: { value: " " }, touched: false });
    city.onChange({ target: { value: " " }, touched: false });
    country.onChange({ target: { value: " " }, touched: false });
    pronunciation.onChange({ target: { value: " " }, touched: false });
    enDay.onChange({
      value: "",
    }, false);
    enMonth.onChange({
      value: " ",
    }, false);
    enYear.onChange({
      value: ""
    }, false);
    jewishDay.onChange({
      value: "",
    }, false);
    jewishMonth.onChange({
      value: " ",
    }, false);
    jewishYear.onChange({
      value: ""
    }, false);

    addChildHandler()
  }

  // useEffect(() => {
  //   if (query.get("id")) {
  //     setAddChild(true);
  //     console.log(addChild, "personspersons");
  //   }
  // }, []);

  useEffect(() => {
    if (personsData.length == 0 || query.get("id")) {
      setAddChild(true);
    } else {
      setAddChild(false);
    }
  }, [personsData.length, authorization[`isLoading${ACTIONS.AUTHORIZATION.GET_PERSONS}`]]);

  // const useDateFormatTemp = (date, timeZone = false) => {
  //   if (date == null || date == undefined) {
  //     return null;
  //   }

  //   date = date.split("-");
  //   const utcDateObject = new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]));

  //   // Check if timeZone is true before converting to EST
  //   let estDate;
  //   if (timeZone) {
  //     estDate = utcDateObject.toLocaleString('en-US', {
  //       timeZone: 'America/New_York',
  //     });
  //   } else {
  //     estDate = utcDateObject.toLocaleString('en-US');
  //   }

  //   const formattedDate = new Date(estDate).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' });

  //   return formattedDate;
  // }

  const handlePickChildClick = (selectedChild) => {
    city.onChange({ target: { value: selectedChild.city } });
    country.onChange({ target: { value: selectedChild.country } });
    firstName.onChange({ target: { value: selectedChild.first_name } });
    lastName.onChange({ target: { value: selectedChild.last_name } });
    pronunciation.onChange({ target: { value: selectedChild.pronunciation } });
    person_id.onChange({ target: { value: selectedChild.id } });
    rank.onChange({ target: { value: selectedChild.rank } });
    sex.onChange({
      target: {
        value: selectedChild.sex == null
          ? null
          : selectedChild.sex === "man" || selectedChild.sex === "boy"
            ? "boy"
            : "girl",
      },
    });
    state.onChange({ target: { value: selectedChild.state } });
    enDay.onChange({
      label:
        selectedChild.birthday_date == null
          ? null
          : parseInt(selectedChild.birthday_date.split("-")[2], 10),
      value:
        selectedChild.birthday_date == null
          ? null
          : parseInt(selectedChild.birthday_date.split("-")[2], 10),
    });

    const monthMapping = {
      1: "January",
      2: "February",
      3: "March",
      4: "April",
      5: "May",
      6: "June",
      7: "July",
      8: "August",
      9: "September",
      10: "October",
      11: "November",
      12: "December",
    };

    const monthNumber =
      selectedChild.birthday_date == null
        ? null
        : parseInt(selectedChild.birthday_date.split("-")[1], 10);
    const monthName =
      selectedChild.birthday_date == null ? null : monthMapping[monthNumber];

    enMonth.onChange({
      label: monthName,
      value: monthName,
    });

    enYear.onChange({
      label:
        selectedChild.birthday_date == null
          ? null
          : parseInt(selectedChild.birthday_date.split("-")[0], 10),
      value:
        selectedChild.birthday_date == null
          ? null
          : parseInt(selectedChild.birthday_date.split("-")[0], 10),
    });

    afterShkiyaCheckbox.onChange({ target: {value: selectedChild.born_after_sunset == 0 ? false : true}})

    addChildHandler();
    scroll.scrollToTop();
  };

  console.log("selectedChild", sex);

  if (authorization[`isLoading${ACTIONS.AUTHORIZATION.GET_PERSONS}`]) {
    return (
      <div className={classes.container}>
        <div className={cs(classes.wrapper)}>
          <Loader />
        </div>
      </div>
    );
  }

  function capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }

  const deleteChild = () => {
    persons[`${ACTIONS.PERSONS.DELETE_CHILD}`](selectedChildId)
    console.log("Delete child with ID:", selectedChildId, deleteChildSuccess);
    if (deleteChildSuccess) {
      deleteModal.onClose();
    }
  }

  return (
    <div>
      <h2 className="heading-l-a ta-center c-blue-dark">
        {MAZAL_TOV_SO_WHOS_THE_LUCKY_CHILD}
      </h2>
      {!addChild &&
        <p className="ta-center c-blue-dark">
          Choose a child. You can edit details in the Next step
        </p>
      }

      {!addChild && (
        <div className={classes.childrenCardContainer}>
          <div className={classes.addChildBox} onClick={addNewChild}>
            <p className={cs(classes.font32)}>+</p>
            <p className={cs(classes.addChild)}>Add Child</p>
          </div>

          {personsData?.map((data, i) => {
            return (
              <div
                className={classes.pickChildBox}
                key={i}
                onClick={() => handlePickChildClick(data)}
              >
                <div className={cs(classes.crossButton)} onClick={(e) => {
                  setSelectedChildId(data.id);
                  e.stopPropagation();
                  deleteModal.onOpen();
                }}>
                  <Icon name={NAMES.CrossButton} size="xxs" />
                </div>

                <div
                  className={classes.childBoxContent}
                  style={{ justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className={classes.childName}>
                      <Icon name={NAMES.USER} style={{ color: "#ff820e" }} />
                    </div>

                    <p>
                      <b style={{ color: "#27305A" }}>
                        {data.first_name} {data.last_name}
                      </b>{" "}
                      | {capitalizeFirstLetter(data.sex)}
                    </p>
                  </div>
                </div>

                {data.birthday_date != null &&
                  <div className={classes.childBoxContent}>
                    <Icon name={NAMES.CAKE} className={classes.iconStyle} />
                    <p className={cs(classes.mt05)}>
                      {useDateFormat(data.birthday_date, "-")} <br /> {data.he_he_date}
                    </p>
                  </div>
                }

                <div className={classes.childBoxContent}>
                  <Icon name={NAMES.MAP_MARKER} className={classes.iconStyle} />
                  <p>
                    {data.city}, {data.state}, {data.country}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {addChild && (
        <div>
          <h3 className="heading-m mt-04 c-orange">{NAME}</h3>
          <ul className={cs(classes.list, classes.nameList)}>
            <li className={classes.item}>
              <PlainInput
                error={firstName.error}
                id="my-maor-order-form-first-name"
                label={firstName.label}
                value={firstName.value}
                onChange={firstName.onChange}
              />
            </li>
            <li className={classes.item}>
              <PlainInput
                error={lastName.error}
                id="my-maor-order-form-last-name"
                label={lastName.label}
                value={lastName.value}
                onChange={lastName.onChange}
              />
            </li>
            <li className={classes.item}>
              {/* {isNotHebrew && ( */}
              <PlainInput
                error={pronunciation.error}
                id="my-maor-order-form-pronunciation"
                label={pronunciation.label}
                value={pronunciation.value}
                onChange={pronunciation.onChange}
              />
              {/* )} */}
            </li>

            {/* <li className={classes.item}>
              <PlainInput
                error={person_id.error}
                id="my-maor-order-form-person_id"
                label={person_id.label}
                value={person_id.value}
                onChange={person_id.onChange}
              />
            </li> */}
            {/* 
            <li className={classes.item}>
              {/* <RadioDots horizontal items={sex.options} value={sex.value} onChange={sex.onChange} />
              <SelectR
                id="my-maor-order-form-gender"
                label={sex.label}
                options={sex.options}
                value={sex.value}
                onChange={sex.onChange}
                useObject={true}
                error={sex.error}
              />
            </li> */}
          </ul>

          <h3 className="heading-m mt-10 c-orange">2. Personal Information</h3>
          <ul className={cs(classes.list, classes.personalInformation)}>
            <li className={classes.item}>
              <PlainInput
                error={rank.error}
                id="my-maor-order-form-rank"
                label={rank.label}
                value={rank.value}
                onChange={rank.onChange}
              />
            </li>
            <li className={cs(classes.item)}>
              <div className={cs(classes.relative)}>
                <div className={cs(classes.inputStyle)}>
                  <RadioDots horizontal items={sex.options} value={sex.value} onChange={sex.onChange} error={sex.error} orangeBorderRadio={true} />
                  <span className={cs(classes.inputLine)}></span>
                </div>
                <div className={cs(classes.tape)}></div>
                <label className={cs(classes.inputLabel)}>Gender *</label>
              </div>
            </li>
          </ul>

          <div className={cs(classes.birthdayTop)}>
            <h3 className="heading-m mt-10 c-orange">{BIRTHDAY}</h3>

            <div className={cs(classes.birthdayOptions)}>
              <TextToggleCheckbox
                handleInputChange={languageToggleBox.handleInputChange}
                selectedValue={languageToggleBox.selectedValue}
                label1="Civil"
                label2="Jewish"
              />

              <div
                className={cs(
                  classes.shkiyaCheckbox,
                  languageToggleBox.selectedValue === "Jewish" &&
                  classes.disabelShkiyaCheckbox
                )}
              >
                {isCivil ? (
                  <Checkbox {...afterShkiyaCheckbox} birthday={true}>
                    {afterShkiyaCheckbox.label}
                  </Checkbox>
                ) : (
                  <Checkbox
                    {...afterShkiyaCheckbox}
                    checked={false}
                    birthday={true}
                  >
                    {afterShkiyaCheckbox.label}
                  </Checkbox>
                )}
              </div>
            </div>
          </div>

          <div className={classes.dateContainer}>
            <ul className={cs(classes.list, classes.birthday)}>
              {isCivil && (
                <div className={cs(classes.dateInputContainer)}>
                  <div className={cs(classes.datePickerEng)}>
                    <li className={classes.item}>
                      <SelectR
                        id="my-maor-order-form-month"
                        label={enMonth.label}
                        options={enMonth.options}
                        value={enMonth.value}
                        onChange={enMonth.onChange}
                        error={enMonth.error}
                      />
                    </li>
                    <li className={classes.item}>
                      <SelectR
                        id="my-maor-order-form-day"
                        label={enDay.label}
                        options={enDay.options}
                        value={enDay.value}
                        onChange={enDay.onChange}
                        error={enDay.error}
                      />
                    </li>
                    <li className={classes.item}>
                      <SelectR
                        id="my-maor-order-form-year"
                        label={enYear.label}
                        options={enYear.options}
                        value={enYear.value}
                        onChange={enYear.onChange}
                        error={enYear.error}
                      />
                    </li>
                  </div>
                  {!isEnglishDate &&
                    <div className={cs(classes.dateText)}>
                      <p>
                        {englishToJewishDate} <br />
                        <span className={cs(classes.hide)}>{jewishDate}</span>
                      </p>
                    </div>
                  }
                </div>
              )}

              {!isCivil && (
                <div className={cs(classes.dateInputContainer)}>
                  <div className={cs(classes.datePicker)}>
                    <li className={classes.item}>
                      <SelectR
                        id="my-maor-order-form-year"
                        label={jewishYear.label}
                        options={jewishYear.options}
                        value={jewishYear.value}
                        onChange={(value) => {
                          if (isAfterShkiyaChecked) {
                            afterShkiyaCheckbox.setChecked(false);
                            setIsAfterShkiyaChecked(false);
                          }
                          jewishYear.onChange(value);
                        }}
                      />
                    </li>
                    <li className={classes.item}>
                      <SelectR
                        id="my-maor-order-form-month"
                        label={jewishMonth.label}
                        options={jewishMonth.options}
                        value={jewishMonth.value}
                        onChange={(value) => {
                          if (isAfterShkiyaChecked) {
                            afterShkiyaCheckbox.setChecked(false);
                            setIsAfterShkiyaChecked(false);
                          }
                          jewishMonth.onChange(value);
                        }}
                      />
                    </li>
                    <li className={classes.item}>
                      <SelectR
                        id="my-maor-order-form-day"
                        label={jewishDay.label}
                        options={jewishDay.options}
                        value={jewishDay.value}
                        onChange={(value) => {
                          if (isAfterShkiyaChecked) {
                            afterShkiyaCheckbox.setChecked(false);
                            setIsAfterShkiyaChecked(false);
                          }
                          jewishDay.onChange(value);
                        }}
                      />
                    </li>
                  </div>
                  {!isJewishDate &&
                    <div className={cs(classes.dateText)}>
                      <p>
                        {useDateFormat(gregorianDateText, "/")} {isAfterShkiyaChecked && <span>(After Shkiya)</span>} <br />{" "}
                        <span className={cs(classes.hide)}>{jewishFullDate}</span>
                      </p>
                    </div>
                  }
                </div>
              )}
              {/* <li className={classes.item}>
                <PlainInput
                  error={rank.error}
                  id="my-maor-order-form-rank"
                  label={rank.label}
                  value={rank.value}
                  onChange={rank.onChange}
                />
              </li> */}
            </ul>
          </div>

          <h3 className="heading-m mt-10 c-orange">{LOCATION}</h3>
          <ul className={classes.list}>
            <li className={classes.item}>
              <PlainInput
                error={city.error}
                id="my-maor-order-form-city"
                label={city.label}
                value={city.value}
                onChange={city.onChange}
              />
            </li>
            <li className={classes.item}>
              <PlainInput
                error={state.error}
                id="my-maor-order-form-state"
                label={state.label}
                value={state.value}
                onChange={state.onChange}
              />
            </li>
            <li className={classes.item}>
              <PlainInput
                error={country.error}
                id="my-maor-order-form-country"
                label={country.label}
                value={country.value}
                onChange={country.onChange}
              />
            </li>
          </ul>
        </div>
      )}
      <Modal opened={deleteModal.isOpened}>
        <div className={classes.deleteModalConatiner}>
          <div className={classes.deleteModalCard}>
            <div className={cs(classes.main)}>
              <h4 className="title">Are you sure you want to delete this record?</h4>
            </div>
            {
              deleteChildError && <p>Something Went Wrong</p>
            }
            <div className={cs("d-flex jc-center gap-10 mt-07")}>
              <button
                className={cs(classes.addBtn, "fw-700 c-orange")}
                onClick={deleteChild}
                disabled={deleteChildLoading}
              >
                {deleteChildLoading ? (
                  "Please wait..."
                ) : (
                  <>
                    <p>Confirm</p>
                  </>
                )}
              </button>
              <Button color={COLORS.GHOST} onClick={deleteModal.onClose}>
                <p>Cancel</p>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});

const inputPropTypes = PropTypes.shape({
  error: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  touched: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string,
}).isRequired;

// const radioPropTypes = PropTypes.shape({
//   options: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.string.isRequired,
//       label: PropTypes.string.isRequired,
//       value: PropTypes.string.isRequired,
//     })
//   ).isRequired,
//   value: PropTypes.string.isRequired,
//   onChange: PropTypes.func.isRequired,
// }).isRequired;

const selectPropTypes = PropTypes.shape({
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  bool: PropTypes.bool,
  selectedValue: PropTypes.string,
}).isRequired;

BirthdayDetails.propTypes = {
  // child: selectPropTypes,
  jewishDay: selectPropTypes,
  jewishMonth: selectPropTypes,
  jewishYear: selectPropTypes,
  city: inputPropTypes,
  country: inputPropTypes,
  firstName: inputPropTypes,
  lastName: inputPropTypes,
  pronunciation: inputPropTypes,
  person_id: inputPropTypes,
  rank: inputPropTypes,
  sex: selectPropTypes,
  state: inputPropTypes,
  afterShkiyaCheckbox: inputPropTypes,
  languageToggleBox: inputPropTypes,
  enDay: selectPropTypes,
  enMonth: selectPropTypes,
  enYear: selectPropTypes,
  jewishDate: selectPropTypes,
  gregorianDateText: selectPropTypes,
  englishToJewishDate: selectPropTypes,
  jewishFullDate: PropTypes.string,
  setAddChild: selectPropTypes,
  addChild: selectPropTypes,
  isEnglishDate: selectPropTypes,
  setDeleteChildSuccess: selectPropTypes,
  deleteChildSuccess: selectPropTypes,
  // birthday: PropTypes.object,
};

export default BirthdayDetails;
