
const heMonths = {
    "0": {
        "jewish": "תשרי",
        "jewishEng": "Tishrei",
    },
    "1": {
        "jewish": "חשון",
        "jewishEng": "Cheshvan",
    },
    "2": {
        "jewish": "כסלו",
        "jewishEng": "Kislev",
    },
    "3": {
        "jewish": "טבת",
        "jewishEng": "Tevet",
    },
    "4": {
        "jewish": "שבט",
        "jewishEng": "Sh'vat",
    },
    "5": {
        "jewish": "אדר",
        "jewishEng": "Adar",
    },
    "6": {
        "jewish": "אדר א",
        "jewishEng": "Adar 1",
    },
    "7": {
        "jewish": "אדר ב",
        "jewishEng": "Adar 2",
    },
    "8": {
        "jewish": "ניסן",
        "jewishEng": "Nisan",
    },
    "9": {
        "jewish": "אייר",
        "jewishEng": "Iyyar",
    },
    "10": {
        "jewish": "סיון",
        "jewishEng": "Sivan",
    },
    "11": {
        "jewish": "תמוז",
        "jewishEng": "Tamuz",
    },
    "12": {
        "jewish": "אב",
        "jewishEng": "Av",
    },
    "13": {
        "jewish": "אלול",
        "jewishEng": "Elul",
    },
}


const getJewishMonth = (month) => {

    for (const key in heMonths) {
        if (heMonths[key].jewishEng === month) {
            return heMonths[key].jewish
        }
    }
};

export default getJewishMonth;