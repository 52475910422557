import API from "./_api";
import { API_ROUTES } from "../constants";

const CheckoutAPI = class extends API {
  createTransaction({
    anonymous,
    cartID,
    language,
    projectID,
    reAuthenticated = false,
  }) {
    return this.request(API_ROUTES.CHECKOUT.CREATE_TRANSACTION, {
      body: {
        data: {
          anonymous: anonymous,
          id: cartID,
          project_id: projectID,
          language: language,
          reAuthenticated: reAuthenticated,
        },
      },
      method: "POST",
    });
  }

  updateTransaction({ transactionID, credits }) {
    return this.request(API_ROUTES.CHECKOUT.UPDATE_TRANSACTION, {
      body: {
        data: {
          id: transactionID,
          credits_used: credits,
        },
      },
      method: "POST",
    });
  }

  createBirthdayTransaction({
    anonymous,
    cartID,
    language,
    projectID,
    reAuthenticated = false,
  }) {
    return this.request(API_ROUTES.CHECKOUT.CREATE_BIRTHDAY_TRANSACTION, {
      body: {
        data: {
          anonymous: anonymous,
          id: cartID,
          project_id: projectID,
          language: language,
          reAuthenticated: reAuthenticated,
        },
      },
      method: "POST",
    });
  }

  getToken({ authorization, language, method }) {
    return this.request(API_ROUTES.CHECKOUT.GET_TOKEN, {
      body: {
        data: {
          gateway: method,
          language: language,
        },
      },
      headers: {
        Authorization: authorization,
      },
      method: "POST",
    });
  }

  getTransaction({ id }) {
    return this.request(API_ROUTES.CHECKOUT.GET_TRANSACTION, {
      body: {
        data: {
          id: id,
        },
      },
      method: "POST",
    });
  }

  sale({
    from,
    gateway,
    language,
    nonce,
    transactionID,
    page = "",
    cartID,
    donation_recurring,
  }) {
    return this.request(API_ROUTES.CHECKOUT.SALE, {
      body: {
        data: {
          from: from,
          gateway: gateway,
          language: language,
          cart_id: cartID,
          nonce: nonce,
          transaction_id: transactionID,
          page: page,
          donation_recurring: donation_recurring ? true : undefined,
        },
      },
      method: "POST",
    });
  }

  credit({
    from,
    gateway,
    language,
    nonce,
    transactionID,
    cartID,
    videoDates,
  }) {
    return this.request(API_ROUTES.CHECKOUT.CREDIT, {
      body: {
        data: {
          from: from,
          gateway: gateway,
          language: language,
          nonce: nonce,
          transaction_id: transactionID,
          cart_id: cartID,
          birthday_video: videoDates,
        },
      },
      method: "POST",
    });
  }
};

export default CheckoutAPI;
