const DIRECTIONS = {
  DOWN: "down",
  UP: "up",
};

const calculateDirection = ({ current, previous, previousDirection }) => {
  if (previous < current) return DIRECTIONS.DOWN;
  if (previous > current) return DIRECTIONS.UP;

  return previousDirection;
};

const calculatePercent = ({ full, scrolled, visible }) => {
  return Math.round((100 / (full - visible)) * scrolled);
};

const createState = () => {
  return { vertical: { direction: DIRECTIONS.DOWN, full: 0, percent: 0, scrolled: 0, visible: 0 } };
};

const round = (value, { min = 0, max = 100 } = {}) => {
  if (value < min) return min;
  if (value > max) return max;

  return value;
};

export { calculateDirection, calculatePercent, createState, round };
