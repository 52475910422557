import { useCallback, useEffect, useState } from "react";

import { getStyle, ATTRIBUTES, POSITIONS, WIDTH } from "./utilities";

const usePopover = ({ selector, position = POSITIONS.CENTER_TOP_OUT, width = WIDTH.DEFAULT }) => {
  const [style, setStyle] = useState(null);

  const close = useCallback(() => {
    setStyle(null);
  }, []);

  const open = useCallback(() => {
    const element = document.querySelector(selector);

    if (element) {
      const style = getStyle(element, { position, width });
      setStyle(style);
    }
  }, []);

  useEffect(() => {
    if (style) {
      document.body.setAttribute(ATTRIBUTES.PACKAGE, "active");
    } else {
      document.body.setAttribute(ATTRIBUTES.PACKAGE, "disabled");
    }
  }, [style]);

  return {
    close,
    open,
    style,
  };
};

export default usePopover;
