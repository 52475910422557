import API from "./_api";
import { API_ROUTES } from "../constants";

const PlaylistAPI = class extends API {
  get({ page }) {
    return this.request(API_ROUTES.PLAYLIST.GET, {
      method: "POST",
      body: { page },
    });
  }

  add({ id }) {
    return this.request(API_ROUTES.PLAYLIST.ADD, {
      body: { id },
      method: "POST",
    });
  }

  addSearchFilterVideo(payload) {
    return this.request(API_ROUTES.PLAYLIST.ADD_SEARCH_FILTER, {
      body: payload,
      method: "POST",
    });
  }

  addToPlaylist(payload) {
    return this.request(API_ROUTES.PLAYLIST.ADD_TO_PLAYLIST, {
      body: payload,
      method: "POST",
    });
  }

  delete({ id }) {
    return this.request(API_ROUTES.PLAYLIST.DELETE, {
      body: { id },
      method: "POST",
    });
  }

  clear() {
    return this.request(API_ROUTES.PLAYLIST.CLEAR, {
      method: "POST",
    });
  }
};

export default PlaylistAPI;
