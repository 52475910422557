import React, { useContext, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import cs from "classnames";

import Button from "../../atoms/Button";
import Loader from "../../atoms/Loader";
import Logotype, { TYPES as LOGOTYPE_TYPES } from "../../atoms/Logotype";
import Select from "../../atoms/Select";
import PlainInput from "../../atoms/PlainInput";
import { StoreContext } from "../../../contexts";
import { ACTIONS } from "../../../store";
import Offers from "./components/Offers";
import useDonation from "./useDonation";
import classes from "./PartnersDonation.module.scss";
import Checkbox from "../../atoms/Checkbox";
import PasswordInput from "../../atoms/PasswordInput";
import { APPLICATION_ROUTES, COOKIES, PROJECTS } from "../../../constants";
import { useHistory } from "react-router-dom";
import { scrollToTop } from "../../../utilities/helpers";
import Cookies from "../../../utilities/cookies";
import { Link } from "react-router-dom";
import { useQuery } from "../../../hooks";
import Options from "./components/Options";

const PartnersDonation = observer(() => {
  const {
    apartment,
    city,
    country,
    customAmount,
    email,
    lastName,
    firstName,
    password,
    createAccount,
    anonymous,
    recoverFees,
    hasDelivery,
    isSelectedCustomAmount,
    isLoading,
    isNotValid,
    offer,
    showPersonal,
    state,
    street,
    zip,
    options,
    onSubmit,
  } = useDonation();

  const history = useHistory();

  const { authorization, cart, checkout } = useContext(StoreContext);
  const { t } = useTranslation();

  const query = useQuery();
  const type = query.get("type") || PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID;
  const isAutoSignin = Cookies.get({ name: COOKIES.AUTO_SIGNIN });

  const onSignOut = useCallback(() => {
    Cookies.delete({ name: COOKIES.AUTO_SIGNIN });
    authorization.signOut();
  });

  const onNavigateToSignIn = useCallback(() => {
    history.push(
      APPLICATION_ROUTES.SIGN_IN +
        "?redirect=" +
        APPLICATION_ROUTES.PARTNERS_DONATION
    );
    scrollToTop();
  });

  // if (
  //   authorization.isAuthenticated &&
  //   authorization[`error${ACTIONS.AUTHORIZATION.AUTHENTICATE_PASSWORD}`] != ""
  // ) {
  //   password.errorOfOriginal = t("layout.password_confirmation_failed");
  // }

  if (isAutoSignin == 1) {
    checkout.setValue("reAuthenticated", true);
  }

  return (
    <div className="bg-gold-gradient--horizontal c-blue-dark">
      <div className="wrapper-s wrapper-vertical-s-a minh-100vh">
        <header
          className={cs(classes.header, "mr-auto ml-auto")}
          style={{ maxWidth: "464px" }}
        >
          <nav className="d-flex jc-center ai-center">
            <Logotype type={LOGOTYPE_TYPES.PARTNERS} />
          </nav>
          <div className={classes.message}>
            <h2 className="heading-l tt-uppercase c-orange">
              {t("pages.partners.donation.titles.1")}
            </h2>
            <p className="text-l">
              <Trans
                components={{ a: <br /> }}
                i18nKey="pages.partners.donation.texts.1"
                t={t}
              />
            </p>
          </div>
        </header>
        <main className="mt-09">
          <form className="bxsh-soft br-06 pt-08 pb-08 pr-03 pl-03 bgc-white">
            <header className={classes.textHeader}>
              {authorization[`isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`] && (
                <Loader />
              )}
              {!authorization.isAuthenticated &&
                !authorization[
                  `isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`
                ] && (
                  <>
                    <span className={classes.loggedInText}>
                      <span className={classes.createAnAccount}>
                        {t("partners.create_an_account_or")}&nbsp;
                      </span>
                      or{" "}
                      <a
                        className={classes.logoutLinkText}
                        size="xs"
                        onClick={onNavigateToSignIn}
                      >
                        {t("layout.sign_in")}
                      </a>
                    </span>
                  </>
                )}
              {authorization.isAuthenticated && (
                <p>
                  <span className={classes.loggedInText}>
                    {t("layout.logged_in_as")}
                    <span className={classes.familyText}>
                      <strong>{authorization.lastName} </strong>{" "}
                      {t("layout.family").toLowerCase()}
                    </span>
                  </span>
                  <a
                    className={classes.logoutLink}
                    onClick={onSignOut}
                    href="#"
                  >
                    {t("layout.log_out")}
                  </a>
                  {/* <Button color="ghost" size="xs" onClick={onSignOut}>
                  {t("layout.log_out")}
                </Button> */}
                </p>
              )}
            </header>
            <div
              className={cs(classes.group, {
                [classes.disabled]: isSelectedCustomAmount,
              })}
              hidden
            >
              <div className="d-flex jc-end mb-02">
                {authorization[
                  `isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`
                ] && <Loader />}
                {!authorization.isAuthenticated &&
                  !authorization[
                    `isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`
                  ] && (
                    <Button
                      color="ghost"
                      size="xs"
                      onClick={onNavigateToSignIn}
                    >
                      {t("layout.sign_in")}
                    </Button>
                  )}
                {authorization.isAuthenticated && (
                  <p>
                    <span className={classes.loggedInText}>
                      {t("layout.logged_in_as")}
                      <strong>{authorization.lastName} </strong>{" "}
                      {t("layout.family").toLowerCase()}
                    </span>
                    <a
                      className={classes.logoutLink}
                      onClick={onSignOut}
                      href="#"
                    >
                      {t("layout.log_out")}
                    </a>
                  </p>
                )}
              </div>
              <h3 className="heading-m fw-400 ta-center">
                {t("pages.partners.donation.titles.2")}
              </h3>
              <div className={cs(classes.list, "mt-04")}>
                <Offers
                  items={offer.options}
                  unselected={isSelectedCustomAmount}
                  value={offer.value}
                  onChange={offer.onChange}
                />
              </div>
              {hasDelivery && (
                <div className="mt-08">
                  <h3 className="heading-m c-blue">
                    {t("pages.partners.donation.titles.4")}{" "}
                    <span className="heading-s fw-400 c-red">
                      <Trans
                        components={{ a: <b /> }}
                        i18nKey="pages.partners.donation.texts.7"
                      />
                    </span>
                  </h3>
                  <ul className={cs(classes.list, classes.address, "mt-04")}>
                    <li className={cs(classes.item, classes.street)}>
                      <PlainInput
                        error={street.error}
                        id="partners-order-form-street"
                        label={street.label}
                        stretched
                        value={street.value}
                        onChange={street.onChange}
                      />
                    </li>
                    <li className={cs(classes.item, classes.apartment)}>
                      <PlainInput
                        error={apartment.error}
                        id="partners-order-form-apartment"
                        label={apartment.label}
                        stretched
                        value={apartment.value}
                        onChange={apartment.onChange}
                      />
                    </li>
                    <li className={cs(classes.item, classes.city)}>
                      <PlainInput
                        error={city.error}
                        id="partners-order-form-city"
                        label={city.label}
                        stretched
                        value={city.value}
                        onChange={city.onChange}
                      />
                    </li>
                    <li className={cs(classes.item, classes.state)}>
                      <PlainInput
                        error={state.error}
                        id="partners-order-form-state"
                        label={state.label}
                        stretched
                        value={state.value}
                        onChange={state.onChange}
                      />
                    </li>
                    <li className={cs(classes.item, classes.zip)}>
                      <PlainInput
                        error={zip.error}
                        id="partners-order-form-zip"
                        label={zip.label}
                        stretched
                        value={zip.value}
                        onChange={zip.onChange}
                      />
                    </li>
                    <li className={cs(classes.item, classes.country)}>
                      <Select
                        label={country.label}
                        options={country.options}
                        stretched
                        value={country.value}
                        onChange={country.onChange}
                      />
                    </li>
                  </ul>
                  <div className="text-l mt-04 fw-700 ta-center">
                    {t("pages.partners.donation.texts.6", {
                      value: 60,
                    })}
                  </div>
                </div>
              )}
            </div>

            <div className={classes.group}>
              <h3 className="heading-m fw-400 ta-center mb-03">
                {t("pages.partners.donation.titles.2")}
              </h3>

              <div className="mr-auto ml-auto" style={{ maxWidth: "320px" }}>
                <PlainInput
                  error={customAmount.error}
                  id="donation-amount"
                  label={customAmount.label}
                  value={customAmount.value}
                  onChange={customAmount.onChange}
                />
              </div>

              <Options
                items={options.options}
                value={options.value}
                onChange={options.onChange}
              />

              <div className={cs(classes.checkboxContainer)}>
                <Checkbox {...recoverFees}>{recoverFees.label}</Checkbox>
              </div>
            </div>

            <hr className={cs(classes.boundary, "mt-08 mb-08")} />
            {authorization[`isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`] ? (
              <div className="mb-06">
                <Loader />
              </div>
            ) : (
              showPersonal && (
                <ul className={cs(classes.personal, classes.list)}>
                  <li className={cs(classes.item, classes.firstName)}>
                    <PlainInput
                      error={firstName.error}
                      id="donation-first-name"
                      label={firstName.label}
                      value={firstName.value}
                      stretched
                      onChange={firstName.onChange}
                    />
                  </li>
                  <li className={cs(classes.item, classes.lastName)}>
                    <PlainInput
                      error={lastName.error}
                      id="donation-last-name"
                      label={lastName.label}
                      value={lastName.value}
                      onChange={lastName.onChange}
                      stretched
                    />
                  </li>
                  <li className={cs(classes.item, classes.email)}>
                    <PlainInput
                      error={email.errorOfOriginal}
                      id="donation-email"
                      label={email.labelOfOriginal}
                      stretched
                      value={email.valueOfOriginal}
                      onChange={email.onChangeOriginal}
                    />
                  </li>

                  <li className={cs(classes.item, classes.createAccount)}>
                    <Checkbox {...createAccount}>Create Account?</Checkbox>
                  </li>
                  {createAccount.checked ? (
                    <>
                      <li
                        className={cs(classes.item, classes.password, "mb-05")}
                      >
                        <PasswordInput
                          error={password.errorOfOriginal}
                          id="sign-up-form-password"
                          label={password.labelOfOriginal}
                          value={password.valueOfOriginal}
                          onChange={password.onChangeOriginal}
                          stretched
                        />
                      </li>
                      <li
                        className={cs(
                          classes.item,
                          classes.confirmPassword,
                          "mb-05"
                        )}
                      >
                        <PasswordInput
                          error={password.errorOfConfirm}
                          id="sign-up-form-password-repeat"
                          label={password.labelOfConfirm}
                          value={password.valueOfConfirm}
                          onChange={password.onChangeConfirm}
                          stretched
                        />
                      </li>
                    </>
                  ) : null}
                </ul>
              )
            )}
            {cart[`error${ACTIONS.CART.ADD}`] &&
              cart[`error${ACTIONS.CART.ADD}`] == 409 &&
              !authorization.isAuthenticated && (
                <span className={classes.error}>
                  {cart[`setError`](`error${ACTIONS.CART.ADD}`, "")}
                  There is already an account linked with this email, please{" "}
                  <Link
                    to={`${
                      APPLICATION_ROUTES.SIGN_IN
                    }?redirect=${encodeURIComponent(
                      `${APPLICATION_ROUTES.PARTNERS_ORDER}?type=${type}&login=true`
                    )}`}
                  >
                    log in
                  </Link>
                </span>
              )}
            {authorization.isAuthenticated && isAutoSignin != 1 ? (
              <ul className={cs(classes.list)}>
                <li
                  className={cs(
                    classes.item,
                    classes.password,
                    "mb-05",
                    "m-auto"
                  )}
                >
                  <PasswordInput
                    error={password.errorOfOriginal}
                    id="sign-up-form-password"
                    label={password.labelOfOriginal}
                    value={password.valueOfOriginal}
                    onChange={password.onChangeOriginal}
                  />
                  <small>{t("layout.purcahse_password_confirmatin")}</small>
                </li>
                <li
                  className={cs(
                    classes.item,
                    classes.anonymous,
                    "m-auto",
                    "mb-05"
                  )}
                >
                  <Checkbox {...anonymous}>{anonymous.label}</Checkbox>
                </li>
              </ul>
            ) : (
              <ul className={cs(classes.list)}>
                <li className={cs(classes.item, classes.anonymous)}>
                  <Checkbox {...anonymous}>{anonymous.label}</Checkbox>
                </li>
              </ul>
            )}

            <div
              className="d-flex df-column jc-center ai-center"
              style={{ minHeight: "52px" }}
            >
              {isLoading ? (
                <Loader />
              ) : (
                <Button disabled={isNotValid} type="submit" onClick={onSubmit}>
                  {t("pages.partners.donation.labels.2")}
                </Button>
              )}
            </div>
          </form>
        </main>
      </div>
    </div>
  );
});

export default React.memo(PartnersDonation);
