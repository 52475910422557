import { makeAutoObservable } from "mobx";

import { AdAPI } from "../../api";
import { ACTIONS } from "./utilities";

const adApi = new AdAPI();

const Ad = class {
    constructor() {
        const actions = Object.values(ACTIONS);

        for (let i = 0; i < actions.length; i++) {
            const ACTION = actions[i];

            this[`isLoading${ACTION}`] = false;
            this[`error${ACTION}`] = "";
        }

        this.ad = null;

        makeAutoObservable(this);
    }

    get hasAd() {
        return !!this.ad;
    }

    setIsLoading(name, value) {
        this[`isLoading${name}`] = value;
    }

    setError(name, value) {
        this[`error${name}`] = value;
    }

    setValue(name, value) {
        this[name] = value;
    }

    getAd() {
        this.setIsLoading(ACTIONS.GET_AD, true);

        adApi.getAd()
            .then((response) => {
                if (response.status === 200) return response.json();
                throw new Error(response.status);
            })
            .then((data) => {
                console.log(data, "addddddd")
                this.setValue("ad", data);
                this.setIsLoading(ACTIONS.GET_AD, false);
            })
            .catch((error) => {
                this.setError(ACTIONS.GET_AD, error.message);
                this.setIsLoading(ACTIONS.GET_AD, false);
            });
    }

}

export default Ad;
