/* eslint-disable */
import React, { useCallback, useContext, useEffect } from "react";
import cs from "classnames";

import Slider from "../Slider";

import Item from "./components/Item";
import { items } from "./utilities";
import classes from "./BirthdayPackages.module.scss";
import { StoreContext } from "../../../contexts";
import { observer } from "mobx-react-lite";
import Loader from "../../atoms/Loader";
import { ACTIONS } from "../../../store/authorization/utilities";
import { APPLICATION_ROUTES, COOKIES, PROJECTS } from "../../../constants";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CartIcon from "../../atoms/CartIcon/CartIcon";
import { scrollToTop } from "../../../utilities/helpers";
import { toJS } from "mobx";
import { isMobile, MobileView } from "react-device-detect";
import Cookies from "../../../utilities/cookies";

const BirthdayPackages = observer(() => {
  const { authorization, cart } = useContext(StoreContext);
  const { t } = useTranslation();
  let birthdayItems = items;

  const history = useHistory();

  useEffect(() => {
    cart.cartType = "birthday";
    cart.get();
  }, []);

  const cartID = Cookies.get({ name: COOKIES.CART_ID });

  const navigateToCart = useCallback(() => {
    history.push(APPLICATION_ROUTES.MY_MAOR_CHECKOUT);
    scrollToTop();
  }, []);

  if (
    authorization[`isLoading${ACTIONS.SIGN_IN}`] ||
    authorization[`isLoading${ACTIONS.RESIGN_IN}`]
  ) {
    return <Loader />;
  }

  const redirectScrollTop = (location) => {
    history.push(location);
    scrollToTop();
  };

  let leftCredit = authorization.user ? authorization.user.maor_credit : 0;

  cart.items.forEach((value, index) => {
    leftCredit -= value.price;
  });

  if (leftCredit < 0) {
    leftCredit = 0;
  }

  birthdayItems = birthdayItems.map((item) => {
    if (leftCredit - item.cost >= 0) {
      item.canSelect = true;
    } else {
      item.canSelect = false;
    }
    return item;
  });

  // if (authorization.membership.ID == PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID) {
  //   birthdayItems = birthdayItems.filter((item) => {
  //     if (item.ID == PROJECTS.MY_MAOR.PACKAGES.SINGLE_LAYER.ID) {
  //       item.canSelect = true;
  //     } else {
  //       item.canSelect = false;
  //     }
  //     return item.ID == PROJECTS.MY_MAOR.PACKAGES.SINGLE_LAYER.ID;
  //   });
  // }

  // if (authorization.membership.ID == PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID) {
  //   birthdayItems = birthdayItems.filter((item) => {
  //     if (
  //       item.ID == PROJECTS.MY_MAOR.PACKAGES.DOUBLE_LAYER.ID || item.ID == PROJECTS.MY_MAOR.PACKAGES.TRIPLE_DECKER.ID
  //     ) {
  //       item.canSelect = true;
  //     } else {
  //       item.canSelect = false;
  //     }
  //     return (
  //       item.ID == PROJECTS.MY_MAOR.PACKAGES.DOUBLE_LAYER.ID || item.ID == PROJECTS.MY_MAOR.PACKAGES.TRIPLE_DECKER.ID
  //     );
  //   });
  // }

  let sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="prpl-01 text-l-a c-blue-dark mt-05 ta-center">
        {/* {authorization.membership && ( */}
        <>
          {authorization.isAuthenticated ? (
            <p className={cs(classes.green)}>
              {t("pages.birthdays.main.sections.packages.texts.13")}{" "}
              <span className={cs("fw-700")}>
                {t("pages.birthdays.main.sections.packages.texts.11", {
                  value: leftCredit,
                })}
              </span>{" "}
              {t("pages.birthdays.main.sections.packages.texts.12")},{" "}
              {t("pages.birthdays.main.sections.packages.texts.9")}!
            </p>
          ) : (
            <p className={cs(classes.green, "fw-700")}>
              <Link
                onClick={() => {
                  redirectScrollTop(APPLICATION_ROUTES.PARTNERS_MAIN);
                }}
              >
                Become a partner
              </Link>{" "}
              or{" "}
              <Link
                onClick={() => {
                  redirectScrollTop(APPLICATION_ROUTES.SIGN_IN);
                }}
              >
                log in
              </Link>{" "}
              to use your Maor Credit for free birthdays
            </p>
          )}
          {/* <p style={{ textShadow: "0 0 #ff8a34" }} className="mt-02 c-orange">
              <strong>{t("pages.birthdays.main.sections.packages.texts.8")}:</strong> {t("pages.birthdays.main.sections.packages.texts.10")}
            </p> */}
        </>
        {/* )} */}
      </div>
      <div
        className={cs(isMobile ? "mt-03" : "mt-13", "text-l-a c-blue-dark ")}
      >
        {cart.items.length > 0 && cartID && (
          <div className={classes.fixed}>
            <CartIcon
              title={t("pages.checkout.texts.18")}
              itemsCount={cart.items.length}
              onClick={navigateToCart}
            />
          </div>
        )}
        <div
          className={cs(
            classes.slider,
            isMobile ? classes.mobileBirthdayPackages : ""
          )}
        >
          {birthdayItems.map((item) => (
            <div className="pl-08 pr-08" key={item.title.key}>
              <div
                className={cs(
                  isMobile
                    ? classes.packageWrap
                    : "bd-gradient-lightnavy--horizontal",
                  "d-flex mt-08 mr-auto ml-auto br-06 "
                )}
                style={{ maxWidth: "344px", width: "100%" }}
              >
                {isMobile && (
                  <MobileView>
                    <span className={classes.gradientBorder}></span>
                  </MobileView>
                )}

                <Item {...item} />
              </div>
            </div>
          ))}
        </div>
        <div className={cs(classes.showcase, "wrapper-l")}>
          <div
            className={cs(
              classes.list,
              "mr-auto ml-auto br-06",
              classes.birthdayGradientBackgroud
            )}
          >
            {birthdayItems.map((item) => (
              <Item key={item.title.key} {...item} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
});

export default BirthdayPackages;
