import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import classes from "./Offers.module.scss";

const Offers = ({ items, unselected, value, onChange }) => {
  return (
    <ul className={classes.container}>
      {items.map((item) => (
        <li className={classes.item} key={item.id}>
          <input
            checked={item.value === value && !unselected}
            className={classes.input}
            id={item.id}
            type="radio"
            value={item.value}
            onChange={onChange}
          />
          <label
            className={cs(classes.label, "d-block br-04 pt-04 pb-03 pr-02 pl-02 ta-center curs-pointer bgc-white")}
            htmlFor={item.id}
          >
            <div className="heading-m fw-700">${item.cost}</div>
            <div className="text-m mt-01 whs-nowrap c-blue-dark">{item.label}</div>
          </label>
        </li>
      ))}
    </ul>
  );
};

Offers.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ),
  unselected: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

Offers.defaultProps = {
  unselected: false,
  items: [],
};

export default React.memo(Offers);
