import { ReactComponent as PartnersBasicTierIllustration } from "../assets/svg/level-basic.svg";
import { ReactComponent as PartnersSilverTierIllustration } from "../assets/svg/level-silver.svg";
import { ReactComponent as PartnersGoldTierIllustration } from "../assets/svg/level-gold.svg";
import { ReactComponent as PartnersPremiumTierIllustration } from "../assets/svg/level-platinum.svg";
import { ReactComponent as PartnersPartnerTierIllustration } from "../assets/svg/partner-icon.svg";

import { ReactComponent as SinglerLayerIllustration } from "../assets/svg/single-layer.svg";
import { ReactComponent as DoubleLayerIllustration } from "../assets/svg/double-layer.svg";
import { ReactComponent as TripleDeckerIllustration } from "../assets/svg/triple-decker.svg";
import { ReactComponent as SevenLayerExplosionIllustration } from "../assets/svg/7-layer-explosion.svg";

import { default as SinglerLayerIcon } from "../assets/svg/single-layer.svg";
import { default as DoubleLayerIcon } from "../assets/svg/double-layer.svg";
import { default as TripleDeckerIcon } from "../assets/svg/triple-decker.svg";
import { default as SevenLayerExplosionIcon } from "../assets/svg/7-layer-explosion.svg";

import { default as PartnersBasicTierIcon } from "../assets/svg/level-basic.svg";
import { default as PartnersPartnerTierIcon } from "../assets/svg/partner-icon.svg";
import { default as PartnersSilverTierIcon } from "../assets/svg/level-silver.svg";
import { default as PartnersGoldTierIcon } from "../assets/svg/level-gold.svg";
import { default as PartnersPremiumTierIcon } from "../assets/svg/level-platinum.svg";

const PROJECTS = {
  DONATION: {
    TYPE: {
      ID: "donation",
      MONTHLY: "donation_monthly",
      YEARLY: "donation_yearly",
      PLEDGE_DONATION: "donation_monthly_pledge",
    },
    ILLUSTRATION_NAME: "donation",
  },
  PLEDGE: {
    TYPE: {
      ID: "pledge",
    },
  },
  TITLES: {
    MY_MAOR: "MyMaor",
    DAILY_REBBE_VIDEO: "Daily Rebbe Video",
    ILLUMINATE: "Illuminate",
  },
  NAMES: {
    MAOR_KIDS: "MAOR_KIDS",
  },
  PACKAGES: {
    MAOR_KIDS: {
      SINGLE_LAYER: "SINGLE_LAYER",
      DOUBLE_LAYER: "DOUBLE_LAYER",
      TRIPLE_DECKER: "TRIPLE_DECKER",
      SEVEN_LAYER_EXPLOSION: "SEVEN_LAYER_EXPLOSION",
    },
  },
  DAILY_REBBE_VIDEO: {
    ID: "daily-rebbe",
  },
  ILLUMINATE: {
    ID: "illuminate",
  },
  MY_MAOR: {
    ID: "my-maor",
    NAME: "my-maor",
    PACKAGES: {
      SINGLE_LAYER: {
        COST: "36",
        DELIVERY: false,
        ID: "single_layer",
        NAME: "SINGLE_LAYER",
        Illustration: SinglerLayerIllustration,
        Icon: SinglerLayerIcon,
        TITLE_TRANSLATION_KEY:
          "pages.birthdays.main.sections.packages.subtitles.1",
      },
      DOUBLE_LAYER: {
        COST: "58",
        DELIVERY: true,
        ID: "double_layer",
        NAME: "DOUBLE_LAYER",
        Illustration: DoubleLayerIllustration,
        Icon: DoubleLayerIcon,
        TITLE_TRANSLATION_KEY:
          "pages.birthdays.main.sections.packages.subtitles.2",
      },
      TRIPLE_DECKER: {
        COST: "180",
        DELIVERY: true,
        ID: "triple_layer",
        NAME: "TRIPLE_DECKER",
        Illustration: TripleDeckerIllustration,
        Icon: TripleDeckerIcon,
        TITLE_TRANSLATION_KEY:
          "pages.birthdays.main.sections.packages.subtitles.3",
      },
      SEVEN_LAYER_EXPLOSION: {
        COST: "360",
        DELIVERY: true,
        ID: "seven_layer_explosion",
        NAME: "SEVEN_LAYER_EXPLOSION",
        Illustration: SevenLayerExplosionIllustration,
        Icon: SevenLayerExplosionIcon,
        TITLE_TRANSLATION_KEY:
          "pages.birthdays.main.sections.packages.subtitles.4",
      },
    },
  },
  PARTNERS: {
    DONATIONS: {
      BASIC: {
        COST: 54,
      },
      SILVER: {
        COST: 128,
      },
      GOLD: {
        COST: 300,
      },
      PLATINUM: {
        COST: 600,
      },
    },
    ID: "partner",
    NAME: "partner",
    PACKAGES: {
      BASIC_TIER: {
        COST: 8,
        COST_MONTH: 8,
        COST_YEAR: 72,

        DISCOUNT_MONTH: 0.64,
        DISCOUNT_YEAR: 7.2,

        CREDITS: 0,
        CREDITS_MONTH: 0,
        CREDITS_TOTAL: 0,
        DELIVERY: false,
        ID: "m_basic",
        ILLUSTRATION_NAME: "partners-membership-basic",
        Illustration: PartnersBasicTierIllustration,
        Icon: PartnersBasicTierIcon,
        TITLE_TRANSLATION_KEY: "partners.basic",
        NAME_TRANSLATION_KEY: "partners.basic",
        memberClass: "bronzeMember",
      },
      PARTNER_TIER: {
        COST: 15,
        COST_MONTH: 15,
        COST_YEAR: 180,

        DISCOUNT_MONTH: 0.64,
        DISCOUNT_YEAR: 7.2,

        CREDITS: 0,
        CREDITS_MONTH: 0,
        CREDITS_TOTAL: 0,
        DELIVERY: true,
        ID: "m_partner",
        ILLUSTRATION_NAME: "partners-membership-partner",
        Illustration: PartnersPartnerTierIllustration,
        Icon: PartnersPartnerTierIcon,
        TITLE_TRANSLATION_KEY: "partners.partner",
        NAME_TRANSLATION_KEY: "partners.partner",
        memberClass: "partner",
      },
      SILVER_TIER: {
        COST: 18,
        COST_MONTH: 18,
        COST_YEAR: 180,

        DISCOUNT_MONTH: 1.44,
        DISCOUNT_YEAR: 18,

        CREDITS: 144,
        CREDITS_MONTH: 12,
        CREDITS_TOTAL: 144,
        DELIVERY: false,
        ID: "m_silver",
        ILLUSTRATION_NAME: "partners-membership-silver",
        Illustration: PartnersSilverTierIllustration,
        Icon: PartnersSilverTierIcon,
        TITLE_TRANSLATION_KEY: "partners.silver_partner",
        NAME_TRANSLATION_KEY: "partners.silver",
        BENEFITS_TRANSLATION_KEY: "partners.silver_benefits",
        memberClass: "silverMember",
      },
      SILVER_CARD_TIER: {
        COST: 21.67,
        COST_MONTH: 21.67,
        COST_YEAR: 260,

        DISCOUNT_MONTH: 1.44,
        DISCOUNT_YEAR: 18,

        CREDITS: 144,
        CREDITS_MONTH: 12,
        CREDITS_TOTAL: 144,
        DELIVERY: true,
        ID: "m_silver-card",
        ILLUSTRATION_NAME: "partners-membership-silver",
        Illustration: PartnersSilverTierIllustration,
        Icon: PartnersSilverTierIcon,
        TITLE_TRANSLATION_KEY: "partners.silver_card_partner",
        NAME_TRANSLATION_KEY: "partners.silver_card",
        BENEFITS_TRANSLATION_KEY: "partners.silver_card_benefits",
        memberClass: "silverMember",
      },
      GOLD_TIER: {
        COST: 54,
        COST_MONTH: 54,
        COST_YEAR: 540,

        DISCOUNT_MONTH: 4.32,
        DISCOUNT_YEAR: 54,

        CREDITS: 576,
        CREDITS_MONTH: 30,
        CREDITS_TOTAL: 576,
        DELIVERY: true,
        ID: "m_gold",
        ILLUSTRATION_NAME: "partners-membership-gold",
        Illustration: PartnersGoldTierIllustration,
        Icon: PartnersGoldTierIcon,
        TITLE_TRANSLATION_KEY: "partners.gold_partner",
        NAME_TRANSLATION_KEY: "partners.gold",
        BENEFITS_TRANSLATION_KEY: "partners.gold_benefits",
        memberClass: "goldMember",
      },
      PLATINUM_TIER: {
        COST: 54,
        COST_MONTH: 54,
        COST_YEAR: 648,
        CREDITS: 540,
        CREDITS_MONTH: 45,
        CREDITS_TOTAL: 540,
        DELIVERY: true,
        ID: "m_platinum",
        ILLUSTRATION_NAME: "partners-membership-platinum",
        Illustration: PartnersPremiumTierIllustration,
        Icon: PartnersPremiumTierIcon,
        TITLE_TRANSLATION_KEY: "partners.platinum_partner",
        NAME_TRANSLATION_KEY: "partners.platinum",
      },
    },
  },
};

export default PROJECTS;
