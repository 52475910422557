import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StoreContext } from "../../../contexts";

import { useInput, useRadio } from "../../../hooks";
import { NAMES } from "../../../utilities/validation";
import { PAYMENT_METHODS } from "./utilities";

const useForm = () => {
  const { t } = useTranslation();
  const { cart, checkout } = useContext(StoreContext);
  const LABEL_AMOUNT_OF_CREDIT = t("forms.labels.amount_of_credit");
  const LABEL_CARD = t("checkout.card");
  const LABEL_CARD_NUMBER = t("forms.labels.card_number");
  const LABEL_CVV = t("forms.labels.cvv");
  const LABEL_EMAIL = t("forms.labels.email");
  const LABEL_EXPIRE_DATE = t("forms.labels.expire_date");
  const LABEL_NAME_ON_CARD = t("forms.labels.name_on_card");
  const LABEL_PASSWORD = t("forms.labels.password");
  const LABEL_PAYPAL = t("checkout.paypal");
  const MESSAGE_OF_EXPIRATION_DATE = t(
    "forms.messages.the_field_value_does_not_match_the_credit_card_expiration_date_format"
  );
  const MESSAGE_OF_EMAIL_FORMAT = t("forms.messages.this_email_is_not_valid");
  const MESSAGE_OF_REQUIRED = t("forms.messages.this_field_is_required");

  const [cardIndex, setCardIndex] = useState(0);
  const [creditsApplied, setCreditsApplied] = useState(0);
  const [applyingCredits, setApplyingCredits] = useState(0);
  const [creditValue, setCreditValue] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);

  const [creditsError, setCreditsError] = useState("");

  const cardName = useInput({
    label: LABEL_NAME_ON_CARD,
    validators: [
      { name: NAMES.REQUIRED, options: { message: MESSAGE_OF_REQUIRED } },
    ],
  });
  const cardNumber = useInput({
    label: LABEL_CARD_NUMBER,
    validators: [
      { name: NAMES.REQUIRED, options: { message: MESSAGE_OF_REQUIRED } },
    ],
  });
  const cardExpirationDate = useInput({
    label: LABEL_EXPIRE_DATE,
    validators: [
      { name: NAMES.REQUIRED, options: { message: MESSAGE_OF_REQUIRED } },
      {
        name: NAMES.CARD_EXPIRATION_DATE,
        options: { message: MESSAGE_OF_EXPIRATION_DATE },
      },
    ],
  });
  const cardCVV = useInput({
    label: LABEL_CVV,
    validators: [
      { name: NAMES.REQUIRED, options: { message: MESSAGE_OF_REQUIRED } },
    ],
  });
  const confirmationEmail = useInput({
    label: LABEL_EMAIL,
    validators: [
      { name: NAMES.EMAIL, options: { message: MESSAGE_OF_EMAIL_FORMAT } },
    ],
  });
  const credits = useInput({
    label: LABEL_AMOUNT_OF_CREDIT,
  });
  const paymentMethod = useRadio({
    value: PAYMENT_METHODS.CARD,
    options: [
      {
        id: "maor-kids-checkout-payment-method-card",
        label: LABEL_CARD,
        value: PAYMENT_METHODS.CARD,
      },
      {
        id: "maor-kids-checkout-payment-method-paypal",
        label: LABEL_PAYPAL,
        value: PAYMENT_METHODS.PAYPAL,
      },
    ],
  });
  const paypalEmail = useInput({
    label: LABEL_EMAIL,
    validators: [
      { name: NAMES.REQUIRED, options: { message: MESSAGE_OF_REQUIRED } },
      { name: NAMES.EMAIL, options: { message: MESSAGE_OF_EMAIL_FORMAT } },
    ],
  });
  const paypalPassword = useInput({
    label: LABEL_PASSWORD,
    validators: [
      { name: NAMES.REQUIRED, options: { message: MESSAGE_OF_REQUIRED } },
    ],
  });

  const useCredits = useInput({
    label: "MaorCredit Available ",
    value: checkout.transaction.payment.available_credit,
  });
  let cartTotalValue = 0;
  useEffect(() => {
    if (cart.isNotEmpty) {
      let total = 0;
      cart.items.map((item) => {
        total += item.price;
      });
      setCartTotal(total);
      cartTotalValue = total;
      console.log("Cart total set");
      if (useCredits.value) {
        console.log("Inside timeout", cartTotal, total, cartTotalValue);
        if (checkout && checkout.transaction) {
          applyCredits();
        }
      }
    }
  }, [cart.items.length]);

  const onSetCardIndex = (value) => {
    setCardIndex(value);
  };

  useEffect(() => {
    setCreditsApplied();
  }, [useCredits.value]);

  const afterApplyCredits = () => {
    setApplyingCredits(0);
    setCreditsApplied(1);
  };

  const applyCredits = () => {
    let creditsValue1 = useCredits.value;
    if (cartTotalValue < creditsValue1 && cartTotalValue > 0) {
      useCredits.onChange({ target: { value: cartTotalValue } });
      creditsValue1 = cartTotalValue;
      setCreditsApplied();
    }
    setApplyingCredits(1);
    setCreditsApplied(0);
    setCreditsError("");

    const totalCredits = checkout.transaction.payment.available_credit;

    if (totalCredits < creditsValue1) {
      setCreditsError(
        "You have only $" + totalCredits + " MaorCredit available"
      );
      setApplyingCredits(0);
    } else {
      checkout.updateTransaction({
        credits: creditsValue1,
        onSuccess: afterApplyCredits,
      });
      setCreditValue(creditsValue1);
    }
  };

  return {
    cardIndex: cardIndex,
    cardCVV: cardCVV,
    cardExpirationDate: cardExpirationDate,
    cardName: cardName,
    cardNumber: cardNumber,
    credits: credits,
    confirmationEmail: confirmationEmail,
    paymentMethod: paymentMethod,
    paypalEmail: paypalEmail,
    paypalPassword: paypalPassword,
    useCredits: useCredits,
    creditsApplied: creditsApplied,
    applyingCredits: applyingCredits,
    creditsError: creditsError,
    creditValue: creditValue,
    onSetCardIndex: onSetCardIndex,
    applyCredits: applyCredits,
  };
};

export default useForm;
