/* eslint-disable */

import React, { useState } from "react";
import cs from "classnames";
import classes from "./AudioList.module.scss";
import { Loader } from "react-bootstrap-typeahead";
import Preview from "./components/Preview";
import { useTranslation } from "react-i18next";
import Logotype from "../../atoms/Logotype";
import { Element } from "react-scroll";
import VideoSlider from "../../templates/VideoSlider/VideoSlider";
import AudioPlayer from "../../templates/AudioPlayer";

const AudioList = ({ hasItems, items, title, logotype, isLoading, error }) => {
  const [showAllList, setShowAllList] = useState(false);
  const { t } = useTranslation();
  const [currentAudio, setCurrentAudio] = useState({});

  const audioHandler = {
    currentAudio: currentAudio,
    setCurrentAudio: setCurrentAudio,
  };

  const toggleShowList = () => {
    setShowAllList(!showAllList);
  };

  console.log("Audio Handler #1", audioHandler);
  console.log("Audio Handler #1", Boolean(audioHandler.currentAudio.musicSrc));

  return (
    <div className={cs(classes.container, classes.darkGreen , "wrapper-l")}>
      <Element name="audio" />
      <div className={cs(classes.main, classes.blue)}>
        <div className={classes.header}>
          <div className={classes.logoWrap}>
            <div className={cs(classes.logotype)}>
              <div className={cs(classes.logotypeWrapper, "d-none")}>
                <Logotype type={logotype} size="m" url="#" />
              </div>
              <div>
                <p className={classes.projectTitle}>{title}</p>
              </div>
            </div>

            <div className={cs(classes.sideHeader)}>
              <div>
                <p onClick={toggleShowList} className={cs(classes.viewAllText)}>
                  {showAllList ? "Hide" : "View All"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {hasItems ? (
        <>
          <ul
            className={cs(
              !showAllList ? classes.showASlider : classes.hideSlider
            )}
          >
            <VideoSlider>
              {items.map((item, index) => (
                <li
                  data-find="1"
                  key={item.id + "_" + index}
                  className={cs(classes.item, classes.itemPadding)}
                >
                  <Preview
                    showAllList={showAllList}
                    item={item}
                    index={items.length - index - 1}
                    {...item}
                    audioHandler={audioHandler}
                  />
                </li>
              ))}
              <li className={cs(classes.itemPadding)}>
                <div
                  onClick={toggleShowList}
                  className={cs(classes.viewAllTextSlider)}
                >
                  <p className={cs(classes.viewAllText)}>View All</p>
                </div>
              </li>
            </VideoSlider>
          </ul>
          <ul
            className={cs(showAllList ? classes.listShown : classes.listHide)}
          >
            {items.map((item, index) => (
              <li
                data-find="1"
                key={item.id + "_" + index}
                className={classes.item}
              >
                <Preview
                  showAllList={showAllList}
                  index={items.length - index - 1}
                  item={item}
                  {...item}
                  audioHandler={audioHandler}
                />
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div
          className={cs(classes.message, {
            [classes.empty]: !hasItems,
            [classes.error]: !!error,
          })}
        >
          {!isLoading && (error ? t("generals.warnings.1") : "No result")}
        </div>
      )}

      {!showAllList && isLoading && <Loader />}
      {Boolean(audioHandler.currentAudio.musicSrc) && (
        <AudioPlayer audioHandler={audioHandler} />
      )}
    </div>
  );
};

export default AudioList;
