import { observer } from "mobx-react-lite";
import React from "react";

import cs from "classnames";
import classes from "./ExploreLibrary.module.scss";
import Button from "../../../../atoms/Button";
import Field from "./Field";
import { APPLICATION_ROUTES } from "../../../../../constants";
import { scrollToTop } from "../../../../../utilities/helpers";
import { useHistory } from "react-router-dom";

const ExploreLibrary = observer(() => {
  const history = useHistory();

  const navigateToLibrary = () => {
    history.push(APPLICATION_ROUTES.LIBRARY);
    scrollToTop();
  };

  return (
    <div className={cs(classes.container, "wrapper-m")}>
      <div className={cs(classes.headingContainer)}>
        <h3>
          Hiskashrus<span className={cs(classes.coma)}>,</span>{" "}
          <span className={cs(classes.titleWrap)}>
            - <br />
          </span>{" "}
          now at your fingertips
        </h3>
        <div className={cs(classes.pContainer)}>
          <p>
            Understand the Rebbe&apos;s Messages and Make Them Part of Your
            Daily Life.
          </p>
          <p>Maor Videos Help Your Family - All in an Engaging Way.</p>
        </div>
      </div>

      <div className={cs(classes.fieldContainer)}>
        <Field />
      </div>

      <div className={cs(classes.btnWrap)}>
        <Button onClick={navigateToLibrary}>Explore Library</Button>
      </div>
    </div>
  );
});

export default React.memo(ExploreLibrary);
