import { observer } from "mobx-react-lite";
import React from "react";
import cs from "classnames";
import classes from "./OurMethod.module.scss";
import badgeArrow from "../../../../../assets/images/badgeArrow.png";
import Icon from "../../../../atoms/Icon";
import Modal from "../../../../organisms/Modal";
import { useModal } from "../../../../../hooks";
import Popup from "../../../../atoms/Popup";
import Player from "../../../../organisms/Player";
import { STORAGE } from "../../../../../constants";

const OurMethod = observer(() => {
  const video1 = useModal();
  const video2 = useModal();

  const handleVideoPlayTop = () => {
    video1.onOpen();
  };

  const handleVideoPlayBottom = () => {
    video2.onOpen();
  };

  return (
    <div className={cs(classes.container, "wrapper-m")}>
      <div className={cs(classes.headingContainer)}>
        <h3 className={cs(classes.browserText)}>Our Method</h3>
        <h3 className={cs(classes.mobileText)}>Our Method</h3>
        {/* <h3 className={cs(classes.mobileText)}>Maors Method</h3> */}
      </div>

      <div className={cs(classes.main)}>
        <div className={cs(classes.upperPlayer, "player")}>
          <div className={cs(classes.videoContainer, "bgc-gold")}>
            {" "}
            <Icon
              onClick={handleVideoPlayTop}
              name="play-icon"
              size="xxl"
              className={cs(classes.pointer)}
            />
          </div>

          <div className={cs(classes.textContainer)}>
            <img
              src={badgeArrow}
              alt="badgeArrow"
              className={cs(classes.rightArrow)}
            />
            <h1>
              Ever wonder how each unique clip is produced? It takes a dedicated
              team of researchers, writers, animators, artists, voice actors and
              a vaad, who reviews every detail to ensure accuracy.
            </h1>
          </div>
        </div>
        <div className={cs(classes.lowerPlayer, "player")}>
          <div
            className={cs(
              classes.videoContainer,
              classes.videoContainerBottom,
              "bgc-gold"
            )}
          >
            {" "}
            <Icon
              onClick={handleVideoPlayBottom}
              name="play-icon"
              size="xxl"
              className={cs(classes.pointer)}
            />
          </div>
          <div className={cs(classes.textContainer)}>
            <img
              src={badgeArrow}
              alt="badgeArrow"
              className={cs(classes.leftArrow)}
            />
            <h1 className={cs(classes.mb)}>
              We brought together a group of kids of various ages and we tested
              the signature MyMaor method. Watch the results for yourself.
            </h1>
          </div>
        </div>
      </div>

      <Modal opened={video1.isOpened}>
        <Popup popup={video1}>
          <Player video={STORAGE.OUR_METHOD_TOP} />
        </Popup>
      </Modal>

      <Modal opened={video2.isOpened}>
        <Popup popup={video2}>
          <Player video={STORAGE.OUR_METHOD_BOTTOM} />
        </Popup>
      </Modal>
    </div>
  );
});

export default React.memo(OurMethod);
