/* eslint-disable prettier/prettier */
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  DELIVERY_COUNTRIES,
  PARTNERSHIP_STATUS,
  PROJECTS,
} from "../../../constants";
import { StoreContext } from "../../../contexts";
import {
  useInput,
  useDoubleInput,
  useQuery,
  useRanger,
  useRadio,
  useSelect,
  useCheckbox,
  useSelectR,
} from "../../../hooks";
import { ACTIONS } from "../../../store";
import {
  NAMES,
  NAMES as VALIDATIONS_NAMES,
} from "../../../utilities/validation";
import { checkHasDelivery, getInitials, getPaymentsDetails } from "./utilities";
import { useParams } from "react-router-dom";

const useForm = () => {
  const { authorization, cart, checkout, partnership } =
    useContext(StoreContext);
  const [currentPlan, setCurrentPlan] = useState("");
  const [pageLoaded, setPageLoaded] = useState(false);

  const query = useQuery();
  const { plan, interval } = useParams();

  const type = plan || PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID;
  console.log(query.get("delivery"), "typetype");
  // const interval = query.get("interval");

  // const duration = query.get("duration");
  const id = query.get("id");
  const initials = getInitials({ id: id, items: cart.items });
  // const interval = duration == "anually" ? 12 : 1;
  // const showDetails = !authorization.isAuthenticated;
  const showDetails = true;
  let hasDelivery = checkHasDelivery(type);
  let deliveryStatus = query.get("delivery");
  console.log("#002 to", type);
  console.log("#002 current", currentPlan);

  // Set hasDeliver to false
  // if (
  //   (currentPlan == PROJECTS.PARTNERS.PACKAGES.PARTNER_TIER.ID ||
  //     currentPlan == PROJECTS.PARTNERS.PACKAGES.SILVER_CARD_TIER.ID) &&
  //   (type == PROJECTS.PARTNERS.PACKAGES.SILVER_CARD_TIER.ID ||
  //     type == PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID)
  // ) {
  //   hasDelivery = false;
  // }

  const [deleteAddressSuccess, setDeleteAddressSuccess] = useState(false);
  const [addAddress, setAddAddress] = useState(false);
  console.log(addAddress, "addAddress");
  const [location, setLocation] = useState({
    street: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
  });

  useEffect(() => {
    if (authorization.isAuthenticated) {
      authorization.getAddress();
    }
  }, [authorization.isAuthenticated, deleteAddressSuccess]);

  useEffect(() => {
    addressStreet.onChange({
      target: { value: location.street },
    });
    addressCity.onChange({
      target: { value: location.city },
    });
    addressState.onChange({
      target: { value: location.state },
    });
    if (initials.address.country.value == "") {
      addressCountry.onChange(location.country);
    }
    addressZip.onChange({
      target: { value: location.postalCode },
    });
  }, [location]);

  const { t } = useTranslation();

  useEffect(() => {
    partnership[`${ACTIONS.PARTNERSHIP.GET_PARTNERSHIP}`]();
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    if (authorization.isAuthenticated) {
      authorization.getPersons({});
    }
  }, [authorization.isAuthenticated]);

  useEffect(() => {
    if (
      partnership.hasDetails &&
      (partnership.details["status"] == PARTNERSHIP_STATUS.ACTIVE ||
        partnership.details["status"] == PARTNERSHIP_STATUS.PENDING)
    ) {
      setCurrentPlan(
        partnership.details["plan"].toLowerCase().replace("-", "_")
      );
    }
  }, [partnership.details]);

  const getMembershipAction = () => {
    if (currentPlan) {
      const newPlan = type;
      let action = "upgrade";
      if (
        (currentPlan == PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID &&
          (newPlan == PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID ||
            newPlan == PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID)) ||
        (currentPlan == PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID &&
          newPlan == PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID)
      ) {
        action = "downgrade";
      }
      return action;
    } else {
      return "upgrade";
    }
  };

  /* labels */
  const APARTMENT = t("forms.labels.apartment");
  const CITY = t("forms.labels.city");
  const COUNTRY = t("forms.labels.country");
  const EMAIL = t("forms.labels.email");
  const FIRST_NAME = t("forms.labels.first_name");
  const LAST_NAME = t("forms.labels.last_name");
  const STATE = t("forms.labels.state");
  const STREET = t("forms.labels.street");
  const ZIP = t("forms.labels.zip");

  const PASSWORD = t("forms.labels.password");
  const CONFIRM_PASSWORD = t("forms.labels.confirm_password");
  const CONFIRM_EMAIL = t("forms.labels.confirm_email");
  const ADDRESS_ID = t("forms.labels.address_id");

  /* messages */
  const THE_FIELD_MUST_CONTAIN_AT_LEAST_2_SYMBOLS = t(
    "forms.messages.the_field_must_contain_at_least_number_symbols",
    {
      number: 2,
    }
  );
  const THE_FIELD_MUST_CONTAIN_AT_LEAST_5_SYMBOLS = t(
    "forms.messages.the_field_must_contain_at_least_number_symbols",
    {
      number: 5,
    }
  );
  const THE_FIELD_MUST_CONTAIN_NO_MORE_THAN_10_SYMBOLS = t(
    "forms.messages.the_field_must_contain_no_more_than_number_symbols",
    {
      number: 10,
    }
  );
  const THE_FIELD_MUST_CONTAIN_ONLY_LATIN_CHARACTERS_AND_DIGITS = t(
    "forms.messages.the_field_must_contain_only_latin_characters_and_digits"
  );
  // const THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS = t(
  //   "forms.messages.the_field_must_contain_only_latin_or_hebrew_characters"
  // );
  const THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_NUMBERS_SPACES_AND_SYMBOLS =
    t(
      "forms.messages.the_field_must_contain_only_latin_or_hebrew_characters_numbers_spaces_and_symbols"
    );
  const THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_SPACES_AND_HYPHENS =
    t(
      "forms.messages.the_field_must_contain_only_latin_or_hebrew_characters_spaces_and_hyphens"
    );
  const THIS_EMAIL_IS_NOT_VALID = t("forms.messages.this_email_is_not_valid");
  const THIS_FIELD_IS_REQUIRED = t("forms.messages.this_field_is_required");
  const AT_LEAST_SIX_SYMBOLS = t("forms.messages.at_least_number_symbols", {
    number: 6,
  });
  const THE_FIELD_DOES_NOT_MATCH = t("forms.messages.the_field_does_not_match");

  /* client */
  const lastName = useInput({
    label: LAST_NAME,
    validators: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      {
        name: VALIDATIONS_NAMES.MAX_64,
        options: {
          message: t("forms.messages.max_64_characters", {
            value: 64,
            field: t("forms.labels.last_name"),
          }),
        },
      },
      // {
      //   name: VALIDATIONS_NAMES.HEBREW_LATIN_SPACES_HYPHENS,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_SPACES_AND_HYPHENS,
      //   },
      // },
    ],
    value: authorization.isAuthenticated
      ? authorization.user.last_name
      : initials.details.lastName,
  });
  let email;

  // if (authorization.isAuthenticated) {
  //   email = useInput({
  //     label: EMAIL,
  //     validators: [
  //       {
  //         name: VALIDATIONS_NAMES.REQUIRED,
  //         options: { message: THIS_FIELD_IS_REQUIRED },
  //       },
  //       {
  //         name: VALIDATIONS_NAMES.EMAIL,
  //         options: { message: THIS_EMAIL_IS_NOT_VALID },
  //       },
  //     ],
  //     value: initials.details.email,
  //   });
  // } else {
  email = useDoubleInput({
    first: "Original",
    second: "Confirm",
    labelOfFirst: EMAIL,
    labelOfSecond: CONFIRM_EMAIL,
    validatorsOfFirst: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      {
        name: VALIDATIONS_NAMES.EMAIL,
        options: { message: THIS_EMAIL_IS_NOT_VALID },
      },
    ],
    validatorsOfSecond: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      {
        name: VALIDATIONS_NAMES.EMAIL,
        options: { message: THIS_EMAIL_IS_NOT_VALID },
      },
      {
        name: VALIDATIONS_NAMES.EQUAL,
        options: { message: THE_FIELD_DOES_NOT_MATCH },
      },
    ],
  });
  // }
  /* details */
  const firstName = useInput({
    label: FIRST_NAME,
    validators: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      // {
      //   name: VALIDATIONS_NAMES.HEBREW_LATIN_SPACES_HYPHENS,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_SPACES_AND_HYPHENS,
      //   },
      // },
      {
        name: VALIDATIONS_NAMES.MAX_64,
        options: {
          message: t("forms.messages.max_64_characters", {
            value: 64,
            field: t("forms.labels.first_name"),
          }),
        },
      },
    ],
    value: authorization.isAuthenticated
      ? authorization.user.first_name
      : initials.details.firstName,
  });

  /* create account checkbox */
  const createAccount = useCheckbox({
    label: "Create Account?",
  });

  /* password */
  let password;
  if (authorization.isAuthenticated) {
    password = useDoubleInput({
      first: "Original",
      second: "Confirm",
      labelOfFirst: PASSWORD,
      labelOfSecond: CONFIRM_PASSWORD,
      validatorsOfFirst: [
        {
          name: VALIDATIONS_NAMES.MIN_SIX_SYMBOLS,
          options: { message: AT_LEAST_SIX_SYMBOLS },
        },
      ],
      validatorsOfSecond: [
        {
          name: VALIDATIONS_NAMES.MIN_SIX_SYMBOLS,
          options: { message: AT_LEAST_SIX_SYMBOLS },
        },
        {
          name: VALIDATIONS_NAMES.EQUAL,
          options: { message: THE_FIELD_DOES_NOT_MATCH },
        },
      ],
    });
  } else {
    password = useDoubleInput({
      first: "Original",
      second: "Confirm",
      labelOfFirst: PASSWORD,
      labelOfSecond: CONFIRM_PASSWORD,
      validatorsOfFirst: [
        {
          name: VALIDATIONS_NAMES.REQUIRED,
          options: { message: THIS_FIELD_IS_REQUIRED },
        },
        {
          name: VALIDATIONS_NAMES.MIN_SIX_SYMBOLS,
          options: { message: AT_LEAST_SIX_SYMBOLS },
        },
      ],
      validatorsOfSecond: [
        {
          name: VALIDATIONS_NAMES.REQUIRED,
          options: { message: THIS_FIELD_IS_REQUIRED },
        },
        {
          name: VALIDATIONS_NAMES.MIN_SIX_SYMBOLS,
          options: { message: AT_LEAST_SIX_SYMBOLS },
        },
        {
          name: VALIDATIONS_NAMES.EQUAL,
          options: { message: THE_FIELD_DOES_NOT_MATCH },
        },
      ],
    });
  }

  /* address */
  const street = useInput({
    label: STREET,
    validators: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      {
        name: VALIDATIONS_NAMES.HEBREW_LATIN_DIGITS_SYMBOLS,
        options: {
          message:
            THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_NUMBERS_SPACES_AND_SYMBOLS,
        },
      },
    ],
    value: initials.address.street,
  });

  const apartment = useInput({
    label: APARTMENT,
    validators: [
      {
        name: VALIDATIONS_NAMES.HEBREW_LATIN_DIGITS_SYMBOLS,
        options: {
          message:
            THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_NUMBERS_SPACES_AND_SYMBOLS,
        },
      },
    ],
    value: initials.address.apartment,
  });
  const city = useInput({
    label: CITY,
    validators: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      {
        name: VALIDATIONS_NAMES.HEBREW_LATIN_SPACES_HYPHENS,
        options: {
          message:
            THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_SPACES_AND_HYPHENS,
        },
      },
    ],
    value: initials.address.city,
  });

  const address_id = useInput({
    label: ADDRESS_ID,
    value: initials.details.address_id,
  });

  const zip = useInput({
    label: ZIP,
    validators: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      {
        name: VALIDATIONS_NAMES.LATIN_DIGITS,
        options: {
          message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_CHARACTERS_AND_DIGITS,
        },
      },
      {
        name: VALIDATIONS_NAMES.MIN_5,
        options: { message: THE_FIELD_MUST_CONTAIN_AT_LEAST_5_SYMBOLS },
      },
      {
        name: VALIDATIONS_NAMES.MAX_10,
        options: { message: THE_FIELD_MUST_CONTAIN_NO_MORE_THAN_10_SYMBOLS },
      },
    ],
    value: initials.address.zip,
  });

  console.log(initials.address.zip, "initialsinitials");

  const country = useSelect({
    label: COUNTRY,
    options: Object.values(DELIVERY_COUNTRIES),
    value: initials.address.country,
  });

  const state = useInput({
    label: STATE,
    validators: [
      // { name: NAMES.LATIN, options: { message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_CHARACTERS } },
      // { name: NAMES.MIN_2, options: { message: THE_FIELD_MUST_CONTAIN_AT_LEAST_2_SYMBOLS } },
    ],
    value: initials.details.state,
  });
  /* address details */
  const addressApartment = useInput({
    label: APARTMENT,
    validators: [
      // {
      //   name: NAMES.HEBREW_LATIN_DIGITS_SYMBOLS,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_NUMBERS_SPACES_AND_SYMBOLS,
      //   },
      // },
    ],
    value: initials.address.apartment,
  });
  const addressCity = useInput({
    label: CITY + " *",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      // {
      //   name: NAMES.HEBREW_LATIN_SPACES,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_AND_SPACES,
      //   },
      // },
    ],
    value: initials.address.city,
  });

  const addressCountry = useSelectR({
    label: COUNTRY + " *",
    options: [
      { label: "USA", value: "USA" },
      // { label: "Canada", value: "Canada" },
    ],
    value: initials.address.country || { label: "USA", value: "USA" },
  });

  const addressState = useInput({
    label: STATE + " *",
    validators: [
      // {
      //   name: NAMES.HEBREW_LATIN,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS,
      //   },
      // },
      {
        name: NAMES.REQUIRED,
        options: { message: THE_FIELD_MUST_CONTAIN_AT_LEAST_2_SYMBOLS },
      },
    ],
    value: initials.address.state,
  });

  const addressStreet = useInput({
    label: STREET + " *",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      // {
      //   name: NAMES.HEBREW_LATIN_DIGITS_SYMBOLS,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_NUMBERS_SPACES_AND_SYMBOLS,
      //   },
      // },
    ],
    value: initials.address.street,
  });
  const addressZip = useInput({
    label: ZIP + " *",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      // {
      //   name: NAMES.LATIN_DIGITS,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_CHARACTERS_AND_DIGITS,
      //   },
      // },
      {
        name: NAMES.MIN_5,
        options: { message: THE_FIELD_MUST_CONTAIN_AT_LEAST_5_SYMBOLS },
      },
      {
        name: NAMES.MAX_10,
        options: { message: THE_FIELD_MUST_CONTAIN_NO_MORE_THAN_10_SYMBOLS },
      },
    ],
    value: initials.address.zip,
  });

  /* payments */
  const numberOfPayments = useRanger({
    labels: ["1", "2", "3", "4", "6", "12"],
    max: 6,
    min: 1,
    values: initials.details.interval,
  });

  const billingCycle = useRadio({
    options: [
      { id: "my-maor-order-form-billing-monthly", label: "Monthly", value: 1 },
      {
        id: "my-maor-order-form-billing-six-months",
        label: "6 Months",
        value: 6,
      },
      { id: "my-maor-order-form-billing-yearly", label: "Yearly", value: 12 },
    ],
    value: interval,
  });

  const paymentsDetails = getPaymentsDetails({
    type,
    typeOfPayments: billingCycle.value,
  });
  const isValidPassword = authorization.isAuthenticated
    ? 0
    : password.errorsOfOriginal.length + password.errorsOfConfirm.length;
  const isDowngrade = getMembershipAction() == "downgrade" ? true : false;
  const isValidEmail = authorization.isAuthenticated
    ? email.errorsOfOriginal.length
    : email.errorsOfOriginal.length + email.errorsOfConfirm.length;
  // const isValidPasswordForLoggedIn = authorization.isAuthenticated
  //   ? password.errorsOfOriginal.length
  //   : 0;
  const isNotValidPersonal =
    firstName.errors.length ||
    lastName.errors.length ||
    isValidEmail ||
    isValidPassword ||
    isDowngrade;
  const isNotValidDelivery = hasDelivery
    ? !!(
        street.errors.length ||
        city.errors.length ||
        zip.errors.length ||
        !country.value
      )
    : false;

  let isNotValidUserAddress = false;
  isNotValidUserAddress = !!(
    addressStreet.errors.length ||
    addressCity.errors.length ||
    addressState.errors.length ||
    addressZip.errors.length
  );

  console.log(isNotValidUserAddress, "addressStreet");

  const isNotValid =
    isNotValidPersonal || isNotValidDelivery || isNotValidUserAddress;

  let referredFrom = "";
  if (navigator.cookieEnabled) {
    referredFrom = localStorage.getItem("referral-code");
  }

  const triggerValidationsUserAddress = () => {
    addressCity.touch();
    addressState.touch();
    addressStreet.touch();
    addressZip.touch();
  };

  console.log(hasDelivery, "hasDelivery #1");

  const onSubmit = ({
    onSuccess,
    projectType,
    billingCycle = null,
    prefilled = null,
  }) => {
    const data = {
      client: {
        email:
          prefilled != null && prefilled.email
            ? prefilled.email
            : email.valueOfOriginal,
        first_name:
          prefilled != null && prefilled.firstName
            ? prefilled.firstName
            : firstName.value,
        last_name:
          prefilled != null && prefilled.lastName
            ? prefilled.lastName
            : lastName.value,
        password: password.valueOfOriginal,
        createAccount: authorization.isAuthenticated ? false : true,
      },
      delivery: {
        apartment: addressApartment.errors.length ? "" : addressApartment.value,
        city: addressCity.value,
        country: addressCountry.value.value,
        state: addressState.value,
        street: addressStreet.value,
        zip: addressZip.value,
        address_id: address_id.errors.length ? "" : address_id.value,
      },
      details: {
        // first_name: firstName.value,
        // email: email.valueOfOriginal,
        // interval: parseInt(interval),
        // last_name: lastName.value,

        first_name:
          prefilled != null && prefilled.firstName
            ? prefilled.firstName
            : firstName.value,
        email:
          prefilled != null && prefilled.email
            ? prefilled.email
            : email.valueOfOriginal,
        interval:
          billingCycle != null ? parseInt(billingCycle) : parseInt(interval),
        last_name:
          prefilled != null && prefilled.lastName
            ? prefilled.lastName
            : lastName.value,

        password: password.valueOfOriginal,
        createAccount: createAccount.checked,
      },
      project: PROJECTS.PARTNERS.ID,
      type: projectType || type,
    };

    /* TODO: create util to delete all the empties from request body */
    if (
      authorization.isAuthenticated &&
      password.valueOfOriginal != undefined &&
      password.valueOfOriginal != ""
    ) {
      authorization.autheticatePassword(
        data.client.email,
        data.client.password,
        () => {
          checkout.setValue("reAuthenticated", true);

          if (!data.delivery.apartment) delete data.delivery.apartment;
          // if (!data.delivery.state) delete data.delivery.state;
          if (!data.details.first_name) {
            delete data.client.first_name;
            delete data.details.first_name;
          }

          if (!hasDelivery) delete data.delivery;
          if (deliveryStatus == "false") delete data.delivery;

          if (id) {
            cart.edit({
              data,
              id: Number(id),
              onSuccess,
            });
            return;
          }

          console.log(data.delivery, "cartaddcart");
          cart.add({
            data,
            onSuccess,
          });
        }
      );
    } else {
      checkout.setValue("reAuthenticated", false);

      if (!data.delivery.apartment) delete data.delivery.apartment;
      // if (!data.delivery.state) delete data.delivery.state;
      if (!data.details.first_name) {
        delete data.client.first_name;
        delete data.details.first_name;
      }

      if (referredFrom) data.referred_from = referredFrom;

      if (!hasDelivery) delete data.delivery;
      if (deliveryStatus == "false") delete data.delivery;

      if (id) {
        cart.edit({ data, id: Number(id), onSuccess });
        return;
      }

      cart.add({ data, onSuccess });
      if (navigator.cookieEnabled) {
        localStorage.removeItem("referral-code");
      }
    }
  };

  useEffect(() => {
    if (authorization.isAuthenticated) {
      email.onChangeOriginal({ target: { value: authorization.user.email } });
      firstName.onChange({
        target: { value: authorization.user.first_name || "" },
      });
      lastName.onChange({ target: { value: authorization.user.last_name } });
    }
  }, [authorization.isAuthenticated, authorization.user, pageLoaded]);

  return {
    apartment: apartment,
    city: city,
    country: country,
    email: email,
    firstName: firstName,
    lastName: lastName,
    password: password,
    createAccount: createAccount,
    numberOfPayments: numberOfPayments,
    state: state,
    street: street,
    zip: zip,
    showDetails: showDetails,
    hasDelivery: hasDelivery,
    paymentsDetails: paymentsDetails,
    isNotValidPersonal: isNotValidPersonal,
    isNotValid: isNotValid,
    billingCycle: billingCycle,
    onSubmit: onSubmit,
    addressApartment,
    addressCity,
    addressCountry,
    addressState,
    addressZip,
    addressStreet,
    deleteAddressSuccess,
    setDeleteAddressSuccess,
    setAddAddress: setAddAddress,
    addAddress: addAddress,
    location: location,
    setLocation: setLocation,
    address_id,
    isNotValidUserAddress,
    triggerValidationsUserAddress,
  };
};

export default useForm;
