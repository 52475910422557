import { POSITIONS, WIDTH } from "./constants";

const getCenterBottomOutPosition = (metrics) => {
  return {
    left: metrics.left + metrics.width / 2,
    top: metrics.bottom,
    transform: "translate(-50%, 0%)",
  };
};

const getCenterBottomPosition = (metrics) => {
  return {
    left: metrics.left + metrics.width / 2,
    top: metrics.bottom,
    transform: "translate(-50%, -100%)",
  };
};

const getCenterCenterPosition = (metrics) => {
  return {
    left: metrics.left + metrics.width / 2,
    top: metrics.top + metrics.height / 2,
    transform: "translate(-50%, -50%)",
  };
};

const getCenterTopPosition = (metrics) => {
  return {
    left: metrics.left + metrics.width / 2,
    top: metrics.top,
    transform: "translate(-50%, 0%)",
  };
};

const getCenterTopOutPosition = (metrics) => {
  return {
    left: metrics.left + metrics.width / 2,
    top: metrics.top,
    transform: "translate(-50%, -100%)",
  };
};

const getLeftBottomOutPosition = (metrics) => {
  return {
    left: metrics.left,
    top: metrics.bottom,
    transform: "translate(0%, 0%)",
  };
};

const getLeftBottomPosition = (metrics) => {
  return {
    left: metrics.left,
    top: metrics.bottom,
    transform: "translate(0%, -100%)",
  };
};

const getLeftCenterPosition = (metrics) => {
  return {
    left: metrics.left,
    top: metrics.top + metrics.height / 2,
    transform: "translate(0%, -50%)",
  };
};

const getLeftTopPosition = (metrics) => {
  return {
    left: metrics.left,
    top: metrics.top,
    transform: "translate(0%, 0%)",
  };
};

const getLeftTopOutPosition = (metrics) => {
  return {
    left: metrics.left,
    top: metrics.top,
    transform: "translate(0%, -100%)",
  };
};

const getLeftOutBottomOutPosition = (metrics) => {
  return {
    left: metrics.left,
    top: metrics.bottom,
    transform: "translate(-100%, 0%)",
  };
};

const getLeftOutBottomPosition = (metrics) => {
  return {
    left: metrics.left,
    top: metrics.bottom,
    transform: "translate(-100%, -100%)",
  };
};

const getLeftOutCenterPosition = (metrics) => {
  return {
    left: metrics.left,
    top: metrics.top + metrics.height / 2,
    transform: "translate(-100%, -50%)",
  };
};

const getLeftOutTopPosition = (metrics) => {
  return {
    left: metrics.left,
    top: metrics.top,
    transform: "translate(-100%, 0%)",
  };
};

const getLeftOutTopOutPosition = (metrics) => {
  return {
    left: metrics.left,
    top: metrics.top,
    transform: "translate(-100%, -100%)",
  };
};

const getRightBottomOutPosition = (metrics) => {
  return {
    left: metrics.right,
    top: metrics.bottom,
    transform: "translate(-100%, 0%)",
  };
};

const getRightBottomPosition = (metrics) => {
  return {
    left: metrics.right,
    top: metrics.bottom,
    transform: "translate(-100%, -100%)",
  };
};

const getRightCenterPosition = (metrics) => {
  return {
    left: metrics.right,
    top: metrics.top + metrics.height / 2,
    transform: "translate(-100%, -50%)",
  };
};

const getRightTopPosition = (metrics) => {
  return {
    left: metrics.right,
    top: metrics.top,
    transform: "translate(-100%, 0%)",
  };
};

const getRightTopOutPosition = (metrics) => {
  return {
    left: metrics.right,
    top: metrics.top,
    transform: "translate(-100%, -100%)",
  };
};

const getRightOutBottomOutPosition = (metrics) => {
  return {
    left: metrics.right,
    top: metrics.bottom,
    transform: "translate(0%, 0%)",
  };
};

const getRightOutBottomPosition = (metrics) => {
  return {
    left: metrics.right,
    top: metrics.bottom,
    transform: "translate(0%, -100%)",
  };
};

const getRightOutCenterPosition = (metrics) => {
  return {
    left: metrics.right,
    top: metrics.top + metrics.height / 2,
    transform: "translate(0%, -50%)",
  };
};

const getRightOutTopPosition = (metrics) => {
  return {
    left: metrics.right,
    top: metrics.top,
    transform: "translate(0%, 0%)",
  };
};

const getRightOutTopOutPosition = (metrics) => {
  return {
    left: metrics.right,
    top: metrics.top,
    transform: "translate(0%, -100%)",
  };
};

const getRightTopPositionMobile = (metrics) => {
  return {
    left: metrics.right,
    top: metrics.top  + metrics.height,
    transform: "translate(-83%, 0%)",
  };
};

const getPosition = (target, { position }) => {
  const metrics = target.getBoundingClientRect();
  // console.log(window.getComputedStyle(target));
  // console.dir(target);
  // let parent = target.parentElement;

  // TODO: should find fixed parent and calculate metrics

  // while (parent) {
  //   console.log("parent", parent);
  //   parent = parent.parentElement;
  // }

  switch (position) {
    case POSITIONS.CENTER_BOTTOM_OUT:
      return getCenterBottomOutPosition(metrics);
    case POSITIONS.CENTER_BOTTOM:
      return getCenterBottomPosition(metrics);
    case POSITIONS.CENTER_CENTER:
      return getCenterCenterPosition(metrics);
    case POSITIONS.CENTER_TOP:
      return getCenterTopPosition(metrics);
    case POSITIONS.CENTER_TOP_OUT:
      return getCenterTopOutPosition(metrics);
    case POSITIONS.LEFT_BOTTOM_OUT:
      return getLeftBottomOutPosition(metrics);
    case POSITIONS.LEFT_BOTTOM:
      return getLeftBottomPosition(metrics);
    case POSITIONS.LEFT_CENTER:
      return getLeftCenterPosition(metrics);
    case POSITIONS.LEFT_TOP:
      return getLeftTopPosition(metrics);
    case POSITIONS.LEFT_TOP_OUT:
      return getLeftTopOutPosition(metrics);
    case POSITIONS.LEFT_OUT_BOTTOM_OUT:
      return getLeftOutBottomOutPosition(metrics);
    case POSITIONS.LEFT_OUT_BOTTOM:
      return getLeftOutBottomPosition(metrics);
    case POSITIONS.LEFT_OUT_CENTER:
      return getLeftOutCenterPosition(metrics);
    case POSITIONS.LEFT_OUT_TOP:
      return getLeftOutTopPosition(metrics);
    case POSITIONS.LEFT_OUT_TOP_OUT:
      return getLeftOutTopOutPosition(metrics);
    case POSITIONS.RIGHT_BOTTOM_OUT:
      return getRightBottomOutPosition(metrics);
    case POSITIONS.RIGHT_BOTTOM:
      return getRightBottomPosition(metrics);
    case POSITIONS.RIGHT_CENTER:
      return getRightCenterPosition(metrics);
    case POSITIONS.RIGHT_TOP:
      return getRightTopPosition(metrics);
    case POSITIONS.RIGHT_TOP_OUT:
      return getRightTopOutPosition(metrics);
    case POSITIONS.RIGHT_OUT_BOTTOM_OUT:
      return getRightOutBottomOutPosition(metrics);
    case POSITIONS.RIGHT_OUT_BOTTOM:
      return getRightOutBottomPosition(metrics);
    case POSITIONS.RIGHT_OUT_CENTER:
      return getRightOutCenterPosition(metrics);
    case POSITIONS.RIGHT_OUT_TOP:
      return getRightOutTopPosition(metrics);
    case POSITIONS.RIGHT_OUT_TOP_OUT:
      return getRightOutTopOutPosition(metrics);
     case POSITIONS.RIGHT_TOP_MOBILE:
      return getRightTopPositionMobile(metrics) 
    default:
      return getCenterTopOutPosition(metrics);
  }
};

const getWidth = (target, { width }) => {
  switch (width) {
    case WIDTH.DEFAULT:
      return {};
    case WIDTH.TARGET:
      return { width: window.getComputedStyle(target).getPropertyValue("width") };
    default:
      return {};
  }
};

const getStyle = (target, { position, width }) => {
  const positionStyle = getPosition(target, { position });
  const widthStyle = getWidth(target, { width });
  const style = { ...positionStyle, ...widthStyle };

  return style;
};

export default getStyle;
