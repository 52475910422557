import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import classes from "./OptionSelector.module.scss";

const OptionSelector = ({ items, value, onChange, colorScheme }) => (
  <ul className={cs(classes.container)}>
    {items.map((item) => {
      const checked = item.value == value;

      return (
        <li key={item.id} className={cs(classes.item, classes[colorScheme])}>
          <input
            data-filterlabel={item.label}
            checked={checked}
            className={classes.input}
            id={item.id}
            type="radio"
            value={item.value}
            onChange={onChange}
          />
          <label htmlFor={item.id} className={classes.label}>
            {item.label}
          </label>
        </li>
      );
    })}
  </ul>
);

OptionSelector.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  colorScheme: PropTypes.string,
};

export default OptionSelector;
