import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import cs from "classnames";

import { TYPES as LOGOTYPE_TYPES } from "../../atoms/Logotype";
// import SearchInput from "../../atoms/SearchInput";
import Icon from "../../atoms/Icon";
import PageTitle from "../../atoms/PageTitle";
import Layout from "../../templates/Layout";
import { PAGE_TITLES } from "../../../constants";
import { ACTIONS } from "../../../store";
import Project from "./components/Project";
import useLibrary from "./useLibrary";
import useLibraryFilter from "./useLibraryFilter";
import classes from "./Library.module.scss";
import LibraryNavigation from "../../molecules/LibraryNavigation";
import LibrarySidebar from "../../molecules/LibrarySidebar";
import Pills from "../../atoms/Pills";
import { COLORS as PILL_COLORS } from "../../atoms/Pills/utilities";
// import CheckboxTab from "../../atoms/CheckboxTab";

import { sortingOptions } from "./utilities";
import PROJECTS from "../../../constants/projects";

import LibraryFilterClear from "../../molecules/LibraryFilterClear";
import AsyncAutocompleteSearch from "../../atoms/AsyncAutocompleteSearch";
import AudioPlayer from "../../templates/AudioPlayer";
import Winners from "./components/Winners";
import GoldPartners from "./components/GoldPartners";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import SlideBar from "../../organisms/Mobile/SlideBar/SlideBar";
import { useContext } from "react";
import { StoreContext } from "../../../contexts";
import SelectProject from "../../organisms/SelectProject/SelectProject";
import { useQuery } from "../../../hooks";

const Library = observer(() => {
  const [sidebarVisible, setSideBarVisible] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const { playlist, authorization, common } = useContext(StoreContext);
  const [currentAudio, setCurrentAudio] = useState({});
  const { freedonoraccess } = useContext(StoreContext);
  const query = useQuery();
  const autoPlayAudio = query.get("audio");

  const freeAccessDonor = () => {
    let fdaSession = "";
    if (navigator.cookieEnabled) {
      fdaSession = sessionStorage.getItem("fda");
    }

    if (fdaSession === "1") {
      return true;
    } else {
      return false;
    }
  };

  const audioHandler = {
    currentAudio: currentAudio,
    setCurrentAudio: setCurrentAudio,
  };

  useEffect(() => {
    if (autoPlayAudio != null) {
      audioHandler.setCurrentAudio({
        musicSrc: "d",
      });
    }
  }, [autoPlayAudio]);

  const maorRef = useRef(null);
  const dailyRebbeVideoRef = useRef(null);
  const illuminateRef = useRef(null);

  const dailyRebbeVideo = useLibrary({
    ACTION: ACTIONS.LIBRARY.GET_DAILY_REBBE_VIDEO,
    NAME: "DailyRebbeVideo",
  });
  const myMaor = useLibrary({
    ACTION: ACTIONS.LIBRARY.GET_MY_MAOR,
    NAME: "MyMaor",
  });
  const illuminate = useLibrary({
    ACTION: ACTIONS.LIBRARY.GET_ILLUMINATE,
    NAME: "Illuminate",
  });
  const {
    search,
    projectType,
    setProjectType,
    handleNavigationChange,
    onNavigationChange,
    sorting,
    maorType,
    dailyRebbeType,
    illuminateType,
    coloringPages,
    tag,
    filterData,
    holiday,
    appliedFilters,
    searching,
  } = useLibraryFilter();

  useEffect(() => {
    handleNavigationChange(window.location.href);
    filterData.holidaysToday.onLoad();
  }, []);

  useEffect(() => {
    switch (common.get("project")) {
      case "my-maor":
        setProjectType("MyMaor");
        break;

      case "daily-rebbe-video":
        setProjectType("DailyRebbeVideo");
        break;

      case "illuminate":
        setProjectType("Illuminate");
        break;
    }
  }, [common.get("project")]);

  if (isMobile && common.get("selectProjectScreen")) {
    // document.querySelector("body").style.overflow = "hidden";
  } else {
    document.querySelector("body").style["overflow-y"] = "scroll";
  }

  // const handleGoTo = (projectType) => {
  //   switch (projectType) {
  //     case "MyMaor":
  //       maorRef.current.scrollIntoView({ behavior: "smooth" });
  //       break;

  //     case "DailyRebbeVideo":
  //       dailyRebbeVideoRef.current.scrollIntoView({ behavior: "smooth" });
  //       break;

  //     case "Illuminate":
  //       illuminateRef.current.scrollIntoView({ behavior: "smooth" });
  //       break;
  //   }
  // };

  let holidayOptions = [];
  if (
    filterData &&
    !filterData.holidaysToday.isLoading &&
    !filterData.holidaysToday.isError
  ) {
    holidayOptions =
      filterData.holidaysToday.data.length > 0
        ? filterData.holidaysToday.data
        : [];
    holidayOptions = holidayOptions.map((holidayOption) => {
      return {
        id: `holiday-${holidayOption.id}`,
        label: holidayOption.name_english,
        value: holidayOption.id,
      };
    });
  }
  holidayOptions = holidayOptions.slice(0, 2);
  // console.log("DR", dailyRebbeVideo);
  // console.log("MM", myMaor);
  // console.log("ILL", illuminate);
  useEffect(() => {
    const currentURL = window.location.href;
    let fdaSession = "";
    if (navigator.cookieEnabled) {
      fdaSession = sessionStorage.getItem("fda");
    }
    let fdaURL = currentURL.replace(/&fda=1/, "");
    fdaURL = fdaURL.replace(/\?fda=1/, "");

    if (fdaSession) {
      freedonoraccess[`${ACTIONS.FREE_DONOR_ACCESS.GET}`]({
        fdaURL,
      });
    }
  }, [window.location.href]);

  return (
    <>
      <PageTitle value={PAGE_TITLES.LIBRARY} />
      <Layout
        data={{
          filterData: filterData,
          coloringPages: coloringPages,
          autocomplete: {
            search: search,
            searching: searching,
          },
          audioHandler: audioHandler,
        }}
      >
        <div
          className={cs(classes.mainWrapper, {
            [classes.openSidebarWrap]: sidebarVisible,
            [classes.chooseProjectWrapper]:
              isMobile && common.get("selectProjectScreen"),
          })}
        >
          {common.get("selectProjectScreen") && (
            <>
              {isMobile ? (
                <MobileView>
                  <SelectProject />
                </MobileView>
              ) : null}
            </>
          )}

          {!isMobile ? (
            <BrowserView>
              <LibrarySidebar
                maorType={maorType}
                dailyRebbeType={dailyRebbeType}
                illuminateType={illuminateType}
                tag={tag}
                coloringPages={coloringPages}
                filterData={filterData}
                sidebarVisible={sidebarVisible}
                setSideBarVisible={setSideBarVisible}
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
                audioHandler={audioHandler}
              />
            </BrowserView>
          ) : null}

          <div
            className={cs(
              classes.wrapper,
              "wrapper-xl",
              isMobile ? cs("p-0", classes.mobileWrapper) : "",
              classes.libraryWrapper
            )}
          >
            {isMobile && (
              <MobileView>
                <SlideBar
                  filterData={filterData}
                  maorType={maorType}
                  dailyRebbeType={dailyRebbeType}
                  holidayOptions={holidayOptions}
                  holiday={holiday}
                />
              </MobileView>
            )}

            <div
              className={cs(classes.searchBarWrap, {
                [classes.openFilterWrap]: openFilter,
              })}
            >
              {!isMobile ? (
                // <BrowserView>
                <div
                  className={cs(classes.filterWrapper, {
                    [classes.openFilterWrap]: openFilter,
                  })}
                >
                  <AsyncAutocompleteSearch
                    id="async-autocomplete-search"
                    page="library"
                    placeholder="Search Library"
                    value={search.value}
                    onSearch={searching.onSearch}
                    searchingOptions={toJS(searching.options)}
                    isSearching={searching.isSearching}
                    onChange={search.onChange}
                  >
                    <Icon
                      cursorPointer={true}
                      onClick={() => {
                        console.log("search", search.value);
                        let searchValue =
                          document.querySelector(".rbt-input-main").value;
                        if (searchValue != "") {
                          search.onChange(searchValue);
                        }
                      }}
                      name="search"
                    />
                  </AsyncAutocompleteSearch>

                  <section
                    className={cs(classes.sortingAndHolidaySection, "mb-0")}
                  >
                    <div className={cs(classes.sortingWrapper)}>
                      <strong>Sort by:</strong>
                      <Pills
                        items={sortingOptions}
                        value={sorting.value}
                        onChange={sorting.onChange}
                      />
                    </div>
                    {holidayOptions.length != 0 && (
                      <Pills
                        color={PILL_COLORS.PURPLE}
                        items={holidayOptions}
                        value={holiday.value}
                        onChange={holiday.onChange}
                      />
                    )}
                  </section>
                </div>
              ) : // </BrowserView>
              null}

              {!isMobile ? (
                <BrowserView>
                  <div>
                    <LibraryNavigation onClick={onNavigationChange} />
                  </div>
                </BrowserView>
              ) : null}

              {projectType != "DailyRebbeVideo" && (
                <>
                  {!isMobile ? (
                    <BrowserView style={{ width: "100%" }}>
                      <div className={cs(classes.winnersListContainer)}>
                        <Winners />
                      </div>
                    </BrowserView>
                  ) : null}
                </>
              )}
            </div>
            {Object.keys(appliedFilters.data).length != 0 && (
              <section className={classes.clearFilterSection}>
                <div className={isMobile ? classes.mobileFilters : ""}>
                  <LibraryFilterClear
                    filters={appliedFilters.data}
                    onClear={appliedFilters.onClear}
                  />
                  {isMobile && (
                    <MobileView>
                      {authorization.isAuthenticated && (
                        <button
                          title="Please sign in to add videos to Playlist"
                          disabled={playlist.getIsLoading(
                            ACTIONS.PLAYLIST.ADD_SEARCH_FILTER
                          )}
                          onClick={() => {
                            playlist.addSearchFilterVideos({
                              payload: {
                                search: search.value,
                                order: sorting.value,
                                videoType: maorType.value,
                                holidayId: holiday.value,
                                project: PROJECTS.MY_MAOR.ID,
                                coloringPages: coloringPages.value,
                              },
                              onSuccess: () => {
                                playlist.get({ onSuccess: null });
                              },
                            });
                          }}
                          className={classes.playlistBtn}
                        >
                          {playlist.getIsLoading(
                            ACTIONS.PLAYLIST.ADD_SEARCH_FILTER
                          )
                            ? "Loading..."
                            : "Add to playlist"}
                        </button>
                      )}
                    </MobileView>
                  )}
                </div>
              </section>
            )}

            {/* {projectType == "" && (
            <section className={cs(classes.goToSection)}>
              <label className={cs(classes.goToLabel)}>Go to</label>
              {gotoOptions.map((gotoOption) => {
                return (
                  <button
                    className={cs(classes.goToItem)}
                    id={gotoOption.id}
                    key={gotoOption.id}
                    onClick={() => {
                      handleGoTo(gotoOption.value);
                    }}
                  >
                    {gotoOption.label}
                  </button>
                );
              })}
            </section>
          )} */}

            {/* {search.value && (
            <section className={cs(classes.searchInfo)}>
              Search results for: <strong>{search.value}</strong>
            </section>
          )} */}

            {projectType == "" && <section></section>}
            {(projectType == "" ||
              projectType == "MyMaor" ||
              common.get("project") == "my-maor") && (
              <section ref={maorRef} className={classes.section}>
                <Project
                  hasItems={myMaor.hasItems}
                  hasNextPage={myMaor.hasNextPage}
                  items={myMaor.items}
                  totalItems={myMaor.totalItems}
                  isLoading={myMaor.isLoading}
                  logotype={LOGOTYPE_TYPES.MY_MAOR}
                  onLoad={myMaor.onLoad}
                  search={search.value}
                  order={sorting.value}
                  holidayId={holiday.value}
                  videoType={maorType.value}
                  coloringPages={coloringPages}
                  setRequestPayload={myMaor.setRequestPayload}
                  resetPage={myMaor.resetPage}
                  title={PROJECTS.TITLES.MY_MAOR}
                  projectType={PROJECTS.MY_MAOR.ID}
                  // freeAccess={myMaor.freeAccess}
                  freeAccess={freeAccessDonor()}
                  appliedFilters={appliedFilters}
                  limitRecord={projectType != "" ? 20 : 8}
                />
              </section>
            )}

            {(projectType == "" ||
              projectType == "DailyRebbeVideo" ||
              common.get("project") == "daily-rebbe-video") && (
              <section ref={dailyRebbeVideoRef} className={classes.section}>
                <Project
                  hasItems={dailyRebbeVideo.hasItems}
                  hasNextPage={dailyRebbeVideo.hasNextPage}
                  items={dailyRebbeVideo.items}
                  totalItems={dailyRebbeVideo.totalItems}
                  isLoading={dailyRebbeVideo.isLoading}
                  logotype={LOGOTYPE_TYPES.DAILY_REBBE_VIDEO}
                  onLoad={dailyRebbeVideo.onLoad}
                  search={search.value}
                  order={sorting.value}
                  holidayId={holiday.value}
                  videoType={dailyRebbeType.value}
                  coloringPages={coloringPages}
                  setRequestPayload={dailyRebbeVideo.setRequestPayload}
                  resetPage={dailyRebbeVideo.resetPage}
                  title={PROJECTS.TITLES.DAILY_REBBE_VIDEO}
                  projectType={PROJECTS.DAILY_REBBE_VIDEO.ID}
                  // freeAccess={dailyRebbeType.freeAccess}
                  freeAccess={true}
                  appliedFilters={appliedFilters}
                  limitRecord={projectType != "" ? 20 : 8}
                />
              </section>
            )}

            {(projectType == "" || projectType == "Illuminate") && (
              <section ref={illuminateRef} className={classes.section}>
                <Project
                  hasItems={illuminate.hasItems}
                  hasNextPage={illuminate.hasNextPage}
                  items={illuminate.items}
                  totalItems={illuminate.totalItems}
                  isLoading={illuminate.isLoading}
                  logotype={LOGOTYPE_TYPES.ILLUMINATE}
                  onLoad={illuminate.onLoad}
                  search={search.value}
                  order={sorting.value}
                  holidayId={holiday.value}
                  videoType={illuminateType.value}
                  coloringPages={coloringPages}
                  setRequestPayload={illuminate.setRequestPayload}
                  resetPage={illuminate.resetPage}
                  title={PROJECTS.TITLES.ILLUMINATE}
                  projectType={PROJECTS.ILLUMINATE.ID}
                  // freeAccess={illuminate.freeAccess}
                  freeAccess={freeAccessDonor()}
                  appliedFilters={appliedFilters}
                />
              </section>
            )}

            <div className={cs(classes.partnersListContainer)}>
              <GoldPartners layout="horizontal" />
            </div>
          </div>
        </div>
        {audioHandler.currentAudio.musicSrc && (
          <AudioPlayer audioHandler={audioHandler} />
        )}
      </Layout>
    </>
  );
});

Library.displayName = "Library";
export default Library;
