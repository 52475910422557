import API from "./_api";
import { API_ROUTES } from "../constants";

const TrackDonationAPI = class extends API {
  get() {
    return this.request(API_ROUTES.TRACK_DONATION.GET, {
      method: "GET",
    });
  }
};

export default TrackDonationAPI;
