const NAMES = {
  CARD_EXPIRATION_DATE: "cardExpireDate",
  CHARACTERS_DIGITS_SYMBOLS: "charactersDigitsSymbols",
  COMPLETED_PHONE_NUMBER: "completedPhoneNumber",
  DIGITS: "digits",
  DIGITS_DOTS: "digitsDots",
  EMAIL: "email",
  EQUAL: "equal",
  GREATER_THAN_VALUE: "greaterThanValue",
  HEBREW_LATIN: "hebrewLatin",
  HEBREW_LATIN_DIGITS: "hebrewLatinDigits",
  HEBREW_LATIN_DIGITS_SYMBOLS: "hebrewLatinDigitsSymbols",
  HEBREW_LATIN_SPACES: "hebrewLatinSpaces",
  HEBREW_LATIN_SPACES_HYPHENS: "hebrewLatinSpacesHyphens",
  LATIN: "latin",
  LATIN_DIGITS: "latinDigits",
  MAX_10: "max10",
  MAX_64: "max64",
  MIN_FOUR_SYMBOLS: "min4",
  MIN_FIVE_MAX_FIFTEEN_SYMBOLS: "min5max15",
  MIN_SIX_SYMBOLS: "min6",
  MIN_2: "min2",
  MIN_3: "min3",
  MIN_5: "min5",
  PHONE: "phone",
  PATTERN: "pattern",
  REQUIRED: "required",
};

export default NAMES;
