import React, { useContext, useCallback, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import cs from "classnames";

import Button from "../../atoms/Button";
import Loader from "../../atoms/Loader";
import Select from "../../atoms/Select";
import PlainInput from "../../atoms/PlainInput";
import { StoreContext } from "../../../contexts";
import { ACTIONS } from "../../../store";
import Offers from "./components/Offers";
import useDonation from "./useDonation";
import classes from "./PartnersMobileDonation.module.scss";
import Checkbox from "../../atoms/Checkbox";
import PasswordInput from "../../atoms/PasswordInput";
import { APPLICATION_ROUTES, COOKIES, PROJECTS } from "../../../constants";
import { useHistory } from "react-router-dom";
import { capitalize, scrollToTop } from "../../../utilities/helpers";
import Cookies from "../../../utilities/cookies";
import { Link } from "react-router-dom";
import { useQuery } from "../../../hooks";
import maorLogo from "../../../assets/images/donation-maor-logo.png";
import maorFooterLogo from "../../../assets/images/donation-footer-logo.png";
import appleStore from "../../../assets/images/apple-btn.png";
import googlePlay from "../../../assets/images/google-play.png";
import donationMobile from "../../../assets/images/donation-phone-org.png";
// import donationShadow from "../../../assets/images/shadow-line-2.png";
import donationShadowTop from "../../../assets/images/shadow-line.png";
// import donationMobileGray from "../../../assets/images/donation-phone.png";
// import bimage from "../../../assets//images/list-slider-bg.png";

const PartnersMobileDonation = observer(() => {
  const {
    apartment,
    city,
    country,
    customAmount,
    email,
    lastName,
    firstName,
    password,
    createAccount,
    anonymous,
    hasDelivery,
    isSelectedCustomAmount,
    isLoading,
    isNotValid,
    offer,
    showPersonal,
    state,
    street,
    zip,
    onSubmit,
  } = useDonation();

  const history = useHistory();

  const { authorization, cart, checkout, trackdonation } = useContext(StoreContext);
  const { t } = useTranslation();

  useEffect(() => {
    trackdonation.get();
  }, []);

  const query = useQuery();
  const type = query.get("type") || PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID;
  const isAutoSignin = Cookies.get({ name: COOKIES.AUTO_SIGNIN });

  const onSignOut = useCallback(() => {
    Cookies.delete({ name: COOKIES.AUTO_SIGNIN });
    authorization.signOut();
  });

  const onNavigateToSignIn = useCallback(() => {
    history.push(APPLICATION_ROUTES.SIGN_IN + "?redirect=" + APPLICATION_ROUTES.PARTNERS_DONATION);
    scrollToTop();
  });

  if (authorization.isAuthenticated && authorization[`error${ACTIONS.AUTHORIZATION.AUTHENTICATE_PASSWORD}`] != "") {
    password.errorOfOriginal = t("layout.password_confirmation_failed");
  }

  if (isAutoSignin == 1) {
    checkout.setValue("reAuthenticated", true);
  }

  return (
    <div className="c-blue-dark">
      <main className={cs(classes.donationMain, "bgc-white")}>
        <div className={classes.donationBanner}>
          <div className={cs(classes.greenClipWrap)}>
            <div className={cs(classes.greenClip)}>
              <p>ב&quot;ה</p>
            </div>
          </div>
          <div className={classes.donationSliderWrap}>
            <div className={classes.donationSlider}>
              <span className={trackdonation.data.collected_percentage >= 100 ? classes.vbHidden : ''}>100%</span>
              <span className={trackdonation.data.collected_percentage >= 75 ? classes.vbHidden : ''}>75%</span>
              <span className={trackdonation.data.collected_percentage >= 50 ? classes.vbHidden : ''}>50%</span>
              <span className={trackdonation.data.collected_percentage >= 25 ? classes.vbHidden : ''}>25%</span>
              <span style={{ height: `${parseInt(trackdonation.data.collected_percentage)}%` }} className={classes.donationProgress}>{parseInt(trackdonation.data.collected_percentage)}%</span>
            </div>
          </div>
          <div className={cs(classes.donationImageMain)} >
            <div className={cs(classes.donationImageWrap)} >
              <div style={{ height: `${parseInt(trackdonation.data.collected_percentage)}%`, backgroundImage: 'url("/donation-phone-org.png")', textAlign: "center" }}
                className={cs(classes.donationImageOrg)}></div>
              <img src={donationMobile} alt="My Maor" className={cs(classes.donationGrayImg)} draggable={false} />
            </div>
          </div>
          <div className={cs(classes.donationContent)}>
            <h1 className={classes.donationHeading}>
              Maor app <span>is almost here...</span>
            </h1>
            <p>
              <span className={cs(classes.fundAmount, "fw-700 d-block")}>{trackdonation.getFormmatedAmount(trackdonation.data.collected)}</span>
              <span> out of {trackdonation.getFormmatedAmount(trackdonation.data.funds_target)} was raised!</span>
            </p>
          </div>
          <div className={cs(classes.whiteClipWrap)}>
            <div className={cs(classes.whiteClip)}></div>
          </div>
          <img src={donationShadowTop} alt="My Maor" className={cs(classes.donationShadowLine)} draggable={false} />
          <img src={donationShadowTop} alt="My Maor" className={cs(classes.donationShadowTop)} draggable={false} />
        </div>
        {trackdonation.data.records.length > 0 && (
          <>
            <div
              style={{ backgroundImage: 'url("/list-slider-bg.png")', textAlign: "center" }}
              className={cs(classes.donationListSliderWrap)}
            >
              <div className={cs(classes.donationListSlider)}>
                <ul>
                  {trackdonation.data.records.map((record) => {
                    let nameArr = [];
                    if (record.first_name != '') {
                      nameArr.push(capitalize(record.first_name));
                    }
                    if (record.last_name != '') {
                      nameArr.push(capitalize(record.last_name));
                    }
                    let name = nameArr.join(" ");
                    return (
                      <li key={record.id}>
                        <strong>{name} </strong>{" "}
                        <span className="ml-01">{trackdonation.getFormmatedAmount(record.amount)}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className={cs(classes.donationDivider)}></div>
          </>
        )}

        <form className="pb-08 pr-03 pl-03 bgc-white" autoComplete="off">
          <div
            className={cs(classes.group, {
              [classes.disabled]: isSelectedCustomAmount,
            })}
            hidden
          >
            <div className="d-flex jc-end mb-02">
              {authorization[`isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`] && <Loader />}
              {!authorization.isAuthenticated && !authorization[`isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`] && (
                <Button color="ghost" size="xs" onClick={onNavigateToSignIn}>
                  {t("layout.sign_in")}
                </Button>
              )}
              {authorization.isAuthenticated && (
                <p>
                  <span className={cs("heading-m fw-400", classes.loggedInText)}>
                    {t("layout.logged_in_as")}
                    <strong>{authorization.lastName} </strong> {t("layout.family").toLowerCase()}
                  </span>
                  <a className={classes.logoutLink} onClick={onSignOut} href="#">
                    {t("layout.log_out")}
                  </a>
                </p>
              )}
            </div>
            <h3 className="heading-m fw-400 ta-center">{t("pages.partners.donation.titles.2")}</h3>
            <div className={cs(classes.list, "mt-04")}>
              <Offers
                items={offer.options}
                unselected={isSelectedCustomAmount}
                value={offer.value}
                onChange={offer.onChange}
              />
            </div>
            {hasDelivery && (
              <div className="mt-08">
                <h3 className="heading-m c-blue">
                  {t("pages.partners.donation.titles.4")}{" "}
                  <span className="heading-s fw-400 c-red">
                    <Trans components={{ a: <b /> }} i18nKey="pages.partners.donation.texts.7" />
                  </span>
                </h3>
                <ul className={cs(classes.list, classes.address, "mt-04")}>
                  <li className={cs(classes.item, classes.street)}>
                    <PlainInput
                      error={street.error}
                      id="partners-order-form-street"
                      label={street.label}
                      stretched
                      value={street.value}
                      onChange={street.onChange}
                    />
                  </li>
                  <li className={cs(classes.item, classes.apartment)}>
                    <PlainInput
                      error={apartment.error}
                      id="partners-order-form-apartment"
                      label={apartment.label}
                      stretched
                      value={apartment.value}
                      onChange={apartment.onChange}
                    />
                  </li>
                  <li className={cs(classes.item, classes.city)}>
                    <PlainInput
                      error={city.error}
                      id="partners-order-form-city"
                      label={city.label}
                      stretched
                      value={city.value}
                      onChange={city.onChange}
                    />
                  </li>
                  <li className={cs(classes.item, classes.state)}>
                    <PlainInput
                      error={state.error}
                      id="partners-order-form-state"
                      label={state.label}
                      stretched
                      value={state.value}
                      onChange={state.onChange}
                    />
                  </li>
                  <li className={cs(classes.item, classes.zip)}>
                    <PlainInput
                      error={zip.error}
                      id="partners-order-form-zip"
                      label={zip.label}
                      stretched
                      value={zip.value}
                      onChange={zip.onChange}
                    />
                  </li>
                  <li className={cs(classes.item, classes.country)}>
                    <Select
                      label={country.label}
                      options={country.options}
                      stretched
                      value={country.value}
                      onChange={country.onChange}
                    />
                  </li>
                </ul>
                <div className="text-l mt-04 fw-700 ta-center">
                  {t("pages.partners.donation.texts.6", {
                    value: 60,
                  })}
                </div>
              </div>
            )}
          </div>

          <div className={classes.group}>
            {authorization.isAuthenticated && (
                <p className="ta-center pt-03 heading-s">
                  <span className={cs("fw-400", classes.loggedInText)}>
                    {t("layout.logged_in_as")}
                    <strong>{authorization.lastName} </strong> {t("layout.family").toLowerCase()}
                  </span>
                  <a className={classes.logoutLink} onClick={onSignOut} href="#">
                    {t("layout.log_out")}
                  </a>
                </p>
              )}

            <h3 className="heading-m fw-400 ta-center mb-07 mt-07">{t("pages.partners.donation.titles.2")}</h3>

            <div className="mr-auto ml-auto">
              <PlainInput
                customAutoComplete={false}
                error={customAmount.error}
                id="donation-amount"
                label={customAmount.label}
                value={customAmount.value}
                stretched
                onChange={customAmount.onChange}
              />
            </div>
          </div>
          <hr className={cs(classes.boundary, "mt-08 mb-08")} />
          {authorization[`isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`] ? (
            <div className="mb-06">
              <Loader />
            </div>
          ) : (
            showPersonal && (
              <ul className={cs(classes.personal, classes.list)}>
                <li className={cs(classes.item, classes.firstName)}>
                  <PlainInput
                    // customAutoComplete={true}
                    error={firstName.error}
                    id="donation-first-name"
                    label={firstName.label}
                    value={firstName.value}
                    stretched
                    onChange={firstName.onChange}
                  />
                </li>
                <li className={cs(classes.item, classes.lastName)}>
                  <PlainInput
                    // customAutoComplete={true}
                    error={lastName.error}
                    id="donation-last-name"
                    label={lastName.label}
                    value={lastName.value}
                    onChange={lastName.onChange}
                    stretched
                  />
                </li>
                <li className={cs(classes.item, classes.email)}>
                  <PlainInput
                    // customAutoComplete={true}
                    error={email.errorOfOriginal}
                    id="donation-email"
                    label={email.labelOfOriginal}
                    stretched
                    value={email.valueOfOriginal}
                    onChange={email.onChangeOriginal}
                  />
                </li>

                <li className={cs(classes.item, classes.createAccount)}>
                  <Checkbox {...createAccount}>Create Account?</Checkbox>
                </li>
                {createAccount.checked ? (
                  <>
                    <li className={cs(classes.item, classes.password, "mb-05")}>
                      <PasswordInput
                        error={password.errorOfOriginal}
                        id="sign-up-form-password"
                        label={password.labelOfOriginal}
                        value={password.valueOfOriginal}
                        onChange={password.onChangeOriginal}
                        stretched
                      />
                    </li>
                    <li className={cs(classes.item, classes.confirmPassword, "mb-05")}>
                      <PasswordInput
                        error={password.errorOfConfirm}
                        id="sign-up-form-password-repeat"
                        label={password.labelOfConfirm}
                        value={password.valueOfConfirm}
                        onChange={password.onChangeConfirm}
                        stretched
                      />
                    </li>
                  </>
                ) : null}
              </ul>
            )
          )}
          {cart[`error${ACTIONS.CART.ADD}`] &&
            cart[`error${ACTIONS.CART.ADD}`] == 409 &&
            !authorization.isAuthenticated && (
              <span className={classes.error}>
                {cart[`setError`](`error${ACTIONS.CART.ADD}`, "")}
                There is already an account linked with this email, please{" "}
                <Link
                  to={`${APPLICATION_ROUTES.SIGN_IN}?redirect=${encodeURIComponent(
                    `${APPLICATION_ROUTES.PARTNERS_ORDER}?type=${type}&login=true`
                  )}`}
                >
                  log in
                </Link>
              </span>
            )}
          {authorization.isAuthenticated && isAutoSignin != 1 ? (
            <ul className={cs(classes.list)}>
              <li className={cs(classes.item, classes.password, "mb-05", "m-auto", "w-100")}>
                <PasswordInput
                  error={password.errorOfOriginal}
                  id="sign-up-form-password"
                  label={password.labelOfOriginal}
                  value={password.valueOfOriginal}
                  stretched
                  onChange={password.onChangeOriginal}
                />
                <small>{t("layout.purcahse_password_confirmatin")}</small>
              </li>
              <li className={cs(classes.item, classes.anonymous, "m-auto", "mb-05")}>
                <Checkbox {...anonymous}>{anonymous.label}</Checkbox>
              </li>
            </ul>
          ) : (
            <ul className={cs(classes.list)}>
              <li className={cs(classes.item, classes.anonymous)}>
                <Checkbox {...anonymous}>{anonymous.label}</Checkbox>
              </li>
            </ul>
          )}

          <div className="d-flex df-column jc-center ai-center" style={{ minHeight: "52px" }}>
            {isLoading ? (
              <Loader />
            ) : (
              <Button disabled={isNotValid} type="submit" onClick={onSubmit}>
                {t("pages.partners.donation.labels.1")}
              </Button>
            )}
          </div>
        </form>
        <div className={cs(classes.donationFooter)}>
          <div className={cs(classes.clipFooter)}>
            <div className={cs(classes.donationImage)}>
              <Link to={APPLICATION_ROUTES.MY_MAOR_LIBRARY}>
                <img src={maorLogo} alt="My Maor" draggable={false} />
              </Link>
              <Link to={APPLICATION_ROUTES.DAILY_REBBE_VIDEO_LIBRARY}>
                <img src={maorFooterLogo} alt="Daily Rebbe Videos" draggable={false} />
              </Link>
            </div>
            <div className={cs(classes.donationImage)}>
              <img src={appleStore} alt="Android App" draggable={false} />
              <img src={googlePlay} alt="iOS App" draggable={false} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
});

export default React.memo(PartnersMobileDonation);
