import { observer } from "mobx-react-lite";
import React from "react";

import cs from "classnames";
import classes from "./Testimonial.module.scss";
import TestimonialSlider from "./components/TestimonialSlider";
import Carousel from "./components/Carousel/Carousel";

const Testimonial = observer(() => {
  return (
    <div className={cs(classes.testimonialContainer, "wrapper-m")}>
      <div className={cs(classes.headingContainer)}>
        <h3>Testimonials</h3>
        <p>
          Maor is the Hiskashrus Resource Trusted by Parents, Mechanchim and
          Shluchim Worldwide{" "}
        </p>
      </div>
      <div className={cs(classes.carousel)}>
        <Carousel />
      </div>
      <TestimonialSlider />
    </div>
  );
});

export default React.memo(Testimonial);
