import React, { useState } from "react";
import cs from "classnames";
import classes from "./Winners.module.scss";

import { observer } from "mobx-react-lite";
import useWinners from "./useWinners";
import trophy from "../../../../../assets/images/trophy.png";
import sms from "../../../../../assets/images/sms.png";
import PropTypes from "prop-types";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import Icon from "../../../../atoms/Icon";

const Winners = observer(({ layout, additionalClass, coloringPages }) => {
  const winners = useWinners();
  console.log("winners library", winners);
  const [isAnswersActive, setIsAnswersActive] = useState(false);
  const [isWinnerActive, setIsWinnersActive] = useState(false);

  console.log(isAnswersActive, setIsAnswersActive);

  const capitalize = (value) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  let lastWinnerName = [];
  if (winners.raffle) {
    if (winners.raffle.data && winners.raffle.data.winner) {
      lastWinnerName.push(capitalize(winners.raffle.data.winner.first_name));
      lastWinnerName.push(capitalize(winners.raffle.data.winner.last_name));
      lastWinnerName = lastWinnerName.join(" ");
    }
  }

  let lastWinnerAddress = [];
  if (winners.raffle) {
    if (winners.raffle.data && winners.raffle.data.winner) {
      if (winners.raffle.data.winner.state == undefined) {
        lastWinnerAddress.push(capitalize(winners.raffle.data.winner.city));
        lastWinnerAddress.push(capitalize(winners.raffle.data.winner.country));
        lastWinnerAddress = lastWinnerAddress.join(", ");
      } else {
        lastWinnerAddress.push(capitalize(winners.raffle.data.winner.city));
        if (winners.raffle.data.winner.state.length <= 2) {
          lastWinnerAddress.push(
            winners.raffle.data.winner.state.toUpperCase()
          );
        } else {
          lastWinnerAddress.push(capitalize(winners.raffle.data.winner.state));
        }
        lastWinnerAddress = lastWinnerAddress.join(", ");
      }
    }
  }

  let winnerName = [];
  if (winners.maor && winners.maor.first_name) {
    winnerName = capitalize(winners.maor.first_name);
    winnerName += " " + capitalize(winners.maor.last_name);
    winnerName += " - " + winners.maor.score;
  }

  let winnerAddress = [];
  if (winners.maor && winners.maor.first_name) {
    if (winners.maor.state == undefined) {
      winnerAddress.push(capitalize(winners.maor.city));
      winnerAddress.push(capitalize(winners.maor.country));
      winnerAddress = winnerAddress.join(", ");
    } else {
      winnerAddress.push(capitalize(winners.maor.city));
      if (winners.maor.state.length <= 2) {
        winnerAddress.push(winners.maor.state.toUpperCase());
      } else {
        winnerAddress.push(capitalize(winners.maor.state));
      }
      winnerAddress = winnerAddress.join(", ");
    }
  }

  const removeColoringFilter = () => {
    if (coloringPages.value) {
      coloringPages.onChange();
    }
  }

  return (
    <div className={cs(classes[layout], classes[additionalClass])}>
      <div
        className={cs(
          classes.winnerList,
          isMobile ? classes.mobileWinnerWrapper : ""
        )}
      >
        <div
          className={cs(
            classes.raffleWinner,
            isMobile ? classes.hoverItems : ""
          )}
        >
          {!isMobile ? (
            <BrowserView>
              <img src={trophy} />
            </BrowserView>
          ) : null}

          {isMobile ? (
            <MobileView>
              <div className={cs(classes.mobileFooterItems)} onClick={removeColoringFilter} onMouseEnter={() => setIsWinnersActive(true)} onMouseLeave={() => setIsWinnersActive(false)}>
                <div className={cs(isWinnerActive && classes.hoverHideImage)}>
                  <Icon name="winners" />
                </div>
                <div className={cs(isWinnerActive ? classes.hoverImage : classes.hoverHideImage)}>
                  <Icon
                    name="bluewinners"
                  />
                </div>
                <span className={cs(classes.title, "c-orange")}>Winners</span>
              </div>
            </MobileView>
          ) : (
            <BrowserView>
              <div className={cs(classes.trophyContent)}>
                <span className={cs(classes.title, "c-orange fw-700")}>
                  WINNER
                </span>
                <br />
                <span className={cs(classes.raffle, "c-orange fw-700")}>
                  RAFFLE #{winners.raffle ? winners.raffle.data.raffle_code : ""}
                </span>
              </div>
            </BrowserView>
          )}

          {/* Details */}
          <div
            className={cs(
              classes.details,
              isMobile ? classes.mobileDetails : ""
            )}
          >
            {winners.raffle ? (
              <>
                <div className={cs(classes.trophyContentMobile)}>
                  <span className={cs(classes.title, "c-orange fw-700")}>
                    WINNER
                  </span>
                  <br />
                  <span className={cs(classes.raffle, "c-orange fw-700")}>
                    RAFFLE #{winners.raffle ? winners.raffle.data.raffle_code : ""}
                  </span>
                </div>
                {winners.raffle ? (
                  <>
                    <div className={cs(classes.name, "fw-700")}>
                      {lastWinnerName}
                    </div>
                    <div className={cs(classes.address)}>
                      {lastWinnerAddress}
                    </div>
                  </>
                ) : (
                  <div className={cs(classes.name)}>No Winner yet</div>
                )}
              </>
            ) : (
              <div className={cs(classes.name)}>No Winner yet</div>
            )}
          </div>
        </div>
        <div
          className={cs(classes.maorWinner, isMobile ? classes.hoverItems : "")}
        >
          {!isMobile ? (
            <BrowserView>
              <img src={sms} />
            </BrowserView>
          ) : null}

          {isMobile ? (
            <MobileView>
              <div className={cs(classes.mobileFooterItems)} onClick={removeColoringFilter} onMouseEnter={() => setIsAnswersActive(true)} onMouseLeave={() => setIsAnswersActive(false)} >
                <div className={cs(isAnswersActive && classes.hoverHideImage)}>
                  <Icon name="answers" />
                </div>
                <div className={cs(isAnswersActive ? classes.hoverImage : classes.hoverHideImage)}>
                  <Icon
                    name="blueanswers"
                  />
                </div>
                <span className={cs(classes.title, "c-orange")}>Answers</span>
              </div>
            </MobileView>
          ) : (
            <BrowserView>
              <div className={cs(classes.answerContent)}>
                <span className={cs(classes.raffle, "c-orange fw-700")}>
                  MOST ANSWERS
                </span>
                <br />
                <span className={cs(classes.title, "c-orange fw-700")}>
                  THIS WEEK
                </span>
              </div>
            </BrowserView>
          )}

          {/* Address */}
          <div
            className={cs(
              classes.address,
              isMobile ? classes.mobileAddress : ""
            )}
          >
            {winners.maor ? (
              <>
                <div className={cs(classes.answerContentMobile)}>
                  <span className={cs(classes.title, "c-orange fw-700")}>
                    ANSWERED
                  </span>
                  <br />
                  <span className={cs(classes.raffle, "c-orange fw-700")}>
                    THE MOST Q.
                  </span>
                </div>
                {winners.maor.first_name ? (
                  <>
                    <div className={cs(classes.name, "fw-700")}>
                      {winnerName}
                    </div>
                    <div>{winnerAddress}</div>
                  </>
                ) : (
                  <div className={cs(classes.name)}>
                    No questions answered yet
                  </div>
                )}
              </>
            ) : (
              <div className={cs(classes.name)}>No questions answered yet</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

Winners.propTypes = {
  layout: PropTypes.string,
  additionalClass: PropTypes.string,
};

Winners.defaultProps = {
  layout: "horizontal",
};

export default React.memo(Winners);
