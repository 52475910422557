import { useCallback, useState } from "react";

const useCheckbox = ({ callback, checked: initialState = false, label = "" } = {}) => {
  const [checked, setChecked] = useState(initialState);

  const onChange = useCallback(() => {
    setChecked((checked) => {
      callback && callback({ checked: !checked });
      return !checked;
    });
  }, []);

  return {
    checked: checked,
    setChecked: setChecked,
    label: label,
    onChange: onChange,
  };
};

export default useCheckbox;
