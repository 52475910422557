import { makeAutoObservable } from "mobx";
import { LibraryAPI } from "../../api";
import { PROJECTS } from "../../constants";
import { ACTIONS } from "./utilities";

const libraryAPI = new LibraryAPI();

const Library = class {
  constructor({ store }) {
    const actions = Object.values(ACTIONS);

    for (let i = 0; i < actions.length; i++) {
      const ACTION = actions[i];

      this[`isLoading${ACTION}`] = false;
      this[`error${ACTION}`] = "";
    }

    this.itemsOfDailyRebbeVideo = [];
    this.itemsOfMyMaor = [];
    this.itemsOfRelated = [];
    this.itemsOfUpcomingHoliday = [];
    this.itemsOfIlluminate = [];
    this.videoQuestion = null;

    this.totalItemsOfDailyRebbeVideo = [];
    this.totalItemsOfMyMaor = [];
    this.totalItemsOfRelated = [];
    this.totalItemsOfUpcomingHoliday = [];
    this.totalItemsOfIlluminate = [];

    this.nextPageOfDailyRebbeVideo = 1;
    this.nextPageOfMyMaor = 1;
    this.nextPageOfRelated = 1;
    this.nextPageOfUpcomingHoliday = 1;
    this.nextPageOfIlluminate = 1;
    this.tags = [];

    this.searchingOptions = [];
    this[`isLoading${ACTIONS.SEARCHING_OPTIONS}`] = false;
    this[`error${ACTIONS.SEARCHING_OPTIONS}`] = "";
    this[`freeAccess`] = false;

    this.video = [];
    this.videoRequest = {};
    // this[`isLoading${ACTIONS.GET_VIDEO}`] = false;
    // this[`error${ACTIONS.GET_VIDEO}`] = "";

    this.requestPayloadDailyRebbeVideo = {
      search: "",
      order: "",
      videoType: "",
      tags: [],
    };
    this.requestPayloadMyMaor = {
      search: "",
      order: "",
      videoType: "",
      tags: [],
    };
    this.requestPayloadRelated = {
      search: "",
      order: "",
      videoType: "",
      tags: [],
    };
    this.requestPayloadUpcomingHoliday = {
      search: "",
      order: "",
      videoType: "",
      tags: [],
    };
    this.requestPayloadIlluminate = {
      search: "",
      order: "",
      videoType: "",
      tags: [],
    };
    this.filterData = {
      categories: {
        data: [],
        isLoading: true,
        isError: false,
      },
      tags: {
        data: [],
        isLoading: true,
        isError: false,
      },
      holidays: {
        data: [],
        isLoading: true,
        isError: false,
      },
    };
    this.store = store;

    makeAutoObservable(this);
  }

  get hasItemsOfDailyRebbeVideo() {
    return !!this.itemsOfDailyRebbeVideo.length;
  }

  get hasItemsOfMyMaor() {
    return !!this.itemsOfMyMaor.length;
  }

  get hasItemsOfRelated() {
    return !!this.itemsOfRelated.length;
  }

  get hasItemsOfUpcomingHoliday() {
    return !!this.itemsOfUpcomingHoliday.length;
  }

  get hasItemsOfIlluminate() {
    return !!this.itemsOfIlluminate.length;
  }

  get hasNextPageOfDailyRebbeVideo() {
    return !!this.nextPageOfDailyRebbeVideo;
  }

  get hasNextPageOfMyMaor() {
    return !!this.nextPageOfMyMaor;
  }

  get hasNextPageOfRelated() {
    return !!this.nextPageOfRelated;
  }

  get hasNextPageOfUpcomingHoliday() {
    return !!this.nextPageOfUpcomingHoliday;
  }

  get hasNextPageOfIlluminate() {
    return !!this.nextPageOfIlluminate;
  }

  getSearchingOptions(query) {
    libraryAPI
      .getSearchingOptions(query)
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then((data) => {
        this.setSearchingOptions(data);
        this.setIsLoading(ACTIONS.SEARCHING_OPTIONS, false);
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.SEARCHING_OPTIONS, message);
        this.setIsLoading(ACTIONS.SEARCHING_OPTIONS, false);
      });
  }

  getCategories() {
    libraryAPI
      .getCategories()
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then((data) => {
        this.setFilterData(ACTIONS.FILTER_DATA.CATEGORIES, data);
        this.setFilterDataIsLoading(ACTIONS.FILTER_DATA.CATEGORIES, false);
      })
      .catch(({ message }) => {
        this.setFilterDataIsError(ACTIONS.FILTER_DATA.CATEGORIES, true);
        this.setFilterDataIsLoading(ACTIONS.FILTER_DATA.CATEGORIES, false);
        console.error(message);
      });
  }

  getTags() {
    this.setFilterDataIsLoading(ACTIONS.FILTER_DATA.TAGS, true);
    libraryAPI
      .getTags({
        next_page:
          this.filterData.tags.data.next_page == undefined
            ? 1
            : this.filterData.tags.data.next_page,
      })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then((data) => {
        this.setTagsData(ACTIONS.FILTER_DATA.TAGS, data);
        this.setFilterDataIsLoading(ACTIONS.FILTER_DATA.TAGS, false);
      })
      .catch(({ message }) => {
        this.setFilterDataIsError(ACTIONS.FILTER_DATA.TAGS, true);
        this.setFilterDataIsLoading(ACTIONS.FILTER_DATA.TAGS, false);
        console.error(message);
      });
  }

  getTodayHolidays() {
    libraryAPI
      .getTodayHolidays()
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then((data) => {
        this.setFilterData(ACTIONS.FILTER_DATA.HOLIDAYS, data);
        this.setFilterDataIsLoading(ACTIONS.FILTER_DATA.HOLIDAYS, false);
      })
      .catch(({ message }) => {
        this.setFilterDataIsError(ACTIONS.FILTER_DATA.HOLIDAYS, true);
        this.setFilterDataIsLoading(ACTIONS.FILTER_DATA.HOLIDAYS, false);
        console.error(message);
      });
  }

  resetPageMyMaor() {
    this.setValue("nextPageOfMyMaor", 1);
  }

  resetPageRelated() {
    this.setValue("nextPageOfRelated", 1);
  }

  resetPageUpcomingHoliday() {
    this.setValue("nextPageOfUpcomingHoliday", 1);
  }

  resetPageDailyRebbeVideo() {
    this.setValue("nextPageOfDailyRebbeVideo", 1);
  }

  resetPageIlluminate() {
    this.setValue("nextPageOfIlluminate", 1);
  }

  setRequestPayloadMyMaor(payload) {
    this.setValue("requestPayloadMyMaor", payload);
  }

  setRequestPayloadRelated(payload) {
    this.setValue("requestPayloadRelated", payload);
  }

  setRequestPayloadUpcomingHoliday(payload) {
    this.setValue("requestPayloadUpcomingHoliday", payload);
  }

  setRequestPayloadDailyRebbeVideo(payload) {
    this.setValue("requestPayloadDailyRebbeVideo", payload);
  }

  setRequestPayloadIlluminate(payload) {
    this.setValue("requestPayloadIlluminate", payload);
  }

  setVideoRequest(payload) {
    this.setValue("videoRequest", payload);
  }

  setIsLoading(name, value) {
    this[`isLoading${name}`] = value;
  }

  setError(name, value) {
    this[`error${name}`] = value;
  }

  setSuccess(name, value) {
    this[`success${name}`] = value;
  }

  setValue(name, value) {
    this[name] = value;
  }

  setFreeAccess(value) {
    this[`freeAccess`] = value;
  }

  setFilterDataIsLoading(name, value) {
    this.filterData[name].isLoading = value;
  }

  setSearchingOptions(value) {
    window.searchValue = value;
    this.searchingOptions = Object.values(value);
  }

  setTagsData(name, value) {
    this.tags = [...this.tags, ...value.data];
    value.data = this.tags;
    this.filterData[name].data = value;
  }

  setFilterDataIsError(name, value) {
    this.filterData[name].isError = value;
  }

  setFilterData(name, value) {
    this.filterData[name].data = value;
  }

  addItems(name, items) {
    console.log(name, items, "updated items");
    if (items && items.length) {
      let updatedItems = [];
      if (this[`nextPageOf${name}`] == 1) {
        updatedItems = [...items];
      } else {
        updatedItems = [...this[`itemsOf${name}`], ...items];

        const uniqueValuesSet = new Set();
        updatedItems = updatedItems.filter((obj) => {
          // check if name property value is already in the set
          const isPresentInSet = uniqueValuesSet.has(obj.id);
          // add name property value to Set
          uniqueValuesSet.add(obj.id);
          // return the negated value of
          // isPresentInSet variable
          return !isPresentInSet;
        });
      }
      this[`itemsOf${name}`] = updatedItems;
    } else {
      if (this[`nextPageOf${name}`] == 1) {
        this[`itemsOf${name}`] = [];
      }
    }
  }

  // addItemsNew(name, items) {
  //   if (items) {
  //     this[`itemsOf${name}`] = items;
  //   } else {
  //     this[`itemsOf${name}`] = [];
  //   }
  // }

  addItemsNew(name, newData, section) {
    if (!this[`itemsOf${name}`] || section == "all") {
      // If there's no existing data, initialize it with newData
      return (this[`itemsOf${name}`] = newData);
    } else {
      // If there's existing data, merge/concatenate the new data
      const existingData = this[`itemsOf${name}`];

      for (const [key, value] of Object.entries(newData)) {
        if (value && value.items && Array.isArray(value.items)) {
          // If the key's value has an items array, concatenate the new items
          if (!existingData[key]) {
            existingData[key] = { items: [] };
          }
          if (!Array.isArray(existingData[key].items)) {
            existingData[key].items = [];
          }
          existingData[key].items = existingData[key].items.concat(value.items);
          existingData[key].current_page = value.current_page;
          existingData[key].next_page = value.next_page;
          existingData[key].prev_page = value.prev_page;
        } else if (Array.isArray(value)) {
          // If the value itself is an array, concatenate the arrays
          if (!Array.isArray(existingData[key])) {
            existingData[key] = [];
          }
          existingData[key] = existingData[key].concat(value);
        } else if (typeof value === "object" && value !== null) {
          // If the value is an object, merge the objects
          if (
            typeof existingData[key] !== "object" ||
            existingData[key] === null
          ) {
            existingData[key] = {};
          }
          existingData[key] = {
            ...existingData[key],
            ...value,
          };
        } else {
          // If the value is neither an array nor an object, set it directly
          existingData[key] = value;
        }
      }

      this[`itemsOf${name}`] = existingData;
    }
  }

  setTotalItems(name, total) {
    this[`totalItemsOf${name}`] = total;
  }

  removeItems(name) {
    this[`itemsOf${name}`] = [];
  }

  setVideo(video) {
    this.video = video;
  }

  getDailyRebbeVideo() {
    this.setIsLoading(ACTIONS.GET_DAILY_REBBE_VIDEO, true);
    if (this.nextPageOfDailyRebbeVideo == 1) {
      this.removeItems("DailyRebbeVideo");
      this.setTotalItems("DailyRebbeVideo", 0);
    }

    libraryAPI
      .get({
        page: this.nextPageOfDailyRebbeVideo,
        project: PROJECTS.DAILY_REBBE_VIDEO.ID,
        search: this.requestPayloadDailyRebbeVideo.search ?? "",
        order: this.requestPayloadDailyRebbeVideo.order ?? "",
        videoType: this.requestPayloadDailyRebbeVideo.videoType ?? "",
        tags: this.requestPayloadDailyRebbeVideo.tags ?? [],
        holidayId: this.requestPayloadDailyRebbeVideo.holidayId ?? "",
        limitRecord: this.requestPayloadDailyRebbeVideo.limitRecord ?? 8,
        coloringPages:
          this.requestPayloadDailyRebbeVideo.coloringPages ?? false,
      })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        this.setFreeAccess(data.free_access);
        this.addItems("DailyRebbeVideo", data.items);
        this.setTotalItems("DailyRebbeVideo", data.total_items);
        this.setValue("nextPageOfDailyRebbeVideo", data.next_page);
        this.setIsLoading(ACTIONS.GET_DAILY_REBBE_VIDEO, false);
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.GET_DAILY_REBBE_VIDEO, message);
        this.setIsLoading(ACTIONS.GET_DAILY_REBBE_VIDEO, false);
      });
  }

  // getVideo({ projectType = "MyMaor" }) {
  getVideo() {
    console.log("Video Loading: 1", ACTIONS.GET_VIDEO);
    this.setIsLoading(ACTIONS.GET_VIDEO, true);
    libraryAPI
      // .getVideo({
      //   project_type: projectType,
      // })
      .getVideo(this.videoRequest)
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then((data) => {
        if (data.data.items.length == 0) {
          this.setVideo(null);
          this.setIsLoading(ACTIONS.GET_VIDEO, false);
        } else {
          let video = data.data.items[0];
          video["free"] = data.free;
          video["count"] = data.data.count;
          this.setVideo(video);
          this.setIsLoading(ACTIONS.GET_VIDEO, false);
          console.log("Datee", video);
        }
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.GET_VIDEO, message);
        this.setIsLoading(ACTIONS.GET_VIDEO, false);
      });
  }

  getMyMaor() {
    this.setIsLoading(ACTIONS.GET_MY_MAOR, true);

    if (this.nextPageOfMyMaor == 1) {
      this.removeItems("MyMaor");
      this.setTotalItems("MyMaor", 0);
    }

    libraryAPI
      .get({
        page: this.nextPageOfMyMaor,
        project: PROJECTS.MY_MAOR.ID,
        search: this.requestPayloadMyMaor.search ?? "",
        order: this.requestPayloadMyMaor.order ?? "",
        videoType: this.requestPayloadMyMaor.videoType ?? "",
        tags: this.requestPayloadMyMaor.tags ?? [],
        holidayId: this.requestPayloadMyMaor.holidayId ?? "",
        limitRecord: this.requestPayloadMyMaor.limitRecord ?? 8,
        coloringPages: this.requestPayloadMyMaor.coloringPages ?? false,
      })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        this.setFreeAccess(data.free_access);
        this.addItems("MyMaor", data.items);
        console.log("Items1", data.items);
        this.setTotalItems("MyMaor", data.total_items);
        this.setValue("nextPageOfMyMaor", data.next_page);
        this.setIsLoading(ACTIONS.GET_MY_MAOR, false);
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.GET_MY_MAOR, message);
        this.setIsLoading(ACTIONS.GET_MY_MAOR, false);
      });
  }

  getIlluminate() {
    this.setIsLoading(ACTIONS.GET_ILLUMINATE, true);

    if (this.nextPageOfIlluminate == 1) {
      this.removeItems("Illuminate");
      this.setTotalItems("Illuminate", 0);
    }

    libraryAPI
      .get({
        page: this.nextPageOfIlluminate,
        project: PROJECTS.ILLUMINATE.ID,
        search: this.requestPayloadIlluminate.search ?? "",
        order: this.requestPayloadIlluminate.order ?? "",
        videoType: this.requestPayloadIlluminate.videoType ?? "",
        tags: this.requestPayloadIlluminate.tags ?? [],
        holidayId: this.requestPayloadIlluminate.holidayId ?? "",
        limitRecord: this.requestPayloadIlluminate.limitRecord ?? false,
        coloringPages: this.requestPayloadIlluminate.coloringPages ?? false,
      })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        this.setFreeAccess(data.free_access);
        this.addItems("Illuminate", data.items);
        this.setTotalItems("Illuminate", data.total_items);
        this.setValue("nextPageOfIlluminate", data.next_page);
        this.setIsLoading(ACTIONS.GET_ILLUMINATE, false);
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.GET_ILLUMINATE, message);
        this.setIsLoading(ACTIONS.GET_ILLUMINATE, false);
      });
  }

  getCombined({
    categoryId,
    holidayId,
    section,
    page,
    categoryName = "",
    action,
    videoId,
  }) {
    if (section == "all") {
      this.setIsLoading(ACTIONS.ALL_SECTIONS, true);
    }
    this.setIsLoading(ACTIONS.GET_COMBINED, true);
    this.setIsLoading(categoryName, true);

    libraryAPI
      .getCombined({
        project: PROJECTS.MY_MAOR.ID,
        section,
        categoryId,
        holidayId,
        page,
        action,
        videoId,
      })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        console.log(data, "#1data");
        this.addItemsNew("Combined", data, section);

        this.setIsLoading(categoryName, false);
        this.setIsLoading(ACTIONS.GET_COMBINED, false);
        this.setIsLoading(ACTIONS.ALL_SECTIONS, false);
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.GET_COMBINED, message);
        this.setIsLoading(categoryName, false);
        this.setIsLoading(ACTIONS.GET_COMBINED, false);
        this.setIsLoading(ACTIONS.ALL_SECTIONS, false);
      });
  }

  getRelated() {
    this.setIsLoading(ACTIONS.GET_RELATED, true);

    if (this.nextPageOfRelated == 1) {
      this.removeItems("Related");
      this.setTotalItems("Related", 0);
    }

    libraryAPI
      .get({
        page: this.nextPageOfRelated,
        project: PROJECTS.MY_MAOR.ID,
        search: this.requestPayloadRelated.search ?? "",
        order: this.requestPayloadRelated.order ?? "",
        videoType: this.requestPayloadRelated.videoType ?? "",
        tags: this.requestPayloadRelated.tags ?? [],
        holidayId: this.requestPayloadRelated.holidayId ?? "",
        limitRecord: this.requestPayloadRelated.limitRecord ?? false,
        coloringPages: this.requestPayloadRelated.coloringPages ?? false,
      })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        this.setFreeAccess(data.free_access);
        this.addItems("Related", data.items);
        this.setTotalItems("Related", data.total_items);
        this.setValue("nextPageOfRelated", data.next_page);
        this.setIsLoading(ACTIONS.GET_RELATED, false);
        console.log(`itemsOfRelated #45`, data.items);
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.GET_RELATED, message);
        this.setIsLoading(ACTIONS.GET_RELATED, false);
      });
  }

  getUpcomingHoliday() {
    this.setIsLoading(ACTIONS.GET_UPCOMING_HOLIDAY, true);

    if (this.nextPageOfUpcomingHoliday == 1) {
      this.removeItems("UpcomingHoliday");
      this.setTotalItems("UpcomingHoliday", 0);
    }

    libraryAPI
      .get({
        page: this.nextPageOfUpcomingHoliday,
        project: PROJECTS.MY_MAOR.ID,
        search: this.requestPayloadUpcomingHoliday.search ?? "",
        order: this.requestPayloadUpcomingHoliday.order ?? "",
        videoType: this.requestPayloadUpcomingHoliday.videoType ?? "",
        tags: this.requestPayloadUpcomingHoliday.tags ?? [],
        holidayId: this.requestPayloadUpcomingHoliday.holidayId ?? "",
        limitRecord: this.requestPayloadUpcomingHoliday.limitRecord ?? false,
        coloringPages:
          this.requestPayloadUpcomingHoliday.coloringPages ?? false,
        upcomingHoliday: true,
      })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        this.setFreeAccess(data.free_access);
        this.addItems("UpcomingHoliday", data.items);
        this.setTotalItems("UpcomingHoliday", data.total_items);
        this.setValue("nextPageOfUpcomingHoliday", data.next_page);
        this.setIsLoading(ACTIONS.GET_UPCOMING_HOLIDAY, false);
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.GET_UPCOMING_HOLIDAY, message);
        this.setIsLoading(ACTIONS.GET_UPCOMING_HOLIDAY, false);
      });
  }

  saveVideoQuestionOption(request) {
    this.setSuccess(ACTIONS.SAVE_VIDEO_QUESTION_OPTION, false);
    this.setError(ACTIONS.SAVE_VIDEO_QUESTION_OPTION, false);
    this.setIsLoading(ACTIONS.SAVE_VIDEO_QUESTION_OPTION, true);

    libraryAPI
      .saveVideoQuestionOption(request)
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then((data) => {
        this.setValue("videoQuestion", data);
        this.setSuccess(ACTIONS.SAVE_VIDEO_QUESTION_OPTION, true);
        this.setIsLoading(ACTIONS.SAVE_VIDEO_QUESTION_OPTION, false);
      })
      .catch(() => {
        console.log("error");
        this.setIsLoading(ACTIONS.SAVE_VIDEO_QUESTION_OPTION, false);
        this.setError(ACTIONS.SAVE_VIDEO_QUESTION_OPTION, true);
      });
  }
};

export default Library;
