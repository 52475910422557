import { observer } from "mobx-react-lite";
import React from "react";

import cs from "classnames";
import classes from "./TestimonialSlider.module.scss";
import Slider from "../../../../../../organisms/Slider";
import { useState } from "react";
import Icon from "../../../../../../atoms/Icon";

const TestimonialSlider = observer(() => {
  const [slider, setSlider] = useState(null);
  const sliderRef = {
    instance: slider,
    set: setSlider,
  };

  let sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={cs(classes.sliderWrap)}>
      <div className={cs(classes.sliderContainer, "mt-09")}>
        <Slider {...sliderSettings} sliderRef={sliderRef}>
          <div className={cs(classes.slideLayout, "bgc-gold")}>
            <h5>
              “My kids feel and see that the Rebbe is also for them, <br/> not just
              adults.”
            </h5>
            <div className={cs(classes.info)}>
              <p>
                “My son often repeats the stories he hears in the videos. They
                are interactive and so full of toichen. Because of Maor, my kids
                feel and see that the Rebbe is also for them, not just adults.”
              </p>
            </div>
            <div className={cs(classes.slideBottom)}>
              <div className={cs(classes.slideAbout)}>
                <strong>Rochele Lustig</strong>
                <p>New Haven, CT</p>
              </div>
              <div className={classes.arrowNavigation}>
                <button
                  onClick={() => {
                    sliderRef.instance.slickPrev();
                  }}
                >
                  <Icon name="arrowleft" />
                </button>
                <button
                  onClick={() => {
                    sliderRef.instance.slickNext();
                  }}
                >
                  <Icon name="arrowright" />
                </button>
              </div>
            </div>
          </div>
          <div className={cs(classes.slideLayout, "bgc-gold")}>
            <h5>“This initiative was all because of that message on Maor.”</h5>
            <div className={cs(classes.info)}>
              <p>
                “I sponsored the DRV on the day of my birthday. <br /> In the
                clip, the Rebbe spoke about gathering people together to learn,
                hakhel. <br /> I took the message personally. BH, my mother and
                I arranged a monthly shiur in 30 buildings in Crown Heights.”
              </p>
            </div>
            <div className={cs(classes.slideBottom)}>
              <div className={cs(classes.slideAbout)}>
                <strong>Rivky Levy</strong>
                <p>Crown Heights, NY</p>
              </div>
              <div className={classes.arrowNavigation}>
                <button
                  onClick={() => {
                    sliderRef.instance.slickPrev();
                  }}
                >
                  <Icon name="arrowleft" />
                </button>
                <button
                  onClick={() => {
                    sliderRef.instance.slickNext();
                  }}
                >
                  <Icon name="arrowright" />
                </button>
              </div>
            </div>
          </div>
          <div className={cs(classes.slideLayout, "bgc-gold")}>
            <h5>“It keeps me so engaged while learning from the Rebbe.”</h5>
            <div className={cs(classes.info)}>
              <p>
                “I love the active illustrations in the Illuminated videos. It
                keeps me so engaged while learning from the Rebbe. Maor keeps on
                coming up with more and more unbelievable ways to connect!”
              </p>
            </div>
            <div className={cs(classes.slideBottom)}>
              <div className={cs(classes.slideAbout)}>
                <strong>Malka Solomon</strong>
                <p>Hillside, NJ</p>
              </div>
              <div className={classes.arrowNavigation}>
                <button
                  onClick={() => {
                    sliderRef.instance.slickPrev();
                  }}
                >
                  <Icon name="arrowleft" />
                </button>
                <button
                  onClick={() => {
                    sliderRef.instance.slickNext();
                  }}
                >
                  <Icon name="arrowright" />
                </button>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
});

export default React.memo(TestimonialSlider);
