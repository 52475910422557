import { observer } from "mobx-react-lite";
import React from "react";

import cs from "classnames";
import classes from "./Featured.module.scss";
import prade from "../../../../../assets/images/GreatParade.png";
import beis from "../../../../../assets/images/beis.png";
import newsletter from "../../../../../assets/images/newsletter.png";
import chabad from "../../../../../assets/images/chabad.png";
import hassidout from "../../../../../assets/images/hassidout.png";
import anash from "../../../../../assets/images/anash.png";
import Col from "../../../../../assets/images/col.png";

const Featured = observer(() => {
  return (
    <div className={cs(classes.container, "wrapper-m")}>
      <div className={cs(classes.headingContainer)}>
        <h3>As Featured In</h3>
        <div className={cs(classes.iconsContainer)}>
          <img src={newsletter}  />
          <img src={prade} />
          <img className={cs(classes.imgWidth)} src={beis} />
          <img className={cs(classes.imgWidth)} src={chabad} />
          <img src={hassidout}  className={cs(classes.hassidoutImageWidth)}  />
          <img src={Col} className={cs(classes.imgSmallWidth, classes.colImageWidth)} />
          <img src={anash} className={cs(classes.imgSmallWidth)} />

        </div>
      </div>
    </div>
  );
});

export default React.memo(Featured);
