import validate from "./validate";

const createState = ({ validatorsOfFirst, validatorsOfSecond ,emailValue }) => {
  const valueOfFirst = emailValue;
  const valueOfSecond = emailValue;
  const errors = validate({
    validatorsOfFirst,
    validatorsOfSecond,
    valueOfFirst,
    valueOfSecond,
  });

  return {
    errorsOfFirst: errors.first,
    errorsOfSecond: errors.second,
    touchedFirst: false,
    touchedSecond: false,
    valueOfFirst,
    valueOfSecond,
  };
};

export default createState;
