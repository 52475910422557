import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import { COLORS, SIZES } from "./utilities";
import classes from "./Pills.module.scss";

const Pills = ({ color, items, size, stretched, value, onChange }) => (
  <ul className={cs(classes.container, classes[color], classes[size], { [classes.stretched]: stretched })}>
    {items.map((item) => {
      const checked = item.value == value;

      return (
        <li key={item.id} className={classes.item}>
          <input
            data-filterlabel={item.label}
            checked={checked}
            className={classes.input}
            id={item.id}
            type="radio"
            value={item.value}
            onChange={onChange}
          />
          <label htmlFor={item.id} className={classes.label}>
            {item.label}
          </label>
        </li>
      );
    })}
  </ul>
);

Pills.propTypes = {
  color: PropTypes.oneOf(Object.values(COLORS)),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.isRequired,
    }).isRequired
  ).isRequired,
  size: PropTypes.oneOf(Object.values(SIZES)),
  stretched: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
};

Pills.defaultProps = {
  color: COLORS.PRIMARY,
  size: SIZES.MEDIUM,
  stretched: false,
};

export default Pills;
