import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import cs from "classnames";
import Button from "../../../../atoms/Button";
import Checkbox from "../../../../atoms/Checkbox";
import Loader from "../../../../atoms/Loader";
import { StoreContext } from "../../../../../contexts";
import { ACTIONS } from "../../../../../store";
import { useCheckbox } from "../../../../../hooks";
import classes from "./Submit.module.scss";
import { payMethodCreditsValidation } from "../../../../../utilities/helpers";
// import { APPLICATION_ROUTES } from "../../../../../constants";
// import { useHistory } from "react-router-dom";

const IDENTIFIERS = {
  BUTTON: "braintree-button",
  CONTAINER: "braintree-container",
  SUBMIT: "submit-checkout",
};

const Submit = observer(() => {
  // const history = useHistory();

  const { t } = useTranslation();
  const { cart, checkout } = useContext(StoreContext);
  const confirmation = useCheckbox();
  const isDisabled =
    cart[`isLoading${ACTIONS.CART.GET}`] ||
    cart[`error${ACTIONS.CART.GET}`] ||
    checkout[`isLoading${ACTIONS.CHECKOUT.CREATE_TRANSACTION}`] ||
    checkout[`error${ACTIONS.CHECKOUT.CREATE_TRANSACTION}`] ||
    // checkout.hasNoTransition ||
    // checkout.hasNoToken ||
    !confirmation.checked;
  const isLoading = checkout[`isLoading${ACTIONS.CHECKOUT.SALE}`];

  console.log(isDisabled);

  return (
    <div className={classes.container}>
      <div className={cs(classes.checkbox, classes.hide)}>
        <Checkbox value={confirmation.value} onChange={confirmation.onChange}>
          {t("pages.checkout.texts.19")}
        </Checkbox>
      </div>
      <div className={classes.button}>
        {isLoading ? (
          <Loader />
        ) : (
          <Button disabled={false} id={IDENTIFIERS.BUTTON} onClick={payMethodCreditsValidation}>
            {t("pages.checkout.labels.2")}
          </Button>
        )}
      </div>
    </div>
  );
});

export default Submit;
