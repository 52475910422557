import { useContext, useCallback } from "react";
// import { APPLICATION_ROUTES } from "../../constants";
import { StoreContext } from "../../contexts";
import useModal from "../useModal";

let defaultOptions = {
  audioLists: [],
  theme: "light",
  remember: false,
  remove: false,
  autoplay: true,
  loadAudioErrorPlayNext: false,
  autoPlayInitLoadPlayList: false,
  defaultPosition: {
    right: 30,
    bottom: 5,
  },
  showDownload: false,
  showThemeSwitch: false,
  showLyric: false,
  lyric: false,
  mode: "full",
  clearPriorAudioLists: true,
  toggleMode: false,
  // APPLICATION_ROUTES.LIBRARY === window.location.pathname ||
  // APPLICATION_ROUTES.LIBRARY_PROECTED === window.location.pathname
  //   ? "full"
  //   : "mini",
};

const useAudioPlayer = ({ ACTION }) => {
  const { audios } = useContext(StoreContext);
  // const [options, setOptions] = useState(defaultOptions);
  const error = audios[`error${ACTION}`];
  const isLoading = audios[`isLoading${ACTION}`];
  const playlist = audios.playlist;
  const hasItems = audios.hasPlaylist;
  const modal = useModal();

  const onClose = useCallback(() => {
    modal.onClose();
  }, []);

  const onOpen = useCallback(() => {
    modal.onOpen();
  }, []);

  const onClickOverlay = useCallback((event) => {
    if (event.target.matches(`[data-name="video-overlay"]`)) onClose();
  }, []);

  return {
    onLoad: useCallback(() => {
      audios[`getPlaylist`]();
    }, []),
    playlist: playlist,
    isLoading: isLoading,
    isError: error,
    hasItems: hasItems,
    options: defaultOptions,
    // setOptions: setOptions,
    onClickOverlay: onClickOverlay,
    isOpened: modal.isOpened,
    onClose: onClose,
    onOpen: onOpen,
  };
};

export default useAudioPlayer;
