const useDateSeparate = (date, format = "T", returnFormat = " ") => {
    switch (format) {
        case "T": {
            date = date.split("T")[0];
            let [yearT, monthT, dayT] = date.split("-").map(Number);
            if (returnFormat === "string") {
                return `${yearT}, ${monthT}, ${dayT}`;
            } else {
                return { year: yearT, month: monthT, day: dayT };
            }
        }
        case "/": {
            let [monthS, dayS, yearS] = date.split('/').map(Number);
            if (returnFormat === "string") {
                return `${yearS}, ${monthS}, ${dayS}`;
            } else {
                return { year: yearS, month: monthS, day: dayS };
            }
        }
        case "-": {
            let [yearD, monthD, dayD] = date.split(" ")[0].split("-").map(Number);
            if (returnFormat === "string") {
                return `${yearD}, ${monthD}, ${dayD}`;
            } else {
                return { year: yearD, month: monthD, day: dayD };
            }
        }
        default:
            throw new Error("Invalid date format");
    }
}

export default useDateSeparate;
