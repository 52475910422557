import React from "react";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import cs from "classnames";

import FlagIcon from "../../../assets/svg/flag.svg";
import { COLORS, SIZES, calculateIndicatorStyle } from "./utilities";
import classes from "./ProgressDots.module.scss";

const ProgressDots = ({ color, items, size, vertical }) => {
  const style = calculateIndicatorStyle(items, { vertical });

  return (
    <div
      className={cs(classes.container, classes[color], classes[size], {
        [classes.horizontal]: !vertical,
        [classes.vertical]: vertical,
      })}
    >
      <div className={classes.line}>
        <div style={style} className={classes.indicator} />
      </div>
      <ul className={classes.dots}>
        {items.map((item, index) => (
          <li
            key={index}
            className={cs(classes.dot, { [classes.active]: item.active, [classes.checked]: item.checked })}
          >
            <ReactSVG className={classes.icon} src={FlagIcon} />
          </li>
        ))}
      </ul>
      <ul className={classes.titles}>
        {items.map((item, index) => (
          <li key={index} className={cs(classes.title, { [classes.active]: item.active })}>
            {item.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

ProgressDots.propTypes = {
  color: PropTypes.oneOf(Object.values(COLORS)),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool.isRequired,
      checked: PropTypes.bool.isRequired,
      title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }).isRequired
  ).isRequired,
  size: PropTypes.oneOf(Object.values(SIZES)),
  vertical: PropTypes.bool.isRequired,
};

ProgressDots.defaultProps = {
  color: COLORS.PRIMARY,
  size: SIZES.MEDIUM,
  vertical: false,
};

export default ProgressDots;
