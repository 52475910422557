import React from "react";
import PropTypes from "prop-types";
import Slick from "react-slick";
import cs from "classnames";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Arrow from "./components/Arrow";
import classes from "./Slider.module.scss";

const AppendDots = (dots) => (
  <div>
    <ul className="d-flex jc-center ai-center mt-06">{dots}</ul>
  </div>
);

const CustomPaging = () => <div className={classes.dot} />;

const Slider = ({
  children,
  className,
  sliderRef,
  arrowname,
  rotated = true,
  ...properties
}) => {
  return (
    <>
      <Slick
        ref={sliderRef ? (c) => sliderRef.set(c) : null}
        touchThreshold={18}
        appendDots={AppendDots}
        className={cs(classes.container, className)}
        customPaging={CustomPaging}
        nextArrow={<Arrow arrowname={arrowname} rotated={rotated} />}
        prevArrow={<Arrow arrowname={arrowname} />}
        {...properties}
      >
        {children}
      </Slick>
    </>
  );
};

Slider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  className: PropTypes.string,
  arrowname: PropTypes.string,
  rotated: PropTypes.bool,
  sliderRef: PropTypes.any,
};

Slider.defaultProps = {
  className: "",
};

export default Slider;
