import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classes from "./Preview.module.scss";
import Icon from "../../../../atoms/Icon";
import cs from "classnames";
import { observer } from "mobx-react-lite";
import { useQuery } from "../../../../../hooks";

const Preview = observer(
  ({ name, cover, musicSrc, showAllList, singer, index, audioHandler }) => {
    const query = useQuery();
    const autoPlayAudio = query.get("audio");

    useEffect(() => {
      if (autoPlayAudio != null) {
        audioHandler.setCurrentAudio({
          musicSrc: "d",
        });
      }
    }, [autoPlayAudio]);

    return (
      <div
        className={cs(
          classes.container,
          showAllList && classes.mobilecontainer
        )}
      >
        <div
          className={cs(classes.preview)}
          role="button"
          style={{
            backgroundImage: `url('${encodeURI(cover)}')`,
          }}
        >
          <div
            className={cs(classes.playerControlWrapper)}
            onClick={() => {
              audioHandler.setCurrentAudio({
                cover: cover,
                id: `audio-${index}`,
                index: index,
                musicSrc: musicSrc,
                name: name,
                singer: singer,
                value: musicSrc,
              });
            }}
          >
            <Icon className={cs(classes.playerPlay)} name="audio-icon" />
          </div>
        </div>

        <div className={classes.details}>
          <p className={classes.title}>
            <strong>{name}</strong>
          </p>

          <p className={classes.mobileTitle}>
            <strong>{name}</strong> <br />
          </p>
        </div>
      </div>
    );
  }
);

Preview.propTypes = {
  id: PropTypes.string.isRequired,
  locales: PropTypes.shape({
    // en: PropTypes.shape({
    //   link: PropTypes.string.isRequired,
    // }),
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  showAllList: PropTypes.bool,
};

export default React.memo(Preview);
