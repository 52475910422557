import { makeAutoObservable } from "mobx";

import { FreeDonorAccessAPI } from "../../api";
import { ACTIONS } from "./utilities.js";

const freeDonorAccessAPI = new FreeDonorAccessAPI();

const FreeDonorAccess = class {
    constructor() {
        const actions = Object.values(ACTIONS);

        for (let i = 0; i < actions.length; i++) {
            const ACTION = actions[i];

            this[`isLoading${ACTION}`] = false;
            this[`error${ACTION}`] = "";
        }

        makeAutoObservable(this);

    }

    setIsLoading(name, value) {
        this[`isLoading${name}`] = value;
    }

    setError(name, value) {
        this[`error${name}`] = value;
    }

    setValue(name, value) {
        this[name] = value;
    }

    get({ fdaURL }) {
        this.setIsLoading(ACTIONS.GET, true);

        console.log(fdaURL)

        freeDonorAccessAPI
            .get({ fdaURL })
            .then((response) => {
                if (response.status === 200) return response.json();
                throw new Error(response.status);
            })
            .then(({ data }) => {
                this.setValue("data", data);
                this.setIsLoading(ACTIONS.GET, false);
            })
            .catch((error) => {
                this.setError(ACTIONS.GET, error.message);
                this.setIsLoading(ACTIONS.GET, false);
            });
    }
}

export default FreeDonorAccess;