import React from "react";
import PropTypes from "prop-types";

const UnknownItem = ({ id, ...properties }) => {
  console.log(properties);

  return <div>UnknownItem: {id}</div>;
};

UnknownItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
};

export default UnknownItem;
