import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import icon from "../../../assets/svg/loader-indicator.svg";
import greenLoader from "../../../assets/svg/green-loader.svg";
import { COLORS, SIZES } from "./utilities";
import classes from "./Loader.module.scss";

const Loader = ({ color, size, green = false }) => (
  <div className={cs(classes.container, classes[color], classes[size])}>
    <div className={classes.box}>
      <img
        className={classes.icon}
        src={!green ? icon : greenLoader}
        alt="loader indicator"
        draggable={false}
      />
    </div>
  </div>
);

Loader.propTypes = {
  color: PropTypes.oneOf(Object.values(COLORS)),
  size: PropTypes.oneOf(Object.values(SIZES)),
  green: PropTypes.string,
};

Loader.defaultProps = {
  color: COLORS.PRIMARY,
  size: SIZES.MEDIUM,
};

export default Loader;
