import { PAGE_TITLES } from "../../../constants";

const getTranslationKey = (value) => {
  switch (value) {
    case PAGE_TITLES.COMING_SOON:
      return "layout.coming_soon";
    case PAGE_TITLES.DAILY_REBBE_VIDEO:
      return "layout.daily_rebbe_video";
    case PAGE_TITLES.DONATION_REFUND_POLICY:
      return "layout.donation_refund_policy";
    case PAGE_TITLES.HOME:
      return "layout.home";
    case PAGE_TITLES.ILLUMINATE:
      return "layout.illuminate";
    case PAGE_TITLES.LIBRARY:
      return "layout.library";
    case PAGE_TITLES.MY_MAOR:
      return "layout.mymaor";
    case PAGE_TITLES.NOT_FOUND:
      return "layout.not_found";
    case PAGE_TITLES.PARTNERS:
      return "layout.partners";
    case PAGE_TITLES.SPONSORS:
      return "Our Sponsors";
    case PAGE_TITLES.PASSWORD_RECOVERY:
      return "layout.password_recovery";
    case PAGE_TITLES.PRIVACY_POLICY:
      return "layout.privacy_policy";
    case PAGE_TITLES.QUESTIONNAIRE:
      return "layout.questionnaire";
    case PAGE_TITLES.SIGN_IN:
      return "layout.sign_in";
    case PAGE_TITLES.SIGN_UP:
      return "layout.sign_up";
    default:
      return "layout.maor";
  }
};

export { getTranslationKey };
