import React from "react";
import classes from "./AutoRenewal.module.scss";
import cs from "classnames";
import Checkbox from "../../../../atoms/Checkbox";
import PropTypes from "prop-types";

const AutoRenewal = ({ renewBirthday }) => {

    return (
        <div className={cs(classes.container)}>
            <h1 className={cs(classes.colorOrange)}>New!</h1>

            <div>
                <p className={cs(classes.bold)}>
                    Never miss your child&apos;s birthday!
                </p>
                <p>You can now automatically submit your birthdays every year.</p>
            </div>

            <p>
                <span className={cs(classes.bold)}>How does it work? </span> Simple, 3
                weeks prior to the child&apos;s <br /> birthday you will receive a
                confirmation email that the <br /> birthday has been submitted.
            </p>

            <p className={cs(classes.colorOrange, classes.fontSmall)}>
                If you have Maor credit available, the system will use that first, if
                not the <br /> charge will be on your saved payment method.{" "}
            </p>

            <div className={cs("mt-08", classes.bold)}>
                <Checkbox {...renewBirthday} > Yes! I would like Maor to automatically renew my birthday <br /> submition annually  and charge my card for this service if needed.</Checkbox>
            </div>
        </div>
    );
};

AutoRenewal.propTypes = {
    renewBirthday: PropTypes.func.isRequired
}

export default AutoRenewal;
