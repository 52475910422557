const mayorOptions = [
  {
    id: "mayorOption1",
    label: "12 Psukim",
    value: "12 Psukim",
  },
  {
    id: "mayorOption2",
    label: "Messiah",
    value: "messiah",
  },
  {
    id: "mayorOption3",
    label: "Serie Tanya",
    value: "serie tanya",
  },
];

const dailyRebbOptions = [
  {
    id: "dailyRebbeOption1",
    label: "12 Psukim",
    value: "12 Psukim",
  },
  {
    id: "dailyRebbeOption2",
    label: "Messiah",
    value: "messiah",
  },
  {
    id: "dailyRebbeOption3",
    label: "Serie Tanya",
    value: "Serie Tany",
  },
];

const illuminateOptions = [
  {
    id: "illuminateOption1",
    label: "12 Psukim",
    value: "12 Psukim",
  },
  {
    id: "illuminateOption2",
    label: "Messiah",
    value: "messiah",
  },
  {
    id: "illuminateOption3",
    label: "Serie Tanya",
    value: "Serie Tany",
  },
];

const tags = [
  {
    id: "librarySidebarTag1",
    label: "#Maor",
    value: "maor",
  },
  {
    id: "librarySidebarTag2",
    label: "#MyMaorKids",
    value: "mymaorkids",
  },
  {
    id: "librarySidebarTag3",
    label: "#DailyRebbe",
    value: "dailyrebbe",
  },
  {
    id: "librarySidebarTag4",
    label: "#Video",
    value: "video",
  },
];

const projectTypes = [
  {
    id: "projectCategoriesMyMaor",
    value: "MyMaor",
  },
  {
    id: "projectCategoriesDailyRebbeVideo",
    value: "Daily Rebbe Video",
  },
  {
    id: "projectCategoriesIlluminate",
    value: "Illuminate",
  },
];

export { mayorOptions, dailyRebbOptions, illuminateOptions, tags, projectTypes };
