import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import classes from "./CartIcon.module.scss";
import Icon from "../Icon";

const CartIcon = ({ icon, onClick, itemsCount, ...properties }) => {
  return (
    <div {...properties} onClick={onClick} className={cs(classes.container)}>
      <Icon color="white" name={icon} />
      {itemsCount && <mark className={classes.cartCount}>{itemsCount}</mark>}
    </div>
  );
};

CartIcon.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  itemsCount: PropTypes.number,
};

CartIcon.defaultProps = {
  icon: "cake",
};

export default CartIcon;
