const getTranslationKeyOfError = (value) => {
  switch (value) {
    case "403":
      return "authorization.the_password_does_not_match_this_login";
    case "404":
      return "authorization.there_is_no_user_with_this_login";
    case "422":
      return "authorization.request_parameters_are_not_valid";
    case "502":
      return "authorization.something_went_wrong_please_try_again_later";
    default:
      return "authorization.something_went_wrong_please_try_again_later";
  }
};

export { getTranslationKeyOfError };
