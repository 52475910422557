import { makeAutoObservable } from "mobx";
import { PlaylistAPI } from "../../api";
import { ACTIONS } from "./utilities";

const playlistAPI = new PlaylistAPI();

const Playlist = class {
  constructor() {
    this.currentIndex = 0;
    this.items = [];
    this.playlistVideos = [];
    this.rolled = false;
    this.paused = true;

    const actions = Object.values(ACTIONS);

    for (let i = 0; i < actions.length; i++) {
      const ACTION = actions[i];

      this[`error${ACTION}`] = "";
      this[`isLoading${ACTION}`] = false;
    }

    this.totalItems = 0;
    this.nextPage = null;
    this.currentPage = 1;

    this.loadingIds = [];
    this.currentPlayingVideoId = null;

    makeAutoObservable(this);
  }

  get hasItems() {
    return !!this.items.length;
  }

  get hasNextPage() {
    return !!this.nextPage;
  }

  get isEmpty() {
    return !this.items.length;
  }

  get currentVideo() {
    return this.items[this.currentIndex];
  }

  get currentVideoStartTime() {
    return this.currentVideo ? this.currentVideo.start_time : 0;
  }

  get currentVideoID() {
    return this.currentVideo ? this.currentVideo.id : "";
  }

  get currentVideoURL() {
    return this.currentVideo ? this.currentVideo.url : "";
  }

  get isCurrentVideoFirst() {
    return this.currentIndex === 0;
  }

  get isCurrentVideoLast() {
    return this.currentIndex === this.items.length - 1;
  }

  // add(item) {
  //   const isOnTheList = this.items.find(({ id }) => id === item.id);
  //   if (isOnTheList) return;

  //   this.items.add(item);
  // }

  get({ onSuccess = null, resetPage = false, refreshCurrentPage = false }) {
    this.setIsLoading(ACTIONS.GET, true);

    if (resetPage) {
      this.setValue("nextPage", 1);
    }

    if (refreshCurrentPage) {
      this.setValue("nextPage", this.currentPage);
    }

    playlistAPI
      .get({
        page: this.nextPage,
      })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        this.addItems(data.items);

        this.setValue("totalItems", data.total_items);
        this.setValue("nextPage", data.next_page);
        this.setValue("currentPage", data.current_page);
        this.setValue("playlistVideos", data.playlist_videos);

        this.setIsLoading(ACTIONS.GET, false);
        onSuccess && onSuccess();
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.GET, message);
        this.setIsLoading(ACTIONS.GET, false);
      });
  }

  clear({ onSuccess = null }) {
    this.setIsLoading(ACTIONS.CLEAR_PLAYLIST, true);

    playlistAPI
      .clear()
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response.status);
      })
      .then(() => {
        this.setIsLoading(ACTIONS.CLEAR_PLAYLIST, false);
        onSuccess && onSuccess();
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.CLEAR_PLAYLIST, message);
        this.setIsLoading(ACTIONS.CLEAR_PLAYLIST, false);
      });
  }

  handleAddDelete(id) {
    this.setLoadingId(id);
    if (!this.videoExists(id)) {
      this.add({
        id: id,
        onSuccess: () => {
          this.get({
            onSuccess: () => {
              this.removeLoadingId(id);
            },
            resetPage: true,
          });
        },
      });
    } else {
      this.delete({
        id: id,
        onSuccess: () => {
          this.get({
            onSuccess: () => {
              this.removeLoadingId(id);
            },
            resetPage: true,
          });
        },
      });
    }
  }

  add({ id, onSuccess }) {
    this.setIsLoading(ACTIONS.ADD, true);
    this.setIsLoading(`Video${id}`, true);

    playlistAPI
      .add({ id: id })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response);
      })
      .then(() => {
        this.setIsLoading(`Video${id}`, false);
        this.addPlaylistVideo(id);
        onSuccess && onSuccess();
      })
      .catch((error) => {
        this.setIsLoading(`Video${id}`, false);
        this.setError(ACTIONS.ADD, error.message);
        this.setIsLoading(ACTIONS.ADD, false);
      });
  }

  addSearchFilterVideos({ payload = {}, onSuccess = () => {} }) {
    console.log(payload, onSuccess);

    this.setIsLoading(ACTIONS.ADD_SEARCH_FILTER, true);

    playlistAPI
      .addSearchFilterVideo(payload)
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response);
      })
      .then(() => {
        // this.addPlaylistVideo(id);
        onSuccess && onSuccess();
        this.setIsLoading(ACTIONS.ADD_SEARCH_FILTER, false);
      })
      .catch((error) => {
        this.setError(ACTIONS.ADD, error.message);
        this.setIsLoading(ACTIONS.ADD_SEARCH_FILTER, false);
      });
  }

  addToPlaylist({ payload = {}, onSuccess = () => {} }) {
    console.log(payload, onSuccess);

    this.setIsLoading(ACTIONS.ADD_TO_PLAYLIST, true);

    playlistAPI
      .addToPlaylist(payload)
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response);
      })
      .then(() => {
        // this.addPlaylistVideo(id);
        onSuccess && onSuccess();
        this.setIsLoading(ACTIONS.ADD_TO_PLAYLIST, false);
      })
      .catch((error) => {
        this.setError(ACTIONS.ADD, error.message);
        this.setIsLoading(ACTIONS.ADD_TO_PLAYLIST, false);
      });
  }

  delete({ id, onSuccess }) {
    this.setIsLoading(ACTIONS.DELETE, true);
    this.setIsLoading(`Video${id}`, true);

    playlistAPI
      .delete({ id: id })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error(response);
      })
      .then(() => {
        this.setIsLoading(`Video${id}`, false);
        this.removePlaylistVideo(id);
        onSuccess && onSuccess();
      })
      .catch((error) => {
        this.setError(ACTIONS.DELETE, error.message);
        this.setIsLoading(`Video${id}`, false);
        this.setIsLoading(ACTIONS.DELETE, false);
      });
  }

  addPlaylistVideo(id) {
    this.playlistVideos.push(id);
  }

  removePlaylistVideo(id) {
    this.items = this.items.filter((item) => item.video.id != id);
    this.playlistVideos = this.playlistVideos.filter((id) => id != id);
  }

  addItems(items) {
    if (items && items.length) {
      let updatedItems = [];
      if (this.nextPage == 1) {
        updatedItems = [...items];
      } else {
        updatedItems = [...this.items, ...items];

        const uniqueValuesSet = new Set();
        updatedItems = updatedItems.filter((obj) => {
          // check if name property value is already in the set
          const isPresentInSet = uniqueValuesSet.has(obj.id);
          // add name property value to Set
          uniqueValuesSet.add(obj.id);
          // return the negated value of
          // isPresentInSet variable
          return !isPresentInSet;
        });
      }
      this.setValue("items", updatedItems);
    } else {
      if (this.nextPage == 1) {
        this.setValue("items", []);
      }
    }
  }
  // delete(id) {
  //   const index = this.items.findIndex((item) => item.id === id);
  //   const isNotOnTheList = index === -1;
  //   if (isNotOnTheList) return;

  //   this.items.splice(index, 1);
  // }

  play() {
    this.paused = false;
  }

  pause() {
    this.paused = true;
  }

  toggle() {
    this.paused = !this.paused;
  }

  select(id) {
    const index = this.items.findIndex((item) => item.id === id);
    const isNotOnTheList = index === -1;
    if (isNotOnTheList) return;

    this.currentIndex = index;
  }

  selectPrevious() {
    if (this.isCurrentVideoFirst) return;
    this.currentIndex -= 1;
  }

  selectNext() {
    if (this.isCurrentVideoLast) return;
    this.currentIndex += 1;
  }

  toggleRolling() {
    this.rolled = !this.rolled;
  }

  setValue(name, value) {
    this[`${name}`] = value;
  }

  setIsLoading(name, value) {
    this[`isLoading${name}`] = value;
  }

  setError(name, value) {
    this[`error${name}`] = value;
  }

  videoExists(videoId) {
    if (this.playlistVideos == undefined) {
      return false;
    }
    let value = this.playlistVideos.find((id) => id == videoId);
    return value !== undefined;
  }

  setLoadingId(id) {
    this.loadingIds.push(id);
  }

  removeLoadingId(id) {
    this.loadingIds = this.loadingIds.filter((item) => item != id);
  }

  getCurrentPlayingVideoId() {
    return this.currentPlayingVideoId;
  }

  setCurrentPlayingVideoId(id) {
    this.currentPlayingVideoId = id;
  }

  getIsLoading(name) {
    return this[`isLoading${name}`];
  }
};

export default Playlist;
