import { observer } from "mobx-react-lite";
import React from "react";
import cs from "classnames";
import classes from "./PledgeDonation.module.scss";
import PlainInput from "../../../../atoms/PlainInput";
import useDonation from "../../../PartnersDonation/useDonation";
import Checkbox, { SIZES } from "../../../../atoms/Checkbox";
import { useContext } from "react";
import { StoreContext } from "../../../../../contexts";
import { ACTIONS } from "../../../../../store";
import { Loader } from "react-bootstrap-typeahead";
import PasswordInput from "../../../../atoms/PasswordInput";
import Button from "../../../../atoms/Button";
import {
  APPLICATION_ROUTES,
  COOKIES,
  PROJECTS,
} from "../../../../../constants";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "../../../../../hooks";
import { scrollToTop } from "../../../../../utilities/helpers";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "../../../../../utilities/cookies";
import PhoneInput from "../../../../atoms/PhoneInput";

const PledgeDonation = observer(() => {
  const pledgeDonation = true;
  const { authorization, cart } = useContext(StoreContext);
  const {
    customAmount,
    email,
    lastName,
    firstName,
    password,
    createAccount,
    sendBrocha,
    anonymous,
    recoverFees,
    isLoading,
    isNotValid,
    showPersonal,
    phone,
    motherName,
    yourName,
    onSubmit,
  } = useDonation(pledgeDonation);
  const { t } = useTranslation();
  const query = useQuery();
  const type = query.get("type") || PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID;
  const history = useHistory();

  const createAccountText = `I would like to include my name in a פ"נ`;

  const onNavigateToSignIn = useCallback(() => {
    history.push(
      APPLICATION_ROUTES.SIGN_IN +
        "?redirect=" +
        APPLICATION_ROUTES.PLEDGE_DONATION_SECTION
    );
    scrollToTop();
  });

  const onSignOut = useCallback(() => {
    Cookies.delete({ name: COOKIES.AUTO_SIGNIN });
    authorization.signOut();
  });

  return (
    <div className={cs(classes.PledgeDonationConatiner)}>
      <div className={classes.group}>
        <h1>
          My Monthly Pledge{" "}
          <span className={cs(classes.mobileDisplayNone)}> to Maor </span> is:
        </h1>

        <div className={cs("mr-auto ml-auto", classes.amountConatiner)}>
          <PlainInput
            error={customAmount.error}
            id="donation-amount"
            label={customAmount.label}
            value={customAmount.value}
            onChange={customAmount.onChange}
            color="purple"
          />

          <div className={cs(classes.checkboxContainer)}>
            <Checkbox
              {...recoverFees}
              size={SIZES.PLEDGECHECKOUT}
              color="blackCheckbox"
            >
              {recoverFees.label}
            </Checkbox>
          </div>
        </div>
        

        <ul className={cs(classes.list, "mt-05", "mb-05")}>
          <li className={cs(classes.item)}>
            <p className={cs(classes.changeColor)}>
              Donate <b>$18+</b> monthly for library access, <b>$54+</b> for a Maor Discount
              Card.
            </p>
          </li>
        </ul>

        <ul className={cs(classes.list, "mt-05", "mb-05")}>
          <li className={cs(classes.item)}>
            <p className={cs(classes.newLink)}>
              <Link to={APPLICATION_ROUTES.PARTNERS_DONATION}>
                <span
                  className={cs(
                    classes.orangeColor,
                    classes.textDecorationUnderline
                  )}
                >
                  Click here
                </span>
              </Link>{" "}
              for a one-time donation.
            </p>
          </li>
        </ul>

        <div className={cs(classes.boundary, "mt-06 mb-06")} />

        <div className={cs(classes.bottomSection)}>
          <header className={cs(classes.textHeader, "mb-07")}>
            {authorization[`isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`] && (
              <Loader />
            )}
            {!authorization.isAuthenticated &&
              !authorization[`isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`] && (
                <>
                  <span className={classes.loggedInText}>
                    <span className={classes.createAnAccount}>
                      {t("partners.create_an_account_or")}&nbsp;
                    </span>
                    or{" "}
                    <a
                      className={classes.logoutLinkText}
                      size="xs"
                      onClick={onNavigateToSignIn}
                    >
                      {t("layout.sign_in")}
                    </a>
                  </span>
                </>
              )}
            {authorization.isAuthenticated && (
              <p>
                <span className={classes.loggedInText}>
                  {t("layout.logged_in_as")}
                  <span className={classes.familyText}>
                    <strong>
                      {authorization.lastName}{" "}
                      {t("layout.family").toLowerCase()}{" "}
                    </strong>
                  </span>
                </span>
                <a className={classes.logoutLink} onClick={onSignOut} href="#">
                  {t("layout.log_out")}
                </a>
              </p>
            )}
          </header>

          {authorization[`isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`] ? (
            <div className="mb-06">
              <Loader />
            </div>
          ) : (
            showPersonal && (
              <ul className={cs(classes.personal, classes.list)}>
                <li className={cs(classes.item, classes.firstName)}>
                  <PlainInput
                    error={firstName.error}
                    id="donation-first-name"
                    label={firstName.label}
                    value={firstName.value}
                    stretched
                    onChange={firstName.onChange}
                    color="purple"
                  />
                </li>
                <li className={cs(classes.item, classes.lastName)}>
                  <PlainInput
                    error={lastName.error}
                    id="donation-last-name"
                    label={lastName.label}
                    value={lastName.value}
                    onChange={lastName.onChange}
                    color="purple"
                    stretched
                  />
                </li>
                <li className={cs(classes.item, classes.email)}>
                  <PlainInput
                    error={email.errorOfOriginal}
                    id="donation-email"
                    label={email.labelOfOriginal}
                    stretched
                    value={email.valueOfOriginal}
                    onChange={email.onChangeOriginal}
                    color="purple"
                  />
                </li>

                <div className={cs("w-100p", classes.pledgePhoneInput)}>
                  <PhoneInput
                    error={phone.error}
                    size="large"
                    value={phone.value}
                    onChange={phone.onChange}
                  />
                </div>

                <li
                  className={cs(
                    classes.item,
                    classes.createAccount,
                    classes.hideAccountOption
                  )}
                >
                  <Checkbox {...createAccount} color="purpleCheckbox">
                    Create Account?
                  </Checkbox>
                </li>

                {createAccount.checked ? (
                  <>
                    <li className={cs(classes.item, classes.password, "mb-05")}>
                      <PasswordInput
                        error={password.errorOfOriginal}
                        id="sign-up-form-password"
                        color="purple"
                        label={password.labelOfOriginal}
                        value={password.valueOfOriginal}
                        onChange={password.onChangeOriginal}
                        stretched
                      />
                    </li>
                    <li
                      className={cs(
                        classes.item,
                        classes.confirmPassword,
                        "mb-05"
                      )}
                    >
                      <PasswordInput
                        error={password.errorOfConfirm}
                        id="sign-up-form-password-repeat"
                        color="purple"
                        label={password.labelOfConfirm}
                        value={password.valueOfConfirm}
                        onChange={password.onChangeConfirm}
                        stretched
                      />
                    </li>
                  </>
                ) : null}
              </ul>
            )
          )}
        </div>

        {cart[`error${ACTIONS.CART.ADD}`] &&
          cart[`error${ACTIONS.CART.ADD}`] == 409 &&
          !authorization.isAuthenticated && (
            <span className={classes.error}>
              {cart[`setError`](`error${ACTIONS.CART.ADD}`, "")}
              There is already an account linked with this email, please{" "}
              <Link
                to={`${
                  APPLICATION_ROUTES.SIGN_IN
                }?redirect=${encodeURIComponent(
                  `${APPLICATION_ROUTES.PARTNERS_ORDER}?type=${type}&login=true`
                )}`}
              >
                log in
              </Link>
            </span>
          )}

        {authorization.isAuthenticated ? (
          <ul className={cs(classes.list)}>
            <li
              className={cs(classes.item, classes.password, "mb-05", "m-auto")}
            >
              <PasswordInput
                error={password.errorOfOriginal}
                id="sign-up-form-password"
                label={password.labelOfOriginal}
                value={password.valueOfOriginal}
                onChange={password.onChangeOriginal}
                color="purple"
              />
              <small>{t("layout.purcahse_password_confirmatin")}</small>
            </li>
            <li
              className={cs(
                classes.item,
                classes.anonymous,
                classes.listCenter,
                "m-auto",
                "mb-05"
              )}
            >
              <Checkbox
                {...anonymous}
                size={SIZES.PLEDGECHECKOUT}
                color="blackCheckbox"
              >
                {anonymous.label}
              </Checkbox>
            </li>
          </ul>
        ) : (
          <ul className={cs(classes.list, classes.listCenter)}>
            <li className={cs(classes.item, classes.anonymous)}>
              <Checkbox
                {...anonymous}
                size={SIZES.PLEDGECHECKOUT}
                color="blackCheckbox"
              >
                {anonymous.label}{" "}
              </Checkbox>
            </li>
          </ul>
        )}

        <ul className={cs(classes.sendBrochaContainer, classes.list)}>
          <li className={cs(classes.item, classes.sendBrocha)}>
            <Checkbox size={SIZES.PLEDGECHECKOUT} {...sendBrocha}>
              <span className={cs(classes.orangeColor)}>
                {createAccountText}
              </span>
            </Checkbox>
          </li>

          {sendBrocha.checked ? (
            <>
              <p className={cs(classes.item, classes.fillText)}>
                {t("pages.partners.donation.pledge.2")}
              </p>
              <li className={cs(classes.item, classes.yourName)}>
                <PlainInput
                  error={yourName.error}
                  id="donation-your-name"
                  label={yourName.label}
                  value={yourName.value}
                  stretched
                  onChange={yourName.onChange}
                  color="purple"
                />
              </li>
              <li className={cs(classes.item, classes.motherName, "mb-05")}>
                <PlainInput
                  error={motherName.error}
                  id="donation-mother-name"
                  label={motherName.label}
                  value={motherName.value}
                  stretched
                  onChange={motherName.onChange}
                  color="purple"
                />
              </li>
            </>
          ) : null}
        </ul>

        {/* <ul className={cs(classes.list, 'mb-05')}>
                    <li className={cs(classes.item)}>
                        <p className={cs(classes.newLink)}>If this page doesn&apos;t work, <span className={cs(classes.orangeColor, classes.textDecorationUnderline)}>
                            <a href='https://mymaor.org/form/view.php?id=45332' target="_blank" rel="noreferrer noopener"> Click here </a> </span> to use alternative page.</p>
                    </li>
                </ul> */}

        <div
          className="d-flex df-column jc-center ai-center"
          style={{ minHeight: "52px" }}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <Button
              disabled={isNotValid}
              type="submit"
              color="purple"
              onClick={onSubmit}
            >
              {t("pages.partners.donation.labels.2")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
});

export default PledgeDonation;
