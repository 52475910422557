import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import classes from "./PartnersSection.module.scss";
import cs from "classnames";
import { StoreContext } from "../../../contexts";
import {
  APPLICATION_ROUTES,
  PARTNERSHIP_STATUS,
  PROJECTS,
} from "../../../constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Icon from "../../atoms/Icon";
import { NAMES, SIZES } from "../../atoms/Icon/utilities";
import balloon from "../../../assets/images/balloon.png";
import hand from "../../../assets/images/Hand.png";
import verticalShadow from "../../../assets/images/verticle-shadow.png";
import horizontalShadow from "../../../assets/images/horizontal-shadow.png";
import { ACTIONS } from "../../../store";
import Logotype, { TYPES as LOGOTYPE_TYPES } from "../../atoms/Logotype";
import { useIsDateWithinTwoMonths } from "../../../hooks";
import { isMobile } from "react-device-detect";
import partnersCard from "../../../assets/images/partners-card.png";

const PartnersSection = observer(() => {
  const { partnership, authorization } = useContext(StoreContext);
  const history = useHistory();

  const [monthlyPlan, setMonthlyPlan] = useState(false);
  const [currentPlan, setCurrentPlan] = useState("");
  const [currentPlanBillingInterval, setCurrentPlanBillingInterval] =
    useState("");

  console.log(monthlyPlan);

  useEffect(() => {
    partnership[`${ACTIONS.PARTNERSHIP.GET_PARTNERSHIP}`]();
  }, []);

  useEffect(() => {
    if (
      partnership.hasDetails &&
      (partnership.details["status"] == PARTNERSHIP_STATUS.ACTIVE ||
        partnership.details["status"] == PARTNERSHIP_STATUS.PENDING)
    ) {
      setCurrentPlan(
        partnership.details["plan"].toLowerCase().replace("-", "_")
      );
      setCurrentPlanBillingInterval(partnership.details["interval"]);
      if (partnership.details["interval"] == 1) {
        setMonthlyPlan(true);
      }
    }
  }, [partnership.details]);

  console.log(
    currentPlan,
    currentPlanBillingInterval,
    "\n",
    partnership.details?.next_payment_date,
    "\n next_payment_date"
  );

  let silverCardPlanPrice =
    currentPlan == PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID &&
    currentPlanBillingInterval == 12 &&
    !useIsDateWithinTwoMonths(partnership.details?.next_payment_date)
      ? partnership.details?.silverCardPlanPrice
      : 260;

  let silverPlanPrice =
    currentPlan == PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID &&
    currentPlanBillingInterval == 12 &&
    !useIsDateWithinTwoMonths(partnership.details?.next_payment_date)
      ? partnership.details?.silverPlanPrice
      : 180;

  if (
    currentPlan == PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID &&
    currentPlanBillingInterval == 12
  ) {
    silverCardPlanPrice =
      currentPlan == PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID &&
      currentPlanBillingInterval == 12 &&
      !useIsDateWithinTwoMonths(partnership.details?.next_payment_date)
        ? partnership.details?.silverCardPlanPrice
        : 260;
  } else if (
    currentPlan == PROJECTS.PARTNERS.PACKAGES.PARTNER_TIER.ID &&
    currentPlanBillingInterval == 12
  ) {
    silverCardPlanPrice =
      currentPlan == PROJECTS.PARTNERS.PACKAGES.PARTNER_TIER.ID &&
      currentPlanBillingInterval == 12 &&
      !useIsDateWithinTwoMonths(partnership.details?.next_payment_date)
        ? partnership.details?.silverCardPlanPrice
        : 260;
  }

  let basicPlanPrice =
    currentPlan == PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID &&
    currentPlanBillingInterval == 12 &&
    !useIsDateWithinTwoMonths(partnership.details?.next_payment_date)
      ? partnership.details?.partnerPlanPrice
      : 180;

  const onSubmitAndNavigateToPayment = ({ projectType, billingCycle }) => {
    if (authorization.user === null) {
      // history.push(
      //   `${APPLICATION_ROUTES.SIGN_IN}?redirect=${APPLICATION_ROUTES.PARTNERS_ORDER}/${projectType}/${billingCycle}`
      // );
      history.push(
        `${APPLICATION_ROUTES.SIGN_IN}?redirect=${APPLICATION_ROUTES.PARTNERS_PACKAGES}`
      );
    } else {
      if (
        (currentPlan == PROJECTS.PARTNERS.PACKAGES.PARTNER_TIER.ID ||
          currentPlan == PROJECTS.PARTNERS.PACKAGES.SILVER_CARD_TIER.ID) &&
        (projectType == PROJECTS.PARTNERS.PACKAGES.SILVER_CARD_TIER.ID ||
          projectType == PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID)
      ) {
        history.push(
          `${APPLICATION_ROUTES.CHECKOUT}/${projectType}/${billingCycle}/partnership?delivery=false`
        );
      } else {
        if (
          projectType == PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID ||
          projectType == PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID
        ) {
          history.push(
            `${APPLICATION_ROUTES.CHECKOUT}/${projectType}/${billingCycle}/partnership`
          );
        } else {
          history.push(
            `${APPLICATION_ROUTES.PARTNERS_ORDER}/${projectType}/${billingCycle}`
          );
        }
      }
    }
  };

  return (
    <>
      {location.href.includes(APPLICATION_ROUTES.PARTNERS_PACKAGES) ? (
        <div className={cs("mb-04")}>
          <div className="wrapper-xl d-flex jc-center">
            <Logotype type={LOGOTYPE_TYPES.PARTNERS} />
          </div>
        </div>
      ) : null}
      <div
        className={cs(
          classes.partnersFooter,
          location.href.includes(APPLICATION_ROUTES.PARTNERS_PACKAGES) &&
            classes.packagePagePadding,
          "wrapper-m"
        )}
      >
        {/* Basic Package */}
        <div className={cs(classes.limitedOfferContainer, classes.bronzeOffer)}>
          <div className={cs(classes.limitedOfferRight)}>
            <h1>
              <strong>Basic Package</strong> <br />{" "}
              <span>
                <strong>$72</strong>/Year
              </span>
            </h1>

            <img
              className={cs(
                classes.verticalShadow,
                classes.basicVerticalShadow
              )}
              src={verticalShadow}
              alt="verticalShadow"
            />
            <div className={cs(classes.limitedOfferInfo)}>
              <div className={cs(classes.limitedOfferInfoText)}>
                <Icon size={SIZES.XS} name={NAMES.BrownTick} />
                <p>Full Library Access</p>
              </div>
              <div
                className={cs(classes.limitedOfferInfoText, classes.disabled)}
              >
                <Icon size={SIZES.XS} name={NAMES.MinusIcon} />
                <p>No Maor Partner Card</p>
              </div>
            </div>
          </div>
          <img
            className={cs(classes.horizontalShadow)}
            src={horizontalShadow}
            alt="verticalShadow"
          />
          {currentPlan !== PROJECTS.PARTNERS.PACKAGES.PARTNER_TIER.ID &&
          currentPlan !== PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID &&
          currentPlan !== PROJECTS.PARTNERS.PACKAGES.SILVER_CARD_TIER.ID &&
          currentPlan !== PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID ? (
            <div className={cs(classes.limitedOfferLeft)}>
              <div
                onClick={() =>
                  onSubmitAndNavigateToPayment({
                    projectType: PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID,
                    billingCycle: 12,
                  })
                }
                className={cs(
                  classes.limitedOfferButton,
                  currentPlan == PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID &&
                    classes.disableButton
                )}
              >
                {currentPlan == PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID ? (
                  "CURRENT PLAN"
                ) : (
                  <>
                    {currentPlan == PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID
                      ? "UPGRADE"
                      : " JOIN NOW"}
                  </>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        {/* Partner Package */}
        <div className={cs(classes.limitedOfferContainer, classes.widerBox)}>
          <div className={cs(classes.limitedOfferRight)}>
            <img
              className={cs(classes.limitedOfferImage)}
              src={partnersCard}
              alt="partnersCard"
            />
            {isMobile && (
              <h1
                className={cs(classes.partnerCardText)}
                style={{ fontSize: "30px", lineHeight: "36px" }}
              >
                <strong>Partners Card</strong>
              </h1>
            )}
            {currentPlan == PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID &&
            currentPlanBillingInterval == 12 ? (
              <div className={cs(classes.oneTimeInfo)}>
                <h1>One Time Payment:</h1>
                <h1 className={classes.oneTimePrice}>${basicPlanPrice}</h1>
                <p>(Renewal at regular price)</p>
              </div>
            ) : (
              <h1 className={cs(classes.tempMarginClass)}>
                <strong>Limited time offer!</strong> <br />{" "}
                <span>
                  {" "}
                  Only <b>$180</b>/Year
                </span>
              </h1>
            )}

            <img
              className={cs(classes.verticalShadow)}
              src={verticalShadow}
              alt="verticalShadow"
            />
            <div className={cs(classes.limitedOfferInfo)}>
              <div className={cs(classes.limitedOfferInfoText)}>
                <Icon size={SIZES.XS} name={NAMES.OrangeTick} />
                <p>Full Library Access</p>
              </div>
              <div className={cs(classes.limitedOfferInfoText)}>
                <Icon size={SIZES.XS} name={NAMES.OrangeTick} />
                <p>Maor Partner Card</p>
              </div>
            </div>
          </div>
          <img
            className={cs(classes.horizontalShadow)}
            src={horizontalShadow}
            alt="verticalShadow"
          />
          {currentPlan !== PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID &&
          currentPlan !== PROJECTS.PARTNERS.PACKAGES.SILVER_CARD_TIER.ID &&
          currentPlan !== PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID ? (
            <div className={cs(classes.limitedOfferLeft)}>
              <div
                onClick={() =>
                  onSubmitAndNavigateToPayment({
                    projectType: PROJECTS.PARTNERS.PACKAGES.PARTNER_TIER.ID,
                    billingCycle: 12,
                  })
                }
                className={cs(
                  classes.limitedOfferButton,
                  currentPlan == PROJECTS.PARTNERS.PACKAGES.PARTNER_TIER.ID &&
                    classes.disableButton
                )}
              >
                {currentPlan == PROJECTS.PARTNERS.PACKAGES.PARTNER_TIER.ID ? (
                  "CURRENT PLAN"
                ) : (
                  <>
                    {currentPlan == PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID
                      ? "UPGRADE"
                      : " JOIN NOW"}
                  </>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className={cs(classes.partnersFooterBottom)}>
          <div className={cs(classes.birthdayDiscount)}>
            <img src={balloon} alt="balloon" className={cs(classes.balloon)} />
            <div className={cs(classes.birthdayDiscountTop)}>
              <h4>Birthday Discount Package</h4>
              <div className={cs(classes.birthdayDiscountInfoContainer)}>
                <div className={cs(classes.birthdayDiscountInfo)}>
                  <Icon size={SIZES.XS} name={NAMES.BrownTick} />
                  <p>Up to 4 Birthday Celebrations</p>
                </div>
                <div className={cs(classes.birthdayDiscountInfo)}>
                  <Icon size={SIZES.XS} name={NAMES.BrownTick} />
                  <p>Full Library Access </p>
                </div>
                <div className={cs(classes.birthdayDiscountInfo)}>
                  <Icon size={SIZES.XS} name={NAMES.BrownTick} />
                  <p>Optional: Maor Partner Card</p>
                </div>
              </div>
            </div>

            <div className={cs(classes.birthdayDiscountBottom)}>
              <img
                src={horizontalShadow}
                alt="horizontalShadow"
                className={cs(classes.horizontalShadow)}
              />

              <div
                className={cs(
                  classes.maorSupporterBottomConatiner,
                  classes.silverBottomContainer
                )}
              >
                <div className={cs(classes.priceBox)}>
                  <div
                    className={cs(
                      classes.maorSupporterBottom,
                      classes.silverBottom
                    )}
                  >
                    <p className={cs(classes.withoutCardText)}>Without Card</p>
                    <p
                      className={cs(
                        classes.birthdayDiscountPrice,
                        classes.silverDiscountPrice
                      )}
                    >
                      {silverPlanPrice != 180 && "Add"} ${silverPlanPrice}{" "}
                      {silverPlanPrice == 180 && (
                        <>
                          /Year
                          <span className={cs(classes.silverPercentage)}>
                            {" "}
                            (20% OFF)
                          </span>
                        </>
                      )}
                    </p>
                    {currentPlan !== PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID &&
                      currentPlan !==
                        PROJECTS.PARTNERS.PACKAGES.SILVER_CARD_TIER.ID &&
                      currentPlan !==
                        PROJECTS.PARTNERS.PACKAGES.PARTNER_TIER.ID && (
                        <div
                          onClick={() =>
                            onSubmitAndNavigateToPayment({
                              projectType:
                                PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID,
                              billingCycle: 12,
                            })
                          }
                          className={cs(
                            classes.birthdayDiscountButton,
                            currentPlan ==
                              PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID &&
                              currentPlanBillingInterval == 12 &&
                              classes.disableButton
                          )}
                        >
                          {currentPlan ==
                            PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID &&
                          currentPlanBillingInterval == 12 ? (
                            "CURRENT PLAN"
                          ) : (
                            <>
                              {currentPlan ==
                                PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID ||
                              currentPlan ==
                                PROJECTS.PARTNERS.PACKAGES.PARTNER_TIER.ID ||
                              (currentPlan ==
                                PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID &&
                                currentPlanBillingInterval == 1)
                                ? "UPGRADE"
                                : " JOIN NOW"}
                            </>
                          )}
                        </div>
                      )}
                  </div>
                  <p className={cs(classes.orText)}> or</p>
                  <div
                    className={cs(
                      classes.maorSupporterBottom,
                      classes.silverBottom
                    )}
                  >
                    <p className={cs(classes.withCardText)}>With Card</p>
                    <p
                      className={cs(
                        classes.birthdayDiscountPrice,
                        classes.silverDiscountPrice
                      )}
                    >
                      {silverCardPlanPrice != 260 && "Add"} $
                      {silverCardPlanPrice}{" "}
                      {silverCardPlanPrice == 260 && (
                        <>
                          /Year
                          <span className={cs(classes.silverPercentage)}>
                            {" "}
                            (45% OFF)
                          </span>
                        </>
                      )}
                    </p>
                    {currentPlan !==
                      PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID && (
                      <div
                        onClick={() =>
                          onSubmitAndNavigateToPayment({
                            projectType:
                              PROJECTS.PARTNERS.PACKAGES.SILVER_CARD_TIER.ID,
                            billingCycle: 12,
                          })
                        }
                        className={cs(
                          classes.birthdayDiscountButton,
                          currentPlan ==
                            PROJECTS.PARTNERS.PACKAGES.SILVER_CARD_TIER.ID &&
                            currentPlanBillingInterval == 12 &&
                            classes.disableButton
                        )}
                      >
                        {currentPlan ==
                          PROJECTS.PARTNERS.PACKAGES.SILVER_CARD_TIER.ID &&
                        currentPlanBillingInterval == 12 ? (
                          "CURRENT PLAN"
                        ) : (
                          <>
                            {currentPlan ==
                              PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID ||
                            currentPlan ==
                              PROJECTS.PARTNERS.PACKAGES.PARTNER_TIER.ID ||
                            currentPlan ==
                              PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID ||
                            (currentPlan ==
                              PROJECTS.PARTNERS.PACKAGES.SILVER_CARD_TIER.ID &&
                              currentPlanBillingInterval == 1)
                              ? "UPGRADE"
                              : " JOIN NOW"}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={cs(classes.maorSupporter)}>
            <img src={hand} alt="hand" className={cs(classes.handShake)} />
            <div className={cs(classes.maorSupporterTop)}>
              <h4>Become a Maor Supporter!</h4>
              <div className={cs(classes.maorSupporterInfoContainer)}>
                <div className={cs(classes.maorSupporterInfo)}>
                  <Icon size={SIZES.XS} name={NAMES.BrownTick} />
                  <p>Full Birthday Celebration for the Entire Family</p>
                </div>
                <div className={cs(classes.maorSupporterInfo)}>
                  <Icon size={SIZES.XS} name={NAMES.BrownTick} />
                  <p>Full Library Access</p>
                </div>
                <div className={cs(classes.maorSupporterInfo)}>
                  <Icon size={SIZES.XS} name={NAMES.BrownTick} />
                  <p>Maor Partner Card + Gift a 2nd card to a friend</p>
                </div>
              </div>
            </div>
            <div className={cs(classes.maorSupporterBottomInfo)}>
              <img
                src={horizontalShadow}
                alt="horizontalShadow"
                className={cs(classes.horizontalShadow)}
              />
              <div
                className={cs(
                  classes.maorSupporterBottomConatiner,
                  classes.goldContainer
                )}
              >
                <div
                  className={cs(
                    classes.withWithoutCardBox,
                    classes.withCardText
                  )}
                >
                  With Card
                </div>
                <div className={cs(classes.priceBox)}>
                  <div className={cs(classes.maorSupporterBottom)}>
                    <p className={cs(classes.maorSupporterPrice)}>$54/Month</p>
                    <div
                      className={cs(
                        classes.maorSupporterButton,
                        currentPlan ==
                          PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID &&
                          currentPlanBillingInterval == 1 &&
                          classes.disableButton
                      )}
                      onClick={() =>
                        onSubmitAndNavigateToPayment({
                          projectType: PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID,
                          billingCycle: 1,
                        })
                      }
                    >
                      {currentPlan == PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID &&
                      currentPlanBillingInterval == 1 ? (
                        "CURRENT PLAN"
                      ) : (
                        <>
                          {!currentPlan
                            ? "JOIN NOW"
                            : currentPlan ==
                                PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID &&
                              currentPlanBillingInterval == 12
                            ? "CHANGE"
                            : "UPGRADE"}
                        </>
                      )}
                    </div>
                  </div>
                  <p className={cs(classes.orText)}> or</p>
                  <div className={cs(classes.maorSupporterBottom)}>
                    <p className={cs(classes.maorSupporterPrice)}>$540/Year</p>
                    <div
                      className={cs(
                        classes.maorSupporterButton,
                        currentPlan ==
                          PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID &&
                          currentPlanBillingInterval == 12 &&
                          classes.disableButton
                      )}
                      onClick={() =>
                        onSubmitAndNavigateToPayment({
                          projectType: PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID,
                          billingCycle: 12,
                        })
                      }
                    >
                      {currentPlan == PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID &&
                      currentPlanBillingInterval == 12 ? (
                        "CURRENT PLAN"
                      ) : (
                        <>
                          {!currentPlan
                            ? "JOIN NOW"
                            : currentPlan ==
                                PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID &&
                              currentPlanBillingInterval == 1
                            ? "CHANGE"
                            : "UPGRADE"}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default PartnersSection;
