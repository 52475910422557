const getErrorTranslationKey = (value) => {
  switch (value) {
    case "403":
      return "pages.authorization.email_verified_request.warnings.1";
    case "404":
      return "pages.authorization.email_verified_request.warnings.2";
    case "422":
      return "pages.authorization.email_verified_request.warnings.3";
    default:
      return "generals.warnings.1";
  }
};

export { getErrorTranslationKey };
