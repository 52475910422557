import { PROJECTS } from "../../../../../../../constants";
import { getPackageDetails } from "../../../../../../../utilities/helpers";

const TYPES = {
  BASIC_TIER: PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID,
  SILVER_TIER: PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID,
  GOLD_TIER: PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID,
  PLATINUM_TIER: PROJECTS.PARTNERS.PACKAGES.PLATINUM_TIER.ID,
};

const getPackageView = (type) => {
  const details = getPackageDetails(type);

  return { title_translation_key: details.TITLE_TRANSLATION_KEY, Illustration: details.Illustration };
};

export { TYPES, getPackageView };
