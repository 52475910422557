import React, { useCallback, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import Button from "../../atoms/Button";
import Loader from "../../atoms/Loader";
import Logotype from "../../atoms/Logotype";
import { StoreContext } from "../../../contexts";
import { APPLICATION_ROUTES } from "../../../constants";
import { useQuery } from "../../../hooks";
import { ACTIONS } from "../../../store";
import { getErrorTranslationKey } from "./utilities";

const EmailVerificationRequest = observer(() => {
  const { authorization } = useContext(StoreContext);
  const isLoading = authorization[`isLoading${ACTIONS.AUTHORIZATION.VERIFY_EMAIL}`];
  const error = authorization[`error${ACTIONS.AUTHORIZATION.VERIFY_EMAIL}`];
  const errorTranslationKey = getErrorTranslationKey(error);

  const history = useHistory();

  const query = useQuery();
  const token = query.get("token");
  const onSubmit = useCallback(() => {
    // event.preventDefault();

    authorization.verifyEmail({
      token,
      onSuccess: () => {
        history.push(APPLICATION_ROUTES.EMAIL_VERIFICATION_SUCCESS);
        authorization.resignIn()
      },
    });
  }, []);

  useEffect(() => {
    onSubmit();
  }, [])



  const { t } = useTranslation();

  return (
    <main className="bg-gold-gradient--horizontal c-grey-dark text-m">
      <div className="wrapper-s wrapper-vertical-s-a d-flex fd-column ai-center minh-100vh">
        <nav>
          <Logotype />
        </nav>
        <div className="mt-11">
          <h2 className="heading-l-a ta-center c-blue-dark">
            {t("pages.authorization.email_verified_request.titles.1")}
          </h2>
        </div>
        <form className="mt-10 d-none" onSubmit={onSubmit}>
          <Button type="submit">{t("pages.authorization.email_verified_request.labels.1")}</Button>
        </form>
        {isLoading && (
          <>
          <div className="mt-07">
            <p>Verifying...</p>
            <div className="mt-02">
            <Loader />
            </div>
          </div>
          </>
        )}
        {error && (
          <div className="mt-07 w-100p ta-center c-red" style={{ maxWidth: "320px" }}>
            {t(errorTranslationKey)}
          </div>
        )}
      </div>
    </main>
  );
});

export default EmailVerificationRequest;
