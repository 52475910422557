const COLORS = {
  PRIMARY: "primary",
};

const SIZES = {
  MEDIUM: "medium",
  LARGE: "large",
  EXTRA_LARGE: "extraLarge",
};

export { COLORS, SIZES };
