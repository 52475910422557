import { observer } from "mobx-react-lite";
import React from "react";
import Layout from "../../templates/Layout";
import { PAGE_TITLES } from "../../../constants";
import PageTitle from "../../atoms/PageTitle";
import cs from "classnames";
import classes from "./Sponsors.module.scss";
import CorporateSponsors from "./components/CorporateSponsors";
import GoldPartners from "./components/GoldPartners";

const Sponsors = observer(() => {
  return (
    <>
      <PageTitle value={PAGE_TITLES.SPONSORS} />
      <Layout logotype="maor">
        <div className="bgc-white">
          <div className={cs(classes.container, "wrapper-m")}>
            <CorporateSponsors />
            <GoldPartners />
          </div>
        </div>
      </Layout>
    </>
  );
});

export default React.memo(Sponsors);
