import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import cs from "classnames";

import Icon from "../../../../../../atoms/Icon";
import IconButton from "../../../../../../atoms/IconButton";
import Modal from "../../../../../../organisms/Modal";
import { APPLICATION_ROUTES, PROJECTS } from "../../../../../../../constants";
import { useDateFormat, useModal, useQuery } from "../../../../../../../hooks";
import ModalBody from "../ModalBody";
import classes from "./DonationItem.module.scss";
import { getFormattedDay } from "../../../../../../../utilities/helpers";
import { StoreContext } from "../../../../../../../contexts";
import { useContext } from "react";
import pledgeDonationLogo from "../../../../../../../assets/images/pledge-cart.png"

const DonationItem = ({ data, item_id, isLoadingDelete, price, onDelete, interval, next_auto_renewal_date }) => {
  const { common } = useContext(StoreContext);
  const modal = useModal();
  const history = useHistory();
  const query = useQuery();

  const pledgeDonation = query.get("page") === "pledge" ? true : false;

  const onEdit = useCallback(() => {
    if (pledgeDonation) {
      history.push(`${APPLICATION_ROUTES.PLEDGE_DONATION_SECTION}&id=${item_id}`);
    } else {
      history.push(`${APPLICATION_ROUTES.PARTNERS_DONATION}?id=${item_id}`);
    }
    // history.push(`${APPLICATION_ROUTES.PARTNERS_DONATION}?id=${item_id}`);

  }, []);

  const { t } = useTranslation();
  const pricePerRecurring = price;
  let billingCycle = "";
  const processingFee = data.details.fee_include > 0 ? data.details.fee_include : null;
  const originalAmount = price - processingFee;
  const day = getFormattedDay(new Date(next_auto_renewal_date));

  if (common.get("options") == "donation") {
    interval = null
  } else {
    if (interval == 1) {
      billingCycle = " monthly";
    } else if (interval == 12) {
      billingCycle = " yearly";
    }
  }

  if (pledgeDonation) {
    billingCycle = "monthly";
    interval = 1;
  }

  return (
    <>
      <div className={cs(classes.container, classes.grid, pledgeDonation && classes.backgroundWhite)}>
        <div className={cs(classes.main, pledgeDonation && classes.pledgeItem)}>
          <div className={classes.package}>
            <div className={cs(classes.illustration, pledgeDonation && classes.imageWidth)}>
              {pledgeDonation ?
                <img src={pledgeDonationLogo} alt="pledgeDonationLogo" />
                :
                <Icon
                  name={PROJECTS.DONATION.ILLUSTRATION_NAME}
                  size="stretched"
                />
              }
            </div>
            {pledgeDonation ?
              <h4 className={cs(classes.title, classes.purple)}>{t("pages.checkout.titles.4")}</h4>
              :
              <h4 className={classes.title}>{t("pages.checkout.titles.2")}</h4>
            }
          </div>
        </div>
        <div className={cs(classes.details, pledgeDonation && classes.gridChangeForMobilePledge)}>
          <ul className={cs(classes.list, processingFee && classes.gridView)}>
            <li className={classes.item}>
              <div className={classes.icon}>
                {pledgeDonation ?
                  <Icon name="profile" color='purple' size="s" />
                  :
                  <Icon name="badge" size="s" />
                }
              </div>
              <div className={classes.text}>
                <b>
                  {data.details.first_name && (
                    <span className="mr-01">{data.details.first_name}</span>
                  )}
                  <span>{data.details.last_name}</span>
                </b>
              </div>
            </li>
            <li className={classes.item}>
              <div className={classes.icon}>
                {pledgeDonation ?
                  <Icon name="dollar-coin" size="s" color="purple" />
                  :
                  <Icon name="dollar-coin" size="s" />
                }
              </div>
              <div className={cs(classes.text, classes.donationText)}>
                {/* <b>
                  {t("pages.checkout.texts.2", { value: pricePerRecurring })}
                </b> */}
                <p>
                  <Trans
                    components={{
                      a: <b />,
                      price: "$" + pricePerRecurring,
                      billingCycle,
                    }}
                    // i18nKey={currentPlan ? "pages.checkout.texts.16" : "pages.checkout.texts.7"}
                    i18nKey={pledgeDonation ? "pages.checkout.texts.21" : "pages.checkout.texts.7"}
                    t={t}
                  />
                </p>
                {processingFee &&
                  <span>
                    <Trans
                      components={{
                        originalAmount: originalAmount.toFixed(2),
                        processingFee: processingFee.toFixed(2),
                      }}
                      // i18nKey={"pages.checkout.texts.20"}
                      i18nKey={"pages.checkout.texts.22"}
                      t={t}
                    />
                  </span>
                }
              </div>
            </li>

            {interval &&
              <li className={cs(classes.item, pledgeDonation)}>
                <div className={classes.icon}>
                  {pledgeDonation ?
                    <Icon name="purple-calender" size="s" color="primary" />
                    :
                    <Icon name="calendar" size="s" color="primary" />
                  }
                </div>
                <div className={classes.text}>
                  {pledgeDonation ? <p>You will be charged every <b> {day} </b> of the month.</p> :
                    <Trans
                      components={{
                        a: <b />,
                        date: useDateFormat(next_auto_renewal_date, "T"),
                      }}
                      // i18nKey={currentPlan ? "pages.checkout.texts.17" : "pages.checkout.texts.10"}
                      i18nKey={"pages.checkout.texts.10"}
                      t={t}
                    />
                  }

                </div>
              </li>}

          </ul>
        </div>
        <div className={cs(classes.options)}>
          <div className={classes.buttons}>
            <IconButton iconName="edit" iconSize="xs" onClick={onEdit} />
            <IconButton
              color="warning"
              iconName="x"
              iconSize="xs"
              onClick={modal.onOpen}
            />
          </div>
        </div>
      </div>
      {pledgeDonation && <p className={cs(classes.pledgeText, classes.purple)}>You can cancel/change your pledge at any time <br className={cs(classes.brTagDisplayMobile)} /> in your profile at Maor.org</p>}

      {/* {data.client.user_already_exist == 1 && (
        <div className={classes.infoMessage}>
          There is already an account linked to this email address, your
          password will remain the same.
        </div>
      )} */}
      <Modal opened={modal.isOpened} onClose={modal.onClose}>
        <ModalBody
          isLoading={isLoadingDelete}
          onClose={modal.onClose}
          onDelete={() => onDelete({ id: item_id, onSuccess: modal.onClose })}
        />
      </Modal>
    </>
  );
};

DonationItem.propTypes = {
  data: PropTypes.shape({
    details: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string.isRequired,
      fee_include: PropTypes.number.isRequired,
    }).isRequired,
    client: PropTypes.shape({
      user_already_exist: PropTypes.number,
    }).isRequired,
  }).isRequired,
  item_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isLoadingDelete: PropTypes.bool.isRequired,
  price: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  interval: PropTypes.number.isRequired,
  next_auto_renewal_date: PropTypes.string.isRequired,


};

export default React.memo(DonationItem);
