import { useCallback, useState, useContext } from "react";

import { useQuery, useRedirect } from "../../../hooks";
// import Login from "./components/Login";
// import Password from "./components/Password";
import useForm from "./useForm";
import SingInStep from "./components/SingInStep";

import { StoreContext } from "../../../contexts";
import { PROJECTS } from "../../../constants";

const useSteps = ({ reauthenticate, setClosePopUp, partnersCheckout }) => {
  const { onRedirect } = useRedirect();
  const { checkout, common } = useContext(StoreContext);
  const query = useQuery();

  console.log(reauthenticate,"reauthenticate")
  // eslint-disable-next-line prettier/prettier
  const {
    isNotValidForm,
    isNotValidLogin,
    isNotValidPassword,
    login,
    password,
    remember,
    onSubmit,
  } = useForm({
    onSuccess:
      reauthenticate == true
        ? () => {
          if (setClosePopUp !== null) {
            setClosePopUp(false);
            common.set("isAuthorize", "true");
            if (partnersCheckout) {
              checkout.createTransaction({
                projectID: PROJECTS.MY_MAOR.ID,
                reAuthenticated: true,
              });
            } else {
              checkout.createBirthdayTransaction({
                projectID: PROJECTS.MY_MAOR.ID,
                reAuthenticated: true,
              });
            }
          }
        }
        : () => {
          if (query.get('redirect')) {
            window.location = query.get('redirect')
          } else {
            onRedirect
          }
        },
  });


  const [stepIndex, setStepIndex] = useState(0);
  const steps = [
    {
      Component: SingInStep,
      fields: { login, password },
      isNotValid: isNotValidLogin || isNotValidPassword || isNotValidForm,
    },
    // { Component: Login, fields: { login }, isNotValid: isNotValidLogin },
    // { Component: Password, fields: { password }, isNotValid: isNotValidPassword || isNotValidForm },
  ];
  const allStepsLength = steps.length;
  const currentStepNumber = stepIndex + 1;
  const hasNextStep = currentStepNumber < allStepsLength;

  const fields = steps[stepIndex].fields;
  const isNotValid = steps[stepIndex].isNotValid;
  const Step = steps[stepIndex].Component;

  const goNextStep = useCallback((event) => {
    event.preventDefault();
    setStepIndex((index) => index + 1);
  });

  return {
    allStepsLength,
    currentStepNumber,
    fields,
    isNotValid,
    remember,
    Step,
    onSubmit: hasNextStep ? goNextStep : onSubmit,
  };
};

export default useSteps;
