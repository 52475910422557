import React, { useCallback, useState } from "react";

import Icon from "../../atoms/Icon";
import PlainInput from "../../atoms/PlainInput";
import classes from "./PasswordInput.module.scss";

const PasswordInput = ({ ...properties }) => {
  const [isHidden, setIsHidden] = useState(true);

  const type = isHidden ? "password" : "text";
  const name = isHidden ? "eye-closed" : "eye-opened";

  const onToggle = useCallback(() => setIsHidden((isHidden) => !isHidden), [setIsHidden]);

  return (
    <PlainInput type={type} {...properties}>
      <button tabIndex="-1" type="button" className={classes.button} onClick={onToggle}>
        <Icon name={name} size="s" />
      </button>
    </PlainInput>
  );
};

export default PasswordInput;
