import React from "react";
import Modal from "../Modal";
import classes from "./MailingModal.module.scss";
import PropTypes from "prop-types";
import useForm from "./useForm";
import { isMobile } from "react-device-detect";
import cs from "classnames";
import PlainInput from "../../atoms/PlainInput";
import Icon from "../../atoms/Icon";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { ACTIONS } from "../../../store";

const getError = (error) => {
  if (typeof error == "object" && error != null) {
    if (error.data) {
      for (const property in error.data) {
        return error.data[property];
      }
    }
  }
  return "Something went wrong. Please try again!";
};

const MailingModal = observer(({ popup, mailinglistID }) => {
  const {
    firstName,
    lastName,
    email,
    isNotValid,
    error,
    done,
    isLoading,
    mailinglist,
    submitMailingList,
  } = useForm({ mailinglistID });

  useEffect(() => {
    mailinglist.setIsLoading(ACTIONS.MAILINGLIST.SAVE, false);
    mailinglist.setError(ACTIONS.MAILINGLIST.SAVE, false);
    mailinglist.setSuccess(ACTIONS.MAILINGLIST.SAVE, false);
  }, []);

  const closeMailingList = () => {
    popup.onClose();
  };

  return (
    <Modal
      className={classes.subscribePopup}
      opened={popup.isOpened}
      style={popup.style}
    >
      <div
        data-name="modal-overlay"
        className={classes.overlay}
        role="button"
        onClick={(event) => {
          if (event.target.matches(`[data-name="modal-overlay"]`))
            popup.onClose();
        }}
      >
        <div className={cs(classes.card, isMobile ? classes.mobileCard : "")}>
          <h4 className={cs(classes.title, "mb-06")}>
            Subscribe to Get Email <br />
            Notifications
          </h4>
          <div className={classes.section}>
            <div
              className={cs(
                classes.list,
                classes.details,
                isMobile ? classes.mobileDetails : ""
              )}
            >
              <div>
                <div
                  className={cs(
                    classes.item,
                    classes.firstName,
                    "d-flex fw-wrap gap-6 mb-06"
                  )}
                >
                  <div
                    className={cs(
                      classes.inputWrap,
                      isMobile ? "fw-wrap gap-6" : "",
                      "d-flex gap-4 w-100"
                    )}
                  >
                    <PlainInput
                      error={firstName.error}
                      // additionalClaxss="smallInput"
                      id="mailing-form-first-name"
                      label={firstName.label}
                      stretched
                      value={firstName.value}
                      onChange={firstName.onChange}
                    />
                    <PlainInput
                      error={lastName.error}
                      // additionalClass="smallInput"
                      id="mailing-form-last-name"
                      label={lastName.label}
                      stretched
                      value={lastName.value}
                      onChange={lastName.onChange}
                    />
                  </div>

                  <div
                    className={cs(
                      classes.inputWrap,
                      isMobile ? "fw-wrap gap-6" : "",
                      "d-flex gap-4 w-100"
                    )}
                  >
                    <PlainInput
                      error={email.errorOfOriginal}
                      // additionalClass="smallInput"
                      id="mailing-form-email"
                      label={email.labelOfOriginal}
                      stretched
                      value={email.valueOfOriginal}
                      onChange={email.onChangeOriginal}
                    />
                    <PlainInput
                      error={email.errorOfConfirm}
                      id="mailing-form-email-confirm"
                      label={email.labelOfConfirm}
                      stretched
                      value={email.valueOfConfirm}
                      onChange={email.onChangeConfirm}
                    />
                  </div>
                </div>

                {error && (
                  <p className="ta-center">
                    <span
                      className={cs(classes.errorMsg, "fw-700 mb-04 d-block")}
                    >
                      {getError(error)}
                    </span>
                  </p>
                )}

                {done && (
                  <p className="ta-center">
                    <span
                      className={cs(classes.successMsg, "fw-700 mb-04 d-block")}
                    >
                      Successfully subscribed to Mailing List.
                    </span>
                  </p>
                )}

                {done ? (
                  <div className={cs(classes.addBtnWrap, "d-flex gap-2 ")}>
                    <button
                      className={cs(classes.addBtn, "fw-700 c-orange")}
                      type="submit"
                      onClick={closeMailingList}
                      disabled={isNotValid || isLoading}
                    >
                      Close
                    </button>
                  </div>
                ) : (
                  <div className={cs(classes.addBtnWrap, "d-flex gap-2 ")}>
                    <button
                      className={cs(classes.addBtn, "fw-700 c-orange")}
                      type="submit"
                      onClick={submitMailingList}
                      disabled={isNotValid || isLoading}
                    >
                      {isLoading ? (
                        "Please wait..."
                      ) : (
                        <>
                          <Icon name="letter" /> Get Email Notification
                        </>
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});

MailingModal.propTypes = {
  popup: PropTypes.object,
  authorization: PropTypes.object,
};

export default MailingModal;
