import React from "react";
import PropTypes from "prop-types";

import PasswordInput from "../../../../atoms/PasswordInput";

const Password = ({ password }) => {
  return (
    <PasswordInput
      error={password.error}
      id="sign-in-form-password"
      label={password.label}
      value={password.value}
      onChange={password.onChange}
    />
  );
};

const inputPropTypes = PropTypes.shape({
  error: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  touched: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}).isRequired;

Password.propTypes = {
  password: inputPropTypes,
};

export default Password;
