import React from "react";

import PageTitle from "../../atoms/PageTitle";
import Layout from "../../templates/Layout";
import { PAGE_TITLES } from "../../../constants";

import cs from "classnames";
import classes from "./IlluminateMain.module.scss";
import Member from "./Components/Member";
// import Package from "./Components/Package";
import VideosContainer from "./Components/VideosContainer";

const IlluminateMain = () => {
  return (
    <>
      <PageTitle value={PAGE_TITLES.ILLUMINATE} />
      <Layout logotype="illuminate" size="c" page="illuminate">
        <div className={cs(classes.container)}>
          <div className={cs(classes.memberContainer)}>
            <Member />
          </div>
          <div className={cs(classes.packageContainer, "bgc-pink")}>
            {/* <Package /> */}
          </div>
          <div className={cs(classes.videosContainer)}>
            <VideosContainer />
          </div>
        </div>
      </Layout>
    </>
  );
};

IlluminateMain.displayName = 'IlluminateMain';
export default React.memo(IlluminateMain);

