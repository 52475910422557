import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useContext } from "react";

import cs from "classnames";
import classes from "./VideoContainer.module.scss";
import PlaylistModal from "../../../../../../organisms/Playlist/PlaylistModal";
import Preview from "./components/Preview";
import { NavLink } from "react-router-dom";
import Loader from "../../../../../../atoms/Loader";
import { isMobile } from "react-device-detect";
import { APPLICATION_ROUTES } from "../../../../../../../constants";
import { StoreContext } from "../../../../../../../contexts";
import placeholder from "../../../../../../../assets/images/video-thumbnail-placeholder.png";
import useVideo from "../../../../../Library/components/Project/useVideo";
import { useModal, useQuery } from "../../../../../../../hooks";
import { scroller } from "react-scroll";
import useLibraryFilter from "../../../../../Library/useLibraryFilter";
import useMyMaor from "../../../../../MyMaorMain/useMyMaor";
import { AuthorizationCommon, AuthorizationKids } from "../../../../../../organisms/RestrictionModal/RestrictionModal";
import InfoContainer from "../InfoContainer/InfoContainer";
import { getDetails } from "../../../../../Library/components/Project/components/Preview/utilities";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import videoError from "../../../../../../../assets/images/video-error.png";
import PageMetaTags from "../../../../../../atoms/PageMetaTags";
import videoData from "../../../../../../../video.json";

const VideoContainer = observer(() => {
  const { authorization, partnership } = useContext(StoreContext);
  const { video, setVideoRequest } = useMyMaor();
  const { filterData } = useLibraryFilter();
  const popupKids = useModal();

  const videoAction = useVideo();
  const query = useQuery();
  const birthdaySection = query.get("birthday");
  // const videoId = query.get("video");
  const videoParams = useParams();
  const videoParamsLength = Object.values(videoParams).length;
  const [videoIdMatch, setVideoIdMatch] = useState(false);
  const [isVideoNotFree, setIsVideoNotFree] = useState(false);
  const [baseVideoCode, setBaseVideoCode] = useState(false);
  const [isVideoFreeWithoutSignIn, setIsVideoFreeWithoutSignIn] = useState(false);

  let videoInfo = video.record;
  let details;

  const isBase64 = (str) => {
    try {
      const decodedStr = atob(str);
      const lowerDecodedStr = decodedStr.toLowerCase();
      return ['k', 'd', 'i'].some(prefix => lowerDecodedStr.startsWith(prefix));
    } catch (err) {
      return false;
    }
  };


  if (videoInfo != null) {
    if (videoInfo.id) {
      details = getDetails("en", videoInfo.locales);
    }
  }

  useEffect(() => {
    if (videoInfo != null) {
      if (isBase64(videoParams.id) || baseVideoCode) {
        setIsVideoNotFree(false);
        setIsVideoFreeWithoutSignIn(true)
      } else if (videoInfo.free == false) {
        setIsVideoNotFree(true);
      }
    }
  }, [videoInfo]);

  useEffect(() => {
    if (isBase64(videoParams.id)) {
      setIsVideoNotFree(false);
      setBaseVideoCode(true);
      setIsVideoFreeWithoutSignIn(true)
      videoParams.id = atob(videoParams.id);
    }

    filterData.holidaysToday.onLoad();
    //To check if the video-id params has any id then send the id-value to the API as payload
    if (videoParamsLength > 0) {
      setVideoRequest(videoParams);
    } else {
      setVideoRequest({ project_type: "Illuminate" });
    }

    video.onLoad();

    if (birthdaySection != null) {
      scroller.scrollTo("packages", {
        duration: 500,
        smooth: true,
        offset: -24,
      });
    }
  }, []);

  // To check if the video-params-id has correct id-value in video.json if yes then check match the video-id number within json file and change the description and title of the page
  let videoNumber = videoParams.id;
  let videoTitle = "";
  let videoDescription = "";

  const keys = Object.keys(videoData);

  if (videoNumber != undefined) {
    let videoNumberLowerCase = videoNumber.toLowerCase();
    useEffect(() => {
      for (const key of keys) {
        if (key === videoNumberLowerCase) {
          setVideoIdMatch(true);
        }
      }
    }, []);
    console.log(videoNumberLowerCase, "videoo");

    // check match the video-id number within json file and change the description and title of the page
    if (videoIdMatch) {
      if (videoNumber != undefined && videoData) {
        videoTitle = videoData[videoNumberLowerCase].title;
        videoDescription = videoData[videoNumberLowerCase].description;
      }
    }
  }

  return (
    <>
      {videoIdMatch && (
        <PageMetaTags
          videoDescription={videoDescription}
          videoTitle={videoTitle}
        />
      )}
      <div
        className={cs(
          classes.videoContentMain,
          isMobile ? classes.mobileVideoContent : ""
        )}
      >
        {!video.isLoading ? (
          <>
            {videoInfo == null ? (
              <div className={classes.videoNotFoundContainer}>
                <div className={classes.videoNotFound}>
                  <img src={videoError} />
                  <h2>Video not found!</h2>
                </div>
              </div>
            ) : (
              <>
                {videoInfo.id && (
                  <>
                    <div className={classes.videoWrapMain}>
                      <div className={classes.videoBox}>
                        <div className={classes.videoBoxInner}>
                          {/* Video */}
                          <div className={classes.videoPlayer}>
                            <>
                              {videoAction.isOpened &&
                                (isVideoFreeWithoutSignIn || authorization.isAuthenticated) ? (
                                <>
                                  {isVideoNotFree ? (
                                    <>
                                      {!authorization.isUserMember ? (
                                        <Preview
                                          {...videoInfo}
                                          internal_id={videoInfo.id}
                                          placeholder={placeholder}
                                          onSelect={videoAction.onOpen}
                                          videoAction={videoAction}
                                        />
                                      ) : (
                                        <PlaylistModal
                                          video={videoAction}
                                          hideLibrary="1"
                                          hasPlaylist={true}
                                          page="illuminate"
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <PlaylistModal
                                      video={videoAction}
                                      hideLibrary="1"
                                      hasPlaylist={true}
                                      page="illuminate"
                                    />
                                  )}
                                </>
                                // <PlaylistModal
                                //   video={videoAction}
                                //   hideLibrary="1"
                                //   hasPlaylist={true}
                                //   page="illuminate"
                                // />
                              ) : (
                                <Preview
                                  {...videoInfo}
                                  internal_id={videoInfo.id}
                                  placeholder={placeholder}
                                  onSelect={videoAction.onOpen}
                                  videoAction={videoAction}
                                />
                              )}
                            </>
                          </div>
                        </div>
                        {/* Video Tags */}
                        {!videoAction.isOpened && (
                          <div className={classes.videoTags}>
                            {videoInfo.tags &&
                              videoInfo.tags.map((tag, index) => (
                                <NavLink
                                  target="_blank"
                                  key={tag.id + index}
                                  to={`${APPLICATION_ROUTES.LIBRARY}?keyword=${tag.name_english}`}
                                >
                                  #{tag.name_english}
                                </NavLink>
                              ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={cs(classes.ContentWrapMain)}>
                      <InfoContainer details={details} />
                    </div>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <div className={classes.loadingBox}>
            <p className={classes.innerBox}>
              <Loader size="large" />
              <h3>Please Wait...</h3>
            </p>
          </div>
        )}

        <AuthorizationKids
          popup={popupKids}
          authorization={authorization}
          partnership={partnership}
        />

        <section>
          {!isVideoFreeWithoutSignIn ?
            (
              authorization.isAuthenticated ? (
                <>
                  {isVideoNotFree ? (
                    <>
                      {" "}
                      {!authorization.isUserMember ? (
                        <AuthorizationCommon popup={videoAction} />
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  {isVideoNotFree ? (
                    <>
                      {!authorization.isUserMember ? (
                        <AuthorizationCommon popup={videoAction} />
                      ) : (
                        ""
                      )}{" "}
                    </>
                  ) : (
                    <AuthorizationKids popup={videoAction} />
                  )}
                </>
              ))
            :
            ""
          }
        </section>
      </div>
    </>
  );
});

export default React.memo(VideoContainer);
