import API from "./_api";
import { API_ROUTES } from "../constants";

const ProductsAPI = class extends API {
  getCampaign() {
    return this.request(API_ROUTES.PRODUCTS.GET_CAMPAIGN, {
      method: "GET",
    });
  }

  getMembers({ page }) {
    return this.request(`${API_ROUTES.PRODUCTS.GET_MEMBERS}?page=${page}`, {
      method: "GET",
    });
  }

  getActiveMembers() {
    return this.request(`${API_ROUTES.PRODUCTS.GET_ACTIVE_MEMBERS}`, {
      method: "GET",
    });
  }
};

export default ProductsAPI;
