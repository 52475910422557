/* TODO: release other languages */

import en from "./translations/en.json";
import fr from "./translations/fr.json";
import he from "./translations/he.json";
import ru from "./translations/ru.json";

const detection = {
  caches: ["localStorage", "cookie"],
  checkWhitelist: true,
  excludeCacheFor: ["cimode"],
  lookupCookie: "locale",
  lookupLocalStorage: "locale",
  order: ["cookie", "localStorage", "navigator"],
};

const options = {
  detection: detection,
  fallbackLng: "en",
  interpolation: { escapeValue: false },
  resources: {
    en: { translation: en },
    fr: { translation: fr },
    he: { translation: he },
    ru: { translation: ru },
  },
  whitelist: ["en", "fr", "he", "ru"],
};

export { options };
