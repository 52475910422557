const ACTIONS = {
  GET_PM: "get_pm",
  GET_TOKEN: "get_pm_token",
  ADD_PM: "add_pm",
  REMOVE_PM: "remove_pm",
  DEFAULT_PM: "default_pm",
  SAVE: "save",
};

export { ACTIONS };
