import React, { useEffect } from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import { COLORS, SIZES } from "./utilities";
import classes from "./AsyncAutocompleteSearch.module.scss";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

const AsyncAutocompleteSearch = ({
  children: icon,
  error,
  page,
  id,
  type,
  isSearching,
  onSearch,
  searchingOptions,
  onChange,
  value,
  ...properties
}) => {
  const ref = React.createRef();

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onChange(e.target.value);
      ref.current.hideMenu();
    }
  };

  useEffect(() => {
    console.log("Searvh Value", value);
    let timer = setTimeout(() => {
      document.querySelector(".rbt-input-main").value = value;
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [value]);
  const addClasses = () => {
    if (page == "library") {
      return classes.library;
    }
    if (page == "kids") {
      return classes.kidsLayout;
    }
    if (page == "illuminate") {
      return classes.illuminate;
    }
    if (page == "daily-rebbe-video") {
      return classes.dailyRebbeVideo;
    }
    return classes[page];
  };
  return (
    <div className={cs(classes.container, addClasses())}>
      <AsyncTypeahead
        emptyLabel=""
        ref={ref}
        isLoading={isSearching}
        onSearch={onSearch}
        options={searchingOptions}
        id={id}
        type={type}
        onChange={(value) => {
          onChange(value.pop());
        }}
        onInputChange={(text) => {
          try {
            let items = document.querySelector("#async-autocomplete-search");
            let firstChild = items.firstChild;
            if (firstChild.classList.contains("disabled")) {
              items.style.visibility = "hidden";
            } else {
              items.style.visibility = "";
            }
          } catch (e) {
            console.warn("Soft Warn:", e);
          }
          if (text == "") {
            ref.current.clear();
          }
        }}
        defaultInputValue={value}
        onKeyDown={handleKeyDown}
        // inputProps={{
        //   value: value,
        // }}
        // clearButton={true}
        className={cs(classes.asyncWrapper, {
          [classes.error]: error,
          [classes.withIcon]: icon,
        })}
        {...properties}
      />
      {icon && (
        <div className={cs(classes.icon, { [classes.error]: error })}>
          {icon}
        </div>
      )}
      <button
        style={{ display: "none" }}
        id="hiddenAsyncInputClear"
        onClick={() => {
          ref.current.clear();
        }}
      >
        Clear
      </button>
    </div>
  );
};

AsyncAutocompleteSearch.displayName = "AsyncAutocompleteSearch";

AsyncAutocompleteSearch.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(Object.values(COLORS)),
  error: PropTypes.string,
  id: PropTypes.string,
  page: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
  stretched: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.oneOf(["number", "password", "text"]),
  value: PropTypes.string,
  isSearching: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  searchingOptions: PropTypes.array,
};

AsyncAutocompleteSearch.defaultProps = {
  children: null,
  color: COLORS.PRIMARY,
  error: "",
  page: "",
  size: SIZES.MEDIUM,
  stretched: false,
  label: "",
  type: "text",
  isSearching: false,
  searchingOptions: [],
};

export default AsyncAutocompleteSearch;
