import { makeAutoObservable } from "mobx";
import dropin from "braintree-web-drop-in";

import { CheckoutAPI } from "../../api";
import { COOKIES } from "../../constants";
import Cookies from "../../utilities/cookies";
import { ACTIONS } from "./utilities";

const checkoutAPI = new CheckoutAPI();

const Checkout = class {
  constructor({ store }) {
    const actions = Object.values(ACTIONS);

    for (let i = 0; i < actions.length; i++) {
      const ACTION = actions[i];

      this[`isLoading${ACTION}`] = false;
      this[`error${ACTION}`] = "";
    }

    this.store = store;
    this.gateway = null;
    this.id = null;
    this.token = null;
    this.transaction = null;
    this.reAuthenticated = false;
    this.videoDates = null;
    this.orderDeatils = null;

    makeAutoObservable(this);
  }

  setIsLoading(name, value) {
    this[`isLoading${name}`] = value;
  }

  setError(name, value) {
    this[`error${name}`] = value;
  }

  setValue(name, value) {
    this[name] = value;
  }

  get hasTransition() {
    return !!this.transaction;
  }

  get hasNoTransition() {
    return !this.transaction;
  }

  get hasToken() {
    return !!this.token;
  }

  get hasNoToken() {
    return !this.token;
  }

  clear() {
    const actions = Object.values(ACTIONS);

    for (let i = 0; i < actions.length; i++) {
      const ACTION = actions[i];

      this[`isLoading${ACTION}`] = false;
      this[`error${ACTION}`] = "";
    }

    this.gateway = null;
    this.id = null;
    this.token = null;
    this.transaction = null;
  }

  createTransaction({ projectID, onSuccess, reAuthenticated = false  }) {
    this.setIsLoading(ACTIONS.CREATE_TRANSACTION, true);
    this.setError(ACTIONS.CREATE_TRANSACTION, "");

    const anonymous = Boolean(Cookies.get({ name: COOKIES.ANONYMOUS_PAYMENT }));
    const cartID = Cookies.get({ name: COOKIES.CART_ID });
    const locale = Cookies.get({ name: COOKIES.LOCALE });
    const isAutoSignin = Cookies.get({ name: COOKIES.AUTO_SIGNIN });

    if (isAutoSignin) {
      reAuthenticated = true;
    }

    checkoutAPI
      .createTransaction({
        anonymous: anonymous,
        cartID: cartID,
        language: locale,
        projectID: projectID,
        reAuthenticated: reAuthenticated,
      })
      .then((response) => {
        if (response.status === 201) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        this.store.authorization.saveUser(data.user);
        this.setValue("id", data.transaction_id);
        this.setValue("gateway", data.payment.gateway);
        this.setValue("token", data.payment.token);
        this.setValue("transaction", data);

        Cookies.set({ name: COOKIES.PAYMENT, value: data.payment.token });
        this.setIsLoading(ACTIONS.CREATE_TRANSACTION, false);

        onSuccess && onSuccess(data.transaction_id);
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.CREATE_TRANSACTION, message);
        this.setIsLoading(ACTIONS.CREATE_TRANSACTION, false);
      });
  }

  createBirthdayTransaction({ projectID, onSuccess, reAuthenticated = false }) {
    this.setIsLoading(ACTIONS.CREATE_TRANSACTION, true);
    this.setError(ACTIONS.CREATE_TRANSACTION, "");

    const anonymous = Boolean(Cookies.get({ name: COOKIES.ANONYMOUS_PAYMENT }));
    const cartID = Cookies.get({ name: COOKIES.CART_ID });
    const locale = Cookies.get({ name: COOKIES.LOCALE });

    checkoutAPI
      .createBirthdayTransaction({
        anonymous: anonymous,
        cartID: cartID,
        language: locale,
        projectID: projectID,
        reAuthenticated: reAuthenticated,
      })
      .then((response) => {
        if (response.status === 201) return response.json();
        throw new Error(response.status);
      })
      .then(({ data }) => {
        this.store.authorization.saveUser(data.user);
        this.setValue("id", data.transaction_id);
        this.setValue("gateway", data.payment.gateway);
        this.setValue("token", data.payment.token);
        this.setValue("transaction", data);
        this.setValue("orderDeatils", data.order_Deatils);

        Cookies.set({ name: COOKIES.PAYMENT, value: data.payment.token });
        this.setIsLoading(ACTIONS.CREATE_TRANSACTION, false);

        onSuccess && onSuccess(data.transaction_id);
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.CREATE_TRANSACTION, message);
        this.setIsLoading(ACTIONS.CREATE_TRANSACTION, false);
      });
  }

  initialize({
    donationRecurring,
    buttonSelector,
    containerSelector,
    locale,
    onSaleSuccess,
  }) {
    const gateway = this.gateway;

    // this.setIsLoading(ACTIONS.INITIALIZE_PAYMENT_METHOD, true);
    const token = this.token;
    const container = document.querySelector(containerSelector);
    /* Note: clear up the DOM-node before Braintree SDK will use it */
    if (container) container.innerHTML = "";

    console.log(donationRecurring, "okk");

    dropin
      .create({
        authorization: token,
        container: containerSelector,
        locale: locale,
        paypal: {
          flow: "vault",
        },
        preselectVaultedPaymentMethod: true,
      })
      .then((instance) => {
        const button = document.querySelector(buttonSelector);

        button.addEventListener("click", () => {
          this.setIsLoading(ACTIONS.REQUEST_PAYLOAD_METHOD, true);

          instance
            .requestPaymentMethod()
            .then((payload) => {
              this.setIsLoading(ACTIONS.REQUEST_PAYLOAD_METHOD, false);
              this.sale({
                gateway: gateway,
                nonce: payload.nonce,
                onSuccess: onSaleSuccess,
                donationRecurring: donationRecurring,
              });
            })
            .catch(({ message }) => {
              this.setError(ACTIONS.REQUEST_PAYLOAD_METHOD, message);
              this.setIsLoading(ACTIONS.REQUEST_PAYLOAD_METHOD, false);
            });
        });

        // this.setIsLoading(ACTIONS.INITIALIZE_PAYMENT_METHOD, false);
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.INITIALIZE_PAYMENT_METHOD, message);
        // this.setIsLoading(ACTIONS.INITIALIZE_PAYMENT_METHOD, false);
      });
  }

  initializeBirthdayTransaction({
    buttonSelector,
    containerSelector,
    locale,
    onSaleSuccess,
  }) {
    // this.setIsLoading(ACTIONS.INITIALIZE_PAYMENT_METHOD, true);
    const token = this.token;
    const container = document.querySelector(containerSelector);

    /* Note: clear up the DOM-node before Braintree SDK will use it */
    if (container) container.innerHTML = "";
    dropin
      .create({
        authorization: token,
        container: containerSelector,
        locale: locale,
        paypal: {
          flow: "vault",
        },
        preselectVaultedPaymentMethod: true,
      })
      .then((instance) => {
        const button = document.querySelector(buttonSelector);
        button.addEventListener("click", () => {
          const videoData = this.videoDates;
          console.log(videoData);
          this.setIsLoading(ACTIONS.REQUEST_PAYLOAD_METHOD, true);
          let amt = document.querySelector("#grand-total");
          if (amt && amt.value == 0) {
            this.setIsLoading(ACTIONS.REQUEST_PAYLOAD_METHOD, false);
            this.birthdayPackageSale({
              nonce: null,
              onSuccess: onSaleSuccess,
              videoDates: videoData,
            });
          } else {
            instance
              .requestPaymentMethod()
              .then((payload) => {
                this.setIsLoading(ACTIONS.REQUEST_PAYLOAD_METHOD, false);
                this.birthdayPackageSale({
                  nonce: payload.nonce,
                  onSuccess: onSaleSuccess,
                  videoDates: videoData,
                });
              })
              .catch(({ message }) => {
                this.setError(ACTIONS.REQUEST_PAYLOAD_METHOD, message);
                this.setIsLoading(ACTIONS.REQUEST_PAYLOAD_METHOD, false);
              });
          }
        });

        // this.setIsLoading(ACTIONS.INITIALIZE_PAYMENT_METHOD, false);
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.INITIALIZE_PAYMENT_METHOD, message);
        // this.setIsLoading(ACTIONS.INITIALIZE_PAYMENT_METHOD, false);
      });
  }

  sale({ gateway, nonce, onSuccess, donationRecurring }) {
    this.setIsLoading(ACTIONS.SALE, true);
    this.setError(ACTIONS.SALE, "");

    const from = Cookies.get({ name: COOKIES.INVITING_USER_ID });
    const language = Cookies.get({ name: COOKIES.LOCALE });
    const token = Cookies.get({ name: COOKIES.CART_ID });
    const transactionID = this.id;

    let page = "";

    try {
      let url = new URL(location.href);
      page = url.searchParams.get("page");
    } catch (e) {
      console.log(e);
    }
    checkoutAPI
      .sale({
        from: from,
        language: language,
        nonce: nonce,
        gateway: gateway,
        cartID: token,
        transactionID: transactionID,
        page: page,
        donation_recurring: donationRecurring,
      })
      .then((response) => {
        if (response.status === 200) return response.json();
        let url = new URL(location.href);
        url.searchParams.set(
          "error",
          "There was an error in processing your request. Please check your details and try again."
        );
        window.location.href = url;
        throw new Error(response.status);
      })
      .then(() => {
        Cookies.delete({ name: COOKIES.CART_ID });
        Cookies.delete({ name: COOKIES.INVITING_USER_ID });
        Cookies.delete({ name: COOKIES.PAYMENT });
        this.setIsLoading(ACTIONS.SALE, false);

        onSuccess && onSuccess();
      })
      .catch(({ message }) => {
        this.setError(ACTIONS.SALE, message);
        this.setIsLoading(ACTIONS.SALE, false);
      });
  }

  birthdayPackageSale({ nonce, onSuccess, videoDates }) {
    this.setIsLoading(ACTIONS.SALE, true);
    this.setError(ACTIONS.SALE, "");

    const from = Cookies.get({ name: COOKIES.INVITING_USER_ID });
    const language = Cookies.get({ name: COOKIES.LOCALE });
    const token = Cookies.get({ name: COOKIES.CART_ID });

    const transactionID = this.id;

    checkoutAPI
      .credit({
        from: from,
        nonce: nonce,
        gateway: this.gateway,
        language: language,
        transactionID: transactionID,
        cartID: token,
        videoDates: videoDates,
      })
      .then(async (response) => {
        if (response.status === 200 || response.status === 201)
          return response.json();
        if (response.status == 422) {
          let data = await response.json();
          throw new Error(JSON.stringify(data));
        }
        throw new Error(response);
      })
      .then(() => {
        Cookies.delete({ name: COOKIES.CART_ID });
        Cookies.delete({ name: COOKIES.INVITING_USER_ID });
        Cookies.delete({ name: COOKIES.PAYMENT });
        this.setIsLoading(ACTIONS.SALE, false);
        console.log(onSuccess);
        onSuccess && onSuccess();
      })
      .catch((error) => {
        console.log("message", error.message);
        let data = JSON.parse(error.message);
        if (data.type) {
          this.setError(ACTIONS.SALE, data.type);
        } else {
          // this.setError(ACTIONS.SALE, "");
          this.setError(ACTIONS.SALE, data.errors.message);
        }
        this.setIsLoading(ACTIONS.SALE, false);
      });
  }

  updateTransaction({ credits, onSuccess }) {
    this.setIsLoading(ACTIONS.UPDATE_TRANSACTION, true);
    this.setError(ACTIONS.UPDATE_TRANSACTION, "");

    const transactionID = this.id;
    onSuccess && onSuccess();
    checkoutAPI
      .updateTransaction({
        transactionID: transactionID,
        credits: credits,
      })
      .then(async (response) => {
        if (response.status === 200 || response.status === 201)
          return response.json();
        if (response.status == 422) {
          let data = await response.json();
          throw new Error(JSON.stringify(data));
        }
        throw new Error(response);
      })
      .then(() => {
        this.setIsLoading(ACTIONS.UPDATE_TRANSACTION, false);
        console.log(onSuccess);
        onSuccess && onSuccess();
      })
      .catch((error) => {
        console.log("message", error.message);
        let data = JSON.parse(error.message);
        if (data.type) {
          this.setError(ACTIONS.UPDATE_TRANSACTION, data.type);
        } else {
          // this.setError(ACTIONS.UPDATE_TRANSACTION, "");
          this.setError(ACTIONS.UPDATE_TRANSACTION, error.message);
        }
        this.setIsLoading(ACTIONS.UPDATE_TRANSACTION, false);
      });
  }
};

export default Checkout;
