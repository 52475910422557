import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import cs from "classnames";

import classes from "./ErrorBoundary.module.scss";
import { StoreContext } from "../../../contexts";

class ErrorBoundary extends React.Component {
  static contextType = StoreContext;
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {

  }

  componentDidCatch(error) {

    let componentName;
    
    try {
      const stackLines = error.stack.split('\n');
      const errorLocation = stackLines[1];

      const componentNameRegex = /\/(.*?)\.js/;
      const match = errorLocation.match(componentNameRegex);
      componentName = match ? match[1] : 'Unknown';
      error = error.toString();
      componentName = componentName.toString();
    } catch (e) {
      console.log(e);
    }

    const { notifyError } = this.context;

    notifyError.notifyError({ error: `${error},\n location: ${componentName}` });
  }

  render() {
    const { t } = this.props;
    const TITLE = t("generals.warnings.1");

    if (this.state.hasError) {
      return (
        <div className={classes.container}>
          <div className={cs(classes.wrapper, "wrapper-xl")}>
            <h2 className={classes.title}>{TITLE}</h2>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ErrorBoundary);
