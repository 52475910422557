import { PROJECTS } from "../../../../../constants";
import BirthdayItem from "./components/BirthdayItem";

const getItem = (id) => {
  switch (id) {
    case PROJECTS.MY_MAOR.PACKAGES.SINGLE_LAYER.ID:
    case PROJECTS.MY_MAOR.PACKAGES.DOUBLE_LAYER.ID:
    case PROJECTS.MY_MAOR.PACKAGES.TRIPLE_DECKER.ID:
    case PROJECTS.MY_MAOR.PACKAGES.SEVEN_LAYER_EXPLOSION.ID:
      return BirthdayItem;
    default:
      return null;
  }
};

export { getItem };
