import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { calculateDateDifference } from "../../../utilities/helpers";

/* TODO: optimize count of called function */

const TimePassedCounter = ({ end, start }) => {
  const difference = calculateDateDifference(start, end);

  const { t } = useTranslation();
  const RIGHT_NOW = t("generals.dates.right_now");
  const ONE_MINUTE_AGO = t("generals.dates.one_minute_ago");
  const ONE_HOUR_AGO = t("generals.dates.one_hour_ago");
  const ONE_DAY_AGO = t("generals.dates.one_day_ago");
  const ONE_WEEK_AGO = t("generals.dates.one_week_ago");
  const ONE_MONTH_AGO = t("generals.dates.one_month_ago");
  const ONE_YEAR_AGO = t("generals.dates.one_year_ago");
  const VALUE_MINUTES_AGO = t("generals.dates.value_minutes_ago", { value: difference.minutes });
  const VALUE_HOURS_AGO = t("generals.dates.value_hours_ago", { value: difference.hours });
  const VALUE_DAYS_AGO = t("generals.dates.value_days_ago", { value: difference.days });
  const VALUE_WEEKS_AGO = t("generals.dates.value_weeks_ago", { value: difference.weeks });
  const VALUE_MONTHS_AGO = t("generals.dates.value_months_ago", { value: difference.months });
  const VALUE_YEARS_AGO = t("generals.dates.value_years_ago", { value: difference.years });

  if (difference.years > 1) return <>{VALUE_YEARS_AGO}</>;
  if (difference.years > 0) return <>{ONE_YEAR_AGO}</>;

  if (difference.months > 1) return <>{VALUE_MONTHS_AGO}</>;
  if (difference.months > 0) return <>{ONE_MONTH_AGO}</>;

  if (difference.weeks > 1) return <>{VALUE_WEEKS_AGO}</>;
  if (difference.weeks > 0) return <>{ONE_WEEK_AGO}</>;

  if (difference.days > 1) return <>{VALUE_DAYS_AGO}</>;
  if (difference.days > 0) return <>{ONE_DAY_AGO}</>;

  if (difference.hours > 1) return <>{VALUE_HOURS_AGO}</>;
  if (difference.hours > 0) return <>{ONE_HOUR_AGO}</>;

  if (difference.minutes > 1) return <>{VALUE_MINUTES_AGO}</>;
  if (difference.minutes > 0) return <>{ONE_MINUTE_AGO}</>;

  return <>{RIGHT_NOW}</>;
};

TimePassedCounter.propTypes = {
  end: PropTypes.instanceOf(Date),
  start: PropTypes.instanceOf(Date),
};

TimePassedCounter.defaultProps = {
  end: new Date(),
  start: new Date(),
};

export default React.memo(TimePassedCounter);
