const PARTNERSHIP_STATUS = {
  ACTIVE: "active",
  PENDING: "pending",
  CANCELED: "canceled",
  EXPIRED: "expired",
  FREE: "free",
  PAUSED: "paused",
  PAST_DUE: "past due",
};

export default PARTNERSHIP_STATUS;
