/* eslint-disable */
import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import cs from "classnames";

import Button from "../../atoms/Button";
import Loader from "../../atoms/Loader";
import Logotype, { TYPES as LOGOTYPE_TYPES } from "../../atoms/Logotype";
import Modal from "../../organisms/Modal";
import thumbnail from "../../../assets/images/video-thumbnail-placeholder.png";
import { APPLICATION_ROUTES } from "../../../constants";
import { StoreContext } from "../../../contexts";
import Preview from "./components/Preview";
import useVideo from "./useVideo";
import classes from "./Project.module.scss";
import { NavLink } from "react-router-dom";
import PlaylistModal from "../../organisms/Playlist/PlaylistModal";
import { ACTIONS } from "../../../store";
import Icon from "../../atoms/Icon";
import { getURL } from "./components/Preview/utilities";
import FeatureModal from "../../organisms/FeatureModal/FeatureModal";
import { useModal } from "../../../hooks";
import { BrowserView, isMobile } from "react-device-detect";
import {
  Authorization,
  AuthorizationKids,
} from "../../organisms/RestrictionModal/RestrictionModal";
import VideoSlider from "../../templates/VideoSlider/VideoSlider";

const Project = observer(
  ({
    error,
    hasItems,
    hasNextPage,
    isLoading,
    items,
    placeholder,
    onLoad,
    search,
    order,
    videoType,
    resetPage,
    setRequestPayload,
    holidayId,
    totalItems,
    projectType,
    limitRecord,
    freeAccess,
    coloringPages,
    appliedFilters,
    myMaorPage,
  }) => {
    const { authorization, playlist, partnership } = useContext(StoreContext);
    const featureModal = useModal();
    const [freeDonorWatch, setFreeDonorWatch] = useState(false);
    const [showAllList, setShowAllList] = useState(false);

    // const history = useHistory();

    // const onNavigateToPartnersMain = useCallback(() => {
    //   history.push(APPLICATION_ROUTES.PARTNERS_PACKAGES);
    // }, []);

    // const onNavigateToSignIn = useCallback(() => {
    //   history.push(APPLICATION_ROUTES.SIGN_IN);
    //   scrollToTop();
    // }, []);

    console.log(isLoading, "is loading");
    const video = useVideo();
    const showModalOnPlaylist = useModal();

    const { t } = useTranslation();

    let fdaSession = "";
    if (navigator.cookieEnabled) {
      fdaSession = sessionStorage.getItem("fda");
    }

    useEffect(() => {
      if (fdaSession === "1") {
        setFreeDonorWatch(true);
      }
    }, [fdaSession]);

    useEffect(() => {
      const timer = setTimeout(() => {
        resetPage();
        setRequestPayload({
          search: search,
          order: order,
          videoType: videoType,
          holidayId: holidayId,
          limitRecord: limitRecord,
          coloringPages: coloringPages.value,
        });
        onLoad();
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }, [search, order, videoType, coloringPages.value, limitRecord, holidayId]);

    if (projectType == "my-maor" && video.number == 1000) {
      freeAccess = true;
    }

    // if (!authorization.isAuthenticated) {
    //   freeAccess = false;
    // }

    const toggleShowList = () => {
      setShowAllList(!showAllList);
    };

    useEffect(() => {
      // if (isMobile) {
        setShowAllList(true);
      // }
    }, [isMobile]);

    return (
      <div
        className={cs(
          classes.container,
          classes.orange,
          "wrapper-l",
          isMobile && "mt-08"
        )}
      >
        <div className={classes.main}>
          {/* {!isMobile ? (
            <BrowserView> */}
          <div className={classes.header}>
            <div className={classes.logoWrap}>
              <div className={classes.logotype}>
                {/* <div className={classes.logotypeWrapper}>
                      <Logotype type={logotype} size="m" url="#" />
                    </div> */}
                <div>
                  <p className={classes.projectTitle}>Search Results</p>
                  {totalItems == 0 || totalItems == "" ? (
                    <small className={classes.totalVideosCount}>
                      {t("pages.library.labels.no_videos")}
                    </small>
                  ) : (
                    <small className={classes.totalVideosCount}>
                      {totalItems > 1
                        ? t("pages.library.labels.video_count_plural", {
                            value: totalItems,
                          })
                        : t("pages.library.labels.video_count_singular", {
                            value: totalItems,
                          })}
                    </small>
                  )}
                </div>
              </div>
              {hasItems && (
                <div className={cs(classes.sideHeader)}>
                  {showAllList && (
                    <>
                      {search != "" ||
                      videoType != "" ||
                      holidayId != "" ||
                      coloringPages ? (
                        <div>
                          <button
                            className={classes.playlistBtn}
                            title="Please sign in to add videos to Playlist"
                            disabled={playlist.getIsLoading(
                              ACTIONS.PLAYLIST.ADD_SEARCH_FILTER
                            )}
                            onClick={() => {
                              if (
                                authorization.isAuthenticated &&
                                authorization.isUserMember
                              ) {
                                playlist.addSearchFilterVideos({
                                  payload: {
                                    search: search,
                                    order: order,
                                    videoType: videoType,
                                    holidayId: holidayId,
                                    project: projectType,
                                    coloringPages: coloringPages.value,
                                  },
                                  onSuccess: () => {
                                    playlist.get({ onSuccess: null });
                                  },
                                });
                              } else {
                                showModalOnPlaylist.onOpen();
                              }
                            }}
                          >
                            {playlist.getIsLoading(
                              ACTIONS.PLAYLIST.ADD_SEARCH_FILTER
                            ) ? (
                              "Loading..."
                            ) : (
                              <div className={cs(classes.addToPlayList)}>
                                {isMobile ? (
                                  <Icon name="playlist-icon-white" />
                                ) : (
                                  <Icon name={"orange-playlist"} />
                                )}
                                <p>Add all to playlist</p>
                              </div>
                            )}
                          </button>
                        </div>
                      ) : null}
                    </>
                  )}
                  <div>
                    <p
                      onClick={toggleShowList}
                      className={cs(classes.viewAllText)}
                    >
                      {showAllList ? "Hide" : "View All"}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* </BrowserView>
          ) : null} */}

          {hasItems ? (
            <>
              <ul
                className={cs(
                  !showAllList ? classes.showASlider : classes.hideSlider
                )}
              >
                <VideoSlider>
                  {items.map((item, index) => (
                    <li
                      data-find="1"
                      key={item.id + "_" + index}
                      className={cs(classes.item, classes.itemPadding)}
                    >
                      <Preview
                        {...item}
                        projectType={projectType}
                        internal_id={item.id}
                        placeholder={placeholder}
                        onSelect={video.onOpen}
                        showAllList={showAllList}
                      />
                    </li>
                  ))}
                  {items.length > 4 && (
                    <li className={cs(classes.itemPadding)}>
                      <div
                        onClick={toggleShowList}
                        className={cs(classes.viewAllTextSlider)}
                      >
                        <p className={cs(classes.viewAllText)}>View All</p>
                      </div>
                    </li>
                  )}
                </VideoSlider>
              </ul>
              <ul
                className={cs(
                  showAllList ? classes.listShown : classes.listHide
                )}
              >
                {items.map((item, index) => (
                  <li
                    data-find="1"
                    key={item.id + "_" + index}
                    className={classes.item}
                  >
                    <Preview
                      {...item}
                      projectType={projectType}
                      internal_id={item.id}
                      placeholder={placeholder}
                      onSelect={video.onOpen}
                      showAllList={showAllList}
                    />
                  </li>
                ))}
              </ul>
              {freeAccess && freeDonorWatch ? (
                <Modal
                  hasPlaylist={true}
                  opened={video.isOpened}
                  style={video.style}
                >
                  <PlaylistModal video={video} />
                </Modal>
              ) : (
                <>
                  {!authorization.isAuthenticated && freeAccess ? (
                    <AuthorizationKids
                      popup={video}
                      authorization={authorization}
                      partnership={partnership}
                    />
                  ) : (
                    <>
                      {authorization.isUserMember || freeAccess ? (
                        <Modal
                          hasPlaylist={true}
                          opened={video.isOpened}
                          style={video.style}
                        >
                          <PlaylistModal video={video} />
                        </Modal>
                      ) : (
                        <>
                          <Authorization
                            popup={video}
                            authorization={authorization}
                            partnership={partnership}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <div
              className={cs(classes.message, {
                [classes.empty]: !hasItems,
                [classes.error]: !!error,
              })}
            >
              {!isLoading &&
                (error ? (
                  t("generals.warnings.1")
                ) : (
                  <NoResult appliedFilters={appliedFilters} onLoad={onLoad} />
                ))}
            </div>
          )}
        </div>
        {hasNextPage && showAllList && (
          <div className={classes.footer}>
            {isLoading ? (
              <Loader />
            ) : (
              <button className={classes.button} onClick={onLoad}>
                {t("pages.library.labels.1")}
              </button>
            )}
          </div>
        )}
        <FeatureModal modal={featureModal} />
        <Authorization
          popup={showModalOnPlaylist}
          authorization={authorization}
          partnership={partnership}
        />
      </div>
    );
  }
);

const NoResult = ({ appliedFilters, onLoad }) => {
  let filterData;
  if (appliedFilters != undefined) {
    if (Object.keys(appliedFilters.data).length == 0) {
      return <p>There are no videos!</p>;
    }
    filterData = appliedFilters.data;
  }

  let filters = [];

  for (const property in filterData) {
    filters.push(`${filterData[property]}`);
  }

  filters = filters.join(" <span class='fw-100'>and</span> ");

  return (
    <>
      <div className={classes.noResultContainer}>
        <div className={classes.mobileBlock}>Your search filters </div>{" "}
        <span
          dangerouslySetInnerHTML={{ __html: filters }}
          className={cs(classes.filterItems, classes.mobileBlock)}
        ></span>{" "}
        <div className={classes.mobileBlock}>did not match any videos.</div>{" "}
        <div className={cs(classes.mobileBlock, classes.flex)}>
          {" "}
          <button
            onClick={() => {
              appliedFilters.clearAll();
            }}
            className={classes.clearFilterLink}
          >
            Clear filters
          </button>{" "}
          and try again.
        </div>
      </div>
    </>
  );
};

Project.propTypes = {
  error: PropTypes.bool,
  hasItems: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  isLoading: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  logotype: PropTypes.oneOf(Object.values(LOGOTYPE_TYPES)),
  placeholder: PropTypes.string,
  onLoad: PropTypes.func.isRequired,
  holidayId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalItems: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string.isRequired,
  projectType: PropTypes.string.isRequired,
  freeAccess: PropTypes.bool,
  appliedFilters: PropTypes.any,
};

Project.defaultProps = {
  error: false,
  hasItems: false,
  hasNextPage: false,
  isLoading: false,
  items: [],
  logotype: LOGOTYPE_TYPES.DEFAULT,
  placeholder: thumbnail,
  holidayId: 0,
  totalItems: 0,
  limitRecord: 8,
  freeAccess: false,
};

export default React.memo(Project);
