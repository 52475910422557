import React from "react";
import Icon from "../../../atoms/Icon";
import cs from "classnames";
import classes from "./Footer.module.scss";

import { observer } from "mobx-react-lite";
import Winners from "../../../pages/Library/components/Winners";
import { useContext } from "react";
import { StoreContext } from "../../../../contexts";
import useVideo from "../../../pages/Library/components/Project/useVideo";
import { getURL } from "../../../pages/Library/components/Project/components/Preview/utilities";
import Modal from "../../Modal";
import PlaylistModal from "../../Playlist/PlaylistModal";
import { useModal } from "../../../../hooks";
import { Authorization, EmptyPlaylist } from "../../RestrictionModal/RestrictionModal";
import playWhite from "../../../../assets/images/play-v1-white.png";
import { isMobileDevice } from "../../../../utilities/helpers";
import { COOKIES } from "../../../../constants";
import Cookies from "../../../../utilities/cookies";

const Footer = observer(({ audioHandler, coloringPages, navigateToSearch }) => {
  const { playlist, authorization, partnership, common } = useContext(StoreContext);
  const video = useVideo();
  const popup = useModal();
  const playlistEmptyModal = useModal();

  const removeColoringFilter = () => {
    if (coloringPages.value) {
      coloringPages.onChange();
    }
  }

  const toggleAudio = () => {
    removeColoringFilter();
    if (audioHandler.currentAudio.musicSrc) {
      audioHandler.setCurrentAudio({
        musicSrc: "",
      });
    } else {
      audioHandler.setCurrentAudio({
        musicSrc: "d",
      });
    }
  };

  const authorizationToken = Cookies.get({ name: COOKIES.AUTHORIZATION })

  const handlePlaylist = () => {
    removeColoringFilter();
    try {
      document.querySelector(".destroy-btn").click();
    } catch (e) {
      console.log(e);
    }

    if (authorization.isAuthenticated || authorizationToken) {
      if (playlist.totalItems == 0) {
        playlistEmptyModal.onOpen();
      } else {
        if (authorization.isUserMember || isMobileDevice()) {
          let playlistItem = playlist.items[0];
          playlist.setCurrentPlayingVideoId(playlistItem.video.id);
          video.onOpen({
            end: playlistItem.video.end,
            start: playlistItem.video.start,
            url: getURL(playlistItem.video.url, { start: playlistItem.video.start }),
            name: playlistItem.video.name,
            description: playlistItem.video.description,
            number: playlistItem.video.number,
            date: playlistItem.video.created_at,
            categories: playlistItem.video.video_directory.video_categories,
            tags: playlistItem.video.video_directory.video_tags,
            holiday: playlistItem.video.video_directory.holiday,
            file: playlistItem.video.video_directory.file,
            internal_id: playlistItem.video.video_directory.internal_id,
            info: {
              projectType: "",
            },
          });
        } else {
          popup.onOpen();
        }
      }
    } else {
      popup.onOpen();
    }
  };

  if (common.get("project") == "daily-rebbe-video") {
    return (
      <>
        <div onClick={handlePlaylist} className={classes.playlistFixed}>
          <img width="25px" src={playWhite} />
          <p>Playlist</p>
        </div>

        <Modal hasPlaylist={true} opened={video.isOpened} style={video.style}>
          <PlaylistModal video={video} />
        </Modal>

        <EmptyPlaylist popup={playlistEmptyModal} />
        <Authorization popup={popup} authorization={authorization} partnership={partnership} />
      </>
    );
  }

  return (
    <section className={cs(classes.mobileFooter)}>
      <div className={cs(classes.mobileFooterWrap, "bgc-white")}>
        <div onClick={handlePlaylist} className={cs(classes.footerItems)}>
          <Icon name="play-orange" />
          {/* <Icon className={cs(classes.hoverImage, "d-none")} name="play-blue" /> */}
          <span className={"c-orange"}>Playlist</span>
        </div>
        <div
          onClick={() => {
            coloringPages.onChange();
            if (navigateToSearch != null) {
              navigateToSearch(true);
            }
          }}
          className={cs(classes.footerItems, classes.hoverItems, coloringPages.value ? classes.activeItem : "")}
        >
          <div className={cs(coloringPages.value && classes.hideColoring, classes.hoverHideImage)}>
            <Icon name="coloring" />
          </div>
          <div className={cs(!coloringPages.value ? classes.hideColoring : classes.hideGrayColoring)}>
            <Icon name="coloring-blue" />
          </div>
          <span className={cs(coloringPages.value ? classes.activeSpan : "c-orange")}>Coloring</span>
        </div>
        <div onClick={toggleAudio} className={cs(classes.footerItems, classes.audioItems)}>
          <Icon name="audio" />
          <span className={"c-orange"}>Audio</span>
        </div>
        <div className={cs(classes.footerItems, classes.winnerItem)}>
          <Winners coloringPages={coloringPages} />
        </div>
      </div>

      <Modal hasPlaylist={true} opened={video.isOpened} style={video.style}>
        <PlaylistModal video={video} />
      </Modal>

      <EmptyPlaylist popup={playlistEmptyModal} />
      <Authorization popup={popup} authorization={authorization} partnership={partnership} />
    </section>
  );
});

export default Footer;
