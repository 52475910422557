import { useCallback, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { APPLICATION_ROUTES, COOKIES, DELIVERY_COUNTRIES, PROJECTS } from "../../../constants";
import { StoreContext } from "../../../contexts";
import { useCheckbox, useDoubleInput, useInput, useQuery, useRadio, useSelect } from "../../../hooks";
import { ACTIONS } from "../../../store";
import { NAMES as VALIDATIONS_NAMES } from "../../../utilities/validation";
import { checkHasDelivery, getInitials, getValueWithoutError } from "./utilities";
import Cookies from "../../../utilities/cookies";

const useDonation = () => {
  const history = useHistory();
  const onNavigateToCheckout = useCallback(() => {
    history.push(`${APPLICATION_ROUTES.CHECKOUT}?from=donation&page=app`);
  }, []);

  const { authorization, cart, checkout, common } = useContext(StoreContext);
  const isLoading = cart[`isLoading${ACTIONS.CART.ADD}`];

  const query = useQuery();
  const type = query.get("type") || "";
  const id = query.get("id");
  const initials = getInitials({ id: id, items: cart.items });

  const { t } = useTranslation();
  /* labels */
  const APARTMENT = t("forms.labels.apartment");
  const CITY = t("forms.labels.city");
  const COUNTRY = t("forms.labels.country");
  const STATE = t("forms.labels.state");
  const STREET = t("forms.labels.street");
  const EMAIL = t("forms.labels.email");
  const LAST_NAME = t("forms.labels.last_name");
  const FIRST_NAME = t("forms.labels.first_name");
  const OTHER = t("forms.labels.amount");
  const PASSWORD = t("forms.labels.password");
  const PASSWORD_OPTIONAL = t("forms.labels.password_optional");
  const CONFIRM_PASSWORD = t("forms.labels.confirm_password");
  const CONFIRM_EMAIL = t("forms.labels.confirm_email");
  const I_WANT_TO_DONATE_ANONYMOUSLY = t("partners.i_want_to_donate_anonymously");

  console.log(type, "typee")

  /* messages */
  const THE_FIELD_MUST_CONTAIN_AT_LEAST_2_SYMBOLS = t("forms.messages.the_field_must_contain_at_least_number_symbols", {
    number: 2,
  });
  const THE_FIELD_MUST_CONTAIN_AT_LEAST_5_SYMBOLS = t("forms.messages.the_field_must_contain_at_least_number_symbols", {
    number: 5,
  });
  const THE_FIELD_MUST_CONTAIN_NO_MORE_THAN_10_SYMBOLS = t(
    "forms.messages.the_field_must_contain_no_more_than_number_symbols",
    {
      number: 10,
    }
  );
  const THE_FIELD_MUST_CONTAIN_ONLY_DIGITS_AND_DOTS = t("forms.messages.the_field_must_contain_only_digits_and_dots");
  const THE_FIELD_MUST_CONTAIN_ONLY_LATIN_CHARACTERS_AND_DIGITS = t(
    "forms.messages.the_field_must_contain_only_latin_characters_and_digits"
  );
  const THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS = t(
    "forms.messages.the_field_must_contain_only_latin_or_hebrew_characters"
  );
  const THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_NUMBERS_SPACES_AND_SYMBOLS = t(
    "forms.messages.the_field_must_contain_only_latin_or_hebrew_characters_numbers_spaces_and_symbols"
  );
  const THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_SPACES_AND_HYPHENS = t(
    "forms.messages.the_field_must_contain_only_latin_or_hebrew_characters_spaces_and_hyphens"
  );
  const THE_FIELD_VALUE_MUST_BE_GREATER_THAN_0 = t("forms.messages.the_field_value_must_be_greater_than_value", {
    value: 0,
  });
  const THIS_EMAIL_IS_NOT_VALID = t("forms.messages.this_email_is_not_valid");
  const THIS_FIELD_IS_REQUIRED = t("forms.messages.this_field_is_required");
  const ZIP = t("forms.labels.zip");
  const AT_LEAST_SIX_SYMBOLS = t("forms.messages.at_least_number_symbols", {
    number: 6,
  });
  const THE_FIELD_DOES_NOT_MATCH = t("forms.messages.the_field_does_not_match");

  const anonymous = useCheckbox({
    callback: ({ checked }) => {
      Cookies.set({
        name: COOKIES.ANONYMOUS_PAYMENT,
        value: checked ? "true" : "",
      });
    },
    checked: false,
    label: I_WANT_TO_DONATE_ANONYMOUSLY,
  });

  common.set("options", "donation");

  const offer = useRadio({
    options: [
      {
        cost: PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.COST_YEAR,
        id: "partners-donation-offer-basic",
        label: t("pages.partners.donation.texts.2"),
        value: PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID,
      },
      {
        cost: PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.COST_YEAR,
        id: "partners-donation-offer-silver",
        label: t("pages.partners.donation.texts.3"),
        value: PROJECTS.PARTNERS.PACKAGES.SILVER_TIER.ID,
      },
      {
        cost: PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.COST_YEAR,
        id: "partners-donation-offer-gold",
        label: t("pages.partners.donation.texts.4"),
        value: PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID,
      },
      {
        cost: PROJECTS.PARTNERS.PACKAGES.PLATINUM_TIER.COST_YEAR,
        id: "partners-donation-offer-platinum",
        label: t("pages.partners.donation.texts.5"),
        value: PROJECTS.PARTNERS.PACKAGES.PLATINUM_TIER.ID,
      },
    ],
    value: type,
  });

  const customAmount = useInput({
    label: OTHER,
    validators: [
      {
        name: VALIDATIONS_NAMES.DIGITS_DOTS,
        options: { message: THE_FIELD_MUST_CONTAIN_ONLY_DIGITS_AND_DOTS },
      },
      {
        name: VALIDATIONS_NAMES.GREATER_THAN_VALUE,
        options: { message: THE_FIELD_VALUE_MUST_BE_GREATER_THAN_0, value: 0 },
      },
    ],
    value: initials.details.amount,
  });
  const createAccount = useCheckbox({
    label: "Create Account?",
  });

  /* password */
  // const password = useDoubleInput({
  //   first: "Original",
  //   second: "Confirm",
  //   labelOfFirst: PASSWORD,
  //   labelOfSecond: CONFIRM_PASSWORD,
  //   validatorsOfFirst: [
  //     { name: VALIDATIONS_NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
  //     { name: VALIDATIONS_NAMES.MIN_SIX_SYMBOLS, options: { message: AT_LEAST_SIX_SYMBOLS } },
  //   ],
  //   validatorsOfSecond: [
  //     { name: VALIDATIONS_NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
  //     { name: VALIDATIONS_NAMES.MIN_SIX_SYMBOLS, options: { message: AT_LEAST_SIX_SYMBOLS } },
  //     { name: VALIDATIONS_NAMES.EQUAL, options: { message: THE_FIELD_DOES_NOT_MATCH } },
  //   ],
  // });

  let password;
  if (authorization.isAuthenticated) {
    password = useDoubleInput({
      first: "Original",
      second: "Confirm",
      labelOfFirst: PASSWORD_OPTIONAL,
      labelOfSecond: CONFIRM_PASSWORD,
      validatorsOfFirst: [
        {
          name: VALIDATIONS_NAMES.MIN_SIX_SYMBOLS,
          options: { message: AT_LEAST_SIX_SYMBOLS },
        },
      ],
      validatorsOfSecond: [
        {
          name: VALIDATIONS_NAMES.MIN_SIX_SYMBOLS,
          options: { message: AT_LEAST_SIX_SYMBOLS },
        },
        {
          name: VALIDATIONS_NAMES.EQUAL,
          options: { message: THE_FIELD_DOES_NOT_MATCH },
        },
      ],
    });
  } else {
    password = useDoubleInput({
      first: "Original",
      second: "Confirm",
      labelOfFirst: PASSWORD,
      labelOfSecond: CONFIRM_PASSWORD,
      validatorsOfFirst: [
        {
          name: VALIDATIONS_NAMES.REQUIRED,
          options: { message: THIS_FIELD_IS_REQUIRED },
        },
        {
          name: VALIDATIONS_NAMES.MIN_SIX_SYMBOLS,
          options: { message: AT_LEAST_SIX_SYMBOLS },
        },
      ],
      validatorsOfSecond: [
        {
          name: VALIDATIONS_NAMES.REQUIRED,
          options: { message: THIS_FIELD_IS_REQUIRED },
        },
        {
          name: VALIDATIONS_NAMES.MIN_SIX_SYMBOLS,
          options: { message: AT_LEAST_SIX_SYMBOLS },
        },
        {
          name: VALIDATIONS_NAMES.EQUAL,
          options: { message: THE_FIELD_DOES_NOT_MATCH },
        },
      ],
    });
  }

  /* personal */
  const email = useDoubleInput({
    first: "Original",
    second: "Confirm",
    labelOfFirst: EMAIL,
    labelOfSecond: CONFIRM_EMAIL,
    validatorsOfFirst: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      {
        name: VALIDATIONS_NAMES.EMAIL,
        options: { message: THIS_EMAIL_IS_NOT_VALID },
      },
    ],
    validatorsOfSecond: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      {
        name: VALIDATIONS_NAMES.EMAIL,
        options: { message: THIS_EMAIL_IS_NOT_VALID },
      },
      {
        name: VALIDATIONS_NAMES.EQUAL,
        options: { message: THE_FIELD_DOES_NOT_MATCH },
      },
    ],
  });

  const firstName = useInput({
    label: FIRST_NAME,
    validators: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      // {
      //   name: VALIDATIONS_NAMES.HEBREW_LATIN_SPACES_HYPHENS,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_SPACES_AND_HYPHENS,
      //   },
      // },
    ],
    value: initials.details.firstName,
  });
  const lastName = useInput({
    label: LAST_NAME,
    validators: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      // {
      //   name: VALIDATIONS_NAMES.HEBREW_LATIN_SPACES_HYPHENS,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_SPACES_AND_HYPHENS,
      //   },
      // },
    ],
    value: initials.details.lastName,
  });
  /* delivery */
  const street = useInput({
    label: STREET,
    validators: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      {
        name: VALIDATIONS_NAMES.HEBREW_LATIN_DIGITS_SYMBOLS,
        options: {
          message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_NUMBERS_SPACES_AND_SYMBOLS,
        },
      },
    ],
    value: initials.delivery.street,
  });
  const apartment = useInput({
    label: APARTMENT,
    validators: [
      {
        name: VALIDATIONS_NAMES.HEBREW_LATIN_DIGITS_SYMBOLS,
        options: {
          message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_NUMBERS_SPACES_AND_SYMBOLS,
        },
      },
    ],
    value: initials.delivery.apartment,
  });
  const city = useInput({
    label: CITY,
    validators: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      {
        name: VALIDATIONS_NAMES.HEBREW_LATIN_SPACES_HYPHENS,
        options: {
          message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_SPACES_AND_HYPHENS,
        },
      },
    ],
    value: initials.delivery.city,
  });
  const state = useInput({
    label: STATE,
    validators: [
      {
        name: VALIDATIONS_NAMES.HEBREW_LATIN,
        options: {
          message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS,
        },
      },
      {
        name: VALIDATIONS_NAMES.MIN_2,
        options: { message: THE_FIELD_MUST_CONTAIN_AT_LEAST_2_SYMBOLS },
      },
    ],
    value: initials.delivery.state,
  });
  const zip = useInput({
    label: ZIP,
    validators: [
      {
        name: VALIDATIONS_NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
      {
        name: VALIDATIONS_NAMES.LATIN_DIGITS,
        options: {
          message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_CHARACTERS_AND_DIGITS,
        },
      },
      {
        name: VALIDATIONS_NAMES.MIN_5,
        options: { message: THE_FIELD_MUST_CONTAIN_AT_LEAST_5_SYMBOLS },
      },
      {
        name: VALIDATIONS_NAMES.MAX_10,
        options: { message: THE_FIELD_MUST_CONTAIN_NO_MORE_THAN_10_SYMBOLS },
      },
    ],
    value: initials.delivery.zip,
  });
  const country = useSelect({
    label: COUNTRY,
    options: Object.values(DELIVERY_COUNTRIES),
    value: initials.delivery.country,
  });

  const isSelectedCustomAmount = !!(!customAmount.errors.length && customAmount.value);
  const isSelectedAnything = !(offer.value || customAmount.value);
  const hasDelivery = checkHasDelivery(offer.value);

  // let isNotValidPassword = authorization.isAuthenticated
  //   ? password.errorsOfOriginal.length
  //   : createAccount.checked
  //   ? password.errorsOfOriginal.length + password.errorsOfConfirm.length
  //   : 0;

  let isNVP = 0;
  if (createAccount.checked) {
    if (password.valueOfOriginal == "" || password.valueOfOriginal == undefined) {
      isNVP = 1;
    } else if (password.errorsOfOriginal.length + password.errorsOfConfirm.length) {
      isNVP = 1;
    } else {
      isNVP = 0;
    }
  }

  const isValidEmail = email.errorsOfOriginal.length;
  const isValidPassword = authorization.isAuthenticated
    ? 0
    : createAccount.checked
      ? isNVP
      : 0;

  const isNotValidPersonal = !!(isValidEmail || isValidPassword || lastName.errors.length || firstName.errors.length);
  const isNotValidDelivery =
    hasDelivery && !isSelectedCustomAmount
      ? !!(street.errors.length || city.errors.length || zip.errors.length || !country.value)
      : false;
  const isNotValid = !!(isSelectedAnything || isNotValidDelivery || isNotValidPersonal);
  const showPersonal = authorization.isNotAuthenticated;

  if (password.valueOfOriginal != undefined) {
    if (password.valueOfOriginal.length == "") {
      password.valueOfOriginal = undefined;
    }
  }

  let referredFrom = "";
  if (navigator.cookieEnabled) {
    referredFrom = localStorage.getItem("referral-code");
  }

  const onSubmit = (event) => {
    event.preventDefault();

    if (authorization.isAuthenticated && password.valueOfOriginal != undefined) {
      authorization.autheticatePassword(authorization.user.email, password.valueOfOriginal, () => {
        checkout.setValue("reAuthenticated", true);

        if (isSelectedCustomAmount) {
          const data = {
            client: {
              email: email.valueOfOriginal,
              first_name: firstName.value,
              last_name: lastName.value,
              password: password.valueOfOriginal,
            },
            details: {
              amount: customAmount.value,
              email: email.valueOfOriginal,
              first_name: firstName.value,
              last_name: lastName.value,
              password: password.valueOfOriginal,
            },
            type: PROJECTS.DONATION.TYPE.ID,
          };

          /* TODO: create util to delete all the empties from request body */

          if (!data.details.first_name) {
            delete data.client.first_name;
            delete data.details.first_name;
          }

          cart.add({ data, onSuccess: onNavigateToCheckout });
          if (navigator.cookieEnabled) {
            localStorage.removeItem("referral-code");
          }
          return;
        }

        const data = {
          client: {
            email: email.valueOfOriginal,
            first_name: firstName.value,
            last_name: lastName.value,
            password: password.valueOfOriginal,
          },
          delivery: {
            street: street.value,
            apartment: getValueWithoutError(apartment),
            city: city.value,
            state: getValueWithoutError(state),
            zip: zip.value,
            country: country.value,
          },
          details: {
            email: email.valueOfOriginal,
            first_name: firstName.value,
            last_name: lastName.value,
            interval: 1,
            password: password.valueOfOriginal,
          },
          project: PROJECTS.PARTNERS.ID,
          type: offer.value,
        };

        /* TODO: create util to delete all the empties from request body */

        if (!data.delivery.apartment) delete data.delivery.apartment;
        if (!data.delivery.state) delete data.delivery.state;
        if (!data.details.first_name) {
          delete data.client.first_name;
          delete data.details.first_name;
        }

        if (!hasDelivery) delete data.delivery;

        cart.add({ data, onSuccess: onNavigateToCheckout });
        if (navigator.cookieEnabled) {
          localStorage.removeItem("referral-code");
        }
      });
    } else {
      checkout.setValue("reAuthenticated", false);

      if (isSelectedCustomAmount) {
        const data = {
          client: {
            email: email.valueOfOriginal,
            first_name: firstName.value,
            last_name: lastName.value,
            password: password.valueOfOriginal,
          },
          details: {
            amount: customAmount.value,
            email: email.valueOfOriginal,
            first_name: firstName.value,
            last_name: lastName.value,
            password: password.valueOfOriginal,
          },
          type: PROJECTS.DONATION.TYPE.ID,
        };

        if (referredFrom) data.referred_from = referredFrom;

        /* TODO: create util to delete all the empties from request body */

        if (!data.details.first_name) {
          delete data.client.first_name;
          delete data.details.first_name;
        }

        cart.add({ data, onSuccess: onNavigateToCheckout });
        if (navigator.cookieEnabled) {
          localStorage.removeItem("referral-code");
        }
        return;
      }

      const data = {
        client: {
          email: email.valueOfOriginal,
          first_name: firstName.value,
          last_name: lastName.value,
          password: password.valueOfOriginal,
        },
        delivery: {
          street: street.value,
          apartment: getValueWithoutError(apartment),
          city: city.value,
          state: getValueWithoutError(state),
          zip: zip.value,
          country: country.value,
        },
        details: {
          email: email.valueOfOriginal,
          first_name: firstName.value,
          last_name: lastName.value,
          interval: 1,
          password: password.valueOfOriginal,
        },
        project: PROJECTS.PARTNERS.ID,
        type: offer.value,
      };

      /* TODO: create util to delete all the empties from request body */

      if (!data.delivery.apartment) delete data.delivery.apartment;
      if (!data.delivery.state) delete data.delivery.state;
      if (!data.details.first_name) {
        delete data.client.first_name;
        delete data.details.first_name;
      }

      if (!hasDelivery) delete data.delivery;

      cart.add({ data, onSuccess: onNavigateToCheckout });
      if (navigator.cookieEnabled) {
      localStorage.removeItem("referral-code");
    }
    }
  };

  useEffect(() => {
    if (authorization.isAuthenticated) {
      email.onChangeOriginal({ target: { value: authorization.user.email } });
      lastName.onChange({ target: { value: authorization.user.last_name } });
      firstName.onChange({ target: { value: authorization.user.first_name } });
      return;
    }
  }, [authorization.isAuthenticated]);

  return {
    anonymous: anonymous,
    customAmount: customAmount,
    offer: offer,
    createAccount: createAccount,
    /* personal */
    email: email,
    lastName: lastName,
    firstName: firstName,
    password: password,
    /* delivery */
    apartment: apartment,
    city: city,
    country: country,
    state: state,
    street: street,
    zip: zip,
    /* additional */
    isSelectedCustomAmount: isSelectedCustomAmount,
    hasDelivery: hasDelivery,
    isLoading: isLoading,
    isNotValid: isNotValid,
    showPersonal: showPersonal,
    onSubmit: onSubmit,
  };
};

export default useDonation;
