import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import cs from "classnames";

import { SIZES, TYPES, getImage } from "./utilities";
import classes from "./Logotype.module.scss";
import { isMobileDevice } from "../../../utilities/helpers";
import { StoreContext } from "../../../contexts";

const Logotype = ({ size, type, url, beta }) => {
  const image = getImage(type);
  const { authorization } = useContext(StoreContext);

  return (
    <>
      {!beta ? (
        <Link to={!isMobileDevice() ? url : authorization.isAuthenticated && url} className={cs(classes.container, classes[size])}>
          <img alt="maor" className={classes.image} src={image} />
        </Link>
      ) : (
        <Link to={url} className={cs(classes.container, classes.betaContainer, classes[size])}>
          <span className={cs(classes.beta)}>BETA</span>
          <img alt="maor" className={classes.image} src={image} />
        </Link>
      )}
    </>
  );
};

Logotype.propTypes = {
  size: PropTypes.oneOf(Object.values(SIZES)),
  type: PropTypes.oneOf(Object.values(TYPES)),
  url: PropTypes.string,
  beta: PropTypes.bool,
};

Logotype.defaultProps = {
  size: SIZES.MEDIUM,
  type: TYPES.DEFAULT,
  url: "/",
  beta: false,
};

export default Logotype;
