import React, { useContext, useEffect } from "react";
import Collapse from "../../atoms/Collapse";
import OptionSelector from "../../atoms/OptionSelector";
import Logotype, { TYPES as LOGOTYPE_TYPES } from "../../atoms/Logotype";
import Icon from "../../atoms/Icon";
import { projectTypes } from "./utilities";
import cs from "classnames";
import PropTypes from "prop-types";

import classes from "./LibrarySidebar.module.scss";
import TagSelector from "../../atoms/TagSelector/TagSelector";
import Loader from "../../atoms/Loader";
import { useTranslation } from "react-i18next";
import useVideo from "../../pages/Library/components/Project/useVideo";
import { StoreContext } from "../../../contexts";
import { getURL } from "../../pages/Library/components/Project/components/Preview/utilities";
import Modal from "../../organisms/Modal";
import PlaylistModal from "../../organisms/Playlist/PlaylistModal";
import { observer } from "mobx-react-lite";
import Button from "../../atoms/Button";
import Winners from "../../pages/Library/components/Winners";
import GoldPartners from "../../pages/Library/components/GoldPartners";
import { useModal } from "../../../hooks";
import FeatureModal from "../../organisms/FeatureModal/FeatureModal";
import shlichus from "../../../assets/images/SALogo.png";
import equalizer from "../../../assets/images/equilizer.png";
import useAudioPlayer from "../../../hooks/useAudioPlayer";
import { ACTIONS } from "../../../store";
import { Authorization, EmptyPlaylist } from "../../organisms/RestrictionModal/RestrictionModal";

const LibrarySidebar = observer(
  ({
    maorType,
    dailyRebbeType,
    illuminateType,
    tag,
    coloringPages,
    sidebarVisible,
    setSideBarVisible,
    filterData,
    openFilter,
    setOpenFilter,
    audioHandler,
  }) => {
    const { t } = useTranslation();
    const { authorization, playlist, partnership } = useContext(StoreContext);
    const video = useVideo();
    const featureModal = useModal();
    const player = useAudioPlayer({ ACTION: ACTIONS.AUDIOS.GET_PLAYLIST });
    const playlistEmptyModal = useModal();
    const popup = useModal();

    useEffect(() => {
      filterData.categories.onLoad();
      filterData.tags.onLoad();
      player.onLoad();
    }, []);

    // Transforming data
    let projectTypesData = [];
    if (!filterData.categories.isLoading && !filterData.categories.isError) {
      projectTypes.forEach((projectType) => {
        projectTypesData[projectType.value] = [];
        filterData.categories.data[projectType.value].forEach((category) => {
          projectTypesData[projectType.value].push({
            id: `${projectType.id}-${category.id}`,
            label: category.name_english,
            value: Number(category.id),
          });
        });
      });
    }

    let videoTags = [];
    if (!filterData.tags.isLoading && !filterData.tags.isError) {
      filterData.tags.data.data.forEach((tag, index) => {
        videoTags.push({
          id: `tag-${tag.id}-${index}`,
          label: tag.name_english,
          value: tag.name_english,
        });
      });
    }

    let audioItems = [];
    if (player.hasItems) {
      player.playlist.forEach((audio, index) => {
        let html = (
          <div className={cs(classes.audioContainer)}>
            <div className={cs(classes.audioTitle)}>{audio.name}</div>
            <div className={cs(classes.episodeIndex)}>
              <small>Episode #{player.playlist.length - index + 1 - 1}</small>
            </div>
          </div>
        );

        audioItems.push({
          index: index,
          id: `audio-${index}`,
          label: html,
          value: audio.musicSrc,
          ...audio,
        });
      });
    }

    return (
      <>
        <div className={classes.sidebarMobileWrap}>
          <div onClick={() => setOpenFilter(!openFilter)} className={cs(classes.sidebarSearchButton)}>
            <Icon color="primary" size="xs" name={openFilter ? "x" : "search"} />
          </div>
          <div className={classes.sidebarIconsWrap}>
            <Winners layout="vertical" />
            <GoldPartners layout="vertical" />
          </div>
          {!sidebarVisible && (
            <div onClick={() => setSideBarVisible(true)} className={cs(classes.sidebarOpenButton)}>
              <Icon color="primary" name="chevron-right-double" />
            </div>
          )}
        </div>
        <div
          className={cs(classes.container, {
            [classes.openSidebar]: sidebarVisible,
          })}
        >
          {sidebarVisible && (
            <div onClick={() => setSideBarVisible(false)} className={cs(classes.sidebarCloseButton)}>
              <Icon color="primary" name="exit" />
            </div>
          )}
          <Button
            onClick={() => {
              featureModal.onOpen();
            }}
            className={cs(classes.myfeaturesButton, classes.sidebarButton)}
          >
            <Icon name="play-white" /> Our Features
          </Button>

          {/* {authorization.isUserMember && playlist.items.length > 0 ? ( */}
          <Button
            className={cs(classes.myPlaylistButton, classes.sidebarButton)}
            onClick={() => {
              if (authorization.isAuthenticated) {
                if (playlist.totalItems == 0) {
                  playlistEmptyModal.onOpen();
                } else {
                  if (authorization.isUserMember) {
                    let playlistItem = playlist.items[0];
                    playlist.setCurrentPlayingVideoId(playlistItem.video.id);
                    video.onOpen({
                      end: playlistItem.video.end,
                      start: playlistItem.video.start,
                      url: getURL(playlistItem.video.url, {
                        start: playlistItem.video.start,
                      }),
                      name: playlistItem.video.name,
                      description: playlistItem.video.description,
                      number: playlistItem.video.number,
                      date: playlistItem.video.created_at,
                      categories: playlistItem.video.video_directory.video_categories,
                      tags: playlistItem.video.video_directory.video_tags,
                      holiday: playlistItem.video.video_directory.holiday,
                      file: playlistItem.video.video_directory.file,
                      questions: playlistItem.video.video_questions,
                      internal_id: playlistItem.video.video_directory.internal_id,
                      info: {
                        projectType: "",
                      },
                    });
                  } else {
                    popup.onOpen();
                  }
                }
              } else {
                popup.onOpen();
              }
            }}
          >
            <Icon name="playlist" /> My Playlist ({playlist.totalItems})
          </Button>
          {/* ) : null} */}

          {/* DRV */}
          <section className={cs(classes.sidebarSection)}>
            <Collapse
              header={<Logotype type={LOGOTYPE_TYPES.DAILY_REBBE_VIDEO} size="l" url="#" />}
              body={
                !filterData.categories.isLoading && !filterData.categories.isError ? (
                  <OptionSelector
                    colorScheme="drv"
                    items={projectTypesData["Daily Rebbe Video"]}
                    value={Number(dailyRebbeType.value)}
                    onChange={dailyRebbeType.onChange}
                  />
                ) : filterData.categories.isError ? (
                  <p>{t("generals.warnings.1")}</p>
                ) : (
                  <Loader />
                )
              }
            />
          </section>

          {/* Audio */}
          <section className={cs(classes.sidebarSection)}>
            <Collapse
              opened={false}
              header={
                <div className={cs(classes.equalizerWrap, "mb-02 curs-pointer")}>
                  <img
                    style={{
                      width: "130px",
                      height: "130px",
                      marginLeft: "13px",
                    }}
                    src={shlichus}
                  />
                  <span className={classes.equalizer}>
                    <img src={equalizer} />
                  </span>
                </div>
              }
              body={
                audioItems.length ? (
                  <OptionSelector
                    colorScheme="brown"
                    items={audioItems}
                    value={audioHandler.currentAudio.musicSrc}
                    onChange={({ target }) => {
                      let selectedTrack = target.value;

                      let currentAudio = audioItems.filter((value) => {
                        return value.musicSrc == selectedTrack;
                      });
                      console.log(currentAudio, "currentAudio")
                      audioHandler.setCurrentAudio(currentAudio[0]);
                    }}
                  />
                ) : playlist.error ? (
                  <p>{t("generals.warnings.1")}</p>
                ) : (
                  <Loader />
                )
              }
            />
          </section>

          {/* MyMaor */}
          <section className={cs(classes.sidebarSection)}>
            <Collapse
              opened={true}
              header={<Logotype type={LOGOTYPE_TYPES.MY_MAOR} size="l" url="#" />}
              body={
                <>
                  <div className="mb-05">
                    <Button
                      onClick={coloringPages.onChange}
                      className={cs(classes.myColoringButton, classes.sidebarButton)}
                    >
                      <Icon name="color-pallate" /> Coloring pages
                    </Button>
                  </div>
                  {!filterData.categories.isLoading && !filterData.categories.isError ? (
                    <OptionSelector
                      items={projectTypesData["MyMaor"]}
                      value={Number(maorType.value)}
                      onChange={maorType.onChange}
                    />
                  ) : filterData.categories.isError ? (
                    <p>{t("generals.warnings.1")}</p>
                  ) : (
                    <Loader />
                  )}
                </>
              }
            />
          </section>

          <section className={cs(classes.sidebarSection)}>
            <Collapse
              header={<Logotype type={LOGOTYPE_TYPES.ILLUMINATE} size="l" url="#" />}
              body={
                !filterData.categories.isLoading && !filterData.categories.isError ? (
                  <OptionSelector
                    items={projectTypesData["Illuminate"]}
                    value={Number(illuminateType.value)}
                    onChange={illuminateType.onChange}
                  />
                ) : filterData.categories.isError ? (
                  <p>{t("generals.warnings.1")}</p>
                ) : (
                  <Loader />
                )
              }
            />
          </section>
          <section className={cs(classes.sidebarSection)}>
            {videoTags.length != 0 && (
              <>
                <TagSelector items={videoTags} values={tag.value} onChange={tag.onChange} />
              </>
            )}
            {filterData.tags.data.next_page_url != null && (
              <>
                {filterData.tags.isLoading && !filterData.tags.isError ? (
                  <Loader />
                ) : (
                  <p className={classes.showMoreContainer}>
                    <button
                      className={classes.button}
                      onClick={() => {
                        filterData.tags.onLoad();
                      }}
                    >
                      {t("pages.library.labels.1")}
                    </button>
                  </p>
                )}
              </>
            )}
          </section>
        </div>

        <Modal opened={video.isOpened} style={video.style}>
          <PlaylistModal video={video} />
        </Modal>

        <FeatureModal modal={featureModal} />

        <EmptyPlaylist popup={playlistEmptyModal} />
        <Authorization popup={popup} authorization={authorization} partnership={partnership} />
      </>
    );
  }
);

LibrarySidebar.propTypes = {
  maorType: PropTypes.object.isRequired,
  dailyRebbeType: PropTypes.object.isRequired,
  illuminateType: PropTypes.object.isRequired,
  tag: PropTypes.object.isRequired,
  coloringPages: PropTypes.object.isRequired,
  sidebarVisible: PropTypes.bool.isRequired,
  setSideBarVisible: PropTypes.func.isRequired,
  openFilter: PropTypes.bool.isRequired,
  setOpenFilter: PropTypes.func.isRequired,
  filterData: PropTypes.object.isRequired,
  audioHandler: PropTypes.object,
};

export default LibrarySidebar;
