const sortingOptions = [
  // {
  //   id: "pill_1",
  //   label: "All",
  //   value: "",
  // },
  {
    id: "pill_2",
    label: "New",
    value: "new",
  },
  {
    id: "pill_3",
    label: "Most Popular",
    value: "popular",
  },
];

const gotoOptions = [
  {
    id: "goto_option_1",
    label: "My Maor",
    value: "MyMaor",
  },
  {
    id: "goto_option_2",
    label: "Daily Rebbe Video",
    value: "DailyRebbeVideo",
  },
  // {
  //   id: "goto_option_3",
  //   label: "Illuminate",
  //   value: "Illuminate",
  // },
];

export { sortingOptions, gotoOptions };
