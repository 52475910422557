import React, { useContext } from "react";
import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";

import { createLanguages, getDetails, getURL } from "./utilities";
import classes from "./Preview.module.scss";
import Icon, { SIZES } from "../../../../atoms/Icon";
import cs from "classnames";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../../../../../contexts";
import Loader from "../../../../atoms/Loader";
import { APPLICATION_ROUTES } from "../../../../../constants";

const Preview = observer(
  ({
    locales,
    categories,
    questions,
    tags,
    file,
    holiday,
    placeholder,
    internal_id,
    projectType,
    onSelect,
    showAllList,
  }) => {
    // const {
    //   // t,
    //   i18n: { language },
    // } = useTranslation();

    console.log(showAllList);

    const { playlist, authorization } = useContext(StoreContext);

    const downloadFile = (url) => {
      window.open(url);
    };

    // const details = getDetails(language, locales);
    const details = getDetails("en", locales);
    const languages = createLanguages(locales);

    return (
      <div
        className={cs(
          classes.container,
          showAllList && classes.mobilecontainer
        )}
      >
        <div
          className={cs(
            classes.preview,
            projectType == "illuminate" ? classes.illuminatePreview : ""
          )}
          role="button"
          style={{
            backgroundImage: `url(${details.thumbnail || placeholder})`,
          }}
        >
          <div
            onClick={() => {
              playlist.setCurrentPlayingVideoId(details.id);
              console.log("Details", details);
              onSelect({
                end: details.end_time,
                start: details.start_time,
                url: getURL(details.link, { start: details.start_time }),
                name: details.name,
                description: details.description,
                number: details.number,
                categories: categories,
                questions: questions,
                tags: tags,
                holiday: holiday,
                date: details.created_at,
                file: file,
                internal_id: internal_id,
                info: {
                  projectType: projectType,
                },
              });
            }}
            className={cs(classes.playerControlWrapper)}
          >
            <Icon
              className={cs(
                classes.playerPlay,
                projectType == "illuminate" ? classes.illuminatePlayerPlay : ""
              )}
              name="new-play-icon"
            />
            {details.duration_formatted && (
              <span className={cs(classes.videoTime, "d-none")}>
                {details.duration_formatted}
              </span>
            )}
          </div>

          {file && (
            <div
              onClick={() => {
                if (authorization.isUserMember) {
                  downloadFile(file);
                } else {
                  if (
                    !location.pathname.includes(
                      APPLICATION_ROUTES.LIBRARY_PROECTED
                    )
                  ) {
                    playlist.setCurrentPlayingVideoId(details.id);
                    onSelect({
                      end: details.end_time,
                      start: details.start_time,
                      url: getURL(details.link, { start: details.start_time }),
                      name: details.name,
                      description: details.description,
                      number: details.number,
                      categories: categories,
                      questions: questions,
                      tags: tags,
                      holiday: holiday,
                      date: details.created_at,
                      file: details.file,
                      internal_id: internal_id,
                      info: {
                        projectType: projectType,
                      },
                    });
                  }
                }
              }}
              className={cs(classes.downloadButton)}
            >
              <Icon size={SIZES.S} name="printer" />
            </div>
          )}

          <div className={cs(classes.watchlistButton)}>
            {playlist.loadingIds.includes(details.id) ? (
              <Loader size="s" green={true} />
            ) : (
              <Icon
                onClick={() => {
                  if (
                    authorization.isAuthenticated &&
                    authorization.isUserMember
                  ) {
                    playlist.handleAddDelete(details.id);
                  } else {
                    if (
                      !location.pathname.includes(
                        APPLICATION_ROUTES.LIBRARY_PROECTED
                      )
                    ) {
                      playlist.setCurrentPlayingVideoId(details.id);
                      onSelect({
                        end: details.end_time,
                        start: details.start_time,
                        url: getURL(details.link, {
                          start: details.start_time,
                        }),
                        name: details.name,
                        description: details.description,
                        number: details.number,
                        categories: categories,
                        questions: questions,
                        tags: tags,
                        holiday: holiday,
                        date: details.created_at,
                        file: details.file,
                        internal_id: internal_id,
                        info: {
                          projectType: projectType,
                        },
                      });
                    }
                  }
                }}
                title={
                  !authorization.isAuthenticated
                    ? "Please sign in to add videos to Playlist"
                    : ""
                }
                color="primary"
                size={SIZES.S}
                cursorPointer={true}
                name={
                  authorization.isAuthenticated &&
                  playlist.videoExists(details.id)
                    ? "green-cross"
                    : "add-playlist"
                }
              />
            )}
          </div>
        </div>

        <div className={classes.details}>
          <p className={classes.title}>
            <span>#{details.number} | </span>
            <strong>{details.name}</strong>
          </p>

          <p className={classes.mobileTitle}>
            <strong>{details.name}</strong> <br />
            <span>My Maor #{details.number}</span>
          </p>

          <div className={cs(classes.languageViewsContainer, "d-none")}>
            <ul className={classes.languages}>
              {languages.map((item, index) => (
                <li className={classes.item} key={`${item.link}${index}`}>
                  <button
                    className={classes.button}
                    onClick={() => {
                      playlist.setCurrentPlayingVideoId(item.id);
                      onSelect({
                        end: item.end_time,
                        start: item.start_time,
                        url: getURL(item.link, { start: item.start_time }),
                        name: item.name,
                        description: item.description,
                        number: item.number,
                        categories: categories,
                        questions: questions,
                        tags: tags,
                        holiday: holiday,
                        date: item.created_at,
                        file: file,
                        internal_id: internal_id,
                        info: {
                          projectType: projectType,
                        },
                      });
                    }}
                  >
                    {item.language}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className={cs(classes.watchlistButtonMobile)}>
          {playlist.loadingIds.includes(details.id) ? (
            <Loader size="s" green={true} />
          ) : (
            <Icon
              onClick={() => {
                if (
                  authorization.isAuthenticated &&
                  authorization.isUserMember
                ) {
                  playlist.handleAddDelete(details.id);
                } else {
                  if (
                    !location.pathname.includes(
                      APPLICATION_ROUTES.LIBRARY_PROECTED
                    )
                  ) {
                    playlist.setCurrentPlayingVideoId(details.id);
                    onSelect({
                      end: details.end_time,
                      start: details.start_time,
                      url: getURL(details.link, {
                        start: details.start_time,
                      }),
                      name: details.name,
                      description: details.description,
                      number: details.number,
                      categories: categories,
                      questions: questions,
                      tags: tags,
                      holiday: holiday,
                      date: details.created_at,
                      file: details.file,
                      internal_id: internal_id,
                      info: {
                        projectType: projectType,
                      },
                    });
                  }
                }
              }}
              title={
                !authorization.isAuthenticated
                  ? "Please sign in to add videos to Playlist"
                  : ""
              }
              color="primary"
              size={SIZES.S}
              cursorPointer={true}
              name={
                authorization.isAuthenticated &&
                playlist.videoExists(details.id)
                  ? "green-cross"
                  : "add-playlist"
              }
            />
          )}
        </div>
      </div>
    );
  }
);

Preview.propTypes = {
  id: PropTypes.string.isRequired,
  locales: PropTypes.shape({
    // en: PropTypes.shape({
    //   link: PropTypes.string.isRequired,
    // }),
  }).isRequired,
  placeholder: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default React.memo(Preview);
