import { ReactComponent as Cake1 } from "../../../assets/svg/cake-1.svg";
import { ReactComponent as Cake2 } from "../../../assets/svg/cake-2.svg";
import { ReactComponent as Cake3 } from "../../../assets/svg/cake-3.svg";
import { ReactComponent as Cake4 } from "../../../assets/svg/cake-4.svg";
// import Personal from "./components/Personal";
import BirthdayDetails from "./components/BirthdayDetails";
import DeliveryDetails from "./components/DeliveryDetails";
import { COOKIES, PROJECTS } from "../../../constants";
import { getPackageDetails } from "../../../utilities/helpers";
import { StoreContext } from "../../../contexts";
import { useContext } from "react";
import Cookies from "../../../utilities/cookies";
import AutoRenewal from "./components/AutoRenewal";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const getMonthName = (monthNumber) => {
  if (monthNumber >= 1 && monthNumber <= 12) {
    return months[monthNumber - 1];
  } else {
    return "Invalid month number";
  }
}

const getDots = (steps, currentStepNumber) => {
  const { authorization } = useContext(StoreContext);
  const addAnotherChild = Cookies.get({ name: COOKIES.ADD_ANOTHER_CHILD });

  const title_translation_keys = [];

  // console.log(steps.length)
  let hasDelivery = false;
  if (steps.length >= 3) {
    hasDelivery = true;
  }

  if (authorization.isAuthenticated && addAnotherChild == 1) {
    title_translation_keys.push("projects.maor-kids.birthday_details");
    title_translation_keys.push("projects.maor-kids.delivery_details");
    title_translation_keys.push("projects.maor-kids.auto_renewal");
  } else {
    // title_translation_keys.push("partners.personal_information");
    title_translation_keys.push("projects.maor-kids.birthday_details");
    if (hasDelivery) {
      title_translation_keys.push("projects.maor-kids.delivery_details");
    }
    title_translation_keys.push("projects.maor-kids.auto_renewal");
  }

  const dots = [
    {
      active: true,
      checked: true,
      title_translation_key: "projects.maor-kids.packages",
    },
    ...steps.map((item, index) => ({
      active: index + 1 <= currentStepNumber,
      checked: index + 1 < currentStepNumber,
      title_translation_key: title_translation_keys[index],
    })),
    {
      active: false,
      checked: false,
      title_translation_key: "projects.maor-kids.checkout",
    },
  ];

  return dots;
};

const checkHasDelivery = (type) => {
  const details = getPackageDetails(type);
  const hasDelivery = details.DELIVERY;

  return hasDelivery;
};

const getInitials = ({ id, items, user }) => {
  let person = {
    sex: "",
    city: "",
    state: "",
    country: "",
    last_name: "",
    pronunciation: "",
  };

  if (user.length > 0) {
    person = user[0];
  }

  const defaultValues = {
    birthday_recurring: false,
    delivery: {
      city: "",
      state: "",
      street: "",
      country: "",
      apartment: "",
      zip_code: "",
    },
    details: {
      sex: "",
      city: person.city,
      date: "",
      hebrew_date: "",
      born_after_sunset: false,
      rank: "",
      state: person.state,
      country: person.country,
      last_name: person.last_name,
      first_name: "",
      pronunciation: person.pronunciation,
      day: "",
      month: "",
      year: "",
      jewishDay: "",
      jewishMonth: "",
      jewishYear: ""
    },
    wishes: { to: "", from: "", message: "" },
  };

  if (!id) return defaultValues;

  const item = items.find((item) => item.item_id == id);

  if (!item) return defaultValues;

  console.log(item.data);

  let date = item.data.details.date;
  let jewishDate = item.data.details.hebrew_date;
  let born_after_sunset = item.data.details.born_after_sunset;

  console.log("Date #0072", date);
  console.log("Date Jewish #0072", jewishDate);

  // let testDate = new Date (item.data.details.date.en);
  // console.log(testDate.getDay(), testDate.toLocaleString('default', { month: 'long' }), testDate.getFullYear(), "testDate")
  try {
    date = date.en.split(" ");
    jewishDate = jewishDate.split(" ").reverse();
    console.log("Date In", date, jewishDate);
    if (date.length == 3) {
      item.data.details.day = Number(date[0]);
      item.data.details.month = getMonthName(date[1]);
      item.data.details.year = Number(date[2]);
      item.data.details.jewishDay = jewishDate[2];
      item.data.details.jewishMonth = jewishDate[1];
      item.data.details.jewishYear = jewishDate[0];
    }
    if (item.data.details.born_after_sunset) {
      item.data.details.born_after_sunset = born_after_sunset;
    }
    console.log("Date #0072", item);

  } catch (e) {
    console.log(e);
  }

  try {
    if (item.data.wishes.from == null || item.data.wishes.from == undefined) {
      item.data.wishes.from = "";
    }
    if (item.data.wishes.to == null || item.data.wishes.to == undefined) {
      item.data.wishes.to = "";
    }
    if (
      item.data.wishes.message == null ||
      item.data.wishes.message == undefined
    ) {
      item.data.wishes.message = "";
    }
  } catch (e) {
    console.log(e);
  }

  let data = {
    delivery: { ...item.data.delivery },
    details: { ...item.data.details },
    wishes: { ...item.data.wishes },
    birthday_recurring: item.data.birthday_recurring,
  };

  // data.details.sex = {
  //   label: capitalizeFirstLetter(item.data.details.sex),
  //   value: item.data.details.sex,
  // };

  data.details.sex = item.data.details.sex;

  // console.log(item.data.details)
  // data.details.date = new Date(item.data.details.date);
  data.details.day = { label: item.data.details.day, value: item.data.details.day };
  data.details.month = { label: item.data.details.month, value: item.data.details.month };
  data.details.year = { label: item.data.details.year, value: item.data.details.year };
  data.details.jewishDay = { label: item.data.details.jewishDay, value: item.data.details.jewishDay }
  data.details.jewishMonth = { label: item.data.details.jewishMonth, value: item.data.details.jewishMonth }
  data.details.jewishYear = { label: item.data.details.jewishYear, value: item.data.details.jewishYear }
  data.delivery.country = {
    label: data.delivery.country,
    value: data.delivery.country,
  };
  return data;
};

// function capitalizeFirstLetter(string) {
//   return string.charAt(0).toUpperCase() + string.slice(1);
// }

const getPackageView = (value) => {
  switch (value) {
    case PROJECTS.MY_MAOR.PACKAGES.SINGLE_LAYER.ID:
      return {
        title_translation_key: "projects.maor-kids.single_layer",
        Illustration: Cake1,
      };
    case PROJECTS.MY_MAOR.PACKAGES.DOUBLE_LAYER.ID:
      return {
        title_translation_key: "projects.maor-kids.double_layer",
        Illustration: Cake2,
      };
    case PROJECTS.MY_MAOR.PACKAGES.TRIPLE_DECKER.ID:
      return {
        title_translation_key: "projects.maor-kids.triple_decker",
        Illustration: Cake3,
      };
    case PROJECTS.MY_MAOR.PACKAGES.SEVEN_LAYER_EXPLOSION.ID:
      return {
        title_translation_key: "projects.maor-kids.seven_layer_explosion",
        Illustration: Cake4,
      };
    default:
      throw new Error(
        `There are no translation key for package name like this: ${value}`
      );
  }
};

const getSteps = (type, { form }) => {
  // const { authorization } = useContext(StoreContext);
  // const addAnotherChild = Cookies.get({ name: COOKIES.ADD_ANOTHER_CHILD });

  const hasDelivery = Object.values(PROJECTS.MY_MAOR.PACKAGES).find(
    (item) => item.ID === type
  ).DELIVERY;

  const steps = [];

  if (hasDelivery) {
    // if (!authorization.isAuthenticated || addAnotherChild != 1) {
    //   steps.push({
    //     props: form,
    //     isNotValid: form.isNotValidPersonal,
    //     View: Personal,
    //   });
    // }
    steps.push({
      props: form,
      isNotValid: form.isNotValidBirthdayDetails,
      isNotChildScreen: form.addChild,
      triggerValidations: form.triggerValidationsPersonal,
      View: BirthdayDetails,
    });
    steps.push({
      props: form,
      isNotAddressScreen: form.addAddress,
      isNotValid: form.isNotValidDeliveryDetails,
      triggerValidations: form.triggerValidationsDelivery,
      View: DeliveryDetails,
    });
    steps.push({
      props: form,
      View: AutoRenewal,
    })
  } else {
    // if (!authorization.isAuthenticated || addAnotherChild != 1) {
    //   steps.push({
    //     props: form,
    //     isNotValid: form.isNotValidPersonal,
    //     View: Personal,
    //   });
    // }
    steps.push({
      props: form,
      isNotValid: form.isNotValidBirthdayDetails,
      isNotChildScreen: form.addChild,
      triggerValidations: form.triggerValidationsPersonal,
      View: BirthdayDetails,
    });
    steps.push({
      props: form,
      View: AutoRenewal,
    })
  }

  return steps;
};

const SEX = {
  MAN: "man",
  WOMAN: "woman",
};

export {
  getDots,
  getInitials,
  getPackageView,
  getSteps,
  checkHasDelivery,
  SEX,
};
