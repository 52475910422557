import { useCallback, useState } from "react";

const useRanger = ({ min = 1, max = 10, labels = [], step = 1, rtl = false, values }) => {
  const [state, setState] = useState(values);

  const onChange = useCallback((values) => {
    setState(values);
  });

  return {
    min: min,
    max: max,
    labels: labels,
    rtl: rtl,
    step: step,
    values: state,
    onChange: onChange,
  };
};

export default useRanger;
