const COLORS = {
  PRIMARY: "primary",
};

const SIZES = {
  SMALL: "s",
  MEDIUM: "m",
  PLEDGECHECKOUT: 'pl'
};

export { COLORS, SIZES };
